import React from 'react';
import { Grid } from 'semantic-ui-react';
import ErrorReportGauge from '../../../../Components/Gauges/ErrorReportGauge';
import GovernmentInspectionGauge from '../../../../Components/Gauges/GovernmentInspectionGauge';
import LeaseGauge from '../../../../Components/Gauges/LeaseGauge';
import RoutineInspectionGauge from '../../../../Components/Gauges/RoutineInspectionGauge';
import { FilterValuesType } from '../../../../FiltersProvider/FiltersProvider';

interface Props {
  propertyId?: number;
  filterValues?: FilterValuesType;
}
const SummaryGauges: React.FC<Props> = (props) => {
  return (
    <>
      <Grid.Column widescreen={4} computer={8} largeScreen={4} tablet={8}>
        <LeaseGauge {...props} />
      </Grid.Column>
      <Grid.Column widescreen={4} computer={8} largeScreen={4} tablet={8}>
        <ErrorReportGauge {...props} />
      </Grid.Column>
      <Grid.Column widescreen={4} computer={8} largeScreen={4} tablet={8}>
        <RoutineInspectionGauge {...props} />
      </Grid.Column>
      <Grid.Column widescreen={4} computer={8} largeScreen={4} tablet={8}>
        <GovernmentInspectionGauge {...props} />
      </Grid.Column>
    </>
  );
};

export default SummaryGauges;
