/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { UnitObjectListView } from '../model';
/**
 * UnitObjectApi - axios parameter creator
 * @export
 */
export const UnitObjectApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List unit objects
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unitObjectResourceList: async (
      limit?: number,
      offset?: number,
      propertyId?: number,
      organisationId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/unit-objects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * UnitObjectApi - functional programming interface
 * @export
 */
export const UnitObjectApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UnitObjectApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List unit objects
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unitObjectResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      organisationId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitObjectListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unitObjectResourceList(
        limit,
        offset,
        propertyId,
        organisationId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * UnitObjectApi - factory interface
 * @export
 */
export const UnitObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UnitObjectApiFp(configuration);
  return {
    /**
     *
     * @summary List unit objects
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unitObjectResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      organisationId?: number,
      options?: any
    ): AxiosPromise<UnitObjectListView> {
      return localVarFp
        .unitObjectResourceList(limit, offset, propertyId, organisationId, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for unitObjectResourceList operation in UnitObjectApi.
 * @export
 * @interface UnitObjectApiUnitObjectResourceListRequest
 */
export interface UnitObjectApiUnitObjectResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof UnitObjectApiUnitObjectResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof UnitObjectApiUnitObjectResourceList
   */
  readonly offset?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof UnitObjectApiUnitObjectResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof UnitObjectApiUnitObjectResourceList
   */
  readonly organisationId?: number;
}

/**
 * UnitObjectApi - object-oriented interface
 * @export
 * @class UnitObjectApi
 * @extends {BaseAPI}
 */
export class UnitObjectApi extends BaseAPI {
  /**
   *
   * @summary List unit objects
   * @param {UnitObjectApiUnitObjectResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UnitObjectApi
   */
  public unitObjectResourceList(
    requestParameters: UnitObjectApiUnitObjectResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return UnitObjectApiFp(this.configuration)
      .unitObjectResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.propertyId,
        requestParameters.organisationId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
