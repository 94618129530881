import React from 'react';
import { Checkbox, CheckboxProps, Dropdown } from 'semantic-ui-react';
import { FinancialStatisticsFilterType } from '../FinancialStatistics';
import styles from './FinancialStatisticsFilters.module.scss';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedStatisticsType: FinancialStatisticsFilterType[];
  setSelectedStatisticsType: React.Dispatch<React.SetStateAction<FinancialStatisticsFilterType[]>>;
  granularity: 'QUARTERLY' | 'YEARLY';
  setGranularity: React.Dispatch<React.SetStateAction<'QUARTERLY' | 'YEARLY'>>;
  areAllMarked?: boolean;
  onAllMarkedChange: (value: boolean) => void;
  areStatisticsPerArea: boolean;
  onStatisticsPerAreaChange: (value: boolean) => void;
  includePrognosis: boolean;
  setIncludePrognosis: React.Dispatch<React.SetStateAction<boolean>>;
}

export const translateFinancialStatisticsFilterType = (value: FinancialStatisticsFilterType) => {
  switch (value) {
    case FinancialStatisticsFilterType.CapexAndTi:
      return 'CAPEX & TI';
    case FinancialStatisticsFilterType.OPEX:
      return 'OPEX';
    case FinancialStatisticsFilterType.Income:
      return i18n.t('financialStatistics:income');

    case FinancialStatisticsFilterType.Maintenance:
      return i18n.t('financialStatistics:maintenance');

    case FinancialStatisticsFilterType.Management:
      return i18n.t('financialStatistics:management');
  }
};

const translateGranularityOptions = (value: 'QUARTERLY' | 'YEARLY') => {
  if (value === 'QUARTERLY') return i18n.t('common:quarter');
  else return i18n.t('common:year');
};

export const granularityOptions = () =>
  ['QUARTERLY', 'YEARLY'].map((item) => {
    return {
      text: translateGranularityOptions(item as 'QUARTERLY' | 'YEARLY'),
      value: item,
      key: item
    };
  });

const FinancialStatisticsFilters: React.FC<Props> = (props) => {
  const { t } = useTranslation(['financialStatistics', 'common']);

  const typeValues = Object.values(FinancialStatisticsFilterType);
  const typeOptions = typeValues.map((item) => {
    return {
      text: translateFinancialStatisticsFilterType(item),
      value: item,
      key: item
    };
  });

  const toggleSelection = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (data.checked) {
      props.setSelectedStatisticsType((prevValue) => [...prevValue, data.value as FinancialStatisticsFilterType]);
    } else {
      props.setSelectedStatisticsType((prevValue) => prevValue.filter((item) => item !== data.value));
    }
  };

  return (
    <div className={styles.container}>
      <Dropdown
        // We only want the styles from the selction for consistency
        className="selection"
        compact
        floating
        clearable
        multiple
        text={
          props.selectedStatisticsType.length !== 5
            ? t('financialStatistics:filtered')
            : t('financialStatistics:filter')
        }
        // The value and onChange handler should work differently from normal dropdowns
        // When trying to clear the dropdown we should select all the items instead of clearing them
        // Hence we use the logic where if we have all filters selected we pass an empty array as the value,
        // and when we have less than all of them selected we send a dummy array.
        value={props.selectedStatisticsType.length !== 5 ? ['dummyDataToShowClearableIcon'] : []}
        onChange={(e, d) => {
          props.setSelectedStatisticsType(typeValues);
        }}
      >
        <Dropdown.Menu className={styles.dropdownMenu}>
          {typeOptions.map((item) => (
            <Dropdown.Item key={item.key} className={styles.menuItem} onClick={(e) => e.stopPropagation()}>
              <Checkbox
                label={item.text}
                value={item.value}
                checked={props.selectedStatisticsType.includes(item.value)}
                onChange={toggleSelection}
              />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown
        placeholder="Granularity"
        compact
        floating
        className={styles.compactDropdown}
        selection
        options={granularityOptions()}
        value={props.granularity}
        onChange={(e, d) => {
          props.setGranularity(d.value as 'QUARTERLY' | 'YEARLY');
        }}
      />

      <Checkbox
        label={t('common:selectAll')}
        checked={props.areAllMarked}
        onChange={(e, d) => {
          props.onAllMarkedChange(d.checked as boolean);
        }}
      />

      <Checkbox
        label={t('common:sekSqm')}
        checked={props.areStatisticsPerArea}
        onChange={(e, d) => {
          props.onStatisticsPerAreaChange(d.checked as boolean);
        }}
      />

      <Checkbox
        label={t('financialStatistics:prognosis')}
        checked={props.includePrognosis}
        onChange={(e, d) => {
          props.setIncludePrognosis(d.checked as boolean);
        }}
      />
    </div>
  );
};

export default FinancialStatisticsFilters;
