import { FinancialReportReadView, FinancialStatisticsValueView } from '../GeneratedServices';

export const getPerAreaValue = (
  value: FinancialStatisticsValueView,
  actualArea: number,
  plannedArea: number
): FinancialStatisticsValueView => {
  return {
    actual: value.actual / (actualArea !== 0 ? actualArea : 1),
    planned: value.planned / (plannedArea !== 0 ? plannedArea : 1)
  };
};

/**
 * Generates a label with an asterix if the period is based on the contract payment data.
 **/
export const generatePeriodLabel = (item: FinancialReportReadView) => {
  return item.label + (item.real ? '' : '*');
};
