import React from 'react';
import { Header } from 'semantic-ui-react';
import styles from './HeaderContainer.module.scss';

interface Props {
  header?: string | JSX.Element;
  headerExtra?: string | JSX.Element;
  rightComponent?: string | JSX.Element;
  headerLeft?: string | JSX.Element;
  children?: any;
}

const HeaderContainer = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.content}>
        <div className={styles.headerWrapper}>
          {props.headerLeft}

          <div className={styles.header}>
            <Header as="h1" className={styles.headerContent}>
              {props.header}
            </Header>

            {props.headerExtra}
          </div>
        </div>

        {props.children}
      </div>

      <div className={styles.rightComponent}>{props.rightComponent}</div>
    </div>
  );
});

export const HeaderInfoSection = ({ label, content }: { label: string; content: any }) => (
  <div>
    <Header as="h3" className={styles.headerInfoLabel}>
      {label}
    </Header>
    <div className={styles.headerInfoContent}>{content}</div>
  </div>
);

export default HeaderContainer;
