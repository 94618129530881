import axios, { AxiosError } from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { UserReadSelfView } from '../GeneratedServices';
import { userApi } from '../Http/Http';
import {
  ResponseErrorCode,
  ResponseErrorCodeOverrideList,
  toUserfriendlyApiResponse,
  UnexpectedErrorText,
  UserFriendlyApiResponse
} from '../Http/response-error';
import i18n from '../i18n';
import * as MembershipService from '../Services/MembershipService';
import { MembershipReadView } from '../Services/MembershipService.types';

interface ResponseErrorSettings {
  handleResponseValidationError?: (resp: UserFriendlyApiResponse) => void;
  errorCodeOverrides?: ResponseErrorCodeOverrideList;
  handleDefaultResponseValidationError?: (resp: UserFriendlyApiResponse) => void;
}

interface GlobalStateCTX {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: UserReadSelfView | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<UserReadSelfView | undefined>>;
  currentUserMemberships: MembershipReadView[] | undefined;
  setCurrentUserMemberships: React.Dispatch<React.SetStateAction<MembershipReadView[] | undefined>>;
  handleHttpErrors: (e: unknown, responseErrorSettings?: ResponseErrorSettings) => boolean;
  handleLogOut: () => void;
  loading: boolean;
}

const initialState: GlobalStateCTX = {
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentUserMemberships: [],
  setCurrentUserMemberships: () => {},
  handleHttpErrors: () => true,
  handleLogOut: () => {},
  loading: true
};

export enum Tokens {
  BAM_AUTH_TOKEN = 'bam_auth_token',
  BAM_REFRESH_TOKEN = 'bam_refresh_token'
}

export const globalStateCTX = React.createContext<GlobalStateCTX>(initialState);

type Props = { children: any };

const GlobalState: React.FC<Props> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentUser, setCurrentUser] = React.useState<UserReadSelfView>();
  const [currentUserMemberships, setCurrentUserMemberships] = React.useState<MembershipReadView[]>();

  React.useEffect(() => {
    if (currentUser != null && currentUser.languagePreference !== i18n.language) {
      i18n.changeLanguage(currentUser.languagePreference);
    }
  }, [currentUser]);

  // Returns true if the error is handled and false if it's a cancel error. This means that we can set some logic locally to handle cancel errors.
  const handleHttpErrors = React.useCallback((error: unknown, settings?: ResponseErrorSettings) => {
    if (axios.isCancel(error)) {
      return false;
    }
    const { response } = error as AxiosError;
    if (response) {
      const { data } = response;
      switch (data?.code) {
        case ResponseErrorCode.R000001:
          // the token renewal with refresh token was not successful.
          handleLogOut();
          break;

        case ResponseErrorCode.R000004: {
          const resp = toUserfriendlyApiResponse(data, settings?.errorCodeOverrides);
          settings?.handleResponseValidationError?.(resp);
          break;
        }

        default: {
          const resp = toUserfriendlyApiResponse(data, settings?.errorCodeOverrides);
          settings?.handleDefaultResponseValidationError
            ? settings.handleDefaultResponseValidationError(resp)
            : toast.error(resp.message ?? UnexpectedErrorText);
        }
      }
    } else {
      // todo: Check for internet connection lost.
      toast.error(UnexpectedErrorText);
    }
    return true;
  }, []);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        const { data } = await userApi.userResourceReadSelf({ signal: abortController.signal });
        if (data) {
          setCurrentUser(data);
        }
      } catch (error) {
        handleHttpErrors(error);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      if (currentUser) {
        try {
          // Find memberships for current user
          const membership = await MembershipService.getMemberships(
            { userId: currentUser.id },
            { signal: abortController.signal }
          );
          setCurrentUserMemberships(membership.records);
          setIsAuthenticated(true);
        } catch (error) {
          handleHttpErrors(error);
        } finally {
          setLoading(false);
        }
      } else {
        setCurrentUserMemberships(undefined);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [currentUser, handleHttpErrors]);

  const handleLogOut = () => {
    localStorage.removeItem(Tokens.BAM_AUTH_TOKEN);
    localStorage.removeItem(Tokens.BAM_REFRESH_TOKEN);
    setCurrentUser(undefined);
    setCurrentUserMemberships(undefined);
    setIsAuthenticated(false);
    setLoading(false);
  };

  return (
    <globalStateCTX.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        currentUser,
        setCurrentUser,
        handleHttpErrors,
        handleLogOut,
        loading,
        currentUserMemberships,
        setCurrentUserMemberships
      }}
    >
      {children}
    </globalStateCTX.Provider>
  );
};

export default GlobalState;
