import React from 'react';
import { Checkbox, CheckboxProps, Popup } from 'semantic-ui-react';
import { renderValidationErrors } from '../../Utils/FieldValidationErrorMessage';
import { FieldValidationResult } from '../../Utils/validation';

interface Props extends CheckboxProps {
  isInEditMode?: boolean;
  validationResult?: FieldValidationResult;
}
const EditableTableCheckboxCell: React.FC<Props> = (props) => {
  const { isInEditMode, validationResult, ...rest } = props;

  if (isInEditMode)
    return (
      <Popup
        content={renderValidationErrors(validationResult)}
        disabled={!validationResult?.error}
        trigger={<Checkbox {...rest} />}
      />
    );
  else return <Checkbox {...rest} readOnly />;
};

export default EditableTableCheckboxCell;
