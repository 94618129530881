/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PointView } from './point-view';

/**
 *
 * @export
 * @interface ComparablePropertyReadView
 */
export interface ComparablePropertyReadView {
  /**
   * ComparableProperty address.
   * @type {string}
   * @memberof ComparablePropertyReadView
   */
  address: string;
  /**
   * ComparableProperty country.
   * @type {string}
   * @memberof ComparablePropertyReadView
   */
  country: ComparablePropertyReadViewCountryEnum;
  /**
   * ID.
   * @type {number}
   * @memberof ComparablePropertyReadView
   */
  id: number;
  /**
   * Label to be shown on map.
   * @type {string}
   * @memberof ComparablePropertyReadView
   */
  label?: string;
  /**
   * ComparableProperty municipality.
   * @type {string}
   * @memberof ComparablePropertyReadView
   */
  municipality: string;
  /**
   * ComparableProperty name.
   * @type {string}
   * @memberof ComparablePropertyReadView
   */
  name: string;
  /**
   * Organisation ID.
   * @type {number}
   * @memberof ComparablePropertyReadView
   */
  organisationId: number;
  /**
   * Owned property ID.
   * @type {number}
   * @memberof ComparablePropertyReadView
   */
  ownedPropertyId?: number | null;
  /**
   *
   * @type {PointView}
   * @memberof ComparablePropertyReadView
   */
  position: PointView | null;
  /**
   * Type of property.
   * @type {string}
   * @memberof ComparablePropertyReadView
   */
  type: ComparablePropertyReadViewTypeEnum;
}

export const ComparablePropertyReadViewCountryEnum = {
  Sweden: 'SWEDEN',
  Finland: 'FINLAND',
  Denmark: 'DENMARK',
  Spain: 'SPAIN',
  UnitedKingdom: 'UNITED_KINGDOM'
} as const;

export type ComparablePropertyReadViewCountryEnum =
  (typeof ComparablePropertyReadViewCountryEnum)[keyof typeof ComparablePropertyReadViewCountryEnum];
export const ComparablePropertyReadViewTypeEnum = {
  Retail: 'RETAIL',
  Other: 'OTHER',
  Office: 'OFFICE',
  Hotel: 'HOTEL',
  Residential: 'RESIDENTIAL',
  Logistics: 'LOGISTICS',
  Industrial: 'INDUSTRIAL',
  Parking: 'PARKING',
  BuildingRights: 'BUILDING_RIGHTS',
  Warehouse: 'WAREHOUSE',
  Public: 'PUBLIC'
} as const;

export type ComparablePropertyReadViewTypeEnum =
  (typeof ComparablePropertyReadViewTypeEnum)[keyof typeof ComparablePropertyReadViewTypeEnum];
