import React from 'react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import GaugeCard from './Gauge/GaugeCard';
import { KPIStatus } from '../KPIs/KPICard/KpiCard';
import { useNonce } from '../../Utils/hooks';
import { statisticsApi } from '../../Http/Http';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';
import { LeaseStatusStatisticsReadView } from '../../GeneratedServices';
import colors from '../../Utils/Colors/Colors.module.scss';

interface Props {
  propertyId?: number;
  filterValues?: FilterValuesType;
}

const LeaseGauge: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [expiringLeaseStatistics, setExpiringLeaseStatistics] = React.useState<LeaseStatusStatisticsReadView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();
    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceReadLeaseStatusStatistics(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setExpiringLeaseStatistics(data);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();

    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  return (
    <GaugeCard
      title={t('common:leases')}
      data={[
        {
          id: '1',
          value: expiringLeaseStatistics?.unassigned ?? 0,
          label: t('contracts:unassigned'),
          labelWrap: true
        },
        {
          id: '2',
          value: expiringLeaseStatistics?.renegotiated ?? 0,
          label: t('contracts:renegotiation')
        },
        {
          id: '3',
          value: expiringLeaseStatistics?.assigned ?? 0,
          label: t('contracts:assigned')
        }
      ]}
      colors={[colors.chart7, colors.orange, colors.chart2]}
      status={status}
      retry={reload}
    />
  );
};

export default LeaseGauge;
