import React from 'react';
import { Table, TableHeaderCellProps } from 'semantic-ui-react';
import { getUpcomingSortDirection, SortDirection } from '../SortableTable';
import { sortableTableStateCTX } from './SortableTableContext';

interface Props extends TableHeaderCellProps {
  children?: any;
  sortable?: boolean;
  name?: string;
}

const SortableHeaderCell: React.FC<Props> = (props) => {
  const { sortDirection, sortedColumnKey, handleSort, setSortDirection, setSortedColumnKey } =
    React.useContext(sortableTableStateCTX);

  return (
    <Table.HeaderCell
      {...props}
      sortable={props.sortable === false ? 'false' : 'true'}
      sorted={sortedColumnKey === props.name ? sortDirection : undefined}
      onClick={() => {
        let newColumnKey = sortedColumnKey;
        let newSortDirection = sortDirection;

        if (sortedColumnKey !== props.name) {
          newColumnKey = props.name;
          newSortDirection = SortDirection.ASC;
        } else {
          newSortDirection = getUpcomingSortDirection(sortDirection);
          //Clear sorted column if upcoming sort direction is undefined.
          if (!newSortDirection) {
            newColumnKey = undefined;
          }
        }

        setSortedColumnKey(newColumnKey);
        setSortDirection(newSortDirection);
        handleSort(newColumnKey, newSortDirection);
      }}
    />
  );
};

export default SortableHeaderCell;
