import { isValid, parseISO } from 'date-fns';
import {
  ResponseFieldErrorCode,
  ResponseFieldErrorCodeOverrideList,
  UserFriendlyApiResponse
} from '../Http/response-error';
import { RangeView } from '../Services/types';
import i18n from '../i18n';

export interface FieldValidationResult {
  error: boolean;
  errorMessage?: (string | JSX.Element)[];
}

export interface ApplyApiResponseValidationErrorMapping {
  formFieldId: string;
  setStateFunc: (value: FieldValidationResult | undefined) => void;
}

/**
 * Returns a validation result object for specified value.
 *
 * undefined means value has not yet been altered.
 * If string is empty an mandatory validation result is returned.
 *
 * @param val Value to create validation message of.
 */
export const resolveMandatoryMessage = (val: string | undefined): FieldValidationResult | undefined => {
  return val || val === undefined
    ? undefined
    : {
        error: true,
        errorMessage: [i18n.t('validation:fieldIsObligatory')]
      };
};

/**
 * Returns a value indicating whether the specified e-mail address is valid.
 * @param email Email to validate.
 */
const isEmailValid = (email: string) => {
  const regex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

/**
 * Checks whether text is empty and sets validation error message.
 * @param value Value to validate.
 * @param setStateFunc Set state action with validation result for the specified value.
 * @param emptyMessage Text to display as validation error if value is an empty string.
 */
export const validateRequiredField = (
  value: string,
  setStateFunc: (data: FieldValidationResult | undefined) => void,
  emptyMessage: string
) => {
  const error = value.trim().length === 0;

  setStateFunc({
    error,
    errorMessage: error ? [emptyMessage] : undefined
  });

  return !error;
};

/**
 * Checks whether value is a valid date in format YYYY-MM-DD.
 * @param value Value to validate.
 * @param setStateFunc Set state action with validation result for the specified value.
 * @param emptyMessage Text to display as validation error if value is not a valid date.
 */
export const validateDate = (
  value: string | undefined,
  setStateFunc: (data: FieldValidationResult | undefined) => void,
  emptyMessage: string
) => {
  const error = !value || !value.match(/^\d{4}-\d{2}-\d{2}$/) || !isValid(parseISO(value!));

  setStateFunc({
    error,
    errorMessage: error ? [emptyMessage] : undefined
  });

  return !error;
};

/**
 * Checks whether specified value exists in specified enum type and sets validation error message.
 * @param enumType Enum type.
 * @param value Value to validate.
 * @param setStateFunc Set state action with validation result for the specified field.
 * @param emptyMessage Text to display as validation error if enum doesn't contains specified value.
 * @returns
 */
export const validateRequiredEnum = <T extends { [name: string]: any }>(
  enumType: T,
  value: any | undefined,
  setStateFunc: React.Dispatch<React.SetStateAction<FieldValidationResult | undefined>>,
  emptyMessage: string
) => {
  const error = !value || !Object.values(enumType).includes(value);

  setStateFunc({
    error,
    errorMessage: error ? [emptyMessage] : undefined
  });

  return !error;
};

/**
 * Validates email and sets validation result.
 * @param email Email to validate.
 * @param setStateFunc Set state action with validation result for the email field.
 */
export const validateEmailField = (
  email: string,
  setStateFunc: React.Dispatch<React.SetStateAction<FieldValidationResult | undefined>>
) => {
  if (email.length === 0) {
    setStateFunc({
      error: true,
      errorMessage: [i18n.t('validation:missingEmail')]
    });

    return;
  }

  // todo: Change regex to match backend regex.
  // eslint-disable-next-line no-useless-escape
  const emailValid = isEmailValid(email);
  setStateFunc({
    error: !emailValid,
    errorMessage: !emailValid ? [i18n.t('validation:invalidEmail')] : undefined
  });
};

/**
 * Validates password and sets validation result.
 * @param password Password to validate.
 * @param setStateFunc Set state action with validation result for the password field.
 */
export const validatePasswordField = (
  password: string,
  setStateFunc: React.Dispatch<React.SetStateAction<FieldValidationResult | undefined>>
) => {
  const invalidPassword = password.length === 0;
  setStateFunc({
    error: invalidPassword,
    errorMessage: invalidPassword ? [i18n.t('validation:missingPassword')] : undefined
  });

  // todo: Add other constraints, like min/max/valid characters.
};

export const validatePasswordMatch = (
  newPassoword: string,
  confirmNewPassword: string,
  setStateFunc: React.Dispatch<React.SetStateAction<FieldValidationResult | undefined>>
) => {
  const mismatch = newPassoword !== confirmNewPassword;
  setStateFunc({
    error: mismatch,
    errorMessage: mismatch ? [i18n.t('validation:newAndConfirmPasswordMismatch')] : undefined
  });
  return !mismatch;
};

/**
 * Returns a value representing whether specified fields are all valid.
 * A validation result is condsidered valid if it's undefined (not yet altered by the user) or error property is false.
 * @param fieldValidationResults List of field validation results.
 */
export const allFieldsValid = (fieldValidationResults: (FieldValidationResult | undefined)[]) => {
  return fieldValidationResults.every((res) => res === undefined || !res.error);
};

/**
 * Checks whether the response contains field errors matching ids specified.
 * For those matching, error message is saved to field by calling the corresponding set state function.
 * @param resp Api response with user friendly messages.
 * @param mapping Mapping of form field id and set state function.
 */
export const applyApiResponseValidationToFields = (
  resp: UserFriendlyApiResponse,
  mapping: ApplyApiResponseValidationErrorMapping[],
  errorCodeOverride?: ResponseFieldErrorCodeOverrideList
) => {
  for (const item of mapping) {
    const errorItem = resp.fieldErrors?.get(item.formFieldId);
    if (errorItem) {
      item.setStateFunc({
        error: true,
        errorMessage: errorItem.map((x) =>
          errorCodeOverride?.has(x.code) ? errorCodeOverride.get(x.code)! : x.message
        )
      });
    }
  }
};

/**
 * Checks whether value is between 0 and 59.
 * @param value Value to validate.
 * @param setStateFunc Set state action with validation result for the specified value.
 * @param emptyMessage Text to display as validation error if value is not a valid date.
 */
export const validateMinutes = (
  value: number,
  setStateFunc: React.Dispatch<React.SetStateAction<FieldValidationResult | undefined>>,
  emptyMessage: string
) => {
  const error = value < 0 || value >= 60;

  setStateFunc({
    error,
    errorMessage: error ? [emptyMessage] : undefined
  });

  return !error;
};

export const hasError = (response: UserFriendlyApiResponse, fieldName: string, errorCode: ResponseFieldErrorCode) => {
  return (
    response.fieldErrors != null &&
    response.fieldErrors.has(fieldName) &&
    response.fieldErrors.get(fieldName)!.some((item) => item.code === errorCode)
  );
};

/**
 * Validates range and sets validation result.
 * @param range Range to validate.
 * @param setStateFunc Set state action with validation result for the range field.
 */
export const validateRange = (
  range: RangeView,
  setStateFunc: React.Dispatch<React.SetStateAction<FieldValidationResult | undefined>>
) => {
  let errorMessage: string | undefined = undefined;
  if (isNaN(range.min) || isNaN(range.max)) {
    errorMessage = i18n.t('validation:minMaxMustBeSubmitted');
  } else if (range.min > range.max) {
    errorMessage = i18n.t('validation:minIsGreaterThanMax');
  }
  setStateFunc({
    error: errorMessage ? true : false,
    errorMessage: errorMessage ? [errorMessage] : undefined
  });

  return errorMessage ? false : true;
};
