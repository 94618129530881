import React from 'react';

interface Props {
  header?: string;
  extra?: string;
}
const TableHeaderCellContent: React.FC<Props> = (props) => {
  return (
    <div style={{ display: 'inline' }}>
      <span>{props.header}</span>
      <span style={{ paddingLeft: '0.25em', fontWeight: 300, fontSize: '12px' }}>{props.extra}</span>
    </div>
  );
};

export default TableHeaderCellContent;
