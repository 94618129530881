import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'semantic-ui-react';
import { GovernmentInspectionItemResultUpdateView, GovernmentInspectionListItemView } from '../../../GeneratedServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMultiply } from '@fortawesome/pro-solid-svg-icons';
import EditableTableDateCell from '../../EditableTableCell/EditableTableDateCell';
import { mapGovernmentInspectionItemsToDropdownItems } from '../../../Services/GovernmentInspectionService.types';

import TextAreaAutosize from 'react-textarea-autosize';
import EditableTableDropdownCell from '../../EditableTableCell/EditableTableDropdownCell';
import styles from './GovernmentInspectionWorkOrderTable.module.scss';
import { generateGovernmentInspectionItemGrade } from '../../../Services/GovernmentInspectionService';
import { notEmpty } from '../../../Utils/array';
import { inspectionItemResultsReducer, InspectionItemResultState } from './GovernmentInspectionItemResultsReducer';

interface Props {
  inspection?: GovernmentInspectionListItemView;
  onChange: (state: InspectionItemResultState) => void;
  itemResultDefaults?: Omit<GovernmentInspectionItemResultUpdateView, 'itemId'>;
  initialState?: InspectionItemResultState;
  disabled?: boolean;
}

const GovernmentInspectionWorkOrderTable: React.FC<Props> = (props) => {
  const { inspection, onChange } = props;
  const { t } = useTranslation(['inspections', 'common']);

  const [itemResults, dispatchItemResults] = React.useReducer(inspectionItemResultsReducer, []);

  React.useEffect(() => {
    if (props.initialState) {
      dispatchItemResults({
        type: 'INIT',
        data: props.initialState
      });
    } else
      dispatchItemResults({
        type: 'ADD_BLANK'
      });

    return () => dispatchItemResults({ type: 'INIT', data: [] });
  }, [props.initialState]);

  React.useEffect(() => {
    onChange(itemResults);
  }, [itemResults, onChange]);

  React.useEffect(() => {
    props.itemResultDefaults &&
      dispatchItemResults({
        type: 'EDIT_ALL',
        data: props.itemResultDefaults
      });
  }, [props.itemResultDefaults]);

  return (
    <>
      <div className={styles.header}>
        <label>{t('common:results')}</label>
        <Button
          circular
          icon
          type="button"
          title={t('common:add')}
          size="mini"
          color="blue"
          disabled={props.disabled}
          onClick={() =>
            dispatchItemResults({
              type: 'ADD_BLANK'
            })
          }
        >
          <FontAwesomeIcon icon={faPlus} className={styles.buttonIcon} />
        </Button>
      </div>

      <Table basic="very" singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>{t('inspections:itemId')}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{t('inspections:itemGrade')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t('inspections:performDate')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t('inspections:nextDeadline')}</Table.HeaderCell>
            <Table.HeaderCell width={6}>{t('common:comment')}</Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {itemResults.map((item) => (
            <Table.Row key={item.uuid}>
              <Table.Cell>
                <EditableTableDropdownCell
                  disabled={props.disabled}
                  isInEditMode={true}
                  validationResult={item.validationResults.itemId}
                  compact
                  search
                  placeholder={t('inspections:itemId')}
                  upward
                  scrolling
                  value={item.itemId}
                  onChange={(e, d) =>
                    dispatchItemResults({
                      type: 'EDIT_ITEM',
                      item: {
                        ...item,
                        itemId: d.value as number,
                        validationResults: {
                          ...item.validationResults,
                          itemId: undefined
                        }
                      }
                    })
                  }
                  options={mapGovernmentInspectionItemsToDropdownItems(
                    inspection?.items ?? [],
                    itemResults.map((item) => item.itemId).filter(notEmpty)
                  )}
                  noResultsMessage={t('inspections:noInspectionObjectsFound')}
                />
              </Table.Cell>

              <Table.Cell>
                <EditableTableDropdownCell
                  disabled={props.disabled}
                  isInEditMode={true}
                  validationResult={item.validationResults.passed}
                  compact
                  placeholder={t('inspections:itemGrade')}
                  upward
                  scrolling
                  value={item.passed}
                  onChange={(e, d) =>
                    dispatchItemResults({
                      type: 'EDIT_ITEM',
                      item: {
                        ...item,
                        passed: d.value as boolean,
                        validationResults: {
                          ...item.validationResults,
                          passed: undefined
                        }
                      }
                    })
                  }
                  options={generateGovernmentInspectionItemGrade()}
                />
              </Table.Cell>

              <Table.Cell>
                <EditableTableDateCell
                  disabled={props.disabled}
                  isInEditMode={true}
                  displayValue={item.performed ?? ''}
                  validationResult={item.validationResults.performed}
                  value={item.performed}
                  placeholder={'YYYY-MM-DD'}
                  onValueChange={(value) => {
                    dispatchItemResults({
                      type: 'EDIT_ITEM',
                      item: {
                        ...item,
                        performed: value,
                        validationResults: {
                          ...item.validationResults,
                          performed: undefined
                        }
                      }
                    });
                  }}
                  inputClassName={styles.input}
                />
              </Table.Cell>

              <Table.Cell>
                <EditableTableDateCell
                  disabled={props.disabled}
                  isInEditMode={true}
                  displayValue={item.nextInspectionDeadline ?? ''}
                  value={item.nextInspectionDeadline}
                  validationResult={item.validationResults.nextInspectionDeadline}
                  placeholder={'YYYY-MM-DD'}
                  onValueChange={(value) =>
                    dispatchItemResults({
                      type: 'EDIT_ITEM',
                      item: {
                        ...item,
                        nextInspectionDeadline: value,
                        validationResults: {
                          ...item.validationResults,
                          nextInspectionDeadline: undefined
                        }
                      }
                    })
                  }
                  inputClassName={styles.input}
                />
              </Table.Cell>

              <Table.Cell>
                <TextAreaAutosize
                  disabled={props.disabled}
                  rows={1}
                  maxRows={3}
                  cacheMeasurements
                  value={item.comment ?? undefined}
                  placeholder="Aa"
                  onChange={(e) =>
                    dispatchItemResults({
                      type: 'EDIT_ITEM',
                      item: {
                        ...item,
                        comment: e.currentTarget.value,
                        validationResults: {
                          ...item.validationResults,
                          comment: undefined
                        }
                      }
                    })
                  }
                />
              </Table.Cell>

              <Table.Cell>
                <Button
                  circular
                  icon
                  size="small"
                  type="button"
                  negative
                  disabled={props.disabled}
                  onClick={() =>
                    dispatchItemResults({
                      type: 'REMOVE_ITEM',
                      uuid: item.uuid
                    })
                  }
                >
                  <FontAwesomeIcon icon={faMultiply} className={styles.buttonIcon} />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default GovernmentInspectionWorkOrderTable;
