import React from 'react';
import { Grid } from 'semantic-ui-react';
import AreaVacancyKpi from '../KPIs/AreaVacancyKpi';
import LeaseCountKpi from '../KPIs/LeaseCountKpi';
import AreaKpi from './KPIs/AreaKpi';
import BaseRentKpi from './KPIs/BaseRentKpi';
import ParkingLeaseCountKpi from './KPIs/ParkingLeaseCountKpi';
import ParkingLeaseRentKpi from './KPIs/ParkingLeaseRentKpi';
import TotalRentKpi from './KPIs/TotalRentKpi';
import PlotAreaKpi from './KPIs/PlotAreaKpi';
import GroundAreaKpi from './KPIs/GroundAreaKpi';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';
import AdditionsKpi from './KPIs/AdditionsKpi';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
  reloadNonce?: number;
}

const LeaseSummary: React.FC<Props> = (props) => {
  return (
    <>
      <Grid style={{ maxWidth: 1920 }}>
        <Grid.Column widescreen={6} largeScreen={8} computer={10} tablet={10}>
          <AdditionsKpi {...props} />
        </Grid.Column>

        <Grid.Column widescreen={4} largeScreen={4} computer={6} tablet={6}>
          <Grid>
            <Grid.Column width={16}>
              <BaseRentKpi {...props} />
            </Grid.Column>

            <Grid.Column width={16}>
              <TotalRentKpi {...props} />
            </Grid.Column>
          </Grid>
        </Grid.Column>

        <Grid.Column widescreen={3} largeScreen={4} computer={16} tablet={16}>
          <Grid>
            <Grid.Column widescreen={16} largeScreen={16} computer={5} tablet={5}>
              <LeaseCountKpi {...props} />
            </Grid.Column>

            <Grid.Column widescreen={16} largeScreen={16} computer={5} tablet={5}>
              <AreaKpi {...props} />
            </Grid.Column>

            <Grid.Column widescreen={16} largeScreen={16} computer={6} tablet={6}>
              <AreaVacancyKpi {...props} />
            </Grid.Column>
          </Grid>
        </Grid.Column>

        <Grid.Column widescreen={3} largeScreen={16} computer={16} tablet={16}>
          <Grid>
            <Grid.Column widescreen={16} largeScreen={4} computer={5} tablet={5}>
              <PlotAreaKpi {...props} />
            </Grid.Column>

            <Grid.Column widescreen={16} largeScreen={4} computer={5} tablet={5}>
              <GroundAreaKpi {...props} />
            </Grid.Column>
          </Grid>
        </Grid.Column>

        <Grid.Column widescreen={3} largeScreen={4} computer={5} tablet={5}>
          <ParkingLeaseCountKpi {...props} />
        </Grid.Column>

        <Grid.Column widescreen={3} largeScreen={4} computer={5} tablet={5}>
          <ParkingLeaseRentKpi {...props} />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default LeaseSummary;
