import React from 'react';
import { Table, Icon, ButtonGroup, Button, Message, Loader } from 'semantic-ui-react';
import {
  PropertyCheckReadView,
  PropertyCheckStatus,
  translatePropertyCheckReoccurrence
} from '../../Services/PropertyCheckService.types';
import * as PropertyCheckService from '../../Services/PropertyCheckService';
import styles from './PropertyChecksTable.module.scss';
import { createInfinitePaginationParams } from '../../Http/Http';
import DeletePropertyCheckModal from './DeletePropertyCheckModal';
import EditPropertyCheckModal from './EditPropertyCheckModal';
import { useNonce } from '../../Utils/hooks';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import OverlayLoader from '../../Components/OverlayLoader';
import { parseISO } from 'date-fns';
import { getDeadlineDate, toDateString, toDateTimeString } from '../../Utils/date';
import { useTranslation } from 'react-i18next';

interface Props {
  organisationId?: number;
  propertyId?: number;
  refreshNonce: number;
}

enum Status {
  Idle,
  Loading,
  LoadError
}

type PropertyCheckEditModalItem = {
  open: boolean;
  propertyCheck?: PropertyCheckReadView;
};
type PropertyCheckEditModalState = PropertyCheckEditModalItem;
type PropertyCheckEditModalAction = { type: 'OPEN'; propertyCheck?: PropertyCheckReadView } | { type: 'CLOSE' };

const PropertyChecksTable: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'propertyChecks']);

  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);

  const [status, setStatus] = React.useState<Status>(Status.Idle);
  const [refreshNonce, setRefreshNonce] = useNonce();

  const [propertyChecks, setPropertyChecks] = React.useState<PropertyCheckReadView[]>([]);
  const [propertyCheckEditModal, dispatchPropertyCheckEditModal] = React.useReducer(propertyCheckEditModalReducer, {
    open: false
  });
  const [propertyCheckToDelete, setPropertyCheckToDelete] = React.useState<PropertyCheckReadView>();

  /**
   * Loading property checks.
   */
  React.useEffect(() => {
    const load = async () => {
      const abortController = new AbortController();

      try {
        setStatus(Status.Loading);
        const result = await PropertyCheckService.getPropertyChecks(
          {
            ...createInfinitePaginationParams(),
            propertyId: props.propertyId,
            organisationId: props.organisationId,
            isGeneral: props.organisationId !== undefined
          },
          {
            signal: abortController.signal
          }
        );
        setPropertyChecks(result.records);
        setStatus(Status.Idle);
      } catch (error) {
        globalState.handleHttpErrors(error) && setStatus(Status.LoadError);
      }

      return () => {
        abortController.abort();
      };
    };

    load();
  }, [globalState, props.organisationId, props.propertyId, props.refreshNonce, refreshNonce]);

  function propertyCheckEditModalReducer(
    state: PropertyCheckEditModalState,
    action: PropertyCheckEditModalAction
  ): PropertyCheckEditModalState {
    switch (action.type) {
      case 'OPEN':
        return {
          open: true,
          propertyCheck: action.propertyCheck
        };

      case 'CLOSE':
        return {
          open: false
        };
    }
  }

  const onEditButtonClicked = (item: PropertyCheckReadView) => {
    dispatchPropertyCheckEditModal({
      type: 'OPEN',
      propertyCheck: item
    });
  };

  const emptyView =
    status === Status.Loading ? (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader active size="large" inline="centered" />
      </div>
    ) : (
      <Message>{t('propertyChecks:noControlPointsFound')}</Message>
    );

  const nonEmptyView = (
    <OverlayLoader loading={status === Status.Loading}>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common:name')}</Table.HeaderCell>
            <Table.HeaderCell collapsing>{t('propertyChecks:scheduling')}</Table.HeaderCell>
            {props.propertyId !== undefined && (
              <>
                <Table.HeaderCell collapsing>{t('propertyChecks:deadline')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{t('propertyChecks:lastCompleted')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{t('common:status')}</Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {propertyChecks.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>{item.title}</Table.Cell>

              <Table.Cell className="no-line-break">{translatePropertyCheckReoccurrence(item.reoccurrence)}</Table.Cell>

              {props.propertyId !== undefined && (
                <>
                  <Table.Cell className="no-line-break">{toDateString(getDeadlineDate(item.deadline))}</Table.Cell>

                  <Table.Cell className="no-line-break">
                    {item.lastCompleted ? toDateTimeString(parseISO(item.lastCompleted)) : '-'}
                  </Table.Cell>

                  <Table.Cell>
                    <div className={styles.statusContainer}>
                      {item.status && (
                        <Icon
                          size="large"
                          color={
                            item.status === PropertyCheckStatus.COMPLETED
                              ? 'green'
                              : item.status === PropertyCheckStatus.DUE
                              ? 'grey'
                              : 'red'
                          }
                          name={
                            item.status === PropertyCheckStatus.COMPLETED
                              ? 'check circle'
                              : item.status === PropertyCheckStatus.DUE
                              ? 'circle outline'
                              : 'remove circle'
                          }
                          style={{ marginRight: 10 }}
                        />
                      )}

                      {item.status === PropertyCheckStatus.COMPLETED
                        ? t('propertyChecks:ok')
                        : item.status === PropertyCheckStatus.DUE
                        ? t('propertyChecks:notCompleted')
                        : t('propertyChecks:late')}
                    </div>
                  </Table.Cell>
                </>
              )}

              <Table.Cell>
                <ButtonGroup>
                  <Button
                    title={t('common:edit')}
                    size="mini"
                    color="blue"
                    compact
                    onClick={(e, d) => {
                      e.stopPropagation();
                      onEditButtonClicked(item);
                    }}
                  >
                    <Icon name="edit" fitted />
                  </Button>
                  <Button
                    title={t('common:remove')}
                    size="mini"
                    color="red"
                    compact
                    onClick={(e, d) => {
                      e.stopPropagation();
                      setPropertyCheckToDelete(item);
                    }}
                  >
                    <Icon name="trash" fitted />
                  </Button>
                </ButtonGroup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </OverlayLoader>
  );

  return (
    <>
      {propertyChecks.length === 0 ? emptyView : nonEmptyView}

      {propertyCheckEditModal.open && (
        <EditPropertyCheckModal
          action={{ propertyCheck: propertyCheckEditModal.propertyCheck! }}
          onClose={(saved) => {
            if (saved) {
              setRefreshNonce();
            }

            dispatchPropertyCheckEditModal({ type: 'CLOSE' });
          }}
        />
      )}

      {propertyCheckToDelete && (
        <DeletePropertyCheckModal
          propertyCheck={propertyCheckToDelete!}
          onClose={(deleted) => {
            if (deleted) {
              setRefreshNonce();
            }

            setPropertyCheckToDelete(undefined);
          }}
        />
      )}
    </>
  );
};

export default PropertyChecksTable;
