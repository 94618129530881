import {
  PropertyListView,
  PropertyListViewParams,
  PropertyReadView,
  PropertyUpdateView
} from './PropertyService.types';
import { BAM_API_BASE_URL } from '../config';
import { http } from '../Http/Http';
import { AxiosRequestConfig } from 'axios';
import { PropertyReadViewTypeEnum } from '../GeneratedServices';
import { sortBy } from 'lodash';
import i18n from '../i18n';

export const getProperties = async (
  params: PropertyListViewParams,
  config?: AxiosRequestConfig
): Promise<PropertyListView> => {
  const { data } = await http.get<PropertyListView>(`${BAM_API_BASE_URL}/v1/properties`, { ...config, params });
  return data;
};

export const getProperty = async (propertyId: number, config?: AxiosRequestConfig): Promise<PropertyReadView> => {
  const { data } = await http.get<PropertyReadView>(`${BAM_API_BASE_URL}/v1/properties/${propertyId}`, config);
  return data;
};

export const updateProperty = async (propertyId: number, payload: PropertyUpdateView): Promise<PropertyReadView> => {
  const { data } = await http.patch<PropertyReadView>(`${BAM_API_BASE_URL}/v1/properties/${propertyId}`, payload);
  return data;
};

export const translatePropertyType = (type: PropertyReadViewTypeEnum): string => {
  const map = new Map([
    [PropertyReadViewTypeEnum.Retail, i18n.t('properties:propertyType.retail')],
    [PropertyReadViewTypeEnum.Other, i18n.t('properties:propertyType.other')],
    [PropertyReadViewTypeEnum.Office, i18n.t('properties:propertyType.office')],
    [PropertyReadViewTypeEnum.Hotel, i18n.t('properties:propertyType.hotel')],
    [PropertyReadViewTypeEnum.Residential, i18n.t('properties:propertyType.residential')],
    [PropertyReadViewTypeEnum.Logistics, i18n.t('properties:propertyType.logistics')],
    [PropertyReadViewTypeEnum.Industrial, i18n.t('properties:propertyType.industrial')],
    [PropertyReadViewTypeEnum.Parking, i18n.t('properties:propertyType.parking')],
    [PropertyReadViewTypeEnum.BuildingRights, i18n.t('properties:propertyType.buildingRights')],
    [PropertyReadViewTypeEnum.Warehouse, i18n.t('properties:propertyType.warehouse')],
    [PropertyReadViewTypeEnum.Public, i18n.t('properties:propertyType.public')]
  ]);

  return map.has(type) ? map.get(type)! : type.toString();
};

const propertyTypeValues = Object.values(PropertyReadViewTypeEnum);

export const propertyOptions = () =>
  sortBy(
    propertyTypeValues.map((item) => {
      return {
        text: translatePropertyType(item),
        value: item,
        key: item
      };
    }),
    'text'
  );
