import { faArrowUp, faArrowDown, faMinus } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { FinancialReportReadView } from '../../../GeneratedServices';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { formatCurrency, formatNumber, getComparedPercentage } from '../../../Utils/number';
import NumberFormater from '../../NumberFormater/NumberFormater';
import KpiCard from '../../KPIs/KPICard/KpiCard';
import PieCard from '../../PieCard/PieCard';
import { useTranslation } from 'react-i18next';
interface Props {
  financialStatistics: FinancialReportReadView[];
  areStatisticsPerArea: boolean;
  totalCapexAndTi: number;
  estimatedCapexAndTi: number;
}

const FinancialStatisticsKPIs: React.FC<Props> = (props) => {
  const { t } = useTranslation(['financialStatistics', 'common']);

  const {
    estimatedTotalCost,
    estimatedTotalIncome,
    estimatedTotalNoi,
    totalCapexAndTi,
    totalCost,
    totalIncome,
    totalNoi,
    totalElectricity,
    totalHeating,
    totalCooling,
    totalWater,
    totalUtility,
    estimatedUtility,
    totalMaintenance,
    estimatedMaintenance,
    totalRepairs,
    totalPeriodicMaintenance
  } = React.useMemo(() => {
    let totalCost = 0;
    let totalIncome = 0;
    let totalNoi = 0;

    let estimatedTotalCost = 0;
    let estimatedTotalIncome = 0;
    let estimatedTotalNoi = 0;

    let totalCapexAndTi = 0;

    let totalElectricity = 0;
    let totalHeating = 0;
    let totalCooling = 0;
    let totalWater = 0;

    let totalUtility = 0;
    let estimatedUtility = 0;

    let totalMaintenance = 0;
    let estimatedMaintenance = 0;
    let totalRepairs = 0;
    let totalPeriodicMaintenance = 0;

    props.financialStatistics.forEach((item) => {
      totalCost += item.totalCosts.actual;
      totalIncome += item.totalIncome.actual;
      totalNoi += item.NOI.actual;
      totalCapexAndTi += item.capexAndTi.actual;

      estimatedTotalCost += item.totalCosts.planned;
      estimatedTotalIncome += item.totalIncome.planned;
      estimatedTotalNoi += item.NOI.planned;

      totalElectricity += item.electricity.actual;
      totalHeating += item.heating.actual;
      totalCooling += item.cooling.actual;
      totalWater += item.water.actual;

      totalUtility += item.electricity.actual;
      totalUtility += item.heating.actual;
      totalUtility += item.cooling.actual;
      totalUtility += item.water.actual;

      estimatedUtility += item.electricity.planned;
      estimatedUtility += item.heating.planned;
      estimatedUtility += item.cooling.planned;
      estimatedUtility += item.water.planned;

      totalMaintenance += item.maintenance.actual;
      estimatedMaintenance += item.maintenance.planned;
      totalRepairs += item.repairs.actual;
      totalPeriodicMaintenance += item.periodicMaintenance.actual;
    });

    // The costs are negative values, but to present them in charts we need to
    // turn them into the positive values. The caveat is that sometimes cost
    // can also be a positive number (that's when landlord actually gets money
    // for the given category instead of paying them). Such values we don't
    // want to display in charts because it is meaningless to compare them with
    // the actual costs.
    totalElectricity = totalElectricity < 0 ? -totalElectricity : 0;
    totalHeating = totalHeating < 0 ? -totalHeating : 0;
    totalCooling = totalCooling < 0 ? -totalCooling : 0;
    totalWater = totalWater < 0 ? -totalWater : 0;
    totalRepairs = totalRepairs < 0 ? -totalRepairs : 0;
    totalPeriodicMaintenance = totalPeriodicMaintenance < 0 ? -totalPeriodicMaintenance : 0;

    return {
      totalCost,
      totalIncome,
      totalNoi,
      totalCapexAndTi,
      estimatedTotalCost,
      estimatedTotalIncome,
      estimatedTotalNoi,
      totalElectricity,
      totalHeating,
      totalCooling,
      totalWater,
      totalUtility,
      estimatedUtility,
      totalMaintenance,
      estimatedMaintenance,
      totalRepairs,
      totalPeriodicMaintenance
    };
  }, [props.financialStatistics]);

  // These values are for charts only. See caveat regarding the components
  // being negative/positive above. We still want to compare complete values,
  // but totals on the charts should be sum of the displayed components.
  const totalUtilityForChart = totalElectricity + totalHeating + totalCooling + totalWater;
  const totalMaintenanceForChart = totalRepairs + totalPeriodicMaintenance;

  const comparedIncome = getComparedPercentage(totalIncome, estimatedTotalIncome);

  const comparedNoi = getComparedPercentage(totalNoi, estimatedTotalNoi);
  const comparedUtility = getComparedPercentage(totalUtility, estimatedUtility);
  const comparedMaintenance = getComparedPercentage(totalMaintenance, estimatedMaintenance);

  const negatedTotalCost = totalCost * -1;
  const negatedEstimatedCost = estimatedTotalCost * -1;
  const comparedCost = getComparedPercentage(negatedTotalCost, negatedEstimatedCost);

  const negatedAllTimeTotalCapexAndTi = props.totalCapexAndTi * -1;
  const negatedAllTimeEstimatedCapexAndTi = props.estimatedCapexAndTi * -1;
  const negatedCapexAndTi = totalCapexAndTi * -1;

  const commonFormattingProps: {
    unit?: string | undefined;
    forceCurrency?: 'SEK' | 'KSEK' | undefined;
  } = {
    unit: props.areStatisticsPerArea ? t('common:sekSqm') : undefined,
    forceCurrency: props.areStatisticsPerArea ? 'SEK' : 'KSEK'
  };

  return (
    <>
      <Grid.Column widescreen={4} largeScreen={5} computer={6} tablet={10}>
        <Grid>
          <Grid.Column width={16}>
            <PieCard
              title={'Media'}
              extra={
                comparedUtility ? (
                  <NumberFormater
                    value={comparedUtility}
                    suffix={t('common:vsBusinessPlan')}
                    additionalProps={{ decimalScale: 1 }}
                  />
                ) : (
                  t('common:noBusinessPlanInformation')
                )
              }
              data={[
                {
                  id: 'electricity',
                  label: t('financialStatistics:electricity'),
                  ...formatCurrency(totalElectricity, { ...commonFormattingProps })
                },
                {
                  id: 'heating',
                  label: t('financialStatistics:heat'),
                  ...formatCurrency(totalHeating, { ...commonFormattingProps })
                },
                {
                  id: 'cooling',
                  label: t('financialStatistics:cooling'),
                  ...formatCurrency(totalCooling, { ...commonFormattingProps })
                },
                {
                  id: 'water',
                  label: t('financialStatistics:waterAndSewage'),
                  ...formatCurrency(totalWater, { ...commonFormattingProps })
                }
              ]}
              extraColor={comparedUtility ? (comparedUtility > 0 ? colors.red : colors.green) : colors.grey}
              extraIcon={comparedUtility ? (comparedUtility < 0 ? faArrowDown : faArrowUp) : faMinus}
              centerText={
                totalUtilityForChart !== 0
                  ? {
                      value: formatNumber(
                        parseFloat(
                          formatCurrency(totalUtilityForChart, {
                            ...commonFormattingProps
                          }).value.toFixed(props.areStatisticsPerArea ? 1 : 0)
                        )
                      ),
                      suffix: props.areStatisticsPerArea ? t('common:sekSqm') : 'KSEK'
                    }
                  : { value: t('common:noData') }
              }
            />
          </Grid.Column>

          <Grid.Column width={16}>
            <PieCard
              title={t('financialStatistics:maintenance')}
              extra={
                comparedMaintenance ? (
                  <NumberFormater
                    value={comparedMaintenance}
                    suffix={t('common:vsBusinessPlan')}
                    additionalProps={{ decimalScale: 1 }}
                  />
                ) : (
                  t('common:noBusinessPlanInformation')
                )
              }
              data={[
                {
                  id: 'repairs',
                  label: t('financialStatistics:repairs'),
                  ...formatCurrency(totalRepairs, { ...commonFormattingProps })
                },
                {
                  id: 'periodic',
                  label: t('financialStatistics:periodicMaintenance'),
                  ...formatCurrency(totalPeriodicMaintenance, { ...commonFormattingProps })
                }
              ]}
              extraColor={comparedMaintenance ? (comparedMaintenance > 0 ? colors.red : colors.green) : colors.grey}
              extraIcon={comparedMaintenance ? (comparedMaintenance < 0 ? faArrowDown : faArrowUp) : faMinus}
              centerText={
                totalMaintenanceForChart !== 0
                  ? {
                      value: formatNumber(
                        parseFloat(
                          formatCurrency(totalMaintenanceForChart, { ...commonFormattingProps }).value.toFixed(
                            props.areStatisticsPerArea ? 1 : 0
                          )
                        )
                      ),
                      suffix: props.areStatisticsPerArea ? t('common:sekSqm') : 'KSEK'
                    }
                  : { value: t('common:noData') }
              }
            />
          </Grid.Column>
        </Grid>
      </Grid.Column>

      <Grid.Column widescreen={3} largeScreen={4} computer={16} tablet={6}>
        <Grid>
          <Grid.Column widescreen={16} largeScreen={16} computer={5}>
            <KpiCard
              title={t('common:income')}
              value={
                <NumberFormater
                  value={formatCurrency(totalIncome, { ...commonFormattingProps }).value}
                  additionalProps={{ decimalScale: 0 }}
                />
              }
              valueSuffix={formatCurrency(totalIncome, { ...commonFormattingProps }).suffix}
              extra={
                comparedIncome ? (
                  <NumberFormater
                    value={comparedIncome}
                    suffix={t('common:vsBusinessPlan')}
                    additionalProps={{ decimalScale: 1 }}
                  />
                ) : (
                  t('common:noBusinessPlanInformation')
                )
              }
              extraColor={comparedIncome !== undefined ? (comparedIncome < 0 ? colors.red : colors.green) : colors.grey}
              extraIcon={comparedIncome !== undefined ? (comparedIncome < 0 ? faArrowDown : faArrowUp) : faMinus}
              includeExtra
            />
          </Grid.Column>

          <Grid.Column widescreen={16} largeScreen={16} computer={5}>
            <KpiCard
              title={t('common:costs')}
              value={
                <>
                  <NumberFormater
                    value={formatCurrency(negatedTotalCost, { ...commonFormattingProps }).value}
                    additionalProps={{ decimalScale: 0, allowNegative: true }}
                  />
                </>
              }
              valueSuffix={formatCurrency(negatedTotalCost, { ...commonFormattingProps }).suffix}
              extra={
                comparedCost ? (
                  <NumberFormater
                    value={comparedCost}
                    suffix={t('common:vsBusinessPlan')}
                    additionalProps={{ decimalScale: 1 }}
                  />
                ) : (
                  t('common:noBusinessPlanInformation')
                )
              }
              extraColor={comparedCost !== undefined ? (comparedCost > 0 ? colors.red : colors.green) : colors.grey}
              extraIcon={comparedCost !== undefined ? (comparedCost < 0 ? faArrowDown : faArrowUp) : faMinus}
              includeExtra
            />
          </Grid.Column>

          <Grid.Column widescreen={16} largeScreen={16} computer={6}>
            <KpiCard
              title={t('common:netOperatingIncome')}
              value={
                <NumberFormater
                  value={formatCurrency(totalNoi, { ...commonFormattingProps }).value}
                  additionalProps={{ allowNegative: true, decimalScale: 0 }}
                />
              }
              valueSuffix={formatCurrency(totalIncome, { ...commonFormattingProps }).suffix}
              extra={
                comparedNoi ? (
                  <NumberFormater
                    value={comparedNoi}
                    suffix={t('common:vsBusinessPlan')}
                    additionalProps={{ decimalScale: 1 }}
                  />
                ) : (
                  t('common:noBusinessPlanInformation')
                )
              }
              extraColor={comparedNoi !== undefined ? (comparedNoi < 0 ? colors.red : colors.green) : colors.grey}
              extraIcon={comparedNoi !== undefined ? (comparedNoi < 0 ? faArrowDown : faArrowUp) : faMinus}
              includeExtra
            />
          </Grid.Column>

          <Grid.Column widescreen={16} largeScreen={16} computer={5}>
            <KpiCard
              title={t('common:capexAndTenantImprovement')}
              value={
                <NumberFormater
                  value={formatCurrency(negatedCapexAndTi, { ...commonFormattingProps }).value}
                  additionalProps={{ allowNegative: true, decimalScale: 0 }}
                />
              }
              valueSuffix={formatCurrency(negatedCapexAndTi, { ...commonFormattingProps }).suffix}
              extra={
                <>
                  <NumberFormater
                    value={formatCurrency(negatedAllTimeTotalCapexAndTi, { forceCurrency: 'KSEK' }).value}
                    suffix={formatCurrency(negatedAllTimeTotalCapexAndTi, { forceCurrency: 'KSEK' }).suffix}
                    additionalProps={{ decimalScale: 0, allowNegative: true }}
                  />{' '}
                  /{' '}
                  <NumberFormater
                    value={formatCurrency(negatedAllTimeEstimatedCapexAndTi, { forceCurrency: 'KSEK' }).value}
                    suffix={formatCurrency(negatedAllTimeEstimatedCapexAndTi, { forceCurrency: 'KSEK' }).suffix}
                    additionalProps={{ decimalScale: 0, allowNegative: true }}
                  />
                </>
              }
              extraColor={negatedAllTimeTotalCapexAndTi > negatedAllTimeEstimatedCapexAndTi ? colors.red : colors.green}
              includeExtra
            />
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </>
  );
};

export default FinancialStatisticsKPIs;
