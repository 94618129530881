import { RangeView } from '../Services/types';

/**
 * Returns a string representing the number formatted according to the sv-se locale.
 */
export const formatNumber = (value: number) => {
  return new Intl.NumberFormat('sv-SE').format(value);
};

/**
 * Returns value in minutes
 */
export const combineHoursAndMinutes = (hours: number, minutes: number) => {
  let tempHourMinutes: number = 0;
  let tempMinutes: number = 0;
  if (!isNaN(hours)) tempHourMinutes = hours * 60;
  if (!isNaN(minutes)) tempMinutes = minutes;
  return tempHourMinutes + tempMinutes;
};

/**
 * Returns minutes value in HH:MM format
 */
export const formatMinutes = (minutes: number) => {
  let min = minutes % 60;
  let hour = (minutes - min) / 60;
  return hour.toString() + ':' + min.toString().padStart(2, '0');
};

/**
 * Format currency amount and return it and a unit prefixed with the unit
 * prefix.
 *
 * @param valueInOre - Amount in öre.
 * @param options - Options.
 */
export const formatCurrency = (
  valueInOre: number,
  { unit = 'SEK', forceCurrency = undefined }: { unit?: string; forceCurrency?: 'SEK' | 'KSEK' } = {}
) => {
  const valueInSEK = valueInOre / 100;

  if (forceCurrency === 'SEK') {
    return { value: valueInSEK, suffix: ` ${unit}` };
  }
  if (forceCurrency === 'KSEK') {
    return { value: valueInSEK / 1_000, suffix: ` K${unit}` };
  }

  if (valueInSEK < 1_000_000) {
    return { value: valueInSEK, suffix: ` ${unit}` };
  } else if (valueInSEK > 1_000_000_000) {
    return { value: valueInSEK / 1_000_000, suffix: ` M${unit}` };
  } else {
    return { value: valueInSEK / 1_000, suffix: ` K${unit}` };
  }
};

/**
 * Reformat currency values to return a value in SEK or KSEK if formatedToKSEK flag is true
 * @param value - Value
 * @param formatedToKSEK - force format to KSEK
 */
export const getFormatedCurrencyValue = (value: number, formatedToKSEK?: boolean) => {
  const sekValue = value / 100;
  if (formatedToKSEK) return sekValue / 1000;
  else return sekValue;
};

/**
 * Tries to format two number/undefined values into a RangeView and if it fails returns undefined
 * @param min - Minimum value
 * @param max - Maximum value
 */
export const convertToRange = (min?: number, max?: number): RangeView | undefined => {
  if (min && max) return { min, max };
  else return undefined;
};

/**
 * Returns the percentage relative change between two compared numbers.
 * Returns undefined if the reference value is zero.
 *
 * @see https://en.wikipedia.org/wiki/Relative_change_and_difference
 *
 * @param value - Value
 * @param referenceValue - Reference value
 */
export const getComparedPercentage = (value: number, referenceValue: number) => {
  if (referenceValue === 0) return undefined;
  return ((value - referenceValue) / Math.abs(referenceValue)) * 100;
};

/**
 * Returns the number or 0 for null or undefined values.
 *
 * @param value - Value
 */
export const numberOrZero = (value: number | null | undefined) => {
  return value ?? 0;
};

/**
 * Reformat currency values to return a value in Öre
 *
 * @param value - Value
 */
export const toOre = (value: number) => {
  return value ? value * 100 : 0;
};

/**
 * Returns the monthly or yearly value.
 *
 * @param value - Yearly value
 */
export const toMonthlyOrYearly = (amount: number, monthly: boolean) => {
  if (monthly) return amount / 12;
  return amount;
};
