import { AxiosRequestConfig } from 'axios';
import { DropdownItemProps } from 'semantic-ui-react';
import { BAM_API_BASE_URL } from '../config';
import { http } from '../Http/Http';
import { localeCompareSv } from '../Utils/string';
import {
  FileCreateView,
  FileArchiveParams,
  FileInfoListParams,
  FileInfoListView,
  FileInfoReadView,
  FileInfoUpdateView,
  FileSubType,
  FileType,
  FileUrlParams,
  ThumbnailUrlParams,
  translateFileSubType,
  translateFileType
} from './FileService.types';

export const getFiles = async (params: FileInfoListParams, config?: AxiosRequestConfig): Promise<FileInfoListView> => {
  const { data } = await http.get<FileInfoListView>(`${BAM_API_BASE_URL}/v1/files`, {
    ...config,
    params
  });
  return data;
};

export const getFilesArchiveUrl = async (params: FileArchiveParams, config?: AxiosRequestConfig): Promise<string> => {
  const { data } = await http.get<string>(`${BAM_API_BASE_URL}/v1/files/archive-url`, {
    ...config,
    params
  });
  return data;
};

export const getFile = async (id: string, config?: AxiosRequestConfig): Promise<FileInfoReadView> => {
  const { data } = await http.get<FileInfoReadView>(`${BAM_API_BASE_URL}/v1/files/${id}`, config);
  return data;
};

export const getFileBlob = async (id: string, config?: AxiosRequestConfig): Promise<Blob> => {
  const { data } = await http.get<Blob>(`${BAM_API_BASE_URL}/v1/files/${id}/blob`, {
    ...config,
    headers: {
      accept: 'application/octet-stream'
    },
    responseType: 'blob'
  });
  return data;
};

export const getFileThumbnail = async (id: string, config?: AxiosRequestConfig): Promise<Blob> => {
  const { data } = await http.get<Blob>(`${BAM_API_BASE_URL}/v1/files/${id}/thumbnail`, {
    ...config,
    headers: {
      accept: 'application/octet-stream'
    },
    responseType: 'blob'
  });
  return data;
};

export const getFileBlobUrl = async (params: FileUrlParams, config?: AxiosRequestConfig): Promise<string> => {
  const { id, ...otherParams } = params;
  const { data } = await http.get<string>(`${BAM_API_BASE_URL}/v1/files/${params.id}/blob-url`, {
    params: { ...otherParams },
    ...config
  });
  return data;
};

export const getFileThumbnailUrl = async (params: ThumbnailUrlParams, config?: AxiosRequestConfig): Promise<string> => {
  const { id, ...otherParams } = params;
  const { data } = await http.get<string>(`${BAM_API_BASE_URL}/v1/files/${id}/thumbnail-url`, {
    params: { ...otherParams },
    ...config
  });
  return data;
};

export const uploadFile = async (payload: FileCreateView, config: AxiosRequestConfig) => {
  const { data } = await http.post<FileInfoReadView>(`${BAM_API_BASE_URL}/v1/files`, payload, config);
  return data;
};

export const updateFile = async (id: string, payload: FileInfoUpdateView, config?: AxiosRequestConfig) => {
  const { data } = await http.patch<FileInfoReadView>(`${BAM_API_BASE_URL}/v1/files/${id}`, payload, config);
  return data;
};

export const deleteFile = async (id: string, config: AxiosRequestConfig) => {
  return await http.delete(`${BAM_API_BASE_URL}/v1/files/${id}`, config);
};

export const getSubTypesForType = (type: FileType): FileSubType[] => {
  if (!type) {
    return [];
  }

  switch (type) {
    case FileType.BUILDING_PERMITS:
      return [];
    case FileType.DD_REPORT:
      return [];
    case FileType.DRAWING:
      return [
        FileSubType.FLOOR_PLAN,
        FileSubType.CONSTRUCTION_DRAWING,
        FileSubType.INSTALLATION_DRAWING,
        FileSubType.GROUND_DRAWING,
        FileSubType.BUILDING_PERMIT_DRAWING,
        FileSubType.RECORD_DRAWING,
        FileSubType.ZONING_PLAN,
        FileSubType.FIRE_PROTECTION
      ];
    case FileType.EASEMENT:
      return [];
    case FileType.INSPECTION:
      return [
        FileSubType.OVK,
        FileSubType.ELEVATOR,
        FileSubType.DOOR,
        FileSubType.LIFT_TABLE,
        FileSubType.LOADING_DOCK,
        FileSubType.AUDIT_INSPECTION_FIRE_ALARM,
        FileSubType.AUDIT_INSPECTION_SPRINKLER,
        FileSubType.ELECTRICITY_AUDIT,
        FileSubType.REFRIGERANT,
        FileSubType.OIL_SEPARATOR,
        FileSubType.SBA_SYSTEMATIC_FIRE_PROTECTION_WORK,
        FileSubType.PESTS,
        FileSubType.ENERGY_DECLARATION,
        FileSubType.PRESSURE_VESSELS,
        FileSubType.ENVIRONMENTAL_SURVEY,
        FileSubType.SHELTER
      ];
    case FileType.INSURANCE:
      return [];
    case FileType.LEASE:
      return [FileSubType.AGREEMENT, FileSubType.ADDITION];
    case FileType.LEASEHOLD_AGREEMENT:
      return [];
    case FileType.LAND_AGREEMENT:
      return [];
    case FileType.NEGOTIATION_AGREEMENT:
      return [];
    case FileType.OPERATING_SERVICING_AGREEMENTS:
      return [
        FileSubType.WINTER_MAINTENANCE,
        FileSubType.LEASEHOLD,
        FileSubType.AGREEMENT_PORT,
        FileSubType.CONTRACT_PRESSURE_VESSEL,
        FileSubType.AGREEMENT_OIL_SEPARATOR,
        FileSubType.FIRE_ALARM_TRANSMISSION,
        FileSubType.LOCK_SCHEME,
        FileSubType.ELEVATOR,
        FileSubType.PESTS,
        FileSubType.LAND_MANAGEMENT,
        FileSubType.TREATMENT_PLANT,
        FileSubType.COOLING
      ];
    case FileType.OTHER:
      return [];
    case FileType.PROPERTY_PICTURE:
      return [FileSubType.PROPERTY_PICTURE, FileSubType.INSIDE];
    case FileType.PROJECT:
      return [FileSubType.ORDER, FileSubType.OFFER, FileSubType.FINAL_INSPECTION];
  }
};

export const resolveTypeDropdownItems = (): DropdownItemProps[] => {
  return (
    Object.values(FileType)
      .map((type) => ({
        key: type,
        text: translateFileType(type),
        value: type
      }))
      .sort((a, b) => localeCompareSv(a.text, b.text)) ?? []
  );
};

export const resolveSubTypeDropdownItems = (type: FileType): DropdownItemProps[] => {
  return (
    getSubTypesForType(type)
      .map((subType) => ({
        key: subType,
        text: translateFileSubType(subType),
        value: subType.toString()
      }))
      .sort((a, b) => localeCompareSv(a.text, b.text)) ?? []
  );
};

export const subTypesExist = (type?: FileType) => {
  return type !== undefined && getSubTypesForType(type).length > 0;
};

interface FileUrlType {
  url: string;
  timestamp: number;
}

export async function getCachedFileThumbnailUrl(
  params: ThumbnailUrlParams,
  config: AxiosRequestConfig
): Promise<string> {
  const cachedFileUrlObjectString = sessionStorage.getItem(params.id);
  const cachedFileUrlObject: FileUrlType = cachedFileUrlObjectString ? JSON.parse(cachedFileUrlObjectString) : null;

  if (cachedFileUrlObject) {
    const delta = new Date().getTime() - cachedFileUrlObject.timestamp;
    // Don't refetch urls for thumbnails which are under 20 hours old
    if (delta < 20 * 60 * 60 * 1000) {
      return cachedFileUrlObject.url;
    }
  }

  const url = await getFileThumbnailUrl(params, config);

  sessionStorage.setItem(params.id, JSON.stringify({ url: url, timestamp: new Date().getTime() }));
  return url;
}
