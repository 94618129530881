import React from 'react';
import { Grid } from 'semantic-ui-react';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';
import AreaKpi from '../LeaseSummary/KPIs/AreaKpi';
import AreaVacancyKpi from './AreaVacancyKpi';
import YearlyRollingCapexAndTiKpi from './YearlyRollingCapexAndTiKpi';
import YearlyRollingCostKpi from './YearlyRollingCostKpi';
import YearlyRollingEnergyKpi from './YearlyRollingEnergyKpi';
import YearlyRollingIncomeKpi from './YearlyRollingIncomeKpi';
import LeaseCountKpi from './LeaseCountKpi';
import YearlyRollingNoiKpi from './YearlyRollingNoiKpi';
import TotalRentKpi from './TotalRentKpi';
import WaultKpi from './WaultKpi';
import CoverageRatioKpi from './CoverageRatioKpi';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}

const SummaryKPIs: React.FC<Props> = (props) => {
  return (
    <>
      <Grid.Column largeScreen={4} widescreen={3} computer={5} tablet={16}>
        <Grid>
          <Grid.Column widescreen={16} largeScreen={16} computer={16} tablet={8}>
            <YearlyRollingIncomeKpi {...props} />
          </Grid.Column>
          <Grid.Column widescreen={16} largeScreen={16} computer={16} tablet={8}>
            <YearlyRollingCostKpi {...props} />
          </Grid.Column>
          <Grid.Column widescreen={16} largeScreen={16} computer={16} tablet={8}>
            <YearlyRollingNoiKpi {...props} />
          </Grid.Column>
          <Grid.Column widescreen={16} largeScreen={16} computer={16} tablet={8}>
            <YearlyRollingCapexAndTiKpi {...props} />
          </Grid.Column>
        </Grid>
      </Grid.Column>

      <Grid.Column largeScreen={4} widescreen={3} computer={16} tablet={16}>
        <Grid>
          <Grid.Column widescreen={8} largeScreen={8} computer={4} tablet={4}>
            <TotalRentKpi {...props} />
          </Grid.Column>
          <Grid.Column widescreen={8} largeScreen={8} computer={4} tablet={4}>
            <AreaKpi {...props} />
          </Grid.Column>

          <Grid.Column widescreen={16} largeScreen={16} computer={4} tablet={8}>
            <LeaseCountKpi {...props} />
          </Grid.Column>
          <Grid.Column widescreen={16} largeScreen={16} computer={4} tablet={8}>
            <CoverageRatioKpi {...props} />
          </Grid.Column>

          <Grid.Column widescreen={16} largeScreen={16} computer={4} tablet={8}>
            <AreaVacancyKpi {...props} />
          </Grid.Column>
          <Grid.Column widescreen={16} largeScreen={16} computer={4} tablet={8}>
            <WaultKpi {...props} />
          </Grid.Column>

          <Grid.Column widescreen={16} largeScreen={16} computer={4} tablet={8}>
            <YearlyRollingEnergyKpi {...props} />
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </>
  );
};

export default SummaryKPIs;
