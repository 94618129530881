import { faArrowDown, faArrowUp, faMinus } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import { formatCurrency, getComparedPercentage } from '../../Utils/number';
import NumberFormater from '../NumberFormater/NumberFormater';
import KpiCard, { KPIStatus } from './KPICard/KpiCard';
import colors from '../../Utils/Colors/Colors.module.scss';
import { YTQStatisticsReadView } from '../../GeneratedServices';
import { statisticsApi } from '../../Http/Http';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}
const YearlyRollingNoiKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [yearlyNoiStatistics, setYearlyNoiStatistics] = React.useState<YTQStatisticsReadView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceReadNOIYTQStatistics(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setYearlyNoiStatistics(data);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();
    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  const formattedValue = yearlyNoiStatistics
    ? formatCurrency(yearlyNoiStatistics?.value, { forceCurrency: 'KSEK' })
    : undefined;
  const comparedNOI = yearlyNoiStatistics
    ? getComparedPercentage(yearlyNoiStatistics.value, yearlyNoiStatistics.businessPlanValue)
    : 0;
  const formattedValuePerArea = yearlyNoiStatistics
    ? formatCurrency(yearlyNoiStatistics?.valuePerArea, { forceCurrency: 'SEK', unit: t('sekSqm') })
    : undefined;

  const hasBussinesPlanValue = yearlyNoiStatistics?.businessPlanValue;
  return (
    <KpiCard
      title={t('yearlyRollingNetOperatingIncome')}
      value={
        <NumberFormater value={formattedValue?.value} additionalProps={{ decimalScale: 0, allowNegative: true }} />
      }
      valueSuffix={formattedValue?.suffix}
      additionalValue={
        <NumberFormater
          value={formattedValuePerArea?.value}
          additionalProps={{ decimalScale: 0, allowNegative: true }}
        />
      }
      additionalValueSuffix={formattedValuePerArea?.suffix}
      extra={
        hasBussinesPlanValue ? (
          <NumberFormater value={comparedNOI} suffix={t('vsBusinessPlan')} />
        ) : (
          t('noBusinessPlanInformation')
        )
      }
      extraColor={hasBussinesPlanValue ? (comparedNOI && comparedNOI > 0 ? colors.green : colors.red) : colors.grey}
      extraIcon={hasBussinesPlanValue ? (comparedNOI && comparedNOI > 0 ? faArrowUp : faArrowDown) : faMinus}
      includeExtra
      status={status}
      retry={reload}
    />
  );
};

export default YearlyRollingNoiKpi;
