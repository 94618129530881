import React from 'react';
import { Input, Popup } from 'semantic-ui-react';
import { renderValidationErrors } from '../../Utils/FieldValidationErrorMessage';
import { FieldValidationResult } from '../../Utils/validation';
import styles from './EditableTableNumberCell.module.scss';

interface Props {
  displayValue: string | undefined;
  value: string | undefined;
  placeholder: string;
  onValueChange: (value: string) => void;
  isInEditMode?: boolean;
  validationResult?: FieldValidationResult;
}

const EditableTableStringCell: React.FC<Props> = (props) => {
  const { displayValue, value, placeholder, onValueChange, isInEditMode, validationResult } = props;

  if (isInEditMode)
    return (
      <Popup
        content={renderValidationErrors(validationResult)}
        disabled={!validationResult?.error}
        openOnTriggerClick
        trigger={
          <Input
            className={styles.input}
            error={validationResult?.error}
            placeholder={placeholder}
            value={value ?? ''}
            onChange={(e, d) => {
              onValueChange(d.value);
            }}
          />
        }
      />
    );
  else return <>{displayValue}</>;
};

export default EditableTableStringCell;
