import { notEmpty } from './array';

/**
 * If value is a string and contains a valid natural number, then return that
 * string converted to a number, otherwise return undefined.
 * @param value - Value to parse.
 */
export const parseNatural = (value: string | null | undefined): number | undefined => {
  return value != null && /^[1-9]\d*$/.test(value) ? +value : undefined;
};

/**
 * If value is a string and contains a valid whole number, then return that
 * string converted to a number, otherwise return undefined.
 * @param value - Value to parse.
 */
export const parseWhole = (value: string | null | undefined): number | undefined => {
  return value != null && /^0|[1-9]\d*$/.test(value) ? +value : undefined;
};

/**
 * If value is a string and contains a valid rational number, then return that
 * string converted to a number, otherwise return undefined.
 * @param value - Value to parse.
 */
export const parseRational = (value: string | null | undefined): number | undefined => {
  return value != null && /^0|[1-9]\d*(?:\.\d+)?$/.test(value) ? +value : undefined;
};

/**
 * If value is a string and contains a valid ISO date, then return that
 * string, otherwise return undefined.
 * @param value - Value to parse.
 */
export const parseDateString = (value: string | null | undefined): string | undefined => {
  return value != null && /^\d{4}-\d{2}-\d{2}$/.test(value) ? value : undefined;
};

/**
 * If value is a string and contains a valid boolean value, then return that
 * string converted to a boolean, otherwise return undefined.
 * @param value - Value to parse.
 */
export const parseBool = (value: string | null | undefined): boolean | undefined => {
  return value != null && /^true|false$/.test(value) ? value === 'true' : undefined;
};

/**
 * If value is a string and is one of the valid values, then return that
 * value, otherwise return undefined.
 * @param value - Value to parse.
 * @param allowedValues - List of allowed values.
 */
export const parseEnum = (value: string | null | undefined, allowedValues: string[]): string | undefined => {
  return value != null && allowedValues.includes(value) ? value : undefined;
};

/**
 * If value is a string and contains valid polygon string format, then return that
 * value, otherwise return undefined.
 * @param value - Value to parse.
 */
export const parsePolygon = (value: string | null | undefined): google.maps.LatLngLiteral[] | undefined => {
  if (value) {
    const points = value.split('-');
    const polygon: google.maps.LatLngLiteral[] = points.map((item) => {
      const [lat, lng] = item.split(',');
      return {
        lat: parseWhole(lat)!,
        lng: parseWhole(lng)!
      };
    });
    return polygon;
  }
  return undefined;
};

/**
 * Converts a polygon point array to a string
 * @param polygon - Polygon to convert.
 */
export const convertPolygonToString = (polygon: google.maps.LatLngLiteral[]): string => {
  let polygonString = '';
  polygon.forEach((item, index) => {
    const isLast = index === polygon.length - 1;
    polygonString = polygonString.concat(item.lat.toString() + ',' + item.lng.toString());
    polygonString = !isLast ? polygonString.concat('-') : polygonString;
  });

  return polygonString;
};

/**
 * If value is a string and is one of the valid values, then return that
 * value, otherwise return undefined.
 * @param value - Value to parse.
 * @param allowedValues - List of allowed values.
 */
export const parseEnumList = (value: string | null | undefined, allowedValues: string[]): string[] | undefined => {
  const enumValues = value?.split(',');

  return enumValues ? enumValues?.map((item) => parseEnum(item, allowedValues)).filter(notEmpty) : undefined;
};
