import React from 'react';
import { Tab, TabProps } from 'semantic-ui-react';
import { useIntersection } from '../../Utils/hooks';
import OverflowButton from '../ScrollableBar/OverflowButton';
import styles from './ScrollableTabs.module.scss';

interface Props extends TabProps {}

const getClosestMenu = (element: HTMLDivElement) => {
  const menuCollection = element.getElementsByClassName('menu');

  const menus = Array.from(menuCollection);

  return menus[0];
};
const ScrollableTabs: React.FC<Props> = (props) => {
  const tabWrapperRef = React.useRef<HTMLDivElement>(null);
  const [firstElement, setFirstElement] = React.useState<HTMLElement>();
  const [lastElement, setLastElement] = React.useState<HTMLElement>();

  const firstVisible = useIntersection(firstElement, tabWrapperRef);
  const lastVisible = useIntersection(lastElement, tabWrapperRef);

  React.useEffect(() => {
    if (tabWrapperRef.current) {
      //The wrapping div can contain multiple menus, so therefore we need to select the first menu and its items to get the first and last element.
      const closestMenu = getClosestMenu(tabWrapperRef.current);
      const menuItemsCollection = closestMenu.getElementsByClassName('item');

      if (menuItemsCollection) {
        const menuItems = Array.from(menuItemsCollection);

        setFirstElement(menuItems[0] as HTMLElement);
        setLastElement(menuItems[menuItemsCollection.length - 1] as HTMLElement);
      }
    }
  }, [tabWrapperRef]);

  const scroll = (direction: 'left' | 'right') => {
    if (tabWrapperRef.current) {
      const closestMenu = getClosestMenu(tabWrapperRef.current);

      if (direction === 'left') {
        closestMenu.scrollLeft -= 200;
      } else {
        closestMenu.scrollLeft += 200;
      }
    }
  };

  return (
    <div ref={tabWrapperRef} className={styles.wrapper}>
      <OverflowButton direction="left" onClick={() => scroll('left')} visible={!firstVisible} />
      <Tab
        {...props}
        menu={{ secondary: true, pointing: true, size: 'large', className: styles.horizontalTopMenu, ...props.menu }}
      />
      <OverflowButton direction="right" onClick={() => scroll('right')} visible={!lastVisible} />
    </div>
  );
};

export default ScrollableTabs;
