import React from 'react';
import { useDidMountEffect } from '../../../Utils/hooks';
import { SortDirection } from '../SortableTable';

interface SortableTableStateCTX {
  sortDirection: SortDirection | undefined;
  sortedColumnKey: string | undefined;
  handleSort: (columnKey?: string, sortDirection?: SortDirection) => void;
  setSortedColumnKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSortDirection: React.Dispatch<React.SetStateAction<SortDirection | undefined>>;
}

const initialState: SortableTableStateCTX = {
  sortDirection: undefined,
  sortedColumnKey: undefined,
  handleSort: () => {},
  setSortedColumnKey: () => {},
  setSortDirection: () => {}
};

export const sortableTableStateCTX = React.createContext<SortableTableStateCTX>(initialState);

type Props = {
  children: any;
  defaultSortDirection?: SortDirection;
  defaultSortedColumn?: string;
  handleSort: (columnKey?: string, sortDirection?: SortDirection) => void;
  shouldResetSort?: number;
};

const SortableTableState: React.FC<Props> = ({
  children,
  defaultSortDirection,
  defaultSortedColumn,
  handleSort,
  shouldResetSort
}) => {
  const [sortDirection, setSortDirection] = React.useState<SortDirection | undefined>(defaultSortDirection);
  const [sortedColumnKey, setSortedColumnKey] = React.useState<string | undefined>(defaultSortedColumn);

  useDidMountEffect(() => {
    if (shouldResetSort) {
      setSortDirection(undefined);
      setSortedColumnKey(undefined);
    }
  }, [shouldResetSort]);
  return (
    <sortableTableStateCTX.Provider
      value={{
        sortDirection,
        sortedColumnKey,
        handleSort,
        setSortedColumnKey,
        setSortDirection
      }}
    >
      {children}
    </sortableTableStateCTX.Provider>
  );
};

export default SortableTableState;
