import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { MembershipReadViewRoleEnum, PropertySubscriptionReadView } from '../../../../GeneratedServices';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';

interface Props {
  onClose: (deleted: boolean) => void;
  subscription: PropertySubscriptionReadView;
  isDeleting: boolean;
  deleteSubscription: (item: PropertySubscriptionReadView) => void;
}

const DeleteSubscriptionConfirmationModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'users']);

  const { currentUser } = React.useContext(globalStateCTX);

  const getMessage = () => {
    if (
      currentUser?.id === props.subscription.userId &&
      props.subscription.role === MembershipReadViewRoleEnum.Technician
    ) {
      return t('users:removeSelfFromPropertyMessage');
    } else {
      return t('users:removeUserFromPropertyMessage', { userName: props.subscription.name });
    }
  };
  return (
    <Modal open size="mini" closeIcon={!props.isDeleting} onClose={() => props.onClose(false)}>
      <Modal.Header>{t('users:removeUser')}</Modal.Header>
      <Modal.Content>{getMessage()}</Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => props.onClose(false)} disabled={props.isDeleting}>
          {t('common:cancel')}
        </Button>
        <Button
          positive
          onClick={() => props.deleteSubscription(props.subscription)}
          disabled={props.isDeleting}
          loading={props.isDeleting}
        >
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteSubscriptionConfirmationModal;
