import React from 'react';
import { Header, Modal, Image, Tab, Form, Button, Message, Label, Loader } from 'semantic-ui-react';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { getCachedFileThumbnailUrl } from '../../../../Services/FileService';
import styles from './BusinessPlanModal.module.scss';
import propertyPlaceholderImage from '../../../../Components/PropertyCard/images/placeholder.png';
import BusinessPlanLeasesTab from './BusinessPlanLeaseTab';
import BusinessPlanCostsTab from './BusinessPlanCostsTab';
import BusinessPlanIndexTab from './BusinessPlanIndexTab';
import { applyApiResponseValidationToFields, FieldValidationResult, validateDate } from '../../../../Utils/validation';
import { businessPlanApi, NetworkRequestStatus } from '../../../../Http/Http';
import { UserFriendlyApiResponse } from '../../../../Http/response-error';
import { useNonce } from '../../../../Utils/hooks';
import EditableControlCell from '../../../../Components/EditableTableCell/EditableControlCell';
import { hasPermittedWriteRoleInOrganisation } from '../../../../Utils/permissions';
import DatePicker from '../../../../Components/DatePicker/DatePicker';
import { BusinessPlanReadView, PropertyReadView } from '../../../../GeneratedServices';
import { useTranslation } from 'react-i18next';
import LoadError from '../../../../Components/LoadError';

export const datepickerPortalId = 'datepicker-portal';

interface Props {
  property: PropertyReadView;
  onClose: () => void;
  organisationId?: number;
}

enum Status {
  None,
  Saving
}

enum FormFieldId {
  businessPlanStartDate = 'startDate',
  businessPlanEndDate = 'endDate'
}

const BusinessPlanModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts', 'validation', 'properties']);
  const [shouldReloadBusinessPlanData, reloadBusinessPlanData] = useNonce();
  const { handleHttpErrors, currentUser, currentUserMemberships } = React.useContext(globalStateCTX);
  const [status, setStatus] = React.useState<Status>(Status.None);

  const [propertyPictureUrl, setPropertyPictureUrl] = React.useState<string>();

  const [businessPlan, setBusinessPlan] = React.useState<BusinessPlanReadView>();
  const [businessPlanStatus, setBusinessPlanStatus] = React.useState<NetworkRequestStatus>(
    NetworkRequestStatus.Loading
  );
  const [reloadBusinessPlanCount, incrementReloadBusinessPlanCount] = useNonce();

  const [businessPlanStartDate, setBusinessPlanStartDate] = React.useState<string | undefined | null>();
  const [startDateValidationResult, setStartDateValidationResult] = React.useState<FieldValidationResult>();
  const [businessPlanEndDate, setBusinessPlanEndDate] = React.useState<string | undefined | null>();
  const [endDateValidationResult, setEndDateValidationResult] = React.useState<FieldValidationResult>();

  const [shouldDatesEdit, setShouldDatesEdit] = React.useState<boolean>(false);

  const validateStartDate = React.useCallback(() => {
    if (!businessPlanStartDate) {
      setStartDateValidationResult(undefined);
    }
    return businessPlanStartDate
      ? validateDate(
          businessPlanStartDate.slice(undefined, 10),
          setStartDateValidationResult,
          t('validation:invalidDate')
        )
      : true;
  }, [businessPlanStartDate, t]);

  const validateEndDate = React.useCallback(() => {
    if (!businessPlanEndDate) {
      setEndDateValidationResult(undefined);
    }
    return businessPlanEndDate
      ? validateDate(businessPlanEndDate.slice(undefined, 10), setEndDateValidationResult, t('validation:invalidDate'))
      : true;
  }, [businessPlanEndDate, t]);

  React.useEffect(() => {
    validateStartDate();
  }, [validateStartDate]);

  React.useEffect(() => {
    validateEndDate();
  }, [validateEndDate]);

  const handleResponseValidationError = (resp: UserFriendlyApiResponse) => {
    const fieldIdMapping = [
      {
        formFieldId: FormFieldId.businessPlanStartDate,
        setStateFunc: setStartDateValidationResult
      },
      {
        formFieldId: FormFieldId.businessPlanEndDate,
        setStateFunc: setEndDateValidationResult
      }
    ];

    applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        if (props.property.pictureId) {
          const thumnbailUrl = await getCachedFileThumbnailUrl(
            {
              id: props.property.pictureId,
              download: false
            },
            { signal: abortController.signal }
          );
          setPropertyPictureUrl(thumnbailUrl);
        }
        setStatus(Status.None);
      } catch (error) {
        handleHttpErrors(error);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [props.property.pictureId, handleHttpErrors]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setBusinessPlanStatus(NetworkRequestStatus.Loading);

        const { data } = await businessPlanApi.businessPlanResourceList(
          {
            propertyId: props.property.id
          },
          {
            signal: abortController.signal
          }
        );

        if (data.records.length !== 0) {
          setBusinessPlan(data.records[0]);
          setBusinessPlanStartDate(data.records[0].startDate);
          setBusinessPlanEndDate(data.records[0].endDate);
        }
        setBusinessPlanStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setBusinessPlanStatus(NetworkRequestStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [props.property.id, handleHttpErrors, reloadBusinessPlanCount]);

  const saveBusinessDates = async () => {
    if (!validateStartDate() || !validateEndDate()) {
      return;
    }
    try {
      setStatus(Status.Saving);

      let newBusinessPlan: BusinessPlanReadView | undefined;
      if (businessPlan) {
        if (!businessPlanStartDate && !businessPlanEndDate) {
          await businessPlanApi.businessPlanResourceDelete({
            id: businessPlan.id
          });

          newBusinessPlan = undefined;
        } else {
          newBusinessPlan = (
            await businessPlanApi.businessPlanResourceUpdate({
              id: businessPlan.id,
              businessPlanUpdateView: {
                startDate: businessPlanStartDate ?? undefined,
                endDate: businessPlanEndDate ?? undefined
              }
            })
          ).data;
        }
      } else {
        newBusinessPlan = (
          await businessPlanApi.businessPlanResourceCreate({
            businessPlanCreateView: {
              propertyId: props.property.id,
              endDate: businessPlanEndDate!,
              startDate: businessPlanStartDate!
            }
          })
        ).data;
      }

      setBusinessPlan(newBusinessPlan);
      setShouldDatesEdit(false);
      reloadBusinessPlanData();
    } catch (error) {
      handleHttpErrors(error, {
        handleResponseValidationError
      });
    } finally {
      setStatus(Status.None);
    }
  };

  const panes = [
    {
      menuItem: t('common:leases'),
      render: () => (
        <Tab.Pane className={styles.paneContainer}>
          <BusinessPlanLeasesTab
            propertyId={props.property.id}
            businessPlanId={businessPlan?.id}
            shouldReload={shouldReloadBusinessPlanData}
            isPermitted={isPermitted}
            businessPlanStartDate={businessPlanStartDate}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('contracts:costsAndCAPEX'),
      render: () => (
        <Tab.Pane className={styles.paneContainer}>
          <BusinessPlanCostsTab
            propertyId={props.property.id}
            businessPlanId={businessPlan!.id}
            capex={businessPlan?.CAPEX}
            reloadBusinessPlan={incrementReloadBusinessPlanCount}
            shouldReload={shouldReloadBusinessPlanData}
            isPermitted={isPermitted}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('common:index'),
      render: () => (
        <Tab.Pane className={styles.paneContainer}>
          <BusinessPlanIndexTab
            propertyId={props.property.id}
            shouldReload={shouldReloadBusinessPlanData}
            isPermitted={isPermitted}
          />
        </Tab.Pane>
      )
    }
  ];
  const isPermitted = hasPermittedWriteRoleInOrganisation(currentUser, currentUserMemberships, props.organisationId);
  const areButtonsDisabled =
    status === Status.Saving || startDateValidationResult?.error || endDateValidationResult?.error;

  const noBusinessPlanLayout = <Message>{t('contracts:noBusinessPlanForPropertyMessage')}</Message>;

  const businessPlanLayout = (
    <>
      <Form className={styles.form}>
        <Form.Group>
          <Form.Field width={3} error={startDateValidationResult?.error ?? false}>
            <label>{t('common:startDate')}</label>
            <DatePicker
              selected={businessPlanStartDate ? new Date(businessPlanStartDate) : undefined}
              dateFormat="P"
              popperPlacement="bottom"
              placeholderText="YYYY-MM-DD"
              disabled={!shouldDatesEdit}
              maxDate={businessPlanEndDate ? new Date(businessPlanEndDate) : undefined}
              isClearable={shouldDatesEdit}
              onChange={(date) => {
                setBusinessPlanStartDate(date ? (date as Date).toLocaleDateString('sv') : undefined);
              }}
              customInput={
                <Form.Input
                  fluid
                  width={16}
                  icon="calendar alternate outline"
                  iconPosition="left"
                  error={startDateValidationResult?.error}
                />
              }
            />
            {startDateValidationResult?.error && (
              <Label prompt={true} pointing="above">
                {startDateValidationResult!.errorMessage}
              </Label>
            )}
          </Form.Field>

          <Form.Field width={3} error={endDateValidationResult?.error ?? false}>
            <label>{t('common:endDate')}</label>
            <DatePicker
              selected={businessPlanEndDate ? new Date(businessPlanEndDate) : undefined}
              dateFormat="P"
              disabled={!shouldDatesEdit}
              popperPlacement="bottom"
              placeholderText="YYYY-MM-DD"
              minDate={businessPlanStartDate ? new Date(businessPlanStartDate) : undefined}
              isClearable={shouldDatesEdit}
              onChange={(date) => {
                setBusinessPlanEndDate(date ? (date as Date).toLocaleDateString('sv') : undefined);
              }}
              customInput={
                <Form.Input
                  fluid
                  width={16}
                  icon="calendar alternate outline"
                  iconPosition="left"
                  error={endDateValidationResult?.error}
                />
              }
            />
            {endDateValidationResult?.error && (
              <Label prompt={true} pointing="above">
                {endDateValidationResult!.errorMessage}
              </Label>
            )}
          </Form.Field>

          {isPermitted && (
            <Form.Field>
              <label>&nbsp;</label>
              <EditableControlCell
                isInEditMode={shouldDatesEdit}
                onCancel={() => {
                  setBusinessPlanEndDate(businessPlan?.endDate);
                  setBusinessPlanStartDate(businessPlan?.startDate);
                  setShouldDatesEdit(false);
                }}
                onEditModeEnter={() => setShouldDatesEdit(true)}
                saveLoading={status === Status.Saving}
                onSave={() => saveBusinessDates()}
                savingDisabled={areButtonsDisabled}
              />
            </Form.Field>
          )}
        </Form.Group>
      </Form>
      {!businessPlan ? (
        noBusinessPlanLayout
      ) : (
        <Tab menu={{ secondary: true, pointing: true, size: 'large' }} panes={panes} />
      )}
    </>
  );

  const getBusinessPlanLayout = () => {
    switch (businessPlanStatus) {
      case NetworkRequestStatus.LoadError:
        return (
          <LoadError message={t('properties:unableToLoadBusinessPlan')} retry={incrementReloadBusinessPlanCount} />
        );
      case NetworkRequestStatus.Loading:
        return businessPlan ? businessPlanLayout : <Loader active size="large" inline="centered" />;
      case NetworkRequestStatus.None:
        return businessPlanLayout;
    }
  };

  return (
    <Modal
      open
      closeIcon={status !== Status.Saving && !shouldDatesEdit}
      size="fullscreen"
      onClose={() => props.onClose()}
    >
      <Modal.Header>
        <div className={styles.headerContainer}>
          <Image src={propertyPictureUrl ?? propertyPlaceholderImage} rounded />
          <div>
            <Header as="h3">
              {props.property.name}
              <Header.Subheader>{t('common:businessPlan')}</Header.Subheader>
            </Header>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div id={datepickerPortalId} />
        {getBusinessPlanLayout()}
      </Modal.Content>

      <Modal.Actions>
        <Button primary onClick={() => props.onClose()} disabled={status === Status.Saving || shouldDatesEdit}>
          {t('common:finished')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BusinessPlanModal;
