import React from 'react';
import { createInfinitePaginationParams, NetworkRequestStatus, workOrderApi } from '../../../Http/Http';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { useNonce } from '../../../Utils/hooks';
import { WorkOrderApiWorkOrderResourceListRequest, WorkOrderListItemView } from '../../../GeneratedServices';
import { notEmpty } from '../../../Utils/array';

export const useFaultReports = (props: {
  filterParams: WorkOrderApiWorkOrderResourceListRequest;
  loadThumbnails?: (ids: string[], abortController: AbortController) => Promise<void>;
  reloadCount?: number;
}) => {
  const { loadThumbnails, filterParams, reloadCount: reloadFRCount } = props;
  const globalState = React.useContext(globalStateCTX);

  const [faultReports, setFaultReports] = React.useState<WorkOrderListItemView[]>();
  const [reloadCount, incrementReloadCount] = useNonce();

  const [status, setStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(NetworkRequestStatus.Loading);
        const { data } = await workOrderApi.workOrderResourceList(
          {
            ...createInfinitePaginationParams(),
            sortBy: 'created_desc',
            ...filterParams
          },
          { signal: abortController.signal }
        );
        setFaultReports(data.records);

        setStatus(NetworkRequestStatus.None);

        loadThumbnails &&
          loadThumbnails(data.records.map((item) => item.property.pictureId).filter(notEmpty), abortController);
      } catch (error) {
        globalState.handleHttpErrors(error) && setStatus(NetworkRequestStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [globalState, reloadCount, filterParams, loadThumbnails, reloadFRCount]);

  return { faultReports, status, reloadFaultReports: incrementReloadCount };
};
