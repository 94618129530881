import React from 'react';
import { FileInfoReadView } from '../../Services/FileService.types';
import styles from './FileListItem.module.scss';
import * as FileService from '../../Services/FileService';
import { Icon } from 'semantic-ui-react';
import { filenameExtension, filenameWithoutExtensions } from '../../Utils/file';
import { resolveIconFromFilename } from '../../Utils/icon';
import ActionBadge from '../ActionBadge/ActionBadge';
import Positioned from '../Positioned/Positioned';
import CircleLoader from '../CircleLoader/CircleLoader';
import FileViewer from '../FileViewer/FileViewer';
import colors from '../../Utils/Colors/Colors.module.scss';
import { useTranslation } from 'react-i18next';
import { FileThumbnailStatus, FileWithThumbnailUrlEntity } from '../FileCardList/Hooks/useFilesReducer';

interface Props {
  file: FileWithThumbnailUrlEntity;
  deleteType: 'NON_DELETABLE' | 'DELETE' | 'MARK_AS_DELETED';
  onDeleteChanged?: (action: 'DELETED' | 'MARKED_AS_DELETED' | 'MARKED_AS_DELETED_UNDO') => void;
  files?: FileInfoReadView[];
  overviewOnly?: boolean;
  onClick?: (file: FileInfoReadView) => void;
  highlighted?: boolean;
  retryLoadingThumbnail: (file: FileWithThumbnailUrlEntity) => void;
}

const FileListItem: React.FC<Props> = (props) => {
  const { t } = useTranslation(['files', 'common']);
  const { isImageOrPDF, thumbnailUrl: url, status } = props.file;

  const imageRef = React.useRef<HTMLImageElement>(null);
  const abortControllerRef = React.useRef<AbortController>();

  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [imageViewerOpened, setImageViewerOpened] = React.useState(false);
  const [markedAsDeleted, setMarkedAsDeleted] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  React.useLayoutEffect(() => {
    if (imageRef.current) {
      imageRef.current.src = url ?? '';
    }

    return () => {
      // Make <img> stop loading image when component dismounts.
      if (imageRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        imageRef.current.src = '';
      }
    };
  }, [url]);

  React.useEffect(() => {
    if (props.highlighted && imageRef.current) {
      imageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.highlighted, imageRef.current]);

  const show = () => {
    setHovering(false);
    if (props.onClick) {
      props.onClick(props.file);
    }
    if (isImageOrPDF) {
      setImageViewerOpened(true);
    }
  };

  const download = async () => {
    try {
      const downloadUrl = await FileService.getFileBlobUrl(
        {
          id: props.file.id
        },
        { signal: abortControllerRef.current!.signal }
      );
      if (downloadUrl) window.location.assign(downloadUrl);
    } catch (error) {
      setError(true);
    }
  };

  const deleteFile = () => {
    if (props.deleteType === 'NON_DELETABLE') {
      return;
    }

    if (props.deleteType === 'DELETE') {
      props.onDeleteChanged?.('DELETED');
    }

    if (props.deleteType === 'MARK_AS_DELETED') {
      if (markedAsDeleted) {
        setMarkedAsDeleted(false);
        props.onDeleteChanged?.('MARKED_AS_DELETED_UNDO');
      } else {
        setMarkedAsDeleted(true);
        props.onDeleteChanged?.('MARKED_AS_DELETED');
      }
    }
  };

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setError(true);
  };

  const retry = () => {
    props.retryLoadingThumbnail(props.file);
  };

  const loadingError =
    error || [FileThumbnailStatus.ThumbnailGenerationError, FileThumbnailStatus.Error].includes(status);
  return (
    <div
      className={styles.componentWrapper}
      data-image={isImageOrPDF}
      title={props.file.name}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div
        className={styles.contentWrapper}
        data-marked-for-deletion={markedAsDeleted}
        title={isImageOrPDF && loaded ? t('files:clickToDisplayPicture') : ''}
        onClick={show}
        style={{ border: props.highlighted ? '3px solid white' : undefined }}
      >
        {isImageOrPDF && url && !loadingError && (
          <img ref={imageRef} src={url} alt="" onLoad={onLoad} onError={onError} />
        )}

        {isImageOrPDF && !loaded && !loadingError && <CircleLoader size={'2.5em'} dy={'-0.9em'} />}

        {((isImageOrPDF && loadingError) || !isImageOrPDF) && (
          <>
            {<div className={styles.extension}>{filenameExtension(props.file.name)}</div>}
            {<div className={styles.fileName}>{filenameWithoutExtensions(props.file.name)}</div>}
            <Icon
              name={resolveIconFromFilename(props.file.name)}
              color={loadingError ? 'red' : 'blue'}
              className={styles.nonImageIcon}
            />
          </>
        )}
      </div>

      {!props.overviewOnly && (
        <>
          <Positioned className={styles.badgePositioner} position="TOP-RIGHT" dx={'1.2em'} dy={'-50%'} zIndex={10}>
            <div style={{ display: 'flex' }}>
              {loadingError && (
                <ActionBadge
                  icon="refresh"
                  title={t('common:tryAgain')}
                  semiTransparent={false}
                  backgroundColor={colors.red}
                  onClick={retry}
                />
              )}
              {!loadingError && hovering && (
                <ActionBadge
                  icon="download"
                  title={t('common:download')}
                  backgroundColor={colors.blue1}
                  onClick={download}
                />
              )}

              {/* Delete file instantly using API. */}
              {!loadingError && hovering && props.deleteType === 'DELETE' && (
                <ActionBadge
                  icon="delete"
                  title={t('common:remove')}
                  backgroundColor={colors.black}
                  onClick={deleteFile}
                />
              )}

              {/* Mark file as deleted. Deletion undoable. */}
              {/* Display undo icon even when not hovering the component. */}
              {!error && props.deleteType === 'MARK_AS_DELETED' && markedAsDeleted && (
                <ActionBadge
                  icon="delete"
                  title={t('files:markedForDeletionClickToUndo')}
                  semiTransparent={false}
                  backgroundColor={colors.red}
                  onClick={deleteFile}
                />
              )}
              {!error && hovering && props.deleteType === 'MARK_AS_DELETED' && !markedAsDeleted && (
                <ActionBadge
                  icon="delete"
                  title={t('common:remove')}
                  backgroundColor={colors.black}
                  onClick={deleteFile}
                />
              )}
            </div>
          </Positioned>

          {imageViewerOpened && (
            <FileViewer file={props.file} onClosed={() => setImageViewerOpened(false)} files={props.files} />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(FileListItem);
