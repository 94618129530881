import React from 'react';
import {
  FileInfoReadView,
  FileInfoReadViewEntityTypeEnum,
  FileInfoReadViewTypeEnum
} from '../../../../../GeneratedServices';
import { globalStateCTX } from '../../../../../GlobalState/GlobalState';
import { createInfinitePaginationParams, fileApi } from '../../../../../Http/Http';
import { useNonce } from '../../../../../Utils/hooks';
import { ContractStatus } from '../ContractModal';

export const useContractFiles = (props: { contractId: number }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [retry, setRetry] = useNonce();
  const [status, setStatus] = React.useState<ContractStatus>(ContractStatus.Loading);

  const [files, setFiles] = React.useState<FileInfoReadView[]>();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(ContractStatus.Loading);

        const { data } = await fileApi.fileResourceList(
          {
            entityId: props.contractId,
            ...createInfinitePaginationParams(),
            type: FileInfoReadViewTypeEnum.Lease,
            entityType: FileInfoReadViewEntityTypeEnum.Contract
          },
          { signal: abortController.signal }
        );

        setFiles(data.records);
        setStatus(ContractStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(ContractStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, props.contractId, retry]);

  return {
    files,
    status,
    reloadFiles: setRetry
  };
};
