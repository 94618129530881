import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { FiltersContext } from '../../../FiltersProvider/FiltersProvider';
import { MembershipReadView } from '../../../GeneratedServices';
import { NetworkRequestStatus } from '../../../Http/Http';
import { mapMembershipsToDropdownItems } from '../../../Services/MembershipService.types';
import FilterLabel from '../FilterLabel';

interface Props {
  selectedUserIds?: number[];
  setSelectedUserIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  memberships: MembershipReadView[];
  upward?: boolean;
}

const UserMembershipDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common']);
  const { membershipsStatus } = React.useContext(FiltersContext);

  return (
    <Dropdown
      placeholder={t('common:allUsers')}
      value={props.selectedUserIds ?? []}
      renderLabel={(item, i, prop) => <FilterLabel text={item.text} type="user" labelProps={prop} />}
      multiple
      fluid
      search
      scrolling
      selection
      upward={props.upward}
      floating
      error={membershipsStatus === NetworkRequestStatus.LoadError}
      loading={membershipsStatus === NetworkRequestStatus.Loading}
      disabled={membershipsStatus !== NetworkRequestStatus.None}
      clearable
      options={mapMembershipsToDropdownItems(props.memberships, 'userId')}
      onChange={(e, d) => {
        props.setSelectedUserIds(d.value as number[]);
      }}
    />
  );
};

export default UserMembershipDropdown;
