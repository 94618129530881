import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { FileSubType } from './FileService.types';
import i18n from '../i18n';
import {
  GovernmentInspectionItemReadViewAssigneeEnum,
  GovernmentInspectionItemReadViewStatusEnum,
  GovernmentInspectionListItemViewCategoryEnum,
  GovernmentInspectionListItemView,
  GovernmentInspectionItemReadView,
  GovernmentInspectionItemResultReadView
} from '../GeneratedServices';
import { DropdownItemProps } from 'semantic-ui-react';

export const translateGovernmentInspectionItemStatus = (status: GovernmentInspectionItemReadViewStatusEnum): string => {
  switch (status) {
    case GovernmentInspectionItemReadViewStatusEnum.Passed:
      return i18n.t('inspections:inspectionItemStatus.approved');
    case GovernmentInspectionItemReadViewStatusEnum.ExpiringSoon:
      return i18n.t('inspections:inspectionItemStatus.approvalEndingSoon');
    case GovernmentInspectionItemReadViewStatusEnum.Failed:
    case GovernmentInspectionItemReadViewStatusEnum.Overdue:
      return i18n.t('inspections:inspectionItemStatus.notApproved');
  }
};

export const getGovernmentInspectionStatusColor = (
  status: GovernmentInspectionItemReadViewStatusEnum
): SemanticCOLORS => {
  switch (status) {
    case GovernmentInspectionItemReadViewStatusEnum.Passed:
      return 'green';
    case GovernmentInspectionItemReadViewStatusEnum.ExpiringSoon:
      return 'yellow';
    case GovernmentInspectionItemReadViewStatusEnum.Failed:
    case GovernmentInspectionItemReadViewStatusEnum.Overdue:
      return 'red';
  }
};

export const translateGovernmentInspectionCategory = (
  category: GovernmentInspectionListItemViewCategoryEnum
): string => {
  const map = new Map([
    [
      GovernmentInspectionListItemViewCategoryEnum.AuditInspectionFireAlarm,
      i18n.t('inspections:inspectionCategory.auditInspectionFireAlarm')
    ],
    [
      GovernmentInspectionListItemViewCategoryEnum.AuditInspectionSprinkler,
      i18n.t('inspections:inspectionCategory.auditInspectionSprinkler')
    ],
    [GovernmentInspectionListItemViewCategoryEnum.Door, i18n.t('inspections:inspectionCategory.door')],
    [
      GovernmentInspectionListItemViewCategoryEnum.ElectricityAudit,
      i18n.t('inspections:inspectionCategory.electricityAudit')
    ],
    [GovernmentInspectionListItemViewCategoryEnum.Elevator, i18n.t('inspections:inspectionCategory.elevator')],
    [
      GovernmentInspectionListItemViewCategoryEnum.EnergyDeclaration,
      i18n.t('inspections:inspectionCategory.energyDeclaration')
    ],
    [GovernmentInspectionListItemViewCategoryEnum.LiftTable, i18n.t('inspections:inspectionCategory.liftTable')],
    [GovernmentInspectionListItemViewCategoryEnum.LoadingDock, i18n.t('inspections:inspectionCategory.loadingDoor')],
    [GovernmentInspectionListItemViewCategoryEnum.OilSeparator, i18n.t('inspections:inspectionCategory.oilSeparator')],
    [GovernmentInspectionListItemViewCategoryEnum.Ovk, i18n.t('inspections:inspectionCategory.OVK')],
    [
      GovernmentInspectionListItemViewCategoryEnum.PressureVessels,
      i18n.t('inspections:inspectionCategory.pressureVessels')
    ],
    [GovernmentInspectionListItemViewCategoryEnum.Refrigerant, i18n.t('inspections:inspectionCategory.refrigerant')],
    [
      GovernmentInspectionListItemViewCategoryEnum.SbaSystematicFireProtectionWork,
      i18n.t('inspections:inspectionCategory.sbaSystematicFireProtectionWork')
    ],
    [GovernmentInspectionListItemViewCategoryEnum.Shelter, i18n.t('inspections:inspectionCategory.shelter')]
  ]);

  return map.has(category) ? map.get(category)! : category.toString();
};

export const translateGovernmentInspectionItemAssignee = (assignee: GovernmentInspectionItemReadViewAssigneeEnum) => {
  if (assignee === GovernmentInspectionItemReadViewAssigneeEnum.Landlord)
    return i18n.t('inspections:assignee.landlord');
  else return i18n.t('inspections:assignee.tenant');
};

export const convertGovernmentInspectionCategoryToFileSubType = (
  rowState: GovernmentInspectionListItemViewCategoryEnum
) => {
  return FileSubType[rowState as keyof typeof FileSubType];
};

export const generateGovernmentInspectionCategoryOptions = (): DropdownItemProps[] =>
  Object.values(GovernmentInspectionListItemViewCategoryEnum).map((item) => ({
    text: translateGovernmentInspectionCategory(item),
    value: item,
    key: item
  }));

export const generateGovernmentInspectionAssigneeOptions = (): DropdownItemProps[] =>
  Object.values(GovernmentInspectionItemReadViewAssigneeEnum).map((item) => ({
    text: translateGovernmentInspectionItemAssignee(item),
    value: item,
    key: item
  }));

export const mapGovernmentInspectionsToDropdownItems = (
  inspections: GovernmentInspectionListItemView[]
): DropdownItemProps[] => {
  return (
    inspections.map((item) => ({
      key: item.id,
      text: item.property.name,
      value: item.id
    })) ?? []
  );
};

export const mapGovernmentInspectionItemsToDropdownItems = (
  inspectionItems: GovernmentInspectionItemReadView[],
  usedItemIds: number[]
): DropdownItemProps[] => {
  return (
    inspectionItems.map((item) => ({
      key: item.id,
      text: item.title,
      value: item.id,
      disabled: usedItemIds.includes(item.id)
    })) ?? []
  );
};

export const mapGovernmentInspectionItemResultsToDropdownItems = (
  inspectionItemResults: GovernmentInspectionItemResultReadView[]
): DropdownItemProps[] => {
  return (
    inspectionItemResults.map((item) => ({
      key: item.id,
      text: item.itemTitle,
      value: item.id!
    })) ?? []
  );
};
