import React from 'react';
import { Label, LabelProps } from 'semantic-ui-react';

interface Props extends LabelProps {}

const LeaseStatusLabel: React.FC<Props> = (props) => {
  return (
    <Label
      color={props.color}
      content={props.content}
      style={{
        borderRadius: 100,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        whiteSpace: 'noWrap'
      }}
      size="large"
    />
  );
};

export default LeaseStatusLabel;
