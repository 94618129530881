import React from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'semantic-ui-react';
import { FileInfoReadView } from '../../Services/FileService.types';
import ActionBadge from '../ActionBadge/ActionBadge';
import FileView from './FileView';
import styles from './FileViewer.module.scss';
import FileList from '../FileList/FileList';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import * as FileService from '../../Services/FileService';
import colors from '../../Utils/Colors/Colors.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  file: FileInfoReadView;
  onClosed: () => void;
  files?: FileInfoReadView[];
  extraActionBadge?: JSX.Element;
  onSelectedFileChange?: (file: FileInfoReadView) => void;
}

const FileViewer: React.FC<Props> = (props) => {
  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);

  const { onSelectedFileChange } = props;
  const { t } = useTranslation(['common']);

  const componentWrapperRef = React.useRef(null);

  const [selectedFile, setSelectedFile] = React.useState<FileInfoReadView>(props.file);

  React.useEffect(() => {
    onSelectedFileChange && onSelectedFileChange(selectedFile);
  }, [selectedFile, onSelectedFileChange]);

  const currentFileIndex = props.files?.findIndex((item) => item.id === selectedFile.id);

  const handleSelectPreviousFile = () => {
    if (props.files && currentFileIndex !== undefined) setSelectedFile(props.files[currentFileIndex - 1]);
  };

  const handleSelectNextFile = () => {
    if (props.files && currentFileIndex !== undefined) setSelectedFile(props.files[currentFileIndex + 1]);
  };

  const isFileFirst = () => {
    if (props.files) return currentFileIndex === 0;
    else return true;
  };

  const isFileLast = () => {
    if (props.files) return currentFileIndex !== undefined && currentFileIndex + 1 === props.files?.length;
    else return true;
  };

  const download = async (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    if (selectedFile) {
      try {
        const downloadUrl = await FileService.getFileBlobUrl({ id: selectedFile.id });
        if (downloadUrl) window.location.assign(downloadUrl);
      } catch (error) {
        globalState.handleHttpErrors(error);
      }
    }
  };

  const content = (
    <div ref={componentWrapperRef} className={styles.componentWrapper}>
      <div className={styles.topContainer}>
        <div style={{ flex: 1 }} />
        <div className={styles.fileTitle}>{selectedFile.name}</div>
        <div className={styles.badgesContainer}>
          {props.extraActionBadge}
          <ActionBadge
            icon="download"
            backgroundColor={colors.blue1}
            title={t('common:download')}
            onClick={download}
            size={'1.1em'}
          />
          <ActionBadge
            icon="close"
            backgroundColor={colors.black}
            title={t('common:close')}
            onClick={props.onClosed}
            size={'1.1em'}
          />
        </div>
      </div>

      <div className={styles.middleContainer}>
        <div className={styles.controlIconsContainer}>
          <Icon
            name="chevron left"
            size="huge"
            fitted
            onClick={handleSelectPreviousFile}
            className={styles.icon}
            style={{ visibility: !isFileFirst() ? 'visible' : 'hidden' }}
          />
        </div>

        <div className={styles.centerContainer}>
          <FileView file={selectedFile} onClosed={props.onClosed} />
        </div>

        <div className={styles.controlIconsContainer}>
          <Icon
            name="chevron right"
            size="huge"
            fitted
            onClick={handleSelectNextFile}
            className={styles.icon}
            style={{
              visibility: !isFileLast() ? 'visible' : 'hidden'
            }}
          />
        </div>
      </div>

      <div className={styles.bottomContainer}>
        <div className={styles.fileListContainer}>
          {props.files && (
            <FileList
              files={props.files}
              overviewOnly
              onItemClick={(file) => setSelectedFile(file)}
              highlightedIndex={currentFileIndex}
              limitedWidth={true}
            />
          )}
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(content, document.body);
};

export default FileViewer;
