import { SemanticICONS } from 'semantic-ui-react';
import { filenameExtension } from './file';

/**
 * Returns a Semantic icon name for the specified file extension.
 * "file" is returned if no extension exists or is matched.
 * @param filename Name of file.
 */
export const resolveIconFromFilename = (filename: string): SemanticICONS => {
  const map = new Map<string, SemanticICONS>([
    // Images
    ['.png', 'file image outline'],
    ['.jpg', 'file image outline'],
    ['.jpeg', 'file image outline'],
    ['.gif', 'file image outline'],
    ['.tif', 'file image outline'],
    ['.tiff', 'file image outline'],

    // Video
    ['.mp4', 'file video outline'],
    ['.mov', 'file video outline'],
    ['.wmv', 'file video outline'],
    ['.avi', 'file video outline'],
    ['.mkv', 'file video outline'],
    ['.webm', 'file video outline'],

    // Audio
    ['.mp4', 'file audio outline'],
    ['.mov', 'file audio outline'],
    ['.wmv', 'file audio outline'],
    ['.avi', 'file audio outline'],
    ['.mkv', 'file audio outline'],
    ['.webm', 'file audio outline'],

    // Word
    ['.doc', 'file word outline'],
    ['.docx', 'file word outline'],
    ['.docm', 'file word outline'],
    ['.dotx', 'file word outline'],
    ['.dotm', 'file word outline'],

    // Excel
    ['.xls', 'file excel outline'],
    ['.xlt', 'file excel outline'],
    ['.xlsx', 'file excel outline'],
    ['.xlsm', 'file excel outline'],
    ['.xltm', 'file excel outline'],

    // PowerPoint
    ['.ppt', 'file powerpoint outline'],
    ['.pptx', 'file powerpoint outline'],
    ['.pptm', 'file powerpoint outline'],
    ['.potx', 'file powerpoint outline'],
    ['.xltm', 'file powerpoint outline'],

    // Pdf
    ['.pdf', 'file pdf outline'],

    // Text
    ['.txt', 'file text outline']
  ]);

  const ext = (filenameExtension(filename) ?? '').toLowerCase();
  return ext && map.has(ext) ? map.get(ext)! : 'file outline';
};
