import React from 'react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import { formatCurrency } from '../../Utils/number';
import NumberFormater from '../NumberFormater/NumberFormater';
import KpiCard, { KPIStatus } from './KPICard/KpiCard';
import colors from '../../Utils/Colors/Colors.module.scss';
import { CapexAndTiYTQStatisticsReadView } from '../../GeneratedServices';
import { statisticsApi } from '../../Http/Http';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}
const YearlyRollingCapexAndTiKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [yearlyCapexAndTiStatistics, setYearlyCapexAndTiStatistics] = React.useState<CapexAndTiYTQStatisticsReadView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceReadCapexAndTiYTQStatistics(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setYearlyCapexAndTiStatistics({
          businessPlanValue: data.businessPlanValue * -1,
          totalBusinessPlanValue: data.totalBusinessPlanValue * -1,
          totalValue: data.totalValue * -1,
          value: data.value * -1,
          valuePerArea: data.valuePerArea * -1
        });
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();
    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  const formattedValue = yearlyCapexAndTiStatistics
    ? formatCurrency(yearlyCapexAndTiStatistics?.value, { forceCurrency: 'KSEK' })
    : undefined;

  const formattedValuePerArea = yearlyCapexAndTiStatistics
    ? formatCurrency(yearlyCapexAndTiStatistics?.valuePerArea, { forceCurrency: 'SEK', unit: t('sekSqm') })
    : undefined;

  return (
    <KpiCard
      title={t('yearlyRollingCapexAndTenantImprovement')}
      value={
        <NumberFormater value={formattedValue?.value} additionalProps={{ decimalScale: 0, allowNegative: true }} />
      }
      valueSuffix={formattedValue?.suffix}
      additionalValue={
        <NumberFormater
          value={formattedValuePerArea?.value}
          additionalProps={{ decimalScale: 0, allowNegative: true }}
        />
      }
      additionalValueSuffix={formattedValuePerArea?.suffix}
      extra={
        <>
          <NumberFormater
            value={
              formatCurrency(yearlyCapexAndTiStatistics?.totalValue ?? 0, { forceCurrency: 'KSEK', unit: 'KSEK' }).value
            }
            suffix=" KSEK"
            additionalProps={{ decimalScale: 0, allowNegative: true }}
          />{' '}
          /{' '}
          <NumberFormater
            value={
              formatCurrency(yearlyCapexAndTiStatistics?.totalBusinessPlanValue ?? 0, {
                forceCurrency: 'KSEK',
                unit: 'KSEK'
              }).value
            }
            suffix=" KSEK"
            additionalProps={{ decimalScale: 0, allowNegative: true }}
          />
        </>
      }
      extraColor={
        yearlyCapexAndTiStatistics &&
        yearlyCapexAndTiStatistics.totalValue > yearlyCapexAndTiStatistics?.totalBusinessPlanValue
          ? colors.red
          : colors.green
      }
      includeExtra
      status={status}
      retry={reload}
    />
  );
};

export default YearlyRollingCapexAndTiKpi;
