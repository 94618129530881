import { faCheck, faPencil, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ButtonGroup } from 'semantic-ui-react';

interface Props {
  isInEditMode: boolean;
  allowDelete?: boolean;
  onSave: () => void;
  onCancel: () => void;
  onEditModeEnter: () => void;
  onDelete?: () => void;
  saveLoading?: boolean;
  deleteLoading?: boolean;
  editModeDisabled?: boolean;
  savingDisabled?: boolean;
  deletingDisabled?: boolean;
}
const EditableControlCell: React.FC<Props> = (props) => {
  const disabled = props.editModeDisabled || props.saveLoading || props.deleteLoading;
  return (
    <ButtonGroup icon>
      {props.isInEditMode ? (
        <>
          <Button
            title={'Spara'}
            type="button"
            primary
            onClick={props.onSave}
            loading={props.saveLoading}
            disabled={disabled || props.savingDisabled}
          >
            <FontAwesomeIcon icon={faCheck} fixedWidth />
          </Button>
          <Button title="Avbryt" type="button" secondary onClick={props.onCancel} disabled={disabled}>
            <FontAwesomeIcon icon={faTimes} fixedWidth />
          </Button>
          {props.allowDelete && (
            <Button
              title="Ta bort"
              color="red"
              onClick={props.onDelete}
              disabled={disabled || props.deletingDisabled}
              loading={props.deleteLoading}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth />
            </Button>
          )}
        </>
      ) : (
        <Button title="Redigera" secondary onClick={props.onEditModeEnter} disabled={disabled}>
          <FontAwesomeIcon icon={faPencil} fixedWidth />
        </Button>
      )}
    </ButtonGroup>
  );
};

export default EditableControlCell;
