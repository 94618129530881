import React from 'react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import { formatCurrency } from '../../Utils/number';
import NumberFormater from '../NumberFormater/NumberFormater';
import KpiCard, { KPISize, KPIStatus } from './KPICard/KpiCard';
import { statisticsApi } from '../../Http/Http';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}

const TotalRentKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation(['contracts', 'common']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [totalRentStatistics, setTotalRentStatistics] = React.useState<number | null>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceGetTotalRent(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setTotalRentStatistics(data.actualValuePerArea);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();
    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  const formattedValue =
    totalRentStatistics != null
      ? formatCurrency(totalRentStatistics, { forceCurrency: 'SEK', unit: t('common:sekSqm') })
      : undefined;

  return (
    <KpiCard
      title={t('contracts:totalRent')}
      valueAs="h5"
      value={
        totalRentStatistics != null ? (
          <NumberFormater value={formattedValue?.value} additionalProps={{ decimalScale: 0 }} />
        ) : (
          t('common:noData')
        )
      }
      valueSuffix={totalRentStatistics != null ? formattedValue?.suffix : undefined}
      status={status}
      retry={reload}
      size={KPISize.small}
    />
  );
};

export default TotalRentKpi;
