import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, List, Segment } from 'semantic-ui-react';
import styles from './ClusterControl.module.scss';

interface Props {
  map: google.maps.Map | null;
  areMarkersClustered: boolean;
  setMarkersClustered: React.Dispatch<React.SetStateAction<boolean>>;
}
const ClusterControl: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common']);

  const customControlRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (props.map) {
      //Add custom control component for clusters
      const clusterControlDiv = document.createElement('div');

      const customControlDiv = customControlRef.current;
      clusterControlDiv.appendChild(customControlDiv as Node);
      clusterControlDiv.style.pointerEvents = 'none';
      props.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(clusterControlDiv);
    }
  }, [props.map]);
  return (
    <div className={styles.clusterControl} ref={customControlRef}>
      <Segment className={styles.checkboxMenu}>
        <List verticalAlign="middle" relaxed>
          <List.Item>
            <Checkbox
              label={t('common:cluster')}
              checked={props.areMarkersClustered}
              onChange={() => {
                props.setMarkersClustered((prevVal) => !prevVal);
              }}
            />
          </List.Item>
        </List>
      </Segment>
    </div>
  );
};

export default ClusterControl;
