/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { OrganisationCreateView } from '../model';
// @ts-ignore
import { OrganisationListView } from '../model';
// @ts-ignore
import { OrganisationReadView } from '../model';
// @ts-ignore
import { OrganisationUpdateView } from '../model';
/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create an organisation
     * @param {OrganisationCreateView} [organisationCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceCreate: async (
      organisationCreateView?: OrganisationCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/organisations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        organisationCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Under the hood no data is deleted and can be restored by modifying the database if needed.
     * @summary Delete an organisation
     * @param {number} id Organisation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('organisationResourceDelete', 'id', id);
      const localVarPath = `/v1/organisations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List organisations
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceList: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/organisations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read an organisation
     * @param {number} id Organisation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('organisationResourceRead', 'id', id);
      const localVarPath = `/v1/organisations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update an organisation
     * @param {number} id Organisation ID
     * @param {OrganisationUpdateView} [organisationUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceUpdate: async (
      id: number,
      organisationUpdateView?: OrganisationUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('organisationResourceUpdate', 'id', id);
      const localVarPath = `/v1/organisations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        organisationUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrganisationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create an organisation
     * @param {OrganisationCreateView} [organisationCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationResourceCreate(
      organisationCreateView?: OrganisationCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationResourceCreate(
        organisationCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Under the hood no data is deleted and can be restored by modifying the database if needed.
     * @summary Delete an organisation
     * @param {number} id Organisation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List organisations
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationResourceList(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationResourceList(limit, offset, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read an organisation
     * @param {number} id Organisation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update an organisation
     * @param {number} id Organisation ID
     * @param {OrganisationUpdateView} [organisationUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationResourceUpdate(
      id: number,
      organisationUpdateView?: OrganisationUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationResourceUpdate(
        id,
        organisationUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OrganisationApiFp(configuration);
  return {
    /**
     *
     * @summary Create an organisation
     * @param {OrganisationCreateView} [organisationCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceCreate(
      organisationCreateView?: OrganisationCreateView,
      options?: any
    ): AxiosPromise<OrganisationReadView> {
      return localVarFp
        .organisationResourceCreate(organisationCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Under the hood no data is deleted and can be restored by modifying the database if needed.
     * @summary Delete an organisation
     * @param {number} id Organisation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.organisationResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List organisations
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceList(limit?: number, offset?: number, options?: any): AxiosPromise<OrganisationListView> {
      return localVarFp.organisationResourceList(limit, offset, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read an organisation
     * @param {number} id Organisation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceRead(id: number, options?: any): AxiosPromise<OrganisationReadView> {
      return localVarFp.organisationResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update an organisation
     * @param {number} id Organisation ID
     * @param {OrganisationUpdateView} [organisationUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationResourceUpdate(
      id: number,
      organisationUpdateView?: OrganisationUpdateView,
      options?: any
    ): AxiosPromise<OrganisationReadView> {
      return localVarFp
        .organisationResourceUpdate(id, organisationUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for organisationResourceCreate operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrganisationResourceCreateRequest
 */
export interface OrganisationApiOrganisationResourceCreateRequest {
  /**
   *
   * @type {OrganisationCreateView}
   * @memberof OrganisationApiOrganisationResourceCreate
   */
  readonly organisationCreateView?: OrganisationCreateView;
}

/**
 * Request parameters for organisationResourceDelete operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrganisationResourceDeleteRequest
 */
export interface OrganisationApiOrganisationResourceDeleteRequest {
  /**
   * Organisation ID
   * @type {number}
   * @memberof OrganisationApiOrganisationResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for organisationResourceList operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrganisationResourceListRequest
 */
export interface OrganisationApiOrganisationResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof OrganisationApiOrganisationResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof OrganisationApiOrganisationResourceList
   */
  readonly offset?: number;
}

/**
 * Request parameters for organisationResourceRead operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrganisationResourceReadRequest
 */
export interface OrganisationApiOrganisationResourceReadRequest {
  /**
   * Organisation ID
   * @type {number}
   * @memberof OrganisationApiOrganisationResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for organisationResourceUpdate operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrganisationResourceUpdateRequest
 */
export interface OrganisationApiOrganisationResourceUpdateRequest {
  /**
   * Organisation ID
   * @type {number}
   * @memberof OrganisationApiOrganisationResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {OrganisationUpdateView}
   * @memberof OrganisationApiOrganisationResourceUpdate
   */
  readonly organisationUpdateView?: OrganisationUpdateView;
}

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
  /**
   *
   * @summary Create an organisation
   * @param {OrganisationApiOrganisationResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public organisationResourceCreate(
    requestParameters: OrganisationApiOrganisationResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return OrganisationApiFp(this.configuration)
      .organisationResourceCreate(requestParameters.organisationCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Under the hood no data is deleted and can be restored by modifying the database if needed.
   * @summary Delete an organisation
   * @param {OrganisationApiOrganisationResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public organisationResourceDelete(
    requestParameters: OrganisationApiOrganisationResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return OrganisationApiFp(this.configuration)
      .organisationResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List organisations
   * @param {OrganisationApiOrganisationResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public organisationResourceList(
    requestParameters: OrganisationApiOrganisationResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return OrganisationApiFp(this.configuration)
      .organisationResourceList(requestParameters.limit, requestParameters.offset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read an organisation
   * @param {OrganisationApiOrganisationResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public organisationResourceRead(
    requestParameters: OrganisationApiOrganisationResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return OrganisationApiFp(this.configuration)
      .organisationResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update an organisation
   * @param {OrganisationApiOrganisationResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganisationApi
   */
  public organisationResourceUpdate(
    requestParameters: OrganisationApiOrganisationResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return OrganisationApiFp(this.configuration)
      .organisationResourceUpdate(requestParameters.id, requestParameters.organisationUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
