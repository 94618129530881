export interface ListViewPagination<T> {
  limit: number;
  offset: number;
  totalCount: number;
  records: T[];
}

export interface ListViewPaginationParams {
  limit?: number;
  offset?: number;
  search?: string;
}

/**
 * Helper function to get total count from a nullable list view pagination.
 * @param listViewPagination List view pagination or undefined.
 */
export const listViewTotalCount = <T>(listViewPagination?: ListViewPagination<T>): number => {
  return listViewPagination?.totalCount ?? 0;
};

export interface RangeView {
  min: number;
  max: number;
}

export interface PointView {
  lat: number;
  lng: number;
}

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};
