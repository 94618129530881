import React from 'react';
import { Grid, Header, StrictGridColumnProps } from 'semantic-ui-react';
import OverviewMap from '../../Overview/Components/Map/OverviewMap';
import Map from '../../../Components/Map/Map';
import SummaryGauges from '../../Overview/Components/OverViewSummary/SummaryGauges';
import { hasPermittedReadRoleInOrganisation } from '../../../Utils/permissions';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { PropertyReadView } from '../../../GeneratedServices';
import { ComparablePropertyType } from '../../../Services/ComparableService.types';
import { useTranslation } from 'react-i18next';
import SummaryKPIs from '../../../Components/KPIs/SummaryKPIs';

interface Props {
  property: PropertyReadView;
  reloadProperty: () => void;
  organisationId?: number;
}

const TabSummary: React.FC<Props> = (props) => {
  const { t } = useTranslation(['properties', 'common']);
  const { currentUser, currentUserMemberships } = React.useContext(globalStateCTX);

  const isPermitted = hasPermittedReadRoleInOrganisation(currentUser, currentUserMemberships, props.organisationId);

  let mapGridSizes: StrictGridColumnProps = {
    largeScreen: 8,
    widescreen: 10,
    computer: 11,
    tablet: 16
  };

  if (!isPermitted) {
    mapGridSizes = {
      largeScreen: 16,
      widescreen: 16,
      computer: 16,
      tablet: 16
    };
  }
  return (
    <>
      <Header as="h3">{t('common:keyPerformanceMetrics')}</Header>
      <Grid>
        <Grid.Column {...mapGridSizes}>
          <Map
            content={(map) => {
              map?.setOptions({
                mapTypeId: google.maps.MapTypeId.HYBRID
              });
              return (
                <OverviewMap
                  properties={[{ ...props.property, type: props.property.type as ComparablePropertyType }]}
                  map={map}
                  areLabelsShown={false}
                  focusSingleProperty
                />
              );
            }}
            roundedEdges
          />
        </Grid.Column>
        {isPermitted && <SummaryKPIs propertyId={props.property.id} />}

        <SummaryGauges propertyId={props.property.id} />
      </Grid>
    </>
  );
};

export default TabSummary;
