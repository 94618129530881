import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MembershipReadView, OrganisationReadView, PortfolioReadView } from '../GeneratedServices';
import { globalStateCTX } from '../GlobalState/GlobalState';
import {
  createInfinitePaginationParams,
  membershipApi,
  NetworkRequestStatus,
  organisationApi,
  portfolioApi
} from '../Http/Http';
import {
  getGeneralDefaultFilters,
  getGeneralSessionFilters,
  setGeneralDefaultFilters,
  setGeneralSessionFilters
} from '../Services/StorageService';
import { groupByToMap } from '../Utils/array';
import { useNonce } from '../Utils/hooks';

interface Props {
  children: any;
}

interface FiltersContextType {
  organisationIds?: number[];
  portfolioIds?: number[];
  propertySubscriptionUserIds?: number[];
  municipalities?: string[];
  handleFiltersChange: (
    organisationIds?: number[],
    portfolioIds?: number[],
    propertySubscriptionUserIds?: number[],
    municipalities?: string[]
  ) => void;
  organisationsStatus: NetworkRequestStatus;
  organisationsMap: Map<number, OrganisationReadView>;
  portfoliosStatus: NetworkRequestStatus;
  portfoliosMap: Map<number, PortfolioReadView>;
  membershipsStatus: NetworkRequestStatus;
  userMembershipsMap: Map<number, MembershipReadView[]>;
  setFiltersAsDefault: () => void;
  filterValues: FilterValuesType;
  removeOrganisation: (id: number) => void;
  removePortfolio: (id: number) => void;
  removePropertySubscriptionUser: (id: number) => void;
  removeMunicipality: (municipality: string) => void;
  restoreDefaultFilters: () => void;
  defaultFilters: FilterValuesType;
}

const initialState: FiltersContextType = {
  handleFiltersChange: () => {},
  organisationsStatus: NetworkRequestStatus.Loading,
  portfoliosStatus: NetworkRequestStatus.Loading,
  membershipsStatus: NetworkRequestStatus.Loading,
  organisationsMap: new Map(),
  portfoliosMap: new Map(),
  userMembershipsMap: new Map(),
  setFiltersAsDefault: () => {},
  filterValues: {},
  removeOrganisation: () => {},
  removePortfolio: () => {},
  removePropertySubscriptionUser: () => {},
  removeMunicipality: () => {},
  restoreDefaultFilters: () => {},
  defaultFilters: {}
};
export interface FilterValuesType {
  organisationIds?: number[];
  portfolioIds?: number[];
  propertySubscriptionUserIds?: number[];
  municipalities?: string[];
}
export const FiltersContext = React.createContext<FiltersContextType>(initialState);

const FiltersProvider: React.FC<Props> = ({ children }) => {
  const { handleHttpErrors, currentUser } = React.useContext(globalStateCTX);
  const { t } = useTranslation(['common']);

  const currentUserId = currentUser?.id;

  const [defaultFiltersReloadCount, incrementDefaultFiltersReloadCount] = useNonce();
  const defaultFilters = React.useMemo(
    () => getGeneralDefaultFilters(currentUserId!) ?? {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUserId, defaultFiltersReloadCount]
  );

  const sessionFilters = React.useMemo(() => {
    const parsed = getGeneralSessionFilters(currentUserId!) ?? {};
    if (Object.values(parsed).length !== 0) return parsed;
    else return null;
  }, [currentUserId]);

  const [organisationIds, setOrganisationIds] = React.useState<number[] | undefined>(
    sessionFilters ? sessionFilters.organisationIds : defaultFilters.organisationIds
  );
  const [portfolioIds, setPortfolioIds] = React.useState<number[] | undefined>(
    sessionFilters ? sessionFilters.portfolioIds : defaultFilters.portfolioIds
  );
  const [propertySubscriptionUserIds, setPropertySubscriptionUserIds] = React.useState<number[] | undefined>(
    sessionFilters ? sessionFilters.propertySubscriptionUserIds : defaultFilters.propertySubscriptionUserIds
  );
  const [municipalities, setMunicipalities] = React.useState<string[] | undefined>(
    sessionFilters ? sessionFilters.municipalities : defaultFilters.municipalities
  );

  const [organisationsStatus, setOrganisationsStatus] = React.useState<NetworkRequestStatus>(
    NetworkRequestStatus.Loading
  );
  const [organisationsMap, setOrganisationsMap] = React.useState<Map<number, OrganisationReadView>>(new Map());

  const [portfoliosStatus, setPortfoliosStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);
  const [portfoliosMap, setPortfoliosMap] = React.useState<Map<number, PortfolioReadView>>(new Map());

  const [membershipsStatus, setMembershipsStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);
  const [userMembershipsMap, setUserMembershipsMap] = React.useState<Map<number, MembershipReadView[]>>(new Map());

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setOrganisationsStatus(NetworkRequestStatus.Loading);

        const { data } = await organisationApi.organisationResourceList(createInfinitePaginationParams(), {
          signal: abortController.signal
        });

        setOrganisationsMap(new Map(data.records.map((item) => [item.id, item])));

        setOrganisationsStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setOrganisationsStatus(NetworkRequestStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setPortfoliosStatus(NetworkRequestStatus.Loading);

        const { data } = await portfolioApi.portfolioResourceList(createInfinitePaginationParams(), {
          signal: abortController.signal
        });

        setPortfoliosMap(new Map(data.records.map((item) => [item.id, item])));

        setPortfoliosStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setPortfoliosStatus(NetworkRequestStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setMembershipsStatus(NetworkRequestStatus.Loading);

        const { data } = await membershipApi.membershipResourceList(createInfinitePaginationParams(), {
          signal: abortController.signal
        });

        setUserMembershipsMap(groupByToMap(data.records, (item) => item.userId));

        setMembershipsStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setMembershipsStatus(NetworkRequestStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors]);

  const handleFiltersChange = (
    organisationIds?: number[],
    portfolioIds?: number[],
    propertySubscriptionUserIds?: number[],
    municipalities?: string[]
  ) => {
    setOrganisationIds(organisationIds);
    setPortfolioIds(portfolioIds);
    setPropertySubscriptionUserIds(propertySubscriptionUserIds);
    setMunicipalities(municipalities);

    // Update session storage with the new filters
    setGeneralSessionFilters(currentUser!.id, {
      organisationIds,
      portfolioIds,
      propertySubscriptionUserIds,
      municipalities
    });
  };

  const removeOrganisation = (id: number) => {
    handleFiltersChange(
      organisationIds?.filter((item) => item !== id),
      portfolioIds,
      propertySubscriptionUserIds,
      municipalities
    );
  };

  const removePortfolio = (id: number) => {
    handleFiltersChange(
      organisationIds,
      portfolioIds?.filter((item) => item !== id),
      propertySubscriptionUserIds,
      municipalities
    );
  };

  const removePropertySubscriptionUser = (id: number) => {
    handleFiltersChange(
      organisationIds,
      portfolioIds,
      propertySubscriptionUserIds?.filter((item) => item !== id),
      municipalities
    );
  };

  const removeMunicipality = (municipality: string) => {
    handleFiltersChange(
      organisationIds,
      portfolioIds,
      propertySubscriptionUserIds,
      municipalities?.filter((item) => item !== municipality)
    );
  };
  const setFiltersAsDefault = () => {
    setGeneralDefaultFilters(currentUser!.id, {
      organisationIds,
      portfolioIds,
      propertySubscriptionUserIds,
      municipalities
    });
    incrementDefaultFiltersReloadCount();
  };

  const restoreDefaultFilters = () => {
    const { municipalities, organisationIds, portfolioIds, propertySubscriptionUserIds } = defaultFilters ?? {};
    handleFiltersChange(organisationIds, portfolioIds, propertySubscriptionUserIds, municipalities);

    toast.info(t('common:successfullyRestoredDefaultFilters'));
  };

  const filterValues = React.useMemo(() => {
    return { organisationIds, portfolioIds, propertySubscriptionUserIds, municipalities };
  }, [organisationIds, portfolioIds, propertySubscriptionUserIds, municipalities]);

  return (
    <FiltersContext.Provider
      value={{
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        handleFiltersChange,
        organisationsMap,
        organisationsStatus,
        portfoliosMap,
        portfoliosStatus,
        userMembershipsMap,
        membershipsStatus,
        setFiltersAsDefault,
        filterValues,
        removeOrganisation,
        removePortfolio,
        removePropertySubscriptionUser,
        removeMunicipality,
        restoreDefaultFilters,
        defaultFilters
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersProvider;
