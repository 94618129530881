import { DropdownItemProps } from 'semantic-ui-react';
import { localeCompareSv } from '../Utils/string';
import { ListViewPagination, ListViewPaginationParams } from './types';
import { TenantReadView as GeneratedTenantReadView } from '../GeneratedServices';

export interface TenantListViewParams extends ListViewPaginationParams {
  ongoingPropertyId?: number;
  ongoingContractId?: number;
}

export interface TenantListView extends ListViewPagination<TenantReadView> {}

export interface TenantReadView {
  id: number;
  organisationId: number;
  name: string;
  phone: string;
  email: string;
  identification: string;
  type: 'PERSON' | 'COMPANY';
}

export const mapTenantsToDropdownItems = (tenants: GeneratedTenantReadView[]): DropdownItemProps[] => {
  return (
    tenants
      .map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id
      }))
      .sort((a, b) => localeCompareSv(a.text, b.text)) ?? []
  );
};
