import { faFileExport, faFileImport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import LeaseSummary from '../../../Components/LeaseSummary/LeaseSummary';
import LeaseTable from '../../../Components/LeaseTable/LeaseTable';
import { BAM_API_BASE_URL } from '../../../config';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { businessPlanApi } from '../../../Http/Http';
import { hasPermittedReadRoleInOrganisation } from '../../../Utils/permissions';
import BusinessPlanModal from './BusinessPlan/BusinessPlanModal';
import { useNonce } from '../../../Utils/hooks';
import { ContractApiContractResourceListRequest, PropertyReadView } from '../../../GeneratedServices';
import { useTranslation } from 'react-i18next';
import LeaseFiltersModal, {
  getActiveFiltersCount,
  initialLeaseParams
} from '../../../Components/LeaseTable/Components/LeaseFiltersModal';
import FilterButton from '../../../Components/Filters/FilterButton';
import LeaseExportModal from '../../../Components/LeaseTable/Components/LeaseExportModal/LeaseExportModal';
import BusinessPlanImportModal from '../../../Components/BusinessPlanImportModal/BusinessPlanImportModal';

interface Props {
  property: PropertyReadView;
  organisationId?: number;
}

enum Status {
  None,
  ExportingBusinessPlan
}
const TabLeases: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);

  const { handleHttpErrors, currentUser, currentUserMemberships } = React.useContext(globalStateCTX);
  const [isBusinessModalOpen, setBusinessModalOpen] = React.useState<boolean>(false);

  const [status, setStatus] = React.useState<Status>(Status.None);
  const [leasesAndStatisticsNonce, reloadLeasesAndStatistics] = useNonce();

  const isPermitted = hasPermittedReadRoleInOrganisation(currentUser, currentUserMemberships, props.organisationId);

  const [isFiltersModalOpen, setFiltersModalOpen] = React.useState<boolean>(false);
  const [leaseFilterParams, setLeaseFilterParams] =
    React.useState<ContractApiContractResourceListRequest>(initialLeaseParams);

  const [isExportModalOpen, setExportModalOpen] = React.useState<boolean>(false);

  const [isBusinessPlanImportModalOpen, setBusinessPlanImportModalOpen] = React.useState<boolean>(false);

  const exportBusinessPlan = async () => {
    try {
      setStatus(Status.ExportingBusinessPlan);
      const { data } = await businessPlanApi.businessPlanResourceExportExcelBusinessPlanUrl({
        propertyId: props.property.id
      });

      window.location.assign(`${BAM_API_BASE_URL}/v1/business-plan/xlsx/export/${data}`);
    } catch (error) {
      handleHttpErrors(error);
    } finally {
      setStatus(Status.None);
    }
  };

  const areButtonsDisabled = status === Status.ExportingBusinessPlan;
  return (
    <>
      {isPermitted && (
        <>
          <Header as="h3">{t('contracts:keyFigures')}</Header>
          <LeaseSummary propertyId={props.property.id} reloadNonce={leasesAndStatisticsNonce} />
        </>
      )}

      <div style={{ marginTop: isPermitted ? '1em' : undefined }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <Header as="h3">{t('common:leases')}</Header>

          <div>
            {isPermitted && (
              <>
                <Button primary onClick={() => setExportModalOpen(true)} disabled={areButtonsDisabled}>
                  <Icon>
                    <FontAwesomeIcon fixedWidth icon={faFileExport} />
                  </Icon>
                  {t('common:export')}
                </Button>
                <Button
                  primary
                  onClick={exportBusinessPlan}
                  disabled={areButtonsDisabled}
                  loading={status === Status.ExportingBusinessPlan}
                >
                  <Icon>
                    <FontAwesomeIcon fixedWidth icon={faFileExport} />
                  </Icon>
                  {t('contracts:exportBusinessPlan')}
                </Button>

                <Button primary onClick={() => setBusinessPlanImportModalOpen(true)} disabled={areButtonsDisabled}>
                  <Icon>
                    <FontAwesomeIcon fixedWidth icon={faFileImport} />
                  </Icon>
                  {t('contracts:importBusinessPlan')}
                </Button>

                <Button primary onClick={() => setBusinessModalOpen(true)}>
                  {t('contracts:editBusinessPlan')}
                </Button>
              </>
            )}

            <FilterButton
              openFiltersModal={() => setFiltersModalOpen(true)}
              count={getActiveFiltersCount(leaseFilterParams)}
            />
          </div>
        </div>
        <LeaseTable
          propertyId={props.property.id}
          includeTenantData
          reloadNonce={leasesAndStatisticsNonce}
          leaseFilterParams={leaseFilterParams}
        />
      </div>

      {isBusinessModalOpen && (
        <BusinessPlanModal
          organisationId={props.organisationId}
          property={props.property}
          onClose={() => {
            setBusinessModalOpen(false);
            reloadLeasesAndStatistics();
          }}
        />
      )}

      {isFiltersModalOpen && (
        <LeaseFiltersModal
          filterParams={leaseFilterParams}
          onClose={() => setFiltersModalOpen(false)}
          onConfirm={(params) => setLeaseFilterParams(params)}
        />
      )}

      {isExportModalOpen && (
        <LeaseExportModal onClose={() => setExportModalOpen(false)} propertyId={props.property.id} />
      )}

      {isBusinessPlanImportModalOpen && (
        <BusinessPlanImportModal
          onClose={() => setBusinessPlanImportModalOpen(false)}
          organisationId={props.property.organisationId}
        />
      )}
    </>
  );
};

export default TabLeases;
