/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { UserCreateView } from '../model';
// @ts-ignore
import { UserListView } from '../model';
// @ts-ignore
import { UserReadSelfView } from '../model';
// @ts-ignore
import { UserReadView } from '../model';
// @ts-ignore
import { UserUpdateSelfView } from '../model';
// @ts-ignore
import { UserUpdateView } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create user
     * @param {UserCreateView} [userCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceCreate: async (
      userCreateView?: UserCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userCreateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceDeleteSelf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/users/self`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List users
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceList: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userResourceRead', 'id', id);
      const localVarPath = `/v1/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceReadSelf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/users/self`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Request deletion of current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceRequestDeleteSelf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/users/self-request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a user
     * @param {number} id User ID
     * @param {UserUpdateView} [userUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceUpdate: async (
      id: number,
      userUpdateView?: UserUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userResourceUpdate', 'id', id);
      const localVarPath = `/v1/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userUpdateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update current user
     * @param {UserUpdateSelfView} [userUpdateSelfView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceUpdateSelf: async (
      userUpdateSelfView?: UserUpdateSelfView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/users/self`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userUpdateSelfView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create user
     * @param {UserCreateView} [userCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userResourceCreate(
      userCreateView?: UserCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userResourceCreate(userCreateView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userResourceDeleteSelf(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userResourceDeleteSelf(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List users
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userResourceList(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userResourceList(limit, offset, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userResourceReadSelf(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadSelfView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userResourceReadSelf(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Request deletion of current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userResourceRequestDeleteSelf(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userResourceRequestDeleteSelf(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a user
     * @param {number} id User ID
     * @param {UserUpdateView} [userUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userResourceUpdate(
      id: number,
      userUpdateView?: UserUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userResourceUpdate(id, userUpdateView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update current user
     * @param {UserUpdateSelfView} [userUpdateSelfView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userResourceUpdateSelf(
      userUpdateSelfView?: UserUpdateSelfView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadSelfView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userResourceUpdateSelf(userUpdateSelfView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @summary Create user
     * @param {UserCreateView} [userCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceCreate(userCreateView?: UserCreateView, options?: any): AxiosPromise<UserReadView> {
      return localVarFp.userResourceCreate(userCreateView, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceDeleteSelf(options?: any): AxiosPromise<void> {
      return localVarFp.userResourceDeleteSelf(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List users
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceList(limit?: number, offset?: number, options?: any): AxiosPromise<UserListView> {
      return localVarFp.userResourceList(limit, offset, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceRead(id: number, options?: any): AxiosPromise<UserReadView> {
      return localVarFp.userResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceReadSelf(options?: any): AxiosPromise<UserReadSelfView> {
      return localVarFp.userResourceReadSelf(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Request deletion of current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceRequestDeleteSelf(options?: any): AxiosPromise<void> {
      return localVarFp.userResourceRequestDeleteSelf(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a user
     * @param {number} id User ID
     * @param {UserUpdateView} [userUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceUpdate(id: number, userUpdateView?: UserUpdateView, options?: any): AxiosPromise<UserReadView> {
      return localVarFp.userResourceUpdate(id, userUpdateView, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update current user
     * @param {UserUpdateSelfView} [userUpdateSelfView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userResourceUpdateSelf(userUpdateSelfView?: UserUpdateSelfView, options?: any): AxiosPromise<UserReadSelfView> {
      return localVarFp.userResourceUpdateSelf(userUpdateSelfView, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for userResourceCreate operation in UserApi.
 * @export
 * @interface UserApiUserResourceCreateRequest
 */
export interface UserApiUserResourceCreateRequest {
  /**
   *
   * @type {UserCreateView}
   * @memberof UserApiUserResourceCreate
   */
  readonly userCreateView?: UserCreateView;
}

/**
 * Request parameters for userResourceList operation in UserApi.
 * @export
 * @interface UserApiUserResourceListRequest
 */
export interface UserApiUserResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof UserApiUserResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof UserApiUserResourceList
   */
  readonly offset?: number;
}

/**
 * Request parameters for userResourceRead operation in UserApi.
 * @export
 * @interface UserApiUserResourceReadRequest
 */
export interface UserApiUserResourceReadRequest {
  /**
   * User ID
   * @type {number}
   * @memberof UserApiUserResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for userResourceUpdate operation in UserApi.
 * @export
 * @interface UserApiUserResourceUpdateRequest
 */
export interface UserApiUserResourceUpdateRequest {
  /**
   * User ID
   * @type {number}
   * @memberof UserApiUserResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {UserUpdateView}
   * @memberof UserApiUserResourceUpdate
   */
  readonly userUpdateView?: UserUpdateView;
}

/**
 * Request parameters for userResourceUpdateSelf operation in UserApi.
 * @export
 * @interface UserApiUserResourceUpdateSelfRequest
 */
export interface UserApiUserResourceUpdateSelfRequest {
  /**
   *
   * @type {UserUpdateSelfView}
   * @memberof UserApiUserResourceUpdateSelf
   */
  readonly userUpdateSelfView?: UserUpdateSelfView;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Create user
   * @param {UserApiUserResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userResourceCreate(requestParameters: UserApiUserResourceCreateRequest = {}, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userResourceCreate(requestParameters.userCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userResourceDeleteSelf(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userResourceDeleteSelf(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List users
   * @param {UserApiUserResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userResourceList(requestParameters: UserApiUserResourceListRequest = {}, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userResourceList(requestParameters.limit, requestParameters.offset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a user
   * @param {UserApiUserResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userResourceRead(requestParameters: UserApiUserResourceReadRequest, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userResourceReadSelf(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userResourceReadSelf(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Request deletion of current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userResourceRequestDeleteSelf(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userResourceRequestDeleteSelf(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a user
   * @param {UserApiUserResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userResourceUpdate(requestParameters: UserApiUserResourceUpdateRequest, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userResourceUpdate(requestParameters.id, requestParameters.userUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update current user
   * @param {UserApiUserResourceUpdateSelfRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userResourceUpdateSelf(
    requestParameters: UserApiUserResourceUpdateSelfRequest = {},
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userResourceUpdateSelf(requestParameters.userUpdateSelfView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
