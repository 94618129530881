import React from 'react';
import { Navigate } from 'react-router-dom';
import { globalStateCTX } from '../GlobalState/GlobalState';

const AnonymousRoute: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { isAuthenticated } = React.useContext(globalStateCTX);
  return !isAuthenticated ? <>{React.Children.only(props.children)}</> : <Navigate to="/" />;
};

export default AnonymousRoute;
