import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import { FileInfoReadView } from '../../Services/FileService.types';
import styles from '../FileCardList/FileCardList.module.scss';
import FileCardListItem from './FileCardListItem';
import { useFilesReducer } from './Hooks/useFilesReducer';

interface Props {
  files?: FileInfoReadView[];
  reloadFiles?: () => void;
  allowCategoryEditing?: boolean;
  onFileChange?: (action: 'UPDATE' | 'DELETE', file: FileInfoReadView) => void;
  readOnly?: boolean;
  hideDate?: boolean;
  descriptionFontSize?: number;
  dateFontSize?: number;
  loading?: boolean;
  fileIds?: string[] | null;
}

const FileCardList: React.FC<Props> = (props) => {
  const { files, deleteFile, retryLoadingThumbnail } = useFilesReducer({ files: props.files });

  const getLayout = () => {
    if (props.loading) return <FileCardPlaceholderList fileIds={props.fileIds ?? []} />;
    else
      return (
        <>
          {files.map((file) => (
            <FileCardListItem
              key={file.id}
              file={file}
              {...props}
              deleteFile={async (file) => {
                if (await deleteFile(file)) {
                  props.reloadFiles && props.reloadFiles();
                  props.onFileChange && props.onFileChange('DELETE', file);
                }
              }}
              retryLoadingThumbnail={retryLoadingThumbnail}
            />
          ))}
        </>
      );
  };
  return <div className={styles.componentWrapper}>{getLayout()}</div>;
};

const FileCardPlaceholderList = ({ fileIds }: { fileIds: string[] }) => {
  return (
    <>
      {fileIds.map((item) => (
        <Placeholder className={styles.filePlaceholder} key={item}>
          <Placeholder.Image square />
        </Placeholder>
      ))}
    </>
  );
};

export default FileCardList;
