import { ResponsivePie } from '@nivo/pie';
import { Button, Card, Header, Label, Loader } from 'semantic-ui-react';
import styles from './GaugeCard.module.scss';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { KPIStatus } from '../../KPIs/KPICard/KpiCard';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  data: {
    id: string;
    label: string;
    value: number;
    suffix?: string;
    labelWrap?: boolean;
  }[];
  status?: KPIStatus;
  retry?: () => void;
  colors?: string[];
}
const GaugeCard: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common']);

  const pieColors = props.colors ?? [colors.chart7, colors.chart1, colors.chart2];

  const getGaugeContent = () => {
    if (props.status) {
      switch (props.status) {
        case KPIStatus.Loading:
          return layoutLoading;
        case KPIStatus.None:
          return generalLayout;
        case KPIStatus.LoadError:
          return layoutLoadError;
      }
    } else return generalLayout;
  };

  const layoutLoading = (
    <div className={styles.alternateLayoutContainer}>
      <Loader active inline size="medium" />
    </div>
  );

  const layoutLoadError = (
    <div className={styles.alternateLayoutContainer}>
      <Button onClick={props.retry} secondary>
        {t('common:tryAgain')}
      </Button>
    </div>
  );

  const generalLayout = (
    <div style={{ height: 150 }}>
      {props.data.every((item) => item.value === 0) ? (
        <div className={styles.alternateLayoutContainer}>
          <Header as="h3">{t('common:noData')}</Header>
        </div>
      ) : (
        <ResponsivePie
          renderWrapper
          colors={pieColors}
          data={props.data}
          margin={{ top: 0, right: 10, bottom: -70, left: 10 }}
          startAngle={-90}
          endAngle={90}
          innerRadius={0.8}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          fit={false}
          tooltip={(d) => {
            return (
              <Card className={styles.tooltipCard}>
                <Card.Content className={styles.tooltipCardContent}>
                  <Label circular style={{ backgroundColor: d.datum.color }} size="tiny" />
                  <div>{d.datum.data.label}:</div>
                  <div>{d.datum.data.value}</div>
                </Card.Content>
              </Card>
            );
          }}
        />
      )}
    </div>
  );

  return (
    <Card className={styles.card}>
      <Card.Content className={styles.cardContent}>
        <Header className={styles.cardHeader} as="h4">
          {props.title}
        </Header>

        {getGaugeContent()}

        <div className={styles.legendContainer}>
          {props.data.map((item, index) => (
            <div className={styles.legendItem} key={item.id}>
              <Label circular style={{ backgroundColor: pieColors[index] }} size="mini" />
              <span style={{ whiteSpace: item.labelWrap ? 'pre-wrap' : 'nowrap' }}>{item.label}</span>
            </div>
          ))}
        </div>
      </Card.Content>
    </Card>
  );
};

export default GaugeCard;
