/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * List of all coverage ratios.
 * @export
 * @interface CoverageRatioReadView
 */
export interface CoverageRatioReadView {
  /**
   * Contribution ( sum of payments of corresponding type).
   * @type {number}
   * @memberof CoverageRatioReadView
   */
  contribution: number;
  /**
   * Sale price ( sum of transactions of corresponding type).
   * @type {number}
   * @memberof CoverageRatioReadView
   */
  salePrice: number;
  /**
   * Type.
   * @type {string}
   * @memberof CoverageRatioReadView
   */
  type: CoverageRatioReadViewTypeEnum;
}

export const CoverageRatioReadViewTypeEnum = {
  Electricity: 'ELECTRICITY',
  Waste: 'WASTE',
  Heating: 'HEATING',
  RealEstateTaxes: 'REAL_ESTATE_TAXES',
  SnowRemovalAndGarden: 'SNOW_REMOVAL_AND_GARDEN',
  WaterAndSewage: 'WATER_AND_SEWAGE'
} as const;

export type CoverageRatioReadViewTypeEnum =
  (typeof CoverageRatioReadViewTypeEnum)[keyof typeof CoverageRatioReadViewTypeEnum];
