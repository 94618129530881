import { SemanticCOLORS } from 'semantic-ui-react';
import {
  ContractPaymentReadViewAdditionalTypeEnum,
  ContractPaymentReadViewAreaTypeEnum,
  ContractListItemViewStatusEnum,
  ContractListItemView as GeneratedContractReadView
} from '../GeneratedServices';
import i18n from '../i18n';
import { toDateString } from '../Utils/date';

export const translateAdditionalType = (additionalType: ContractPaymentReadViewAdditionalTypeEnum) => {
  switch (additionalType) {
    case ContractPaymentReadViewAdditionalTypeEnum.CoolingAndVentilation:
      return i18n.t('contracts:additionalType.coolingAndVentilation');
    case ContractPaymentReadViewAdditionalTypeEnum.Electricity:
      return i18n.t('contracts:additionalType.electricity');
    case ContractPaymentReadViewAdditionalTypeEnum.Heating:
      return i18n.t('contracts:additionalType.heat');
    case ContractPaymentReadViewAdditionalTypeEnum.Other:
      return i18n.t('contracts:additionalType.otherExpenses');
    case ContractPaymentReadViewAdditionalTypeEnum.PropertyTax:
      return i18n.t('contracts:additionalType.propertyTax');
    case ContractPaymentReadViewAdditionalTypeEnum.AdditionalRent:
      return i18n.t('contracts:additionalType.additionalRent');
    case ContractPaymentReadViewAdditionalTypeEnum.WaterAndSewage:
      return i18n.t('contracts:additionalType.waterAndSewage');
    case ContractPaymentReadViewAdditionalTypeEnum.RentReduction:
      return i18n.t('contracts:additionalType.rentReduction');
    case ContractPaymentReadViewAdditionalTypeEnum.ChargedCost:
      return i18n.t('contracts:additionalType.chargedCost');
    case ContractPaymentReadViewAdditionalTypeEnum.ClaimAndDelay:
      return i18n.t('contracts:additionalType.claimAndDelay');
    case ContractPaymentReadViewAdditionalTypeEnum.Deposit:
      return i18n.t('contracts:additionalType.deposit');
    case ContractPaymentReadViewAdditionalTypeEnum.Waste:
      return i18n.t('contracts:additionalType.waste');
    case ContractPaymentReadViewAdditionalTypeEnum.LandLease:
      return i18n.t('contracts:additionalType.landLease');
    case ContractPaymentReadViewAdditionalTypeEnum.Maintenance:
      return i18n.t('contracts:additionalType.maintenance');
    case ContractPaymentReadViewAdditionalTypeEnum.ParkingAndGarage:
      return i18n.t('contracts:additionalType.parkingAndGarage');
    case ContractPaymentReadViewAdditionalTypeEnum.SnowRemoval:
      return i18n.t('contracts:additionalType.snowRemovalAndGardening');
    case ContractPaymentReadViewAdditionalTypeEnum.TenantImprovements:
      return i18n.t('contracts:additionalType.tenantImprovements');
  }
};

export const translateAreaType = (areaType: ContractPaymentReadViewAreaTypeEnum) => {
  switch (areaType) {
    case ContractPaymentReadViewAreaTypeEnum.Residence:
      return i18n.t('contracts:areaType.residence');
    case ContractPaymentReadViewAreaTypeEnum.Garage:
      return i18n.t('contracts:areaType.garage');
    case ContractPaymentReadViewAreaTypeEnum.IndustrialOffice:
      return i18n.t('contracts:areaType.industrialOffice');
    case ContractPaymentReadViewAreaTypeEnum.Land:
      return i18n.t('contracts:areaType.land');
    case ContractPaymentReadViewAreaTypeEnum.Office:
      return i18n.t('contracts:areaType.office');
    case ContractPaymentReadViewAreaTypeEnum.Other:
      return i18n.t('contracts:areaType.other');
    case ContractPaymentReadViewAreaTypeEnum.Parking:
      return i18n.t('contracts:areaType.parking');
    case ContractPaymentReadViewAreaTypeEnum.Production:
      return i18n.t('contracts:areaType.production');
    case ContractPaymentReadViewAreaTypeEnum.Restaurant:
      return i18n.t('contracts:areaType.restaurant');
    case ContractPaymentReadViewAreaTypeEnum.Store:
      return i18n.t('contracts:areaType.store');
    case ContractPaymentReadViewAreaTypeEnum.Warehouse:
      return i18n.t('contracts:areaType.warehouse');
    case ContractPaymentReadViewAreaTypeEnum.NotAnArea:
      return i18n.t('contracts:areaType.notAnArea');
  }
};

// Contracts which will turn vacant, have their terms changed or  have a tenant change display the moveOutDate+1 (start date of the next contract)
export const getContractStatusContentAndColor = (
  contract: GeneratedContractReadView
): { content: string; color: SemanticCOLORS } => {
  switch (contract.status) {
    case ContractListItemViewStatusEnum.FutureMoveOutWithoutDate:
      return {
        content: i18n.t('contracts:status.futureMoveOutWithoutDate'),
        color: 'red'
      };
    case ContractListItemViewStatusEnum.Extension:
      return {
        content: i18n.t('contracts:status.extension') + contract.terminationDate,
        color: 'green'
      };
    case ContractListItemViewStatusEnum.ExtensionWithin90Days:
      return {
        content: i18n.t('contracts:status.extension') + contract.terminationDate,
        color: 'yellow'
      };
    case ContractListItemViewStatusEnum.FutureVacantWithMoveOut: {
      const upcomingDate = new Date(contract.moveOutDate!);
      upcomingDate.setDate(upcomingDate.getDate() + 1);

      return {
        content: i18n.t('contracts:status.vacant') + toDateString(upcomingDate),
        color: 'red'
      };
    }
    case ContractListItemViewStatusEnum.ChangeOfTerms: {
      const upcomingDate = new Date(contract.moveOutDate!);
      upcomingDate.setDate(upcomingDate.getDate() + 1);

      return {
        content: i18n.t('contracts:status.changeOfTerms') + toDateString(upcomingDate),
        color: 'green'
      };
    }
    case ContractListItemViewStatusEnum.TenantChange: {
      const upcomingDate = new Date(contract.moveOutDate!);
      upcomingDate.setDate(upcomingDate.getDate() + 1);

      return {
        content: i18n.t('contracts:status.tenantChange') + toDateString(upcomingDate),
        color: 'green'
      };
    }
    case ContractListItemViewStatusEnum.MovedOut:
      return {
        content: i18n.t('contracts:status.movedOut') + contract.moveOutDate,
        color: 'grey'
      };
    case ContractListItemViewStatusEnum.Negotiation:
      return {
        content: i18n.t('contracts:status.negotiation') + contract.negotiationBy,
        color: 'orange'
      };
    case ContractListItemViewStatusEnum.OngoingWithoutEnd:
      return {
        content: i18n.t('contracts:status.ongoingWithoutEnd'),
        color: 'green'
      };
    case ContractListItemViewStatusEnum.VacantWithMoveOut: {
      const upcomingDate = new Date(contract.moveOutDate!);
      upcomingDate.setDate(upcomingDate.getDate() + 1);

      return {
        content: i18n.t('contracts:status.vacantWithMoveOut') + toDateString(upcomingDate),
        color: 'green'
      };
    }
    case ContractListItemViewStatusEnum.VacantWithoutMoveOut:
      return {
        content: i18n.t('contracts:status.vacantWithoutMoveOut'),
        color: 'red'
      };
  }
};
