/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface BusinessPlanCostReadView
 */
export interface BusinessPlanCostReadView {
  /**
   * Category.
   * @type {string}
   * @memberof BusinessPlanCostReadView
   */
  category: BusinessPlanCostReadViewCategoryEnum;
  /**
   * ID.
   * @type {number}
   * @memberof BusinessPlanCostReadView
   */
  id: number;
  /**
   * Property ID.
   * @type {number}
   * @memberof BusinessPlanCostReadView
   */
  propertyId: number;
  /**
   * Value.
   * @type {number}
   * @memberof BusinessPlanCostReadView
   */
  value: number;
}

export const BusinessPlanCostReadViewCategoryEnum = {
  OtherOpex: 'OTHER_OPEX',
  ServiceAgreements: 'SERVICE_AGREEMENTS',
  Marketing: 'MARKETING',
  Cleaning: 'CLEANING',
  SnowRemovalGardening: 'SNOW_REMOVAL_GARDENING',
  Security: 'SECURITY',
  Electricity: 'ELECTRICITY',
  Cooling: 'COOLING',
  Heating: 'HEATING',
  WaterSewage: 'WATER_SEWAGE',
  Waste: 'WASTE',
  Insurance: 'INSURANCE',
  LeaseholdFee: 'LEASEHOLD_FEE',
  PropertyTax: 'PROPERTY_TAX',
  PeriodicMaintenance: 'PERIODIC_MAINTENANCE',
  PropertyManagement: 'PROPERTY_MANAGEMENT',
  Repairs: 'REPAIRS'
} as const;

export type BusinessPlanCostReadViewCategoryEnum =
  (typeof BusinessPlanCostReadViewCategoryEnum)[keyof typeof BusinessPlanCostReadViewCategoryEnum];
