import { TrafficLayer, TransitLayer } from '@react-google-maps/api';
import React from 'react';
import { Card, Dropdown, Image, List } from 'semantic-ui-react';
import styles from './MapTypeControl.module.scss';
import RoadmapImage from './images/roadmap.png';
import SatelliteImage from './images/satellite.png';
import TerrainImage from './images/terrain.png';
import TrafficImage from './images/traffic.png';
import TransitImage from './images/transit.png';
import POIImage from './images/poi.png';
import { useTranslation } from 'react-i18next';

interface Props {
  map: google.maps.Map | null;
}

const mapStyles = {
  default: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    }
  ],
  showPOI: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'on' }]
    }
  ]
};

const MapTypeControl: React.FC<Props> = (props) => {
  const { t } = useTranslation(['analytics', 'common']);

  const customControlRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = React.useState<boolean>(false);

  const [isTransitSelected, setTransitSelected] = React.useState<boolean>();
  const [isTrafficSelected, setTrafficSelected] = React.useState<boolean>();
  const [arePOIsShown, setPOIsShown] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.map) {
      if (arePOIsShown) {
        props.map?.setOptions({ styles: mapStyles.showPOI });
      } else props.map?.setOptions({ styles: mapStyles.default });
    }
  }, [arePOIsShown, props.map]);

  React.useEffect(() => {
    if (props.map) {
      const mapTypeControlDiv = document.createElement('div');

      const customControlDiv = customControlRef.current;
      mapTypeControlDiv.appendChild(customControlDiv as Node);
      props.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(mapTypeControlDiv);
    }
  }, [props.map]);

  const currentMapTypeId = props.map?.getMapTypeId();

  const handleSateliteClick = () => {
    props.map?.setMapTypeId(google.maps.MapTypeId.HYBRID);
    setOpen(false);
  };

  const handleRoadmapClick = () => {
    props.map?.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    setOpen(false);
  };

  const handleTerrainClick = () => {
    props.map?.setMapTypeId(google.maps.MapTypeId.TERRAIN);
    setOpen(false);
  };

  const getImageFromMapType = () => {
    if (currentMapTypeId === google.maps.MapTypeId.TERRAIN) return TerrainImage;
    else if (currentMapTypeId === google.maps.MapTypeId.HYBRID) return SatelliteImage;
    else return RoadmapImage;
  };

  return (
    <div className={styles.mapTypeControl} ref={customControlRef}>
      <Dropdown
        trigger={
          <Card className={styles.dropdownTrigger} onClick={() => setOpen((prevValue) => !prevValue)}>
            <Image src={getImageFromMapType()} />
          </Card>
        }
        open={isOpen}
        pointing="bottom"
        icon={null}
      >
        <Dropdown.Menu className={styles.dropdownMenu}>
          <List selection className={styles.dropdownContent}>
            <List.Item
              onClick={handleRoadmapClick}
              active={currentMapTypeId === google.maps.MapTypeId.ROADMAP}
              className={styles.dropdownOption}
            >
              <div className={styles.optionCard}>
                <Image src={RoadmapImage} />
                {t('common:map')}
              </div>
            </List.Item>

            <List.Item
              onClick={handleSateliteClick}
              active={currentMapTypeId === google.maps.MapTypeId.HYBRID}
              className={styles.dropdownOption}
            >
              <div className={styles.optionCard}>
                <Image src={SatelliteImage} />
                {t('analytics:satelite')}
              </div>
            </List.Item>

            <List.Item
              onClick={handleTerrainClick}
              active={currentMapTypeId === google.maps.MapTypeId.TERRAIN}
              className={styles.dropdownOption}
            >
              <div className={styles.optionCard}>
                <Image src={TerrainImage} />
                {t('analytics:terrain')}
              </div>
            </List.Item>
          </List>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown trigger={<div className={styles.emptyTrigger} />} open={isOpen} pointing="left" icon={null}>
        <Dropdown.Menu className={styles.dropdownMenu}>
          <List selection horizontal className={styles.dropdownContent}>
            <List.Item />

            <List.Item
              onClick={() => setTrafficSelected((prevValue) => !prevValue)}
              active={isTrafficSelected}
              className={styles.dropdownOption}
            >
              <div className={styles.optionCard}>
                <Image src={TrafficImage} />
                {t('analytics:traffic')}
              </div>
            </List.Item>

            <List.Item
              onClick={() => setTransitSelected((prevValue) => !prevValue)}
              active={isTransitSelected}
              className={styles.dropdownOption}
            >
              <div className={styles.optionCard}>
                <Image src={TransitImage} />
                {t('analytics:publicTransport')}
              </div>
            </List.Item>

            <List.Item
              onClick={() => {
                setPOIsShown((prevValue) => !prevValue);
              }}
              active={arePOIsShown}
              className={styles.dropdownOption}
            >
              <div className={styles.optionCard}>
                <Image src={POIImage} />
                {t('analytics:pointsOfInterest')}
              </div>
            </List.Item>
            <List.Item className={styles.lastItem} />
          </List>
        </Dropdown.Menu>
      </Dropdown>

      {isTrafficSelected && (
        <TrafficLayer
          options={{
            autoRefresh: true
          }}
        />
      )}
      {isTransitSelected && <TransitLayer />}
    </div>
  );
};

export default MapTypeControl;
