import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Button } from 'semantic-ui-react';
import EditPropertyCheckModal from '../../../Components/PropertyChecks/EditPropertyCheckModal';
import PropertyChecksTable from '../../../Components/PropertyChecks/PropertyChecksTable';
import { useNonce } from '../../../Utils/hooks';
import CopyRoutineTasksModal from './CopyRoutineTasksModal';

interface Props {
  propertyId: number;
  organisationId?: number;
}

const TabPropertyChecks: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'propertyChecks']);

  const [newPropertyCheckModalOpen, setNewPropertyCheckModalOpen] = React.useState(false);
  const [reloadNonce, setReloadNonce] = useNonce();

  const [copyModalType, setCopyModalType] = React.useState<'import' | 'export'>();
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10 }}>
        <Header as="h3">{t('common:propertyChecks')}</Header>

        <div style={{ gap: 5, display: 'flex' }}>
          <Button primary onClick={() => setCopyModalType('import')}>
            {t('common:import')}
          </Button>

          <Button primary onClick={() => setCopyModalType('export')}>
            {t('common:export')}
          </Button>

          <Button
            content={t('propertyChecks:newControlPoint')}
            primary
            onClick={() => setNewPropertyCheckModalOpen(true)}
          />
        </div>
      </div>

      <PropertyChecksTable propertyId={props.propertyId} refreshNonce={reloadNonce} />

      {newPropertyCheckModalOpen && (
        <EditPropertyCheckModal
          action={{ propertyId: props.propertyId }}
          onClose={(saved) => {
            setNewPropertyCheckModalOpen(false);

            if (saved) {
              setReloadNonce();
            }
          }}
        />
      )}

      {props.organisationId && copyModalType && (
        <CopyRoutineTasksModal
          type={copyModalType}
          organisationId={props.organisationId}
          propertyId={props.propertyId}
          onClose={(saved) => {
            if (saved) {
              setReloadNonce();
            }
            setCopyModalType(undefined);
          }}
        />
      )}
    </>
  );
};

export default TabPropertyChecks;
