/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { PropertyCheckApplyView } from '../model';
// @ts-ignore
import { PropertyCheckCreateView } from '../model';
// @ts-ignore
import { PropertyCheckListView } from '../model';
// @ts-ignore
import { PropertyCheckReadView } from '../model';
// @ts-ignore
import { PropertyCheckUpdateView } from '../model';
// @ts-ignore
import { PropertyListView } from '../model';
// @ts-ignore
import { PropertyReadView } from '../model';
// @ts-ignore
import { PropertyUpdateView } from '../model';
// @ts-ignore
import { SearchReadView } from '../model';
/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Copy property checks from one property to others
     * @param {number} id From property Id
     * @param {PropertyCheckApplyView} [propertyCheckApplyView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceCopyFromProperty: async (
      id: number,
      propertyCheckApplyView?: PropertyCheckApplyView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('propertyCheckResourceCopyFromProperty', 'id', id);
      const localVarPath = `/v1/property-checks/copy-from-property/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        propertyCheckApplyView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a property check
     * @param {PropertyCheckCreateView} [propertyCheckCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceCreate: async (
      propertyCheckCreateView?: PropertyCheckCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/property-checks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        propertyCheckCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a property check
     * @param {number} id Property check ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('propertyCheckResourceDelete', 'id', id);
      const localVarPath = `/v1/property-checks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List property checks
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {boolean} [isGeneral] Filter by the organisation\&#39;s general list
     * @param {number} [caretakerId] Filter by caretaker
     * @param {number} [propertyManagerId] Filter by property manager
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {'deadline_asc'} [sortBy] Sort by
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceList: async (
      limit?: number,
      offset?: number,
      propertyId?: number,
      isGeneral?: boolean,
      caretakerId?: number,
      propertyManagerId?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      sortBy?: 'deadline_asc',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/property-checks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (isGeneral !== undefined) {
        localVarQueryParameter['isGeneral'] = isGeneral;
      }

      if (caretakerId !== undefined) {
        localVarQueryParameter['caretakerId'] = caretakerId;
      }

      if (propertyManagerId !== undefined) {
        localVarQueryParameter['propertyManagerId'] = propertyManagerId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyAssigneeId !== undefined) {
        localVarQueryParameter['propertyAssigneeId'] = propertyAssigneeId;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a property check
     * @param {number} id Property check ID
     * @param {PropertyCheckUpdateView} [propertyCheckUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourcePatch: async (
      id: number,
      propertyCheckUpdateView?: PropertyCheckUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('propertyCheckResourcePatch', 'id', id);
      const localVarPath = `/v1/property-checks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        propertyCheckUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a property check
     * @param {number} id Property check ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('propertyCheckResourceRead', 'id', id);
      const localVarPath = `/v1/property-checks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List properties
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [caretakerId] Filter by caretaker
     * @param {number} [receivedWorkOrdersCountGt] Filter by count of received work orders (greater than)
     * @param {number} [startedWorkOrdersCountGt] Filter by count of started work orders (greater than)
     * @param {number} [finishedWorkOrdersCountGt] Filter by count of finished work orders (greater than)
     * @param {'name_asc'} [sortBy] Sort by
     * @param {string} [search] Filter by search term
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by properties assigned to the user
     * @param {number} [activeFaultReportsCountGt] Filter by count of active fault reports (status &#x3D; RECIEVED or status &#x3D; STARTED)
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      companyId?: number,
      caretakerId?: number,
      receivedWorkOrdersCountGt?: number,
      startedWorkOrdersCountGt?: number,
      finishedWorkOrdersCountGt?: number,
      sortBy?: 'name_asc',
      search?: string,
      portfolioId?: number,
      assigneeId?: number,
      activeFaultReportsCountGt?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/properties`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (caretakerId !== undefined) {
        localVarQueryParameter['caretakerId'] = caretakerId;
      }

      if (receivedWorkOrdersCountGt !== undefined) {
        localVarQueryParameter['receivedWorkOrdersCount_gt'] = receivedWorkOrdersCountGt;
      }

      if (startedWorkOrdersCountGt !== undefined) {
        localVarQueryParameter['startedWorkOrdersCount_gt'] = startedWorkOrdersCountGt;
      }

      if (finishedWorkOrdersCountGt !== undefined) {
        localVarQueryParameter['finishedWorkOrdersCount_gt'] = finishedWorkOrdersCountGt;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (activeFaultReportsCountGt !== undefined) {
        localVarQueryParameter['activeFaultReportsCount_gt'] = activeFaultReportsCountGt;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a property
     * @param {number} id Property ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('propertyResourceRead', 'id', id);
      const localVarPath = `/v1/properties/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a property
     * @param {number} id Property ID
     * @param {PropertyUpdateView} [propertyUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyResourceUpdate: async (
      id: number,
      propertyUpdateView?: PropertyUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('propertyResourceUpdate', 'id', id);
      const localVarPath = `/v1/properties/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(propertyUpdateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchResourceList: async (
      query: string,
      limit: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchResourceList', 'query', query);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('searchResourceList', 'limit', limit);
      const localVarPath = `/v1/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PropertyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Copy property checks from one property to others
     * @param {number} id From property Id
     * @param {PropertyCheckApplyView} [propertyCheckApplyView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyCheckResourceCopyFromProperty(
      id: number,
      propertyCheckApplyView?: PropertyCheckApplyView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyCheckReadView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyCheckResourceCopyFromProperty(
        id,
        propertyCheckApplyView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a property check
     * @param {PropertyCheckCreateView} [propertyCheckCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyCheckResourceCreate(
      propertyCheckCreateView?: PropertyCheckCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyCheckReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyCheckResourceCreate(
        propertyCheckCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a property check
     * @param {number} id Property check ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyCheckResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyCheckResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List property checks
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {boolean} [isGeneral] Filter by the organisation\&#39;s general list
     * @param {number} [caretakerId] Filter by caretaker
     * @param {number} [propertyManagerId] Filter by property manager
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {'deadline_asc'} [sortBy] Sort by
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyCheckResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      isGeneral?: boolean,
      caretakerId?: number,
      propertyManagerId?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      sortBy?: 'deadline_asc',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyCheckListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyCheckResourceList(
        limit,
        offset,
        propertyId,
        isGeneral,
        caretakerId,
        propertyManagerId,
        organisationId,
        portfolioId,
        propertyAssigneeId,
        sortBy,
        search,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a property check
     * @param {number} id Property check ID
     * @param {PropertyCheckUpdateView} [propertyCheckUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyCheckResourcePatch(
      id: number,
      propertyCheckUpdateView?: PropertyCheckUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyCheckReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyCheckResourcePatch(
        id,
        propertyCheckUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a property check
     * @param {number} id Property check ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyCheckResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyCheckReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyCheckResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List properties
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [caretakerId] Filter by caretaker
     * @param {number} [receivedWorkOrdersCountGt] Filter by count of received work orders (greater than)
     * @param {number} [startedWorkOrdersCountGt] Filter by count of started work orders (greater than)
     * @param {number} [finishedWorkOrdersCountGt] Filter by count of finished work orders (greater than)
     * @param {'name_asc'} [sortBy] Sort by
     * @param {string} [search] Filter by search term
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by properties assigned to the user
     * @param {number} [activeFaultReportsCountGt] Filter by count of active fault reports (status &#x3D; RECIEVED or status &#x3D; STARTED)
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      companyId?: number,
      caretakerId?: number,
      receivedWorkOrdersCountGt?: number,
      startedWorkOrdersCountGt?: number,
      finishedWorkOrdersCountGt?: number,
      sortBy?: 'name_asc',
      search?: string,
      portfolioId?: number,
      assigneeId?: number,
      activeFaultReportsCountGt?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyResourceList(
        limit,
        offset,
        organisationId,
        companyId,
        caretakerId,
        receivedWorkOrdersCountGt,
        startedWorkOrdersCountGt,
        finishedWorkOrdersCountGt,
        sortBy,
        search,
        portfolioId,
        assigneeId,
        activeFaultReportsCountGt,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a property
     * @param {number} id Property ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a property
     * @param {number} id Property ID
     * @param {PropertyUpdateView} [propertyUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertyResourceUpdate(
      id: number,
      propertyUpdateView?: PropertyUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertyResourceUpdate(id, propertyUpdateView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchResourceList(
      query: string,
      limit: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchResourceList(query, limit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PropertyApiFp(configuration);
  return {
    /**
     *
     * @summary Copy property checks from one property to others
     * @param {number} id From property Id
     * @param {PropertyCheckApplyView} [propertyCheckApplyView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceCopyFromProperty(
      id: number,
      propertyCheckApplyView?: PropertyCheckApplyView,
      options?: any
    ): AxiosPromise<Array<PropertyCheckReadView>> {
      return localVarFp
        .propertyCheckResourceCopyFromProperty(id, propertyCheckApplyView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a property check
     * @param {PropertyCheckCreateView} [propertyCheckCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceCreate(
      propertyCheckCreateView?: PropertyCheckCreateView,
      options?: any
    ): AxiosPromise<PropertyCheckReadView> {
      return localVarFp
        .propertyCheckResourceCreate(propertyCheckCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a property check
     * @param {number} id Property check ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.propertyCheckResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List property checks
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {boolean} [isGeneral] Filter by the organisation\&#39;s general list
     * @param {number} [caretakerId] Filter by caretaker
     * @param {number} [propertyManagerId] Filter by property manager
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {'deadline_asc'} [sortBy] Sort by
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      isGeneral?: boolean,
      caretakerId?: number,
      propertyManagerId?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      sortBy?: 'deadline_asc',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<PropertyCheckListView> {
      return localVarFp
        .propertyCheckResourceList(
          limit,
          offset,
          propertyId,
          isGeneral,
          caretakerId,
          propertyManagerId,
          organisationId,
          portfolioId,
          propertyAssigneeId,
          sortBy,
          search,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a property check
     * @param {number} id Property check ID
     * @param {PropertyCheckUpdateView} [propertyCheckUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourcePatch(
      id: number,
      propertyCheckUpdateView?: PropertyCheckUpdateView,
      options?: any
    ): AxiosPromise<PropertyCheckReadView> {
      return localVarFp
        .propertyCheckResourcePatch(id, propertyCheckUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a property check
     * @param {number} id Property check ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyCheckResourceRead(id: number, options?: any): AxiosPromise<PropertyCheckReadView> {
      return localVarFp.propertyCheckResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List properties
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [caretakerId] Filter by caretaker
     * @param {number} [receivedWorkOrdersCountGt] Filter by count of received work orders (greater than)
     * @param {number} [startedWorkOrdersCountGt] Filter by count of started work orders (greater than)
     * @param {number} [finishedWorkOrdersCountGt] Filter by count of finished work orders (greater than)
     * @param {'name_asc'} [sortBy] Sort by
     * @param {string} [search] Filter by search term
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by properties assigned to the user
     * @param {number} [activeFaultReportsCountGt] Filter by count of active fault reports (status &#x3D; RECIEVED or status &#x3D; STARTED)
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      companyId?: number,
      caretakerId?: number,
      receivedWorkOrdersCountGt?: number,
      startedWorkOrdersCountGt?: number,
      finishedWorkOrdersCountGt?: number,
      sortBy?: 'name_asc',
      search?: string,
      portfolioId?: number,
      assigneeId?: number,
      activeFaultReportsCountGt?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<PropertyListView> {
      return localVarFp
        .propertyResourceList(
          limit,
          offset,
          organisationId,
          companyId,
          caretakerId,
          receivedWorkOrdersCountGt,
          startedWorkOrdersCountGt,
          finishedWorkOrdersCountGt,
          sortBy,
          search,
          portfolioId,
          assigneeId,
          activeFaultReportsCountGt,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a property
     * @param {number} id Property ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyResourceRead(id: number, options?: any): AxiosPromise<PropertyReadView> {
      return localVarFp.propertyResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a property
     * @param {number} id Property ID
     * @param {PropertyUpdateView} [propertyUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyResourceUpdate(
      id: number,
      propertyUpdateView?: PropertyUpdateView,
      options?: any
    ): AxiosPromise<PropertyReadView> {
      return localVarFp
        .propertyResourceUpdate(id, propertyUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchResourceList(query: string, limit: number, options?: any): AxiosPromise<SearchReadView> {
      return localVarFp.searchResourceList(query, limit, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for propertyCheckResourceCopyFromProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyCheckResourceCopyFromPropertyRequest
 */
export interface PropertyApiPropertyCheckResourceCopyFromPropertyRequest {
  /**
   * From property Id
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceCopyFromProperty
   */
  readonly id: number;

  /**
   *
   * @type {PropertyCheckApplyView}
   * @memberof PropertyApiPropertyCheckResourceCopyFromProperty
   */
  readonly propertyCheckApplyView?: PropertyCheckApplyView;
}

/**
 * Request parameters for propertyCheckResourceCreate operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyCheckResourceCreateRequest
 */
export interface PropertyApiPropertyCheckResourceCreateRequest {
  /**
   *
   * @type {PropertyCheckCreateView}
   * @memberof PropertyApiPropertyCheckResourceCreate
   */
  readonly propertyCheckCreateView?: PropertyCheckCreateView;
}

/**
 * Request parameters for propertyCheckResourceDelete operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyCheckResourceDeleteRequest
 */
export interface PropertyApiPropertyCheckResourceDeleteRequest {
  /**
   * Property check ID
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for propertyCheckResourceList operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyCheckResourceListRequest
 */
export interface PropertyApiPropertyCheckResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly offset?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by the organisation\&#39;s general list
   * @type {boolean}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly isGeneral?: boolean;

  /**
   * Filter by caretaker
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly caretakerId?: number;

  /**
   * Filter by property manager
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly propertyManagerId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by property\&#39;s portfolio
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by property\&#39;s assignee
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly propertyAssigneeId?: number;

  /**
   * Sort by
   * @type {'deadline_asc'}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly sortBy?: 'deadline_asc';

  /**
   * Filter by search term
   * @type {string}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly search?: string;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof PropertyApiPropertyCheckResourceList
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for propertyCheckResourcePatch operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyCheckResourcePatchRequest
 */
export interface PropertyApiPropertyCheckResourcePatchRequest {
  /**
   * Property check ID
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourcePatch
   */
  readonly id: number;

  /**
   *
   * @type {PropertyCheckUpdateView}
   * @memberof PropertyApiPropertyCheckResourcePatch
   */
  readonly propertyCheckUpdateView?: PropertyCheckUpdateView;
}

/**
 * Request parameters for propertyCheckResourceRead operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyCheckResourceReadRequest
 */
export interface PropertyApiPropertyCheckResourceReadRequest {
  /**
   * Property check ID
   * @type {number}
   * @memberof PropertyApiPropertyCheckResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for propertyResourceList operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyResourceListRequest
 */
export interface PropertyApiPropertyResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by company
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly companyId?: number;

  /**
   * Filter by caretaker
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly caretakerId?: number;

  /**
   * Filter by count of received work orders (greater than)
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly receivedWorkOrdersCountGt?: number;

  /**
   * Filter by count of started work orders (greater than)
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly startedWorkOrdersCountGt?: number;

  /**
   * Filter by count of finished work orders (greater than)
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly finishedWorkOrdersCountGt?: number;

  /**
   * Sort by
   * @type {'name_asc'}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly sortBy?: 'name_asc';

  /**
   * Filter by search term
   * @type {string}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly search?: string;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by properties assigned to the user
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly assigneeId?: number;

  /**
   * Filter by count of active fault reports (status &#x3D; RECIEVED or status &#x3D; STARTED)
   * @type {number}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly activeFaultReportsCountGt?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof PropertyApiPropertyResourceList
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for propertyResourceRead operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyResourceReadRequest
 */
export interface PropertyApiPropertyResourceReadRequest {
  /**
   * Property ID
   * @type {number}
   * @memberof PropertyApiPropertyResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for propertyResourceUpdate operation in PropertyApi.
 * @export
 * @interface PropertyApiPropertyResourceUpdateRequest
 */
export interface PropertyApiPropertyResourceUpdateRequest {
  /**
   * Property ID
   * @type {number}
   * @memberof PropertyApiPropertyResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PropertyUpdateView}
   * @memberof PropertyApiPropertyResourceUpdate
   */
  readonly propertyUpdateView?: PropertyUpdateView;
}

/**
 * Request parameters for searchResourceList operation in PropertyApi.
 * @export
 * @interface PropertyApiSearchResourceListRequest
 */
export interface PropertyApiSearchResourceListRequest {
  /**
   * Search term
   * @type {string}
   * @memberof PropertyApiSearchResourceList
   */
  readonly query: string;

  /**
   * Maximum amount of items per kind
   * @type {number}
   * @memberof PropertyApiSearchResourceList
   */
  readonly limit: number;
}

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
  /**
   *
   * @summary Copy property checks from one property to others
   * @param {PropertyApiPropertyCheckResourceCopyFromPropertyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyCheckResourceCopyFromProperty(
    requestParameters: PropertyApiPropertyCheckResourceCopyFromPropertyRequest,
    options?: AxiosRequestConfig
  ) {
    return PropertyApiFp(this.configuration)
      .propertyCheckResourceCopyFromProperty(requestParameters.id, requestParameters.propertyCheckApplyView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a property check
   * @param {PropertyApiPropertyCheckResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyCheckResourceCreate(
    requestParameters: PropertyApiPropertyCheckResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PropertyApiFp(this.configuration)
      .propertyCheckResourceCreate(requestParameters.propertyCheckCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a property check
   * @param {PropertyApiPropertyCheckResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyCheckResourceDelete(
    requestParameters: PropertyApiPropertyCheckResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return PropertyApiFp(this.configuration)
      .propertyCheckResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List property checks
   * @param {PropertyApiPropertyCheckResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyCheckResourceList(
    requestParameters: PropertyApiPropertyCheckResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PropertyApiFp(this.configuration)
      .propertyCheckResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.propertyId,
        requestParameters.isGeneral,
        requestParameters.caretakerId,
        requestParameters.propertyManagerId,
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.propertyAssigneeId,
        requestParameters.sortBy,
        requestParameters.search,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a property check
   * @param {PropertyApiPropertyCheckResourcePatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyCheckResourcePatch(
    requestParameters: PropertyApiPropertyCheckResourcePatchRequest,
    options?: AxiosRequestConfig
  ) {
    return PropertyApiFp(this.configuration)
      .propertyCheckResourcePatch(requestParameters.id, requestParameters.propertyCheckUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a property check
   * @param {PropertyApiPropertyCheckResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyCheckResourceRead(
    requestParameters: PropertyApiPropertyCheckResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return PropertyApiFp(this.configuration)
      .propertyCheckResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List properties
   * @param {PropertyApiPropertyResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyResourceList(
    requestParameters: PropertyApiPropertyResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PropertyApiFp(this.configuration)
      .propertyResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.companyId,
        requestParameters.caretakerId,
        requestParameters.receivedWorkOrdersCountGt,
        requestParameters.startedWorkOrdersCountGt,
        requestParameters.finishedWorkOrdersCountGt,
        requestParameters.sortBy,
        requestParameters.search,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.activeFaultReportsCountGt,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a property
   * @param {PropertyApiPropertyResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyResourceRead(requestParameters: PropertyApiPropertyResourceReadRequest, options?: AxiosRequestConfig) {
    return PropertyApiFp(this.configuration)
      .propertyResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a property
   * @param {PropertyApiPropertyResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyResourceUpdate(
    requestParameters: PropertyApiPropertyResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return PropertyApiFp(this.configuration)
      .propertyResourceUpdate(requestParameters.id, requestParameters.propertyUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search for things
   * @param {PropertyApiSearchResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public searchResourceList(requestParameters: PropertyApiSearchResourceListRequest, options?: AxiosRequestConfig) {
    return PropertyApiFp(this.configuration)
      .searchResourceList(requestParameters.query, requestParameters.limit, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
