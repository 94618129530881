/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { OrganisationDataSourceCreateView } from '../model';
// @ts-ignore
import { OrganisationDataSourceListView } from '../model';
// @ts-ignore
import { OrganisationDataSourceReadView } from '../model';
// @ts-ignore
import { OrganisationDataSourceTriggerView } from '../model';
// @ts-ignore
import { OrganisationDataSourceUpdateView } from '../model';
/**
 * DatasourceApi - axios parameter creator
 * @export
 */
export const DatasourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create an organisation\'s data source
     * @param {OrganisationDataSourceCreateView} [organisationDataSourceCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceCreate: async (
      organisationDataSourceCreateView?: OrganisationDataSourceCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/organisation-data-sources`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        organisationDataSourceCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceDelete: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('organisationDataSourceResourceDelete', 'id', id);
      const localVarPath = `/v1/organisation-data-sources/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List organisation data sources
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {'STRIFAST' | 'EDISON' | 'DUMMY' | 'METRY'} [dataSource] Filter by kind of data source
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceList: async (
      limit?: number,
      offset?: number,
      dataSource?: 'STRIFAST' | 'EDISON' | 'DUMMY' | 'METRY',
      organisationId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/organisation-data-sources`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (dataSource !== undefined) {
        localVarQueryParameter['dataSource'] = dataSource;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('organisationDataSourceResourceRead', 'id', id);
      const localVarPath = `/v1/organisation-data-sources/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * The method will short-circuit if the data source import is already in progress.
     * @summary Trigger import from the data source
     * @param {number} id Organisation data source ID
     * @param {OrganisationDataSourceTriggerView} [organisationDataSourceTriggerView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceTrigger: async (
      id: number,
      organisationDataSourceTriggerView?: OrganisationDataSourceTriggerView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('organisationDataSourceResourceTrigger', 'id', id);
      const localVarPath = `/v1/organisation-data-sources/{id}/trigger`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        organisationDataSourceTriggerView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {OrganisationDataSourceUpdateView} [organisationDataSourceUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceUpdate: async (
      id: number,
      organisationDataSourceUpdateView?: OrganisationDataSourceUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('organisationDataSourceResourceUpdate', 'id', id);
      const localVarPath = `/v1/organisation-data-sources/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        organisationDataSourceUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DatasourceApi - functional programming interface
 * @export
 */
export const DatasourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DatasourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create an organisation\'s data source
     * @param {OrganisationDataSourceCreateView} [organisationDataSourceCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationDataSourceResourceCreate(
      organisationDataSourceCreateView?: OrganisationDataSourceCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDataSourceReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationDataSourceResourceCreate(
        organisationDataSourceCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationDataSourceResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationDataSourceResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List organisation data sources
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {'STRIFAST' | 'EDISON' | 'DUMMY' | 'METRY'} [dataSource] Filter by kind of data source
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationDataSourceResourceList(
      limit?: number,
      offset?: number,
      dataSource?: 'STRIFAST' | 'EDISON' | 'DUMMY' | 'METRY',
      organisationId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDataSourceListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationDataSourceResourceList(
        limit,
        offset,
        dataSource,
        organisationId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationDataSourceResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDataSourceReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationDataSourceResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The method will short-circuit if the data source import is already in progress.
     * @summary Trigger import from the data source
     * @param {number} id Organisation data source ID
     * @param {OrganisationDataSourceTriggerView} [organisationDataSourceTriggerView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationDataSourceResourceTrigger(
      id: number,
      organisationDataSourceTriggerView?: OrganisationDataSourceTriggerView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationDataSourceResourceTrigger(
        id,
        organisationDataSourceTriggerView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {OrganisationDataSourceUpdateView} [organisationDataSourceUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organisationDataSourceResourceUpdate(
      id: number,
      organisationDataSourceUpdateView?: OrganisationDataSourceUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDataSourceReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organisationDataSourceResourceUpdate(
        id,
        organisationDataSourceUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * DatasourceApi - factory interface
 * @export
 */
export const DatasourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DatasourceApiFp(configuration);
  return {
    /**
     *
     * @summary Create an organisation\'s data source
     * @param {OrganisationDataSourceCreateView} [organisationDataSourceCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceCreate(
      organisationDataSourceCreateView?: OrganisationDataSourceCreateView,
      options?: any
    ): AxiosPromise<OrganisationDataSourceReadView> {
      return localVarFp
        .organisationDataSourceResourceCreate(organisationDataSourceCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.organisationDataSourceResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List organisation data sources
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {'STRIFAST' | 'EDISON' | 'DUMMY' | 'METRY'} [dataSource] Filter by kind of data source
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceList(
      limit?: number,
      offset?: number,
      dataSource?: 'STRIFAST' | 'EDISON' | 'DUMMY' | 'METRY',
      organisationId?: number,
      options?: any
    ): AxiosPromise<OrganisationDataSourceListView> {
      return localVarFp
        .organisationDataSourceResourceList(limit, offset, dataSource, organisationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceRead(id: number, options?: any): AxiosPromise<OrganisationDataSourceReadView> {
      return localVarFp.organisationDataSourceResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     * The method will short-circuit if the data source import is already in progress.
     * @summary Trigger import from the data source
     * @param {number} id Organisation data source ID
     * @param {OrganisationDataSourceTriggerView} [organisationDataSourceTriggerView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceTrigger(
      id: number,
      organisationDataSourceTriggerView?: OrganisationDataSourceTriggerView,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .organisationDataSourceResourceTrigger(id, organisationDataSourceTriggerView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update an organisation\'s data source
     * @param {number} id Organisation data source ID
     * @param {OrganisationDataSourceUpdateView} [organisationDataSourceUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organisationDataSourceResourceUpdate(
      id: number,
      organisationDataSourceUpdateView?: OrganisationDataSourceUpdateView,
      options?: any
    ): AxiosPromise<OrganisationDataSourceReadView> {
      return localVarFp
        .organisationDataSourceResourceUpdate(id, organisationDataSourceUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for organisationDataSourceResourceCreate operation in DatasourceApi.
 * @export
 * @interface DatasourceApiOrganisationDataSourceResourceCreateRequest
 */
export interface DatasourceApiOrganisationDataSourceResourceCreateRequest {
  /**
   *
   * @type {OrganisationDataSourceCreateView}
   * @memberof DatasourceApiOrganisationDataSourceResourceCreate
   */
  readonly organisationDataSourceCreateView?: OrganisationDataSourceCreateView;
}

/**
 * Request parameters for organisationDataSourceResourceDelete operation in DatasourceApi.
 * @export
 * @interface DatasourceApiOrganisationDataSourceResourceDeleteRequest
 */
export interface DatasourceApiOrganisationDataSourceResourceDeleteRequest {
  /**
   * Organisation data source ID
   * @type {number}
   * @memberof DatasourceApiOrganisationDataSourceResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for organisationDataSourceResourceList operation in DatasourceApi.
 * @export
 * @interface DatasourceApiOrganisationDataSourceResourceListRequest
 */
export interface DatasourceApiOrganisationDataSourceResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof DatasourceApiOrganisationDataSourceResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof DatasourceApiOrganisationDataSourceResourceList
   */
  readonly offset?: number;

  /**
   * Filter by kind of data source
   * @type {'STRIFAST' | 'EDISON' | 'DUMMY' | 'METRY'}
   * @memberof DatasourceApiOrganisationDataSourceResourceList
   */
  readonly dataSource?: 'STRIFAST' | 'EDISON' | 'DUMMY' | 'METRY';

  /**
   * Filter by organisation
   * @type {number}
   * @memberof DatasourceApiOrganisationDataSourceResourceList
   */
  readonly organisationId?: number;
}

/**
 * Request parameters for organisationDataSourceResourceRead operation in DatasourceApi.
 * @export
 * @interface DatasourceApiOrganisationDataSourceResourceReadRequest
 */
export interface DatasourceApiOrganisationDataSourceResourceReadRequest {
  /**
   * Organisation data source ID
   * @type {number}
   * @memberof DatasourceApiOrganisationDataSourceResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for organisationDataSourceResourceTrigger operation in DatasourceApi.
 * @export
 * @interface DatasourceApiOrganisationDataSourceResourceTriggerRequest
 */
export interface DatasourceApiOrganisationDataSourceResourceTriggerRequest {
  /**
   * Organisation data source ID
   * @type {number}
   * @memberof DatasourceApiOrganisationDataSourceResourceTrigger
   */
  readonly id: number;

  /**
   *
   * @type {OrganisationDataSourceTriggerView}
   * @memberof DatasourceApiOrganisationDataSourceResourceTrigger
   */
  readonly organisationDataSourceTriggerView?: OrganisationDataSourceTriggerView;
}

/**
 * Request parameters for organisationDataSourceResourceUpdate operation in DatasourceApi.
 * @export
 * @interface DatasourceApiOrganisationDataSourceResourceUpdateRequest
 */
export interface DatasourceApiOrganisationDataSourceResourceUpdateRequest {
  /**
   * Organisation data source ID
   * @type {number}
   * @memberof DatasourceApiOrganisationDataSourceResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {OrganisationDataSourceUpdateView}
   * @memberof DatasourceApiOrganisationDataSourceResourceUpdate
   */
  readonly organisationDataSourceUpdateView?: OrganisationDataSourceUpdateView;
}

/**
 * DatasourceApi - object-oriented interface
 * @export
 * @class DatasourceApi
 * @extends {BaseAPI}
 */
export class DatasourceApi extends BaseAPI {
  /**
   *
   * @summary Create an organisation\'s data source
   * @param {DatasourceApiOrganisationDataSourceResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourceApi
   */
  public organisationDataSourceResourceCreate(
    requestParameters: DatasourceApiOrganisationDataSourceResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return DatasourceApiFp(this.configuration)
      .organisationDataSourceResourceCreate(requestParameters.organisationDataSourceCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete an organisation\'s data source
   * @param {DatasourceApiOrganisationDataSourceResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourceApi
   */
  public organisationDataSourceResourceDelete(
    requestParameters: DatasourceApiOrganisationDataSourceResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return DatasourceApiFp(this.configuration)
      .organisationDataSourceResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List organisation data sources
   * @param {DatasourceApiOrganisationDataSourceResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourceApi
   */
  public organisationDataSourceResourceList(
    requestParameters: DatasourceApiOrganisationDataSourceResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return DatasourceApiFp(this.configuration)
      .organisationDataSourceResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.dataSource,
        requestParameters.organisationId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read an organisation\'s data source
   * @param {DatasourceApiOrganisationDataSourceResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourceApi
   */
  public organisationDataSourceResourceRead(
    requestParameters: DatasourceApiOrganisationDataSourceResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return DatasourceApiFp(this.configuration)
      .organisationDataSourceResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The method will short-circuit if the data source import is already in progress.
   * @summary Trigger import from the data source
   * @param {DatasourceApiOrganisationDataSourceResourceTriggerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourceApi
   */
  public organisationDataSourceResourceTrigger(
    requestParameters: DatasourceApiOrganisationDataSourceResourceTriggerRequest,
    options?: AxiosRequestConfig
  ) {
    return DatasourceApiFp(this.configuration)
      .organisationDataSourceResourceTrigger(
        requestParameters.id,
        requestParameters.organisationDataSourceTriggerView,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update an organisation\'s data source
   * @param {DatasourceApiOrganisationDataSourceResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourceApi
   */
  public organisationDataSourceResourceUpdate(
    requestParameters: DatasourceApiOrganisationDataSourceResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return DatasourceApiFp(this.configuration)
      .organisationDataSourceResourceUpdate(
        requestParameters.id,
        requestParameters.organisationDataSourceUpdateView,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
