import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import ActionBadge from '../../../../Components/ActionBadge/ActionBadge';
import FileViewer from '../../../../Components/FileViewer/FileViewer';
import { PropertyReadView } from '../../../../GeneratedServices';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { propertyApi } from '../../../../Http/Http';
import { FileInfoReadView } from '../../../../Services/FileService.types';
import colors from '../../../../Utils/Colors/Colors.module.scss';

enum ImageStatus {
  None,
  Saving
}

interface Props {
  property: PropertyReadView;
  reloadProperty: () => void;
  propertyImages: FileInfoReadView[];
  onClose: () => void;
}

const PropertyImages: React.FC<Props> = (props) => {
  const { property, reloadProperty, propertyImages, onClose } = props;
  const { t } = useTranslation(['properties', 'common']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  React.useEffect(() => {
    setFocusedImage(propertyImages.find((item) => item.id === property.pictureId) as FileInfoReadView);
  }, [propertyImages, property.pictureId]);

  const [imageStatus, setImageStatus] = React.useState<ImageStatus>(ImageStatus.None);

  const [focusedImage, setFocusedImage] = React.useState<FileInfoReadView>();
  const updatePropertyMainImage = async (id: string) => {
    try {
      setImageStatus(ImageStatus.Saving);
      await propertyApi.propertyResourceUpdate({
        id: property.id,
        propertyUpdateView: {
          pictureId: id
        }
      });
      reloadProperty();
    } catch (error) {
      handleHttpErrors(error);
    } finally {
      setImageStatus(ImageStatus.None);
    }
  };

  const getMoreInfoContent = () => {
    if (imageStatus === ImageStatus.Saving) return <Loader active inline size="tiny" />;
    else if (!isDifferent) {
      return (
        <>
          <Icon>
            <FontAwesomeIcon icon={faCheck} />
          </Icon>
          {t('properties:mainPropertyPicture')}
        </>
      );
    } else return t('properties:makeMainPropertyPicture');
  };

  const handleSelectedFileChange = React.useCallback((file: FileInfoReadView) => setFocusedImage(file), []);

  const isDifferent = focusedImage?.id && focusedImage?.id !== property?.pictureId;
  return (
    <FileViewer
      file={propertyImages.find((item) => item.id === property?.pictureId)!}
      onClosed={onClose}
      files={propertyImages}
      extraActionBadge={
        <Popup
          //Same value as z-index for FileViewer component
          style={{ zIndex: 2147483647 }}
          content={
            <div
              onClick={() => {
                if (isDifferent) {
                  updatePropertyMainImage(focusedImage.id);
                }
              }}
            >
              <div style={{ cursor: isDifferent ? 'pointer' : undefined }}>{getMoreInfoContent()}</div>
            </div>
          }
          on="click"
          position="bottom right"
          basic
          trigger={<ActionBadge icon="ellipsis vertical" backgroundColor={colors.blue1} size={'1.1em'} />}
        />
      }
      onSelectedFileChange={handleSelectedFileChange}
    />
  );
};

export default PropertyImages;
