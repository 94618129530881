import { ResponsivePie } from '@nivo/pie';
import { LegendProps } from '@nivo/legends';
import React from 'react';
import { Card, Label } from 'semantic-ui-react';
import colors from '../../Utils/Colors/Colors.module.scss';
import NumberFormater from '../NumberFormater/NumberFormater';
import styles from './PieCardContent.module.scss';

type ChartData = {
  id: string;
  label: string;
  value: number;
  suffix: string;
}[];
interface Props {
  data: ChartData;
  centerText?: { value?: number | string; suffix?: string };
  centerAdditionalText?: string | number;
  negateValue?: boolean;
}
const calculateLeftMargin = (data: ChartData) => {
  const longestLabel =
    data.length !== 0
      ? data.reduce(function (a, b) {
          return a.label.length > b.label.length ? a : b;
        }).label
      : '';

  return longestLabel.length * 6 * (data.length > 7 ? 2 : 1);
};

const PIE_COLORS = [
  colors.chart1,
  colors.chart2,
  colors.chart3,
  colors.chart4,
  colors.chart5,
  colors.chart6,
  colors.chart7
];

const commonLegendProps: LegendProps = {
  anchor: 'left',
  direction: 'column',
  itemsSpacing: 10,
  itemWidth: 121,
  itemHeight: 10,
  itemDirection: 'left-to-right',
  itemOpacity: 1,
  symbolSize: 14,
  symbolShape: 'circle'
};

const getDynamicLegends = (leftMargin: number, data: ChartData) => {
  const legendProps = {
    ...commonLegendProps,
    translateX: leftMargin * -1
  };

  return data.length > 7
    ? [
        {
          ...legendProps,
          data: data.slice(undefined, 7).map((item, index) => ({
            ...item,
            color: PIE_COLORS[index]
          }))
        },
        {
          ...legendProps,
          translateX: legendProps.translateX / 2,
          data: data.slice(7, 14).map((item, index) => ({
            ...item,
            color: PIE_COLORS[index]
          }))
        }
      ]
    : [legendProps];
};

const PieCardContent: React.FC<Props> = (props) => {
  const leftMargin = calculateLeftMargin(props.data);

  return (
    <div className={styles.pieContainer}>
      <ResponsivePie
        data={props.data}
        margin={{ top: 0, right: 0, bottom: 0, left: leftMargin }}
        innerRadius={0.8}
        colors={PIE_COLORS}
        fit={true}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        legends={getDynamicLegends(leftMargin, props.data)}
        tooltip={(d) => {
          return (
            <Card className={styles.tooltipCard}>
              <Card.Content className={styles.tooltipCardContent}>
                <Label circular style={{ backgroundColor: d.datum.color }} size="tiny" />
                <div>{d.datum.data.label}:</div>
                <div>
                  <NumberFormater
                    value={props.negateValue ? d.datum.data.value * -1 : d.datum.data.value}
                    suffix={d.datum.data.suffix}
                    additionalProps={{
                      allowNegative: true,
                      decimalScale: d.datum.data.suffix.includes('kvm') ? 1 : 0
                    }}
                  />
                </div>
              </Card.Content>
            </Card>
          );
        }}
        layers={[
          'arcs',
          'legends',
          (pieProps) => (
            <CenteredMetric {...pieProps} centerText={props.centerText} additionalText={props.centerAdditionalText} />
          )
        ]}
      />
    </div>
  );
};

const CenteredMetric = ({
  centerText,
  additionalText,
  centerX,
  centerY
}: {
  centerText?: { value?: number | string; suffix?: string };
  additionalText?: number | string;
  centerX: number;
  centerY: number;
}) => {
  return (
    <text
      x={centerX}
      y={!additionalText ? centerY + 6 : centerY}
      textAnchor="middle"
      fontSize={'18px'}
      fontWeight="400"
    >
      {centerText?.value + ' '}
      <tspan fontSize={'12px'} fontWeight="300">
        {centerText?.suffix}
      </tspan>
      <tspan x={centerX} dy={12} fontSize={'12px'} fontWeight="300">
        {additionalText}
      </tspan>
    </text>
  );
};

export default PieCardContent;
