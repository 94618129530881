import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DropdownItemProps, Form, Modal } from 'semantic-ui-react';
import { renderValidationErrors } from '../../../../Utils/FieldValidationErrorMessage';
import {
  FieldValidationResult,
  allFieldsValid,
  applyApiResponseValidationToFields,
  validateDate,
  validateRequiredField
} from '../../../../Utils/validation';
import { UnitObjectReadView } from '../../../../GeneratedServices';
import NumberFormat from 'react-number-format';
import { commonNumberFormatProperties } from '../../../../Components/NumberFormater/NumberFormater';
import { getFormatedCurrencyValue, toOre } from '../../../../Utils/number';
import DatePicker from '../../../../Components/DatePicker/DatePicker';
import { toDateString } from '../../../../Utils/date';
import { businessPlanApi } from '../../../../Http/Http';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { UserFriendlyApiResponse } from '../../../../Http/response-error';
import { toast } from 'react-toastify';

interface Props {
  onClose: (created: boolean) => void;
  unitObjects?: UnitObjectReadView[];
  businessPlanId: number;
}

enum Status {
  None,
  Saving
}

type ValueType = number | string | undefined;

const CreateBusinessPlanLeaseModal: React.FC<Props> = ({ onClose, unitObjects, businessPlanId }) => {
  const { t } = useTranslation(['common', 'contracts']);

  const { handleHttpErrors } = useContext(globalStateCTX);

  const [status, setStatus] = React.useState<Status>();

  const [objectId, setObjectId] = React.useState<number>();
  const [objectIdValidationResult, setObjectIdValidationResult] = React.useState<FieldValidationResult>();

  const [generation, setGeneration] = React.useState<number>();
  const [generationValidationResult, setGenerationValidationResult] = React.useState<FieldValidationResult>();

  const [tenantName, setTenantName] = React.useState<string | undefined>();
  const [tenantNameValidationResult, setTenantNameValidationResult] = React.useState<FieldValidationResult>();

  const [moveOutDate, setMoveOutDate] = React.useState<string>();
  const [moveOutDateValidationResult, setMoveOutDateValidationResult] = React.useState<FieldValidationResult>();

  const [indoorArea, setIndoorArea] = React.useState<number>();
  const [indoorAreaValidationResult, setIndoorAreaValidationResult] = React.useState<FieldValidationResult>();

  const [downtimePeriod, setDowntimePeriod] = React.useState<number>();
  const [downtimePeriodValidationResult, setDowntimePeriodValidationResult] = React.useState<FieldValidationResult>();

  const [investment, setInvestment] = React.useState<number>();
  const [investmentValidationResult, setInvestmentValidationResult] = React.useState<FieldValidationResult>();

  const [baseRent, setBaseRent] = React.useState<number>();
  const [baseRentValidationResult, setBaseRentValidationResult] = React.useState<FieldValidationResult>();

  const [additions, setAdditions] = React.useState<number>();
  const [additionsValidationResult, setAdditionsValidationResult] = React.useState<FieldValidationResult>();

  const unitObjectOptions: DropdownItemProps[] = React.useMemo(
    () =>
      unitObjects
        ? unitObjects.map((item) => ({
            key: item.id,
            text: item.strifastPropertyId + '-' + item.strifastObjectId,
            value: item.id
          }))
        : [],
    [unitObjects]
  );

  const handleResponseValidationError = (resp: UserFriendlyApiResponse) => {
    applyApiResponseValidationToFields(resp, [
      {
        formFieldId: 'additions',
        setStateFunc: setAdditionsValidationResult
      },
      {
        formFieldId: 'baseRent',
        setStateFunc: setBaseRentValidationResult
      },
      {
        formFieldId: 'downtimePeriod',
        setStateFunc: setDowntimePeriodValidationResult
      },
      {
        formFieldId: 'generation',
        setStateFunc: setGenerationValidationResult
      },
      {
        formFieldId: 'indoorArea',
        setStateFunc: setIndoorAreaValidationResult
      },
      {
        formFieldId: 'investment',
        setStateFunc: setInvestmentValidationResult
      },
      {
        formFieldId: 'moveOutDate',
        setStateFunc: setMoveOutDateValidationResult
      },
      {
        formFieldId: 'objectId',
        setStateFunc: setObjectIdValidationResult
      },
      {
        formFieldId: 'tenantName',
        setStateFunc: setTenantNameValidationResult
      },
      {
        formFieldId: 'businessPlanId',
        setStateFunc: (data) => data?.errorMessage && toast.error(data?.errorMessage[0])
      }
    ]);
  };

  const validateRequiredFormField = React.useCallback(
    (value: ValueType, setStateFunc: (data: FieldValidationResult | undefined) => void, emptyMessage?: string) => {
      const requiredFieldMessage = t('validation:fieldError.mustNotBeEmpty');
      return validateRequiredField(value?.toString() ?? '', setStateFunc, emptyMessage ?? requiredFieldMessage);
    },
    [t]
  );

  const validateObjectId = (value: ValueType) => validateRequiredFormField(value, setObjectIdValidationResult);

  const validateGeneration = (value: ValueType) => validateRequiredFormField(value, setGenerationValidationResult);

  const validateMoveOutDate = (value?: string) =>
    validateDate(value, setMoveOutDateValidationResult, t('validation:invalidDate'));

  const validateIndoorArea = (value: ValueType) =>
    validateRequiredFormField(value, setIndoorAreaValidationResult, t('validation:invalidArea'));

  const validateDowntimePeriod = (value: ValueType) =>
    validateRequiredFormField(value, setDowntimePeriodValidationResult);

  const validateInvestment = (value: ValueType) => validateRequiredFormField(value, setInvestmentValidationResult);

  const validateBaseRent = (value: ValueType) =>
    validateRequiredFormField(value, setBaseRentValidationResult, t('validation:invalidBaseRent'));

  const validateAdditions = (value: ValueType) => validateRequiredFormField(value, setAdditionsValidationResult);

  const validateForm = () => {
    if (
      !(
        validateObjectId(objectId) &&
        validateGeneration(generation) &&
        validateIndoorArea(indoorArea) &&
        validateDowntimePeriod(downtimePeriod) &&
        validateInvestment(investment)
      )
    )
      return false;

    return generation !== undefined && generation > 1
      ? validateMoveOutDate(moveOutDate) && validateBaseRent(baseRent) && validateAdditions(additions)
      : validateIsVacant(moveOutDate, baseRent, additions);
  };

  function validateIsVacant(moveOutDate?: string, baseRent?: number, additions?: number) {
    if (moveOutDate === undefined && baseRent === undefined && additions === undefined) {
      return true;
    }
    return validateMoveOutDate(moveOutDate) && validateBaseRent(baseRent) && validateAdditions(additions);
  }

  function validateVacantFields(callback: () => void, moveOutDate?: string, baseRent?: number, additions?: number) {
    if (generation === 1 && moveOutDate === undefined && baseRent === undefined && additions === undefined) {
      setMoveOutDateValidationResult(undefined);
      setBaseRentValidationResult(undefined);
      setAdditionsValidationResult(undefined);
    } else {
      callback();
    }
  }

  const vacantRequiredStatus =
    additions !== undefined || baseRent !== undefined || (generation || 0) > 1 || moveOutDate !== undefined;

  const onSave = async () => {
    if (!validateForm()) return;
    try {
      setStatus(Status.Saving);

      await businessPlanApi.businessPlanLeaseResourceCreate({
        businessPlanLeaseCreateView: {
          additions,
          baseRent,
          businessPlanId,
          downtimePeriod: downtimePeriod!,
          generation: generation!,
          indoorArea: indoorArea!,
          investment: investment!,
          moveOutDate,
          objectId: objectId!,
          tenantName
        }
      });

      onClose(true);
    } catch (error) {
      handleHttpErrors(error, {
        handleResponseValidationError
      });
    } finally {
      setStatus(Status.None);
    }
  };

  const areValid = allFieldsValid([
    additionsValidationResult,
    baseRentValidationResult,
    downtimePeriodValidationResult,
    generationValidationResult,
    investmentValidationResult,
    moveOutDateValidationResult,
    objectIdValidationResult,
    tenantNameValidationResult,
    indoorAreaValidationResult
  ]);

  const isSaving = status === Status.Saving;
  return (
    <Modal
      size="small"
      open
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon={!isSaving}
      onClose={() => onClose(false)}
    >
      <Modal.Header>{t('common:lease')}</Modal.Header>

      <Modal.Content>
        <Form noValidate="noValidate">
          <Form.Group>
            <Form.Dropdown
              label={t('contracts:object')}
              placeholder={t('contracts:object')}
              floating
              width={4}
              required
              value={objectId}
              onChange={(e, { value }) => {
                setObjectId(value as number);
                validateObjectId(value as number);
              }}
              selection
              scrolling
              fluid
              options={unitObjectOptions}
              error={renderValidationErrors(objectIdValidationResult)}
            />

            <Form.Input
              label={t('contracts:generation')}
              placeholder={t('contracts:generation')}
              width={4}
              required
              input={
                <NumberFormat
                  value={generation}
                  onValueChange={({ floatValue }) => {
                    setGeneration(floatValue);
                    validateGeneration(floatValue);
                  }}
                  {...commonNumberFormatProperties}
                  decimalScale={0}
                />
              }
              error={renderValidationErrors(generationValidationResult)}
            />

            <Form.Input
              label={t('common:tenant')}
              type="text"
              width={8}
              value={tenantName ?? ''}
              placeholder={t('common:tenant')}
              onChange={(e, d) => {
                setTenantName(d.value);
                setTenantNameValidationResult(undefined);
              }}
              disabled={isSaving}
              error={renderValidationErrors(tenantNameValidationResult)}
            />
          </Form.Group>

          <Form.Group widths={'equal'}>
            <Form.Field required={vacantRequiredStatus}>
              <label>{t('common:endDate')}</label>
              <DatePicker
                popperPlacement="top"
                selected={moveOutDate ? new Date(moveOutDate) : undefined}
                dateFormat="P"
                placeholderText={`YYYY-MM-DD`}
                onChange={(date) => {
                  const dateString = date ? toDateString(date) : undefined;
                  setMoveOutDate(dateString);
                  validateVacantFields(() => validateMoveOutDate(dateString), dateString, baseRent, additions);
                }}
                customInput={
                  <Form.Input
                    icon="calendar alternate outline"
                    iconPosition="left"
                    error={renderValidationErrors(moveOutDateValidationResult)}
                  />
                }
              />
            </Form.Field>

            <Form.Input
              label={`${t('contracts:investmentPeriod')} (${t('common:months')})`}
              placeholder={`${t('contracts:investmentPeriod')} (${t('common:months')})`}
              required
              input={
                <NumberFormat
                  value={downtimePeriod}
                  onValueChange={({ value, floatValue }) => {
                    setDowntimePeriod(floatValue);
                    validateDowntimePeriod(value);
                  }}
                  {...commonNumberFormatProperties}
                  decimalScale={0}
                />
              }
              error={renderValidationErrors(downtimePeriodValidationResult)}
            />

            <Form.Input
              label={`${t('contracts:investment')} (SEK)`}
              placeholder={`${t('contracts:investment')} (SEK)`}
              required
              input={
                <NumberFormat
                  value={investment ? getFormatedCurrencyValue(investment) : undefined}
                  onValueChange={({ value, floatValue }) => {
                    setInvestment(floatValue !== undefined ? toOre(floatValue) : undefined);
                    validateInvestment(value);
                  }}
                  {...commonNumberFormatProperties}
                />
              }
              error={renderValidationErrors(investmentValidationResult)}
            />
          </Form.Group>

          <Form.Group widths={'equal'}>
            <Form.Input
              label={`${t('common:lettableArea')} (${t('common:sqm')})`}
              placeholder={`${t('common:lettableArea')} (${t('common:sqm')})`}
              required
              input={
                <NumberFormat
                  value={indoorArea}
                  onValueChange={({ floatValue, value }) => {
                    setIndoorArea(floatValue);
                    validateIndoorArea(value);
                  }}
                  {...commonNumberFormatProperties}
                />
              }
              error={renderValidationErrors(indoorAreaValidationResult)}
            />
            <Form.Input
              label={`${t('contracts:baseRent')} (${t('common:sekSqm')})`}
              placeholder={`(${t('common:optionalIfVacant')})`}
              required={vacantRequiredStatus}
              input={
                <NumberFormat
                  value={baseRent ? getFormatedCurrencyValue(baseRent) : undefined}
                  onValueChange={({ value, floatValue }) => {
                    setBaseRent(floatValue !== undefined ? toOre(floatValue) : undefined);
                    validateVacantFields(() => validateBaseRent(value), moveOutDate, floatValue, additions);
                  }}
                  {...commonNumberFormatProperties}
                />
              }
              error={renderValidationErrors(baseRentValidationResult)}
            />

            <Form.Input
              label={`${t('contracts:additions')} (${t('common:sekSqm')})`}
              placeholder={`(${t('common:optionalIfVacant')})`}
              required={vacantRequiredStatus}
              input={
                <NumberFormat
                  value={additions ? getFormatedCurrencyValue(additions) : undefined}
                  onValueChange={({ value, floatValue }) => {
                    setAdditions(floatValue !== undefined ? toOre(floatValue) : undefined);
                    validateVacantFields(() => validateAdditions(value), moveOutDate, baseRent, floatValue);
                  }}
                  {...commonNumberFormatProperties}
                />
              }
              error={renderValidationErrors(additionsValidationResult)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button primary onClick={onSave} disabled={isSaving || !areValid} loading={isSaving}>
          {t('common:save')}
        </Button>
        <Button content={t('common:close')} disabled={isSaving} onClick={() => onClose(false)} secondary />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateBusinessPlanLeaseModal;
