import React from 'react';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { UtilityMetricsType, UtilityType } from '../../../Services/UtilityService.types';
import styles from './EnergyReportFilters.module.scss';
import i18n from '../../../i18n';

interface Props {
  filters: {
    utilityType: UtilityType;
    utilityMetricType: UtilityMetricsType;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      utilityType: UtilityType;
      utilityMetricType: UtilityMetricsType;
    }>
  >;
  granularity: 'QUARTERLY' | 'YEARLY' | 'MONTHLY';
  setGranularity: React.Dispatch<React.SetStateAction<'QUARTERLY' | 'YEARLY' | 'MONTHLY'>>;
  areAllMarked?: boolean;
  onAllMarkedChange: (value: boolean) => void;
}

enum UtilityTypeAndMetricCombinations {
  ELECTRICITY_ENERGY_KWH = 'ELECTRICITY_ENERGY_KWH',
  GAS_ENERGY_KWH = 'GAS_ENERGY_KWH',
  GAS_FLOW_M3 = 'GAS_FLOW_M3',
  HEAT_ENERGY_KWH = 'HEAT_ENERGY_KWH',
  HEAT_FLOW_M3 = 'HEAT_FLOW_M3',
  WATER_FLOW_M3 = 'WATER_FLOW_M3'
}

const translateGranularityOptions = (value: 'QUARTERLY' | 'YEARLY' | 'MONTHLY') => {
  if (value === 'QUARTERLY') return i18n.t('common:quarter');
  else if (value === 'YEARLY') return i18n.t('common:year');
  else return i18n.t('common:month');
};

export const granularityOptions = () =>
  ['MONTHLY', 'QUARTERLY', 'YEARLY'].map((item) => {
    return {
      text: translateGranularityOptions(item as 'QUARTERLY' | 'YEARLY' | 'MONTHLY'),
      value: item,
      key: item
    };
  });

export const translateUtilityCombinations = (value: UtilityTypeAndMetricCombinations) => {
  switch (value) {
    case UtilityTypeAndMetricCombinations.ELECTRICITY_ENERGY_KWH:
      return i18n.t('energyReport:utilityTypeAndMetricCombination.electricityKwh');
    case UtilityTypeAndMetricCombinations.GAS_ENERGY_KWH:
      return i18n.t('energyReport:utilityTypeAndMetricCombination.gasKwh');
    case UtilityTypeAndMetricCombinations.GAS_FLOW_M3:
      return i18n.t('energyReport:utilityTypeAndMetricCombination.gasM3');
    case UtilityTypeAndMetricCombinations.HEAT_ENERGY_KWH:
      return i18n.t('energyReport:utilityTypeAndMetricCombination.heatKwh');
    case UtilityTypeAndMetricCombinations.HEAT_FLOW_M3:
      return i18n.t('energyReport:utilityTypeAndMetricCombination.heatM3');
    case UtilityTypeAndMetricCombinations.WATER_FLOW_M3:
      return i18n.t('energyReport:utilityTypeAndMetricCombination.waterM3');
  }
};

export const concatUtilityCombinations = (filter: {
  utilityType: UtilityType;
  utilityMetricType: UtilityMetricsType;
}) => {
  switch (filter.utilityType) {
    case UtilityType.ELECTRICITY:
      return UtilityTypeAndMetricCombinations.ELECTRICITY_ENERGY_KWH;
    case UtilityType.GAS:
      return filter.utilityMetricType === UtilityMetricsType.ENERGY_KWH
        ? UtilityTypeAndMetricCombinations.GAS_ENERGY_KWH
        : UtilityTypeAndMetricCombinations.GAS_FLOW_M3;
    case UtilityType.HEAT:
      return filter.utilityMetricType === UtilityMetricsType.ENERGY_KWH
        ? UtilityTypeAndMetricCombinations.HEAT_ENERGY_KWH
        : UtilityTypeAndMetricCombinations.HEAT_FLOW_M3;
    case UtilityType.WATER:
      return UtilityTypeAndMetricCombinations.WATER_FLOW_M3;
    default:
      return UtilityTypeAndMetricCombinations.ELECTRICITY_ENERGY_KWH;
  }
};

const expandUtilityCombinations = (combination: UtilityTypeAndMetricCombinations) => {
  switch (combination) {
    case UtilityTypeAndMetricCombinations.ELECTRICITY_ENERGY_KWH:
      return { utilityType: UtilityType.ELECTRICITY, utilityMetricType: UtilityMetricsType.ENERGY_KWH };
    case UtilityTypeAndMetricCombinations.GAS_ENERGY_KWH:
      return { utilityType: UtilityType.GAS, utilityMetricType: UtilityMetricsType.ENERGY_KWH };
    case UtilityTypeAndMetricCombinations.GAS_FLOW_M3:
      return { utilityType: UtilityType.GAS, utilityMetricType: UtilityMetricsType.FLOW_M3 };
    case UtilityTypeAndMetricCombinations.HEAT_ENERGY_KWH:
      return { utilityType: UtilityType.HEAT, utilityMetricType: UtilityMetricsType.ENERGY_KWH };
    case UtilityTypeAndMetricCombinations.HEAT_FLOW_M3:
      return { utilityType: UtilityType.HEAT, utilityMetricType: UtilityMetricsType.FLOW_M3 };
    case UtilityTypeAndMetricCombinations.WATER_FLOW_M3:
      return { utilityType: UtilityType.WATER, utilityMetricType: UtilityMetricsType.FLOW_M3 };
  }
};

const energyUtilityTypeOptions = () =>
  Object.values(UtilityTypeAndMetricCombinations).map((item) => {
    return {
      text: translateUtilityCombinations(item as UtilityTypeAndMetricCombinations),
      value: item,
      key: item
    };
  });

const EnergyReportFilters: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <Dropdown
        placeholder="Filter"
        floating
        compact
        className={styles.compactDropdown}
        selection
        options={energyUtilityTypeOptions()}
        value={concatUtilityCombinations(props.filters)}
        onChange={(e, d) => {
          props.setFilters(expandUtilityCombinations(d.value as UtilityTypeAndMetricCombinations));
        }}
      />

      <Dropdown
        placeholder="Granularity"
        floating
        compact
        className={styles.compactDropdown}
        selection
        options={granularityOptions()}
        value={props.granularity}
        onChange={(e, d) => {
          props.setGranularity(d.value as 'QUARTERLY' | 'YEARLY' | 'MONTHLY');
        }}
      />

      <Checkbox
        label={i18n.t('common:markAll')}
        checked={props.areAllMarked}
        onChange={(e, d) => {
          props.onAllMarkedChange(d.checked as boolean);
        }}
      />
    </div>
  );
};

export default EnergyReportFilters;
