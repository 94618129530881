import React from 'react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import './scss/AppShell.scss';
import './scss/overrides.scss';
import SideMenu from './SideMenu';
import TopMenu from './TopMenu';

interface Props {
  children: any;
}

const AppShell: React.FC<Props> = (props) => {
  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);

  return (
    <div id="root-content">
      <TopMenu currentUser={globalState.currentUser!.email} handleLogOut={globalState.handleLogOut} />
      <div id="shell-container">
        <SideMenu />
        <main>{props.children}</main>
      </div>
    </div>
  );
};

export default AppShell;
