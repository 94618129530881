import { isArray } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Dropdown, Modal } from 'semantic-ui-react';
import { PropertyReadView } from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { createInfinitePaginationParams, NetworkRequestStatus, propertyApi } from '../../../Http/Http';
import { mapPropertiesToDropdownItems } from '../../../Services/PropertyService.types';

interface Props {
  type: 'import' | 'export';
  organisationId: number;
  propertyId: number;
  onClose: (saved: boolean) => void;
}

enum Status {
  Saving,
  None
}

const CopyRoutineTasksModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'propertyChecks', 'properties']);
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const abortControllerRef = React.useRef<AbortController>();

  const [selectedPropertiesIds, setSelectedPropertiesIds] = React.useState<number[]>([]);

  const [propertiesStatus, setPropertiesStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);
  const [properties, setProperties] = React.useState<PropertyReadView[]>([]);

  const [status, setStatus] = React.useState<Status>(Status.None);

  const isImportType = props.type === 'import';

  React.useEffect(() => {
    abortControllerRef.current = new AbortController();

    const loadProperties = async () => {
      try {
        setPropertiesStatus(NetworkRequestStatus.Loading);
        const { data } = await propertyApi.propertyResourceList(
          {
            ...createInfinitePaginationParams(),
            organisationId: props.organisationId
          },
          {
            signal: abortControllerRef.current?.signal
          }
        );

        setProperties(data.records.filter((item) => item.id !== props.propertyId));

        setPropertiesStatus(NetworkRequestStatus.None);
      } catch (error) {
        setPropertiesStatus(NetworkRequestStatus.LoadError);
      }
    };

    loadProperties();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, [handleHttpErrors, props.organisationId, props.propertyId]);

  const handleApply = async () => {
    try {
      setStatus(Status.Saving);

      await propertyApi.propertyCheckResourceCopyFromProperty({
        id: isImportType ? selectedPropertiesIds[0] : props.propertyId,
        propertyCheckApplyView: {
          toPropertyIds: isImportType ? [props.propertyId] : selectedPropertiesIds
        }
      });
      toast.info(
        isImportType ? t('propertyChecks:schemaImportedSuccessfully') : t('propertyChecks:schemaExportedSuccessfully')
      );

      props.onClose(props.type === 'import');
    } catch (error) {
      handleHttpErrors(error);
    } finally {
      setStatus(Status.None);
    }
  };

  const getHeader = () => {
    switch (props.type) {
      case 'import':
        return t('propertyChecks:selectPropertyToImportFrom');
      case 'export':
        return t('propertyChecks:selectPropertiesToExportTo');
    }
  };

  const isSaving = status === Status.Saving;
  return (
    <Modal
      size="small"
      open
      onClose={() => props.onClose(false)}
      closeIcon={propertiesStatus === NetworkRequestStatus.None && !isSaving}
    >
      <Modal.Header>{getHeader()}</Modal.Header>

      <Modal.Content>
        <Dropdown
          selection
          fluid
          placeholder={isImportType ? t('propertyChecks:selectProperty') : t('propertyChecks:selectProperties')}
          floating
          multiple={props.type === 'export'}
          search
          clearable
          options={mapPropertiesToDropdownItems(properties)}
          error={propertiesStatus === NetworkRequestStatus.LoadError}
          loading={propertiesStatus === NetworkRequestStatus.Loading}
          disabled={propertiesStatus !== NetworkRequestStatus.None}
          onChange={(e, d) => {
            if (isArray(d.value)) {
              setSelectedPropertiesIds(d.value as number[]);
            } else setSelectedPropertiesIds([d.value as number]);
          }}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button disabled={selectedPropertiesIds.length === 0 || isSaving} primary onClick={handleApply}>
          {isImportType ? t('common:import') : t('common:export')}
        </Button>

        <Button onClick={() => props.onClose(false)} secondary>
          {t('common:close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CopyRoutineTasksModal;
