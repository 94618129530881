import React from 'react';
import { Label, LabelGroup, List, Message, Popup } from 'semantic-ui-react';
import UserAvatar from './UserAvatar';
import styles from './UserLabels.module.scss';

interface Props {
  users: { name: string; id: number }[];
  onClick?: () => void;
  displayedUsersLimit: number;
  marginBottom?: number;
}

const UserLabels: React.FC<Props> = (props) => {
  const displayedUsers = props.users.slice(0, props.displayedUsersLimit);

  const getAdditionalLabel = () =>
    props.users.length > props.displayedUsersLimit && (
      <Label className={styles.label} style={{ width: 'fit-content', marginBottom: props.marginBottom }}>
        +{props.users.length - props.displayedUsersLimit}
      </Label>
    );

  if (props.users.length !== 0) {
    return (
      <Popup
        className={styles.popup}
        position="bottom center"
        trigger={
          <LabelGroup
            size="large"
            className={styles.labelsWrapper}
            style={props.onClick && { cursor: 'pointer' }}
            onClick={props.onClick}
          >
            {displayedUsers.map((item) => (
              <UserAvatar name={item.name} key={item.id} style={{ marginBottom: props.marginBottom }} />
            ))}
            {getAdditionalLabel()}
          </LabelGroup>
        }
        content={
          <List>
            {props.users?.map((item) => (
              <List.Item key={item.id}>{item.name}</List.Item>
            ))}
          </List>
        }
      />
    );
  } else return null;
};

export const UserLabelEmptyLayout = (props: { message: string; onClick?: () => void }) => {
  return (
    <Message
      compact
      className={styles.emptyUserLabel}
      style={{ cursor: props.onClick ? 'pointer' : undefined }}
      onClick={props.onClick}
    >
      {props.message}
    </Message>
  );
};

export default UserLabels;
