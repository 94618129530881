import React from 'react';
import { FileInfoReadView } from '../../Services/FileService.types';
import { useFilesReducer } from '../FileCardList/Hooks/useFilesReducer';
import styles from './FileList.module.scss';
import FileListItem from './FileListItem';

interface Props {
  files?: FileInfoReadView[];
  overviewOnly?: boolean;
  onItemClick?: (file: FileInfoReadView) => void;
  highlightedIndex?: number;
  limitedWidth?: boolean;
}

const FileList: React.FC<Props> = (props) => {
  const { files, retryLoadingThumbnail } = useFilesReducer({ files: props.files });

  return (
    <div className={styles.componentWrapper} style={{ flexWrap: props.limitedWidth ? 'inherit' : undefined }}>
      {files?.map((file, index) => {
        return (
          <FileListItem
            key={file.id}
            file={file}
            deleteType="NON_DELETABLE"
            files={props.files}
            overviewOnly={props.overviewOnly}
            onClick={props.onItemClick}
            highlighted={props.highlightedIndex === index}
            retryLoadingThumbnail={retryLoadingThumbnail}
          />
        );
      })}
    </div>
  );
};

export default FileList;
