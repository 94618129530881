import React from 'react';
import _ from 'lodash';
import FileAutoUploaderItem, { FileItem } from './FileAutoUploaderItem';
import { v4 } from 'uuid';
import styles from './FileAutoUploader.module.scss';
import FileListItem from '../FileList/FileListItem';
import { FileInfoReadView, FileSubType, FileType } from '../../Services/FileService.types';
import { USE_NONCE_HOOK_DEFAULT_VALUE } from '../../Utils/hooks';
import { useFilesReducer } from '../FileCardList/Hooks/useFilesReducer';

interface Props {
  noFilesUploadedMessage?: any;
  openFileSelectionNonce: number;
  initFiles?: FileInfoReadView[];
  onStateChanged(hasNonCompletedFiles: boolean): void;
  onFilesChanged(fileIds: string[], files: (Item | InitFileItem)[]): void;
  readonly?: boolean;
  type?: FileType;
  subType?: FileSubType;
  reset?: boolean;
}

enum FileStatus {
  Loading,
  ReadyForUpload,
  Uploading,
  Uploaded,
  MarkedAsDeleted,
  Deleting,
  Error
}
enum InitFileStatus {
  Uploaded,
  MarkedAsDeleted,
  Deleted
}

type Item = FileItem & { status: FileStatus };
type InitFileItem = FileInfoReadView & { status: InitFileStatus };

type FileItemState = Item[];
type FileItemAction =
  | { type: 'ADD'; files: Item[] }
  | { type: 'DELETE'; tempId: string }
  | { type: 'DELETING'; tempId: string }
  | { type: 'FINISH'; tempId: string; id: string }
  | { type: 'START_UPLOAD'; tempId: string }
  | { type: 'FILE_ERROR'; tempId: string }
  | { type: 'RETRY'; tempId: string }
  | { type: 'CLEAR' };

type InitFileItemState = InitFileItem[];
type InitFileItemAction =
  | { type: 'INIT'; files: InitFileItem[] }
  | { type: 'MARK_AS_DELETED'; id: string }
  | { type: 'MARK_AS_DELETED_UNDO'; id: string }
  | { type: 'DELETE'; id: string };

// Most browsers limits number of connections per domain to six.
const maxConcurrentUploadingFiles = 6;

const FileAutoUploader: React.FC<Props> = (props) => {
  const [initFiles, dispatchInitFiles] = React.useReducer(filesInitReducer, []);
  const [files, dispatchFiles] = React.useReducer(filesReducer, []);
  const { files: extendedInitFiles, retryLoadingThumbnail } = useFilesReducer({ files: props.initFiles });

  const fileInputElementRef = React.useRef<HTMLInputElement>(null);

  const prevUploadedFileIds = React.useRef<string[]>([]);
  const prevHasNonCompletedFiles = React.useRef<boolean>(false);

  const { onStateChanged: propsOnStateChanged, onFilesChanged: propsOnFilesChanged } = props;

  const getFilesToSave = React.useCallback(() => {
    const initFilesToKeep = initFiles.filter((file) => file.status === InitFileStatus.Uploaded);
    const uploadedFileIds = files.filter((file) => file.id !== undefined);
    return [...initFilesToKeep, ...uploadedFileIds];
  }, [initFiles, files]);

  React.useEffect(() => {
    if (props.reset) {
      dispatchFiles({
        type: 'CLEAR'
      });
    }
  }, [props.reset]);

  React.useEffect(() => {
    dispatchInitFiles({
      type: 'INIT',
      files:
        props.initFiles?.map((file) => ({
          ...file,
          status: InitFileStatus.Uploaded
        })) ?? []
    });
  }, [props.initFiles]);

  // Focus input field.
  React.useEffect(() => {
    if (props.openFileSelectionNonce !== USE_NONCE_HOOK_DEFAULT_VALUE) {
      fileInputElementRef.current!.click();
    }
  }, [props.openFileSelectionNonce]);

  React.useEffect(() => {
    // Boolean whether non completed files exist.
    const hasNonCompletedFiles = files.length > 0 && files.some((file) => file.status !== FileStatus.Uploaded);
    if (hasNonCompletedFiles !== prevHasNonCompletedFiles.current) {
      propsOnStateChanged(hasNonCompletedFiles);
      prevHasNonCompletedFiles.current = hasNonCompletedFiles;
    }

    // Id of uploaded files.

    // This useEffect is retriggered after propsOnFileChanged is called.
    // Prevent loop by calling only if data has changed.
    //
    // todo: Find source to problem and do not pass new functions for
    // todo: propsOnStateChanged / propsOnFilesChanged.
    const filesToSave = getFilesToSave();
    const fileIds = filesToSave.map((file) => file.id!);
    if (!_.isEqual(fileIds.sort(), prevUploadedFileIds.current.sort())) {
      propsOnFilesChanged(fileIds, filesToSave);
      prevUploadedFileIds.current = fileIds;
    }
  }, [initFiles, files, getFilesToSave, propsOnStateChanged, propsOnFilesChanged]);

  const getTotalFileCount = () => files.length + (props.initFiles?.length ?? 0);

  /**
   * Starts uploading files ready for upload. Stops when current number of files in progress reaches the
   * specified limit of cuncurrent connections.
   * @param list Current state of files
   * @param tempFileId Start upload specific file only, if specified.
   */
  function startUpload(list: FileItemState, tempFileId?: string) {
    const currentUploadingCount = list.filter((file) => file.status === FileStatus.Uploading).length;
    const take = maxConcurrentUploadingFiles - currentUploadingCount;
    const readyForUpload = list
      .filter((file) => (tempFileId ? file.tempId === tempFileId : file.status === FileStatus.ReadyForUpload))
      .slice(0, take);

    for (const file of readyForUpload) {
      file.startNonce = v4();
      file.status = FileStatus.Uploading;
    }
  }

  function filesInitReducer(state: InitFileItemState, action: InitFileItemAction): InitFileItemState {
    switch (action.type) {
      case 'INIT':
        return action.files;

      case 'DELETE':
        return state.filter((item) => item.id !== action.id);

      case 'MARK_AS_DELETED':
        return state.map((file) =>
          file.id === action.id
            ? {
                ...file,
                status: InitFileStatus.MarkedAsDeleted
              }
            : file
        );

      case 'MARK_AS_DELETED_UNDO':
        return state.map((file) =>
          file.id === action.id
            ? {
                ...file,
                status: InitFileStatus.Uploaded
              }
            : file
        );
    }
  }

  function filesReducer(state: FileItemState, action: FileItemAction): FileItemState {
    switch (action.type) {
      case 'ADD':
        return [...state, ...action.files];

      case 'DELETE': {
        const list = [...state].filter((x) => x.tempId !== action.tempId);

        startUpload(list);

        return list;
      }

      case 'DELETING': {
        const list = [...state];

        const file = list.find((file) => file.tempId === action.tempId);
        if (file) {
          file.status = FileStatus.Deleting;
        }

        return list;
      }

      case 'FINISH': {
        const list = [...state];

        const file = list.find((file) => file.tempId === action.tempId);
        if (file) {
          file.id = action.id;
          file.status = FileStatus.Uploaded;
        }

        startUpload(list);

        return list;
      }

      case 'START_UPLOAD': {
        const list = [...state];

        const file = list.find((file) => file.tempId === action.tempId);
        if (file) {
          file.status = FileStatus.ReadyForUpload;
        }

        startUpload(list);

        return list;
      }

      case 'FILE_ERROR': {
        const list = [...state];

        const file = list.find((file) => file.tempId === action.tempId);
        if (file) {
          file.status = FileStatus.Error;
        }

        startUpload(list);

        return list;
      }

      case 'RETRY': {
        const list = [...state];
        const file = list.find((file) => file.tempId === action.tempId);
        if (file) {
          file.status = FileStatus.ReadyForUpload;
          startUpload(list, file.tempId);
        }

        return list;
      }
      case 'CLEAR': {
        return [];
      }
    }
  }

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchFiles({
      type: 'ADD',
      files: [...event.target!.files!].map((file) => ({
        tempId: v4(), // Temporary id used to identify file.
        content: file,
        name: file.name,
        fileSize: file.size,
        status: FileStatus.Loading
      })) as Item[]
    });

    // Clear target so we can re-add the same file again immediately after.
    event.target!.value = '';
  };

  const onLoaded = (tempId: string) => {
    dispatchFiles({
      type: 'START_UPLOAD',
      tempId
    });
  };

  const onUploaded = (tempId: string, id: string) => {
    dispatchFiles({
      type: 'FINISH',
      tempId,
      id
    });
  };

  const onDeleting = (tempId: string) => {
    dispatchFiles({
      type: 'DELETING',
      tempId
    });
  };

  const onDeleted = (tempId: string) => {
    dispatchFiles({
      type: 'DELETE',
      tempId
    });
  };

  const onError = (tempId: string) => {
    dispatchFiles({
      type: 'FILE_ERROR',
      tempId
    });
  };

  const onRetry = (tempId: string) => {
    dispatchFiles({
      type: 'RETRY',
      tempId
    });
  };

  return (
    <div className={styles.componentWrapper}>
      {getTotalFileCount() === 0 && props.noFilesUploadedMessage !== undefined && (
        <div>{props.noFilesUploadedMessage}</div>
      )}

      {extendedInitFiles?.map((file) => {
        return (
          <FileListItem
            key={file.id}
            file={file}
            deleteType={props.readonly ? 'NON_DELETABLE' : 'MARK_AS_DELETED'}
            onDeleteChanged={(type) => {
              switch (type) {
                case 'DELETED':
                  dispatchInitFiles({
                    type: 'DELETE',
                    id: file.id
                  });
                  break;

                case 'MARKED_AS_DELETED':
                  dispatchInitFiles({
                    type: 'MARK_AS_DELETED',
                    id: file.id
                  });
                  break;

                case 'MARKED_AS_DELETED_UNDO':
                  dispatchInitFiles({
                    type: 'MARK_AS_DELETED_UNDO',
                    id: file.id
                  });
                  break;
              }
            }}
            retryLoadingThumbnail={retryLoadingThumbnail}
          />
        );
      })}

      {files.map((file) => (
        <FileAutoUploaderItem
          key={file.tempId}
          startNonce={file.startNonce}
          file={file}
          type={props.type}
          subType={props.subType}
          onLoaded={onLoaded}
          onUploaded={onUploaded}
          onDeleting={onDeleting}
          onDeleted={onDeleted}
          onError={onError}
          onRetry={onRetry}
        />
      ))}
      <input type="file" multiple ref={fileInputElementRef} onChange={fileChangeHandler} style={{ display: 'none' }} />
    </div>
  );
};

export default FileAutoUploader;
