import { MembershipReadViewRoleEnum, PropertySubscriptionReadView, UserReadView } from '../GeneratedServices';
import { InvitationReadView } from '../Services/InvitationService.types';
import { MembershipReadView } from '../Services/MembershipService.types';
import i18n from '../i18n';

export enum Role {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Technician = 'TECHNICIAN',
  Investor = 'INVESTOR'
}

const roleRules = new Map<Role | undefined, Role[]>([
  // Superuser
  [undefined, [Role.Admin, Role.Manager, Role.Technician, Role.Investor]],

  // Admin
  [Role.Admin, [Role.Admin, Role.Manager, Role.Technician, Role.Investor]],

  // Manager
  [Role.Manager, [Role.Manager, Role.Technician, Role.Investor]]
]);

export const translateRole = (role: Role) => {
  const map = new Map([
    [Role.Admin, i18n.t('common:administrator')],
    [Role.Manager, i18n.t('common:propertyManager')],
    [Role.Technician, i18n.t('common:propertyCaretaker')],
    [Role.Investor, i18n.t('common:guest')]
  ]);

  return map.has(role) ? map.get(role)! : role.toString();
};

/**
 * Returns a value indicating whether current user can invite users with a specific role.
 *
 * @param currentRole Role of logged in user.
 * @param roleToInvite Role to invite.
 */
export const canInviteUserRole = (currentRole: Role, roleToInvite: Role) => {
  if (!roleRules.has(currentRole)) {
    return false;
  }

  return roleRules.get(currentRole)!.includes(roleToInvite);
};

/**
 * Returns a value indicating whether current user can revoke a specific user invitation.
 *
 * @param currentRole Role of logged in user.
 * @param invitation Invitation to revoke.
 */
export const canRevokeUserInvitation = (currentRole: Role, invitation: InvitationReadView) => {
  if (!roleRules.has(currentRole)) {
    return false;
  }

  return roleRules.get(currentRole)!.includes(invitation.role as Role);
};

/**
 * Returns a value indicating whether current user can remove a user from an organisation.
 *
 * @param currentRole Role of logged in user.
 * @param membership Membership to delete.
 */
export const canRemoveUserFromOrganisation = (currentRole: Role, membership: MembershipReadView) => {
  if (!roleRules.has(currentRole)) {
    return false;
  }

  return roleRules.get(currentRole)!.includes(membership.role as Role);
};

/**
 * Returns a list of role items that current user has permission to use.
 *
 * @param currentRole Role of logged in user.
 */
export const resolveRoleDropdownItems = (currentRole: Role) => {
  if (!roleRules.has(currentRole)) {
    return [];
  }

  return roleRules.get(currentRole)!.map((role) => {
    return {
      key: role,
      text: translateRole(role),
      value: role
    };
  });
};

/**
 * Returns a value indicating if the user is a superuser, manager or admin in any organisation.
 *
 * @param user User to check.
 * @param memberships List of memberships for the user.
 */
export const hasARolePermittedToAccessData = (user?: UserReadView, memberships?: MembershipReadView[]) => {
  const userMembershipRoles = memberships?.map((item) => item.role);
  const isUserAdminOrManagerOrInvestor =
    userMembershipRoles?.includes(Role.Manager) ||
    userMembershipRoles?.includes(Role.Admin) ||
    userMembershipRoles?.includes(Role.Investor);
  if (user?.isSuperuser) return true;
  else if (isUserAdminOrManagerOrInvestor) return true;
  else return false;
};

/**
 * Returns a value indicating if the user is a superuser , or manager/admin for the organisation.
 *
 * @param user User to check.
 * @param memberships List of memberships for the user.
 * @param organisationId Id of organisation to check.
 */
export const hasPermittedWriteRoleInOrganisation = (
  user?: UserReadView,
  memberships?: MembershipReadView[],
  organisationId?: number
) => {
  const membershipForOrganisation = memberships?.find(
    (item) => organisationId && item.organisationId === organisationId
  );
  const isUserAdminOrManagerForOrganisation =
    membershipForOrganisation?.role === Role.Admin || membershipForOrganisation?.role === Role.Manager;
  if (user?.isSuperuser) return true;
  else if (isUserAdminOrManagerForOrganisation) return true;
  else return false;
};

/**
 * Returns a value indicating if the user is a superuser, manager, admin or investor for the organisation.
 * If the organisationId is undefined returns a value in regards to any organisation.
 *
 * @param user User to check.
 * @param memberships List of memberships for the user.
 * @param organisationId Id of organisation to check.
 */
export const hasPermittedReadRoleInOrganisation = (
  user?: UserReadView,
  memberships?: MembershipReadView[],
  organisationId?: number
) => {
  const membershipForOrganisation = memberships?.find(
    (item) => organisationId && item.organisationId === organisationId
  );
  const permitedRoles: Role[] = [Role.Admin, Role.Investor, Role.Manager];
  const isUserAdminOrManagerForOrganisation =
    membershipForOrganisation && permitedRoles.includes(membershipForOrganisation.role as Role);
  if (user?.isSuperuser) return true;
  else if (organisationId === undefined) return hasARolePermittedToAccessData(user, memberships);
  else if (isUserAdminOrManagerForOrganisation) return true;
  else return false;
};

/**
 * Returns an of distinct roles from the user memberships.
 *
 * @param memberships List of memberships for the user.
 */
export const getUserRoles = (memberships?: MembershipReadView[]) => {
  const roles = Array.from(new Set(memberships?.map((item) => item.role)));
  return roles;
};

/**
 * Returns a value indicating if the user is a superuser or admin for the organisation.
 *
 * @param user User to check.
 * @param memberships List of memberships for the user.
 * @param organisationId Id of organisation to check.
 */
export const isAdminForOrganisation = (
  user?: UserReadView,
  memberships?: MembershipReadView[],
  organisationId?: number
) => {
  const membershipForOrganisation = memberships?.find(
    (item) => organisationId && item.organisationId === organisationId
  );
  return user?.isSuperuser || membershipForOrganisation?.role === Role.Admin;
};

/**
 * Returns a value indicating if the user has one of the provided roles in the organisation.
 * If the organisationId is undefined returns a value in regards to any organisation.
 *
 * @param roles Roles to check.
 * @param user User to check.
 * @param memberships List of memberships for the user.
 * @param organisationId Id of organisation to check.
 */
export const hasRoleMatchWithinOrganisation = (
  roles: Role[],
  user?: UserReadView,
  memberships?: MembershipReadView[],
  organisationId?: number
) => {
  const membershipForOrganisation = memberships?.find(
    (item) => organisationId && item.organisationId === organisationId
  );
  const hasRoleMatch = membershipForOrganisation && roles.includes(membershipForOrganisation.role);
  if (user?.isSuperuser) return true;
  else if (organisationId === undefined) return memberships?.some((membership) => roles.includes(membership.role));
  else if (hasRoleMatch) return true;
  else return false;
};

/**
 * Returns a value indicating if the user has one of the provided roles in any organisation
 *
 * @param roles Roles to check.
 * @param user User to check.
 * @param memberships List of memberships for the user.
 */
export const hasRoleMatch = (roles: Role[], user?: UserReadView, memberships?: MembershipReadView[]) => {
  return hasRoleMatchWithinOrganisation(roles, user, memberships);
};

/**
 * Returns a value indicating if the user has the ability to manipulate subscriptions in the organisation.
 *
 * @param subscription Subscription to check.
 * @param organisationId Subscription's organisation.
 * @param memberships List of memberships for the user.
 * @param user User.
 */
export const canManipulateSubscription = (
  subscription: PropertySubscriptionReadView,
  organisationId: number,
  memberships?: MembershipReadView[],
  user?: UserReadView
) => {
  const membershipInOrganisation = memberships?.find((item) => item.organisationId === organisationId);

  if (
    user?.isSuperuser ||
    membershipInOrganisation?.role === MembershipReadViewRoleEnum.Admin ||
    membershipInOrganisation?.role === MembershipReadViewRoleEnum.Manager ||
    (membershipInOrganisation?.role === MembershipReadViewRoleEnum.Technician &&
      subscription.role === MembershipReadViewRoleEnum.Technician)
  ) {
    return true;
  } else return false;
};
