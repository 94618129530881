import { BarTooltipProps } from '@nivo/bar';
import React from 'react';
import { Card, Label, Table } from 'semantic-ui-react';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { formatCurrency } from '../../../Utils/number';
import NumberFormater from '../../NumberFormater/NumberFormater';
import { FinancialStatisticsFilterType } from '../FinancialStatistics';
import { translateFinancialStatisticsFilterType } from './FinancialStatisticsFilters';
import styles from './FinancialStatisticsCustomTooltip.module.scss';
import ReactDOM from 'react-dom';
import { FinancialReportReadView } from '../../../GeneratedServices';
import { useTranslation } from 'react-i18next';
import { generatePeriodLabel } from '../../../Services/FinancialReportService';
import { useMousePosition } from '../../../Utils/hooks';

interface Props {
  tooltipProps: React.PropsWithChildren<
    BarTooltipProps<{
      label: string;
      actual: number;
      planned: number;
    }>
  >;
  financialStatistics: FinancialReportReadView[];
  selectedStatisticsType: FinancialStatisticsFilterType[];
  areStatisticsPerArea: boolean;
}
const getFinancialStatisticTypeOrder = (type: FinancialStatisticsFilterType) => {
  switch (type) {
    case FinancialStatisticsFilterType.Income:
      return 1;
    case FinancialStatisticsFilterType.OPEX:
      return 2;
    case FinancialStatisticsFilterType.Maintenance:
      return 3;
    case FinancialStatisticsFilterType.Management:
      return 4;
    case FinancialStatisticsFilterType.CapexAndTi:
      return 5;
  }
};

const orderStatisticsType = (selectedStatisticsType: FinancialStatisticsFilterType[]) => {
  return selectedStatisticsType.sort((a, b) => {
    const aOrder = getFinancialStatisticTypeOrder(a);
    const bOrder = getFinancialStatisticTypeOrder(b);
    return aOrder > bOrder ? 1 : -1;
  });
};
const getStatisticalValue = (
  filter: FinancialStatisticsFilterType,
  financialStatistics?: FinancialReportReadView,
  planned?: boolean
) => {
  if (financialStatistics)
    switch (filter) {
      case FinancialStatisticsFilterType.Income:
        return planned ? financialStatistics?.totalIncome.planned : financialStatistics?.totalIncome.actual;
      case FinancialStatisticsFilterType.CapexAndTi:
        return planned ? financialStatistics?.capexAndTi.planned : financialStatistics?.capexAndTi.actual;

      case FinancialStatisticsFilterType.Maintenance:
        return planned ? financialStatistics?.maintenance.planned : financialStatistics?.maintenance.actual;
      case FinancialStatisticsFilterType.Management:
        return planned
          ? financialStatistics?.propertyManagement.planned
          : financialStatistics?.propertyManagement.actual;
      case FinancialStatisticsFilterType.OPEX:
        return planned ? financialStatistics?.OPEX.planned : financialStatistics?.OPEX.actual;
    }
  else return 0;
};

const FinancialStatisticsCustomTooltip: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'financialStatistics']);
  const position = useMousePosition();

  const { areStatisticsPerArea, financialStatistics, selectedStatisticsType, tooltipProps } = props;
  const foundStatistics = financialStatistics.find((item) => generatePeriodLabel(item) === tooltipProps?.data.label);

  const content = (
    <div
      className={styles.tooltipWrapper}
      style={{
        display: !position.x && !position.y ? 'none' : undefined,
        top: position.y + 30,
        left: position.x - 110
      }}
    >
      <Card className={styles.hoverCard}>
        <Card.Content>
          <div className={styles.headerContainer}>
            <Card.Header>
              {tooltipProps.id === 'actual' ? t('financialStatistics:outcome') : t('common:businessPlan')}
            </Card.Header>
            <div>
              <Label
                circular
                style={{
                  backgroundColor: tooltipProps.indexValue.toString().includes('*')
                    ? tooltipProps.id === 'actual'
                      ? colors.chart9
                      : colors.chart8
                    : tooltipProps.id === 'actual'
                    ? colors.chart4
                    : colors.chart1
                }}
                size="tiny"
              />
            </div>
          </div>

          <Card.Description>
            <Table basic={'very'} singleLine compact className={styles.tooltipTable}>
              <Table.Body>
                {orderStatisticsType(selectedStatisticsType).map((item) => {
                  return (
                    <Table.Row key={item}>
                      <Table.Cell>{translateFinancialStatisticsFilterType(item)}</Table.Cell>
                      <Table.Cell
                        style={{ color: item === FinancialStatisticsFilterType.Income ? colors.green : colors.red }}
                      >
                        <NumberFormater
                          {...formatCurrency(
                            getStatisticalValue(item, foundStatistics, tooltipProps.id === 'planned') ?? 0,
                            {
                              unit: areStatisticsPerArea ? t('common:sekSqm') : undefined,
                              forceCurrency: props.areStatisticsPerArea ? 'SEK' : 'KSEK'
                            }
                          )}
                          additionalProps={{
                            allowNegative: true,
                            decimalScale: props.areStatisticsPerArea ? 1 : 0
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}

                <Table.Row className={styles.boldRow}>
                  <Table.Cell>{t('financialStatistics:total')}</Table.Cell>
                  <Table.Cell>
                    <NumberFormater
                      {...formatCurrency(tooltipProps.value, {
                        unit: areStatisticsPerArea ? t('common:sekSqm') : undefined,
                        forceCurrency: props.areStatisticsPerArea ? 'SEK' : 'KSEK'
                      })}
                      additionalProps={{ allowNegative: true, decimalScale: props.areStatisticsPerArea ? 1 : 0 }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

export default FinancialStatisticsCustomTooltip;
