import React from 'react';
import { Modal, Tab } from 'semantic-ui-react';
import {
  MembershipReadView,
  MembershipReadViewRoleEnum,
  PropertySubscriptionReadView,
  UserReadView
} from '../../../../GeneratedServices';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { createInfinitePaginationParams, membershipApi } from '../../../../Http/Http';
import { useNonce } from '../../../../Utils/hooks';
import AddSubscriptionsTab from './AddSubscriptionsTab';
import SubscriptionsTab from './SubscriptionsTab';
import styles from './SubscriptionsModal.module.scss';
import {
  hasPermittedWriteRoleInOrganisation,
  hasRoleMatchWithinOrganisation,
  Role
} from '../../../../Utils/permissions';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
  subscriptions: PropertySubscriptionReadView[];
  reloadSubscriptions: () => void;
  organisationId: number;
  propertyId: number;
  activeTab?: SubscriptionsTabs;
}

export enum SubscriptionsTabs {
  SUBSCRIPTIONS = 0,
  ADD_SUBSCRIPTIONS = 1
}

export enum MembershipStatus {
  None,
  Loading,
  LoadError
}

const filterMembershipsBasedOnCurrentUserRole = (
  organisationId: number,
  memberships: MembershipReadView[],
  currentUserMemberships?: MembershipReadView[],
  currentUser?: UserReadView
) => {
  const isPermitted = hasPermittedWriteRoleInOrganisation(
    currentUser,
    currentUserMemberships?.map((item) => ({ ...item, role: item.role as Role })),
    organisationId
  );
  if (!isPermitted) {
    return memberships.filter((item) => item.role === MembershipReadViewRoleEnum.Technician);
  } else return memberships.filter((item) => item.role !== MembershipReadViewRoleEnum.Investor);
};

const shouldTabBeVisible = (
  organisationId: number,
  currentUserMemberships?: MembershipReadView[],
  currentUser?: UserReadView
) => {
  return hasRoleMatchWithinOrganisation(
    [Role.Admin, Role.Technician, Role.Manager],
    currentUser,
    currentUserMemberships?.map((item) => ({ ...item, role: item.role as Role })),
    organisationId
  );
};

const SubscriptionsModal: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const { handleHttpErrors, currentUserMemberships, currentUser } = React.useContext(globalStateCTX);

  const [users, setUsers] = React.useState<MembershipReadView[]>();
  const [status, setStatus] = React.useState<MembershipStatus>(MembershipStatus.None);
  const [shouldReloadUsers, reloadUsers] = useNonce();

  const [activeTab, setActiveTab] = React.useState<SubscriptionsTabs>(
    props.activeTab ?? SubscriptionsTabs.SUBSCRIPTIONS
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const loadUsers = async () => {
      try {
        setStatus(MembershipStatus.Loading);

        const { data } = await membershipApi.membershipResourceList(
          {
            ...createInfinitePaginationParams(),
            organisationId: props.organisationId
          },
          {
            signal: abortController.signal
          }
        );

        setUsers(
          filterMembershipsBasedOnCurrentUserRole(
            props.organisationId,
            data.records,
            currentUserMemberships,
            currentUser
          )
        );

        setStatus(MembershipStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(MembershipStatus.LoadError);
      }
    };

    loadUsers();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, props.organisationId, shouldReloadUsers, currentUserMemberships, currentUser]);

  const panes = [
    {
      menuItem: {
        content: t('followers'),
        key: SubscriptionsTabs.SUBSCRIPTIONS
      },
      content: (
        <Tab.Pane>
          <SubscriptionsTab
            subscriptions={props.subscriptions}
            reloadSubscriptions={props.reloadSubscriptions}
            organisationId={props.organisationId}
          />
        </Tab.Pane>
      ),
      visible: true
    },
    {
      menuItem: {
        content: t('add'),
        key: SubscriptionsTabs.ADD_SUBSCRIPTIONS
      },
      content: (
        <Tab.Pane>
          <AddSubscriptionsTab
            subscriptions={props.subscriptions}
            reloadSubscriptions={props.reloadSubscriptions}
            organisationId={props.organisationId}
            users={users ?? []}
            userStatus={status}
            reloadUsers={reloadUsers}
            propertyId={props.propertyId}
          />
        </Tab.Pane>
      ),
      visible: shouldTabBeVisible(props.organisationId, currentUserMemberships, currentUser)
    }
  ];
  return (
    <Modal open closeOnEscape={false} closeOnDimmerClick={false} onClose={props.onClose} closeIcon>
      <Modal.Header className={styles.header}>
        {t('followers')}
        <Tab
          menu={{ secondary: true, pointing: true, size: 'large', className: styles.tabMenu }}
          activeIndex={activeTab}
          panes={panes.filter((item) => item.visible)}
          onTabChange={(i, d) => setActiveTab(d.activeIndex as SubscriptionsTabs)}
        />
      </Modal.Header>
      <Modal.Content scrolling={activeTab === SubscriptionsTabs.SUBSCRIPTIONS}>
        {panes[activeTab].content}
      </Modal.Content>
    </Modal>
  );
};

export default SubscriptionsModal;
