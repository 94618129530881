import { CoverageRatioReadViewTypeEnum } from '../GeneratedServices';
import i18n from '../i18n';

export const translateCoverageRatioType = (type: CoverageRatioReadViewTypeEnum) => {
  switch (type) {
    case CoverageRatioReadViewTypeEnum.Electricity:
      return i18n.t('contracts:additionalType.electricity');
    case CoverageRatioReadViewTypeEnum.Waste:
      return i18n.t('contracts:additionalType.waste');
    case CoverageRatioReadViewTypeEnum.Heating:
      return i18n.t('contracts:additionalType.heat');
    case CoverageRatioReadViewTypeEnum.RealEstateTaxes:
      return i18n.t('contracts:additionalType.propertyTax');
    case CoverageRatioReadViewTypeEnum.SnowRemovalAndGarden:
      return i18n.t('contracts:additionalType.snowRemovalAndGardening');
    case CoverageRatioReadViewTypeEnum.WaterAndSewage:
      return i18n.t('contracts:additionalType.waterAndSewage');
  }
};
