/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdditionsByTypeReadView } from '../model';
// @ts-ignore
import { AreaOccupancyReadView } from '../model';
// @ts-ignore
import { AreaReadView } from '../model';
// @ts-ignore
import { BaseRentReadView } from '../model';
// @ts-ignore
import { CapexAndTiYTQStatisticsReadView } from '../model';
// @ts-ignore
import { CoverageRatioListView } from '../model';
// @ts-ignore
import { EconomicOccupancyReadView } from '../model';
// @ts-ignore
import { EnergyYTQStatisticsReadView } from '../model';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { GovernmentInspectionStatisticsReadView } from '../model';
// @ts-ignore
import { LeaseStatusStatisticsReadView } from '../model';
// @ts-ignore
import { ObjectsCountsReadView } from '../model';
// @ts-ignore
import { ParkingStatisticsReadView } from '../model';
// @ts-ignore
import { PlotAreaReadView } from '../model';
// @ts-ignore
import { RoutineInspectionStatisticsReadView } from '../model';
// @ts-ignore
import { TotalRentReadView } from '../model';
// @ts-ignore
import { WaultStatisticsReadView } from '../model';
// @ts-ignore
import { WorkOrderStatisticsReadView } from '../model';
// @ts-ignore
import { YTQStatisticsReadView } from '../model';
/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get additions by type statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetAdditionsByTypeBreakdown: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/additions-by-type`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get area statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetArea: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/area`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get (indoor) area occupancy statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetAreaOccupancy: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/area-occupancy`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get base rent statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetBaseRent: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/base-rent`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get economic occupancy statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetEconomicOccupancy: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/economic-occupancy`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get objects counts statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetObjectsCounts: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/objects-counts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get parking statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetParking: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/parking`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get plot area statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetPlotArea: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/plot-area`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get total rent statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetTotalRent: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/total-rent`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get Capex & TI YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadCapexAndTiYTQStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/capex-and-ti-ytq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get cost YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadCostYTQStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/cost-ytq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get coverage ratio.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadCoverageRatio: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/coverage-ratio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get Energy YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadEnergyYTQStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/energy-ytq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get government inspections statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadGovernmentInspectionStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/government-inspections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get income YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadIncomeYTQStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/income-ytq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get lease status statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadLeaseStatusStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/lease-status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get NOI YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadNOIYTQStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/noi-ytq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get routine inspections statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadRoutineInspectionStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/routine-inspections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get WAULT statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadWAULTStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/wault`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get error report statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {number} [connectedToPropertyUserId] Filter by fault report\&#39;s assignee or an existing user property subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadWorkOrderStatistics: async (
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      connectedToPropertyUserId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/statistics/work-orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (connectedToPropertyUserId !== undefined) {
        localVarQueryParameter['connectedToPropertyUserId'] = connectedToPropertyUserId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get additions by type statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetAdditionsByTypeBreakdown(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionsByTypeReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetAdditionsByTypeBreakdown(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get area statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetArea(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetArea(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get (indoor) area occupancy statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetAreaOccupancy(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaOccupancyReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetAreaOccupancy(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get base rent statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetBaseRent(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseRentReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetBaseRent(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get economic occupancy statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetEconomicOccupancy(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EconomicOccupancyReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetEconomicOccupancy(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get objects counts statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetObjectsCounts(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectsCountsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetObjectsCounts(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get parking statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetParking(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetParking(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get plot area statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetPlotArea(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlotAreaReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetPlotArea(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get total rent statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceGetTotalRent(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalRentReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceGetTotalRent(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Capex & TI YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadCapexAndTiYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CapexAndTiYTQStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadCapexAndTiYTQStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get cost YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadCostYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YTQStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadCostYTQStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get coverage ratio.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadCoverageRatio(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoverageRatioListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadCoverageRatio(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Energy YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadEnergyYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnergyYTQStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadEnergyYTQStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get government inspections statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadGovernmentInspectionStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadGovernmentInspectionStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get income YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadIncomeYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YTQStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadIncomeYTQStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get lease status statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadLeaseStatusStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseStatusStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadLeaseStatusStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get NOI YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadNOIYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YTQStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadNOIYTQStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get routine inspections statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadRoutineInspectionStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutineInspectionStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadRoutineInspectionStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get WAULT statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadWAULTStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WaultStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadWAULTStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get error report statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {number} [connectedToPropertyUserId] Filter by fault report\&#39;s assignee or an existing user property subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsResourceReadWorkOrderStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      connectedToPropertyUserId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsResourceReadWorkOrderStatistics(
        organisationId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        connectedToPropertyUserId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = StatisticsApiFp(configuration);
  return {
    /**
     *
     * @summary Get additions by type statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetAdditionsByTypeBreakdown(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<AdditionsByTypeReadView> {
      return localVarFp
        .statisticsResourceGetAdditionsByTypeBreakdown(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get area statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetArea(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<AreaReadView> {
      return localVarFp
        .statisticsResourceGetArea(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get (indoor) area occupancy statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetAreaOccupancy(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<AreaOccupancyReadView> {
      return localVarFp
        .statisticsResourceGetAreaOccupancy(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get base rent statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetBaseRent(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<BaseRentReadView> {
      return localVarFp
        .statisticsResourceGetBaseRent(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get economic occupancy statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetEconomicOccupancy(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<EconomicOccupancyReadView> {
      return localVarFp
        .statisticsResourceGetEconomicOccupancy(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get objects counts statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetObjectsCounts(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<ObjectsCountsReadView> {
      return localVarFp
        .statisticsResourceGetObjectsCounts(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get parking statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetParking(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<ParkingStatisticsReadView> {
      return localVarFp
        .statisticsResourceGetParking(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get plot area statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetPlotArea(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<PlotAreaReadView> {
      return localVarFp
        .statisticsResourceGetPlotArea(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get total rent statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceGetTotalRent(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<TotalRentReadView> {
      return localVarFp
        .statisticsResourceGetTotalRent(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Capex & TI YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadCapexAndTiYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<CapexAndTiYTQStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadCapexAndTiYTQStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get cost YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadCostYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<YTQStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadCostYTQStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get coverage ratio.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadCoverageRatio(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<CoverageRatioListView> {
      return localVarFp
        .statisticsResourceReadCoverageRatio(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Energy YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadEnergyYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<EnergyYTQStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadEnergyYTQStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get government inspections statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadGovernmentInspectionStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<GovernmentInspectionStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadGovernmentInspectionStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get income YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadIncomeYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<YTQStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadIncomeYTQStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get lease status statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadLeaseStatusStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<LeaseStatusStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadLeaseStatusStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get NOI YTQ statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadNOIYTQStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<YTQStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadNOIYTQStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get routine inspections statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadRoutineInspectionStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<RoutineInspectionStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadRoutineInspectionStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get WAULT statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadWAULTStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<WaultStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadWAULTStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get error report statistics.
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {number} [connectedToPropertyUserId] Filter by fault report\&#39;s assignee or an existing user property subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsResourceReadWorkOrderStatistics(
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      connectedToPropertyUserId?: number,
      options?: any
    ): AxiosPromise<WorkOrderStatisticsReadView> {
      return localVarFp
        .statisticsResourceReadWorkOrderStatistics(
          organisationId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          connectedToPropertyUserId,
          options
        )
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for statisticsResourceGetAdditionsByTypeBreakdown operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdownRequest
 */
export interface StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdownRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdown
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdown
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdown
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdown
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdown
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdown
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdown
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdown
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceGetArea operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetAreaRequest
 */
export interface StatisticsApiStatisticsResourceGetAreaRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetArea
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetArea
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetArea
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetArea
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetArea
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetArea
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetArea
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetArea
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceGetAreaOccupancy operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetAreaOccupancyRequest
 */
export interface StatisticsApiStatisticsResourceGetAreaOccupancyRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetAreaOccupancy
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetAreaOccupancy
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetAreaOccupancy
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetAreaOccupancy
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetAreaOccupancy
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetAreaOccupancy
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetAreaOccupancy
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetAreaOccupancy
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceGetBaseRent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetBaseRentRequest
 */
export interface StatisticsApiStatisticsResourceGetBaseRentRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetBaseRent
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetBaseRent
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetBaseRent
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetBaseRent
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetBaseRent
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetBaseRent
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetBaseRent
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetBaseRent
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceGetEconomicOccupancy operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetEconomicOccupancyRequest
 */
export interface StatisticsApiStatisticsResourceGetEconomicOccupancyRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetEconomicOccupancy
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetEconomicOccupancy
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetEconomicOccupancy
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetEconomicOccupancy
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetEconomicOccupancy
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetEconomicOccupancy
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetEconomicOccupancy
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetEconomicOccupancy
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceGetObjectsCounts operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetObjectsCountsRequest
 */
export interface StatisticsApiStatisticsResourceGetObjectsCountsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetObjectsCounts
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetObjectsCounts
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetObjectsCounts
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetObjectsCounts
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetObjectsCounts
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetObjectsCounts
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetObjectsCounts
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetObjectsCounts
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceGetParking operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetParkingRequest
 */
export interface StatisticsApiStatisticsResourceGetParkingRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetParking
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetParking
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetParking
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetParking
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetParking
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetParking
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetParking
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetParking
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceGetPlotArea operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetPlotAreaRequest
 */
export interface StatisticsApiStatisticsResourceGetPlotAreaRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetPlotArea
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetPlotArea
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetPlotArea
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetPlotArea
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetPlotArea
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetPlotArea
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetPlotArea
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetPlotArea
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceGetTotalRent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceGetTotalRentRequest
 */
export interface StatisticsApiStatisticsResourceGetTotalRentRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetTotalRent
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetTotalRent
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetTotalRent
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceGetTotalRent
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetTotalRent
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetTotalRent
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceGetTotalRent
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceGetTotalRent
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadCapexAndTiYTQStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadCapexAndTiYTQStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadCapexAndTiYTQStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCapexAndTiYTQStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCapexAndTiYTQStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCapexAndTiYTQStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCapexAndTiYTQStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCapexAndTiYTQStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCapexAndTiYTQStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCapexAndTiYTQStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadCapexAndTiYTQStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadCostYTQStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadCostYTQStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadCostYTQStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCostYTQStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCostYTQStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCostYTQStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCostYTQStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCostYTQStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCostYTQStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCostYTQStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadCostYTQStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadCoverageRatio operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadCoverageRatioRequest
 */
export interface StatisticsApiStatisticsResourceReadCoverageRatioRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCoverageRatio
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCoverageRatio
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCoverageRatio
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadCoverageRatio
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCoverageRatio
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCoverageRatio
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadCoverageRatio
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadCoverageRatio
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadEnergyYTQStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadEnergyYTQStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadEnergyYTQStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadEnergyYTQStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadEnergyYTQStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadEnergyYTQStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadEnergyYTQStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadEnergyYTQStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadEnergyYTQStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadEnergyYTQStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadEnergyYTQStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadGovernmentInspectionStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadGovernmentInspectionStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadGovernmentInspectionStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadGovernmentInspectionStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadGovernmentInspectionStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadGovernmentInspectionStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadGovernmentInspectionStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadGovernmentInspectionStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadGovernmentInspectionStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadGovernmentInspectionStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadGovernmentInspectionStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadIncomeYTQStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadIncomeYTQStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadIncomeYTQStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadIncomeYTQStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadIncomeYTQStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadIncomeYTQStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadIncomeYTQStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadIncomeYTQStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadIncomeYTQStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadIncomeYTQStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadIncomeYTQStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadLeaseStatusStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadLeaseStatusStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadLeaseStatusStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadLeaseStatusStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadLeaseStatusStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadLeaseStatusStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadLeaseStatusStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadLeaseStatusStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadLeaseStatusStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadLeaseStatusStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadLeaseStatusStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadNOIYTQStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadNOIYTQStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadNOIYTQStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadNOIYTQStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadNOIYTQStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadNOIYTQStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadNOIYTQStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadNOIYTQStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadNOIYTQStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadNOIYTQStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadNOIYTQStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadRoutineInspectionStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadRoutineInspectionStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadRoutineInspectionStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadRoutineInspectionStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadRoutineInspectionStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadRoutineInspectionStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadRoutineInspectionStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadRoutineInspectionStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadRoutineInspectionStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadRoutineInspectionStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadRoutineInspectionStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadWAULTStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadWAULTStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadWAULTStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWAULTStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWAULTStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWAULTStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWAULTStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadWAULTStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadWAULTStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadWAULTStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadWAULTStatistics
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for statisticsResourceReadWorkOrderStatistics operation in StatisticsApi.
 * @export
 * @interface StatisticsApiStatisticsResourceReadWorkOrderStatisticsRequest
 */
export interface StatisticsApiStatisticsResourceReadWorkOrderStatisticsRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by fault report\&#39;s assignee or an existing user property subscription
   * @type {number}
   * @memberof StatisticsApiStatisticsResourceReadWorkOrderStatistics
   */
  readonly connectedToPropertyUserId?: number;
}

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
  /**
   *
   * @summary Get additions by type statistics.
   * @param {StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdownRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetAdditionsByTypeBreakdown(
    requestParameters: StatisticsApiStatisticsResourceGetAdditionsByTypeBreakdownRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetAdditionsByTypeBreakdown(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get area statistics.
   * @param {StatisticsApiStatisticsResourceGetAreaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetArea(
    requestParameters: StatisticsApiStatisticsResourceGetAreaRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetArea(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get (indoor) area occupancy statistics.
   * @param {StatisticsApiStatisticsResourceGetAreaOccupancyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetAreaOccupancy(
    requestParameters: StatisticsApiStatisticsResourceGetAreaOccupancyRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetAreaOccupancy(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get base rent statistics.
   * @param {StatisticsApiStatisticsResourceGetBaseRentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetBaseRent(
    requestParameters: StatisticsApiStatisticsResourceGetBaseRentRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetBaseRent(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get economic occupancy statistics.
   * @param {StatisticsApiStatisticsResourceGetEconomicOccupancyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetEconomicOccupancy(
    requestParameters: StatisticsApiStatisticsResourceGetEconomicOccupancyRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetEconomicOccupancy(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get objects counts statistics.
   * @param {StatisticsApiStatisticsResourceGetObjectsCountsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetObjectsCounts(
    requestParameters: StatisticsApiStatisticsResourceGetObjectsCountsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetObjectsCounts(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get parking statistics.
   * @param {StatisticsApiStatisticsResourceGetParkingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetParking(
    requestParameters: StatisticsApiStatisticsResourceGetParkingRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetParking(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get plot area statistics.
   * @param {StatisticsApiStatisticsResourceGetPlotAreaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetPlotArea(
    requestParameters: StatisticsApiStatisticsResourceGetPlotAreaRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetPlotArea(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get total rent statistics.
   * @param {StatisticsApiStatisticsResourceGetTotalRentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceGetTotalRent(
    requestParameters: StatisticsApiStatisticsResourceGetTotalRentRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceGetTotalRent(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Capex & TI YTQ statistics.
   * @param {StatisticsApiStatisticsResourceReadCapexAndTiYTQStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadCapexAndTiYTQStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadCapexAndTiYTQStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadCapexAndTiYTQStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get cost YTQ statistics.
   * @param {StatisticsApiStatisticsResourceReadCostYTQStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadCostYTQStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadCostYTQStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadCostYTQStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get coverage ratio.
   * @param {StatisticsApiStatisticsResourceReadCoverageRatioRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadCoverageRatio(
    requestParameters: StatisticsApiStatisticsResourceReadCoverageRatioRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadCoverageRatio(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Energy YTQ statistics.
   * @param {StatisticsApiStatisticsResourceReadEnergyYTQStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadEnergyYTQStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadEnergyYTQStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadEnergyYTQStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get government inspections statistics.
   * @param {StatisticsApiStatisticsResourceReadGovernmentInspectionStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadGovernmentInspectionStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadGovernmentInspectionStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadGovernmentInspectionStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get income YTQ statistics.
   * @param {StatisticsApiStatisticsResourceReadIncomeYTQStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadIncomeYTQStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadIncomeYTQStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadIncomeYTQStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get lease status statistics.
   * @param {StatisticsApiStatisticsResourceReadLeaseStatusStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadLeaseStatusStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadLeaseStatusStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadLeaseStatusStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get NOI YTQ statistics.
   * @param {StatisticsApiStatisticsResourceReadNOIYTQStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadNOIYTQStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadNOIYTQStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadNOIYTQStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get routine inspections statistics.
   * @param {StatisticsApiStatisticsResourceReadRoutineInspectionStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadRoutineInspectionStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadRoutineInspectionStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadRoutineInspectionStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get WAULT statistics.
   * @param {StatisticsApiStatisticsResourceReadWAULTStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadWAULTStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadWAULTStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadWAULTStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get error report statistics.
   * @param {StatisticsApiStatisticsResourceReadWorkOrderStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsResourceReadWorkOrderStatistics(
    requestParameters: StatisticsApiStatisticsResourceReadWorkOrderStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .statisticsResourceReadWorkOrderStatistics(
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.connectedToPropertyUserId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
