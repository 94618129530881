import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Divider, Form, Header, Modal } from 'semantic-ui-react';
import { BAM_API_BASE_URL } from '../../../../config';
import { FiltersContext } from '../../../../FiltersProvider/FiltersProvider';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { contractApi } from '../../../../Http/Http';
import { toDateString } from '../../../../Utils/date';
import DatePicker from '../../../DatePicker/DatePicker';
import { useFilters } from '../../../Filters/Hooks/useFilters';
import PortfolioDropdown from '../../../Filters/FilterDropdowns/PortfolioDropdown';
import UserMembershipDropdown from '../../../Filters/FilterDropdowns/UserMembershipDropdown';

interface Props {
  onClose: () => void;
  propertyId?: number;
}
enum Status {
  None,
  Exporting
}

const LeaseExportModal: React.FC<Props> = (props) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const { portfolioIds, propertySubscriptionUserIds } = React.useContext(FiltersContext);

  const { t } = useTranslation(['common', 'contracts']);

  const [status, setStatus] = React.useState<Status>(Status.None);

  const [selectedPortfolioIds, setSelectedPortfolioIds] = React.useState<number[] | undefined>(
    props.propertyId ? undefined : portfolioIds
  );
  const [selectedPropertySubscriptionUserIds, setSelectedPropertySubscriptionUserIds] = React.useState<
    number[] | undefined
  >(props.propertyId ? undefined : propertySubscriptionUserIds);

  const { handleClearFilters, handlePortfoliosChange, memberships, portfolios } = useFilters({
    selectedOrganisationIds: undefined,
    selectedPortfolioIds: selectedPortfolioIds,
    setSelectedOrganisationIds: () => {},
    setSelectedPortfolioIds: setSelectedPortfolioIds,
    setSelectedPropertySubscriptionUserIds: setSelectedPropertySubscriptionUserIds,
    setSelectedMunicipalities: () => {}
  });

  const [latestGeneration, setLatestGeneration] = React.useState<boolean>(false);
  const [snapshotDate, setSnapshotDate] = React.useState<string>(toDateString(new Date()));

  const exportRentroll = async () => {
    try {
      setStatus(Status.Exporting);
      const { data } = await contractApi.contractResourceExportExcelContractsUrl({
        date: !latestGeneration ? snapshotDate : undefined,
        latestGeneration: latestGeneration ?? undefined,
        propertyId: props.propertyId,
        portfolioIds: selectedPortfolioIds,
        propertySubscriptionUserIds: selectedPropertySubscriptionUserIds
      });

      window.location.assign(`${BAM_API_BASE_URL}/v2/contracts/xlsx/export/${data}`);
    } catch (error) {
      handleHttpErrors(error);
    } finally {
      setStatus(Status.None);
    }
  };
  return (
    <Modal open onClose={props.onClose} size="small" closeIcon={status === Status.None}>
      <Modal.Header>{t('contracts:exportRentroll')}</Modal.Header>
      <Modal.Content>
        <Form>
          {!props.propertyId && (
            <>
              <Header as="h4">{t('common:portfolios')}</Header>
              <PortfolioDropdown
                portfolios={portfolios}
                handlePortfoliosChange={handlePortfoliosChange}
                selectedPortfolioIds={selectedPortfolioIds}
              />
              <Divider />

              <Header as="h4">{t('common:users')}</Header>
              <UserMembershipDropdown
                memberships={memberships}
                setSelectedUserIds={setSelectedPropertySubscriptionUserIds}
                selectedUserIds={selectedPropertySubscriptionUserIds}
              />
              <Divider />
            </>
          )}

          <Form.Group widths={1}>
            <Form.Field>
              <Checkbox
                radio
                label={t('contracts:snapshot')}
                checked={!latestGeneration}
                onChange={(e, d) => setLatestGeneration(false)}
              />
            </Form.Field>

            <Form.Field>
              <Checkbox
                radio
                label={t('contracts:knownFuture')}
                checked={latestGeneration}
                onChange={(e, d) => setLatestGeneration(true)}
              />
            </Form.Field>
          </Form.Group>

          {!latestGeneration && (
            <Form.Group>
              <Form.Field>
                <DatePicker
                  popperPlacement="bottom"
                  selected={snapshotDate ? new Date(snapshotDate) : null}
                  dateFormat="P"
                  placeholderText={t('common:date')}
                  maxDate={new Date()}
                  onChange={(date) => setSnapshotDate(date ? toDateString(date) : toDateString(new Date()))}
                  customInput={<Form.Input icon="calendar alternate outline" iconPosition="left" />}
                />
              </Form.Field>
            </Form.Group>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          onClick={() => {
            setLatestGeneration(false);
            setSnapshotDate(toDateString(new Date()));
            handleClearFilters();
          }}
        >
          {t('common:clearFilters')}
        </Button>
        <Button
          primary
          onClick={exportRentroll}
          disabled={status === Status.Exporting}
          loading={status === Status.Exporting}
        >
          {t('common:export')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LeaseExportModal;
