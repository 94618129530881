import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import { Menu } from 'semantic-ui-react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { getUserRoles, Role } from '../../Utils/permissions';
import { useTranslation } from 'react-i18next';
import {
  faHouseBuilding as solidFaBuilding,
  faGauge as solidFaGauge,
  faListAlt as solidFaListAlt,
  faMap as solidFaMap,
  faCog as solidFaCog,
  faHexagonExclamation as solidFaHexagonExclamation,
  faFileMagnifyingGlass as solidFaFileMagnifyingGlass
} from '@fortawesome/pro-solid-svg-icons';
import {
  faGauge as lightFaGauge,
  faHouseBuilding as lightFaBuilding,
  faListAlt as lightFaListAlt,
  faMap as lightFaMap,
  faCog as lightFaCog,
  faHexagonExclamation as lightFaHexagonExclamation,
  faFileMagnifyingGlass as lightFaFileMagnifyingGlass
} from '@fortawesome/pro-light-svg-icons';

const SideMenu: React.FC<{}> = () => {
  const { pathname } = useLocation();
  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);

  const userRoles = getUserRoles(globalState.currentUserMemberships);

  const { t } = useTranslation(['common']);

  const normalizedPathname = pathname === '/' ? '/overview' : pathname;

  const MenuItem = ({
    route,
    iconName,
    name,
    allowedRoles
  }: {
    route: string;
    iconName: { active: IconDefinition; inActive: IconDefinition };
    name: string;
    allowedRoles?: Role[];
  }) => {
    const roleOverlap = userRoles.find((role) => allowedRoles?.includes(role));

    if (globalState.currentUser?.isSuperuser || roleOverlap || !allowedRoles)
      return (
        <Menu.Item as={Link} to={route} name={name} active={normalizedPathname.includes(route)} key={name}>
          <FontAwesomeIcon
            fixedWidth
            icon={normalizedPathname.includes(route) ? iconName.active : iconName.inActive}
            style={{ marginRight: '10px' }}
          />
          {name}
        </Menu.Item>
      );
    else return null;
  };

  return (
    <Menu as="nav" className="left-menu" vertical secondary size="large">
      <MenuItem
        name={t('common:overview')}
        route="/overview"
        iconName={{ active: solidFaGauge, inActive: lightFaGauge }}
      />

      <MenuItem
        name={t('common:properties')}
        route="/properties"
        iconName={{ active: solidFaBuilding, inActive: lightFaBuilding }}
        allowedRoles={[Role.Admin, Role.Manager, Role.Technician, Role.Investor]}
      />

      <MenuItem
        name={t('common:faultReports')}
        route="/fault-reports"
        iconName={{ active: solidFaHexagonExclamation, inActive: lightFaHexagonExclamation }}
        allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}
      />

      <MenuItem
        name={t('common:inspections')}
        route="/government-inspections"
        iconName={{ active: solidFaFileMagnifyingGlass, inActive: lightFaFileMagnifyingGlass }}
        allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}
      />
      <MenuItem
        name={t('common:routineWork')}
        route="/work-orders"
        iconName={{ active: solidFaListAlt, inActive: lightFaListAlt }}
        allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}
      />

      <MenuItem
        name={t('common:analytics')}
        route="/analytics"
        iconName={{ active: solidFaMap, inActive: lightFaMap }}
        allowedRoles={[Role.Admin, Role.Manager, Role.Investor]}
      />

      <MenuItem
        name={t('common:settings')}
        route="/organisations"
        iconName={{ active: solidFaCog, inActive: lightFaCog }}
        allowedRoles={[Role.Admin, Role.Manager, Role.Investor]}
      />
    </Menu>
  );
};

export default SideMenu;
