/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContractListItemView } from '../model';
// @ts-ignore
import { ContractListView } from '../model';
// @ts-ignore
import { ContractListViewV1 } from '../model';
// @ts-ignore
import { ContractPaymentListView } from '../model';
// @ts-ignore
import { ContractPaymentReadView } from '../model';
// @ts-ignore
import { ContractReadViewV1 } from '../model';
// @ts-ignore
import { ErrorView } from '../model';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List payments associated with a contract
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [contractId] Filter by contract
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [assigneeId] Filter by assignee
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractPaymentResourceList: async (
      limit?: number,
      offset?: number,
      contractId?: number,
      organisationId?: number,
      tenantId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/payments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (contractId !== undefined) {
        localVarQueryParameter['contractId'] = contractId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (tenantId !== undefined) {
        localVarQueryParameter['tenantId'] = tenantId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List payments associated with a contract
     * @param {number} id Contract payment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractPaymentResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('contractPaymentResourceRead', 'id', id);
      const localVarPath = `/v1/payments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Export contracts to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceExportContracts: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('contractResourceExportContracts', 'token', token);
      const localVarPath = `/v2/contracts/xlsx/export/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get a token to export contracts
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [propertyAssigneeId] Filter by property assignee
     * @param {boolean} [isVacant] Filter by vacant contracts
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {string} [minStatusDate] Filter by status min date.
     * @param {string} [maxStatusDate] Filter by status max date.
     * @param {Array<'FUTURE_MOVE_OUT_WITHOUT_DATE' | 'VACANT_WITHOUT_MOVE_OUT' | 'VACANT_WITH_MOVE_OUT' | 'CHANGE_OF_TERMS' | 'TENANT_CHANGE' | 'FUTURE_VACANT_WITH_MOVE_OUT' | 'MOVED_OUT' | 'ONGOING_WITHOUT_END' | 'EXTENSION_WITHIN_90_DAYS' | 'EXTENSION' | 'NEGOTIATION'>} [statuses] Filter by a list of statuses.
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum lettable area
     * @param {number} [maxArea] Filter by maximum lettable area
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [latestGeneration] Filter contracts with the highest generation for its unit
     * @param {string} [date] Filter by contracts that are ongoing on this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceExportExcelContractsUrl: async (
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      propertyId?: number,
      propertyAssigneeId?: number,
      isVacant?: boolean,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      minStatusDate?: string,
      maxStatusDate?: string,
      statuses?: Array<
        | 'FUTURE_MOVE_OUT_WITHOUT_DATE'
        | 'VACANT_WITHOUT_MOVE_OUT'
        | 'VACANT_WITH_MOVE_OUT'
        | 'CHANGE_OF_TERMS'
        | 'TENANT_CHANGE'
        | 'FUTURE_VACANT_WITH_MOVE_OUT'
        | 'MOVED_OUT'
        | 'ONGOING_WITHOUT_END'
        | 'EXTENSION_WITHIN_90_DAYS'
        | 'EXTENSION'
        | 'NEGOTIATION'
      >,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      municipalities?: Array<string>,
      latestGeneration?: boolean,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/contracts/xlsx/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (unitId !== undefined) {
        localVarQueryParameter['unitId'] = unitId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (tenantId !== undefined) {
        localVarQueryParameter['tenantId'] = tenantId;
      }

      if (isOngoing !== undefined) {
        localVarQueryParameter['isOngoing'] = isOngoing;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (propertyAssigneeId !== undefined) {
        localVarQueryParameter['propertyAssigneeId'] = propertyAssigneeId;
      }

      if (isVacant !== undefined) {
        localVarQueryParameter['isVacant'] = isVacant;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (minStatusDate !== undefined) {
        localVarQueryParameter['minStatusDate'] =
          (minStatusDate as any) instanceof Date ? (minStatusDate as any).toISOString().substr(0, 10) : minStatusDate;
      }

      if (maxStatusDate !== undefined) {
        localVarQueryParameter['maxStatusDate'] =
          (maxStatusDate as any) instanceof Date ? (maxStatusDate as any).toISOString().substr(0, 10) : maxStatusDate;
      }

      if (statuses) {
        localVarQueryParameter['statuses'] = statuses;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (latestGeneration !== undefined) {
        localVarQueryParameter['latestGeneration'] = latestGeneration;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List contracts
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [propertyAssigneeId] Filter by property assignee
     * @param {boolean} [isVacant] Filter by vacant contracts
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {string} [minStatusDate] Filter by status min date.
     * @param {string} [maxStatusDate] Filter by status max date.
     * @param {Array<'FUTURE_MOVE_OUT_WITHOUT_DATE' | 'VACANT_WITHOUT_MOVE_OUT' | 'VACANT_WITH_MOVE_OUT' | 'CHANGE_OF_TERMS' | 'TENANT_CHANGE' | 'FUTURE_VACANT_WITH_MOVE_OUT' | 'MOVED_OUT' | 'ONGOING_WITHOUT_END' | 'EXTENSION_WITHIN_90_DAYS' | 'EXTENSION' | 'NEGOTIATION'>} [statuses] Filter by a list of statuses.
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum lettable area
     * @param {number} [maxArea] Filter by maximum lettable area
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [latestGeneration] Filter contracts with the highest generation for its unit
     * @param {string} [date] Filter by contracts that are ongoing on this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceList: async (
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      propertyId?: number,
      propertyAssigneeId?: number,
      isVacant?: boolean,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      minStatusDate?: string,
      maxStatusDate?: string,
      statuses?: Array<
        | 'FUTURE_MOVE_OUT_WITHOUT_DATE'
        | 'VACANT_WITHOUT_MOVE_OUT'
        | 'VACANT_WITH_MOVE_OUT'
        | 'CHANGE_OF_TERMS'
        | 'TENANT_CHANGE'
        | 'FUTURE_VACANT_WITH_MOVE_OUT'
        | 'MOVED_OUT'
        | 'ONGOING_WITHOUT_END'
        | 'EXTENSION_WITHIN_90_DAYS'
        | 'EXTENSION'
        | 'NEGOTIATION'
      >,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      municipalities?: Array<string>,
      latestGeneration?: boolean,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/contracts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (unitId !== undefined) {
        localVarQueryParameter['unitId'] = unitId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (tenantId !== undefined) {
        localVarQueryParameter['tenantId'] = tenantId;
      }

      if (isOngoing !== undefined) {
        localVarQueryParameter['isOngoing'] = isOngoing;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (propertyAssigneeId !== undefined) {
        localVarQueryParameter['propertyAssigneeId'] = propertyAssigneeId;
      }

      if (isVacant !== undefined) {
        localVarQueryParameter['isVacant'] = isVacant;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (minStatusDate !== undefined) {
        localVarQueryParameter['minStatusDate'] =
          (minStatusDate as any) instanceof Date ? (minStatusDate as any).toISOString().substr(0, 10) : minStatusDate;
      }

      if (maxStatusDate !== undefined) {
        localVarQueryParameter['maxStatusDate'] =
          (maxStatusDate as any) instanceof Date ? (maxStatusDate as any).toISOString().substr(0, 10) : maxStatusDate;
      }

      if (statuses) {
        localVarQueryParameter['statuses'] = statuses;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (latestGeneration !== undefined) {
        localVarQueryParameter['latestGeneration'] = latestGeneration;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a contract
     * @param {number} id Contract ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('contractResourceRead', 'id', id);
      const localVarPath = `/v2/contracts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List contracts
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceV1List: async (
      limit?: number,
      offset?: number,
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/contracts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (unitId !== undefined) {
        localVarQueryParameter['unitId'] = unitId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (tenantId !== undefined) {
        localVarQueryParameter['tenantId'] = tenantId;
      }

      if (isOngoing !== undefined) {
        localVarQueryParameter['isOngoing'] = isOngoing;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a contract
     * @param {number} id Contract ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceV1Read: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('contractResourceV1Read', 'id', id);
      const localVarPath = `/v1/contracts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List payments associated with a contract
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [contractId] Filter by contract
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [assigneeId] Filter by assignee
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractPaymentResourceList(
      limit?: number,
      offset?: number,
      contractId?: number,
      organisationId?: number,
      tenantId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractPaymentListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractPaymentResourceList(
        limit,
        offset,
        contractId,
        organisationId,
        tenantId,
        portfolioId,
        propertyId,
        assigneeId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List payments associated with a contract
     * @param {number} id Contract payment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractPaymentResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractPaymentReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractPaymentResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Export contracts to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractResourceExportContracts(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractResourceExportContracts(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a token to export contracts
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [propertyAssigneeId] Filter by property assignee
     * @param {boolean} [isVacant] Filter by vacant contracts
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {string} [minStatusDate] Filter by status min date.
     * @param {string} [maxStatusDate] Filter by status max date.
     * @param {Array<'FUTURE_MOVE_OUT_WITHOUT_DATE' | 'VACANT_WITHOUT_MOVE_OUT' | 'VACANT_WITH_MOVE_OUT' | 'CHANGE_OF_TERMS' | 'TENANT_CHANGE' | 'FUTURE_VACANT_WITH_MOVE_OUT' | 'MOVED_OUT' | 'ONGOING_WITHOUT_END' | 'EXTENSION_WITHIN_90_DAYS' | 'EXTENSION' | 'NEGOTIATION'>} [statuses] Filter by a list of statuses.
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum lettable area
     * @param {number} [maxArea] Filter by maximum lettable area
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [latestGeneration] Filter contracts with the highest generation for its unit
     * @param {string} [date] Filter by contracts that are ongoing on this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractResourceExportExcelContractsUrl(
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      propertyId?: number,
      propertyAssigneeId?: number,
      isVacant?: boolean,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      minStatusDate?: string,
      maxStatusDate?: string,
      statuses?: Array<
        | 'FUTURE_MOVE_OUT_WITHOUT_DATE'
        | 'VACANT_WITHOUT_MOVE_OUT'
        | 'VACANT_WITH_MOVE_OUT'
        | 'CHANGE_OF_TERMS'
        | 'TENANT_CHANGE'
        | 'FUTURE_VACANT_WITH_MOVE_OUT'
        | 'MOVED_OUT'
        | 'ONGOING_WITHOUT_END'
        | 'EXTENSION_WITHIN_90_DAYS'
        | 'EXTENSION'
        | 'NEGOTIATION'
      >,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      municipalities?: Array<string>,
      latestGeneration?: boolean,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractResourceExportExcelContractsUrl(
        unitId,
        organisationId,
        tenantId,
        isOngoing,
        portfolioId,
        propertyId,
        propertyAssigneeId,
        isVacant,
        sortBy,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        minStatusDate,
        maxStatusDate,
        statuses,
        minBaseRentIndexed,
        maxBaseRentIndexed,
        minTotalRentIndexed,
        maxTotalRentIndexed,
        minBaseRentPerSQMIndexed,
        maxBaseRentPerSQMIndexed,
        minTotalRentPerSQMIndexed,
        maxTotalRentPerSQMIndexed,
        minArea,
        maxArea,
        municipalities,
        latestGeneration,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List contracts
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [propertyAssigneeId] Filter by property assignee
     * @param {boolean} [isVacant] Filter by vacant contracts
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {string} [minStatusDate] Filter by status min date.
     * @param {string} [maxStatusDate] Filter by status max date.
     * @param {Array<'FUTURE_MOVE_OUT_WITHOUT_DATE' | 'VACANT_WITHOUT_MOVE_OUT' | 'VACANT_WITH_MOVE_OUT' | 'CHANGE_OF_TERMS' | 'TENANT_CHANGE' | 'FUTURE_VACANT_WITH_MOVE_OUT' | 'MOVED_OUT' | 'ONGOING_WITHOUT_END' | 'EXTENSION_WITHIN_90_DAYS' | 'EXTENSION' | 'NEGOTIATION'>} [statuses] Filter by a list of statuses.
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum lettable area
     * @param {number} [maxArea] Filter by maximum lettable area
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [latestGeneration] Filter contracts with the highest generation for its unit
     * @param {string} [date] Filter by contracts that are ongoing on this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractResourceList(
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      propertyId?: number,
      propertyAssigneeId?: number,
      isVacant?: boolean,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      minStatusDate?: string,
      maxStatusDate?: string,
      statuses?: Array<
        | 'FUTURE_MOVE_OUT_WITHOUT_DATE'
        | 'VACANT_WITHOUT_MOVE_OUT'
        | 'VACANT_WITH_MOVE_OUT'
        | 'CHANGE_OF_TERMS'
        | 'TENANT_CHANGE'
        | 'FUTURE_VACANT_WITH_MOVE_OUT'
        | 'MOVED_OUT'
        | 'ONGOING_WITHOUT_END'
        | 'EXTENSION_WITHIN_90_DAYS'
        | 'EXTENSION'
        | 'NEGOTIATION'
      >,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      municipalities?: Array<string>,
      latestGeneration?: boolean,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractResourceList(
        unitId,
        organisationId,
        tenantId,
        isOngoing,
        portfolioId,
        propertyId,
        propertyAssigneeId,
        isVacant,
        sortBy,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        minStatusDate,
        maxStatusDate,
        statuses,
        minBaseRentIndexed,
        maxBaseRentIndexed,
        minTotalRentIndexed,
        maxTotalRentIndexed,
        minBaseRentPerSQMIndexed,
        maxBaseRentPerSQMIndexed,
        minTotalRentPerSQMIndexed,
        maxTotalRentPerSQMIndexed,
        minArea,
        maxArea,
        municipalities,
        latestGeneration,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a contract
     * @param {number} id Contract ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractListItemView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List contracts
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractResourceV1List(
      limit?: number,
      offset?: number,
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractListViewV1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractResourceV1List(
        limit,
        offset,
        unitId,
        organisationId,
        tenantId,
        isOngoing,
        portfolioId,
        sortBy,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a contract
     * @param {number} id Contract ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractResourceV1Read(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractReadViewV1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractResourceV1Read(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ContractApiFp(configuration);
  return {
    /**
     *
     * @summary List payments associated with a contract
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [contractId] Filter by contract
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [assigneeId] Filter by assignee
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractPaymentResourceList(
      limit?: number,
      offset?: number,
      contractId?: number,
      organisationId?: number,
      tenantId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<ContractPaymentListView> {
      return localVarFp
        .contractPaymentResourceList(
          limit,
          offset,
          contractId,
          organisationId,
          tenantId,
          portfolioId,
          propertyId,
          assigneeId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List payments associated with a contract
     * @param {number} id Contract payment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractPaymentResourceRead(id: number, options?: any): AxiosPromise<ContractPaymentReadView> {
      return localVarFp.contractPaymentResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export contracts to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceExportContracts(token: string, options?: any): AxiosPromise<void> {
      return localVarFp.contractResourceExportContracts(token, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a token to export contracts
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [propertyAssigneeId] Filter by property assignee
     * @param {boolean} [isVacant] Filter by vacant contracts
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {string} [minStatusDate] Filter by status min date.
     * @param {string} [maxStatusDate] Filter by status max date.
     * @param {Array<'FUTURE_MOVE_OUT_WITHOUT_DATE' | 'VACANT_WITHOUT_MOVE_OUT' | 'VACANT_WITH_MOVE_OUT' | 'CHANGE_OF_TERMS' | 'TENANT_CHANGE' | 'FUTURE_VACANT_WITH_MOVE_OUT' | 'MOVED_OUT' | 'ONGOING_WITHOUT_END' | 'EXTENSION_WITHIN_90_DAYS' | 'EXTENSION' | 'NEGOTIATION'>} [statuses] Filter by a list of statuses.
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum lettable area
     * @param {number} [maxArea] Filter by maximum lettable area
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [latestGeneration] Filter contracts with the highest generation for its unit
     * @param {string} [date] Filter by contracts that are ongoing on this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceExportExcelContractsUrl(
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      propertyId?: number,
      propertyAssigneeId?: number,
      isVacant?: boolean,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      minStatusDate?: string,
      maxStatusDate?: string,
      statuses?: Array<
        | 'FUTURE_MOVE_OUT_WITHOUT_DATE'
        | 'VACANT_WITHOUT_MOVE_OUT'
        | 'VACANT_WITH_MOVE_OUT'
        | 'CHANGE_OF_TERMS'
        | 'TENANT_CHANGE'
        | 'FUTURE_VACANT_WITH_MOVE_OUT'
        | 'MOVED_OUT'
        | 'ONGOING_WITHOUT_END'
        | 'EXTENSION_WITHIN_90_DAYS'
        | 'EXTENSION'
        | 'NEGOTIATION'
      >,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      municipalities?: Array<string>,
      latestGeneration?: boolean,
      date?: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .contractResourceExportExcelContractsUrl(
          unitId,
          organisationId,
          tenantId,
          isOngoing,
          portfolioId,
          propertyId,
          propertyAssigneeId,
          isVacant,
          sortBy,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          minStatusDate,
          maxStatusDate,
          statuses,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          municipalities,
          latestGeneration,
          date,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List contracts
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [propertyId] Filter by property
     * @param {number} [propertyAssigneeId] Filter by property assignee
     * @param {boolean} [isVacant] Filter by vacant contracts
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {string} [minStatusDate] Filter by status min date.
     * @param {string} [maxStatusDate] Filter by status max date.
     * @param {Array<'FUTURE_MOVE_OUT_WITHOUT_DATE' | 'VACANT_WITHOUT_MOVE_OUT' | 'VACANT_WITH_MOVE_OUT' | 'CHANGE_OF_TERMS' | 'TENANT_CHANGE' | 'FUTURE_VACANT_WITH_MOVE_OUT' | 'MOVED_OUT' | 'ONGOING_WITHOUT_END' | 'EXTENSION_WITHIN_90_DAYS' | 'EXTENSION' | 'NEGOTIATION'>} [statuses] Filter by a list of statuses.
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum lettable area
     * @param {number} [maxArea] Filter by maximum lettable area
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [latestGeneration] Filter contracts with the highest generation for its unit
     * @param {string} [date] Filter by contracts that are ongoing on this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceList(
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      propertyId?: number,
      propertyAssigneeId?: number,
      isVacant?: boolean,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      minStatusDate?: string,
      maxStatusDate?: string,
      statuses?: Array<
        | 'FUTURE_MOVE_OUT_WITHOUT_DATE'
        | 'VACANT_WITHOUT_MOVE_OUT'
        | 'VACANT_WITH_MOVE_OUT'
        | 'CHANGE_OF_TERMS'
        | 'TENANT_CHANGE'
        | 'FUTURE_VACANT_WITH_MOVE_OUT'
        | 'MOVED_OUT'
        | 'ONGOING_WITHOUT_END'
        | 'EXTENSION_WITHIN_90_DAYS'
        | 'EXTENSION'
        | 'NEGOTIATION'
      >,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      municipalities?: Array<string>,
      latestGeneration?: boolean,
      date?: string,
      options?: any
    ): AxiosPromise<ContractListView> {
      return localVarFp
        .contractResourceList(
          unitId,
          organisationId,
          tenantId,
          isOngoing,
          portfolioId,
          propertyId,
          propertyAssigneeId,
          isVacant,
          sortBy,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          minStatusDate,
          maxStatusDate,
          statuses,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          municipalities,
          latestGeneration,
          date,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a contract
     * @param {number} id Contract ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceRead(id: number, options?: any): AxiosPromise<ContractListItemView> {
      return localVarFp.contractResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List contracts
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [unitId] Filter by unit
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [tenantId] Filter by tenant
     * @param {boolean} [isOngoing] Filter by ongoing status
     * @param {number} [portfolioId] Filter by portfolio
     * @param {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'} [sortBy] Sort by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceV1List(
      limit?: number,
      offset?: number,
      unitId?: number,
      organisationId?: number,
      tenantId?: number,
      isOngoing?: boolean,
      portfolioId?: number,
      sortBy?:
        | 'property_name_asc'
        | 'property_name_desc'
        | 'tenant_name_asc'
        | 'tenant_name_desc'
        | 'start_date_asc'
        | 'start_date_desc'
        | 'status_asc'
        | 'status_desc'
        | 'lettable_area_asc'
        | 'lettable_area_desc'
        | 'base_rent_indexed_asc'
        | 'base_rent_indexed_desc'
        | 'total_rent_indexed_asc'
        | 'total_rent_indexed_desc'
        | 'additions_indexed_asc'
        | 'additions_indexed_desc'
        | 'termination_period_asc'
        | 'termination_period_desc'
        | 'extension_period_asc'
        | 'extension_period_desc'
        | 'reductions_indexed_asc'
        | 'reductions_indexed_desc',
      options?: any
    ): AxiosPromise<ContractListViewV1> {
      return localVarFp
        .contractResourceV1List(
          limit,
          offset,
          unitId,
          organisationId,
          tenantId,
          isOngoing,
          portfolioId,
          sortBy,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a contract
     * @param {number} id Contract ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractResourceV1Read(id: number, options?: any): AxiosPromise<ContractReadViewV1> {
      return localVarFp.contractResourceV1Read(id, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for contractPaymentResourceList operation in ContractApi.
 * @export
 * @interface ContractApiContractPaymentResourceListRequest
 */
export interface ContractApiContractPaymentResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly offset?: number;

  /**
   * Filter by contract
   * @type {number}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly contractId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by tenant
   * @type {number}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly tenantId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly assigneeId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof ContractApiContractPaymentResourceList
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for contractPaymentResourceRead operation in ContractApi.
 * @export
 * @interface ContractApiContractPaymentResourceReadRequest
 */
export interface ContractApiContractPaymentResourceReadRequest {
  /**
   * Contract payment ID
   * @type {number}
   * @memberof ContractApiContractPaymentResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for contractResourceExportContracts operation in ContractApi.
 * @export
 * @interface ContractApiContractResourceExportContractsRequest
 */
export interface ContractApiContractResourceExportContractsRequest {
  /**
   *
   * @type {string}
   * @memberof ContractApiContractResourceExportContracts
   */
  readonly token: string;
}

/**
 * Request parameters for contractResourceExportExcelContractsUrl operation in ContractApi.
 * @export
 * @interface ContractApiContractResourceExportExcelContractsUrlRequest
 */
export interface ContractApiContractResourceExportExcelContractsUrlRequest {
  /**
   * Filter by unit
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly unitId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly organisationId?: number;

  /**
   * Filter by tenant
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly tenantId?: number;

  /**
   * Filter by ongoing status
   * @type {boolean}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly isOngoing?: boolean;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly portfolioId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly propertyId?: number;

  /**
   * Filter by property assignee
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly propertyAssigneeId?: number;

  /**
   * Filter by vacant contracts
   * @type {boolean}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly isVacant?: boolean;

  /**
   * Sort by
   * @type {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly sortBy?:
    | 'property_name_asc'
    | 'property_name_desc'
    | 'tenant_name_asc'
    | 'tenant_name_desc'
    | 'start_date_asc'
    | 'start_date_desc'
    | 'status_asc'
    | 'status_desc'
    | 'lettable_area_asc'
    | 'lettable_area_desc'
    | 'base_rent_indexed_asc'
    | 'base_rent_indexed_desc'
    | 'total_rent_indexed_asc'
    | 'total_rent_indexed_desc'
    | 'additions_indexed_asc'
    | 'additions_indexed_desc'
    | 'termination_period_asc'
    | 'termination_period_desc'
    | 'extension_period_asc'
    | 'extension_period_desc'
    | 'reductions_indexed_asc'
    | 'reductions_indexed_desc';

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by status min date.
   * @type {string}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly minStatusDate?: string;

  /**
   * Filter by status max date.
   * @type {string}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly maxStatusDate?: string;

  /**
   * Filter by a list of statuses.
   * @type {Array<'FUTURE_MOVE_OUT_WITHOUT_DATE' | 'VACANT_WITHOUT_MOVE_OUT' | 'VACANT_WITH_MOVE_OUT' | 'CHANGE_OF_TERMS' | 'TENANT_CHANGE' | 'FUTURE_VACANT_WITH_MOVE_OUT' | 'MOVED_OUT' | 'ONGOING_WITHOUT_END' | 'EXTENSION_WITHIN_90_DAYS' | 'EXTENSION' | 'NEGOTIATION'>}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly statuses?: Array<
    | 'FUTURE_MOVE_OUT_WITHOUT_DATE'
    | 'VACANT_WITHOUT_MOVE_OUT'
    | 'VACANT_WITH_MOVE_OUT'
    | 'CHANGE_OF_TERMS'
    | 'TENANT_CHANGE'
    | 'FUTURE_VACANT_WITH_MOVE_OUT'
    | 'MOVED_OUT'
    | 'ONGOING_WITHOUT_END'
    | 'EXTENSION_WITHIN_90_DAYS'
    | 'EXTENSION'
    | 'NEGOTIATION'
  >;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum lettable area
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly minArea?: number;

  /**
   * Filter by maximum lettable area
   * @type {number}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly maxArea?: number;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter contracts with the highest generation for its unit
   * @type {boolean}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly latestGeneration?: boolean;

  /**
   * Filter by contracts that are ongoing on this date
   * @type {string}
   * @memberof ContractApiContractResourceExportExcelContractsUrl
   */
  readonly date?: string;
}

/**
 * Request parameters for contractResourceList operation in ContractApi.
 * @export
 * @interface ContractApiContractResourceListRequest
 */
export interface ContractApiContractResourceListRequest {
  /**
   * Filter by unit
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly unitId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by tenant
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly tenantId?: number;

  /**
   * Filter by ongoing status
   * @type {boolean}
   * @memberof ContractApiContractResourceList
   */
  readonly isOngoing?: boolean;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by property assignee
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly propertyAssigneeId?: number;

  /**
   * Filter by vacant contracts
   * @type {boolean}
   * @memberof ContractApiContractResourceList
   */
  readonly isVacant?: boolean;

  /**
   * Sort by
   * @type {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'}
   * @memberof ContractApiContractResourceList
   */
  readonly sortBy?:
    | 'property_name_asc'
    | 'property_name_desc'
    | 'tenant_name_asc'
    | 'tenant_name_desc'
    | 'start_date_asc'
    | 'start_date_desc'
    | 'status_asc'
    | 'status_desc'
    | 'lettable_area_asc'
    | 'lettable_area_desc'
    | 'base_rent_indexed_asc'
    | 'base_rent_indexed_desc'
    | 'total_rent_indexed_asc'
    | 'total_rent_indexed_desc'
    | 'additions_indexed_asc'
    | 'additions_indexed_desc'
    | 'termination_period_asc'
    | 'termination_period_desc'
    | 'extension_period_asc'
    | 'extension_period_desc'
    | 'reductions_indexed_asc'
    | 'reductions_indexed_desc';

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ContractApiContractResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ContractApiContractResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ContractApiContractResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by status min date.
   * @type {string}
   * @memberof ContractApiContractResourceList
   */
  readonly minStatusDate?: string;

  /**
   * Filter by status max date.
   * @type {string}
   * @memberof ContractApiContractResourceList
   */
  readonly maxStatusDate?: string;

  /**
   * Filter by a list of statuses.
   * @type {Array<'FUTURE_MOVE_OUT_WITHOUT_DATE' | 'VACANT_WITHOUT_MOVE_OUT' | 'VACANT_WITH_MOVE_OUT' | 'CHANGE_OF_TERMS' | 'TENANT_CHANGE' | 'FUTURE_VACANT_WITH_MOVE_OUT' | 'MOVED_OUT' | 'ONGOING_WITHOUT_END' | 'EXTENSION_WITHIN_90_DAYS' | 'EXTENSION' | 'NEGOTIATION'>}
   * @memberof ContractApiContractResourceList
   */
  readonly statuses?: Array<
    | 'FUTURE_MOVE_OUT_WITHOUT_DATE'
    | 'VACANT_WITHOUT_MOVE_OUT'
    | 'VACANT_WITH_MOVE_OUT'
    | 'CHANGE_OF_TERMS'
    | 'TENANT_CHANGE'
    | 'FUTURE_VACANT_WITH_MOVE_OUT'
    | 'MOVED_OUT'
    | 'ONGOING_WITHOUT_END'
    | 'EXTENSION_WITHIN_90_DAYS'
    | 'EXTENSION'
    | 'NEGOTIATION'
  >;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum lettable area
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly minArea?: number;

  /**
   * Filter by maximum lettable area
   * @type {number}
   * @memberof ContractApiContractResourceList
   */
  readonly maxArea?: number;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof ContractApiContractResourceList
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter contracts with the highest generation for its unit
   * @type {boolean}
   * @memberof ContractApiContractResourceList
   */
  readonly latestGeneration?: boolean;

  /**
   * Filter by contracts that are ongoing on this date
   * @type {string}
   * @memberof ContractApiContractResourceList
   */
  readonly date?: string;
}

/**
 * Request parameters for contractResourceRead operation in ContractApi.
 * @export
 * @interface ContractApiContractResourceReadRequest
 */
export interface ContractApiContractResourceReadRequest {
  /**
   * Contract ID
   * @type {number}
   * @memberof ContractApiContractResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for contractResourceV1List operation in ContractApi.
 * @export
 * @interface ContractApiContractResourceV1ListRequest
 */
export interface ContractApiContractResourceV1ListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof ContractApiContractResourceV1List
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof ContractApiContractResourceV1List
   */
  readonly offset?: number;

  /**
   * Filter by unit
   * @type {number}
   * @memberof ContractApiContractResourceV1List
   */
  readonly unitId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ContractApiContractResourceV1List
   */
  readonly organisationId?: number;

  /**
   * Filter by tenant
   * @type {number}
   * @memberof ContractApiContractResourceV1List
   */
  readonly tenantId?: number;

  /**
   * Filter by ongoing status
   * @type {boolean}
   * @memberof ContractApiContractResourceV1List
   */
  readonly isOngoing?: boolean;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof ContractApiContractResourceV1List
   */
  readonly portfolioId?: number;

  /**
   * Sort by
   * @type {'property_name_asc' | 'property_name_desc' | 'tenant_name_asc' | 'tenant_name_desc' | 'start_date_asc' | 'start_date_desc' | 'status_asc' | 'status_desc' | 'lettable_area_asc' | 'lettable_area_desc' | 'base_rent_indexed_asc' | 'base_rent_indexed_desc' | 'total_rent_indexed_asc' | 'total_rent_indexed_desc' | 'additions_indexed_asc' | 'additions_indexed_desc' | 'termination_period_asc' | 'termination_period_desc' | 'extension_period_asc' | 'extension_period_desc' | 'reductions_indexed_asc' | 'reductions_indexed_desc'}
   * @memberof ContractApiContractResourceV1List
   */
  readonly sortBy?:
    | 'property_name_asc'
    | 'property_name_desc'
    | 'tenant_name_asc'
    | 'tenant_name_desc'
    | 'start_date_asc'
    | 'start_date_desc'
    | 'status_asc'
    | 'status_desc'
    | 'lettable_area_asc'
    | 'lettable_area_desc'
    | 'base_rent_indexed_asc'
    | 'base_rent_indexed_desc'
    | 'total_rent_indexed_asc'
    | 'total_rent_indexed_desc'
    | 'additions_indexed_asc'
    | 'additions_indexed_desc'
    | 'termination_period_asc'
    | 'termination_period_desc'
    | 'extension_period_asc'
    | 'extension_period_desc'
    | 'reductions_indexed_asc'
    | 'reductions_indexed_desc';
}

/**
 * Request parameters for contractResourceV1Read operation in ContractApi.
 * @export
 * @interface ContractApiContractResourceV1ReadRequest
 */
export interface ContractApiContractResourceV1ReadRequest {
  /**
   * Contract ID
   * @type {number}
   * @memberof ContractApiContractResourceV1Read
   */
  readonly id: number;
}

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
  /**
   *
   * @summary List payments associated with a contract
   * @param {ContractApiContractPaymentResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public contractPaymentResourceList(
    requestParameters: ContractApiContractPaymentResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ContractApiFp(this.configuration)
      .contractPaymentResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.contractId,
        requestParameters.organisationId,
        requestParameters.tenantId,
        requestParameters.portfolioId,
        requestParameters.propertyId,
        requestParameters.assigneeId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List payments associated with a contract
   * @param {ContractApiContractPaymentResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public contractPaymentResourceRead(
    requestParameters: ContractApiContractPaymentResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return ContractApiFp(this.configuration)
      .contractPaymentResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export contracts to Excel file
   * @param {ContractApiContractResourceExportContractsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public contractResourceExportContracts(
    requestParameters: ContractApiContractResourceExportContractsRequest,
    options?: AxiosRequestConfig
  ) {
    return ContractApiFp(this.configuration)
      .contractResourceExportContracts(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a token to export contracts
   * @param {ContractApiContractResourceExportExcelContractsUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public contractResourceExportExcelContractsUrl(
    requestParameters: ContractApiContractResourceExportExcelContractsUrlRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ContractApiFp(this.configuration)
      .contractResourceExportExcelContractsUrl(
        requestParameters.unitId,
        requestParameters.organisationId,
        requestParameters.tenantId,
        requestParameters.isOngoing,
        requestParameters.portfolioId,
        requestParameters.propertyId,
        requestParameters.propertyAssigneeId,
        requestParameters.isVacant,
        requestParameters.sortBy,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.minStatusDate,
        requestParameters.maxStatusDate,
        requestParameters.statuses,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.municipalities,
        requestParameters.latestGeneration,
        requestParameters.date,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List contracts
   * @param {ContractApiContractResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public contractResourceList(
    requestParameters: ContractApiContractResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ContractApiFp(this.configuration)
      .contractResourceList(
        requestParameters.unitId,
        requestParameters.organisationId,
        requestParameters.tenantId,
        requestParameters.isOngoing,
        requestParameters.portfolioId,
        requestParameters.propertyId,
        requestParameters.propertyAssigneeId,
        requestParameters.isVacant,
        requestParameters.sortBy,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.minStatusDate,
        requestParameters.maxStatusDate,
        requestParameters.statuses,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.municipalities,
        requestParameters.latestGeneration,
        requestParameters.date,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a contract
   * @param {ContractApiContractResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public contractResourceRead(requestParameters: ContractApiContractResourceReadRequest, options?: AxiosRequestConfig) {
    return ContractApiFp(this.configuration)
      .contractResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List contracts
   * @param {ContractApiContractResourceV1ListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public contractResourceV1List(
    requestParameters: ContractApiContractResourceV1ListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ContractApiFp(this.configuration)
      .contractResourceV1List(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.unitId,
        requestParameters.organisationId,
        requestParameters.tenantId,
        requestParameters.isOngoing,
        requestParameters.portfolioId,
        requestParameters.sortBy,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a contract
   * @param {ContractApiContractResourceV1ReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public contractResourceV1Read(
    requestParameters: ContractApiContractResourceV1ReadRequest,
    options?: AxiosRequestConfig
  ) {
    return ContractApiFp(this.configuration)
      .contractResourceV1Read(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
