import { ComparablePropertyReadViewCountryEnum } from '../GeneratedServices';
import { toArray } from '../Utils/array';

export interface MunicipalityPolygon {
  outerBoundaries: google.maps.LatLngLiteral[][];
  innerBoundaries: google.maps.LatLngLiteral[][];
}

let swedishMunicipalities = new Map<string, MunicipalityPolygon>();
let danishMunicipalities = new Map<string, MunicipalityPolygon>();
let finnishMunicipalities = new Map<string, MunicipalityPolygon>();
let spanishMunicipalities = new Map<string, MunicipalityPolygon>();
let unitedKingdomMunicipalities = new Map<string, MunicipalityPolygon>();

export const getMunicipalityBounds = (
  country: ComparablePropertyReadViewCountryEnum,
  municipalityName: string
): MunicipalityPolygon => {
  const fallbackBounds = { outerBoundaries: [], innerBoundaries: [] };

  switch (country) {
    case ComparablePropertyReadViewCountryEnum.Sweden:
      return swedishMunicipalities.get(municipalityName) ?? fallbackBounds;
    case ComparablePropertyReadViewCountryEnum.Denmark:
      return danishMunicipalities.get(municipalityName) ?? fallbackBounds;
    case ComparablePropertyReadViewCountryEnum.Finland:
      return finnishMunicipalities.get(municipalityName) ?? fallbackBounds;
    case ComparablePropertyReadViewCountryEnum.Spain:
      return spanishMunicipalities.get(municipalityName) ?? fallbackBounds;
    case ComparablePropertyReadViewCountryEnum.UnitedKingdom:
      return unitedKingdomMunicipalities.get(municipalityName) ?? fallbackBounds;
  }
};

export const getAllMunicipalities = (country: ComparablePropertyReadViewCountryEnum) => {
  let map: Map<string, MunicipalityPolygon>;
  switch (country) {
    case ComparablePropertyReadViewCountryEnum.Sweden:
      map = swedishMunicipalities;
      break;
    case ComparablePropertyReadViewCountryEnum.Denmark:
      map = danishMunicipalities;
      break;
    case ComparablePropertyReadViewCountryEnum.Finland:
      map = finnishMunicipalities;
      break;
    case ComparablePropertyReadViewCountryEnum.Spain:
      map = spanishMunicipalities;
      break;
    case ComparablePropertyReadViewCountryEnum.UnitedKingdom:
      map = unitedKingdomMunicipalities;
      break;
  }
  return toArray(map.keys());
};

const parseKmlFile = async (
  country: ComparablePropertyReadViewCountryEnum,
  onParse: (map: Map<string, MunicipalityPolygon>) => void,
  municipalityNameParser: (element: Element) => string | null,
  coordinatesSeparator: ' ' | '\n' = ' '
) => {
  try {
    const resp = await fetch(`/${country.toLowerCase()}.kml`);

    let fileReader = new FileReader();
    fileReader.onload = async (e: any) => {
      onParse(await extractGoogleCoords(e.target.result, municipalityNameParser, coordinatesSeparator));
    };
    fileReader.readAsText(new Blob([await resp.blob()]));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error parsing kml file ', error);
  }
};

async function extractGoogleCoords(
  plainText: string,
  municipalityNameParser: (element: Element) => string | null,
  coordinatesSeparator: ' ' | '\n'
) {
  let parser = new DOMParser();
  let xmlDoc = parser.parseFromString(plainText, 'text/xml');
  let polygonsMap = new Map<string, MunicipalityPolygon>();

  if (xmlDoc.documentElement.nodeName === 'kml') {
    for (const item of xmlDoc.getElementsByTagName('Placemark')) {
      const municipality = municipalityNameParser(item);
      if (!municipality) {
        continue;
      }
      let outerBoundaries = item.getElementsByTagName('outerBoundaryIs');
      let innerBoundaries = item.getElementsByTagName('innerBoundaryIs');

      let outerBounds: google.maps.LatLngLiteral[][] = [];
      let innerBounds: google.maps.LatLngLiteral[][] = [];

      /** POLYGONS PARSE **/
      for (const boundary of outerBoundaries) {
        let coords = boundary.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue?.trim();
        let points = coords?.split(coordinatesSeparator);
        let outerPaths = [];
        if (points)
          for (const point of points) {
            let coord = point.split(',');
            outerPaths.push({ lat: +coord[1], lng: +coord[0] });
          }
        outerBounds.push(outerPaths);
      }

      for (const boundary of innerBoundaries) {
        let coords = boundary.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue?.trim();
        let points = coords?.split(coordinatesSeparator);
        let innerPaths = [];
        if (points)
          for (const point of points) {
            let coord = point.split(',');
            innerPaths.push({ lat: +coord[1], lng: +coord[0] });
          }
        // Note that the points forming the inner path are wound in the
        // opposite direction to those in the outer path, to form the hole.
        innerBounds.push(innerPaths.slice().reverse());
      }

      if (polygonsMap.has(municipality)) {
        const oldData = polygonsMap.get(municipality);
        polygonsMap.set(municipality, {
          outerBoundaries: [...(oldData?.outerBoundaries ?? []), ...outerBounds],
          innerBoundaries: [...(oldData?.innerBoundaries ?? []), ...innerBounds]
        });
      } else polygonsMap.set(municipality, { outerBoundaries: outerBounds, innerBoundaries: innerBounds });
    }
  } else {
    throw Error('Could not find kml node.');
  }

  return polygonsMap;
}

parseKmlFile(
  ComparablePropertyReadViewCountryEnum.Sweden,
  (map) => (swedishMunicipalities = map),
  (item) => item.querySelectorAll('[name="KOMMUNNAMN"]')[0].textContent
);

parseKmlFile(
  ComparablePropertyReadViewCountryEnum.Finland,
  (map) => (finnishMunicipalities = map),
  (item) => item.querySelectorAll('[name="name"]')[0].textContent
);

parseKmlFile(
  ComparablePropertyReadViewCountryEnum.Denmark,
  (map) => (danishMunicipalities = map),
  (item) => item.querySelectorAll('[name="label_en"]')[0].textContent
);

const detailedSpanishProvinces = {
  Barcelona: '08',
  Madrid: '28'
};
const detailedSpanishProvincesIds = Object.values(detailedSpanishProvinces);

parseKmlFile(
  ComparablePropertyReadViewCountryEnum.Spain,
  (map) => (spanishMunicipalities = map),
  (item) => {
    const municipalityId = item.getAttribute('id');
    const type = item.getAttribute('type');

    if (
      !municipalityId ||
      type === 'autonomous_community' ||
      (municipalityId.length === 2 && detailedSpanishProvincesIds.includes(municipalityId)) ||
      (municipalityId.length === 5 && detailedSpanishProvincesIds.every((item) => !municipalityId.startsWith(item)))
    )
      return null;

    return item.getElementsByTagName('name')[0].textContent;
  },
  '\n'
);

// The format of this kml file is different, it returns the values with spaces and new lines for the name. We have to trim it if it's not null.
parseKmlFile(
  ComparablePropertyReadViewCountryEnum.UnitedKingdom,
  (map) => (unitedKingdomMunicipalities = map),
  (item) => {
    const countyNameElements = item.querySelectorAll('[name="county_name"]');
    if (countyNameElements.length > 0 && countyNameElements[0].textContent) {
      return countyNameElements[0].textContent.trim();
    } else {
      return null;
    }
  },
  '\n'
);
