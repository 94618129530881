import React from 'react';
import OverviewInfoBox from './OverviewInfoBox';
import { getMarkersContent } from '../../../../Components/Map/Markers/Markers';
import { ComparablePropertyForMap } from '../../../Analytics/Analytics';
import { PropertyReadView } from '../../../../Services/PropertyService.types';
import ClusterControl from './MapControls/ClusterControl';
import { useStreetView } from '../../../../Components/Map/StreetView/useStreetView';
import { ComparablePropertyReadView } from '../../../../GeneratedServices';

interface Props {
  properties: PropertyReadView[];
  map: google.maps.Map | null;
  areLabelsShown?: boolean;
  disabled?: boolean;
  focusSingleProperty?: boolean;
}

const OverviewMap: React.FC<Props> = (props) => {
  const { isInStreetView, streetViewPosition } = useStreetView({
    map: props.map
  });
  const [areMarkersClustered, setMarkersClustered] = React.useState<boolean>(true);

  const [selectedProperty, setSelectedProperty] = React.useState<PropertyReadView>();

  React.useEffect(() => {
    if (props.map && props.focusSingleProperty && props.properties?.length === 1) {
      const position: google.maps.LatLngLiteral | null = props.properties[0].position;
      if (position) props.map?.setCenter(position);
      props.map?.setZoom(16);
    } else if (props.map && props.properties) {
      // Zoom map once all property markers are loaded.
      const bounds = new google.maps.LatLngBounds();
      props.properties.forEach((item, index) => {
        if (item.position) bounds.extend(item.position);
        if (index === props.properties.length - 1) {
          props.map?.fitBounds(bounds);
        }
      });
    }
  }, [props.properties, props.map, props.focusSingleProperty]);

  const handleMarkerClick = React.useCallback(
    (item: PropertyReadView) => {
      if (!props.disabled) {
        setSelectedProperty((prevValue) => (prevValue?.id === item.id ? undefined : item));
      }
    },
    [props.disabled]
  );

  React.useEffect(() => {
    if (selectedProperty && props.map) {
      props.map?.setOptions({
        scrollwheel: false
      });
    } else {
      props.map?.setOptions({
        scrollwheel: true
      });
    }
  }, [selectedProperty, props.map]);

  const Markers = React.useMemo(() => {
    return getMarkersContent({
      properties: props.properties ?? [],
      handleMarkerClick: handleMarkerClick as (
        item: PropertyReadView | ComparablePropertyForMap | ComparablePropertyReadView
      ) => void,
      handleUnmount: () => setSelectedProperty(undefined),
      areMarkersClustered: areMarkersClustered,
      disabled: props.disabled,
      label: props.areLabelsShown,
      isInStreetView,
      streetViewPosition
    });
  }, [
    props.properties,
    areMarkersClustered,
    handleMarkerClick,
    props.disabled,
    props.areLabelsShown,
    isInStreetView,
    streetViewPosition
  ]);

  return (
    <>
      {!props.focusSingleProperty && (
        <ClusterControl
          areMarkersClustered={areMarkersClustered}
          map={props.map}
          setMarkersClustered={setMarkersClustered}
        />
      )}

      {Markers}

      {selectedProperty?.position && (
        <OverviewInfoBox selectedProperty={selectedProperty} onClose={() => setSelectedProperty(undefined)} />
      )}
    </>
  );
};

export default OverviewMap;
