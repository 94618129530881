import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues } from 'react-number-format';
import { Loader, Table } from 'semantic-ui-react';
import EditableControlCell from '../../../../Components/EditableTableCell/EditableControlCell';
import EditableTableNumberCell from '../../../../Components/EditableTableCell/EditableTableNumberCell';
import LoadError from '../../../../Components/LoadError';
import TableHeaderCellContent from '../../../../Components/TableHeaderCell/TableHeaderCell';
import { BusinessPlanIndexReadView, BusinessPlanIndexReadViewIndexTypeEnum } from '../../../../GeneratedServices';
import { useDidMountEffect, useNonce } from '../../../../Utils/hooks';
import {
  BusinessPlanIndexEntity,
  BusinessPlanIndexStatus,
  useBusinessPlanIndexesReducer
} from './hooks/useBusinessPlanIndexesReducer';

interface Props {
  propertyId: number;
  shouldReload: number;
  isPermitted?: boolean;
}

const BusinessPlanIndexTab: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);

  const [shouldIndexesReload, reloadIndexes] = useNonce();

  const [indexTypesInEditMode, setIndexTypesInEditMode] = React.useState<Set<BusinessPlanIndexReadViewIndexTypeEnum>>(
    new Set()
  );

  const {
    businessPlanIndexes,
    dispatchBusinessPlanIndexes,
    saveBusinessPlanIndexes,
    inflationIndicesStatus,
    rentalGrowthIndicesStatus
  } = useBusinessPlanIndexesReducer({
    propertyId: props.propertyId,
    shouldIndexesReload
  });

  useDidMountEffect(() => {
    reloadIndexes();
  }, [props.shouldReload]);

  const getLayout = () => {
    if (
      inflationIndicesStatus === BusinessPlanIndexStatus.LoadError ||
      rentalGrowthIndicesStatus === BusinessPlanIndexStatus.LoadError
    ) {
      return <LoadError message={t('contracts:unableToLoadIndexOfBusinessPlanMessage')} retry={reloadIndexes} />;
    }
    if (
      inflationIndicesStatus === BusinessPlanIndexStatus.Loading &&
      rentalGrowthIndicesStatus === BusinessPlanIndexStatus.Loading &&
      businessPlanIndexes.INFLATION.length === 0 &&
      businessPlanIndexes.RENTAL_GROWTH.length === 0
    ) {
      return <Loader active inline="centered" size="large" />;
    } else return generalLayout;
  };

  const isInEditMode = (type: BusinessPlanIndexReadViewIndexTypeEnum) => {
    return indexTypesInEditMode.has(type);
  };

  const onCancel = (type: BusinessPlanIndexReadViewIndexTypeEnum) => {
    setIndexTypesInEditMode((prevValue) => {
      const setCopy = new Set(prevValue);
      setCopy.delete(type);

      return setCopy;
    });
    dispatchBusinessPlanIndexes({
      type: 'VALUE_RESET',
      indexType: type
    });
  };

  const onEditModeEnter = (type: BusinessPlanIndexReadViewIndexTypeEnum) => {
    setIndexTypesInEditMode((prevValue) => {
      const setCopy = new Set(prevValue);
      setCopy.add(type);

      return setCopy;
    });
  };

  const onSave = async (type: BusinessPlanIndexReadViewIndexTypeEnum) => {
    const result = await saveBusinessPlanIndexes(type);
    if (result) {
      reloadIndexes();
      setIndexTypesInEditMode((prevValue) => {
        const setCopy = new Set(prevValue);
        setCopy.delete(type);

        return setCopy;
      });
    }
  };

  const onValueChange = (item: BusinessPlanIndexReadView, value: NumberFormatValues) => {
    dispatchBusinessPlanIndexes({
      type: 'VALUE_CHANGE',
      id: item.id,
      indexType: item.indexType,
      value: value.floatValue
    });
  };

  const disabled =
    inflationIndicesStatus === BusinessPlanIndexStatus.Saving ||
    rentalGrowthIndicesStatus === BusinessPlanIndexStatus.Saving;

  const generalLayout = (
    <div style={{ overflowX: 'auto' }}>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            {businessPlanIndexes.INFLATION.map((item) => (
              <Table.HeaderCell key={item.id}>
                <TableHeaderCellContent header={item.year.toString()} extra={t('common:percent')} />
              </Table.HeaderCell>
            ))}
            {props.isPermitted && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <IndexRow
            indexType={BusinessPlanIndexReadViewIndexTypeEnum.RentalGrowth}
            businessPlanIndexes={businessPlanIndexes.RENTAL_GROWTH}
            isInEditMode={isInEditMode}
            onCancel={onCancel}
            onEditModeEnter={onEditModeEnter}
            onSave={onSave}
            onValueChange={onValueChange}
            status={rentalGrowthIndicesStatus}
            isPermitted={props.isPermitted}
            disabled={disabled}
          />

          <IndexRow
            indexType={BusinessPlanIndexReadViewIndexTypeEnum.Inflation}
            businessPlanIndexes={businessPlanIndexes.INFLATION}
            isInEditMode={isInEditMode}
            onCancel={onCancel}
            onEditModeEnter={onEditModeEnter}
            onSave={onSave}
            onValueChange={onValueChange}
            status={inflationIndicesStatus}
            isPermitted={props.isPermitted}
            disabled={disabled}
          />
        </Table.Body>
      </Table>
    </div>
  );

  return <>{getLayout()}</>;
};

const IndexRow = (props: {
  indexType: BusinessPlanIndexReadViewIndexTypeEnum;
  businessPlanIndexes: BusinessPlanIndexEntity[];
  isInEditMode: (type: BusinessPlanIndexReadViewIndexTypeEnum) => boolean;
  onEditModeEnter: (type: BusinessPlanIndexReadViewIndexTypeEnum) => void;
  onValueChange: (item: BusinessPlanIndexReadView, value: NumberFormatValues) => void;
  onCancel: (type: BusinessPlanIndexReadViewIndexTypeEnum) => void;
  onSave: (type: BusinessPlanIndexReadViewIndexTypeEnum) => void;
  status: BusinessPlanIndexStatus;
  disabled: boolean;
  isPermitted?: boolean;
}) => {
  const {
    businessPlanIndexes,
    isInEditMode,
    onEditModeEnter,
    onValueChange,
    onCancel,
    onSave,
    status,
    isPermitted,
    indexType,
    disabled
  } = props;
  return (
    <Table.Row>
      {businessPlanIndexes.map((item) => (
        <Table.Cell key={item.id}>
          <EditableTableNumberCell
            isInEditMode={isInEditMode(indexType)}
            displayValue={item.value}
            value={item?.newValue}
            placeholder={'0%'}
            onValueChange={(v) => onValueChange(item, v)}
            validationResult={item.validationResult}
          />
        </Table.Cell>
      ))}

      {isPermitted && (
        <Table.Cell collapsing>
          <EditableControlCell
            editModeDisabled={disabled}
            isInEditMode={isInEditMode(indexType)}
            onCancel={() => onCancel(indexType)}
            onEditModeEnter={() => onEditModeEnter(indexType)}
            onSave={() => onSave(indexType)}
            savingDisabled={!businessPlanIndexes.every((item) => item.validationResult === undefined)}
            saveLoading={status === BusinessPlanIndexStatus.Loading || status === BusinessPlanIndexStatus.Saving}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default BusinessPlanIndexTab;
