import React from 'react';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './MapControlButton.module.scss';

interface Props {
  onClick?: () => void;
  icon?: IconProp;
  additionalStyles?: React.CSSProperties;
  title?: string;
  content?: React.ReactNode;
}

const MapControlButton: React.FC<Props> = (props) => {
  return (
    <Button
      style={props.additionalStyles}
      className={styles.button}
      icon={props.icon && <FontAwesomeIcon fixedWidth icon={props.icon} />}
      compact
      size="large"
      content={props.content}
      basic
      title={props.title}
      onClick={props.onClick}
    />
  );
};

export default MapControlButton;
