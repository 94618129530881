import React from 'react';
import * as SearchService from '../../../Services/SearchService';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { Link, useNavigate } from 'react-router-dom';
import { listViewTotalCount } from '../../../Services/types';
import { Button, Header, Input, Search, SearchCategoryProps, SearchResultProps } from 'semantic-ui-react';
import { SearchListView } from '../../../Services/SearchService.types';
import { SearchCategoryLayoutProps } from 'semantic-ui-react/dist/commonjs/modules/Search/SearchCategoryLayout';
import styles from './SearchBar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const LIMIT = 3;

export enum EntityType {
  Property = 'property',
  Tenant = 'tenant',
  WorkOrder = 'workorder'
}

interface SearchBarResultItem {
  title: string;
  id?: number;
  searchtype: EntityType;
}

interface SearchBarResults {
  properties: { name: string; results: SearchBarResultItem[] };
  tenants: { name: string; results: SearchBarResultItem[] };
  workOrders: { name: string; results: SearchBarResultItem[] };
}

const generateSearchBarResults = (searchResult: SearchListView): SearchBarResults => {
  const searchBarResults = {
    properties: { name: `${i18n.t('common:properties')} (${searchResult.properties.totalCount})`, results: [] },
    tenants: { name: `${i18n.t('common:tenants')} (${searchResult.tenants.totalCount})`, results: [] },
    workOrders: { name: `${i18n.t('common:faultReports')} (${searchResult.workOrders.totalCount})`, results: [] }
  } as SearchBarResults;

  searchBarResults.properties.results = searchResult.properties.records.map((p) => ({
    title: p.name,
    id: p.id,
    searchtype: EntityType.Property
  }));

  searchBarResults.tenants.results = searchResult.tenants.records.map((t) => ({
    title: t.name,
    id: t.id,
    searchtype: EntityType.Tenant
  }));

  searchBarResults.workOrders.results = searchResult.workOrders.records.map((w) => ({
    title: w.caseNumber,
    description: w.description,
    id: w.id,
    searchtype: EntityType.WorkOrder
  }));

  return searchBarResults;
};

const categoryLayoutRenderer = (props: Pick<SearchCategoryLayoutProps, 'categoryContent' | 'resultsContent'>) => {
  return (
    <div className={styles.categorySection}>
      {props.categoryContent}
      <div className={styles.resultsSection}>{props.resultsContent}</div>
    </div>
  );
};

const categoryRenderer = (searchProps: SearchCategoryProps, results?: SearchBarResults, value?: string) => {
  let key = '';
  switch (searchProps.name) {
    case results?.properties.name:
      key = 'property';
      break;
    case results?.tenants.name:
      key = 'tenant';
      break;
    case results?.workOrders.name:
      key = 'workorder';
      break;
    default:
      break;
  }
  return (
    <Header as="h5" className={styles.categoryTitle} inverted>
      {searchProps.name}
      <Link to={`/search?type=${key}&q=${value}`}>{i18n.t('common:viewAll')}</Link>
    </Header>
  );
};

const resultRenderer = (props: SearchResultProps) => (
  <div className={styles.itemText}>
    {props.title + (props.searchtype === EntityType.WorkOrder && props.description ? `: ${props.description}` : '')}
  </div>
);

const SearchBar: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('common');

  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const searchWrapperRef = React.useRef<HTMLDivElement>(null);

  const [enabledKeyDown, setEnabledKeyDown] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');
  const [originalResults, setOriginalResults] = React.useState<SearchListView>();
  const [results, setResults] = React.useState<SearchBarResults>({
    properties: { name: `${t('properties')} (0)`, results: [] },
    tenants: { name: `${t('tenants')} (0)`, results: [] },
    workOrders: { name: `${t('faultReports')} (0)`, results: [] }
  });

  const handleResultSelect = (e: any, data: any) => {
    const { value, result } = data;

    // Item could be selected by both mouse and enter key.
    // "onKeyDown" was triggered after "onResultSelect".
    setEnabledKeyDown(false);

    if (result.id) {
      setValue('');

      if (result.searchtype === EntityType.Property) {
        navigate(`/properties/${result.id}`);
      }

      if (result.searchtype === EntityType.Tenant) {
        navigate(`/tenants/${result.id}`);
      }

      if (result.searchtype === EntityType.WorkOrder) {
        navigate(`/fault-reports/${result.id}`);
      }
    } else {
      navigate(`/search?type=${result.searchtype}&q=${value}&page=1`);
    }
  };

  const getFirstEntityTypeWithResults = (): EntityType | undefined => {
    if (listViewTotalCount(originalResults?.properties) > 0) {
      return EntityType.Property;
    }

    if (listViewTotalCount(originalResults?.tenants) > 0) {
      return EntityType.Tenant;
    }

    if (listViewTotalCount(originalResults?.workOrders) > 0) {
      return EntityType.WorkOrder;
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && value) {
      e.target.blur();

      const entityType = getFirstEntityTypeWithResults();
      if (entityType) {
        navigate(`/search?type=${entityType}&q=${value}&page=1`);
      }
    }
  };

  const handleFocus = () => setEnabledKeyDown(true);

  React.useLayoutEffect(() => {
    const abortController = new AbortController();

    const handleSearchChange = async (data: string) => {
      if (value) {
        try {
          setEnabledKeyDown(true);
          setLoading(true);

          const searchResults = await SearchService.getSearchResults(
            {
              limit: LIMIT,
              query: data
            },
            {
              signal: abortController.signal
            }
          );

          setOriginalResults(searchResults);
          setResults(generateSearchBarResults(searchResults));
        } catch (error) {
          handleHttpErrors(error);
        } finally {
          setLoading(false);
        }
      }
    };

    const trigger = debounce(() => handleSearchChange(value), 300);

    trigger();
    return () => {
      trigger.cancel();
      abortController.abort();
    };
  }, [value, handleHttpErrors]);

  return (
    <div className={styles.searchWrapper} ref={searchWrapperRef}>
      <Search
        className={styles.searchBar}
        input={<Input icon="search" iconPosition="left" className={styles.input} value={value} />}
        icon="trash"
        category
        categoryLayoutRenderer={categoryLayoutRenderer}
        categoryRenderer={(p) => categoryRenderer(p, results, value)}
        resultRenderer={resultRenderer}
        loading={loading}
        onResultSelect={handleResultSelect}
        onSearchChange={(e, d) => {
          setValue(d.value ?? '');
        }}
        onFocus={handleFocus}
        results={results}
        value={value}
        placeholder={t('search')}
        onKeyDown={enabledKeyDown ? handleKeyDown : null}
      />
      <Button
        compact
        style={{ visibility: value ? 'visible' : 'hidden' }}
        icon={<FontAwesomeIcon icon={faTimes} fixedWidth />}
        onClick={() => setValue('')}
        className={styles.button}
      />
    </div>
  );
};

export default SearchBar;
