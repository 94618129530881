import mime from 'mime';
import React from 'react';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { fileApi } from '../../../Http/Http';
import { ResponseErrorCode } from '../../../Http/response-error';
import { getCachedFileThumbnailUrl } from '../../../Services/FileService';
import { FileInfoReadView } from '../../../Services/FileService.types';
import PromisePool from 'es6-promise-pool';
import _ from 'lodash';

export enum FileThumbnailStatus {
  None,
  Deleting,
  Loading,
  Error,
  ThumbnailGenerationError
}

export type FileWithThumbnailUrlEntity = FileInfoReadView & {
  status: FileThumbnailStatus;
  isImageOrPDF?: boolean;
  thumbnailUrl?: string;
};
type FileWithThumbnailUrlState = FileWithThumbnailUrlEntity[];
type FileWithThumbnailUrlAction =
  | { type: 'INIT'; items: FileWithThumbnailUrlState }
  | { type: 'STATUS_CHANGE'; file: FileWithThumbnailUrlEntity; status: FileThumbnailStatus }
  | { type: 'URL_CHANGE'; file: FileWithThumbnailUrlEntity; thumbnailUrl: string };

function filesReducer(state: FileWithThumbnailUrlState, action: FileWithThumbnailUrlAction): FileWithThumbnailUrlState {
  switch (action.type) {
    case 'INIT': {
      return action.items;
    }
    case 'STATUS_CHANGE': {
      return state.map((item) => (item.id !== action.file.id ? item : { ...action.file, status: action.status }));
    }
    case 'URL_CHANGE': {
      return state.map((item) =>
        item.id !== action.file.id
          ? item
          : {
              ...action.file,
              thumbnailUrl: action.thumbnailUrl,
              status: FileThumbnailStatus.None
            }
      );
    }
  }
}

export const useFilesReducer = (props: { files?: FileInfoReadView[] }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const [files, dispatchFiles] = React.useReducer(filesReducer, []);
  const previousFilesRef = React.useRef<FileInfoReadView[]>();

  const loadThumbnails = React.useCallback(
    async (files: FileWithThumbnailUrlEntity[], abortController?: AbortController) => {
      const fetchThumbnailUrl = async (
        file: FileWithThumbnailUrlEntity,
        abortController?: AbortController
      ): Promise<void> => {
        return new Promise(async (resolve) => {
          try {
            dispatchFiles({
              type: 'STATUS_CHANGE',
              file,
              status: FileThumbnailStatus.Loading
            });
            if (file.isImageOrPDF) {
              const thumnbailUrl = await getCachedFileThumbnailUrl(
                {
                  id: file.id,
                  download: false
                },
                { signal: abortController?.signal }
              );

              dispatchFiles({
                type: 'URL_CHANGE',
                file,
                thumbnailUrl: thumnbailUrl
              });
            } else dispatchFiles({ type: 'STATUS_CHANGE', file, status: FileThumbnailStatus.None });
          } catch (error) {
            handleHttpErrors(error, {
              handleDefaultResponseValidationError: (response) => {
                if (response.code === ResponseErrorCode.R000026) {
                  dispatchFiles({
                    type: 'STATUS_CHANGE',
                    file,
                    status: FileThumbnailStatus.ThumbnailGenerationError
                  });
                } else
                  dispatchFiles({
                    type: 'STATUS_CHANGE',
                    file,
                    status: FileThumbnailStatus.Error
                  });
              }
            });
          } finally {
            resolve();
          }
        });
      };

      const generateThumbnailPromises = function* () {
        for (let count = 0; count < files.length; count++) {
          yield fetchThumbnailUrl(files[count], abortController);
        }
      };

      const promiseIterator = generateThumbnailPromises();
      // @ts-ignore
      const pool = new PromisePool(promiseIterator, 5);

      pool.start();
    },
    [handleHttpErrors]
  );

  const deleteFile = async (file: FileWithThumbnailUrlEntity) => {
    try {
      dispatchFiles({
        type: 'STATUS_CHANGE',
        file,
        status: FileThumbnailStatus.Deleting
      });
      await fileApi.fileResourceDelete({ id: file.id });
      dispatchFiles({
        type: 'STATUS_CHANGE',
        file,
        status: FileThumbnailStatus.None
      });

      return true;
    } catch (error) {
      handleHttpErrors(error);
      dispatchFiles({
        type: 'STATUS_CHANGE',
        file,
        status: FileThumbnailStatus.Error
      });

      return false;
    }
  };

  const memoizedFiles = React.useMemo(() => {
    if (props.files && !_.isEqual(previousFilesRef.current ?? [], props.files)) {
      previousFilesRef.current = props.files;
    }
    return previousFilesRef.current;
  }, [props.files]);

  React.useEffect(() => {
    const abortController = new AbortController();
    if (memoizedFiles) {
      const files = memoizedFiles.map((item) => {
        const fileMimeType = mime.getType(item.name);
        const isImage = fileMimeType?.startsWith('image/');
        const isPDF = fileMimeType?.includes('pdf');
        const isImageOrPDF = isImage || isPDF;

        return { ...item, status: FileThumbnailStatus.Loading, isImageOrPDF };
      });
      dispatchFiles({
        type: 'INIT',
        items: files
      });

      loadThumbnails(files, abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [memoizedFiles, loadThumbnails]);

  const retryLoadingThumbnail = (file: FileWithThumbnailUrlEntity) => loadThumbnails([file]);
  return { files, deleteFile, retryLoadingThumbnail };
};
