/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface WorkOrderLogCreateView
 */
export interface WorkOrderLogCreateView {
  /**
   * Type.
   * @type {string}
   * @memberof WorkOrderLogCreateView
   */
  type: WorkOrderLogCreateViewTypeEnum;
  /**
   * Work order.
   * @type {number}
   * @memberof WorkOrderLogCreateView
   */
  workOrderId: number;
}

export const WorkOrderLogCreateViewTypeEnum = {
  Message: 'MESSAGE',
  StatusChange: 'STATUS_CHANGE',
  TenantMessage: 'TENANT_MESSAGE',
  Assignment: 'ASSIGNMENT',
  AssignmentRemoval: 'ASSIGNMENT_REMOVAL',
  DescriptionChange: 'DESCRIPTION_CHANGE',
  PropertyChange: 'PROPERTY_CHANGE',
  TenantChange: 'TENANT_CHANGE',
  TenantEmailChange: 'TENANT_EMAIL_CHANGE',
  TenantPhoneChange: 'TENANT_PHONE_CHANGE',
  CategoryChange: 'CATEGORY_CHANGE'
} as const;

export type WorkOrderLogCreateViewTypeEnum =
  (typeof WorkOrderLogCreateViewTypeEnum)[keyof typeof WorkOrderLogCreateViewTypeEnum];
