import React from 'react';
import { Button, Icon, Loader, Message, Table } from 'semantic-ui-react';
import EditableControlCell from '../../../../Components/EditableTableCell/EditableControlCell';
import EditableTableNumberCell from '../../../../Components/EditableTableCell/EditableTableNumberCell';
import LoadError from '../../../../Components/LoadError';
import TableHeaderCellContent from '../../../../Components/TableHeaderCell/TableHeaderCell';
import { BusinessPlanLeaseReadView, UnitObjectReadView } from '../../../../GeneratedServices';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { businessPlanApi, createInfinitePaginationParams, unitObjectApi } from '../../../../Http/Http';
import { translateObjectType } from '../../../../Services/BusinessPlanService.types';
import { useNonce } from '../../../../Utils/hooks';
import { getFormatedCurrencyValue } from '../../../../Utils/number';
import { BusinessPlanLeaseFormFieldId, useBusinessPlanLeasesReducer } from './hooks/useBusinessPlanLeasesReducer';
import EditableTableDateCell from '../../../../Components/EditableTableCell/EditableTableDateCell';
import { useTranslation } from 'react-i18next';
import EditableTableStringCell from '../../../../Components/EditableTableCell/EditableTableStringCell';
import CreateBusinessPlanLeaseModal from './CreateBusinessPlanLeaseModal';

interface Props {
  propertyId: number;
  businessPlanId?: number;
  shouldReload: number;
  isPermitted?: boolean;
  businessPlanStartDate?: string | null;
}

enum Status {
  None,
  Loading,
  LoadError
}

type BusinessPlanLeaseWithUnitReadView = BusinessPlanLeaseReadView & Omit<UnitObjectReadView, 'id'>;

const BusinessPlanLeasesTab: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const [businessPlanLeases, setBusinessPlanLeases] = React.useState<BusinessPlanLeaseWithUnitReadView[]>();
  const [unitObjects, setUnitObjects] = React.useState<UnitObjectReadView[]>();

  const [status, setStatus] = React.useState<Status>();

  const [shouldReloadLeases, reloadLeases] = useNonce();

  const [leaseToEdit, setLeaseToEdit] = React.useState<BusinessPlanLeaseWithUnitReadView>();
  const [objectLeaseGenerationMap, setObjectLeaseGenerationMap] = React.useState<Map<number, number>>(new Map());

  const [isCreateLeaseModalOpen, setCreateLeaseModalOpen] = React.useState(false);

  const {
    businessPlanLease,
    dispatchBusinessPlanLease,
    saveBusinessPlanLease,
    deleteBusinessPlanLease,
    saving: isLeaseSaving,
    deleting: isLeaseDeleting
  } = useBusinessPlanLeasesReducer({ lease: leaseToEdit });

  React.useEffect(() => {
    const abortController = new AbortController();
    const load = async () => {
      try {
        setStatus(Status.Loading);

        const { data: leases } = await businessPlanApi.businessPlanLeaseResourceList(
          {
            ...createInfinitePaginationParams(),
            propertyId: props.propertyId
          },
          { signal: abortController.signal }
        );

        const { data: unitObjects } = await unitObjectApi.unitObjectResourceList(
          {
            ...createInfinitePaginationParams(),
            propertyId: props.propertyId
          },
          { signal: abortController.signal }
        );

        const objectMap = new Map(unitObjects.records.map((item) => [item.id, item]));
        setUnitObjects(unitObjects.records);

        const generationMap = new Map();
        setBusinessPlanLeases(
          leases.records.map((item) => {
            const { id, ...rest } = objectMap.get(item.objectId)!;

            const maxGen = generationMap.get(item.objectId);
            if (!maxGen || item.generation > maxGen) generationMap.set(item.objectId, item.generation);
            return { ...item, ...rest };
          })
        );
        setObjectLeaseGenerationMap(generationMap);
        setStatus(Status.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [props.propertyId, handleHttpErrors, shouldReloadLeases, props.shouldReload]);

  const getLayout = () => {
    switch (status) {
      case Status.Loading:
        return businessPlanLeases && businessPlanLeases.length !== 0 ? generalLayout : layoutLoading;
      case Status.None:
        return generalLayout;
      case Status.LoadError:
        return layoutLoadError;
    }
  };

  const layoutLoading = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader active inline="centered" size="large" />
    </div>
  );

  const layoutLoadError = (
    <LoadError message={t('contracts:unableToLoadLeasesOfBusinessPlanMessage')} retry={reloadLeases} />
  );

  const generalLayout = (
    <div style={{ overflowX: 'auto' }}>
      <Table basic="very" singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'ID'}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:type')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:tenant')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:startDate')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:endDate')}</Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('common:lettableArea')} extra={t('common:sqm')} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('contracts:investmentPeriod')} extra={t('common:months')} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('contracts:investment')} extra="SEK" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('common:baseRent')} extra={t('common:sekSqm')} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('contracts:additions')} extra={t('common:sekSqm')} />
            </Table.HeaderCell>
            {props.isPermitted && (
              <Table.HeaderCell collapsing>
                <Button
                  primary
                  icon
                  compact
                  onClick={() => {
                    setCreateLeaseModalOpen(true);
                  }}
                >
                  <Icon name="plus" />
                </Button>
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {businessPlanLeases?.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>{`${item.strifastPropertyId}-${item.strifastObjectId}-${item.generation}`}</Table.Cell>
              <Table.Cell>{translateObjectType(item.objectType)}</Table.Cell>
              <Table.Cell>
                <EditableTableStringCell
                  isInEditMode={leaseToEdit?.id === item.id}
                  displayValue={item.tenantName}
                  value={businessPlanLease.tenantName}
                  placeholder={t('common:tenant')}
                  onValueChange={(value) => {
                    dispatchBusinessPlanLease({
                      type: 'VALUE_CHANGE',
                      formFieldId: BusinessPlanLeaseFormFieldId.tenantName,
                      value: value
                    });
                  }}
                  validationResult={
                    businessPlanLease.validationResult?.find(
                      (item) => item.field === BusinessPlanLeaseFormFieldId.tenantName
                    )?.result
                  }
                />
              </Table.Cell>
              <Table.Cell>{item.moveInDate}</Table.Cell>
              <Table.Cell>
                <EditableTableDateCell
                  isInEditMode={leaseToEdit?.id === item.id}
                  displayValue={item.moveOutDate}
                  value={businessPlanLease.moveOutDate}
                  placeholder={t('common:endDate')}
                  onValueChange={(value) => {
                    dispatchBusinessPlanLease({
                      type: 'VALUE_CHANGE',
                      formFieldId: BusinessPlanLeaseFormFieldId.moveOutDate,
                      value
                    });
                  }}
                  validationResult={
                    businessPlanLease.validationResult?.find(
                      (item) => item.field === BusinessPlanLeaseFormFieldId.moveOutDate
                    )?.result
                  }
                />
              </Table.Cell>

              <Table.Cell>
                <EditableTableNumberCell
                  isInEditMode={leaseToEdit?.id === item.id}
                  displayValue={item.indoorArea}
                  value={businessPlanLease.indoorArea}
                  placeholder={t('common:lettableArea')}
                  onValueChange={(value) => {
                    dispatchBusinessPlanLease({
                      type: 'VALUE_CHANGE',
                      formFieldId: BusinessPlanLeaseFormFieldId.indoorArea,
                      value: value.floatValue
                    });
                  }}
                  validationResult={
                    businessPlanLease.validationResult?.find(
                      (item) => item.field === BusinessPlanLeaseFormFieldId.indoorArea
                    )?.result
                  }
                />
              </Table.Cell>

              <Table.Cell>
                <EditableTableNumberCell
                  isInEditMode={leaseToEdit?.id === item.id}
                  displayValue={item.downtimePeriod}
                  value={businessPlanLease.downtimePeriod}
                  placeholder={t('contracts:investmentPeriod')}
                  onValueChange={(value) => {
                    dispatchBusinessPlanLease({
                      type: 'VALUE_CHANGE',
                      formFieldId: BusinessPlanLeaseFormFieldId.downtimePeriod,
                      value: value.floatValue
                    });
                  }}
                  additionalInputProps={{ decimalScale: 0 }}
                  validationResult={
                    businessPlanLease.validationResult?.find(
                      (item) => item.field === BusinessPlanLeaseFormFieldId.downtimePeriod
                    )?.result
                  }
                />
              </Table.Cell>

              <Table.Cell>
                <EditableTableNumberCell
                  isInEditMode={leaseToEdit?.id === item.id}
                  displayValue={item.investment && getFormatedCurrencyValue(item.investment)}
                  value={
                    businessPlanLease.investment ? getFormatedCurrencyValue(businessPlanLease.investment) : undefined
                  }
                  placeholder={t('contracts:investment')}
                  onValueChange={(value) => {
                    dispatchBusinessPlanLease({
                      type: 'VALUE_CHANGE',
                      formFieldId: BusinessPlanLeaseFormFieldId.investment,
                      value: (value.floatValue ?? 0) * 100
                    });
                  }}
                  validationResult={
                    businessPlanLease.validationResult?.find(
                      (item) => item.field === BusinessPlanLeaseFormFieldId.investment
                    )?.result
                  }
                />
              </Table.Cell>

              <Table.Cell>
                <EditableTableNumberCell
                  isInEditMode={leaseToEdit?.id === item.id}
                  displayValue={item.baseRent && getFormatedCurrencyValue(item.baseRent)}
                  value={businessPlanLease.baseRent ? getFormatedCurrencyValue(businessPlanLease.baseRent) : undefined}
                  placeholder={t('common:baseRent')}
                  onValueChange={(value) => {
                    dispatchBusinessPlanLease({
                      type: 'VALUE_CHANGE',
                      formFieldId: BusinessPlanLeaseFormFieldId.baseRent,
                      value: (value.floatValue ?? 0) * 100
                    });
                  }}
                  validationResult={
                    businessPlanLease.validationResult?.find(
                      (item) => item.field === BusinessPlanLeaseFormFieldId.baseRent
                    )?.result
                  }
                />
              </Table.Cell>

              <Table.Cell>
                <EditableTableNumberCell
                  isInEditMode={leaseToEdit?.id === item.id}
                  displayValue={item.additions && getFormatedCurrencyValue(item.additions)}
                  value={
                    businessPlanLease.additions ? getFormatedCurrencyValue(businessPlanLease.additions) : undefined
                  }
                  placeholder={t('contracts:additions')}
                  onValueChange={(value) => {
                    dispatchBusinessPlanLease({
                      type: 'VALUE_CHANGE',
                      formFieldId: BusinessPlanLeaseFormFieldId.additions,
                      value: (value.floatValue ?? 0) * 100
                    });
                  }}
                  validationResult={
                    businessPlanLease.validationResult?.find(
                      (item) => item.field === BusinessPlanLeaseFormFieldId.additions
                    )?.result
                  }
                />
              </Table.Cell>

              {props.isPermitted && (
                <Table.Cell collapsing textAlign="right">
                  <EditableControlCell
                    isInEditMode={leaseToEdit?.id === item.id}
                    onCancel={() => {
                      dispatchBusinessPlanLease({
                        type: 'VALUE_RESET'
                      });
                      setLeaseToEdit(undefined);
                    }}
                    onEditModeEnter={() => {
                      setLeaseToEdit(item);
                    }}
                    saveLoading={isLeaseSaving || status === Status.Loading}
                    onSave={async () => {
                      const result = await saveBusinessPlanLease();
                      if (result) {
                        reloadLeases();
                        setLeaseToEdit(undefined);
                        dispatchBusinessPlanLease({
                          type: 'VALUE_RESET'
                        });
                      }
                    }}
                    allowDelete={true}
                    deleteLoading={isLeaseDeleting || status === Status.Loading}
                    deletingDisabled={objectLeaseGenerationMap.get(item.objectId) !== item.generation}
                    onDelete={async () => {
                      const result = await deleteBusinessPlanLease();
                      if (result) {
                        reloadLeases();
                        setLeaseToEdit(undefined);
                        dispatchBusinessPlanLease({
                          type: 'VALUE_RESET'
                        });
                      }
                    }}
                    editModeDisabled={isLeaseSaving || (leaseToEdit && leaseToEdit.id !== item?.id)}
                  />
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>

        {businessPlanLeases?.length === 0 && (
          <Table.Footer fullWidth>
            <Table.Cell colSpan={11} textAlign="center">
              <Message>{t('contracts:noLeasesFound')}</Message>
            </Table.Cell>
          </Table.Footer>
        )}
      </Table>
    </div>
  );

  return (
    <>
      {getLayout()}

      {isCreateLeaseModalOpen && props.businessPlanId && (
        <CreateBusinessPlanLeaseModal
          onClose={(saved) => {
            saved && reloadLeases();
            setCreateLeaseModalOpen(false);
          }}
          businessPlanId={props.businessPlanId}
          unitObjects={unitObjects}
        />
      )}
    </>
  );
};

export default BusinessPlanLeasesTab;
