/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeOwnPasswordView } from '../model';
// @ts-ignore
import { ChangePasswordView } from '../model';
// @ts-ignore
import { CredentialsView } from '../model';
// @ts-ignore
import { EmailView } from '../model';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { RenewTokenView } from '../model';
// @ts-ignore
import { RenewedTokenView } from '../model';
// @ts-ignore
import { TokenView } from '../model';
// @ts-ignore
import { VerifyEmailView } from '../model';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Change password using current password
     * @param {ChangeOwnPasswordView} [changeOwnPasswordView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceChangeOwnPassword: async (
      changeOwnPasswordView?: ChangeOwnPasswordView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/auth/change-own-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(changeOwnPasswordView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Change password using token from email
     * @param {ChangePasswordView} [changePasswordView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceChangePassword: async (
      changePasswordView?: ChangePasswordView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/auth/change-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(changePasswordView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Used the token sent during user sign up to verify the users email address.
     * @summary Verify email
     * @param {VerifyEmailView} [verifyEmailView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceEmailVerify: async (
      verifyEmailView?: VerifyEmailView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/auth/verify-email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get authentication token
     * @param {CredentialsView} [credentialsView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceGetToken: async (
      credentialsView?: CredentialsView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/auth/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(credentialsView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get authentication token
     * @param {RenewTokenView} [renewTokenView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceRenewToken: async (
      renewTokenView?: RenewTokenView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/auth/renew-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(renewTokenView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Reset password through email
     * @param {EmailView} [emailView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceResetPassword: async (
      emailView?: EmailView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/auth/reset-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(emailView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     * Change password using current password
     * @param {ChangeOwnPasswordView} [changeOwnPasswordView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authResourceChangeOwnPassword(
      changeOwnPasswordView?: ChangeOwnPasswordView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authResourceChangeOwnPassword(
        changeOwnPasswordView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Change password using token from email
     * @param {ChangePasswordView} [changePasswordView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authResourceChangePassword(
      changePasswordView?: ChangePasswordView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authResourceChangePassword(changePasswordView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Used the token sent during user sign up to verify the users email address.
     * @summary Verify email
     * @param {VerifyEmailView} [verifyEmailView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authResourceEmailVerify(
      verifyEmailView?: VerifyEmailView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authResourceEmailVerify(verifyEmailView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get authentication token
     * @param {CredentialsView} [credentialsView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authResourceGetToken(
      credentialsView?: CredentialsView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authResourceGetToken(credentialsView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get authentication token
     * @param {RenewTokenView} [renewTokenView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authResourceRenewToken(
      renewTokenView?: RenewTokenView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenewedTokenView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authResourceRenewToken(renewTokenView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Reset password through email
     * @param {EmailView} [emailView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authResourceResetPassword(
      emailView?: EmailView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authResourceResetPassword(emailView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     * Change password using current password
     * @param {ChangeOwnPasswordView} [changeOwnPasswordView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceChangeOwnPassword(changeOwnPasswordView?: ChangeOwnPasswordView, options?: any): AxiosPromise<void> {
      return localVarFp
        .authResourceChangeOwnPassword(changeOwnPasswordView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Change password using token from email
     * @param {ChangePasswordView} [changePasswordView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceChangePassword(changePasswordView?: ChangePasswordView, options?: any): AxiosPromise<void> {
      return localVarFp
        .authResourceChangePassword(changePasswordView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Used the token sent during user sign up to verify the users email address.
     * @summary Verify email
     * @param {VerifyEmailView} [verifyEmailView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceEmailVerify(verifyEmailView?: VerifyEmailView, options?: any): AxiosPromise<void> {
      return localVarFp.authResourceEmailVerify(verifyEmailView, options).then((request) => request(axios, basePath));
    },
    /**
     * Get authentication token
     * @param {CredentialsView} [credentialsView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceGetToken(credentialsView?: CredentialsView, options?: any): AxiosPromise<TokenView> {
      return localVarFp.authResourceGetToken(credentialsView, options).then((request) => request(axios, basePath));
    },
    /**
     * Get authentication token
     * @param {RenewTokenView} [renewTokenView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceRenewToken(renewTokenView?: RenewTokenView, options?: any): AxiosPromise<RenewedTokenView> {
      return localVarFp.authResourceRenewToken(renewTokenView, options).then((request) => request(axios, basePath));
    },
    /**
     * Reset password through email
     * @param {EmailView} [emailView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authResourceResetPassword(emailView?: EmailView, options?: any): AxiosPromise<void> {
      return localVarFp.authResourceResetPassword(emailView, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for authResourceChangeOwnPassword operation in AuthApi.
 * @export
 * @interface AuthApiAuthResourceChangeOwnPasswordRequest
 */
export interface AuthApiAuthResourceChangeOwnPasswordRequest {
  /**
   *
   * @type {ChangeOwnPasswordView}
   * @memberof AuthApiAuthResourceChangeOwnPassword
   */
  readonly changeOwnPasswordView?: ChangeOwnPasswordView;
}

/**
 * Request parameters for authResourceChangePassword operation in AuthApi.
 * @export
 * @interface AuthApiAuthResourceChangePasswordRequest
 */
export interface AuthApiAuthResourceChangePasswordRequest {
  /**
   *
   * @type {ChangePasswordView}
   * @memberof AuthApiAuthResourceChangePassword
   */
  readonly changePasswordView?: ChangePasswordView;
}

/**
 * Request parameters for authResourceEmailVerify operation in AuthApi.
 * @export
 * @interface AuthApiAuthResourceEmailVerifyRequest
 */
export interface AuthApiAuthResourceEmailVerifyRequest {
  /**
   *
   * @type {VerifyEmailView}
   * @memberof AuthApiAuthResourceEmailVerify
   */
  readonly verifyEmailView?: VerifyEmailView;
}

/**
 * Request parameters for authResourceGetToken operation in AuthApi.
 * @export
 * @interface AuthApiAuthResourceGetTokenRequest
 */
export interface AuthApiAuthResourceGetTokenRequest {
  /**
   *
   * @type {CredentialsView}
   * @memberof AuthApiAuthResourceGetToken
   */
  readonly credentialsView?: CredentialsView;
}

/**
 * Request parameters for authResourceRenewToken operation in AuthApi.
 * @export
 * @interface AuthApiAuthResourceRenewTokenRequest
 */
export interface AuthApiAuthResourceRenewTokenRequest {
  /**
   *
   * @type {RenewTokenView}
   * @memberof AuthApiAuthResourceRenewToken
   */
  readonly renewTokenView?: RenewTokenView;
}

/**
 * Request parameters for authResourceResetPassword operation in AuthApi.
 * @export
 * @interface AuthApiAuthResourceResetPasswordRequest
 */
export interface AuthApiAuthResourceResetPasswordRequest {
  /**
   *
   * @type {EmailView}
   * @memberof AuthApiAuthResourceResetPassword
   */
  readonly emailView?: EmailView;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   * Change password using current password
   * @param {AuthApiAuthResourceChangeOwnPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authResourceChangeOwnPassword(
    requestParameters: AuthApiAuthResourceChangeOwnPasswordRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authResourceChangeOwnPassword(requestParameters.changeOwnPasswordView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Change password using token from email
   * @param {AuthApiAuthResourceChangePasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authResourceChangePassword(
    requestParameters: AuthApiAuthResourceChangePasswordRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authResourceChangePassword(requestParameters.changePasswordView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Used the token sent during user sign up to verify the users email address.
   * @summary Verify email
   * @param {AuthApiAuthResourceEmailVerifyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authResourceEmailVerify(
    requestParameters: AuthApiAuthResourceEmailVerifyRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authResourceEmailVerify(requestParameters.verifyEmailView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get authentication token
   * @param {AuthApiAuthResourceGetTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authResourceGetToken(
    requestParameters: AuthApiAuthResourceGetTokenRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authResourceGetToken(requestParameters.credentialsView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get authentication token
   * @param {AuthApiAuthResourceRenewTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authResourceRenewToken(
    requestParameters: AuthApiAuthResourceRenewTokenRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authResourceRenewToken(requestParameters.renewTokenView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reset password through email
   * @param {AuthApiAuthResourceResetPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authResourceResetPassword(
    requestParameters: AuthApiAuthResourceResetPasswordRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authResourceResetPassword(requestParameters.emailView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
