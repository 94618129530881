/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface WorkOrderLogReadView
 */
export interface WorkOrderLogReadView {
  /**
   * User, who created this log record.
   * @type {number}
   * @memberof WorkOrderLogReadView
   */
  authorId: number;
  /**
   * When log record was created.
   * @type {string}
   * @memberof WorkOrderLogReadView
   */
  created: string;
  /**
   * Unique identifier.
   * @type {number}
   * @memberof WorkOrderLogReadView
   */
  id: number;
  /**
   * Organisation.
   * @type {number}
   * @memberof WorkOrderLogReadView
   */
  organisationId: number;
  /**
   * Type.
   * @type {string}
   * @memberof WorkOrderLogReadView
   */
  type: WorkOrderLogReadViewTypeEnum;
  /**
   * When log record was last updated.
   * @type {string}
   * @memberof WorkOrderLogReadView
   */
  updated: string;
  /**
   * Work order.
   * @type {number}
   * @memberof WorkOrderLogReadView
   */
  workOrderId: number;
}

export const WorkOrderLogReadViewTypeEnum = {
  Message: 'MESSAGE',
  StatusChange: 'STATUS_CHANGE',
  TenantMessage: 'TENANT_MESSAGE',
  Assignment: 'ASSIGNMENT',
  AssignmentRemoval: 'ASSIGNMENT_REMOVAL',
  DescriptionChange: 'DESCRIPTION_CHANGE',
  PropertyChange: 'PROPERTY_CHANGE',
  TenantChange: 'TENANT_CHANGE',
  TenantEmailChange: 'TENANT_EMAIL_CHANGE',
  TenantPhoneChange: 'TENANT_PHONE_CHANGE',
  CategoryChange: 'CATEGORY_CHANGE'
} as const;

export type WorkOrderLogReadViewTypeEnum =
  (typeof WorkOrderLogReadViewTypeEnum)[keyof typeof WorkOrderLogReadViewTypeEnum];
