/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface NotificationReadView
 */
export interface NotificationReadView {
  /**
   * When notification was created.
   * @type {string}
   * @memberof NotificationReadView
   */
  created: string;
  /**
   * Entity ID.
   * @type {number}
   * @memberof NotificationReadView
   */
  entityId: number | null;
  /**
   * Entity type.
   * @type {string}
   * @memberof NotificationReadView
   */
  entityType: NotificationReadViewEntityTypeEnum;
  /**
   * Notification ID.
   * @type {number}
   * @memberof NotificationReadView
   */
  id: number;
  /**
   * Notification message.
   * @type {string}
   * @memberof NotificationReadView
   */
  message: string;
  /**
   * Notification type.
   * @type {string}
   * @memberof NotificationReadView
   */
  notificationType: NotificationReadViewNotificationTypeEnum;
  /**
   * Name of organisation.
   * @type {number}
   * @memberof NotificationReadView
   */
  organisationId: number;
  /**
   * Seen state.
   * @type {boolean}
   * @memberof NotificationReadView
   */
  seen: boolean;
  /**
   * Notification title.
   * @type {string}
   * @memberof NotificationReadView
   */
  title: string;
  /**
   * User ID.
   * @type {number}
   * @memberof NotificationReadView
   */
  userId: number;
}

export const NotificationReadViewEntityTypeEnum = {
  Contract: 'CONTRACT',
  Property: 'PROPERTY',
  Workorder: 'WORKORDER',
  Workorderlog: 'WORKORDERLOG',
  PropertyCheck: 'PROPERTY_CHECK',
  GovernmentInspectionWorkOrder: 'GOVERNMENT_INSPECTION_WORK_ORDER',
  Organisation: 'ORGANISATION',
  Portfolio: 'PORTFOLIO',
  GovernmentInspection: 'GOVERNMENT_INSPECTION'
} as const;

export type NotificationReadViewEntityTypeEnum =
  (typeof NotificationReadViewEntityTypeEnum)[keyof typeof NotificationReadViewEntityTypeEnum];
export const NotificationReadViewNotificationTypeEnum = {
  AddedOrRemovedOrganisation: 'ADDED_OR_REMOVED_ORGANISATION',
  ConnectedOrDisconnectedProperty: 'CONNECTED_OR_DISCONNECTED_PROPERTY',
  NewErrorReport: 'NEW_ERROR_REPORT',
  NewErrorReportLog: 'NEW_ERROR_REPORT_LOG',
  FaultReportStatusChanged: 'FAULT_REPORT_STATUS_CHANGED',
  ExpiringContract: 'EXPIRING_CONTRACT',
  MissedGovernmentInspectionWorkOrder: 'MISSED_GOVERNMENT_INSPECTION_WORK_ORDER',
  CompleteGovernmentInspectionWorkOrder: 'COMPLETE_GOVERNMENT_INSPECTION_WORK_ORDER',
  UpcomingGovernmentInspectionWorkOrder: 'UPCOMING_GOVERNMENT_INSPECTION_WORK_ORDER',
  PropertyCheckDue: 'PROPERTY_CHECK_DUE',
  ErrorReportAssignment: 'ERROR_REPORT_ASSIGNMENT',
  AddedOrRemovedPortfolio: 'ADDED_OR_REMOVED_PORTFOLIO'
} as const;

export type NotificationReadViewNotificationTypeEnum =
  (typeof NotificationReadViewNotificationTypeEnum)[keyof typeof NotificationReadViewNotificationTypeEnum];
