import React from 'react';
import { Loader } from 'semantic-ui-react';
import { SemanticSIZES } from 'semantic-ui-react';

interface Props {
  loading: boolean;
  top?: string | number;
  size?: SemanticSIZES;
}

const OverlayLoader: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <div style={{ position: 'relative', textAlign: 'left' }}>
      {props.loading && <Loader active size={props.size ?? 'large'} style={{ top: props.top }} />}

      <div style={{ opacity: props.loading ? 0.4 : 1, pointerEvents: props.loading ? 'none' : 'inherit' }}>
        {props.children}
      </div>
    </div>
  );
};

export default OverlayLoader;
