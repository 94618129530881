/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface FinancialTransactionPeriodReadView
 */
export interface FinancialTransactionPeriodReadView {
  /**
   * Transaction period ID.
   * @type {number}
   * @memberof FinancialTransactionPeriodReadView
   */
  id: number;
  /**
   * When the period was last updated.
   * @type {string}
   * @memberof FinancialTransactionPeriodReadView
   */
  lastUpdated: string;
  /**
   * Month of period.
   * @type {string}
   * @memberof FinancialTransactionPeriodReadView
   */
  month: FinancialTransactionPeriodReadViewMonthEnum;
  /**
   * Organisation ID.
   * @type {number}
   * @memberof FinancialTransactionPeriodReadView
   */
  organisationId: number;
  /**
   * Status.
   * @type {string}
   * @memberof FinancialTransactionPeriodReadView
   */
  status: FinancialTransactionPeriodReadViewStatusEnum;
  /**
   * Year of period.
   * @type {number}
   * @memberof FinancialTransactionPeriodReadView
   */
  year: number;
}

export const FinancialTransactionPeriodReadViewMonthEnum = {
  January: 'JANUARY',
  February: 'FEBRUARY',
  March: 'MARCH',
  April: 'APRIL',
  May: 'MAY',
  June: 'JUNE',
  July: 'JULY',
  August: 'AUGUST',
  September: 'SEPTEMBER',
  October: 'OCTOBER',
  November: 'NOVEMBER',
  December: 'DECEMBER'
} as const;

export type FinancialTransactionPeriodReadViewMonthEnum =
  (typeof FinancialTransactionPeriodReadViewMonthEnum)[keyof typeof FinancialTransactionPeriodReadViewMonthEnum];
export const FinancialTransactionPeriodReadViewStatusEnum = {
  Succeeded: 'SUCCEEDED',
  Failed: 'FAILED',
  InProgress: 'IN_PROGRESS'
} as const;

export type FinancialTransactionPeriodReadViewStatusEnum =
  (typeof FinancialTransactionPeriodReadViewStatusEnum)[keyof typeof FinancialTransactionPeriodReadViewStatusEnum];
