import { ListViewPagination, ListViewPaginationParams } from './types';
import i18n from '../i18n';

export interface FileInfoListView extends ListViewPagination<FileInfoReadView> {}

export interface FileInfoListParams extends ListViewPaginationParams {
  organisationId?: number;
  entityType?: FileEntityType;
  entityId?: number;
  type?: FileType;
  subType?: FileSubType;
  deleted?: boolean;
  archived?: boolean;
  propertyId?: number;
}

export interface FileArchiveParams {
  organisationId?: number;
  entityType?: FileEntityType;
  entityId?: number;
  type?: FileType;
  subType?: FileSubType;
  propertyId?: number;
}

export interface ThumbnailUrlParams extends FileUrlParams {
  maxWidth?: number;
  maxHeight?: number;
}
export interface FileUrlParams {
  id: string;
  download?: boolean;
}

export interface FileCreateView {
  name: string;
  blobBase64: string;
  type?: FileType;
  subType?: FileSubType;
  entityType?: FileEntityType;
  entityId?: number;
}

export interface FileInfoUpdateView {
  name?: string;
  type?: FileType | null;
  subType?: FileSubType | null;
  deleted?: boolean;
  archived?: boolean;
}

export interface FileInfoReadView {
  id: string;
  name: string;
  fileSize: number;
  type?: FileType;
  subType?: FileSubType;
  organisationId?: number;
  entityType?: string;
  entityId?: number;
  deleted: boolean;
  archived: boolean;
  creatorId: number;
  created: string;
  updaterId?: number;
  updated?: string;
}

export type FileEntityType =
  | 'PROPERTY'
  | 'CONTRACT'
  | 'WORKORDER'
  | 'WORKORDERLOG'
  | 'GOVERNMENT_INSPECTION_WORK_ORDER';

export enum FileType {
  PROPERTY_PICTURE = 'PROPERTY_PICTURE',
  LEASEHOLD_AGREEMENT = 'LEASEHOLD_AGREEMENT',
  EASEMENT = 'EASEMENT',
  INSURANCE = 'INSURANCE',
  INSPECTION = 'INSPECTION',
  LAND_AGREEMENT = 'LAND_AGREEMENT',
  OPERATING_SERVICING_AGREEMENTS = 'OPERATING_SERVICING_AGREEMENTS',
  BUILDING_PERMITS = 'BUILDING_PERMITS',
  DD_REPORT = 'DD_REPORT',
  NEGOTIATION_AGREEMENT = 'NEGOTIATION_AGREEMENT',
  LEASE = 'LEASE',
  DRAWING = 'DRAWING',
  OTHER = 'OTHER',
  PROJECT = 'PROJECT'
}

export enum FileSubType {
  PROPERTY_PICTURE = 'PROPERTY_PICTURE',
  INSIDE = 'INSIDE',
  OVK = 'OVK',
  ELEVATOR = 'ELEVATOR',
  DOOR = 'DOOR',
  LIFT_TABLE = 'LIFT_TABLE',
  LOADING_DOCK = 'LOADING_DOCK',
  AUDIT_INSPECTION_FIRE_ALARM = 'AUDIT_INSPECTION_FIRE_ALARM',
  AUDIT_INSPECTION_SPRINKLER = 'AUDIT_INSPECTION_SPRINKLER',
  ELECTRICITY_AUDIT = 'ELECTRICITY_AUDIT',
  REFRIGERANT = 'REFRIGERANT',
  OIL_SEPARATOR = 'OIL_SEPARATOR',
  SBA_SYSTEMATIC_FIRE_PROTECTION_WORK = 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK',
  PESTS = 'PESTS',
  ENERGY_DECLARATION = 'ENERGY_DECLARATION',
  PRESSURE_VESSELS = 'PRESSURE_VESSELS',
  ENVIRONMENTAL_SURVEY = 'ENVIRONMENTAL_SURVEY',
  WINTER_MAINTENANCE = 'WINTER_MAINTENANCE',
  LEASEHOLD = 'LEASEHOLD',
  AGREEMENT_PORT = 'AGREEMENT_PORT',
  CONTRACT_PRESSURE_VESSEL = 'CONTRACT_PRESSURE_VESSEL',
  AGREEMENT_OIL_SEPARATOR = 'AGREEMENT_OIL_SEPARATOR',
  FIRE_ALARM_TRANSMISSION = 'FIRE_ALARM_TRANSMISSION',
  LOCK_SCHEME = 'LOCK_SCHEME',
  LAND_MANAGEMENT = 'LAND_MANAGEMENT',
  AGREEMENT = 'AGREEMENT',
  ADDITION = 'ADDITION',
  FLOOR_PLAN = 'FLOOR_PLAN',
  CONSTRUCTION_DRAWING = 'CONSTRUCTION_DRAWING',
  INSTALLATION_DRAWING = 'INSTALLATION_DRAWING',
  GROUND_DRAWING = 'GROUND_DRAWING',
  BUILDING_PERMIT_DRAWING = 'BUILDING_PERMIT_DRAWING',
  RECORD_DRAWING = 'RECORD_DRAWING',
  ZONING_PLAN = 'ZONING_PLAN',
  FIRE_PROTECTION = 'FIRE_PROTECTION',
  SHELTER = 'SHELTER',
  TREATMENT_PLANT = 'TREATMENT_PLANT',
  OFFER = 'OFFER',
  ORDER = 'ORDER',
  FINAL_INSPECTION = 'FINAL_INSPECTION',
  COOLING = 'COOLING'
}

export const translateFileType = (type: FileType): string => {
  const map = new Map([
    [FileType.BUILDING_PERMITS, i18n.t('files:fileType.buildingPermits')],
    [FileType.DD_REPORT, i18n.t('files:fileType.ddReport')],
    [FileType.DRAWING, i18n.t('files:fileType.drawing')],
    [FileType.EASEMENT, i18n.t('files:fileType.easement')],
    [FileType.INSPECTION, i18n.t('files:fileType.inspection')],
    [FileType.INSURANCE, i18n.t('files:fileType.insurance')],
    [FileType.LEASE, i18n.t('files:fileType.lease')],
    [FileType.LEASEHOLD_AGREEMENT, i18n.t('files:fileType.leaseholdAgreement')],
    [FileType.LAND_AGREEMENT, i18n.t('files:fileType.landAgreement')],
    [FileType.NEGOTIATION_AGREEMENT, i18n.t('files:fileType.negotiationAgreement')],
    [FileType.OPERATING_SERVICING_AGREEMENTS, i18n.t('files:fileType.operatingServiceAgreements')],
    [FileType.OTHER, i18n.t('files:fileType.other')],
    [FileType.PROPERTY_PICTURE, i18n.t('files:fileType.propertyPicure')],
    [FileType.PROJECT, i18n.t('files:fileType.project')]
  ]);

  return map.has(type) ? map.get(type)! : type.toString();
};

export const translateFileSubType = (subType: FileSubType): string => {
  const map = new Map([
    [FileSubType.ADDITION, i18n.t('files:fileSubType.addition')],
    [FileSubType.AGREEMENT, i18n.t('files:fileSubType.agreement')],
    [FileSubType.AGREEMENT_OIL_SEPARATOR, i18n.t('files:fileSubType.agreementOilSeparator')],
    [FileSubType.AGREEMENT_PORT, i18n.t('files:fileSubType.agreementPort')],
    [FileSubType.AUDIT_INSPECTION_FIRE_ALARM, i18n.t('files:fileSubType.auditInspactionFireAlarm')],
    [FileSubType.AUDIT_INSPECTION_SPRINKLER, i18n.t('files:fileSubType.auditInspectionSprinkler')],
    [FileSubType.BUILDING_PERMIT_DRAWING, i18n.t('files:fileSubType.buildingPermitDrawing')],
    [FileSubType.CONSTRUCTION_DRAWING, i18n.t('files:fileSubType.constructionDrawing')],
    [FileSubType.CONTRACT_PRESSURE_VESSEL, i18n.t('files:fileSubType.contractPressureVessel')],
    [FileSubType.DOOR, i18n.t('files:fileSubType.door')],
    [FileSubType.ELECTRICITY_AUDIT, i18n.t('files:fileSubType.electricityAudit')],
    [FileSubType.ELEVATOR, i18n.t('files:fileSubType.elevator')],
    [FileSubType.ENERGY_DECLARATION, i18n.t('files:fileSubType.energyDeclaration')],
    [FileSubType.ENVIRONMENTAL_SURVEY, i18n.t('files:fileSubType.environmentalSurvey')],
    [FileSubType.FIRE_ALARM_TRANSMISSION, i18n.t('files:fileSubType.fireAlarmTransmission')],
    [FileSubType.FIRE_PROTECTION, i18n.t('files:fileSubType.fireProtection')],
    [FileSubType.FLOOR_PLAN, i18n.t('files:fileSubType.floorPlan')],
    [FileSubType.GROUND_DRAWING, i18n.t('files:fileSubType.groundDrawing')],
    [FileSubType.INSIDE, i18n.t('files:fileSubType.inside')],
    [FileSubType.INSTALLATION_DRAWING, i18n.t('files:fileSubType.installationDrawing')],
    [FileSubType.LAND_MANAGEMENT, i18n.t('files:fileSubType.landManagement')],
    [FileSubType.LEASEHOLD, i18n.t('files:fileSubType.leasehold')],
    [FileSubType.LIFT_TABLE, i18n.t('files:fileSubType.liftTable')],
    [FileSubType.LOADING_DOCK, i18n.t('files:fileSubType.loadingDock')],
    [FileSubType.LOCK_SCHEME, i18n.t('files:fileSubType.lockScheme')],
    [FileSubType.OIL_SEPARATOR, i18n.t('files:fileSubType.oilSeparator')],
    [FileSubType.OVK, i18n.t('files:fileSubType.OVK')],
    [FileSubType.PESTS, i18n.t('files:fileSubType.pests')],
    [FileSubType.PRESSURE_VESSELS, i18n.t('files:fileSubType.pressureVessels')],
    [FileSubType.PROPERTY_PICTURE, i18n.t('files:fileSubType.propertyPicture')],
    [FileSubType.RECORD_DRAWING, i18n.t('files:fileSubType.recordDrawing')],
    [FileSubType.REFRIGERANT, i18n.t('files:fileSubType.refrigerant')],
    [FileSubType.SBA_SYSTEMATIC_FIRE_PROTECTION_WORK, i18n.t('files:fileSubType.sbaSystematicFireProtectionWork')],
    [FileSubType.WINTER_MAINTENANCE, i18n.t('files:fileSubType.winterMaintenance')],
    [FileSubType.ZONING_PLAN, i18n.t('files:fileSubType.zoningPlan')],
    [FileSubType.SHELTER, i18n.t('files:fileSubType.shelter')],
    [FileSubType.TREATMENT_PLANT, i18n.t('files:fileSubType.treatmentPlant')],
    [FileSubType.ORDER, i18n.t('files:fileSubType.order')],
    [FileSubType.OFFER, i18n.t('files:fileSubType.offer')],
    [FileSubType.FINAL_INSPECTION, i18n.t('files:fileSubType.finalInspection')],
    [FileSubType.COOLING, i18n.t('files:fileSubType.cooling')]
  ]);

  return map.has(subType) ? map.get(subType)! : subType.toString();
};
