import { sortBy } from 'lodash';
import { DropdownItemProps } from 'semantic-ui-react';
import { ListViewPagination, ListViewPaginationParams, PointView, RangeView } from './types';
import i18n from '../i18n';
import {
  ComparableLeaseReductionCreateView,
  ComparableLeaseReductionReadView,
  ComparableLeaseReductionUpdateView,
  ComparablePropertyCreateViewCountryEnum,
  ComparablePropertyReadViewCountryEnum,
  ComparablePropertyUpdateViewCountryEnum
} from '../GeneratedServices';
import { getAllMunicipalities } from './CountryMunicipalityService';

export interface ComparablePropertyListViewParams extends ListViewPaginationParams {
  organisationId?: number;
  minLeaseCount?: number;
  maxLeaseCount?: number;
  date?: string;
  country?: ComparablePropertyCreateViewCountryEnum;
  municipality?: string;
  comparablePropertyType?: ComparablePropertyType;
  owned?: boolean;
  polygon?: PointView[];
}

export interface ComparableLeaseListViewParams extends ListViewPaginationParams {
  comparablePropertyId?: number;
  organisationId?: number;
  sensitive?: boolean;
  minBaseRent?: number;
  maxBaseRent?: number;
  minTotalRent?: number;
  maxTotalRent?: number;
  minBaseRentPerSQM?: number;
  maxBaseRentPerSQM?: number;
  minTotalRentPerSQM?: number;
  maxTotalRentPerSQM?: number;
  minArea?: number;
  maxArea?: number;
  date?: string;
}

export interface ComparableTransactionListViewParams extends ListViewPaginationParams {
  comparablePropertyId?: number;
  organisationId?: number;
  sensitive?: boolean;
  minPricePerSQM?: number;
  maxPricePerSQM?: number;
  minPrice?: number;
  maxPrice?: number;
  minArea?: number;
  maxArea?: number;
  minNOI?: number;
  maxNOI?: number;
  minYield?: number;
  maxYield?: number;
  startDate?: string;
  endDate?: string;
}

export interface ComparablePropertyReadView {
  id: number;
  name: string;
  address: string;
  country: ComparablePropertyReadViewCountryEnum;
  municipality: string;
  type: ComparablePropertyType;
  owned: boolean;
  organisationId: number;
  label?: string;
  position: PointView | null;
}

export interface ComparablePropertyLeaseStatisticsListViewParams {
  comparablePropertyId?: number;
  sensitive?: boolean;
  country?: ComparablePropertyCreateViewCountryEnum;
  municipality?: string;
  comparablePropertyType?: ComparablePropertyType;
  date?: string;
  // These filters are still available, but their indexed counterparts should
  // be used instead. They are commented out to prevent somebody from
  // accidentally using them. Uncomment if needed.
  // minBaseRent?: number;
  // maxBaseRent?: number;
  // minTotalRent?: number;
  // maxTotalRent?: number;
  // minBaseRentPerSQM?: number;
  // maxBaseRentPerSQM?: number;
  // minTotalRentPerSQM?: number;
  // maxTotalRentPerSQM?: number;
  minBaseRentIndexed?: number;
  maxBaseRentIndexed?: number;
  minTotalRentIndexed?: number;
  maxTotalRentIndexed?: number;
  minBaseRentPerSQMIndexed?: number;
  maxBaseRentPerSQMIndexed?: number;
  minTotalRentPerSQMIndexed?: number;
  maxTotalRentPerSQMIndexed?: number;
  minArea?: number;
  maxArea?: number;
  minLeaseCount?: number;
  maxLeaseCount?: number;
  organisationId?: number;
  owned?: boolean;
  polygon?: PointView[];
  municipalities?: string[];
  comparablePropertyTypes?: ComparablePropertyType[];
}

export interface ComparablePropertyLeaseStatisticsListView extends Array<ComparablePropertyLeaseStatisticsReadView> {}

export interface ComparablePropertyTransactionsListViewParams {
  comparablePropertyId?: number;
  organisationId?: number;
  sensitive?: boolean;
  minPricePerSQM?: number;
  maxPricePerSQM?: number;
  minPrice?: number;
  maxPrice?: number;
  minArea?: number;
  maxArea?: number;
  minNOI?: number;
  maxNOI?: number;
  minYield?: number;
  maxYield?: number;
  startDate?: string;
  endDate?: string;
  country?: ComparablePropertyReadViewCountryEnum;
  municipality?: string;
  comparablePropertyType?: ComparablePropertyType;
  polygon?: PointView[];
  municipalities?: string[];
  comparablePropertyTypes?: ComparablePropertyType[];
}

export interface ComparablePropertyTransactionsListView extends Array<ComparablePropertyTransactionReadView> {}

export interface ComparablePropertyListView extends ListViewPagination<ComparablePropertyReadView> {}

export interface ComparableLeaseListView extends ListViewPagination<ComparableLeaseReadView> {}

export interface ComparableTransactionListView extends ListViewPagination<ComparableTransactionReadView> {}

export enum ComparablePropertyType {
  RETAIL = 'RETAIL',
  OTHER = 'OTHER',
  OFFICE = 'OFFICE',
  HOTEL = 'HOTEL',
  RESIDENTIAL = 'RESIDENTIAL',
  LOGISTICS = 'LOGISTICS',
  INDUSTRIAL = 'INDUSTRIAL',
  PARKING = 'PARKING',
  BUILDING_RIGHTS = 'BUILDING_RIGHTS',
  WAREHOUSE = 'WAREHOUSE',
  PUBLIC = 'PUBLIC'
}

export interface ComparableTransactionReadView {
  id: number;
  buyer?: string | null;
  comment?: string | null;
  comparablePropertyId: number;
  date: string;
  priceIsEstimated: boolean;
  noiIsEstimated?: boolean | null;
  yieldIsEstimated?: boolean | null;
  noi?: RangeView | null;
  price: RangeView;
  yield?: RangeView | null;
  seller?: string | null;
  sensitive: boolean;
  source?: string | null;
  area: number;
}

export interface ComparableLeaseReadView {
  id: number;
  lastUpdated: string;
  objectType: string | null;
  area: number;
  yearlyVAT: number | null;
  startDate: string | null;
  endDate: string | null;
  landlordNoticePeriod: number | null;
  tenantNoticePeriod: number | null;
  baseRent: number;
  propertyTax: number | null;
  propertyTaxPercentage: number | null;
  heating: number | null;
  electricity: number | null;
  cooling: number | null;
  water: number | null;
  other: number | null;
  real: boolean;
  indexType: string;
  sensitive: boolean;
  comment: string | null;
  comparablePropertyId: number;
  commercial: boolean;
  extension: number | null;
  totalRent: number;
  totalRentIndexed: number;
  indexBaseYear: number;
  sharePercentage: number;
  tenantName: string | null;
  reductions: ComparableLeaseReductionReadView[];
}

export interface ComparableLeaseCreateView {
  area: number;
  baseRent: number;
  comment?: string | null;
  commercial: boolean;
  comparablePropertyId: number;
  cooling?: number | null;
  electricity?: number | null;
  endDate?: string | null;
  extension?: number | null;
  heating?: number | null;
  indexType: string;
  landlordNoticePeriod?: number | null;
  objectType?: string | null;
  other?: number | null;
  propertyTax?: number | null;
  propertyTaxPercentage?: number | null;
  real: boolean;
  sensitive: boolean;
  startDate?: string | null;
  tenantNoticePeriod?: number | null;
  water?: number | null;
  yearlyVAT?: number | null;
  indexBaseYear: number;
  sharePercentage: number;
  tenantName?: string | null;
  reductions?: ComparableLeaseReductionCreateView[];
}

export interface ComparableLeaseStatisticsReadView {
  tenants: number;
  properties: number;
  area: number;
  baseRent: number;
  baseRentIndexed: number;
  rent: number;
  rentIndexed: number;
  baseRentPerArea: number | null;
  baseRentPerAreaIndexed: number | null;
  rentPerArea: number | null;
  rentPerAreaIndexed: number | null;
  wault: number | null;
  waultIndexed: number | null;
}

export interface ComparableLeaseUpdateView {
  area?: number;
  baseRent?: number;
  comment?: string | null;
  commercial?: boolean;
  comparablePropertyId?: number;
  cooling?: number | null;
  electricity?: number | null;
  endDate?: string | null;
  extension?: number | null;
  heating?: number | null;
  indexType?: string;
  landlordNoticePeriod?: number | null;
  objectType?: string | null;
  other?: number | null;
  propertyTax?: number | null;
  propertyTaxPercentage?: number | null;
  real?: boolean;
  sensitive?: boolean;
  startDate?: string | null;
  tenantNoticePeriod?: number | null;
  water?: number | null;
  yearlyVAT?: number | null;
  indexBaseYear: number;
  sharePercentage?: number;
  tenantName?: string | null;
  reductionsToAdd?: ComparableLeaseReductionCreateView[] | null;
  reductionsToDelete?: number[] | null;
  reductionsToUpdate?: ComparableLeaseReductionUpdateView[] | null;
}
export interface ComparablePropertyCreateView {
  address: string;
  country: ComparablePropertyCreateViewCountryEnum;
  municipality: string;
  name: string;
  organisationId: number;
  type: ComparablePropertyType;
  position?: PointView;
}

export interface ComparablePropertyLeaseStatisticsReadView extends ComparablePropertyReadView {
  leaseStatistics: ComparableLeaseStatisticsReadView;
  totalPropertyLeases: number;
  label?: string;
}

export interface ComparablePropertyTransactionReadView extends ComparablePropertyReadView {
  transactions: ComparableTransactionReadView[];
}

export interface ComparablePropertyUpdateView {
  address?: string;
  country?: ComparablePropertyUpdateViewCountryEnum;
  municipality?: string;
  name?: string;
  type?: ComparablePropertyType;
  position?: PointView;
}

export interface ComparableTransactionCreateView {
  buyer?: string | null;
  comment?: string | null;
  comparablePropertyId: number;
  date: string;
  priceIsEstimated: boolean;
  noiIsEstimated?: boolean | null;
  yieldIsEstimated?: boolean | null;
  noi?: RangeView | null;
  price: RangeView;
  yield?: RangeView | null;
  seller?: string | null;
  sensitive: boolean;
  source?: string | null;
  area: number;
}

export interface ComparableTransactionUpdateView {
  buyer?: string | null;
  comment?: string | null;
  date?: string;
  priceIsEstimated?: boolean;
  noiIsEstimated?: boolean | null;
  yieldIsEstimated?: boolean | null;
  noi?: RangeView | null;
  price?: RangeView;
  yield?: RangeView | null;
  seller?: string | null;
  sensitive?: boolean;
  source?: string | null;
  area?: number;
}

export const translateCountry = (country: ComparablePropertyReadViewCountryEnum): string => {
  const map = new Map([
    [ComparablePropertyReadViewCountryEnum.Sweden, i18n.t('analytics:country.sweden')],
    [ComparablePropertyReadViewCountryEnum.Denmark, i18n.t('analytics:country.denmark')],
    [ComparablePropertyReadViewCountryEnum.Finland, i18n.t('analytics:country.finland')],
    [ComparablePropertyReadViewCountryEnum.Spain, i18n.t('analytics:country.spain')],
    [ComparablePropertyReadViewCountryEnum.UnitedKingdom, i18n.t('analytics:country.unitedKingdom')]
  ]);
  return map.has(country) ? map.get(country)! : country.toString();
};

export const translateComparablePropertyType = (type: ComparablePropertyType): string => {
  const map = new Map([
    [ComparablePropertyType.RETAIL, i18n.t('analytics:comparablePropertyType.retail')],
    [ComparablePropertyType.OTHER, i18n.t('analytics:comparablePropertyType.other')],
    [ComparablePropertyType.OFFICE, i18n.t('analytics:comparablePropertyType.office')],
    [ComparablePropertyType.HOTEL, i18n.t('analytics:comparablePropertyType.hotel')],
    [ComparablePropertyType.RESIDENTIAL, i18n.t('analytics:comparablePropertyType.residential')],
    [ComparablePropertyType.LOGISTICS, i18n.t('analytics:comparablePropertyType.logistics')],
    [ComparablePropertyType.INDUSTRIAL, i18n.t('analytics:comparablePropertyType.industrial')],
    [ComparablePropertyType.PARKING, i18n.t('analytics:comparablePropertyType.parking')],
    [ComparablePropertyType.BUILDING_RIGHTS, i18n.t('analytics:comparablePropertyType.buildingRights')],
    [ComparablePropertyType.WAREHOUSE, i18n.t('analytics:comparablePropertyType.warehouse')],
    [ComparablePropertyType.PUBLIC, i18n.t('analytics:comparablePropertyType.public')]
  ]);

  return map.has(type) ? map.get(type)! : type.toString();
};

export const getMunicipalityOptions = (country?: ComparablePropertyReadViewCountryEnum) =>
  country
    ? getAllMunicipalities(country).map((item) => {
        return {
          text: item,
          value: item,
          key: item
        };
      })
    : [];

const countryValues = Object.values(ComparablePropertyReadViewCountryEnum);
export const countryOptions = countryValues.map((item) => {
  return {
    text: translateCountry(item),
    value: item,
    key: item
  };
});

const propertyTypeValues = Object.values(ComparablePropertyType);
export const propertyOptions = () =>
  sortBy(
    propertyTypeValues.map((item) => {
      return {
        text: translateComparablePropertyType(item),
        value: item,
        key: item
      };
    }),
    'text'
  );

export const normalizeCurrencyFilter = (value: number | undefined, multiplier?: number) => {
  if (value === undefined) return undefined;
  else {
    return Math.round(value * 100 * (multiplier ?? 1));
  }
};

export const mapComparablePropertiesToDropdownItems = (
  properties?: ComparablePropertyReadView[]
): DropdownItemProps[] => {
  if (properties)
    return properties.map((property) => ({
      text: property.name,
      value: property.id,
      key: property.id
    }));
  else return [];
};

export interface ImportComparablesToOrganisationParams {
  organisationId: number;
  blob: Blob;
}

export interface ImportComparablesToPropertyParams {
  comparablePropertyId: number;
  blob: Blob;
}

export interface ImportResultsView {
  created: number;
  updated: number;
  skipped: number;
  failed: number;
  skippedRows: string | null;
  failedRows: string | null;
}
export interface ComparableMunicipalityLeaseStatisticsListView
  extends Array<ComparableMunicipalityLeaseStatisticsReadView> {}

export interface ComparablePropertyTypeLeaseStatisticsReadView {
  type: ComparablePropertyType;
  leaseStatistics: ComparableLeaseStatisticsReadView;
}
export interface ComparableMunicipalityLeaseStatisticsReadView {
  name: string;
  country: ComparablePropertyReadViewCountryEnum;
  propertyTypeStatistics: ComparablePropertyTypeLeaseStatisticsListView;
}

export interface ComparableMunicipalityTransactionStatisticsListView
  extends Array<ComparableMunicipalityTransactionStatisticsReadView> {}

export interface ComparableTransactionStatisticsReadView {
  properties: number;
  averagePricePerArea: number;
  averageNoiPerArea: number | null;
  averageYield: number | null;
  area: number;
}

export interface ComparablePropertyTypeTransactionStatisticsReadView {
  type: ComparablePropertyType;
  transactionStatistics: ComparableTransactionStatisticsReadView;
}
export interface ComparableMunicipalityTransactionStatisticsReadView {
  name: string;
  country: ComparablePropertyReadViewCountryEnum;
  propertyTypeStatistics: ComparablePropertyTypeTransactionStatisticsListView;
}

export interface ComparablePropertyTypeTransactionStatisticsListView
  extends Array<ComparablePropertyTypeTransactionStatisticsReadView> {}

export interface ComparablePropertyTypeLeaseStatisticsListView
  extends Array<ComparablePropertyTypeLeaseStatisticsReadView> {}

export interface ComparablePolygonLeaseStatisticsReadView {
  polygon?: PointView[];
  leaseStatistics: ComparablePropertyTypeLeaseStatisticsListView;
}

export interface ComparablePolygonTransactionStatisticsReadView {
  polygon?: PointView[];
  transactionStatistics: ComparablePropertyTypeTransactionStatisticsListView;
}
