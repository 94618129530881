import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePicker.module.scss';
import { sv, enCA } from 'date-fns/locale';
import i18n from '../../i18n';

const DatePicker: React.FC<ReactDatePickerProps> = (props) => {
  return (
    <ReactDatePicker
      {...props}
      locale={i18n.resolvedLanguage === 'sv' ? sv : enCA}
      className={styles.datePicker}
      wrapperClassName={styles.datePickerWrapper}
      popperClassName={styles.datePickerPopper}
    />
  );
};

export default DatePicker;
