/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { MinimumSupportedMobileVersionReadView } from '../model';
// @ts-ignore
import { MinimumSupportedMobileVersionUpdateView } from '../model';
/**
 * MinimumSupportedMobileVersionApi - axios parameter creator
 * @export
 */
export const MinimumSupportedMobileVersionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Read the minimum supported mobile versions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    minimumSupportedMobileVersionResourceRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/minimum-supported-mobile-version`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a minimum supported mobile version
     * @param {MinimumSupportedMobileVersionUpdateView} [minimumSupportedMobileVersionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    minimumSupportedMobileVersionResourceUpdate: async (
      minimumSupportedMobileVersionUpdateView?: MinimumSupportedMobileVersionUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/minimum-supported-mobile-version`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        minimumSupportedMobileVersionUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * MinimumSupportedMobileVersionApi - functional programming interface
 * @export
 */
export const MinimumSupportedMobileVersionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MinimumSupportedMobileVersionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Read the minimum supported mobile versions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async minimumSupportedMobileVersionResourceRead(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimumSupportedMobileVersionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.minimumSupportedMobileVersionResourceRead(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a minimum supported mobile version
     * @param {MinimumSupportedMobileVersionUpdateView} [minimumSupportedMobileVersionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async minimumSupportedMobileVersionResourceUpdate(
      minimumSupportedMobileVersionUpdateView?: MinimumSupportedMobileVersionUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimumSupportedMobileVersionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.minimumSupportedMobileVersionResourceUpdate(
        minimumSupportedMobileVersionUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * MinimumSupportedMobileVersionApi - factory interface
 * @export
 */
export const MinimumSupportedMobileVersionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MinimumSupportedMobileVersionApiFp(configuration);
  return {
    /**
     *
     * @summary Read the minimum supported mobile versions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    minimumSupportedMobileVersionResourceRead(options?: any): AxiosPromise<MinimumSupportedMobileVersionReadView> {
      return localVarFp.minimumSupportedMobileVersionResourceRead(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a minimum supported mobile version
     * @param {MinimumSupportedMobileVersionUpdateView} [minimumSupportedMobileVersionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    minimumSupportedMobileVersionResourceUpdate(
      minimumSupportedMobileVersionUpdateView?: MinimumSupportedMobileVersionUpdateView,
      options?: any
    ): AxiosPromise<MinimumSupportedMobileVersionReadView> {
      return localVarFp
        .minimumSupportedMobileVersionResourceUpdate(minimumSupportedMobileVersionUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for minimumSupportedMobileVersionResourceUpdate operation in MinimumSupportedMobileVersionApi.
 * @export
 * @interface MinimumSupportedMobileVersionApiMinimumSupportedMobileVersionResourceUpdateRequest
 */
export interface MinimumSupportedMobileVersionApiMinimumSupportedMobileVersionResourceUpdateRequest {
  /**
   *
   * @type {MinimumSupportedMobileVersionUpdateView}
   * @memberof MinimumSupportedMobileVersionApiMinimumSupportedMobileVersionResourceUpdate
   */
  readonly minimumSupportedMobileVersionUpdateView?: MinimumSupportedMobileVersionUpdateView;
}

/**
 * MinimumSupportedMobileVersionApi - object-oriented interface
 * @export
 * @class MinimumSupportedMobileVersionApi
 * @extends {BaseAPI}
 */
export class MinimumSupportedMobileVersionApi extends BaseAPI {
  /**
   *
   * @summary Read the minimum supported mobile versions.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinimumSupportedMobileVersionApi
   */
  public minimumSupportedMobileVersionResourceRead(options?: AxiosRequestConfig) {
    return MinimumSupportedMobileVersionApiFp(this.configuration)
      .minimumSupportedMobileVersionResourceRead(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a minimum supported mobile version
   * @param {MinimumSupportedMobileVersionApiMinimumSupportedMobileVersionResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinimumSupportedMobileVersionApi
   */
  public minimumSupportedMobileVersionResourceUpdate(
    requestParameters: MinimumSupportedMobileVersionApiMinimumSupportedMobileVersionResourceUpdateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return MinimumSupportedMobileVersionApiFp(this.configuration)
      .minimumSupportedMobileVersionResourceUpdate(requestParameters.minimumSupportedMobileVersionUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
