import React from 'react';
import { BusinessPlanIndexReadView, BusinessPlanIndexReadViewIndexTypeEnum } from '../../../../../GeneratedServices';
import { globalStateCTX } from '../../../../../GlobalState/GlobalState';
import { businessPlanApi, createInfinitePaginationParams } from '../../../../../Http/Http';
import { UserFriendlyApiResponse } from '../../../../../Http/response-error';
import { applyApiResponseValidationToFields, FieldValidationResult } from '../../../../../Utils/validation';
import pLimit from 'p-limit';

export interface BusinessPlanIndexEntity extends BusinessPlanIndexReadView {
  newValue?: number;
  validationResult?: FieldValidationResult;
}

type BusinessPlanIndexState = Record<BusinessPlanIndexReadViewIndexTypeEnum, BusinessPlanIndexEntity[]>;
type BusinessPlanIndexAction =
  | { type: 'INIT'; items: BusinessPlanIndexState }
  | {
      type: 'VALUE_CHANGE';
      id: number;
      indexType: BusinessPlanIndexReadViewIndexTypeEnum;
      value?: number;
    }
  | {
      type: 'VALIDATION_CHANGE';
      id: number;
      indexType: BusinessPlanIndexReadViewIndexTypeEnum;
      validationResult?: FieldValidationResult;
    }
  | {
      type: 'VALUE_RESET';
      indexType: BusinessPlanIndexReadViewIndexTypeEnum;
    };

function businessPlanIndexReducer(
  state: BusinessPlanIndexState,
  action: BusinessPlanIndexAction
): BusinessPlanIndexState {
  switch (action.type) {
    case 'INIT':
      return action.items;

    case 'VALUE_CHANGE':
      return {
        ...state,
        [action.indexType]: state[action.indexType].map((item) => {
          let newValue = action.value;
          return item.id === action.id
            ? {
                ...item,
                newValue,
                validationResult: undefined
              }
            : item;
        })
      };
    case 'VALIDATION_CHANGE':
      return {
        ...state,
        [action.indexType]: state[action.indexType].map((item) =>
          item.id === action.id
            ? {
                ...item,
                validationResult: action.validationResult
              }
            : item
        )
      };
    case 'VALUE_RESET':
      return {
        ...state,
        [action.indexType]: state[action.indexType].map((item) => {
          return {
            ...item,
            newValue: item.value,
            validationResult: undefined
          };
        })
      };
  }
}

export enum BusinessPlanIndexStatus {
  None,
  Saving,
  Loading,
  LoadError
}

export const useBusinessPlanIndexesReducer = (props: { propertyId: number; shouldIndexesReload: number }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const [inflationIndicesStatus, setInflationIndicesStatus] = React.useState<BusinessPlanIndexStatus>(
    BusinessPlanIndexStatus.None
  );
  const [rentalGrowthIndicesStatus, setRentalGrowthIndicesStatus] = React.useState<BusinessPlanIndexStatus>(
    BusinessPlanIndexStatus.None
  );

  const [businessPlanIndexes, dispatchBusinessPlanIndexes] = React.useReducer(businessPlanIndexReducer, {
    [BusinessPlanIndexReadViewIndexTypeEnum.Inflation]: [],
    [BusinessPlanIndexReadViewIndexTypeEnum.RentalGrowth]: []
  });

  React.useEffect(() => {
    const abortController = new AbortController();
    const load = async () => {
      try {
        setInflationIndicesStatus(BusinessPlanIndexStatus.Loading);
        setRentalGrowthIndicesStatus(BusinessPlanIndexStatus.Loading);

        const { data: indexes } = await businessPlanApi.businessPlanIndexResourceList(
          {
            ...createInfinitePaginationParams(),
            propertyId: props.propertyId
          },
          { signal: abortController.signal }
        );

        dispatchBusinessPlanIndexes({
          type: 'INIT',
          items: indexes.records.reduce(
            (result, element) => {
              result[element.indexType].push({ ...element, newValue: element.value });
              return result;
            },
            {
              [BusinessPlanIndexReadViewIndexTypeEnum.Inflation]: [],
              [BusinessPlanIndexReadViewIndexTypeEnum.RentalGrowth]: []
            } as Record<BusinessPlanIndexReadViewIndexTypeEnum, BusinessPlanIndexEntity[]>
          )
        });
        setInflationIndicesStatus(BusinessPlanIndexStatus.None);
        setRentalGrowthIndicesStatus(BusinessPlanIndexStatus.None);
      } catch (error) {
        if (handleHttpErrors(error)) {
          setInflationIndicesStatus(BusinessPlanIndexStatus.LoadError);
          setRentalGrowthIndicesStatus(BusinessPlanIndexStatus.LoadError);
        }
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [props.propertyId, handleHttpErrors, props.shouldIndexesReload]);

  const handleResponseValidationError = (resp: UserFriendlyApiResponse, item: BusinessPlanIndexEntity) => {
    const fieldIdMapping = [
      {
        formFieldId: 'value',
        setStateFunc: (data: any) => {
          dispatchBusinessPlanIndexes({
            id: item.id,
            type: 'VALIDATION_CHANGE',
            indexType: item.indexType,
            validationResult: data
          });
        }
      }
    ];

    applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  const saveBusinessPlanIndexes = async (type: BusinessPlanIndexReadViewIndexTypeEnum) => {
    type === BusinessPlanIndexReadViewIndexTypeEnum.Inflation
      ? setInflationIndicesStatus(BusinessPlanIndexStatus.Saving)
      : setRentalGrowthIndicesStatus(BusinessPlanIndexStatus.Saving);
    const limit = pLimit(2);

    const responses = await Promise.all(
      businessPlanIndexes[type].map(async (item) => {
        if (item.value === item.newValue) return true;
        return limit(async () => {
          try {
            return await businessPlanApi.businessPlanIndexResourceUpdate({
              id: item.id,
              businessPlanIndexUpdateView: {
                value: item.newValue ?? 0
              }
            });
          } catch (error) {
            handleHttpErrors(error, {
              handleResponseValidationError: (resp) => {
                handleResponseValidationError(resp, item);
              }
            });
            return false;
          }
        });
      })
    );

    type === BusinessPlanIndexReadViewIndexTypeEnum.Inflation
      ? setInflationIndicesStatus(BusinessPlanIndexStatus.None)
      : setRentalGrowthIndicesStatus(BusinessPlanIndexStatus.None);
    return responses.every((solved) => solved);
  };

  return {
    businessPlanIndexes,
    dispatchBusinessPlanIndexes,
    inflationIndicesStatus,
    rentalGrowthIndicesStatus,
    saveBusinessPlanIndexes
  };
};
