import { AxiosRequestConfig } from 'axios';
import { BAM_API_BASE_URL } from '../config';
import { http } from '../Http/Http';
import { SearchListView, SearchListViewParams } from './SearchService.types';

export const getSearchResults = async (
  params: SearchListViewParams,
  config?: AxiosRequestConfig
): Promise<SearchListView> => {
  const { data } = await http.get<SearchListView>(`${BAM_API_BASE_URL}/v1/search`, {
    ...config,
    params
  });
  return data;
};
