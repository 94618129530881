import { DropdownProps } from 'semantic-ui-react';
import { GovernmentInspectionItemResultUpdateView } from '../../../GeneratedServices';
import { DeepPartial } from '../../../Services/types';
import { FieldValidationResult } from '../../../Utils/validation';
import { v4 as uuid } from 'uuid';
import i18n from 'i18next';

export const validateRequired = (value: DropdownProps['value']): FieldValidationResult | undefined => {
  if (!value) return { error: true, errorMessage: [i18n.t('validation:fieldError.required')] };
};

type InspectionItemResultEntity = DeepPartial<GovernmentInspectionItemResultUpdateView> & {
  uuid: string;
  validationResults: Record<keyof GovernmentInspectionItemResultUpdateView, FieldValidationResult | undefined>;
};

export type InspectionItemResultState = InspectionItemResultEntity[];
type InspectionItemResultAction =
  | { type: 'INIT'; data: InspectionItemResultState }
  | { type: 'ADD_BLANK' }
  | { type: 'EDIT_ITEM'; item: InspectionItemResultEntity }
  | { type: 'EDIT_ALL'; data: Omit<GovernmentInspectionItemResultUpdateView, 'itemId'> }
  | { type: 'REMOVE_ITEM'; uuid: string };

export function inspectionItemResultsReducer(
  state: InspectionItemResultState,
  action: InspectionItemResultAction
): InspectionItemResultState {
  switch (action.type) {
    case 'INIT':
      return action.data;
    case 'ADD_BLANK':
      return state.concat({
        uuid: uuid(),
        validationResults: {
          itemId: validateRequired(undefined),
          passed: validateRequired(undefined),
          performed: validateRequired(undefined),
          nextInspectionDeadline: validateRequired(undefined),
          comment: undefined
        }
      });
    case 'EDIT_ITEM':
      return state.map((item) => (item.uuid === action.item.uuid ? action.item : item));
    case 'EDIT_ALL':
      return state.map((item) => ({
        itemId: item.itemId,
        uuid: item.uuid,
        validationResults: {
          itemId: undefined,
          passed: undefined,
          performed: undefined,
          nextInspectionDeadline: undefined,
          comment: undefined
        },
        ...action.data
      }));
    case 'REMOVE_ITEM':
      return state.filter((item) => item.uuid !== action.uuid);
  }
}
