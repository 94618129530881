import React from 'react';
import { Container, Header } from 'semantic-ui-react';

interface Props {
  id?: string;
  header?: string | JSX.Element;
  children: any;
  headerRightComponent?: JSX.Element;
  subheader?: string | JSX.Element;
}

const MainContainer: React.FC<Props> = (props) => {
  return (
    <Container id={props.id} fluid>
      {props.header && (
        <Container fluid style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em' }}>
          <Header as="h1" style={{ whiteSpace: 'nowrap' }}>
            {props.header}

            <Header.Subheader>{props.subheader}</Header.Subheader>
          </Header>
          {props.headerRightComponent}
        </Container>
      )}

      {props.children}
    </Container>
  );
};

export default MainContainer;
