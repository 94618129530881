/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AccountReadView
 */
export interface AccountReadView {
  /**
   * Account NOI type.
   * @type {string}
   * @memberof AccountReadView
   */
  accountNOIType: AccountReadViewAccountNOITypeEnum;
  /**
   * Account number.
   * @type {number}
   * @memberof AccountReadView
   */
  accountNumber: number;
  /**
   * Account type.
   * @type {string}
   * @memberof AccountReadView
   */
  accountType: AccountReadViewAccountTypeEnum;
  /**
   * Account comment.
   * @type {string}
   * @memberof AccountReadView
   */
  comment: string | null;
  /**
   * Account ID.
   * @type {number}
   * @memberof AccountReadView
   */
  id: number;
  /**
   * Account name.
   * @type {string}
   * @memberof AccountReadView
   */
  name: string;
  /**
   * Account specification.
   * @type {string}
   * @memberof AccountReadView
   */
  specification: AccountReadViewSpecificationEnum;
  /**
   * If account is used.
   * @type {boolean}
   * @memberof AccountReadView
   */
  used: boolean;
}

export const AccountReadViewAccountNOITypeEnum = {
  Income: 'INCOME',
  Maintenance: 'MAINTENANCE',
  Management: 'MANAGEMENT',
  NotInNoi: 'NOT_IN_NOI',
  Opex: 'OPEX',
  Asset: 'ASSET',
  Debt: 'DEBT',
  PropertyTax: 'PROPERTY_TAX'
} as const;

export type AccountReadViewAccountNOITypeEnum =
  (typeof AccountReadViewAccountNOITypeEnum)[keyof typeof AccountReadViewAccountNOITypeEnum];
export const AccountReadViewAccountTypeEnum = {
  Income: 'INCOME',
  Cost: 'COST',
  Asset: 'ASSET',
  Debt: 'DEBT'
} as const;

export type AccountReadViewAccountTypeEnum =
  (typeof AccountReadViewAccountTypeEnum)[keyof typeof AccountReadViewAccountTypeEnum];
export const AccountReadViewSpecificationEnum = {
  AmOverhead: 'AM_OVERHEAD',
  Cleaning: 'CLEANING',
  Cooling: 'COOLING',
  CorporateManagementNotInNoi: 'CORPORATE_MANAGEMENT_NOT_IN_NOI',
  Depreciations: 'DEPRECIATIONS',
  Discounts: 'DISCOUNTS',
  Electricity: 'ELECTRICITY',
  FacilityPropertyManInNoi: 'FACILITY_PROPERTY_MAN_IN_NOI',
  Waste: 'WASTE',
  Heating: 'HEATING',
  Insurance: 'INSURANCE',
  InterestCost: 'INTEREST_COST',
  InterestIncome: 'INTEREST_INCOME',
  LeaseholdFee: 'LEASEHOLD_FEE',
  LettingFees: 'LETTING_FEES',
  Marketing: 'MARKETING',
  OtherCosts: 'OTHER_COSTS',
  OtherIncome: 'OTHER_INCOME',
  OtherOperatingExpenses: 'OTHER_OPERATING_EXPENSES',
  PeriodicMaintenance: 'PERIODIC_MAINTENANCE',
  ProvisionsReceivables: 'PROVISIONS_RECEIVABLES',
  RealEstateTaxes: 'REAL_ESTATE_TAXES',
  Rent: 'RENT',
  Repairs: 'REPAIRS',
  Security: 'SECURITY',
  ServiceAgreements: 'SERVICE_AGREEMENTS',
  SnowRemovalAndGarden: 'SNOW_REMOVAL_AND_GARDEN',
  Supplements: 'SUPPLEMENTS',
  Water: 'WATER',
  CapexTi: 'CAPEX_TI'
} as const;

export type AccountReadViewSpecificationEnum =
  (typeof AccountReadViewSpecificationEnum)[keyof typeof AccountReadViewSpecificationEnum];
