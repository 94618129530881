/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { PropertySubscriptionCreateView } from '../model';
// @ts-ignore
import { PropertySubscriptionListView } from '../model';
// @ts-ignore
import { PropertySubscriptionReadView } from '../model';
/**
 * PropertySubscriptionApi - axios parameter creator
 * @export
 */
export const PropertySubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a property subscription
     * @param {PropertySubscriptionCreateView} [propertySubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertySubscriptionResourceCreate: async (
      propertySubscriptionCreateView?: PropertySubscriptionCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/property-subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        propertySubscriptionCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a property subscription
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertySubscriptionResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('propertySubscriptionResourceDelete', 'id', id);
      const localVarPath = `/v1/property-subscriptions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List property subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertySubscriptionResourceList: async (
      limit?: number,
      offset?: number,
      propertyId?: number,
      userId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/property-subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * PropertySubscriptionApi - functional programming interface
 * @export
 */
export const PropertySubscriptionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PropertySubscriptionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a property subscription
     * @param {PropertySubscriptionCreateView} [propertySubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertySubscriptionResourceCreate(
      propertySubscriptionCreateView?: PropertySubscriptionCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertySubscriptionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertySubscriptionResourceCreate(
        propertySubscriptionCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a property subscription
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertySubscriptionResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertySubscriptionResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List property subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async propertySubscriptionResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      userId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertySubscriptionListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.propertySubscriptionResourceList(
        limit,
        offset,
        propertyId,
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * PropertySubscriptionApi - factory interface
 * @export
 */
export const PropertySubscriptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PropertySubscriptionApiFp(configuration);
  return {
    /**
     *
     * @summary Create a property subscription
     * @param {PropertySubscriptionCreateView} [propertySubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertySubscriptionResourceCreate(
      propertySubscriptionCreateView?: PropertySubscriptionCreateView,
      options?: any
    ): AxiosPromise<PropertySubscriptionReadView> {
      return localVarFp
        .propertySubscriptionResourceCreate(propertySubscriptionCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a property subscription
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertySubscriptionResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.propertySubscriptionResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List property subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertySubscriptionResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      userId?: number,
      options?: any
    ): AxiosPromise<PropertySubscriptionListView> {
      return localVarFp
        .propertySubscriptionResourceList(limit, offset, propertyId, userId, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for propertySubscriptionResourceCreate operation in PropertySubscriptionApi.
 * @export
 * @interface PropertySubscriptionApiPropertySubscriptionResourceCreateRequest
 */
export interface PropertySubscriptionApiPropertySubscriptionResourceCreateRequest {
  /**
   *
   * @type {PropertySubscriptionCreateView}
   * @memberof PropertySubscriptionApiPropertySubscriptionResourceCreate
   */
  readonly propertySubscriptionCreateView?: PropertySubscriptionCreateView;
}

/**
 * Request parameters for propertySubscriptionResourceDelete operation in PropertySubscriptionApi.
 * @export
 * @interface PropertySubscriptionApiPropertySubscriptionResourceDeleteRequest
 */
export interface PropertySubscriptionApiPropertySubscriptionResourceDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof PropertySubscriptionApiPropertySubscriptionResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for propertySubscriptionResourceList operation in PropertySubscriptionApi.
 * @export
 * @interface PropertySubscriptionApiPropertySubscriptionResourceListRequest
 */
export interface PropertySubscriptionApiPropertySubscriptionResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof PropertySubscriptionApiPropertySubscriptionResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof PropertySubscriptionApiPropertySubscriptionResourceList
   */
  readonly offset?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof PropertySubscriptionApiPropertySubscriptionResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by user
   * @type {number}
   * @memberof PropertySubscriptionApiPropertySubscriptionResourceList
   */
  readonly userId?: number;
}

/**
 * PropertySubscriptionApi - object-oriented interface
 * @export
 * @class PropertySubscriptionApi
 * @extends {BaseAPI}
 */
export class PropertySubscriptionApi extends BaseAPI {
  /**
   *
   * @summary Create a property subscription
   * @param {PropertySubscriptionApiPropertySubscriptionResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertySubscriptionApi
   */
  public propertySubscriptionResourceCreate(
    requestParameters: PropertySubscriptionApiPropertySubscriptionResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PropertySubscriptionApiFp(this.configuration)
      .propertySubscriptionResourceCreate(requestParameters.propertySubscriptionCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a property subscription
   * @param {PropertySubscriptionApiPropertySubscriptionResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertySubscriptionApi
   */
  public propertySubscriptionResourceDelete(
    requestParameters: PropertySubscriptionApiPropertySubscriptionResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return PropertySubscriptionApiFp(this.configuration)
      .propertySubscriptionResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List property subscriptions
   * @param {PropertySubscriptionApiPropertySubscriptionResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertySubscriptionApi
   */
  public propertySubscriptionResourceList(
    requestParameters: PropertySubscriptionApiPropertySubscriptionResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PropertySubscriptionApiFp(this.configuration)
      .propertySubscriptionResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.propertyId,
        requestParameters.userId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
