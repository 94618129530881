import { Link } from 'react-router-dom';
import React from 'react';
import { Button, Header, Label, Loader, Message, Modal, Tab, Table } from 'semantic-ui-react';
import FileUploadModal from '../../../FileUpload/FileUploadModal';
import LoadError from '../../../LoadError';
import PropertyCard from '../../../PropertyCard/PropertyCard';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { FileSubType, FileType } from '../../../../Services/FileService.types';
import { useContractDetails } from './Hooks/useContractDetails';
import { useContractPayments } from './Hooks/useContractPayments';
import styles from './ContractModal.module.scss';
import NumberFormater from '../../../../Components/NumberFormater/NumberFormater';
import { formatCurrency, toMonthlyOrYearly } from '../../../../Utils/number';
import TableHeaderCellContent from '../../../TableHeaderCell/TableHeaderCell';
import { ContractListItemView, ContractPaymentReadView, TenantReadView } from '../../../../GeneratedServices';
import { useNonce } from '../../../../Utils/hooks';
import { hasPermittedWriteRoleInOrganisation, Role } from '../../../../Utils/permissions';
import { useContractFiles } from './Hooks/useContractFiles';
import {
  getContractStatusContentAndColor,
  translateAdditionalType,
  translateAreaType
} from '../../../../Services/ContractService.types';
import { tenantApi } from '../../../../Http/Http';
import { translateObjectType } from '../../../../Services/BusinessPlanService.types';
import PieCardContent from '../../../PieCard/PieCardContent';
import { useTranslation } from 'react-i18next';
import { MembershipReadView } from '../../../../Services/MembershipService.types';
import LeaseStatusLabel from '../../../../Components/LeaseTable/Components/LeaseStatusLabel';
import ListLabel from '../../../ListLabel/ListLabel';
import { sortObjects } from '../../../../Utils/array';
import { SortDirection } from '../../../SortableTable/SortableTable';
import FileSubTypeAccordion from '../../../FileSubTypeAccordion/FileSubTypeAccordion';

interface Props {
  contractId: number;
  close: () => void;
}

export enum ContractStatus {
  None,
  Loading,
  LoadError
}

const hasPermissionToViewPayments = (
  contract: ContractListItemView,
  isSuperuser?: boolean,
  currentUserMemberships?: MembershipReadView[]
) => {
  const organisationRole = currentUserMemberships?.find(
    (item) => item.organisationId === contract.organisationId
  )?.role;
  if (isSuperuser || (organisationRole && [Role.Admin, Role.Investor, Role.Manager].includes(organisationRole)))
    return true;
  else return false;
};

const getSortedPayments = (payments: ContractPaymentReadView[]) => {
  // Sort contracts with null end date first and sort others by start date.
  const { paymentsWithoutEnd, otherPayments } = payments.reduce(
    (result, element) => {
      result[!element.endDate ? 'paymentsWithoutEnd' : 'otherPayments'].push(element);
      return result;
    },
    { paymentsWithoutEnd: [], otherPayments: [] } as {
      paymentsWithoutEnd: ContractPaymentReadView[];
      otherPayments: ContractPaymentReadView[];
    }
  );

  return sortObjects(paymentsWithoutEnd, 'startDate', SortDirection.ASC).concat(
    sortObjects(otherPayments, 'startDate', SortDirection.ASC)
  );
};

const ContractModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts', 'properties']);

  const [contractId, setContractId] = React.useState<number>(props.contractId);

  const { currentUser, currentUserMemberships } = React.useContext(globalStateCTX);

  const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [tenants, setTenants] = React.useState<TenantReadView[]>();
  const [tenantsStatus, setTenantsStatus] = React.useState<ContractStatus>(ContractStatus.Loading);
  const [shouldReloadTenants, reloadTenants] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setTenantsStatus(ContractStatus.Loading);

        const { data } = await tenantApi.tenantResourceList(
          {
            ongoingContractId: contractId
          },
          {
            signal: abortController.signal
          }
        );

        setTenants(data.records);
        setTenantsStatus(ContractStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setTenantsStatus(ContractStatus.LoadError);
      }
    };
    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, contractId, shouldReloadTenants]);

  const {
    reloadContractInfo,
    contract,
    property,
    status: contractStatus,
    propertyPictureUrl,
    portfolios
  } = useContractDetails({ contractId: contractId });

  const { contractPayments, status: paymentsStatus, reloadPayments } = useContractPayments({ contractId: contractId });

  const { files, reloadFiles, status: filesStatus } = useContractFiles({ contractId: contractId });

  const isPermitted = hasPermittedWriteRoleInOrganisation(
    currentUser,
    currentUserMemberships,
    contract?.organisationId
  );

  const getLayout = (
    status: ContractStatus,
    layoutLoading: JSX.Element,
    layoutLoadError: JSX.Element,
    layout: JSX.Element
  ) => {
    switch (status) {
      case ContractStatus.Loading:
        return layoutLoading;

      case ContractStatus.LoadError:
        return layoutLoadError;

      case ContractStatus.None:
        return layout;
    }
  };

  const layoutLoading = (
    <div className={styles.loaderContainer}>
      <Loader active size="medium" inline />
    </div>
  );

  const contractDetailsLayoutLoadError = (
    <LoadError message={t('contracts:unableToLoadTheContract')} retry={reloadContractInfo} />
  );

  const contractPaymentsLoadErrorLayout = (
    <LoadError message={t('contracts:unableToLoadPayments')} retry={reloadPayments} />
  );

  const contractTenantsLoadErrorLayout = (
    <LoadError message={t('contracts:unableToLoadTenants')} retry={reloadTenants} />
  );

  const contractFilesLoadErrorLayout = <LoadError message={t('contracts:unableToLoadFiles')} retry={reloadFiles} />;

  const contractTenantsLayout =
    tenants?.length !== 0 ? (
      <Table basic="very" singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common:name')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:phone')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:email')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tenants?.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Link to={`/tenants/${item.id}`}>{item.name}</Link>
                </Table.Cell>
                <Table.Cell>{item.phone}</Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    ) : (
      <Message>{t('contracts:noTenantFound')}</Message>
    );

  const contractFilesLayout = (
    <FileSubTypeAccordion
      files={files ?? []}
      reloadFiles={reloadFiles}
      emptyLayout={<Message>{t('contracts:noFilesFound')}</Message>}
      readOnly={!isPermitted}
    />
  );

  const contractPaymentsLayout =
    contract && contractPayments && contractPayments.length !== 0 ? (
      <Table basic="very" singleLine className={styles.borderlessTable}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common:type')}</Table.HeaderCell>
            <Table.HeaderCell>{t('contracts:start')}</Table.HeaderCell>
            <Table.HeaderCell>{t('contracts:end')}</Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('contracts:amount')} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getSortedPayments(contractPayments).map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  {item.additional
                    ? translateAdditionalType(item.additionalType)
                    : t('common:baseRent') + ' - ' + translateAreaType(item.areaType)}
                </Table.Cell>
                <Table.Cell>{item.startDate}</Table.Cell>
                <Table.Cell>{item.endDate}</Table.Cell>
                <Table.Cell>
                  {item.indexedAmount && (
                    <NumberFormater
                      {...formatCurrency(toMonthlyOrYearly(item.indexedAmount, item.additional), {
                        unit: `SEK/${
                          item.additional ? t('common:month').toLowerCase() : t('common:year').toLowerCase()
                        }`,
                        forceCurrency: 'SEK'
                      })}
                      additionalProps={{
                        allowNegative: true
                      }}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row className={styles.lastRow}>
            <Table.Cell>{t('contracts:currentTotal')}</Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              {contract.totalRentIndexed && (
                <NumberFormater
                  {...formatCurrency(contract.totalRentIndexed, {
                    unit: 'SEK',
                    forceCurrency: 'SEK'
                  })}
                />
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    ) : (
      <Message>{t('contracts:noPaymentsFound')}</Message>
    );

  const dataForGraph = React.useMemo(() => {
    const areaTypes = Array.from(
      new Set(contractPayments?.filter((item) => !item.additional).map((item) => item.areaType))
    );

    return areaTypes.map((item) => {
      const paymentForAreaType = contractPayments?.filter((payments) => payments.areaType === item);
      let value = 0;
      paymentForAreaType?.forEach((payment) => (value += payment.area ?? 0));

      return {
        id: item,
        label: translateAreaType(item),
        value,
        suffix: ' kvm'
      };
    });
  }, [contractPayments]);

  const ParkedLeaseMessage = () => (
    <div className={styles.infoMessageWrapper}>
      <Header as="h4">{t('contracts:objectNoLongerInUse')}</Header>
      <div>{t('contracts:objectNoLongerInUseMessage')}</div>
    </div>
  );

  const contractDetailsLayout = () => {
    const workOrdersCount = property ? property.receivedWorkOrdersCount + property.startedWorkOrdersCount : 0;

    return (
      <>
        <div className={styles.contractInfoContainer}>
          <PropertyCard
            name={property?.name ?? ''}
            pictureUrl={propertyPictureUrl}
            imageWidth={300}
            labels={
              <>
                <ListLabel list={portfolios ?? []} displayValueFunction={(data) => data.name} />
                <Label size="large">{t('properties:faultReports', { count: workOrdersCount })}</Label>
              </>
            }
          />

          <div className={styles.tableContainer}>
            <Table basic="very" className={styles.table} compact singleLine>
              <Table.Body>
                <Table.Row className={styles.tableHeaderRow}>
                  <Table.Cell>{t('contracts:start')}</Table.Cell>
                  <Table.Cell>{t('contracts:extends')}</Table.Cell>
                  <Table.Cell>{t('contracts:end')}</Table.Cell>
                  <Table.Cell collapsing>{t('contracts:noticePeriod')}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>{contract?.moveInDate}</Table.Cell>
                  <Table.Cell>{contract?.terminationDate}</Table.Cell>
                  <Table.Cell>{contract?.endDate}</Table.Cell>
                  <Table.Cell collapsing>{contract?.terminationPeriod} mån</Table.Cell>
                </Table.Row>

                <Table.Row className={styles.tableHeaderRow}>
                  <Table.Cell>{t('common:type')}</Table.Cell>
                  <Table.Cell>{t('common:lettableArea')}</Table.Cell>
                  <Table.Cell>{t('common:index')}</Table.Cell>
                  <Table.Cell collapsing>{t('common:salesTax')}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>{contract && translateObjectType(contract.unitObjectType)}</Table.Cell>
                  <Table.Cell>
                    {contract?.area} {` ${t('common:sqm')}`}
                  </Table.Cell>
                  <Table.Cell>{contract?.primaryIndexType ?? '-'}</Table.Cell>
                  <Table.Cell collapsing>{contract?.vat ? t('common:yes') : t('common:no')}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>

        {contract && !contract.isParked ? (
          <>
            <div className={styles.middleSection}>
              <div className={styles.tenants}>
                <Header as="h4">{t('common:tenants')}</Header>
                {getLayout(tenantsStatus, layoutLoading, contractTenantsLoadErrorLayout, contractTenantsLayout)}
              </div>

              <div className={styles.areaDistribution}>
                <Header as="h4">{t('contracts:areaDistribution')}</Header>
                <PieCardContent
                  data={dataForGraph}
                  centerText={{
                    suffix: t('common:sqm'),
                    value: contract?.area
                  }}
                />
              </div>
            </div>

            {hasPermissionToViewPayments(contract, currentUser?.isSuperuser, currentUserMemberships) && (
              <>
                <Header as="h4">{t('contracts:incomes')}</Header>
                {getLayout(paymentsStatus, layoutLoading, contractPaymentsLoadErrorLayout, contractPaymentsLayout)}
              </>
            )}
          </>
        ) : (
          <ParkedLeaseMessage />
        )}
      </>
    );
  };

  const panes = [
    {
      menuItem: t('common:details'),
      render: () => (
        <Tab.Pane className={styles.scrollingContainer}>
          {getLayout(contractStatus, layoutLoading, contractDetailsLayoutLoadError, contractDetailsLayout())}
        </Tab.Pane>
      )
    },
    {
      menuItem: t('common:files'),
      render: () => (
        <Tab.Pane className={styles.scrollingContainer}>
          {getLayout(filesStatus, layoutLoading, contractFilesLoadErrorLayout, contractFilesLayout)}
        </Tab.Pane>
      )
    }
  ];

  const leaseStatus = React.useMemo(() => contract && getContractStatusContentAndColor(contract), [contract]);
  return (
    <>
      <Modal open closeOnEscape={false} closeOnDimmerClick={false} onClose={props.close} closeIcon>
        <Modal.Header className={styles.header}>
          {t('common:lease')} {contract && `${contract.strifastPropertyId}-${contract.strifastObjectId}`}
          {leaseStatus && <LeaseStatusLabel {...leaseStatus} />}
          <div className={styles.linkContainer}>
            {contract?.previousContractId && (
              <div onClick={() => setContractId(contract.previousContractId!)}>{t('contracts:openPreviousLease')}</div>
            )}
            {contract?.upcomingContractId && (
              <div onClick={() => setContractId(contract.upcomingContractId!)}>{t('contracts:openFutureLease')}</div>
            )}
          </div>
        </Modal.Header>
        <Modal.Content>
          <Tab menu={{ secondary: true, pointing: true, size: 'large' }} panes={panes} />
        </Modal.Content>
        {isPermitted && (
          <Modal.Actions>
            <Button primary onClick={() => setShowFileUploadModal(true)}>
              {t('common:uploadFiles')}
            </Button>
          </Modal.Actions>
        )}
      </Modal>

      {showFileUploadModal && (
        <FileUploadModal
          entityType="CONTRACT"
          entityId={contractId}
          defaultFileType={FileType.LEASE}
          defaultFileSubType={FileSubType.AGREEMENT}
          onClose={(filesUploaded) => {
            setShowFileUploadModal(false);
            if (filesUploaded) {
              reloadFiles();
            }
          }}
        />
      )}
    </>
  );
};

export default ContractModal;
