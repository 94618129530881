/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface WorkOrderReadView
 */
export interface WorkOrderReadView {
  /**
   * Case number. A human-readable identifier, which can be used by end users.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  caseNumber: string;
  /**
   * Category.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  category: WorkOrderReadViewCategoryEnum;
  /**
   * When work order was created.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  created: string;
  /**
   * ID of the user who created a work order. Null if work order was created by the tenant.
   * @type {number}
   * @memberof WorkOrderReadView
   */
  creatorId: number | null;
  /**
   * Description.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  description: string;
  /**
   * Attached file IDs.
   * @type {Array<string>}
   * @memberof WorkOrderReadView
   */
  fileIds?: Array<string> | null;
  /**
   * When work order was finished. Null if work order is not yet finished.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  finished: string | null;
  /**
   * Government inspection work order.
   * @type {number}
   * @memberof WorkOrderReadView
   */
  governmentInspectionWorkOrderId: number | null;
  /**
   * Unique identifier.
   * @type {number}
   * @memberof WorkOrderReadView
   */
  id: number;
  /**
   * Organisation.
   * @type {number}
   * @memberof WorkOrderReadView
   */
  organisationId: number;
  /**
   * Property.
   * @type {number}
   * @memberof WorkOrderReadView
   */
  propertyId: number;
  /**
   * Time spent on the work order in minutes.
   * @type {number}
   * @memberof WorkOrderReadView
   */
  spentTime?: number | null;
  /**
   * Status.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  status: WorkOrderReadViewStatusEnum;
  /**
   * Contact email of the tenant or tenant representative. Work order updates will be sent to this email.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  tenantEmail: string | null;
  /**
   * Tenant.
   * @type {number}
   * @memberof WorkOrderReadView
   */
  tenantId: number | null;
  /**
   * Contact phone of the tenant or tenant representative.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  tenantPhone: string | null;
  /**
   * Type.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  type: WorkOrderReadViewTypeEnum;
  /**
   * When work order was last updated.
   * @type {string}
   * @memberof WorkOrderReadView
   */
  updated: string;
}

export const WorkOrderReadViewCategoryEnum = {
  Ground: 'GROUND',
  PortDoor: 'PORT_DOOR',
  IndoorClimate: 'INDOOR_CLIMATE',
  FireProtection: 'FIRE_PROTECTION',
  Building: 'BUILDING',
  SharedSpace: 'SHARED_SPACE',
  WaterDrain: 'WATER_DRAIN',
  InspectionNote: 'INSPECTION_NOTE'
} as const;

export type WorkOrderReadViewCategoryEnum =
  (typeof WorkOrderReadViewCategoryEnum)[keyof typeof WorkOrderReadViewCategoryEnum];
export const WorkOrderReadViewStatusEnum = {
  Received: 'RECEIVED',
  Started: 'STARTED',
  Finished: 'FINISHED'
} as const;

export type WorkOrderReadViewStatusEnum =
  (typeof WorkOrderReadViewStatusEnum)[keyof typeof WorkOrderReadViewStatusEnum];
export const WorkOrderReadViewTypeEnum = {
  FaultReport: 'FAULT_REPORT'
} as const;

export type WorkOrderReadViewTypeEnum = (typeof WorkOrderReadViewTypeEnum)[keyof typeof WorkOrderReadViewTypeEnum];
