import React from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { UserUpdateSelfViewLanguagePreferenceEnum } from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { userApi } from '../../../Http/Http';
import i18n from '../../../i18n';

const languageOptions: DropdownItemProps[] = [
  { key: 'SV', value: 'SV', flag: 'se', text: 'Svenska' },
  { key: 'EN', value: 'EN', flag: 'gb', text: 'English' }
];

const translateToLanguagePreferenceEnum = new Map<string, UserUpdateSelfViewLanguagePreferenceEnum>([
  ['SV', UserUpdateSelfViewLanguagePreferenceEnum.Sv],
  ['EN', UserUpdateSelfViewLanguagePreferenceEnum.En]
]);

enum FormFieldId {
  languagePreference = 'languagePreference'
}

const LanguageDropdown: React.FC = () => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  return (
    <Dropdown
      item
      options={languageOptions}
      className="profile-dropdown"
      value={i18n.resolvedLanguage}
      onChange={async (e, d) => {
        if (d.value) {
          const selectedLanguage = d.value.toString();
          i18n.changeLanguage(selectedLanguage);
          try {
            userApi.userResourceUpdateSelf({
              userUpdateSelfView: {
                [FormFieldId.languagePreference]: translateToLanguagePreferenceEnum.get(selectedLanguage)
              }
            });
          } catch (error) {
            handleHttpErrors(error);
          }
        }
      }}
    />
  );
};

export default LanguageDropdown;
