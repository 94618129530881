import { BarTooltipProps } from '@nivo/bar';
import React from 'react';
import { Card, Label } from 'semantic-ui-react';
import styles from './EnergyReportCustomTooltip.module.scss';
import ReactDOM from 'react-dom';
import NumberFormater from '../../NumberFormater/NumberFormater';
import { useMousePosition } from '../../../Utils/hooks';

interface Props {
  tooltipProps: React.PropsWithChildren<
    BarTooltipProps<{
      period: string;
      value: number;
    }>
  >;
  unit?: string;
}

const EnergyReportCustomTooltip: React.FC<Props> = (props) => {
  const position = useMousePosition();

  const content = (
    <div
      className={styles.tooltipWrapper}
      style={{
        display: !position.x && !position.y ? 'none' : undefined,
        top: position.y + 30,
        left: position.x - 110
      }}
    >
      <Card className={styles.hoverCard}>
        <Card.Content>
          <div className={styles.cardContent}>
            <Label circular style={{ backgroundColor: props.tooltipProps.color }} size="tiny" />
            <div>{props.tooltipProps.data.period}:</div>
            <NumberFormater value={props.tooltipProps.data.value} suffix={' ' + props.unit} />
          </div>
        </Card.Content>
      </Card>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

export default EnergyReportCustomTooltip;
