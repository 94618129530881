/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { NotificationSettingListView } from '../model';
// @ts-ignore
import { NotificationSettingReadView } from '../model';
// @ts-ignore
import { NotificationSettingUpdateView } from '../model';
/**
 * NotificationSettingsApi - axios parameter creator
 * @export
 */
export const NotificationSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List notification settings
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [isGlobal] Filter by whether notification type is global or per organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationSettingResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      isGlobal?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/notification-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (isGlobal !== undefined) {
        localVarQueryParameter['isGlobal'] = isGlobal;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a notification setting
     * @param {number} id Notification setting ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationSettingResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationSettingResourceRead', 'id', id);
      const localVarPath = `/v1/notification-settings/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a notification setting
     * @param {number} id Notification setting ID
     * @param {NotificationSettingUpdateView} [notificationSettingUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationSettingResourceUpdate: async (
      id: number,
      notificationSettingUpdateView?: NotificationSettingUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationSettingResourceUpdate', 'id', id);
      const localVarPath = `/v1/notification-settings/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationSettingUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * NotificationSettingsApi - functional programming interface
 * @export
 */
export const NotificationSettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationSettingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List notification settings
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [isGlobal] Filter by whether notification type is global or per organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationSettingResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      isGlobal?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationSettingResourceList(
        limit,
        offset,
        organisationId,
        isGlobal,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a notification setting
     * @param {number} id Notification setting ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationSettingResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationSettingResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a notification setting
     * @param {number} id Notification setting ID
     * @param {NotificationSettingUpdateView} [notificationSettingUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationSettingResourceUpdate(
      id: number,
      notificationSettingUpdateView?: NotificationSettingUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationSettingResourceUpdate(
        id,
        notificationSettingUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * NotificationSettingsApi - factory interface
 * @export
 */
export const NotificationSettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificationSettingsApiFp(configuration);
  return {
    /**
     *
     * @summary List notification settings
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [isGlobal] Filter by whether notification type is global or per organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationSettingResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      isGlobal?: boolean,
      options?: any
    ): AxiosPromise<NotificationSettingListView> {
      return localVarFp
        .notificationSettingResourceList(limit, offset, organisationId, isGlobal, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a notification setting
     * @param {number} id Notification setting ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationSettingResourceRead(id: number, options?: any): AxiosPromise<NotificationSettingReadView> {
      return localVarFp.notificationSettingResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a notification setting
     * @param {number} id Notification setting ID
     * @param {NotificationSettingUpdateView} [notificationSettingUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationSettingResourceUpdate(
      id: number,
      notificationSettingUpdateView?: NotificationSettingUpdateView,
      options?: any
    ): AxiosPromise<NotificationSettingReadView> {
      return localVarFp
        .notificationSettingResourceUpdate(id, notificationSettingUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for notificationSettingResourceList operation in NotificationSettingsApi.
 * @export
 * @interface NotificationSettingsApiNotificationSettingResourceListRequest
 */
export interface NotificationSettingsApiNotificationSettingResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof NotificationSettingsApiNotificationSettingResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof NotificationSettingsApiNotificationSettingResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof NotificationSettingsApiNotificationSettingResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by whether notification type is global or per organisation
   * @type {boolean}
   * @memberof NotificationSettingsApiNotificationSettingResourceList
   */
  readonly isGlobal?: boolean;
}

/**
 * Request parameters for notificationSettingResourceRead operation in NotificationSettingsApi.
 * @export
 * @interface NotificationSettingsApiNotificationSettingResourceReadRequest
 */
export interface NotificationSettingsApiNotificationSettingResourceReadRequest {
  /**
   * Notification setting ID
   * @type {number}
   * @memberof NotificationSettingsApiNotificationSettingResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for notificationSettingResourceUpdate operation in NotificationSettingsApi.
 * @export
 * @interface NotificationSettingsApiNotificationSettingResourceUpdateRequest
 */
export interface NotificationSettingsApiNotificationSettingResourceUpdateRequest {
  /**
   * Notification setting ID
   * @type {number}
   * @memberof NotificationSettingsApiNotificationSettingResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {NotificationSettingUpdateView}
   * @memberof NotificationSettingsApiNotificationSettingResourceUpdate
   */
  readonly notificationSettingUpdateView?: NotificationSettingUpdateView;
}

/**
 * NotificationSettingsApi - object-oriented interface
 * @export
 * @class NotificationSettingsApi
 * @extends {BaseAPI}
 */
export class NotificationSettingsApi extends BaseAPI {
  /**
   *
   * @summary List notification settings
   * @param {NotificationSettingsApiNotificationSettingResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationSettingsApi
   */
  public notificationSettingResourceList(
    requestParameters: NotificationSettingsApiNotificationSettingResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return NotificationSettingsApiFp(this.configuration)
      .notificationSettingResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.isGlobal,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a notification setting
   * @param {NotificationSettingsApiNotificationSettingResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationSettingsApi
   */
  public notificationSettingResourceRead(
    requestParameters: NotificationSettingsApiNotificationSettingResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificationSettingsApiFp(this.configuration)
      .notificationSettingResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a notification setting
   * @param {NotificationSettingsApiNotificationSettingResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationSettingsApi
   */
  public notificationSettingResourceUpdate(
    requestParameters: NotificationSettingsApiNotificationSettingResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificationSettingsApiFp(this.configuration)
      .notificationSettingResourceUpdate(requestParameters.id, requestParameters.notificationSettingUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
