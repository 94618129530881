/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface FileInfoReadView
 */
export interface FileInfoReadView {
  /**
   * Archived status.
   * @type {boolean}
   * @memberof FileInfoReadView
   */
  archived: boolean;
  /**
   * When file was saved.
   * @type {string}
   * @memberof FileInfoReadView
   */
  created: string;
  /**
   * ID of the user who uploaded a file.
   * @type {number}
   * @memberof FileInfoReadView
   */
  creatorId: number;
  /**
   * Deleted status.
   * @type {boolean}
   * @memberof FileInfoReadView
   */
  deleted: boolean;
  /**
   * Entity ID.
   * @type {number}
   * @memberof FileInfoReadView
   */
  entityId?: number;
  /**
   * Entity type.
   * @type {string}
   * @memberof FileInfoReadView
   */
  entityType?: FileInfoReadViewEntityTypeEnum;
  /**
   * File size.
   * @type {number}
   * @memberof FileInfoReadView
   */
  fileSize: number;
  /**
   * UUID identifier.
   * @type {string}
   * @memberof FileInfoReadView
   */
  id: string;
  /**
   * Name.
   * @type {string}
   * @memberof FileInfoReadView
   */
  name: string;
  /**
   * Organisation.
   * @type {number}
   * @memberof FileInfoReadView
   */
  organisationId?: number;
  /**
   * Sub type.
   * @type {string}
   * @memberof FileInfoReadView
   */
  subType?: FileInfoReadViewSubTypeEnum;
  /**
   * Type.
   * @type {string}
   * @memberof FileInfoReadView
   */
  type?: FileInfoReadViewTypeEnum;
  /**
   * When file was updated.
   * @type {string}
   * @memberof FileInfoReadView
   */
  updated?: string;
  /**
   * ID of the user who updated a file.
   * @type {number}
   * @memberof FileInfoReadView
   */
  updaterId?: number;
}

export const FileInfoReadViewEntityTypeEnum = {
  Contract: 'CONTRACT',
  Property: 'PROPERTY',
  Workorder: 'WORKORDER',
  Workorderlog: 'WORKORDERLOG',
  PropertyCheck: 'PROPERTY_CHECK',
  GovernmentInspectionWorkOrder: 'GOVERNMENT_INSPECTION_WORK_ORDER',
  Organisation: 'ORGANISATION',
  Portfolio: 'PORTFOLIO',
  GovernmentInspection: 'GOVERNMENT_INSPECTION'
} as const;

export type FileInfoReadViewEntityTypeEnum =
  (typeof FileInfoReadViewEntityTypeEnum)[keyof typeof FileInfoReadViewEntityTypeEnum];
export const FileInfoReadViewSubTypeEnum = {
  Addition: 'ADDITION',
  Agreement: 'AGREEMENT',
  AgreementOilSeparator: 'AGREEMENT_OIL_SEPARATOR',
  AgreementPort: 'AGREEMENT_PORT',
  AuditInspectionFireAlarm: 'AUDIT_INSPECTION_FIRE_ALARM',
  AuditInspectionSprinkler: 'AUDIT_INSPECTION_SPRINKLER',
  BuildingPermitDrawing: 'BUILDING_PERMIT_DRAWING',
  ConstructionDrawing: 'CONSTRUCTION_DRAWING',
  ContractPressureVessel: 'CONTRACT_PRESSURE_VESSEL',
  Cooling: 'COOLING',
  Door: 'DOOR',
  ElectricityAudit: 'ELECTRICITY_AUDIT',
  Elevator: 'ELEVATOR',
  EnergyDeclaration: 'ENERGY_DECLARATION',
  EnvironmentalSurvey: 'ENVIRONMENTAL_SURVEY',
  FinalInspection: 'FINAL_INSPECTION',
  FireAlarmTransmission: 'FIRE_ALARM_TRANSMISSION',
  FireProtection: 'FIRE_PROTECTION',
  FloorPlan: 'FLOOR_PLAN',
  GroundDrawing: 'GROUND_DRAWING',
  Inside: 'INSIDE',
  InstallationDrawing: 'INSTALLATION_DRAWING',
  LandManagement: 'LAND_MANAGEMENT',
  Leasehold: 'LEASEHOLD',
  LiftTable: 'LIFT_TABLE',
  LoadingDock: 'LOADING_DOCK',
  LockScheme: 'LOCK_SCHEME',
  Offer: 'OFFER',
  OilSeparator: 'OIL_SEPARATOR',
  Order: 'ORDER',
  Ovk: 'OVK',
  Pests: 'PESTS',
  PressureVessels: 'PRESSURE_VESSELS',
  PropertyPicture: 'PROPERTY_PICTURE',
  RecordDrawing: 'RECORD_DRAWING',
  Refrigerant: 'REFRIGERANT',
  SbaSystematicFireProtectionWork: 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK',
  Shelter: 'SHELTER',
  TreatmentPlant: 'TREATMENT_PLANT',
  WinterMaintenance: 'WINTER_MAINTENANCE',
  ZoningPlan: 'ZONING_PLAN'
} as const;

export type FileInfoReadViewSubTypeEnum =
  (typeof FileInfoReadViewSubTypeEnum)[keyof typeof FileInfoReadViewSubTypeEnum];
export const FileInfoReadViewTypeEnum = {
  BuildingPermits: 'BUILDING_PERMITS',
  DdReport: 'DD_REPORT',
  Drawing: 'DRAWING',
  Easement: 'EASEMENT',
  Inspection: 'INSPECTION',
  Insurance: 'INSURANCE',
  LandAgreement: 'LAND_AGREEMENT',
  Lease: 'LEASE',
  LeaseholdAgreement: 'LEASEHOLD_AGREEMENT',
  NegotiationAgreement: 'NEGOTIATION_AGREEMENT',
  OperatingServicingAgreements: 'OPERATING_SERVICING_AGREEMENTS',
  Other: 'OTHER',
  Project: 'PROJECT',
  PropertyPicture: 'PROPERTY_PICTURE'
} as const;

export type FileInfoReadViewTypeEnum = (typeof FileInfoReadViewTypeEnum)[keyof typeof FileInfoReadViewTypeEnum];
