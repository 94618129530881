/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BusinessPlanCostListView } from '../model';
// @ts-ignore
import { BusinessPlanCostReadView } from '../model';
// @ts-ignore
import { BusinessPlanCostUpdateView } from '../model';
// @ts-ignore
import { BusinessPlanCreateView } from '../model';
// @ts-ignore
import { BusinessPlanIndexListView } from '../model';
// @ts-ignore
import { BusinessPlanIndexReadView } from '../model';
// @ts-ignore
import { BusinessPlanIndexUpdateView } from '../model';
// @ts-ignore
import { BusinessPlanLeaseCreateView } from '../model';
// @ts-ignore
import { BusinessPlanLeaseListView } from '../model';
// @ts-ignore
import { BusinessPlanLeaseReadView } from '../model';
// @ts-ignore
import { BusinessPlanLeaseUpdateView } from '../model';
// @ts-ignore
import { BusinessPlanListView } from '../model';
// @ts-ignore
import { BusinessPlanReadView } from '../model';
// @ts-ignore
import { BusinessPlanUpdateView } from '../model';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { ImportResultsView } from '../model';
/**
 * BusinessPlanApi - axios parameter creator
 * @export
 */
export const BusinessPlanApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List business plan costs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanCostResourceList: async (
      limit?: number,
      offset?: number,
      propertyId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/business-plan/cost`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a business plan cost
     * @param {number} id Business plan cost ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanCostResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanCostResourceRead', 'id', id);
      const localVarPath = `/v1/business-plan/cost/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a business plan cost
     * @param {number} id Business plan cost ID
     * @param {BusinessPlanCostUpdateView} [businessPlanCostUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanCostResourceUpdate: async (
      id: number,
      businessPlanCostUpdateView?: BusinessPlanCostUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanCostResourceUpdate', 'id', id);
      const localVarPath = `/v1/business-plan/cost/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessPlanCostUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List business plan indexes
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanIndexResourceList: async (
      limit?: number,
      offset?: number,
      propertyId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/business-plan/index`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a business plan index
     * @param {number} id Business plan index ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanIndexResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanIndexResourceRead', 'id', id);
      const localVarPath = `/v1/business-plan/index/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a business plan index
     * @param {number} id Business plan index ID
     * @param {BusinessPlanIndexUpdateView} [businessPlanIndexUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanIndexResourceUpdate: async (
      id: number,
      businessPlanIndexUpdateView?: BusinessPlanIndexUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanIndexResourceUpdate', 'id', id);
      const localVarPath = `/v1/business-plan/index/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessPlanIndexUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a business plan lease
     * @param {BusinessPlanLeaseCreateView} [businessPlanLeaseCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceCreate: async (
      businessPlanLeaseCreateView?: BusinessPlanLeaseCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/business-plan/lease`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessPlanLeaseCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a business plan lease
     * @param {number} id Business plan lease ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanLeaseResourceDelete', 'id', id);
      const localVarPath = `/v1/business-plan/lease/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List business plan leases
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceList: async (
      limit?: number,
      offset?: number,
      propertyId?: number,
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/business-plan/lease`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a business plan lease
     * @param {number} id Business plan lease ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanLeaseResourceRead', 'id', id);
      const localVarPath = `/v1/business-plan/lease/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a business plan lease
     * @param {number} id Business plan lease ID
     * @param {BusinessPlanLeaseUpdateView} [businessPlanLeaseUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceUpdate: async (
      id: number,
      businessPlanLeaseUpdateView?: BusinessPlanLeaseUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanLeaseResourceUpdate', 'id', id);
      const localVarPath = `/v1/business-plan/lease/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessPlanLeaseUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a business plan
     * @param {BusinessPlanCreateView} [businessPlanCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceCreate: async (
      businessPlanCreateView?: BusinessPlanCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/business-plan`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessPlanCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a business plan
     * @param {number} id business plan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanResourceDelete', 'id', id);
      const localVarPath = `/v1/business-plan/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Export business plan to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceExportExcelBusinessPlan: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('businessPlanResourceExportExcelBusinessPlan', 'token', token);
      const localVarPath = `/v1/business-plan/xlsx/export/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get a token to export a business plan
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceExportExcelBusinessPlanUrl: async (
      organisationId?: number,
      companyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/business-plan/xlsx/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import business plans from excel file
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceImportBusinessPlan: async (
      organisationId: number,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('businessPlanResourceImportBusinessPlan', 'organisationId', organisationId);
      const localVarPath = `/v1/business-plan/xlsx/{organisationId}`.replace(
        `{${'organisationId'}}`,
        encodeURIComponent(String(organisationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List business plans
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [companyId] Filter by company
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      companyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/business-plans`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a business plan
     * @param {number} id Business plan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanResourceRead', 'id', id);
      const localVarPath = `/v1/business-plan/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a business plan
     * @param {number} id Business plan ID
     * @param {BusinessPlanUpdateView} [businessPlanUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceUpdate: async (
      id: number,
      businessPlanUpdateView?: BusinessPlanUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('businessPlanResourceUpdate', 'id', id);
      const localVarPath = `/v1/business-plan/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessPlanUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * BusinessPlanApi - functional programming interface
 * @export
 */
export const BusinessPlanApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BusinessPlanApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List business plan costs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanCostResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanCostListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanCostResourceList(
        limit,
        offset,
        propertyId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a business plan cost
     * @param {number} id Business plan cost ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanCostResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanCostReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanCostResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a business plan cost
     * @param {number} id Business plan cost ID
     * @param {BusinessPlanCostUpdateView} [businessPlanCostUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanCostResourceUpdate(
      id: number,
      businessPlanCostUpdateView?: BusinessPlanCostUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanCostReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanCostResourceUpdate(
        id,
        businessPlanCostUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List business plan indexes
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanIndexResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanIndexListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanIndexResourceList(
        limit,
        offset,
        propertyId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a business plan index
     * @param {number} id Business plan index ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanIndexResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanIndexReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanIndexResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a business plan index
     * @param {number} id Business plan index ID
     * @param {BusinessPlanIndexUpdateView} [businessPlanIndexUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanIndexResourceUpdate(
      id: number,
      businessPlanIndexUpdateView?: BusinessPlanIndexUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanIndexReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanIndexResourceUpdate(
        id,
        businessPlanIndexUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a business plan lease
     * @param {BusinessPlanLeaseCreateView} [businessPlanLeaseCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanLeaseResourceCreate(
      businessPlanLeaseCreateView?: BusinessPlanLeaseCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanLeaseReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanLeaseResourceCreate(
        businessPlanLeaseCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a business plan lease
     * @param {number} id Business plan lease ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanLeaseResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanLeaseResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List business plan leases
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanLeaseResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanLeaseListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanLeaseResourceList(
        limit,
        offset,
        propertyId,
        organisationId,
        portfolioId,
        assigneeId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a business plan lease
     * @param {number} id Business plan lease ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanLeaseResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanLeaseReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanLeaseResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a business plan lease
     * @param {number} id Business plan lease ID
     * @param {BusinessPlanLeaseUpdateView} [businessPlanLeaseUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanLeaseResourceUpdate(
      id: number,
      businessPlanLeaseUpdateView?: BusinessPlanLeaseUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanLeaseReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanLeaseResourceUpdate(
        id,
        businessPlanLeaseUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a business plan
     * @param {BusinessPlanCreateView} [businessPlanCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanResourceCreate(
      businessPlanCreateView?: BusinessPlanCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanResourceCreate(
        businessPlanCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a business plan
     * @param {number} id business plan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Export business plan to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanResourceExportExcelBusinessPlan(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanResourceExportExcelBusinessPlan(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a token to export a business plan
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanResourceExportExcelBusinessPlanUrl(
      organisationId?: number,
      companyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanResourceExportExcelBusinessPlanUrl(
        organisationId,
        companyId,
        portfolioId,
        assigneeId,
        propertyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Import business plans from excel file
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanResourceImportBusinessPlan(
      organisationId: number,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResultsView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanResourceImportBusinessPlan(
        organisationId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List business plans
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [companyId] Filter by company
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      companyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanResourceList(
        limit,
        offset,
        organisationId,
        propertyId,
        portfolioId,
        assigneeId,
        companyId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a business plan
     * @param {number} id Business plan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a business plan
     * @param {number} id Business plan ID
     * @param {BusinessPlanUpdateView} [businessPlanUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessPlanResourceUpdate(
      id: number,
      businessPlanUpdateView?: BusinessPlanUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessPlanReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessPlanResourceUpdate(
        id,
        businessPlanUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * BusinessPlanApi - factory interface
 * @export
 */
export const BusinessPlanApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BusinessPlanApiFp(configuration);
  return {
    /**
     *
     * @summary List business plan costs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanCostResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      options?: any
    ): AxiosPromise<BusinessPlanCostListView> {
      return localVarFp
        .businessPlanCostResourceList(limit, offset, propertyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a business plan cost
     * @param {number} id Business plan cost ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanCostResourceRead(id: number, options?: any): AxiosPromise<BusinessPlanCostReadView> {
      return localVarFp.businessPlanCostResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a business plan cost
     * @param {number} id Business plan cost ID
     * @param {BusinessPlanCostUpdateView} [businessPlanCostUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanCostResourceUpdate(
      id: number,
      businessPlanCostUpdateView?: BusinessPlanCostUpdateView,
      options?: any
    ): AxiosPromise<BusinessPlanCostReadView> {
      return localVarFp
        .businessPlanCostResourceUpdate(id, businessPlanCostUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List business plan indexes
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanIndexResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      options?: any
    ): AxiosPromise<BusinessPlanIndexListView> {
      return localVarFp
        .businessPlanIndexResourceList(limit, offset, propertyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a business plan index
     * @param {number} id Business plan index ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanIndexResourceRead(id: number, options?: any): AxiosPromise<BusinessPlanIndexReadView> {
      return localVarFp.businessPlanIndexResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a business plan index
     * @param {number} id Business plan index ID
     * @param {BusinessPlanIndexUpdateView} [businessPlanIndexUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanIndexResourceUpdate(
      id: number,
      businessPlanIndexUpdateView?: BusinessPlanIndexUpdateView,
      options?: any
    ): AxiosPromise<BusinessPlanIndexReadView> {
      return localVarFp
        .businessPlanIndexResourceUpdate(id, businessPlanIndexUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a business plan lease
     * @param {BusinessPlanLeaseCreateView} [businessPlanLeaseCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceCreate(
      businessPlanLeaseCreateView?: BusinessPlanLeaseCreateView,
      options?: any
    ): AxiosPromise<BusinessPlanLeaseReadView> {
      return localVarFp
        .businessPlanLeaseResourceCreate(businessPlanLeaseCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a business plan lease
     * @param {number} id Business plan lease ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.businessPlanLeaseResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List business plan leases
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      organisationId?: number,
      portfolioId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<BusinessPlanLeaseListView> {
      return localVarFp
        .businessPlanLeaseResourceList(
          limit,
          offset,
          propertyId,
          organisationId,
          portfolioId,
          assigneeId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a business plan lease
     * @param {number} id Business plan lease ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceRead(id: number, options?: any): AxiosPromise<BusinessPlanLeaseReadView> {
      return localVarFp.businessPlanLeaseResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a business plan lease
     * @param {number} id Business plan lease ID
     * @param {BusinessPlanLeaseUpdateView} [businessPlanLeaseUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanLeaseResourceUpdate(
      id: number,
      businessPlanLeaseUpdateView?: BusinessPlanLeaseUpdateView,
      options?: any
    ): AxiosPromise<BusinessPlanLeaseReadView> {
      return localVarFp
        .businessPlanLeaseResourceUpdate(id, businessPlanLeaseUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a business plan
     * @param {BusinessPlanCreateView} [businessPlanCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceCreate(
      businessPlanCreateView?: BusinessPlanCreateView,
      options?: any
    ): AxiosPromise<BusinessPlanReadView> {
      return localVarFp
        .businessPlanResourceCreate(businessPlanCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a business plan
     * @param {number} id business plan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.businessPlanResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export business plan to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceExportExcelBusinessPlan(token: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .businessPlanResourceExportExcelBusinessPlan(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a token to export a business plan
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [propertyId] Filter by property
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceExportExcelBusinessPlanUrl(
      organisationId?: number,
      companyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      propertyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .businessPlanResourceExportExcelBusinessPlanUrl(
          organisationId,
          companyId,
          portfolioId,
          assigneeId,
          propertyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import business plans from excel file
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceImportBusinessPlan(
      organisationId: number,
      body?: object,
      options?: any
    ): AxiosPromise<ImportResultsView> {
      return localVarFp
        .businessPlanResourceImportBusinessPlan(organisationId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List business plans
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by assignee
     * @param {number} [companyId] Filter by company
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      companyId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<BusinessPlanListView> {
      return localVarFp
        .businessPlanResourceList(
          limit,
          offset,
          organisationId,
          propertyId,
          portfolioId,
          assigneeId,
          companyId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a business plan
     * @param {number} id Business plan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceRead(id: number, options?: any): AxiosPromise<BusinessPlanReadView> {
      return localVarFp.businessPlanResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a business plan
     * @param {number} id Business plan ID
     * @param {BusinessPlanUpdateView} [businessPlanUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessPlanResourceUpdate(
      id: number,
      businessPlanUpdateView?: BusinessPlanUpdateView,
      options?: any
    ): AxiosPromise<BusinessPlanReadView> {
      return localVarFp
        .businessPlanResourceUpdate(id, businessPlanUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for businessPlanCostResourceList operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanCostResourceListRequest
 */
export interface BusinessPlanApiBusinessPlanCostResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanCostResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanCostResourceList
   */
  readonly offset?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanCostResourceList
   */
  readonly propertyId?: number;
}

/**
 * Request parameters for businessPlanCostResourceRead operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanCostResourceReadRequest
 */
export interface BusinessPlanApiBusinessPlanCostResourceReadRequest {
  /**
   * Business plan cost ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanCostResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for businessPlanCostResourceUpdate operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanCostResourceUpdateRequest
 */
export interface BusinessPlanApiBusinessPlanCostResourceUpdateRequest {
  /**
   * Business plan cost ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanCostResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {BusinessPlanCostUpdateView}
   * @memberof BusinessPlanApiBusinessPlanCostResourceUpdate
   */
  readonly businessPlanCostUpdateView?: BusinessPlanCostUpdateView;
}

/**
 * Request parameters for businessPlanIndexResourceList operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanIndexResourceListRequest
 */
export interface BusinessPlanApiBusinessPlanIndexResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanIndexResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanIndexResourceList
   */
  readonly offset?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanIndexResourceList
   */
  readonly propertyId?: number;
}

/**
 * Request parameters for businessPlanIndexResourceRead operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanIndexResourceReadRequest
 */
export interface BusinessPlanApiBusinessPlanIndexResourceReadRequest {
  /**
   * Business plan index ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanIndexResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for businessPlanIndexResourceUpdate operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanIndexResourceUpdateRequest
 */
export interface BusinessPlanApiBusinessPlanIndexResourceUpdateRequest {
  /**
   * Business plan index ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanIndexResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {BusinessPlanIndexUpdateView}
   * @memberof BusinessPlanApiBusinessPlanIndexResourceUpdate
   */
  readonly businessPlanIndexUpdateView?: BusinessPlanIndexUpdateView;
}

/**
 * Request parameters for businessPlanLeaseResourceCreate operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanLeaseResourceCreateRequest
 */
export interface BusinessPlanApiBusinessPlanLeaseResourceCreateRequest {
  /**
   *
   * @type {BusinessPlanLeaseCreateView}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceCreate
   */
  readonly businessPlanLeaseCreateView?: BusinessPlanLeaseCreateView;
}

/**
 * Request parameters for businessPlanLeaseResourceDelete operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanLeaseResourceDeleteRequest
 */
export interface BusinessPlanApiBusinessPlanLeaseResourceDeleteRequest {
  /**
   * Business plan lease ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for businessPlanLeaseResourceList operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanLeaseResourceListRequest
 */
export interface BusinessPlanApiBusinessPlanLeaseResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly offset?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly assigneeId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceList
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for businessPlanLeaseResourceRead operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanLeaseResourceReadRequest
 */
export interface BusinessPlanApiBusinessPlanLeaseResourceReadRequest {
  /**
   * Business plan lease ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for businessPlanLeaseResourceUpdate operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanLeaseResourceUpdateRequest
 */
export interface BusinessPlanApiBusinessPlanLeaseResourceUpdateRequest {
  /**
   * Business plan lease ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {BusinessPlanLeaseUpdateView}
   * @memberof BusinessPlanApiBusinessPlanLeaseResourceUpdate
   */
  readonly businessPlanLeaseUpdateView?: BusinessPlanLeaseUpdateView;
}

/**
 * Request parameters for businessPlanResourceCreate operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanResourceCreateRequest
 */
export interface BusinessPlanApiBusinessPlanResourceCreateRequest {
  /**
   *
   * @type {BusinessPlanCreateView}
   * @memberof BusinessPlanApiBusinessPlanResourceCreate
   */
  readonly businessPlanCreateView?: BusinessPlanCreateView;
}

/**
 * Request parameters for businessPlanResourceDelete operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanResourceDeleteRequest
 */
export interface BusinessPlanApiBusinessPlanResourceDeleteRequest {
  /**
   * business plan ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for businessPlanResourceExportExcelBusinessPlan operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanRequest
 */
export interface BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanRequest {
  /**
   *
   * @type {string}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlan
   */
  readonly token: string;
}

/**
 * Request parameters for businessPlanResourceExportExcelBusinessPlanUrl operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrlRequest
 */
export interface BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrlRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrl
   */
  readonly organisationId?: number;

  /**
   * Filter by company
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrl
   */
  readonly companyId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrl
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrl
   */
  readonly assigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrl
   */
  readonly propertyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrl
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrl
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrl
   */
  readonly propertySubscriptionUserIds?: Array<number>;
}

/**
 * Request parameters for businessPlanResourceImportBusinessPlan operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanResourceImportBusinessPlanRequest
 */
export interface BusinessPlanApiBusinessPlanResourceImportBusinessPlanRequest {
  /**
   *
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceImportBusinessPlan
   */
  readonly organisationId: number;

  /**
   *
   * @type {object}
   * @memberof BusinessPlanApiBusinessPlanResourceImportBusinessPlan
   */
  readonly body?: object;
}

/**
 * Request parameters for businessPlanResourceList operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanResourceListRequest
 */
export interface BusinessPlanApiBusinessPlanResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by assignee
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly assigneeId?: number;

  /**
   * Filter by company
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly companyId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof BusinessPlanApiBusinessPlanResourceList
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for businessPlanResourceRead operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanResourceReadRequest
 */
export interface BusinessPlanApiBusinessPlanResourceReadRequest {
  /**
   * Business plan ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for businessPlanResourceUpdate operation in BusinessPlanApi.
 * @export
 * @interface BusinessPlanApiBusinessPlanResourceUpdateRequest
 */
export interface BusinessPlanApiBusinessPlanResourceUpdateRequest {
  /**
   * Business plan ID
   * @type {number}
   * @memberof BusinessPlanApiBusinessPlanResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {BusinessPlanUpdateView}
   * @memberof BusinessPlanApiBusinessPlanResourceUpdate
   */
  readonly businessPlanUpdateView?: BusinessPlanUpdateView;
}

/**
 * BusinessPlanApi - object-oriented interface
 * @export
 * @class BusinessPlanApi
 * @extends {BaseAPI}
 */
export class BusinessPlanApi extends BaseAPI {
  /**
   *
   * @summary List business plan costs
   * @param {BusinessPlanApiBusinessPlanCostResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanCostResourceList(
    requestParameters: BusinessPlanApiBusinessPlanCostResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanCostResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.propertyId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a business plan cost
   * @param {BusinessPlanApiBusinessPlanCostResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanCostResourceRead(
    requestParameters: BusinessPlanApiBusinessPlanCostResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanCostResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a business plan cost
   * @param {BusinessPlanApiBusinessPlanCostResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanCostResourceUpdate(
    requestParameters: BusinessPlanApiBusinessPlanCostResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanCostResourceUpdate(requestParameters.id, requestParameters.businessPlanCostUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List business plan indexes
   * @param {BusinessPlanApiBusinessPlanIndexResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanIndexResourceList(
    requestParameters: BusinessPlanApiBusinessPlanIndexResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanIndexResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.propertyId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a business plan index
   * @param {BusinessPlanApiBusinessPlanIndexResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanIndexResourceRead(
    requestParameters: BusinessPlanApiBusinessPlanIndexResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanIndexResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a business plan index
   * @param {BusinessPlanApiBusinessPlanIndexResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanIndexResourceUpdate(
    requestParameters: BusinessPlanApiBusinessPlanIndexResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanIndexResourceUpdate(requestParameters.id, requestParameters.businessPlanIndexUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a business plan lease
   * @param {BusinessPlanApiBusinessPlanLeaseResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanLeaseResourceCreate(
    requestParameters: BusinessPlanApiBusinessPlanLeaseResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanLeaseResourceCreate(requestParameters.businessPlanLeaseCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a business plan lease
   * @param {BusinessPlanApiBusinessPlanLeaseResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanLeaseResourceDelete(
    requestParameters: BusinessPlanApiBusinessPlanLeaseResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanLeaseResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List business plan leases
   * @param {BusinessPlanApiBusinessPlanLeaseResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanLeaseResourceList(
    requestParameters: BusinessPlanApiBusinessPlanLeaseResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanLeaseResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.propertyId,
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a business plan lease
   * @param {BusinessPlanApiBusinessPlanLeaseResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanLeaseResourceRead(
    requestParameters: BusinessPlanApiBusinessPlanLeaseResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanLeaseResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a business plan lease
   * @param {BusinessPlanApiBusinessPlanLeaseResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanLeaseResourceUpdate(
    requestParameters: BusinessPlanApiBusinessPlanLeaseResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanLeaseResourceUpdate(requestParameters.id, requestParameters.businessPlanLeaseUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a business plan
   * @param {BusinessPlanApiBusinessPlanResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanResourceCreate(
    requestParameters: BusinessPlanApiBusinessPlanResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanResourceCreate(requestParameters.businessPlanCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a business plan
   * @param {BusinessPlanApiBusinessPlanResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanResourceDelete(
    requestParameters: BusinessPlanApiBusinessPlanResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export business plan to Excel file
   * @param {BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanResourceExportExcelBusinessPlan(
    requestParameters: BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanResourceExportExcelBusinessPlan(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a token to export a business plan
   * @param {BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanResourceExportExcelBusinessPlanUrl(
    requestParameters: BusinessPlanApiBusinessPlanResourceExportExcelBusinessPlanUrlRequest = {},
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanResourceExportExcelBusinessPlanUrl(
        requestParameters.organisationId,
        requestParameters.companyId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.propertyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import business plans from excel file
   * @param {BusinessPlanApiBusinessPlanResourceImportBusinessPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanResourceImportBusinessPlan(
    requestParameters: BusinessPlanApiBusinessPlanResourceImportBusinessPlanRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanResourceImportBusinessPlan(requestParameters.organisationId, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List business plans
   * @param {BusinessPlanApiBusinessPlanResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanResourceList(
    requestParameters: BusinessPlanApiBusinessPlanResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.propertyId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.companyId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a business plan
   * @param {BusinessPlanApiBusinessPlanResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanResourceRead(
    requestParameters: BusinessPlanApiBusinessPlanResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a business plan
   * @param {BusinessPlanApiBusinessPlanResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPlanApi
   */
  public businessPlanResourceUpdate(
    requestParameters: BusinessPlanApiBusinessPlanResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return BusinessPlanApiFp(this.configuration)
      .businessPlanResourceUpdate(requestParameters.id, requestParameters.businessPlanUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
