import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Loader, Message, Table } from 'semantic-ui-react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { createInfinitePaginationParams, governmentInspectionApi, NetworkRequestStatus } from '../../Http/Http';
import { useNonce } from '../../Utils/hooks';
import OverlayLoader from '../OverlayLoader';
import {
  EmbeddedGovernmentInspectionPropertyReadView,
  GovernmentInspectionApiGovernmentInspectionResourceListRequest,
  GovernmentInspectionItemReadView,
  GovernmentInspectionListItemViewCategoryEnum
} from '../../GeneratedServices';
import {
  getGovernmentInspectionStatusColor,
  translateGovernmentInspectionItemAssignee,
  translateGovernmentInspectionItemStatus
} from '../../Services/GovernmentInspectionService.types';
import LoadError from '../LoadError';
import { Link } from 'react-router-dom';
import EditGovernmentInspectionItemModal from './Edit/EditGovernmentInspectionItemModal';
import { applyApiResponseValidationToFields, FieldValidationResult } from '../../Utils/validation';
import { UserFriendlyApiResponse } from '../../Http/response-error';
import GovernmentInspectionItemModal from './GovernmentInspectionItemModal';
import EditableTableCheckboxCell from '../EditableTableCell/EditableTableCheckboxCell';
import PerformGovernmentInspectionModalGroup from './Perform/PerformGovernmentInspectionModalGroup';

interface Props {
  reloadCount: number;
  governmentInspectionParams: GovernmentInspectionApiGovernmentInspectionResourceListRequest;
  includePropertyInfo?: boolean;
}

enum Status {
  None,
  Saving
}

type InspectionItemEntity = GovernmentInspectionItemReadView & {
  newOrdered: boolean;
  property: EmbeddedGovernmentInspectionPropertyReadView;
  category: GovernmentInspectionListItemViewCategoryEnum;
  validationResult?: FieldValidationResult;
  networkStatus: Status;
};
type InspectionItemState = InspectionItemEntity[];
type InspectionItemAction =
  | { type: 'INIT'; data: InspectionItemState }
  | {
      type: 'STATUS_CHANGE';
      id: number;
      networkStatus: Status;
      validationResult?: FieldValidationResult;
    }
  | {
      type: 'EDIT_ITEM';
      item: InspectionItemEntity;
    };

export function inspectionItemsReducer(state: InspectionItemState, action: InspectionItemAction): InspectionItemState {
  switch (action.type) {
    case 'INIT':
      return action.data;
    case 'STATUS_CHANGE':
      return state.map((item) =>
        item.id === action.id
          ? { ...item, networkStatus: action.networkStatus, validationResult: action.validationResult }
          : item
      );
    case 'EDIT_ITEM':
      return state.map((item) => (item.id === action.item.id ? action.item : item));
  }
}

const GovernmentInspectionItemsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'inspections']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const [reloadCount, incrementReloadCount] = useNonce();
  const [itemResultsReloadCount, incrementItemResultsReloadCount] = useNonce();

  const [status, setStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);

  const [inspectionItems, dispatchInspectionItems] = React.useReducer(inspectionItemsReducer, []);

  const [inspectionItemToEdit, setInspectionItemToEdit] = React.useState<InspectionItemEntity>();

  const updateInspectionItemToPreviewRef = React.useRef<InspectionItemEntity>();
  const [inspectionItemToPreview, setInspectionItemToPreview] = React.useState<InspectionItemEntity>();
  const [shouldPerformPreviewItem, setShouldPerformPreviewItem] = React.useState<boolean>(false);

  const [giWorkOrderIdToEdit, setGiWorkOrderIdToEdit] = React.useState<number>();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(NetworkRequestStatus.Loading);
        const { data } = await governmentInspectionApi.governmentInspectionResourceList(
          {
            ...createInfinitePaginationParams(),
            ...props.governmentInspectionParams
          },
          {
            signal: abortController.signal
          }
        );

        dispatchInspectionItems({
          type: 'INIT',
          data: data.records.flatMap((inspection) =>
            inspection.items.map((item) => {
              const inspectionItem = {
                ...item,
                property: inspection.property,
                networkStatus: Status.None,
                newOrdered: item.ordered,
                category: inspection.category
              };

              if (updateInspectionItemToPreviewRef.current?.id === inspectionItem.id) {
                setInspectionItemToPreview(inspectionItem);
                updateInspectionItemToPreviewRef.current = undefined;
              }
              return inspectionItem;
            })
          )
        });
        setStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(NetworkRequestStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, props.reloadCount, reloadCount, props.governmentInspectionParams]);

  const handleResponseValidationError = (resp: UserFriendlyApiResponse, item: InspectionItemEntity) => {
    applyApiResponseValidationToFields(resp, [
      {
        formFieldId: 'ordered',
        setStateFunc: (data) =>
          dispatchInspectionItems({
            type: 'STATUS_CHANGE',
            id: item.id,
            networkStatus: Status.None,
            validationResult: data
          })
      }
    ]);
  };

  const handleOrderedChange = async (item: InspectionItemEntity, ordered: boolean) => {
    try {
      dispatchInspectionItems({
        type: 'EDIT_ITEM',
        item: { ...item, newOrdered: ordered, networkStatus: Status.Saving, validationResult: undefined }
      });

      const { data } = await governmentInspectionApi.governmentInspectionResourceUpdateItem({
        id: item.id,
        governmentInspectionSingleItemUpdateView: {
          assignee: item.assignee,
          info: item.info,
          initialInspectionDeadline: item.initialInspectionDeadline,
          title: item.title,
          ordered
        }
      });

      dispatchInspectionItems({
        type: 'EDIT_ITEM',
        item: {
          ...data,
          newOrdered: data.ordered,
          networkStatus: Status.None,
          property: item.property,
          category: item.category
        }
      });
    } catch (error) {
      handleHttpErrors(error, { handleResponseValidationError: (resp) => handleResponseValidationError(resp, item) });
    }
  };

  const emptyView =
    status === NetworkRequestStatus.Loading ? (
      <Loader inline="centered" active size="large" style={{ top: '40vh' }} />
    ) : (
      <Message>{t('inspections:noInspectionObjectsFound')}</Message>
    );

  const nonEmptyView = (
    <>
      <OverlayLoader loading={status === NetworkRequestStatus.Loading} top={'40vh'}>
        <Table selectable basic="very">
          <Table.Header>
            <Table.Row>
              {props.includePropertyInfo && (
                <>
                  <Table.HeaderCell collapsing>{t('common:property') + ' ID'}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:property')}</Table.HeaderCell>
                </>
              )}
              <Table.HeaderCell>{t('inspections:itemId')}</Table.HeaderCell>
              <Table.HeaderCell>{t('inspections:responsible')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:status')}</Table.HeaderCell>
              <Table.HeaderCell>{t('inspections:deadline')}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{t('inspections:inspectionOrdered')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {inspectionItems.map((item) => {
              return (
                <Table.Row
                  key={item.id}
                  onClick={() => item.networkStatus !== Status.Saving && setInspectionItemToPreview(item)}
                >
                  {props.includePropertyInfo && (
                    <>
                      <Table.Cell>{item.property.strifastId}</Table.Cell>
                      <Table.Cell>
                        <Link to={`/properties/${item.property.id}`}>{item.property.name}</Link>
                      </Table.Cell>
                    </>
                  )}

                  <Table.Cell>{item.title}</Table.Cell>

                  <Table.Cell>
                    <Label size="large">{translateGovernmentInspectionItemAssignee(item.assignee)}</Label>
                  </Table.Cell>

                  <Table.Cell>
                    <Label size="large" color={getGovernmentInspectionStatusColor(item.status)}>
                      {translateGovernmentInspectionItemStatus(item.status)}
                    </Label>
                  </Table.Cell>

                  <Table.Cell>{item.nextInspectionDeadline}</Table.Cell>

                  <Table.Cell error={item.validationResult?.error}>
                    <EditableTableCheckboxCell
                      isInEditMode={true}
                      validationResult={item.validationResult}
                      disabled={item.networkStatus === Status.Saving}
                      checked={item.newOrdered}
                      onChange={(e, d) => {
                        e.stopPropagation();
                        handleOrderedChange(item, d.checked ?? false);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </OverlayLoader>

      {inspectionItemToEdit && (
        <EditGovernmentInspectionItemModal
          item={inspectionItemToEdit}
          onClose={() => setInspectionItemToEdit(undefined)}
          onSave={(item) => {
            if (inspectionItemToPreview && inspectionItemToPreview.id === item.id) {
              setInspectionItemToPreview((prevData) =>
                prevData ? { ...prevData, ...item, newOrdered: item.ordered } : undefined
              );
            }
            incrementReloadCount();
          }}
          type="EDIT"
        />
      )}

      {inspectionItemToPreview && (
        <GovernmentInspectionItemModal
          item={inspectionItemToPreview}
          property={inspectionItemToPreview.property}
          category={inspectionItemToPreview.category}
          onClose={() => setInspectionItemToPreview(undefined)}
          onEdit={() => setInspectionItemToEdit(inspectionItemToPreview)}
          onOrderedChange={(ordered) => {
            if (inspectionItemToEdit && inspectionItemToEdit.id === inspectionItemToPreview.id) {
              setInspectionItemToEdit((prevData) =>
                prevData ? { ...prevData, ordered, newOrdered: ordered } : undefined
              );
            }
            setInspectionItemToPreview((prevData) =>
              prevData ? { ...prevData, ordered, newOrdered: ordered } : undefined
            );
            incrementReloadCount();
          }}
          onPerformClick={() => setShouldPerformPreviewItem(true)}
          onItemResultClick={(id) => {
            setGiWorkOrderIdToEdit(id);
          }}
          itemResultsReloadCount={itemResultsReloadCount}
        />
      )}

      {(shouldPerformPreviewItem || giWorkOrderIdToEdit) && inspectionItemToPreview && (
        <PerformGovernmentInspectionModalGroup
          onClose={() => {
            setGiWorkOrderIdToEdit(undefined);
            setShouldPerformPreviewItem(false);
          }}
          onPerform={() => {
            updateInspectionItemToPreviewRef.current = inspectionItemToPreview;

            incrementReloadCount();
            incrementItemResultsReloadCount();
          }}
          {...(giWorkOrderIdToEdit
            ? { governmentInspectionWorkOrderId: giWorkOrderIdToEdit }
            : {
                governmentInspectionWorkOrderId: undefined,
                initialItemIds: [inspectionItemToPreview.id],
                category: inspectionItemToPreview.category,
                propertyId: inspectionItemToPreview.property.id
              })}
        />
      )}
    </>
  );

  switch (status) {
    case NetworkRequestStatus.LoadError:
      return <LoadError message={t('inspections:unableToLoadGovernmentInspections')} retry={incrementReloadCount} />;
    case NetworkRequestStatus.Loading:
    case NetworkRequestStatus.None:
      return inspectionItems.length === 0 ? emptyView : nonEmptyView;
  }
};

export default GovernmentInspectionItemsTable;
