import { useParams, useSearchParams } from 'react-router-dom';
import React from 'react';
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Label,
  Loader,
  SemanticShorthandItem,
  TabPaneProps,
  Image
} from 'semantic-ui-react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useDimensions, useNonce } from '../../Utils/hooks';
import {
  hasPermittedReadRoleInOrganisation,
  hasPermittedWriteRoleInOrganisation,
  hasRoleMatchWithinOrganisation,
  Role
} from '../../Utils/permissions';
import TabSummary from './Components/TabSummary';
import styles from './PropertyDetails.module.scss';
import TabFiles from './Components/TabFiles';
import TabFaultReports from './Components/TabFaultReports';
import TabPropertyChecks from './Components/TabPropertyChecks';
import LoadError from '../../Components/LoadError';
import MainContainer from '../../Components/MainContainer/MainContainer';
import {
  alterSearchParam,
  companyApi,
  createInfinitePaginationParams,
  fileApi,
  getSearchParam,
  organisationApi,
  portfolioApi,
  propertyApi,
  propertySubscriptionApi
} from '../../Http/Http';
import EditPropertyTypeModal from './Components/EditPropertyTypeModal';
import FinancialStatistics from '../../Components/FinancialStatistics/FinancialStatistics';
import TabLeases from './Components/TabLeases';
import EnergyReport from '../../Components/EnergyReport/EnergyReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseBuilding, faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import {
  CompanyReadView,
  FileInfoReadViewSubTypeEnum,
  FileInfoReadViewTypeEnum,
  OrganisationReadView,
  PortfolioReadView,
  PropertyReadView,
  PropertySubscriptionReadView
} from '../../GeneratedServices';
import HeaderContainer, { HeaderInfoSection } from '../../Components/HeaderContainer/HeaderContainer';
import TabGovernmentInspections from './Components/TabGovernmentInspections';
import { FileInfoReadView, FileSubType, FileType } from '../../Services/FileService.types';
import ScrollableTabs from '../../Components/ScrollableTabs/ScrollableTabs';
import { translatePropertyType } from '../../Services/PropertyService';
import UserLabels from '../../Components/UserLabels/UserLabels';
import SubscriptionsModal, { SubscriptionsTabs } from './Components/Subscriptions/SubscriptionsModal';
import { useTranslation } from 'react-i18next';
import { usePropertiesThumbnails } from './Hooks/usePropertiesThumbnails';
import PropertyImages from './Components/PropertyImages/PropertyImages';
import ListLabel from '../../Components/ListLabel/ListLabel';
import { parseNatural } from '../../Utils/safe-parse';

interface Props {}

type Params = {
  propertyId: string; // Reach router always pass value as string.
};

export enum PropertyTabType {
  SUMMARY = 'summary',
  FILES = 'files',
  WORK_ORDERS = 'workorders',
  PROPERTY_CHECKS = 'propertychecks',
  GOVERNMENT_INSPECTIONS = 'governmentinspections',
  ECONOMY = 'economy',
  LEASES = 'leases',
  ENERGY = 'energy'
}

enum Status {
  None,
  Loading,
  LoadError
}

const PropertyDetails: React.FC<Props> = (props) => {
  const params = useParams<Params>();
  const propertyId = parseNatural(params.propertyId)!;

  const { t } = useTranslation(['properties', 'common']);

  const globalState = React.useContext(globalStateCTX);

  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = getSearchParam(searchParams, 'tab');

  const [property, setProperty] = React.useState<PropertyReadView>();
  const [company, setCompany] = React.useState<CompanyReadView>();
  const [organisation, setOrganisation] = React.useState<OrganisationReadView>();

  const [propertyImages, setPropertyImages] = React.useState<FileInfoReadView[]>();

  const [status, setStatus] = React.useState<Status>(Status.Loading);
  const [reloadNonce, setReloadNonce] = useNonce();

  const [isTypeModalOpen, setTypeModalOpen] = React.useState<boolean>(false);

  const [portfolios, setPortfolios] = React.useState<PortfolioReadView[]>();
  const [propertySubscriptions, setPropertySubscriptions] = React.useState<PropertySubscriptionReadView[]>();

  const [subscriptionsModalOpenTab, setSubscriptionsModalOpenTab] = React.useState<SubscriptionsTabs>();
  const [shouldReloadSubscriptions, reloadSubscriptions] = useNonce();

  const { ref: headerWrapperRef, dimensions } = useDimensions();

  const { getPropertyImageUrl, loadThumbnails, propertiesThumbnails } = usePropertiesThumbnails();

  const propertyThumbnail = property && property.pictureId ? propertiesThumbnails[property.pictureId] : undefined;

  const [isImageViewerOpen, setImageViewerOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        const { data: subscriptions } = await propertySubscriptionApi.propertySubscriptionResourceList(
          {
            propertyId: propertyId
          },
          {
            signal: abortController.signal
          }
        );
        setPropertySubscriptions(subscriptions.records);
      } catch (error) {
        globalState.handleHttpErrors(error);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [shouldReloadSubscriptions, propertyId, globalState]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(Status.Loading);

        const { data: propertyData } = await propertyApi.propertyResourceRead(
          { id: propertyId },
          {
            signal: abortController.signal
          }
        );

        const { data: companyData } = await companyApi.companyResourceRead(
          {
            id: propertyData.companyId
          },
          {
            signal: abortController.signal
          }
        );

        const { data: organisationData } = await organisationApi.organisationResourceRead(
          {
            id: companyData.organisationId
          },
          {
            signal: abortController.signal
          }
        );

        const { data: portfoliosData } = await portfolioApi.portfolioResourceList(
          { organisationId: companyData.organisationId, ...createInfinitePaginationParams() },
          { signal: abortController.signal }
        );

        const { data: propertyImagesData } = await fileApi.fileResourceList(
          {
            ...createInfinitePaginationParams(),
            entityType: 'PROPERTY',
            entityId: propertyData.id,
            type: FileInfoReadViewTypeEnum.PropertyPicture,
            subType: FileInfoReadViewSubTypeEnum.PropertyPicture
          },
          { signal: abortController.signal }
        );

        if (propertyData.pictureId) {
          loadThumbnails([propertyData.pictureId], abortController);
        }
        setProperty(propertyData);
        setPropertyImages(propertyImagesData.records as FileInfoReadView[]);
        setCompany(companyData);
        setOrganisation(organisationData);
        setPortfolios(portfoliosData.records.filter((item) => item.companyIds.includes(propertyData.companyId)));

        setStatus(Status.None);
      } catch (error) {
        globalState.handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [globalState, propertyId, reloadNonce, loadThumbnails]);

  const handleFilesChange = (
    files: {
      action: 'CREATE' | 'UPDATE' | 'DELETE';
      fileId: string;
      type?: FileType | undefined;
      subType?: FileSubType | undefined;
    }[]
  ) => {
    let shouldReload = false;

    if (property?.pictureId) {
      files.forEach((item) => {
        if (item.fileId === property.pictureId) {
          if (
            (item.action === 'UPDATE' &&
              (item.type !== FileType.PROPERTY_PICTURE || item.subType !== FileSubType.PROPERTY_PICTURE)) ||
            item.action === 'DELETE'
          ) {
            shouldReload = true;
          }
        }
      });
    } else {
      files.forEach((item) => {
        if (
          (item.action === 'CREATE' || item.action === 'UPDATE') &&
          item.type === FileType.PROPERTY_PICTURE &&
          item.subType === FileSubType.PROPERTY_PICTURE
        ) {
          shouldReload = true;
        }
      });
    }
    if (shouldReload) {
      setReloadNonce();
    }
  };

  const getLayout = () => {
    switch (status) {
      case Status.Loading:
        return property ? generalLayout : layoutLoading;

      case Status.None:
        return generalLayout;

      case Status.LoadError:
        return layoutLoadError;
    }
  };

  const layoutLoading = <Loader inline="centered" active size="large" style={{ top: '40vh' }} />;

  const layoutLoadError = (
    <LoadError message={t('properties:unableToFetchInformationAboutProperty')} retry={setReloadNonce} />
  );

  const navigateToTab = (tab: string) => {
    setSearchParams((prevValue) => alterSearchParam(prevValue, 'tab', tab));
  };

  const summaryTab = {
    menuItem: { content: t('common:summary'), key: PropertyTabType.SUMMARY },
    order: 1,
    render: () => <TabSummary property={property!} reloadProperty={setReloadNonce} organisationId={organisation?.id} />
  };

  const economyTab = {
    menuItem: { content: t('common:economy'), key: PropertyTabType.ECONOMY },
    order: 2,
    render: () => (
      <>
        <Header as="h3">{t('common:economicDevelopment')}</Header>
        <FinancialStatistics propertyId={propertyId} />
      </>
    )
  };

  const filesTab = {
    menuItem: { content: t('common:files'), key: PropertyTabType.FILES },
    order: 5,
    render: () => (
      <>
        <TabFiles propertyId={propertyId} onChange={handleFilesChange} organisationId={organisation?.id} />
      </>
    )
  };

  const workOrdersTab = {
    menuItem: {
      content: t('common:faultReports'),
      key: PropertyTabType.WORK_ORDERS,
      className: styles.extraTabs
    },
    order: 6,
    render: () => (
      <>
        <TabFaultReports propertyId={propertyId} />
      </>
    )
  };

  const propertyChecksTab = {
    menuItem: {
      content: t('common:propertyChecks'),
      key: PropertyTabType.PROPERTY_CHECKS,
      className: styles.extraTabs
    },
    order: 7,
    render: () => (
      <>
        <TabPropertyChecks propertyId={propertyId} organisationId={organisation?.id} />
      </>
    )
  };

  const governmentInspectionsTab = {
    menuItem: {
      content: t('common:inspections'),
      key: PropertyTabType.GOVERNMENT_INSPECTIONS,
      className: styles.extraTabs
    },
    order: 8,
    render: () => (
      <>
        <TabGovernmentInspections property={property!} />
      </>
    )
  };

  const leasesTab = {
    menuItem: { content: t('common:leases'), key: PropertyTabType.LEASES },
    order: 3,
    render: () => (
      <>
        <TabLeases property={property!} organisationId={organisation?.id} />
      </>
    )
  };
  const energyTab = {
    menuItem: { content: t('common:energy'), key: PropertyTabType.ENERGY },
    order: 4,
    render: () => (
      <>
        <Header as="h3">{t('common:energy')}</Header>
        <EnergyReport propertyId={propertyId} />
      </>
    )
  };

  const tabsForEveryone = [summaryTab, leasesTab, filesTab];
  const tabsForUsersWithReadOnlyRole = [economyTab, energyTab];
  const tabsForTechnician = [workOrdersTab, propertyChecksTab, governmentInspectionsTab];
  const tabsForUsersWithWriteRole = [...tabsForUsersWithReadOnlyRole, ...tabsForTechnician];

  const userHasPermittedReadRoleInOrganisation = hasPermittedReadRoleInOrganisation(
    globalState.currentUser,
    globalState.currentUserMemberships,
    company?.organisationId
  );

  const userHasPermittedWriteRoleInOrganisation = hasPermittedWriteRoleInOrganisation(
    globalState.currentUser,
    globalState.currentUserMemberships,
    company?.organisationId
  );

  const canAddSubscriptions = hasRoleMatchWithinOrganisation(
    [Role.Admin, Role.Technician, Role.Manager],
    globalState.currentUser,
    globalState.currentUserMemberships?.map((item) => ({ ...item, role: item.role as Role })),
    property?.organisationId
  );

  let tabs: {
    pane?: SemanticShorthandItem<TabPaneProps>;
    order: number;
    menuItem?: any;
    render?: (() => React.ReactNode) | undefined;
  }[] = [];
  if (userHasPermittedWriteRoleInOrganisation) {
    tabs = [...tabsForEveryone, ...tabsForUsersWithWriteRole];
  } else if (userHasPermittedReadRoleInOrganisation) {
    tabs = [...tabsForEveryone, ...tabsForUsersWithReadOnlyRole];
  } else tabs = [...tabsForEveryone, ...tabsForTechnician];

  const sortedTabs = tabs.sort((a, b) => (a.order > b.order ? 1 : -1));
  const activeIndex = tabs.findIndex((item) => item.menuItem.key === tabParam);

  const HeaderSection = (
    <HeaderContainer
      header={property?.name}
      ref={headerWrapperRef}
      headerLeft={
        <Image
          src={getPropertyImageUrl(propertyThumbnail)}
          alt=""
          className={styles.headerImage}
          style={{ cursor: !property?.pictureId ? undefined : 'pointer' }}
          onClick={() => property?.pictureId && setImageViewerOpen(true)}
        />
      }
      headerExtra={
        <>
          <div className={styles.headerExtraRow}>
            {property?.type && <Label size="large">{translatePropertyType(property.type)}</Label>}

            <div className={styles.address}>{property?.address}</div>
          </div>

          <div className={styles.headerExtraRow}>
            {canAddSubscriptions && (
              <Button color="blue" onClick={() => setSubscriptionsModalOpenTab(SubscriptionsTabs.ADD_SUBSCRIPTIONS)}>
                {t('common:invite')}
              </Button>
            )}

            {propertySubscriptions && propertySubscriptions.length !== 0 && (
              <UserLabels
                users={propertySubscriptions.map((item) => ({ name: item.name, id: item.userId }))}
                onClick={() => setSubscriptionsModalOpenTab(SubscriptionsTabs.SUBSCRIPTIONS)}
                displayedUsersLimit={2}
                marginBottom={0}
              />
            )}
          </div>
        </>
      }
      rightComponent={
        <EditingDropdown setTypeModalOpen={setTypeModalOpen} isPermitted={userHasPermittedWriteRoleInOrganisation} />
      }
    >
      <HeaderInfoSection label={t('common:company')} content={<Label size="large">{company?.name}</Label>} />
      <HeaderInfoSection
        label={t('common:portfolio')}
        content={<ListLabel list={portfolios ?? []} displayValueFunction={(data) => data.name} />}
      />
      <HeaderInfoSection label={t('common:organisation')} content={<Label size="large">{organisation?.name}</Label>} />
      {
        //Add additional divs at the end of the container when it overflows to prevent wrapped content from being poorly aligned
      }
      {dimensions && dimensions.height > 115 && (
        <>
          <div />
          <div />
          <div />
        </>
      )}
    </HeaderContainer>
  );

  const generalLayout = (
    <>
      {HeaderSection}

      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column width={16}>
            <ScrollableTabs
              onTabChange={(e, d) => {
                if (d.activeIndex !== undefined) {
                  const activeTab = sortedTabs[d.activeIndex as number];
                  const key = activeTab.menuItem.key ? activeTab.menuItem.key.toLowerCase() : null;

                  navigateToTab(key);
                }
              }}
              panes={sortedTabs}
              activeIndex={activeIndex !== -1 ? activeIndex : 0}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );

  return (
    <MainContainer>
      {getLayout()}

      {property && isTypeModalOpen && (
        <EditPropertyTypeModal
          onClose={() => setTypeModalOpen(false)}
          currentType={property.type}
          propertyId={property.id}
          onSave={(data) => {
            data && setReloadNonce();
          }}
        />
      )}

      {propertySubscriptions && subscriptionsModalOpenTab !== undefined && property && (
        <SubscriptionsModal
          onClose={() => setSubscriptionsModalOpenTab(undefined)}
          subscriptions={propertySubscriptions}
          reloadSubscriptions={reloadSubscriptions}
          organisationId={property.organisationId}
          propertyId={property.id}
          activeTab={subscriptionsModalOpenTab}
        />
      )}

      {isImageViewerOpen && property && propertyImages && (
        <PropertyImages
          onClose={() => setImageViewerOpen(false)}
          property={property}
          propertyImages={propertyImages}
          reloadProperty={setReloadNonce}
        />
      )}
    </MainContainer>
  );
};

const EditingDropdown = (props: {
  setTypeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPermitted: boolean;
}) => {
  const { t } = useTranslation(['properties']);
  if (props.isPermitted)
    return (
      <Dropdown
        direction={'left'}
        icon={
          <Button icon className={styles.actionButton} size="large" basic>
            <Icon>
              <FontAwesomeIcon icon={faEllipsisV} />
            </Icon>
          </Button>
        }
      >
        <Dropdown.Menu className="selection">
          <Dropdown.Item
            onClick={() => props.setTypeModalOpen(true)}
            icon={
              <Icon>
                <FontAwesomeIcon icon={faHouseBuilding} />
              </Icon>
            }
            text={t('properties:changePropertyType')}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  else return <div />;
};

export default PropertyDetails;
