import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form, Modal } from 'semantic-ui-react';
import { PropertyReadViewTypeEnum } from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { propertyApi } from '../../../Http/Http';
import { propertyOptions } from '../../../Services/PropertyService';

interface Props {
  onClose: () => void;
  currentType?: PropertyReadViewTypeEnum;
  propertyId: number;
  onSave: (type: PropertyReadViewTypeEnum | null) => void;
}

enum Status {
  None,
  Saving,
  Removing
}

enum FormFieldId {
  type = 'type'
}

const EditPropertyTypeModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['properties', 'common']);

  const globalState = React.useContext(globalStateCTX);
  const [status, setStatus] = React.useState<Status>(Status.None);

  const [type, setType] = React.useState<PropertyReadViewTypeEnum | undefined>(props.currentType);

  const getLayout = () => {
    return (
      <>
        <Form noValidate="noValidate">
          <Form.Dropdown
            label={t('common:propertyType')}
            placeholder={t('common:propertyType')}
            floating
            value={type ?? ''}
            onChange={(e, d) => {
              if (!d.value) setType(undefined);
              else setType(d.value as PropertyReadViewTypeEnum);
            }}
            selection
            scrolling
            options={propertyOptions()}
          />
        </Form>
      </>
    );
  };

  const save = async () => {
    if (type)
      try {
        setStatus(Status.Saving);

        await propertyApi.propertyResourceUpdate({
          id: props.propertyId,
          propertyUpdateView: {
            [FormFieldId.type]: type
          }
        });
        toast.info(t('properties:propertyTypeHasBeenUpdated'));

        props.onSave(type);
        props.onClose();
      } catch (error) {
        globalState.handleHttpErrors(error);
      } finally {
        setStatus(Status.None);
      }
  };

  return (
    <Modal
      size={'mini'}
      open
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon={status !== Status.Saving}
      onClose={props.onClose}
    >
      <Modal.Header>{t('properties:selectPropertyType')}</Modal.Header>
      <Modal.Content>{getLayout()}</Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <Button primary loading={status === Status.Saving} disabled={status !== Status.None} onClick={save}>
              {t('common:save')}
            </Button>

            <Button disabled={[Status.Saving, Status.Removing].includes(status)} onClick={props.onClose}>
              {t('common:close')}
            </Button>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default EditPropertyTypeModal;
