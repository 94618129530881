import { faCompress, faExpand } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MapControlButton from '../MapControlButton';
import styles from './FullscreenControl.module.scss';

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onwebkitfullscreenchange?: any;
    onmsfullscreenchange?: any;
    onmozfullscreenchange?: any;
  }

  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}
interface Props {
  map: google.maps.Map | null;
}
const FullscreenControl: React.FC<Props> = (props) => {
  const { t } = useTranslation('analytics');

  const customControlRef = React.useRef<HTMLDivElement>(null);
  const [isMapFullscreen, setMapFullscreen] = React.useState<boolean>(false);

  React.useEffect(() => {
    function initFullscreenControl(map: google.maps.Map) {
      const elementToSendFullscreen = map.getDiv().firstChild as HTMLElement;
      const fullscreenControlDiv = document.createElement('div');

      const customControlDiv = customControlRef.current;
      fullscreenControlDiv.appendChild(customControlDiv as Node);
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(fullscreenControlDiv);

      fullscreenControlDiv.addEventListener('click', () => {
        if (isFullscreen(elementToSendFullscreen)) {
          exitFullscreen();
        } else {
          requestFullscreen(elementToSendFullscreen);
        }
      });

      document.onfullscreenchange = function () {
        if (isFullscreen(elementToSendFullscreen)) {
          fullscreenControlDiv.classList.add('is-fullscreen');
        } else {
          fullscreenControlDiv.classList.remove('is-fullscreen');
        }
      };
    }
    if (props.map) {
      initFullscreenControl(props.map);
    }
  }, [props.map]);

  function isFullscreen(element: HTMLElement) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) === element
    );
  }

  function requestFullscreen(element: HTMLElement) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
    setMapFullscreen(true);
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setMapFullscreen(false);
  }

  return (
    <div className={styles.fullscreenControl} ref={customControlRef}>
      <MapControlButton
        icon={isMapFullscreen ? faCompress : faExpand}
        title={isMapFullscreen ? t('minimize') : t('maximize')}
      />
    </div>
  );
};

export default FullscreenControl;
