/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountListView } from '../model';
// @ts-ignore
import { AccountReadView } from '../model';
// @ts-ignore
import { AccountUpdateView } from '../model';
// @ts-ignore
import { ErrorView } from '../model';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List accounts
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/accounts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read an account
     * @param {number} id Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('accountResourceRead', 'id', id);
      const localVarPath = `/v1/accounts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update an account
     * @param {number} id Account ID
     * @param {AccountUpdateView} [accountUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountResourceUpdate: async (
      id: number,
      accountUpdateView?: AccountUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('accountResourceUpdate', 'id', id);
      const localVarPath = `/v1/accounts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(accountUpdateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List accounts
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountResourceList(
        limit,
        offset,
        organisationId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read an account
     * @param {number} id Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update an account
     * @param {number} id Account ID
     * @param {AccountUpdateView} [accountUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountResourceUpdate(
      id: number,
      accountUpdateView?: AccountUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountResourceUpdate(id, accountUpdateView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     *
     * @summary List accounts
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      options?: any
    ): AxiosPromise<AccountListView> {
      return localVarFp
        .accountResourceList(limit, offset, organisationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read an account
     * @param {number} id Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountResourceRead(id: number, options?: any): AxiosPromise<AccountReadView> {
      return localVarFp.accountResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update an account
     * @param {number} id Account ID
     * @param {AccountUpdateView} [accountUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountResourceUpdate(
      id: number,
      accountUpdateView?: AccountUpdateView,
      options?: any
    ): AxiosPromise<AccountReadView> {
      return localVarFp
        .accountResourceUpdate(id, accountUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for accountResourceList operation in AccountApi.
 * @export
 * @interface AccountApiAccountResourceListRequest
 */
export interface AccountApiAccountResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof AccountApiAccountResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof AccountApiAccountResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof AccountApiAccountResourceList
   */
  readonly organisationId?: number;
}

/**
 * Request parameters for accountResourceRead operation in AccountApi.
 * @export
 * @interface AccountApiAccountResourceReadRequest
 */
export interface AccountApiAccountResourceReadRequest {
  /**
   * Company ID
   * @type {number}
   * @memberof AccountApiAccountResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for accountResourceUpdate operation in AccountApi.
 * @export
 * @interface AccountApiAccountResourceUpdateRequest
 */
export interface AccountApiAccountResourceUpdateRequest {
  /**
   * Account ID
   * @type {number}
   * @memberof AccountApiAccountResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {AccountUpdateView}
   * @memberof AccountApiAccountResourceUpdate
   */
  readonly accountUpdateView?: AccountUpdateView;
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   *
   * @summary List accounts
   * @param {AccountApiAccountResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountResourceList(
    requestParameters: AccountApiAccountResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AccountApiFp(this.configuration)
      .accountResourceList(requestParameters.limit, requestParameters.offset, requestParameters.organisationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read an account
   * @param {AccountApiAccountResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountResourceRead(requestParameters: AccountApiAccountResourceReadRequest, options?: AxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .accountResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update an account
   * @param {AccountApiAccountResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountResourceUpdate(
    requestParameters: AccountApiAccountResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return AccountApiFp(this.configuration)
      .accountResourceUpdate(requestParameters.id, requestParameters.accountUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
