/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GovernmentInspectionItemReadView
 */
export interface GovernmentInspectionItemReadView {
  /**
   * The person responsible for handling the government inspection for the item.
   * @type {string}
   * @memberof GovernmentInspectionItemReadView
   */
  assignee: GovernmentInspectionItemReadViewAssigneeEnum;
  /**
   * Government inspection item ID.
   * @type {number}
   * @memberof GovernmentInspectionItemReadView
   */
  id: number;
  /**
   * Item info.
   * @type {string}
   * @memberof GovernmentInspectionItemReadView
   */
  info: string | null;
  /**
   * Initial inspection deadline.
   * @type {string}
   * @memberof GovernmentInspectionItemReadView
   */
  initialInspectionDeadline: string;
  /**
   * Next inspection deadline.
   * @type {string}
   * @memberof GovernmentInspectionItemReadView
   */
  nextInspectionDeadline: string;
  /**
   * Whether the inspection for the item is ordered or not.
   * @type {boolean}
   * @memberof GovernmentInspectionItemReadView
   */
  ordered: boolean;
  /**
   * Last performed date.
   * @type {string}
   * @memberof GovernmentInspectionItemReadView
   */
  performed: string;
  /**
   * Status based on the next inspection deadline.
   * @type {string}
   * @memberof GovernmentInspectionItemReadView
   */
  status: GovernmentInspectionItemReadViewStatusEnum;
  /**
   * Item title.
   * @type {string}
   * @memberof GovernmentInspectionItemReadView
   */
  title: string;
}

export const GovernmentInspectionItemReadViewAssigneeEnum = {
  Landlord: 'LANDLORD',
  Tenant: 'TENANT'
} as const;

export type GovernmentInspectionItemReadViewAssigneeEnum =
  (typeof GovernmentInspectionItemReadViewAssigneeEnum)[keyof typeof GovernmentInspectionItemReadViewAssigneeEnum];
export const GovernmentInspectionItemReadViewStatusEnum = {
  Passed: 'PASSED',
  Failed: 'FAILED',
  Overdue: 'OVERDUE',
  ExpiringSoon: 'EXPIRING_SOON'
} as const;

export type GovernmentInspectionItemReadViewStatusEnum =
  (typeof GovernmentInspectionItemReadViewStatusEnum)[keyof typeof GovernmentInspectionItemReadViewStatusEnum];
