/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddressesResultView } from '../model';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { ListOngoingCitiesRequestView } from '../model';
// @ts-ignore
import { ListOngoingCityAddressesRequestView } from '../model';
// @ts-ignore
import { ListTenantNamesRequestView } from '../model';
// @ts-ignore
import { MatchTenantNamesRequestView } from '../model';
// @ts-ignore
import { SearchReadView } from '../model';
// @ts-ignore
import { TenantNamesResultView } from '../model';
// @ts-ignore
import { WorkOrderAnonymousCreateView } from '../model';
// @ts-ignore
import { WorkOrderCreateViewUnion } from '../model';
// @ts-ignore
import { WorkOrderListView } from '../model';
// @ts-ignore
import { WorkOrderLogCreateViewUnion } from '../model';
// @ts-ignore
import { WorkOrderLogCreateViewV1 } from '../model';
// @ts-ignore
import { WorkOrderLogListView } from '../model';
// @ts-ignore
import { WorkOrderLogListViewV1 } from '../model';
// @ts-ignore
import { WorkOrderLogReadViewUnion } from '../model';
// @ts-ignore
import { WorkOrderLogReadViewV1 } from '../model';
// @ts-ignore
import { WorkOrderLogUpdateView } from '../model';
// @ts-ignore
import { WorkOrderReadView } from '../model';
// @ts-ignore
import { WorkOrderUpdateView } from '../model';
/**
 * WorkOrderApi - axios parameter creator
 * @export
 */
export const WorkOrderApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchResourceList: async (
      query: string,
      limit: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchResourceList', 'query', query);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('searchResourceList', 'limit', limit);
      const localVarPath = `/v1/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a work order log
     * @param {WorkOrderLogCreateViewUnion} [workOrderLogCreateViewUnion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceCreate: async (
      workOrderLogCreateViewUnion?: WorkOrderLogCreateViewUnion,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/work-order-logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workOrderLogCreateViewUnion,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List work order logs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [workOrderId] Filter by work order
     * @param {number} [parentLogId] Filter by parent log
     * @param {boolean} [isTopLevel] Filter by whether log is top level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceList: async (
      limit?: number,
      offset?: number,
      workOrderId?: number,
      parentLogId?: number,
      isTopLevel?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/work-order-logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (workOrderId !== undefined) {
        localVarQueryParameter['workOrderId'] = workOrderId;
      }

      if (parentLogId !== undefined) {
        localVarQueryParameter['parentLogId'] = parentLogId;
      }

      if (isTopLevel !== undefined) {
        localVarQueryParameter['isTopLevel'] = isTopLevel;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a work order log
     * @param {number} id Work order log ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('workOrderLogResourceRead', 'id', id);
      const localVarPath = `/v2/work-order-logs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a work order log
     * @param {number} id Work order log ID
     * @param {WorkOrderLogUpdateView} [workOrderLogUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceUpdate: async (
      id: number,
      workOrderLogUpdateView?: WorkOrderLogUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('workOrderLogResourceUpdate', 'id', id);
      const localVarPath = `/v2/work-order-logs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workOrderLogUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a work order log
     * @param {WorkOrderLogCreateViewV1} [workOrderLogCreateViewV1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceV1Create: async (
      workOrderLogCreateViewV1?: WorkOrderLogCreateViewV1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-order-logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workOrderLogCreateViewV1,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List work order logs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [workOrderId] Filter by work order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceV1List: async (
      limit?: number,
      offset?: number,
      workOrderId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-order-logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (workOrderId !== undefined) {
        localVarQueryParameter['workOrderId'] = workOrderId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a work order log
     * @param {number} id Work order log ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceV1Read: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('workOrderLogResourceV1Read', 'id', id);
      const localVarPath = `/v1/work-order-logs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a work order
     * @param {WorkOrderCreateViewUnion} [workOrderCreateViewUnion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceCreate: async (
      workOrderCreateViewUnion?: WorkOrderCreateViewUnion,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workOrderCreateViewUnion,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a work order (anonymous)
     * @param {WorkOrderAnonymousCreateView} [workOrderAnonymousCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceCreateAnonymous: async (
      workOrderAnonymousCreateView?: WorkOrderAnonymousCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-orders/anonymous`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workOrderAnonymousCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List work orders
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [tenantId] Filter by tenant
     * @param {'RECEIVED' | 'STARTED' | 'FINISHED'} [status] Filter by status
     * @param {'GROUND' | 'PORT_DOOR' | 'INDOOR_CLIMATE' | 'FIRE_PROTECTION' | 'BUILDING' | 'SHARED_SPACE' | 'WATER_DRAIN' | 'INSPECTION_NOTE'} [category] Filter by category
     * @param {boolean} [isFinished] Filter by whether work order is finished or not
     * @param {'created_asc' | 'created_desc' | 'finished_asc' | 'finished_desc' | 'intuitive'} [sortBy] Sort by
     * @param {string} [createdBefore] Filter by created before
     * @param {string} [createdAfter] Filter by created after
     * @param {string} [finishedBefore] Filter by finished before
     * @param {string} [finishedAfter] Filter by finished after
     * @param {string} [search] Filter by search term
     * @param {number} [governmentInspectionWorkOrderId] Filter by government inspection work order
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [excludePropertyAssigneeId] Filter by excluding property\&#39;s assignee
     * @param {number} [assigneeId] Filter by fault report\&#39;s assignee
     * @param {number} [excludeAssigneeId] Filter by excluding fault report for assignee
     * @param {number} [connectedToPropertyUserId] Filter by fault report\&#39;s assignee or an existing user property subscription
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<number>} [assigneeIds] Filter by fault report assignees
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {Array<number>} [tenantIds] Filter by tenants
     * @param {Array<'RECEIVED' | 'STARTED' | 'FINISHED'>} [statuses] Filter by fault report statuses
     * @param {Array<'GROUND' | 'PORT_DOOR' | 'INDOOR_CLIMATE' | 'FIRE_PROTECTION' | 'BUILDING' | 'SHARED_SPACE' | 'WATER_DRAIN' | 'INSPECTION_NOTE'>} [categories] Filter by fault report categories
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceList: async (
      limit?: number,
      offset?: number,
      propertyId?: number,
      tenantId?: number,
      status?: 'RECEIVED' | 'STARTED' | 'FINISHED',
      category?:
        | 'GROUND'
        | 'PORT_DOOR'
        | 'INDOOR_CLIMATE'
        | 'FIRE_PROTECTION'
        | 'BUILDING'
        | 'SHARED_SPACE'
        | 'WATER_DRAIN'
        | 'INSPECTION_NOTE',
      isFinished?: boolean,
      sortBy?: 'created_asc' | 'created_desc' | 'finished_asc' | 'finished_desc' | 'intuitive',
      createdBefore?: string,
      createdAfter?: string,
      finishedBefore?: string,
      finishedAfter?: string,
      search?: string,
      governmentInspectionWorkOrderId?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      excludePropertyAssigneeId?: number,
      assigneeId?: number,
      excludeAssigneeId?: number,
      connectedToPropertyUserId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      assigneeIds?: Array<number>,
      propertyIds?: Array<number>,
      tenantIds?: Array<number>,
      statuses?: Array<'RECEIVED' | 'STARTED' | 'FINISHED'>,
      categories?: Array<
        | 'GROUND'
        | 'PORT_DOOR'
        | 'INDOOR_CLIMATE'
        | 'FIRE_PROTECTION'
        | 'BUILDING'
        | 'SHARED_SPACE'
        | 'WATER_DRAIN'
        | 'INSPECTION_NOTE'
      >,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (tenantId !== undefined) {
        localVarQueryParameter['tenantId'] = tenantId;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (isFinished !== undefined) {
        localVarQueryParameter['isFinished'] = isFinished;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      if (createdBefore !== undefined) {
        localVarQueryParameter['created_before'] =
          (createdBefore as any) instanceof Date ? (createdBefore as any).toISOString() : createdBefore;
      }

      if (createdAfter !== undefined) {
        localVarQueryParameter['created_after'] =
          (createdAfter as any) instanceof Date ? (createdAfter as any).toISOString() : createdAfter;
      }

      if (finishedBefore !== undefined) {
        localVarQueryParameter['finished_before'] =
          (finishedBefore as any) instanceof Date ? (finishedBefore as any).toISOString() : finishedBefore;
      }

      if (finishedAfter !== undefined) {
        localVarQueryParameter['finished_after'] =
          (finishedAfter as any) instanceof Date ? (finishedAfter as any).toISOString() : finishedAfter;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (governmentInspectionWorkOrderId !== undefined) {
        localVarQueryParameter['governmentInspectionWorkOrderId'] = governmentInspectionWorkOrderId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyAssigneeId !== undefined) {
        localVarQueryParameter['propertyAssigneeId'] = propertyAssigneeId;
      }

      if (excludePropertyAssigneeId !== undefined) {
        localVarQueryParameter['excludePropertyAssigneeId'] = excludePropertyAssigneeId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (excludeAssigneeId !== undefined) {
        localVarQueryParameter['excludeAssigneeId'] = excludeAssigneeId;
      }

      if (connectedToPropertyUserId !== undefined) {
        localVarQueryParameter['connectedToPropertyUserId'] = connectedToPropertyUserId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (assigneeIds) {
        localVarQueryParameter['assigneeIds'] = assigneeIds;
      }

      if (propertyIds) {
        localVarQueryParameter['propertyIds'] = propertyIds;
      }

      if (tenantIds) {
        localVarQueryParameter['tenantIds'] = tenantIds;
      }

      if (statuses) {
        localVarQueryParameter['statuses'] = statuses;
      }

      if (categories) {
        localVarQueryParameter['categories'] = categories;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Will return an error if there are more than 3 matching names or no matching tenants.
     * @summary List addresses of the units where matching tenants have ongoing contracts
     * @param {MatchTenantNamesRequestView} [matchTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceListOngoingAddresses: async (
      matchTenantNamesRequestView?: MatchTenantNamesRequestView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-orders/anonymous/list-ongoing-addresses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        matchTenantNamesRequestView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List all cities where there are ongoing leases
     * @param {ListOngoingCitiesRequestView} [listOngoingCitiesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceListOngoingCities: async (
      listOngoingCitiesRequestView?: ListOngoingCitiesRequestView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-orders/anonymous/list-ongoing-cities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        listOngoingCitiesRequestView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List addresses of the units in the given city
     * @param {ListOngoingCityAddressesRequestView} [listOngoingCityAddressesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceListOngoingCityAddresses: async (
      listOngoingCityAddressesRequestView?: ListOngoingCityAddressesRequestView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-orders/anonymous/list-ongoing-city-addresses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        listOngoingCityAddressesRequestView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Will return an error if provided parameters match more than one property address.
     * @summary List tenant names
     * @param {ListTenantNamesRequestView} [listTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceListTenantNames: async (
      listTenantNamesRequestView?: ListTenantNamesRequestView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-orders/anonymous/list-tenant-names`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        listTenantNamesRequestView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Will return an error if there are more than 3 matching names or no matching tenants.
     * @summary Get matching tenant names
     * @param {MatchTenantNamesRequestView} [matchTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceMatchTenantNames: async (
      matchTenantNamesRequestView?: MatchTenantNamesRequestView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/work-orders/anonymous/match-tenant-names`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        matchTenantNamesRequestView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a work order
     * @param {number} id Work order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('workOrderResourceRead', 'id', id);
      const localVarPath = `/v1/work-orders/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a work order
     * @param {number} id Work order ID
     * @param {WorkOrderUpdateView} [workOrderUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceUpdate: async (
      id: number,
      workOrderUpdateView?: WorkOrderUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('workOrderResourceUpdate', 'id', id);
      const localVarPath = `/v1/work-orders/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(workOrderUpdateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * WorkOrderApi - functional programming interface
 * @export
 */
export const WorkOrderApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkOrderApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchResourceList(
      query: string,
      limit: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchResourceList(query, limit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a work order log
     * @param {WorkOrderLogCreateViewUnion} [workOrderLogCreateViewUnion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderLogResourceCreate(
      workOrderLogCreateViewUnion?: WorkOrderLogCreateViewUnion,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderLogReadViewUnion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderLogResourceCreate(
        workOrderLogCreateViewUnion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List work order logs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [workOrderId] Filter by work order
     * @param {number} [parentLogId] Filter by parent log
     * @param {boolean} [isTopLevel] Filter by whether log is top level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderLogResourceList(
      limit?: number,
      offset?: number,
      workOrderId?: number,
      parentLogId?: number,
      isTopLevel?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderLogListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderLogResourceList(
        limit,
        offset,
        workOrderId,
        parentLogId,
        isTopLevel,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a work order log
     * @param {number} id Work order log ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderLogResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderLogReadViewUnion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderLogResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a work order log
     * @param {number} id Work order log ID
     * @param {WorkOrderLogUpdateView} [workOrderLogUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderLogResourceUpdate(
      id: number,
      workOrderLogUpdateView?: WorkOrderLogUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderLogReadViewUnion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderLogResourceUpdate(
        id,
        workOrderLogUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a work order log
     * @param {WorkOrderLogCreateViewV1} [workOrderLogCreateViewV1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderLogResourceV1Create(
      workOrderLogCreateViewV1?: WorkOrderLogCreateViewV1,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderLogReadViewV1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderLogResourceV1Create(
        workOrderLogCreateViewV1,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List work order logs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [workOrderId] Filter by work order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderLogResourceV1List(
      limit?: number,
      offset?: number,
      workOrderId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderLogListViewV1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderLogResourceV1List(
        limit,
        offset,
        workOrderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a work order log
     * @param {number} id Work order log ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderLogResourceV1Read(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderLogReadViewV1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderLogResourceV1Read(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a work order
     * @param {WorkOrderCreateViewUnion} [workOrderCreateViewUnion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceCreate(
      workOrderCreateViewUnion?: WorkOrderCreateViewUnion,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceCreate(
        workOrderCreateViewUnion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a work order (anonymous)
     * @param {WorkOrderAnonymousCreateView} [workOrderAnonymousCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceCreateAnonymous(
      workOrderAnonymousCreateView?: WorkOrderAnonymousCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceCreateAnonymous(
        workOrderAnonymousCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List work orders
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [tenantId] Filter by tenant
     * @param {'RECEIVED' | 'STARTED' | 'FINISHED'} [status] Filter by status
     * @param {'GROUND' | 'PORT_DOOR' | 'INDOOR_CLIMATE' | 'FIRE_PROTECTION' | 'BUILDING' | 'SHARED_SPACE' | 'WATER_DRAIN' | 'INSPECTION_NOTE'} [category] Filter by category
     * @param {boolean} [isFinished] Filter by whether work order is finished or not
     * @param {'created_asc' | 'created_desc' | 'finished_asc' | 'finished_desc' | 'intuitive'} [sortBy] Sort by
     * @param {string} [createdBefore] Filter by created before
     * @param {string} [createdAfter] Filter by created after
     * @param {string} [finishedBefore] Filter by finished before
     * @param {string} [finishedAfter] Filter by finished after
     * @param {string} [search] Filter by search term
     * @param {number} [governmentInspectionWorkOrderId] Filter by government inspection work order
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [excludePropertyAssigneeId] Filter by excluding property\&#39;s assignee
     * @param {number} [assigneeId] Filter by fault report\&#39;s assignee
     * @param {number} [excludeAssigneeId] Filter by excluding fault report for assignee
     * @param {number} [connectedToPropertyUserId] Filter by fault report\&#39;s assignee or an existing user property subscription
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<number>} [assigneeIds] Filter by fault report assignees
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {Array<number>} [tenantIds] Filter by tenants
     * @param {Array<'RECEIVED' | 'STARTED' | 'FINISHED'>} [statuses] Filter by fault report statuses
     * @param {Array<'GROUND' | 'PORT_DOOR' | 'INDOOR_CLIMATE' | 'FIRE_PROTECTION' | 'BUILDING' | 'SHARED_SPACE' | 'WATER_DRAIN' | 'INSPECTION_NOTE'>} [categories] Filter by fault report categories
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      tenantId?: number,
      status?: 'RECEIVED' | 'STARTED' | 'FINISHED',
      category?:
        | 'GROUND'
        | 'PORT_DOOR'
        | 'INDOOR_CLIMATE'
        | 'FIRE_PROTECTION'
        | 'BUILDING'
        | 'SHARED_SPACE'
        | 'WATER_DRAIN'
        | 'INSPECTION_NOTE',
      isFinished?: boolean,
      sortBy?: 'created_asc' | 'created_desc' | 'finished_asc' | 'finished_desc' | 'intuitive',
      createdBefore?: string,
      createdAfter?: string,
      finishedBefore?: string,
      finishedAfter?: string,
      search?: string,
      governmentInspectionWorkOrderId?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      excludePropertyAssigneeId?: number,
      assigneeId?: number,
      excludeAssigneeId?: number,
      connectedToPropertyUserId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      assigneeIds?: Array<number>,
      propertyIds?: Array<number>,
      tenantIds?: Array<number>,
      statuses?: Array<'RECEIVED' | 'STARTED' | 'FINISHED'>,
      categories?: Array<
        | 'GROUND'
        | 'PORT_DOOR'
        | 'INDOOR_CLIMATE'
        | 'FIRE_PROTECTION'
        | 'BUILDING'
        | 'SHARED_SPACE'
        | 'WATER_DRAIN'
        | 'INSPECTION_NOTE'
      >,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceList(
        limit,
        offset,
        propertyId,
        tenantId,
        status,
        category,
        isFinished,
        sortBy,
        createdBefore,
        createdAfter,
        finishedBefore,
        finishedAfter,
        search,
        governmentInspectionWorkOrderId,
        organisationId,
        portfolioId,
        propertyAssigneeId,
        excludePropertyAssigneeId,
        assigneeId,
        excludeAssigneeId,
        connectedToPropertyUserId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        assigneeIds,
        propertyIds,
        tenantIds,
        statuses,
        categories,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Will return an error if there are more than 3 matching names or no matching tenants.
     * @summary List addresses of the units where matching tenants have ongoing contracts
     * @param {MatchTenantNamesRequestView} [matchTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceListOngoingAddresses(
      matchTenantNamesRequestView?: MatchTenantNamesRequestView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressesResultView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceListOngoingAddresses(
        matchTenantNamesRequestView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List all cities where there are ongoing leases
     * @param {ListOngoingCitiesRequestView} [listOngoingCitiesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceListOngoingCities(
      listOngoingCitiesRequestView?: ListOngoingCitiesRequestView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceListOngoingCities(
        listOngoingCitiesRequestView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List addresses of the units in the given city
     * @param {ListOngoingCityAddressesRequestView} [listOngoingCityAddressesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceListOngoingCityAddresses(
      listOngoingCityAddressesRequestView?: ListOngoingCityAddressesRequestView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressesResultView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceListOngoingCityAddresses(
        listOngoingCityAddressesRequestView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Will return an error if provided parameters match more than one property address.
     * @summary List tenant names
     * @param {ListTenantNamesRequestView} [listTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceListTenantNames(
      listTenantNamesRequestView?: ListTenantNamesRequestView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantNamesResultView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceListTenantNames(
        listTenantNamesRequestView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Will return an error if there are more than 3 matching names or no matching tenants.
     * @summary Get matching tenant names
     * @param {MatchTenantNamesRequestView} [matchTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceMatchTenantNames(
      matchTenantNamesRequestView?: MatchTenantNamesRequestView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantNamesResultView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceMatchTenantNames(
        matchTenantNamesRequestView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a work order
     * @param {number} id Work order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a work order
     * @param {number} id Work order ID
     * @param {WorkOrderUpdateView} [workOrderUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workOrderResourceUpdate(
      id: number,
      workOrderUpdateView?: WorkOrderUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workOrderResourceUpdate(
        id,
        workOrderUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * WorkOrderApi - factory interface
 * @export
 */
export const WorkOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WorkOrderApiFp(configuration);
  return {
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchResourceList(query: string, limit: number, options?: any): AxiosPromise<SearchReadView> {
      return localVarFp.searchResourceList(query, limit, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a work order log
     * @param {WorkOrderLogCreateViewUnion} [workOrderLogCreateViewUnion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceCreate(
      workOrderLogCreateViewUnion?: WorkOrderLogCreateViewUnion,
      options?: any
    ): AxiosPromise<WorkOrderLogReadViewUnion> {
      return localVarFp
        .workOrderLogResourceCreate(workOrderLogCreateViewUnion, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List work order logs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [workOrderId] Filter by work order
     * @param {number} [parentLogId] Filter by parent log
     * @param {boolean} [isTopLevel] Filter by whether log is top level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceList(
      limit?: number,
      offset?: number,
      workOrderId?: number,
      parentLogId?: number,
      isTopLevel?: boolean,
      options?: any
    ): AxiosPromise<WorkOrderLogListView> {
      return localVarFp
        .workOrderLogResourceList(limit, offset, workOrderId, parentLogId, isTopLevel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a work order log
     * @param {number} id Work order log ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceRead(id: number, options?: any): AxiosPromise<WorkOrderLogReadViewUnion> {
      return localVarFp.workOrderLogResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a work order log
     * @param {number} id Work order log ID
     * @param {WorkOrderLogUpdateView} [workOrderLogUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceUpdate(
      id: number,
      workOrderLogUpdateView?: WorkOrderLogUpdateView,
      options?: any
    ): AxiosPromise<WorkOrderLogReadViewUnion> {
      return localVarFp
        .workOrderLogResourceUpdate(id, workOrderLogUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a work order log
     * @param {WorkOrderLogCreateViewV1} [workOrderLogCreateViewV1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceV1Create(
      workOrderLogCreateViewV1?: WorkOrderLogCreateViewV1,
      options?: any
    ): AxiosPromise<WorkOrderLogReadViewV1> {
      return localVarFp
        .workOrderLogResourceV1Create(workOrderLogCreateViewV1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List work order logs
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [workOrderId] Filter by work order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceV1List(
      limit?: number,
      offset?: number,
      workOrderId?: number,
      options?: any
    ): AxiosPromise<WorkOrderLogListViewV1> {
      return localVarFp
        .workOrderLogResourceV1List(limit, offset, workOrderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a work order log
     * @param {number} id Work order log ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderLogResourceV1Read(id: number, options?: any): AxiosPromise<WorkOrderLogReadViewV1> {
      return localVarFp.workOrderLogResourceV1Read(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a work order
     * @param {WorkOrderCreateViewUnion} [workOrderCreateViewUnion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceCreate(
      workOrderCreateViewUnion?: WorkOrderCreateViewUnion,
      options?: any
    ): AxiosPromise<WorkOrderReadView> {
      return localVarFp
        .workOrderResourceCreate(workOrderCreateViewUnion, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a work order (anonymous)
     * @param {WorkOrderAnonymousCreateView} [workOrderAnonymousCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceCreateAnonymous(
      workOrderAnonymousCreateView?: WorkOrderAnonymousCreateView,
      options?: any
    ): AxiosPromise<WorkOrderReadView> {
      return localVarFp
        .workOrderResourceCreateAnonymous(workOrderAnonymousCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List work orders
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [propertyId] Filter by property
     * @param {number} [tenantId] Filter by tenant
     * @param {'RECEIVED' | 'STARTED' | 'FINISHED'} [status] Filter by status
     * @param {'GROUND' | 'PORT_DOOR' | 'INDOOR_CLIMATE' | 'FIRE_PROTECTION' | 'BUILDING' | 'SHARED_SPACE' | 'WATER_DRAIN' | 'INSPECTION_NOTE'} [category] Filter by category
     * @param {boolean} [isFinished] Filter by whether work order is finished or not
     * @param {'created_asc' | 'created_desc' | 'finished_asc' | 'finished_desc' | 'intuitive'} [sortBy] Sort by
     * @param {string} [createdBefore] Filter by created before
     * @param {string} [createdAfter] Filter by created after
     * @param {string} [finishedBefore] Filter by finished before
     * @param {string} [finishedAfter] Filter by finished after
     * @param {string} [search] Filter by search term
     * @param {number} [governmentInspectionWorkOrderId] Filter by government inspection work order
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [excludePropertyAssigneeId] Filter by excluding property\&#39;s assignee
     * @param {number} [assigneeId] Filter by fault report\&#39;s assignee
     * @param {number} [excludeAssigneeId] Filter by excluding fault report for assignee
     * @param {number} [connectedToPropertyUserId] Filter by fault report\&#39;s assignee or an existing user property subscription
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<number>} [assigneeIds] Filter by fault report assignees
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {Array<number>} [tenantIds] Filter by tenants
     * @param {Array<'RECEIVED' | 'STARTED' | 'FINISHED'>} [statuses] Filter by fault report statuses
     * @param {Array<'GROUND' | 'PORT_DOOR' | 'INDOOR_CLIMATE' | 'FIRE_PROTECTION' | 'BUILDING' | 'SHARED_SPACE' | 'WATER_DRAIN' | 'INSPECTION_NOTE'>} [categories] Filter by fault report categories
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceList(
      limit?: number,
      offset?: number,
      propertyId?: number,
      tenantId?: number,
      status?: 'RECEIVED' | 'STARTED' | 'FINISHED',
      category?:
        | 'GROUND'
        | 'PORT_DOOR'
        | 'INDOOR_CLIMATE'
        | 'FIRE_PROTECTION'
        | 'BUILDING'
        | 'SHARED_SPACE'
        | 'WATER_DRAIN'
        | 'INSPECTION_NOTE',
      isFinished?: boolean,
      sortBy?: 'created_asc' | 'created_desc' | 'finished_asc' | 'finished_desc' | 'intuitive',
      createdBefore?: string,
      createdAfter?: string,
      finishedBefore?: string,
      finishedAfter?: string,
      search?: string,
      governmentInspectionWorkOrderId?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      excludePropertyAssigneeId?: number,
      assigneeId?: number,
      excludeAssigneeId?: number,
      connectedToPropertyUserId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      assigneeIds?: Array<number>,
      propertyIds?: Array<number>,
      tenantIds?: Array<number>,
      statuses?: Array<'RECEIVED' | 'STARTED' | 'FINISHED'>,
      categories?: Array<
        | 'GROUND'
        | 'PORT_DOOR'
        | 'INDOOR_CLIMATE'
        | 'FIRE_PROTECTION'
        | 'BUILDING'
        | 'SHARED_SPACE'
        | 'WATER_DRAIN'
        | 'INSPECTION_NOTE'
      >,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<WorkOrderListView> {
      return localVarFp
        .workOrderResourceList(
          limit,
          offset,
          propertyId,
          tenantId,
          status,
          category,
          isFinished,
          sortBy,
          createdBefore,
          createdAfter,
          finishedBefore,
          finishedAfter,
          search,
          governmentInspectionWorkOrderId,
          organisationId,
          portfolioId,
          propertyAssigneeId,
          excludePropertyAssigneeId,
          assigneeId,
          excludeAssigneeId,
          connectedToPropertyUserId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          assigneeIds,
          propertyIds,
          tenantIds,
          statuses,
          categories,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Will return an error if there are more than 3 matching names or no matching tenants.
     * @summary List addresses of the units where matching tenants have ongoing contracts
     * @param {MatchTenantNamesRequestView} [matchTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceListOngoingAddresses(
      matchTenantNamesRequestView?: MatchTenantNamesRequestView,
      options?: any
    ): AxiosPromise<AddressesResultView> {
      return localVarFp
        .workOrderResourceListOngoingAddresses(matchTenantNamesRequestView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List all cities where there are ongoing leases
     * @param {ListOngoingCitiesRequestView} [listOngoingCitiesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceListOngoingCities(
      listOngoingCitiesRequestView?: ListOngoingCitiesRequestView,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .workOrderResourceListOngoingCities(listOngoingCitiesRequestView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List addresses of the units in the given city
     * @param {ListOngoingCityAddressesRequestView} [listOngoingCityAddressesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceListOngoingCityAddresses(
      listOngoingCityAddressesRequestView?: ListOngoingCityAddressesRequestView,
      options?: any
    ): AxiosPromise<AddressesResultView> {
      return localVarFp
        .workOrderResourceListOngoingCityAddresses(listOngoingCityAddressesRequestView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Will return an error if provided parameters match more than one property address.
     * @summary List tenant names
     * @param {ListTenantNamesRequestView} [listTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceListTenantNames(
      listTenantNamesRequestView?: ListTenantNamesRequestView,
      options?: any
    ): AxiosPromise<TenantNamesResultView> {
      return localVarFp
        .workOrderResourceListTenantNames(listTenantNamesRequestView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Will return an error if there are more than 3 matching names or no matching tenants.
     * @summary Get matching tenant names
     * @param {MatchTenantNamesRequestView} [matchTenantNamesRequestView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceMatchTenantNames(
      matchTenantNamesRequestView?: MatchTenantNamesRequestView,
      options?: any
    ): AxiosPromise<TenantNamesResultView> {
      return localVarFp
        .workOrderResourceMatchTenantNames(matchTenantNamesRequestView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a work order
     * @param {number} id Work order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceRead(id: number, options?: any): AxiosPromise<WorkOrderReadView> {
      return localVarFp.workOrderResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a work order
     * @param {number} id Work order ID
     * @param {WorkOrderUpdateView} [workOrderUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workOrderResourceUpdate(
      id: number,
      workOrderUpdateView?: WorkOrderUpdateView,
      options?: any
    ): AxiosPromise<WorkOrderReadView> {
      return localVarFp
        .workOrderResourceUpdate(id, workOrderUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for searchResourceList operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiSearchResourceListRequest
 */
export interface WorkOrderApiSearchResourceListRequest {
  /**
   * Search term
   * @type {string}
   * @memberof WorkOrderApiSearchResourceList
   */
  readonly query: string;

  /**
   * Maximum amount of items per kind
   * @type {number}
   * @memberof WorkOrderApiSearchResourceList
   */
  readonly limit: number;
}

/**
 * Request parameters for workOrderLogResourceCreate operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderLogResourceCreateRequest
 */
export interface WorkOrderApiWorkOrderLogResourceCreateRequest {
  /**
   *
   * @type {WorkOrderLogCreateViewUnion}
   * @memberof WorkOrderApiWorkOrderLogResourceCreate
   */
  readonly workOrderLogCreateViewUnion?: WorkOrderLogCreateViewUnion;
}

/**
 * Request parameters for workOrderLogResourceList operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderLogResourceListRequest
 */
export interface WorkOrderApiWorkOrderLogResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceList
   */
  readonly offset?: number;

  /**
   * Filter by work order
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceList
   */
  readonly workOrderId?: number;

  /**
   * Filter by parent log
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceList
   */
  readonly parentLogId?: number;

  /**
   * Filter by whether log is top level
   * @type {boolean}
   * @memberof WorkOrderApiWorkOrderLogResourceList
   */
  readonly isTopLevel?: boolean;
}

/**
 * Request parameters for workOrderLogResourceRead operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderLogResourceReadRequest
 */
export interface WorkOrderApiWorkOrderLogResourceReadRequest {
  /**
   * Work order log ID
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for workOrderLogResourceUpdate operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderLogResourceUpdateRequest
 */
export interface WorkOrderApiWorkOrderLogResourceUpdateRequest {
  /**
   * Work order log ID
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {WorkOrderLogUpdateView}
   * @memberof WorkOrderApiWorkOrderLogResourceUpdate
   */
  readonly workOrderLogUpdateView?: WorkOrderLogUpdateView;
}

/**
 * Request parameters for workOrderLogResourceV1Create operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderLogResourceV1CreateRequest
 */
export interface WorkOrderApiWorkOrderLogResourceV1CreateRequest {
  /**
   *
   * @type {WorkOrderLogCreateViewV1}
   * @memberof WorkOrderApiWorkOrderLogResourceV1Create
   */
  readonly workOrderLogCreateViewV1?: WorkOrderLogCreateViewV1;
}

/**
 * Request parameters for workOrderLogResourceV1List operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderLogResourceV1ListRequest
 */
export interface WorkOrderApiWorkOrderLogResourceV1ListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceV1List
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceV1List
   */
  readonly offset?: number;

  /**
   * Filter by work order
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceV1List
   */
  readonly workOrderId?: number;
}

/**
 * Request parameters for workOrderLogResourceV1Read operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderLogResourceV1ReadRequest
 */
export interface WorkOrderApiWorkOrderLogResourceV1ReadRequest {
  /**
   * Work order log ID
   * @type {number}
   * @memberof WorkOrderApiWorkOrderLogResourceV1Read
   */
  readonly id: number;
}

/**
 * Request parameters for workOrderResourceCreate operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceCreateRequest
 */
export interface WorkOrderApiWorkOrderResourceCreateRequest {
  /**
   *
   * @type {WorkOrderCreateViewUnion}
   * @memberof WorkOrderApiWorkOrderResourceCreate
   */
  readonly workOrderCreateViewUnion?: WorkOrderCreateViewUnion;
}

/**
 * Request parameters for workOrderResourceCreateAnonymous operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceCreateAnonymousRequest
 */
export interface WorkOrderApiWorkOrderResourceCreateAnonymousRequest {
  /**
   *
   * @type {WorkOrderAnonymousCreateView}
   * @memberof WorkOrderApiWorkOrderResourceCreateAnonymous
   */
  readonly workOrderAnonymousCreateView?: WorkOrderAnonymousCreateView;
}

/**
 * Request parameters for workOrderResourceList operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceListRequest
 */
export interface WorkOrderApiWorkOrderResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly offset?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by tenant
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly tenantId?: number;

  /**
   * Filter by status
   * @type {'RECEIVED' | 'STARTED' | 'FINISHED'}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly status?: 'RECEIVED' | 'STARTED' | 'FINISHED';

  /**
   * Filter by category
   * @type {'GROUND' | 'PORT_DOOR' | 'INDOOR_CLIMATE' | 'FIRE_PROTECTION' | 'BUILDING' | 'SHARED_SPACE' | 'WATER_DRAIN' | 'INSPECTION_NOTE'}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly category?:
    | 'GROUND'
    | 'PORT_DOOR'
    | 'INDOOR_CLIMATE'
    | 'FIRE_PROTECTION'
    | 'BUILDING'
    | 'SHARED_SPACE'
    | 'WATER_DRAIN'
    | 'INSPECTION_NOTE';

  /**
   * Filter by whether work order is finished or not
   * @type {boolean}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly isFinished?: boolean;

  /**
   * Sort by
   * @type {'created_asc' | 'created_desc' | 'finished_asc' | 'finished_desc' | 'intuitive'}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly sortBy?: 'created_asc' | 'created_desc' | 'finished_asc' | 'finished_desc' | 'intuitive';

  /**
   * Filter by created before
   * @type {string}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly createdBefore?: string;

  /**
   * Filter by created after
   * @type {string}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly createdAfter?: string;

  /**
   * Filter by finished before
   * @type {string}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly finishedBefore?: string;

  /**
   * Filter by finished after
   * @type {string}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly finishedAfter?: string;

  /**
   * Filter by search term
   * @type {string}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly search?: string;

  /**
   * Filter by government inspection work order
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly governmentInspectionWorkOrderId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by property\&#39;s portfolio
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by property\&#39;s assignee
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly propertyAssigneeId?: number;

  /**
   * Filter by excluding property\&#39;s assignee
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly excludePropertyAssigneeId?: number;

  /**
   * Filter by fault report\&#39;s assignee
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly assigneeId?: number;

  /**
   * Filter by excluding fault report for assignee
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly excludeAssigneeId?: number;

  /**
   * Filter by fault report\&#39;s assignee or an existing user property subscription
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly connectedToPropertyUserId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by fault report assignees
   * @type {Array<number>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly assigneeIds?: Array<number>;

  /**
   * Filter by properties
   * @type {Array<number>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly propertyIds?: Array<number>;

  /**
   * Filter by tenants
   * @type {Array<number>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly tenantIds?: Array<number>;

  /**
   * Filter by fault report statuses
   * @type {Array<'RECEIVED' | 'STARTED' | 'FINISHED'>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly statuses?: Array<'RECEIVED' | 'STARTED' | 'FINISHED'>;

  /**
   * Filter by fault report categories
   * @type {Array<'GROUND' | 'PORT_DOOR' | 'INDOOR_CLIMATE' | 'FIRE_PROTECTION' | 'BUILDING' | 'SHARED_SPACE' | 'WATER_DRAIN' | 'INSPECTION_NOTE'>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly categories?: Array<
    | 'GROUND'
    | 'PORT_DOOR'
    | 'INDOOR_CLIMATE'
    | 'FIRE_PROTECTION'
    | 'BUILDING'
    | 'SHARED_SPACE'
    | 'WATER_DRAIN'
    | 'INSPECTION_NOTE'
  >;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof WorkOrderApiWorkOrderResourceList
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for workOrderResourceListOngoingAddresses operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceListOngoingAddressesRequest
 */
export interface WorkOrderApiWorkOrderResourceListOngoingAddressesRequest {
  /**
   *
   * @type {MatchTenantNamesRequestView}
   * @memberof WorkOrderApiWorkOrderResourceListOngoingAddresses
   */
  readonly matchTenantNamesRequestView?: MatchTenantNamesRequestView;
}

/**
 * Request parameters for workOrderResourceListOngoingCities operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceListOngoingCitiesRequest
 */
export interface WorkOrderApiWorkOrderResourceListOngoingCitiesRequest {
  /**
   *
   * @type {ListOngoingCitiesRequestView}
   * @memberof WorkOrderApiWorkOrderResourceListOngoingCities
   */
  readonly listOngoingCitiesRequestView?: ListOngoingCitiesRequestView;
}

/**
 * Request parameters for workOrderResourceListOngoingCityAddresses operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceListOngoingCityAddressesRequest
 */
export interface WorkOrderApiWorkOrderResourceListOngoingCityAddressesRequest {
  /**
   *
   * @type {ListOngoingCityAddressesRequestView}
   * @memberof WorkOrderApiWorkOrderResourceListOngoingCityAddresses
   */
  readonly listOngoingCityAddressesRequestView?: ListOngoingCityAddressesRequestView;
}

/**
 * Request parameters for workOrderResourceListTenantNames operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceListTenantNamesRequest
 */
export interface WorkOrderApiWorkOrderResourceListTenantNamesRequest {
  /**
   *
   * @type {ListTenantNamesRequestView}
   * @memberof WorkOrderApiWorkOrderResourceListTenantNames
   */
  readonly listTenantNamesRequestView?: ListTenantNamesRequestView;
}

/**
 * Request parameters for workOrderResourceMatchTenantNames operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceMatchTenantNamesRequest
 */
export interface WorkOrderApiWorkOrderResourceMatchTenantNamesRequest {
  /**
   *
   * @type {MatchTenantNamesRequestView}
   * @memberof WorkOrderApiWorkOrderResourceMatchTenantNames
   */
  readonly matchTenantNamesRequestView?: MatchTenantNamesRequestView;
}

/**
 * Request parameters for workOrderResourceRead operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceReadRequest
 */
export interface WorkOrderApiWorkOrderResourceReadRequest {
  /**
   * Work order ID
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for workOrderResourceUpdate operation in WorkOrderApi.
 * @export
 * @interface WorkOrderApiWorkOrderResourceUpdateRequest
 */
export interface WorkOrderApiWorkOrderResourceUpdateRequest {
  /**
   * Work order ID
   * @type {number}
   * @memberof WorkOrderApiWorkOrderResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {WorkOrderUpdateView}
   * @memberof WorkOrderApiWorkOrderResourceUpdate
   */
  readonly workOrderUpdateView?: WorkOrderUpdateView;
}

/**
 * WorkOrderApi - object-oriented interface
 * @export
 * @class WorkOrderApi
 * @extends {BaseAPI}
 */
export class WorkOrderApi extends BaseAPI {
  /**
   *
   * @summary Search for things
   * @param {WorkOrderApiSearchResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public searchResourceList(requestParameters: WorkOrderApiSearchResourceListRequest, options?: AxiosRequestConfig) {
    return WorkOrderApiFp(this.configuration)
      .searchResourceList(requestParameters.query, requestParameters.limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a work order log
   * @param {WorkOrderApiWorkOrderLogResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderLogResourceCreate(
    requestParameters: WorkOrderApiWorkOrderLogResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderLogResourceCreate(requestParameters.workOrderLogCreateViewUnion, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List work order logs
   * @param {WorkOrderApiWorkOrderLogResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderLogResourceList(
    requestParameters: WorkOrderApiWorkOrderLogResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderLogResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.workOrderId,
        requestParameters.parentLogId,
        requestParameters.isTopLevel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a work order log
   * @param {WorkOrderApiWorkOrderLogResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderLogResourceRead(
    requestParameters: WorkOrderApiWorkOrderLogResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderLogResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a work order log
   * @param {WorkOrderApiWorkOrderLogResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderLogResourceUpdate(
    requestParameters: WorkOrderApiWorkOrderLogResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderLogResourceUpdate(requestParameters.id, requestParameters.workOrderLogUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a work order log
   * @param {WorkOrderApiWorkOrderLogResourceV1CreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderLogResourceV1Create(
    requestParameters: WorkOrderApiWorkOrderLogResourceV1CreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderLogResourceV1Create(requestParameters.workOrderLogCreateViewV1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List work order logs
   * @param {WorkOrderApiWorkOrderLogResourceV1ListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderLogResourceV1List(
    requestParameters: WorkOrderApiWorkOrderLogResourceV1ListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderLogResourceV1List(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.workOrderId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a work order log
   * @param {WorkOrderApiWorkOrderLogResourceV1ReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderLogResourceV1Read(
    requestParameters: WorkOrderApiWorkOrderLogResourceV1ReadRequest,
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderLogResourceV1Read(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a work order
   * @param {WorkOrderApiWorkOrderResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceCreate(
    requestParameters: WorkOrderApiWorkOrderResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceCreate(requestParameters.workOrderCreateViewUnion, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a work order (anonymous)
   * @param {WorkOrderApiWorkOrderResourceCreateAnonymousRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceCreateAnonymous(
    requestParameters: WorkOrderApiWorkOrderResourceCreateAnonymousRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceCreateAnonymous(requestParameters.workOrderAnonymousCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List work orders
   * @param {WorkOrderApiWorkOrderResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceList(
    requestParameters: WorkOrderApiWorkOrderResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.propertyId,
        requestParameters.tenantId,
        requestParameters.status,
        requestParameters.category,
        requestParameters.isFinished,
        requestParameters.sortBy,
        requestParameters.createdBefore,
        requestParameters.createdAfter,
        requestParameters.finishedBefore,
        requestParameters.finishedAfter,
        requestParameters.search,
        requestParameters.governmentInspectionWorkOrderId,
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.propertyAssigneeId,
        requestParameters.excludePropertyAssigneeId,
        requestParameters.assigneeId,
        requestParameters.excludeAssigneeId,
        requestParameters.connectedToPropertyUserId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.assigneeIds,
        requestParameters.propertyIds,
        requestParameters.tenantIds,
        requestParameters.statuses,
        requestParameters.categories,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Will return an error if there are more than 3 matching names or no matching tenants.
   * @summary List addresses of the units where matching tenants have ongoing contracts
   * @param {WorkOrderApiWorkOrderResourceListOngoingAddressesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceListOngoingAddresses(
    requestParameters: WorkOrderApiWorkOrderResourceListOngoingAddressesRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceListOngoingAddresses(requestParameters.matchTenantNamesRequestView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List all cities where there are ongoing leases
   * @param {WorkOrderApiWorkOrderResourceListOngoingCitiesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceListOngoingCities(
    requestParameters: WorkOrderApiWorkOrderResourceListOngoingCitiesRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceListOngoingCities(requestParameters.listOngoingCitiesRequestView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List addresses of the units in the given city
   * @param {WorkOrderApiWorkOrderResourceListOngoingCityAddressesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceListOngoingCityAddresses(
    requestParameters: WorkOrderApiWorkOrderResourceListOngoingCityAddressesRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceListOngoingCityAddresses(requestParameters.listOngoingCityAddressesRequestView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Will return an error if provided parameters match more than one property address.
   * @summary List tenant names
   * @param {WorkOrderApiWorkOrderResourceListTenantNamesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceListTenantNames(
    requestParameters: WorkOrderApiWorkOrderResourceListTenantNamesRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceListTenantNames(requestParameters.listTenantNamesRequestView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Will return an error if there are more than 3 matching names or no matching tenants.
   * @summary Get matching tenant names
   * @param {WorkOrderApiWorkOrderResourceMatchTenantNamesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceMatchTenantNames(
    requestParameters: WorkOrderApiWorkOrderResourceMatchTenantNamesRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceMatchTenantNames(requestParameters.matchTenantNamesRequestView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a work order
   * @param {WorkOrderApiWorkOrderResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceRead(
    requestParameters: WorkOrderApiWorkOrderResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a work order
   * @param {WorkOrderApiWorkOrderResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderApi
   */
  public workOrderResourceUpdate(
    requestParameters: WorkOrderApiWorkOrderResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return WorkOrderApiFp(this.configuration)
      .workOrderResourceUpdate(requestParameters.id, requestParameters.workOrderUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
