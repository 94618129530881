import { GovernmentInspectionApiGovernmentInspectionResourceListRequest } from '../GeneratedServices';
import { DropdownItemProps, Label } from 'semantic-ui-react';
import i18n from '../i18n';

export type GovernmentInspectionStorageParams = Pick<
  GovernmentInspectionApiGovernmentInspectionResourceListRequest,
  'organisationIds' | 'portfolioIds' | 'propertySubscriptionUserIds' | 'propertyIds' | 'municipalities'
>;

const governmentInspectionStorageParamsInstance: GovernmentInspectionStorageParams = {
  municipalities: [],
  organisationIds: [],
  portfolioIds: [],
  propertyIds: [],
  propertySubscriptionUserIds: []
};

export const governmentInspectionStorageFilterKeys = Object.keys(
  governmentInspectionStorageParamsInstance
) as (keyof GovernmentInspectionStorageParams)[];

export const getFiltersCount = (
  filterParams: GovernmentInspectionApiGovernmentInspectionResourceListRequest,
  customFilterKeys?: (keyof GovernmentInspectionStorageParams)[]
) => {
  let count = 0;
  const filterKeys = new Set(customFilterKeys ?? governmentInspectionStorageFilterKeys);

  Object.entries(filterParams).forEach((item) =>
    filterKeys.has(item[0] as keyof GovernmentInspectionStorageParams) &&
    typeof item[1] === 'object' &&
    item[1].length !== 0
      ? count++
      : null
  );

  return count;
};

export const generateGovernmentInspectionItemGrade = (): DropdownItemProps[] => [
  {
    value: false,
    image: (
      <Label color="red" style={{ whiteSpace: 'nowrap' }}>
        {i18n.t('inspections:inspectionItemStatus.notApproved')}
      </Label>
    ),
    key: 'fail'
  },
  {
    value: true,
    image: <Label color="green">{i18n.t('inspections:inspectionItemStatus.approved')}</Label>,
    key: 'pass'
  }
];
