import { toast } from 'react-toastify';
import { FilterValuesType } from '../FiltersProvider/FiltersProvider';
import i18n from '../i18n';
import { FaultReportStorageParams } from './FaultReportService';
import { GovernmentInspectionStorageParams } from './GovernmentInspectionService';

const setUserSessionStorageItem = (currentUserId: number, key: string, value: Object) => {
  sessionStorage.setItem(`userId:${currentUserId}-${key}`, JSON.stringify(value));
};

const getUserSessionStorageItem = (currentUserId: number, key: string) => {
  return sessionStorage.getItem(`userId:${currentUserId}-${key}`);
};

const setUserLocalStorageItem = (currentUserId: number, key: string, value: Object) => {
  localStorage.setItem(`userId:${currentUserId}-${key}`, JSON.stringify(value));

  toast.info(i18n.t('common:filtersSuccessfullySetAsDefault'));
};

const getUserLocalStorageItem = (currentUserId: number, key: string) => {
  return localStorage.getItem(`userId:${currentUserId}-${key}`);
};

//FAULT REPORT FILTERS
export const setFaultReportSessionFilters = (currentUserId: number, params: FaultReportStorageParams) => {
  setUserSessionStorageItem(currentUserId, 'faultReportFilters', params);
};

export const getFaultReportSessionFilters = (currentUserId: number): FaultReportStorageParams | undefined => {
  const filtersString = getUserSessionStorageItem(currentUserId, 'faultReportFilters');

  return filtersString ? JSON.parse(filtersString) : undefined;
};

export const setFaultReportDefaultFilters = (currentUserId: number, params: FaultReportStorageParams) => {
  setUserLocalStorageItem(currentUserId, 'defaultFaultReportFilters', params);
};

export const getFaultReportDefaultFilters = (currentUserId: number): FaultReportStorageParams | undefined => {
  const filtersString = getUserLocalStorageItem(currentUserId, 'defaultFaultReportFilters');

  return filtersString ? JSON.parse(filtersString) : undefined;
};

//GOVERNMENT INSPECTION FILTERS
export const setGovernmentInspectionSessionFilters = (
  currentUserId: number,
  params: GovernmentInspectionStorageParams
) => {
  setUserSessionStorageItem(currentUserId, 'governmentInspectionFilters', params);
};

export const getGovernmentInspectionSessionFilters = (
  currentUserId: number
): GovernmentInspectionStorageParams | undefined => {
  const filtersString = getUserSessionStorageItem(currentUserId, 'governmentInspectionFilters');

  return filtersString ? JSON.parse(filtersString) : undefined;
};

//GENERAL FILTERS

export const setGeneralSessionFilters = (currentUserId: number, params: FilterValuesType) => {
  setUserSessionStorageItem(currentUserId, 'filters', params);
};

export const getGeneralSessionFilters = (currentUserId: number): FilterValuesType | undefined => {
  const filtersString = getUserSessionStorageItem(currentUserId, 'filters');

  return filtersString ? JSON.parse(filtersString) : undefined;
};

export const setGeneralDefaultFilters = (currentUserId: number, params: FilterValuesType) => {
  setUserLocalStorageItem(currentUserId, 'defaultFilters', params);
};

export const getGeneralDefaultFilters = (currentUserId: number): FilterValuesType | undefined => {
  const filtersString = getUserLocalStorageItem(currentUserId, 'defaultFilters');

  return filtersString ? JSON.parse(filtersString) : undefined;
};
