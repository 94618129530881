import _ from 'lodash';
import { SortDirection } from '../Components/SortableTable/SortableTable';

/**
 * Takes a value and insures that it isn't null or undefined
 * @param value Value to insure.
 * See https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array for more details.
 */
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * Takes an array, sort direction and a column and sorts the array accordingly.
 * @param array Value to insure.
 * @param columnKey Value to insure.
 * @param sortDirection Value to insure.
 */
export function sortObjects<T>(
  array: T[],
  columnKey: string | undefined,
  sortDirection: SortDirection | undefined
): T[] {
  if (columnKey) {
    if (sortDirection === SortDirection.ASC) {
      return _.orderBy(array, [columnKey], ['asc']);
    } else if (sortDirection === SortDirection.DESC) {
      return _.orderBy(array, [columnKey], ['desc']);
    } else return array;
  }
  return array;
}

/**
 * Groups an array into a map based on a provided field.
 * @param array Array.
 * @param grouper Function which returns the field you'd like to group by.
 */
export function groupByToMap<K, V>(array: V[], grouper: (item: V) => K) {
  return array.reduce((store, item) => {
    var key = grouper(item);
    if (!store.has(key)) {
      store.set(key, [item]);
    } else {
      store.get(key)?.push(item);
    }
    return store;
  }, new Map<K, V[]>());
}

/**
 * Converts an itterator of strings to an array of string
 * @param itterator Itterator.
 */
export const toArray = (itterator: IterableIterator<string>) => {
  return Array.from(itterator);
};
