import * as validation from './validation';

export const renderValidationErrors = (result: validation.FieldValidationResult | undefined) => {
  if (!result?.error) {
    return;
  }

  if (result.errorMessage?.length === 1) {
    return result.errorMessage;
  }

  return (
    <div className="ui pointing above prompt label">
      <ul className="input-validation-error-list">
        {result.errorMessage!.map((error) => (
          <li>{error}</li>
        ))}
      </ul>
    </div>
  );
};
