import { globalStateCTX } from '../../../GlobalState/GlobalState';
import React from 'react';
import { Button, DropdownItemProps, Form, Grid, Loader, Modal } from 'semantic-ui-react';
import * as validation from '../../../Utils/validation';
import { UserFriendlyApiResponse } from '../../../Http/response-error';
import { renderValidationErrors } from '../../../Utils/FieldValidationErrorMessage';
import { useDidMountEffect, useNonce } from '../../../Utils/hooks';
import { toast } from 'react-toastify';
import {
  createInfinitePaginationParams,
  governmentInspectionApi,
  NetworkRequestStatus,
  propertyApi,
  tenantApi,
  workOrderApi
} from '../../../Http/Http';
import FileAutoUploader from '../../../Components/FileAutoUploader/FileAutoUploader';
import { localeCompareSv } from '../../../Utils/string';
import LoadError from '../../../Components/LoadError';
import { useFaultReportReaders } from '../Hooks/useFaultReportReaders';
import {
  GovernmentInspectionWorkOrderReadView,
  MembershipReadViewRoleEnum,
  PropertyReadView,
  TenantReadView,
  WorkOrderCreateViewTypeEnum,
  WorkOrderReadViewCategoryEnum
} from '../../../GeneratedServices';
import { getFaultReportCategoryDropdownItems } from '../../../Services/WorkOrderService.types';
import { Trans, useTranslation } from 'react-i18next';
import { validateEmailField } from '../../../Utils/validation';
import { mapPropertiesToDropdownItems } from '../../../Services/PropertyService.types';
import { mapTenantsToDropdownItems } from '../../../Services/TenantService.types';
import { mapGovernmentInspectionItemResultsToDropdownItems } from '../../../Services/GovernmentInspectionService.types';

interface SharedProps {
  onCreated: (assigned: boolean) => void;
  onClose: () => void;
}

export type Props =
  | ({
      governmentInspectionWorkOrder: undefined;
      propertyId?: number;
    } & SharedProps)
  | ({
      governmentInspectionWorkOrder: GovernmentInspectionWorkOrderReadView;
    } & SharedProps);

enum FormFieldId {
  propertyId = 'propertyId',
  tenantId = 'tenantId',
  tenantEmail = 'tenantEmail',
  tenantPhone = 'tenantPhone',
  category = 'category',
  description = 'description',
  userIds = 'userIds',
  itemResultIds = 'itemResultIds'
}

enum Status {
  None,
  Loading,
  LoadError,
  Loaded,
  Creating
}

enum TenantState {
  None,
  Loading,
  LoadError,
  Loaded
}

const CreateFaultReportModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['faultReports', 'common', 'validation', 'properties', 'inspections']);

  const propertyId = props.governmentInspectionWorkOrder === undefined ? props.propertyId : null;

  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);
  const [reloadNonce, setReloadNonce] = useNonce();
  const [reloadTenantsNonce, setReloadTenantsNonce] = useNonce();

  const [itemResultIds, setItemResultIds] = React.useState<number[]>([]);
  const [itemResultIdsValidationResult, setItemResultIdsValidationResult] =
    React.useState<validation.FieldValidationResult>();

  const [properties, setProperties] = React.useState<PropertyReadView[]>([]);
  const [property, setProperty] = React.useState<PropertyReadView>();
  const [propertyValidationResult, setPropertyValidationResult] = React.useState<validation.FieldValidationResult>();

  const [tenants, setTenants] = React.useState<TenantReadView[]>([]);
  const [tenantId, setTenantId] = React.useState<number>();
  const [tenantValidationError, setTenantValidationError] = React.useState<validation.FieldValidationResult>();

  const [tenantEmail, setTenantEmail] = React.useState<string>();
  const [tenantEmailValidationResult, setTenantEmailValidationResult] =
    React.useState<validation.FieldValidationResult>();

  const [tenantPhone, setTenantPhone] = React.useState<string>();
  const [tenantPhoneValidationResult, setTenantPhoneValidationResult] =
    React.useState<validation.FieldValidationResult>();

  const [category, setCategory] = React.useState<WorkOrderReadViewCategoryEnum>();
  const [categoryValidationResult, setCategoryValidationResult] = React.useState<validation.FieldValidationResult>();

  const [description, setDescription] = React.useState<string>();
  const [descriptionValidationResult, setDescriptionValidationResult] =
    React.useState<validation.FieldValidationResult>();

  const [status, setStatus] = React.useState(Status.None);
  const [tenantState, setTenantState] = React.useState(TenantState.None);

  const [openFileSelectionNonce, setOpenFileSelectionNonce] = useNonce();
  const [hasNonCompletedFiles, setHasNonCompletedFiles] = React.useState(false);
  const [fileIds, setFileIds] = React.useState<string[]>([]);

  const [userIds, setUserIds] = React.useState<number[]>([]);
  const [userIdsValidationResult, setUserIdsValidationResult] = React.useState<validation.FieldValidationResult>();

  const {
    memberships,
    status: readersStatus,
    reloadMemberships
  } = useFaultReportReaders({
    propertyId: property?.id,
    organisationId: property?.organisationId
  });

  useDidMountEffect(() => setPropertyValidationResult(undefined), [property]);
  useDidMountEffect(() => setCategoryValidationResult(undefined), [category]);
  useDidMountEffect(() => {
    if (tenantId != null && tenantEmail != null) {
      validateEmailField(tenantEmail, setTenantEmailValidationResult);
    } else {
      setTenantEmailValidationResult(undefined);
    }
  }, [tenantId, tenantEmail]);
  useDidMountEffect(() => setTenantPhoneValidationResult(undefined), [tenantPhone]);
  useDidMountEffect(() => {
    setDescriptionValidationResult(
      description
        ? undefined
        : {
            error: true,
            errorMessage: [t('validation:fieldIsObligatory')]
          }
    );
  }, [description]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      const nonInvestorOrganisationIds = globalState.currentUserMemberships
        ?.filter((item) => item.role !== MembershipReadViewRoleEnum.Investor)
        .map((item) => item.organisationId);

      try {
        setStatus(Status.Loading);
        setTenantId(undefined);
        setTenantEmail(undefined);
        setTenantPhone(undefined);

        let propertyIdToFetch = propertyId;
        if (props.governmentInspectionWorkOrder) {
          const { data: inspectionData } = await governmentInspectionApi.governmentInspectionResourceRead(
            {
              id: props.governmentInspectionWorkOrder.governmentInspectionId
            },
            { signal: abortController.signal }
          );

          propertyIdToFetch = inspectionData.propertyId;
        }

        if (propertyIdToFetch) {
          const { data } = await propertyApi.propertyResourceRead(
            { id: propertyIdToFetch },
            { signal: abortController.signal }
          );
          setProperties([data]);
          setProperty(data);
        } else {
          setProperties(
            (
              await propertyApi.propertyResourceList(
                { ...createInfinitePaginationParams(), organisationIds: nonInvestorOrganisationIds },
                { signal: abortController.signal }
              )
            ).data.records
          );
        }
        setStatus(Status.Loaded);
      } catch (error) {
        globalState.handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [globalState, propertyId, reloadNonce, props.governmentInspectionWorkOrder]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setTenantState(TenantState.Loading);
        setTenants(
          (
            await tenantApi.tenantResourceList(
              { ...createInfinitePaginationParams(), ongoingPropertyId: property?.id },
              { signal: abortController.signal }
            )
          ).data.records.map((item) => item)
        );
        setTenantState(TenantState.Loaded);
      } catch (error) {
        globalState.handleHttpErrors(error) && setTenantState(TenantState.LoadError);
      }
    };

    if (property?.id) {
      load();
    }

    return () => {
      abortController.abort();
    };
  }, [globalState, property?.id, reloadTenantsNonce]);

  React.useEffect(() => {
    if (tenantState === TenantState.LoadError) {
      setTenantValidationError({
        error: true,
        errorMessage: [
          <>
            {t('validation:anErrorOccurredWhenLoadingTenants')}
            <br />
            <br />
            <Form.Button
              secondary
              type="button"
              content={t('common:tryAgain')}
              onClick={setReloadTenantsNonce}
              size={'mini'}
            />
          </>
        ]
      });
    } else {
      setTenantValidationError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantState]);

  React.useEffect(() => {
    if (readersStatus === NetworkRequestStatus.LoadError) {
      setUserIdsValidationResult({
        error: true,
        errorMessage: [
          <>
            {t('validation:userCouldNotBeLoaded')}
            <br />
            <br />
            <Form.Button
              secondary
              type="button"
              content={t('common:tryAgain')}
              onClick={reloadMemberships}
              size={'mini'}
            />
          </>
        ]
      });
    } else {
      setUserIdsValidationResult(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readersStatus]);

  const createWorkOrder = async () => {
    try {
      setStatus(Status.Creating);

      const params = props.governmentInspectionWorkOrder
        ? {
            type: WorkOrderCreateViewTypeEnum.FailedGovernmentInspection,
            governmentInspectionWorkOrderId: props.governmentInspectionWorkOrder.id,
            [FormFieldId.itemResultIds]: itemResultIds
          }
        : {
            type: WorkOrderCreateViewTypeEnum.Default,
            [FormFieldId.propertyId]: property?.id!,
            [FormFieldId.tenantId]: tenantId,
            [FormFieldId.tenantEmail]: tenantId != null ? tenantEmail : null,
            [FormFieldId.tenantPhone]: tenantId != null ? tenantPhone : null,
            [FormFieldId.category]: category!
          };

      const { data } = await workOrderApi.workOrderResourceCreate({
        workOrderCreateViewUnion: {
          fileIds,
          [FormFieldId.description]: description!,
          [FormFieldId.userIds]: userIds,
          ...params
        }
      });

      toast.info(
        <Trans i18nKey="faultReports:faultReportCreated" t={t}>
          Felanmälan <strong>{{ data: '#' + data.id }}</strong> har skapats.
        </Trans>
      );

      props.onCreated(userIds.includes(globalState.currentUser!.id));
    } catch (error) {
      setStatus(Status.None);
      globalState.handleHttpErrors(error, {
        handleResponseValidationError: (resp) => handleResponseValidationError(resp, userIds, itemResultIds)
      });
    }
  };

  const handleResponseValidationError = (resp: UserFriendlyApiResponse, userIds: number[], itemResultIds: number[]) => {
    const fieldIdMapping = [
      {
        formFieldId: FormFieldId.propertyId,
        setStateFunc: setPropertyValidationResult
      },
      {
        formFieldId: FormFieldId.category,
        setStateFunc: setCategoryValidationResult
      },
      {
        formFieldId: FormFieldId.tenantEmail,
        setStateFunc: setTenantEmailValidationResult
      },
      {
        formFieldId: FormFieldId.tenantPhone,
        setStateFunc: setTenantPhoneValidationResult
      },
      {
        formFieldId: FormFieldId.description,
        setStateFunc: setDescriptionValidationResult
      },
      ...userIds.map((item, index) => ({
        formFieldId: FormFieldId.userIds + '.' + index,
        setStateFunc: setUserIdsValidationResult
      })),
      ...itemResultIds.map((item, index) => ({
        formFieldId: FormFieldId.itemResultIds + '.' + index,
        setStateFunc: setItemResultIdsValidationResult
      }))
    ];

    validation.applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  const getLayout = () => {
    switch (status) {
      case Status.None:
      case Status.Creating:
      case Status.Loaded:
        return layoutForm;

      case Status.LoadError:
        return layoutLoadError;

      case Status.Loading:
        return layoutLoading;
    }
  };

  const resolveMembershipsDropdownItems = (): DropdownItemProps[] => {
    return (
      memberships
        .map((item) => ({
          key: item.userId,
          text: item.userFullName,
          description: item.email,
          value: item.userId
        }))
        .sort((a, b) => localeCompareSv(a.text, b.text)) ?? []
    );
  };

  const itemResultOptions = React.useMemo(() => {
    if (props.governmentInspectionWorkOrder)
      return mapGovernmentInspectionItemResultsToDropdownItems(
        props.governmentInspectionWorkOrder.itemResults.filter((item) => !item.passed)
      );
  }, [props.governmentInspectionWorkOrder]);

  const isCreateButtonEnabled = () => {
    return (
      !hasNonCompletedFiles &&
      [Status.None, Status.Loaded].includes(status) &&
      (props.governmentInspectionWorkOrder !== undefined ? itemResultIds.length > 0 : true)
    );
  };

  const layoutLoading = <Loader active inline="centered" size="large" />;

  const layoutLoadError = (
    <LoadError message={t('properties:unableToFetchInformationAboutProperty')} retry={setReloadNonce} />
  );

  const layoutForm = (
    <Form noValidate="noValidate">
      {(props.governmentInspectionWorkOrder || propertyId) && (
        <Form.Input
          label={t('common:property')}
          value={properties.length > 0 ? properties[0].name : '-'}
          readOnly
          className="readOnlyInput"
        />
      )}
      {props.governmentInspectionWorkOrder && (
        <Form.Dropdown
          label={t('inspections:inspectionObjects')}
          placeholder={t('inspections:inspectionObjects')}
          value={itemResultIds}
          onChange={(e, d) => {
            setItemResultIdsValidationResult(undefined);
            setItemResultIds(d.value as number[]);
          }}
          error={renderValidationErrors(itemResultIdsValidationResult)}
          selection
          search
          required
          multiple
          floating
          disabled={status === Status.Creating}
          options={itemResultOptions}
        />
      )}

      {!propertyId && !props.governmentInspectionWorkOrder && (
        <Form.Dropdown
          label={t('common:property')}
          placeholder={t('common:property')}
          floating
          value={property?.id}
          width={6}
          onChange={(e, d) => {
            setProperty(properties.find((item) => item.id === d.value));
          }}
          disabled={status === Status.Creating}
          error={renderValidationErrors(propertyValidationResult)}
          selection
          search
          options={mapPropertiesToDropdownItems(properties)}
          required
        />
      )}

      {props.governmentInspectionWorkOrder === undefined && (
        <>
          <Form.Dropdown
            label={t('common:tenant')}
            placeholder={t('common:tenant')}
            floating
            value={tenantId}
            width={6}
            onChange={(e, d) => {
              setTenantId((d.value as number) || undefined);
            }}
            disabled={!property || status === Status.Creating || tenantState === TenantState.Loading}
            error={renderValidationErrors(tenantValidationError)}
            noResultsMessage={t('faultReports:noTenants')}
            loading={tenantState === TenantState.Loading}
            selection
            search
            clearable
            options={mapTenantsToDropdownItems(tenants)}
          />
          <Form.Input
            width={6}
            label={t('faultReports:tenantEmail')}
            type="email"
            placeholder={t('faultReports:tenantEmail')}
            onChange={(e) => setTenantEmail(e.target.value.trim() !== '' ? e.target.value.trim() : undefined)}
            disabled={status === Status.Creating || tenantId == null}
            error={renderValidationErrors(tenantEmailValidationResult)}
          />
          <Form.Input
            width={6}
            label={t('faultReports:tenantPhone')}
            type="tel"
            placeholder={t('faultReports:tenantPhone')}
            onChange={(e) => setTenantPhone(e.target.value.trim() !== '' ? e.target.value.trim() : undefined)}
            disabled={status === Status.Creating || tenantId == null}
            error={renderValidationErrors(tenantPhoneValidationResult)}
          />
          <Form.Dropdown
            label={t('common:category')}
            placeholder={t('common:category')}
            floating
            upward
            value={category}
            width={6}
            onChange={(e, d) => setCategory((d.value as WorkOrderReadViewCategoryEnum) || undefined)}
            disabled={status === Status.Creating}
            error={renderValidationErrors(categoryValidationResult)}
            selection
            clearable
            options={getFaultReportCategoryDropdownItems()}
            required
          />
        </>
      )}

      <Form.TextArea
        label={t('common:description')}
        type="text"
        placeholder={t('common:description')}
        rows={5}
        onChange={(e) => setDescription(e.target.value.trim())}
        disabled={status === Status.Creating}
        error={renderValidationErrors(descriptionValidationResult)}
        required
      />

      <Form.Dropdown
        label={t('faultReports:assignees')}
        placeholder={t('faultReports:assignees')}
        floating
        multiple
        upward
        value={userIds}
        width={10}
        onChange={(e, d) => {
          setUserIdsValidationResult(undefined);
          setUserIds(d.value as number[]);
        }}
        noResultsMessage={t('faultReports:noUsersFound')}
        loading={readersStatus === NetworkRequestStatus.Loading}
        disabled={!property || status === Status.Creating}
        error={readersStatus === NetworkRequestStatus.LoadError || renderValidationErrors(userIdsValidationResult)}
        selection
        search
        options={resolveMembershipsDropdownItems()}
      />

      <Form.Field>
        <label>{t('common:files')}</label>
        <FileAutoUploader
          noFilesUploadedMessage={t('faultReports:noFilesAttached')}
          openFileSelectionNonce={openFileSelectionNonce}
          onStateChanged={(hasNonCompletedFiles) => {
            setHasNonCompletedFiles(hasNonCompletedFiles);
          }}
          onFilesChanged={(fileIds) => {
            setFileIds(fileIds);
          }}
        />
      </Form.Field>
    </Form>
  );

  return (
    <Modal size="small" closeOnDimmerClick={false} closeOnEscape={false} closeIcon open onClose={props.onClose}>
      <Modal.Header>{t('faultReports:newFaultReport')}</Modal.Header>

      <Modal.Content scrolling>
        <Grid centered columns="equal" id="createWorkOrder">
          <Grid.Column>{getLayout()}</Grid.Column>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="button"
            content={t('faultReports:attachFiles')}
            color="blue"
            disabled={!isCreateButtonEnabled()}
            onClick={() => {
              setOpenFileSelectionNonce();
            }}
          />
          <div>
            <Button
              type="button"
              content={t('common:save')}
              primary
              disabled={!isCreateButtonEnabled()}
              loading={status === Status.Creating}
              onClick={createWorkOrder}
            />
            <Button
              secondary
              type="button"
              content={t('common:close')}
              disabled={status === Status.Creating}
              onClick={props.onClose}
            />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateFaultReportModal;
