import React from 'react';
import { FileInfoReadView } from '../../Services/FileService.types';
import styles from './FileCardListItem.module.scss';
import { Button, Card, Confirm, Icon } from 'semantic-ui-react';
import { resolveIconFromFilename } from '../../Utils/icon';
import ActionBadge from '../ActionBadge/ActionBadge';
import Positioned from '../Positioned/Positioned';
import CircleLoader from '../CircleLoader/CircleLoader';
import FileEditModal from '../../Views/Properties/Components/FileEditModal';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import FileViewer from '../FileViewer/FileViewer';
import colors from '../../Utils/Colors/Colors.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { fileApi } from '../../Http/Http';
import { FileThumbnailStatus, FileWithThumbnailUrlEntity } from './Hooks/useFilesReducer';

interface Props {
  file: FileWithThumbnailUrlEntity;
  reloadFiles?: () => void;
  files?: FileInfoReadView[];
  allowCategoryEditing?: boolean;
  onFileChange?: (action: 'UPDATE' | 'DELETE', file: FileInfoReadView) => void;
  readOnly?: boolean;
  hideDate?: boolean;
  descriptionFontSize?: number;
  dateFontSize?: number;
  deleteFile: (file: FileWithThumbnailUrlEntity) => void;
  retryLoadingThumbnail: (file: FileWithThumbnailUrlEntity) => void;
}

const FileCardListItem: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'files']);
  const { isImageOrPDF, status, thumbnailUrl, name, created, id } = props.file;

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const imageRef = React.useRef<HTMLImageElement>(null);

  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [imageViewerOpened, setImageViewerOpened] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  const [showFileEditModal, setShowFileEditModal] = React.useState(false);
  const [showFileDeleteModal, setShowFileDeleteModal] = React.useState(false);

  React.useLayoutEffect(() => {
    if (imageRef.current) {
      imageRef.current.src = thumbnailUrl ?? '';
    }
    return () => {
      // Make <img> stop loading image when component dismounts.
      if (imageRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        imageRef.current.src = '';
      }
    };
  }, [thumbnailUrl]);

  const show = () => {
    if (isImageOrPDF) {
      setHovering(false);
      setImageViewerOpened(true);
    }
  };

  const download = async () => {
    try {
      const { data: fileUrl } = await fileApi.fileResourceGetBlobUrl({ id: id, download: true });
      if (fileUrl) {
        window.location.assign(fileUrl);
      }
    } catch (error) {
      handleHttpErrors(error);
    }
  };

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setError(true);
  };

  const getFileDescription = () => {
    return t('files:createdAt', { date: new Date(created) });
  };

  const errored = status === FileThumbnailStatus.Error || error;
  return (
    <div>
      <Card
        className={styles.componentWrapper}
        data-image={isImageOrPDF}
        title={name}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div
          className={styles.contentWrapper}
          title={status === FileThumbnailStatus.None ? t('files:clickToDisplayPicture') : ''}
          onClick={show}
        >
          <ImageSection
            error={error}
            onError={onError}
            onLoad={onLoad}
            status={status}
            isImageOrPDF={isImageOrPDF}
            ref={imageRef}
            url={thumbnailUrl}
          />

          <Loader
            error={error}
            loaded={
              loaded || [FileThumbnailStatus.ThumbnailGenerationError, FileThumbnailStatus.Error].includes(status)
            }
            status={status}
            isImageOrPDF={isImageOrPDF}
          />

          <Placeholder errored={errored} status={status} isImageOrPDF={isImageOrPDF} />
        </div>

        <Card.Content className={styles.bottomContainer}>
          <Card.Description className={styles.bottomContainerContent} style={{ fontSize: props.descriptionFontSize }}>
            <div className={styles.fileNameContent}>
              <Icon name={resolveIconFromFilename(name)} color={error ? 'red' : 'blue'} size={'large'} />
              <div className={styles.fileNameInnerText}>{name}</div>
            </div>
          </Card.Description>
          {!props.hideDate && <Card.Meta style={{ fontSize: props.dateFontSize }}>{getFileDescription()}</Card.Meta>}
        </Card.Content>

        <ActionButtonsSection
          download={download}
          hovering={hovering}
          onDeleteClick={() => setShowFileDeleteModal(true)}
          onEditClick={() => setShowFileEditModal(true)}
          readOnly={props.readOnly}
          retry={() => props.retryLoadingThumbnail(props.file)}
          status={status}
        />
      </Card>

      {imageViewerOpened && (
        <FileViewer file={props.file} onClosed={() => setImageViewerOpened(false)} files={props.files} />
      )}

      {showFileEditModal && (
        <FileEditModal
          file={props.file}
          onClose={(file) => {
            if (file) {
              props.reloadFiles && props.reloadFiles();
              props.onFileChange && props.onFileChange('UPDATE', file);
            }
            setShowFileEditModal(false);
          }}
          allowCategoryEditing={props.allowCategoryEditing}
        />
      )}

      {showFileDeleteModal && (
        <Confirm
          open={true}
          header={t('files:removeFile')}
          content={
            <div style={{ margin: 20, lineHeight: '1.4em' }}>
              <Trans i18nKey="files:doYouWantToRemoveMessage" t={t}>
                Vill du ta bort filen <strong style={{ wordBreak: 'break-word' }}>{{ fileName: name }}</strong>?
              </Trans>
            </div>
          }
          size="mini"
          closeIcon={status !== FileThumbnailStatus.Deleting}
          closeOnEscape={status !== FileThumbnailStatus.Deleting}
          cancelButton={
            <Button disabled={status === FileThumbnailStatus.Deleting} secondary>
              {t('common:cancel')}
            </Button>
          }
          confirmButton={
            <Button
              disabled={status === FileThumbnailStatus.Deleting}
              loading={status === FileThumbnailStatus.Deleting}
            >
              {t('common:yesRemove')}
            </Button>
          }
          onCancel={() => setShowFileDeleteModal(false)}
          onConfirm={() => props.deleteFile(props.file)}
        />
      )}
    </div>
  );
};

interface ImageSectionProps {
  isImageOrPDF?: boolean;
  url?: string;
  status: FileThumbnailStatus;
  error: boolean;
  onLoad: () => void;
  onError: () => void;
}
const ImageSection = React.forwardRef<HTMLImageElement, ImageSectionProps>((props, ref) => {
  const { isImageOrPDF, error, status, url, onLoad, onError } = props;

  if (isImageOrPDF && url && status === FileThumbnailStatus.None && !error)
    return <img ref={ref} src={url} alt="" onLoad={onLoad} onError={onError} />;
  else return null;
});

const Loader = (props: { status: FileThumbnailStatus; isImageOrPDF?: boolean; loaded: boolean; error: boolean }) => {
  if (props.status === FileThumbnailStatus.Loading || (props.isImageOrPDF && !props.loaded && !props.error))
    return <CircleLoader size={'2.5em'} dy={'-0.9em'} />;
  else return null;
};

const Placeholder = (props: { status: FileThumbnailStatus; isImageOrPDF?: boolean; errored: boolean }) => {
  if (
    (props.status === FileThumbnailStatus.None && !props.isImageOrPDF) ||
    props.errored ||
    props.status === FileThumbnailStatus.ThumbnailGenerationError
  )
    return <Icon name="file" size="massive" className={styles.icon} />;
  else return null;
};

const ActionButtonsSection = (props: {
  status: FileThumbnailStatus;
  hovering: boolean;
  retry: () => void;
  readOnly?: boolean;
  download: () => Promise<void>;
  onEditClick: () => void;
  onDeleteClick: () => void;
}) => {
  const { t } = useTranslation(['common']);

  const { hovering, readOnly, retry, status, download, onDeleteClick, onEditClick } = props;
  return (
    <div style={{ fontSize: 9 }}>
      <Positioned position="TOP-RIGHT" dx={'1.2em'} dy={'-50%'} zIndex={10}>
        <div style={{ display: 'flex' }}>
          {status === FileThumbnailStatus.Error && (
            <ActionBadge
              icon="refresh"
              title={t('common:tryAgain')}
              semiTransparent={false}
              backgroundColor={colors.red}
              onClick={retry}
            />
          )}
          {hovering &&
            (status === FileThumbnailStatus.None || status === FileThumbnailStatus.ThumbnailGenerationError) && (
              <>
                <ActionBadge
                  icon="download"
                  title={t('common:download')}
                  backgroundColor={colors.blue1}
                  onClick={download}
                />
                {!readOnly && (
                  <>
                    <ActionBadge
                      icon="pencil"
                      title={t('common:edit')}
                      backgroundColor={colors.black}
                      onClick={onEditClick}
                    />
                    <ActionBadge
                      icon="trash"
                      title={t('common:remove')}
                      backgroundColor={colors.red}
                      onClick={onDeleteClick}
                    />
                  </>
                )}
              </>
            )}
        </div>
      </Positioned>
    </div>
  );
};

export default React.memo(FileCardListItem);
