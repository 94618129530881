import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BAM_API_BASE_URL } from '../config';
import { Tokens } from '../GlobalState/GlobalState';
import {
  AccountApi,
  AnalyticsApi,
  AuthApi,
  BusinessPlanApi,
  CompanyApi,
  ComparableApi,
  Configuration,
  ContractApi,
  DatasourceApi,
  DefaultApi,
  FaultReportAssignmentsApi,
  FaultReportSubscriptionsApi,
  FileApi,
  FinancialReportApi,
  FinancialTransactionApi,
  GovernmentInspectionApi,
  ImportApi,
  InvitationApi,
  MembershipApi,
  MinimumSupportedMobileVersionApi,
  NotificationApi,
  NotificationSettingsApi,
  OrganisationApi,
  PortfoliosApi,
  PropertyApi,
  PropertySubscriptionApi,
  PushSubscriptionApi,
  StatisticsApi,
  TenantApi,
  UnitApi,
  UnitObjectApi,
  UserApi,
  UtilityApi,
  WorkOrderApi
} from '../GeneratedServices';
import { ResponseErrorCode } from './response-error';
import queryString from 'query-string';

const baseURL = BAM_API_BASE_URL;
const headers = {
  Accept: 'application/json'
};

export const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem(Tokens.BAM_AUTH_TOKEN);
  if (token != null) {
    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};
export const handleRejection = (error: AxiosError): any => {
  const refreshToken = localStorage.getItem(Tokens.BAM_REFRESH_TOKEN);
  const { response, config } = error;
  if (response && config) {
    const { data } = response;
    if (data) {
      const { code } = data;
      if (code && code === ResponseErrorCode.R000001) {
        return axios
          .post<{ token: string }>(`${BAM_API_BASE_URL}/v1/auth/renew-token`, { refreshToken })
          .then(({ data }) => {
            localStorage.setItem(Tokens.BAM_AUTH_TOKEN, data.token);
            if (config.headers) config.headers.Authorization = `Bearer ${data.token}`;
            return axios.request(config);
          })
          .catch(() => Promise.reject(error));
      }
    }
  }
  // FUTURE NOTE: if any endpoint responds with a type other than JSON, e.g `blob` the conversion can be handled here.
  return Promise.reject(error);
};

class Http {
  private axiosInstance: AxiosInstance | null = null;
  private get http(): AxiosInstance {
    return this.axiosInstance ? this.axiosInstance : this.initAxiosInstance();
  }
  initAxiosInstance(): AxiosInstance {
    const instance = axios.create({ baseURL, headers });
    instance.interceptors.request.use(injectToken, (error) => Promise.reject(error));
    instance.interceptors.response.use((value: AxiosResponse<any>) => value, handleRejection);
    this.axiosInstance = instance;
    return instance;
  }

  public get<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.http.get<T>(url, {
      ...config,
      paramsSerializer: (params) => {
        /*
        The polygon param is the only param which differs from the list params by containing a list of objects instead of primitive values.
        If any future params are objects, add similar logic for each of them.
        TODO: Add a paramsSerializer to generated services axios instance which will parse all future cases similarly 
        which will make it possible to enforce a uniform query param format (currently we use brackets as well as duplicate keys)
        */
        const { polygon, ...rest } = params;

        var polygonString = polygon?.map((item: any) => `polygon[]=${JSON.stringify(item)}`).join('&');

        return queryString.stringify(rest).concat(polygonString ? '&' + polygonString : '');
      }
    });
  }

  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.post<T>(url, data, config);
  }

  public delete(url: string, config?: AxiosRequestConfig) {
    return this.http.delete(url, config);
  }

  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.put<T>(url, data, config);
  }

  public patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.patch<T>(url, data, config);
  }
}

export const http = new Http();
export const MAX_LIST_LIMIT = 2147483647;

/**
 * Returns a structure with 'limit' and 'offset' to use for API list requests.
 *
 * @param page Current page.
 * @param pageLimit Max number of items per page.
 */
export const createPaginationParams = (page: number, pageLimit: number) => {
  return {
    limit: pageLimit,
    offset: pageLimit * (page - 1)
  };
};

/**
 * Returns a structure with limit set to 1 and offset to 2147483647.
 */
export const createInfinitePaginationParams = () => {
  return createPaginationParams(1, MAX_LIST_LIMIT);
};

/**
 * Return value for specified key representing a page number.
 *
 * @param pageKey Name of key representing the page number.
 */
export const getActivePageFromQueryString = (pageKey: string) => {
  const page = getValueFromQueryString(pageKey);
  return page != null && +page > 1 ? +page : 1;
};

/**
 * Returns decoded value for specified query parameter.
 *
 * @param params Url params
 * @param parameterKey Name of query parameter.
 */
export const getSearchParam = (params: URLSearchParams, parameterKey: string): string | undefined => {
  const param = params.get(parameterKey);
  return param != null ? decodeURIComponent(param) : undefined;
};

/**
 * Returns decoded value for specified query parameter.
 *
 * @param parameterKey Name of query parameter.
 */
export const getValueFromQueryString = (parameterKey: string): string | undefined => {
  const params = new URLSearchParams(window.location.search);
  return getSearchParam(params, parameterKey);
};

/**
 * Returns the existing search params with  the specified key modifed, added or deleted.
 *
 *  * @param params Url params
 * @param key Name of key to alter.
 * @param value Value to set for the key.
 */
export const alterSearchParam = (params: URLSearchParams, key: string, value: string | undefined) => {
  if (value) {
    params.set(key, value);
  } else params.delete(key);
  return params;
};

/**
 * Returns the existing query string with the specified key modifed, added or deleted.
 *
 * @param key Name of key to alter.
 * @param value Value to set for the key.
 */
export const alterQueryString = (key: string, value: string | undefined) => {
  const params = new URLSearchParams(window.location.search);
  return alterSearchParam(params, key, value).toString();
};

/**
 * Returns the specified function 'func' or a previous (cached) version of the
 * function if key 'K' exists in cache.
 *
 * @param func Function that returns a promise with result 'R' for given key 'K'.
 */
export const cachedFetcher = <K, R>(func: (key: K) => Promise<R>) => {
  const cache = new Map<K, Promise<R>>();

  return (key: K): Promise<R> => {
    if (!cache.has(key)) {
      cache.set(key, func(key));
    }

    return cache.get(key)!;
  };
};

// Make instances for the generated APIs
const config = new Configuration();

const getAxiosInstance = () => {
  const instance = axios.create({ baseURL, headers });
  instance.interceptors.request.use(injectToken, (error) => Promise.reject(error));
  instance.interceptors.response.use((value: AxiosResponse<any>) => value, handleRejection);
  return instance;
};

const axiosInstance = getAxiosInstance();

export const accountApi = new AccountApi(config, BAM_API_BASE_URL, axiosInstance);
export const analyticsApi = new AnalyticsApi(config, BAM_API_BASE_URL, axiosInstance);
export const authApi = new AuthApi(config, BAM_API_BASE_URL, axiosInstance);
export const companyApi = new CompanyApi(config, BAM_API_BASE_URL, axiosInstance);
export const comparableApi = new ComparableApi(config, BAM_API_BASE_URL, axiosInstance);
export const contractApi = new ContractApi(config, BAM_API_BASE_URL, axiosInstance);
export const dataSourceApi = new DatasourceApi(config, BAM_API_BASE_URL, axiosInstance);
export const defaultApi = new DefaultApi(config, BAM_API_BASE_URL, axiosInstance);
export const faultReportAssignmentsApi = new FaultReportAssignmentsApi(config, BAM_API_BASE_URL, axiosInstance);
export const faultReportSubscriptionsApi = new FaultReportSubscriptionsApi(config, BAM_API_BASE_URL, axiosInstance);
export const fileApi = new FileApi(config, BAM_API_BASE_URL, axiosInstance);
export const financialTransactionApi = new FinancialTransactionApi(config, BAM_API_BASE_URL, axiosInstance);
export const governmentInspectionApi = new GovernmentInspectionApi(config, BAM_API_BASE_URL, axiosInstance);
export const importApi = new ImportApi(config, BAM_API_BASE_URL, axiosInstance);
export const invitationApi = new InvitationApi(config, BAM_API_BASE_URL, axiosInstance);
export const membershipApi = new MembershipApi(config, BAM_API_BASE_URL, axiosInstance);
export const minimumSupportedMobileVersionApi = new MinimumSupportedMobileVersionApi(
  config,
  BAM_API_BASE_URL,
  axiosInstance
);
export const notificationApi = new NotificationApi(config, BAM_API_BASE_URL, axiosInstance);
export const notificationSettingsApi = new NotificationSettingsApi(config, BAM_API_BASE_URL, axiosInstance);
export const organisationApi = new OrganisationApi(config, BAM_API_BASE_URL, axiosInstance);
export const portfolioApi = new PortfoliosApi(config, BAM_API_BASE_URL, axiosInstance);
export const propertyApi = new PropertyApi(config, BAM_API_BASE_URL, axiosInstance);
export const propertySubscriptionApi = new PropertySubscriptionApi(config, BAM_API_BASE_URL, axiosInstance);
export const pushSubscriptionApi = new PushSubscriptionApi(config, BAM_API_BASE_URL, axiosInstance);
export const tenantApi = new TenantApi(config, BAM_API_BASE_URL, axiosInstance);
export const userApi = new UserApi(config, BAM_API_BASE_URL, axiosInstance);
export const unitApi = new UnitApi(config, BAM_API_BASE_URL, axiosInstance);
export const utilityApi = new UtilityApi(config, BAM_API_BASE_URL, axiosInstance);
export const workOrderApi = new WorkOrderApi(config, BAM_API_BASE_URL, axiosInstance);
export const financialReportApi = new FinancialReportApi(config, BAM_API_BASE_URL, axiosInstance);
export const businessPlanApi = new BusinessPlanApi(config, BAM_API_BASE_URL, axiosInstance);
export const statisticsApi = new StatisticsApi(config, BAM_API_BASE_URL, axiosInstance);
export const unitObjectApi = new UnitObjectApi(config, BAM_API_BASE_URL, axiosInstance);

// A common enum which specifies the state of the request (Loading, None or LoadError)
export enum NetworkRequestStatus {
  Loading,
  None,
  LoadError
}
