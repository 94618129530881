import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { useNonce } from '../../../Utils/hooks';
import NumberFormater from '../../NumberFormater/NumberFormater';
import KpiCard, { KPISize, KPIStatus } from '../../KPIs/KPICard/KpiCard';
import React from 'react';
import { formatCurrency } from '../../../Utils/number';
import { statisticsApi } from '../../../Http/Http';
import { ParkingStatisticsReadView } from '../../../GeneratedServices';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../../FiltersProvider/FiltersProvider';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}

const ParkingLeaseRentKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation(['contracts', 'common']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [parkingLeaseRentStatistics, setParkingLeaseRentStatistics] = React.useState<ParkingStatisticsReadView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceGetParking(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setParkingLeaseRentStatistics(data);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();

    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  return (
    <KpiCard
      title={t('contracts:yearlyRentParkingSpots')}
      value={
        parkingLeaseRentStatistics?.rentPerSpot != null ? (
          <NumberFormater
            value={formatCurrency(parkingLeaseRentStatistics?.rentPerSpot, { forceCurrency: 'SEK' }).value}
            additionalProps={{ decimalScale: 0 }}
          />
        ) : (
          t('contracts:noParkingSpots')
        )
      }
      valueSuffix={parkingLeaseRentStatistics?.rentPerSpot != null ? t('contracts:sekPerSpot') : undefined}
      valueAs="h5"
      status={status}
      retry={reload}
      size={KPISize.small}
    />
  );
};

export default ParkingLeaseRentKpi;
