import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';

interface Props {
  message: string;
  retry: () => void;
}

const LoadError: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  return (
    <>
      {/* There is a weird rule in Semantic which only shows messages inside form when there is a status class on the form. */}
      {/* TODO: Use Semantic overrides to get rid of the original rule instead of overriding here */}
      <Message error style={{ display: 'block' }}>
        {props.message}
      </Message>
      <div style={{ textAlign: 'center' }}>
        <Button type="button" onClick={props.retry} secondary>
          {t('tryAgain')}
        </Button>
      </div>
    </>
  );
};

export default LoadError;
