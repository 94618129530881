import { DropdownItemProps } from 'semantic-ui-react';
import { Role } from '../Utils/permissions';
import { ListViewPaginationParams } from './types';
import { MembershipReadView as GeneratedMembershipReadView } from '../GeneratedServices';

export interface MembershipListViewParams extends ListViewPaginationParams {
  userId?: number;
  organisationId?: number;
  role?: Role;
}

export interface MembershipListView {
  limit: number;
  offset: number;
  totalCount: number;
  records: MembershipReadView[];
}

export interface MembershipReadView {
  id: number;
  userId: number;
  userFullName: string;
  email: string;
  role: Role;
  organisationId: number;
  created: string;
  updated: string;
}

export interface MembershipUpdateView {
  role: Role;
}

export const mapMembershipsToDropdownItems = (
  memberships: GeneratedMembershipReadView[],
  key: 'id' | 'userId'
): DropdownItemProps[] => {
  return memberships.map((membership) => ({
    text: membership.userFullName,
    value: membership[key],
    key: membership[key]
  }));
};
