import React from 'react';
import { Label, LabelProps } from 'semantic-ui-react';
import colors from '../../Utils/Colors/Colors.module.scss';
import styles from './FilterLabel.module.scss';

interface Props {
  text: React.ReactNode;
  type?: 'organisation' | 'portfolio' | 'user';
  labelProps?: LabelProps;
}

const getColorFromType = (type?: 'organisation' | 'portfolio' | 'user') => {
  switch (type) {
    case 'organisation':
      return colors.palePurple;
    case 'portfolio':
      return colors.paleYellow;
    case 'user':
      return colors.paleGreen;
    default:
      return undefined;
  }
};
const FilterLabel: React.FC<Props> = (props) => {
  return (
    <Label
      content={props.text}
      {...props.labelProps}
      className={styles.label}
      style={{ backgroundColor: getColorFromType(props.type) }}
    />
  );
};

export default FilterLabel;
