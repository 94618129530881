import { DropdownItemProps } from 'semantic-ui-react';
import { PropertyReadView as GeneratedPropertyReadView } from '../GeneratedServices';
import { localeCompareSv } from '../Utils/string';
import { ComparablePropertyType } from './ComparableService.types';
import { ListViewPagination, ListViewPaginationParams, PointView } from './types';

export enum PropertyListSortBy {
  NameAsc = 'name_asc'
}
export interface PropertyListViewParams extends ListViewPaginationParams {
  organisationId?: number;
  companyId?: number;
  caretakerId?: number;
  receivedWorkOrdersCount_gt?: number;
  startedWorkOrdersCount_gt?: number;
  finishedWorkOrdersCount_gt?: number;
  sortBy?: PropertyListSortBy;
  portfolioId?: number;
  assigneeId?: number;
}

export interface PropertyReadView {
  id: number;
  address: string;
  name: string;
  plotArea: number;
  companyId: number;
  receivedWorkOrdersCount: number;
  startedWorkOrdersCount: number;
  finishedWorkOrdersCount: number;
  pictureId: string | null;
  type?: ComparablePropertyType;
  municipality: string;
  position: PointView | null;
  streetAddress: string;
  postcode: string;
  city: string;
  statistics: PropertyStatisticsView;
  label?: string;
  organisationId: number;
}

export interface PropertyStatisticsView {
  leases: number;
  area: number;
  indoorArea: number;
  baseRent: number;
  baseRentIndexed: number;
  rent: number;
  rentIndexed: number;
  baseRentPerArea: number | null;
  baseRentPerAreaIndexed: number | null;
  rentPerArea: number | null;
  rentPerAreaIndexed: number | null;
  wault: number | null;
  waultIndexed: number | null;
}

export interface PropertyListView extends ListViewPagination<PropertyReadView> {}

export interface PropertyUpdateView {
  managerId?: number | null;
  caretakerId?: number | null;
  type?: ComparablePropertyType;
  pictureId?: string | null;
}

export const mapPropertiesToDropdownItems = (properties: GeneratedPropertyReadView[]): DropdownItemProps[] => {
  return (
    properties
      .map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id
      }))
      .sort((a, b) => localeCompareSv(a.text, b.text)) ?? []
  );
};
