import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { ButtonGroup } from 'semantic-ui-react';
import MapControlButton from '../MapControlButton';
import styles from './ZoomControl.module.scss';

interface Props {
  map: google.maps.Map | null;
}

const ZoomControl: React.FC<Props> = (props) => {
  const zoomInRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (props.map) {
      const zoomControlDiv = document.createElement('div');

      const customControlDiv = zoomInRef.current;
      zoomControlDiv.appendChild(customControlDiv as Node);
      props.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);
    }
  }, [props.map]);

  return (
    <div className={styles.zoomControl} ref={zoomInRef}>
      <ButtonGroup vertical>
        <MapControlButton
          icon={faPlus}
          onClick={() => {
            const zoom = props.map?.getZoom();
            if (zoom !== undefined) props.map?.setZoom(zoom + 1);
          }}
        />

        <MapControlButton
          icon={faMinus}
          onClick={() => {
            const zoom = props.map?.getZoom();
            if (zoom !== undefined) props.map?.setZoom(zoom - 1);
          }}
        />
      </ButtonGroup>
    </div>
  );
};

export default ZoomControl;
