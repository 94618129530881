import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Form, Loader, Table } from 'semantic-ui-react';
import EditableControlCell from '../../../../Components/EditableTableCell/EditableControlCell';
import EditableTableNumberCell from '../../../../Components/EditableTableCell/EditableTableNumberCell';
import LoadError from '../../../../Components/LoadError';
import TableHeaderCellContent from '../../../../Components/TableHeaderCell/TableHeaderCell';
import { BusinessPlanCostReadView, BusinessPlanCostReadViewCategoryEnum } from '../../../../GeneratedServices';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { businessPlanApi, createInfinitePaginationParams } from '../../../../Http/Http';
import { useNonce } from '../../../../Utils/hooks';
import { formatCurrency, getFormatedCurrencyValue } from '../../../../Utils/number';
import NumberFormater, { commonNumberFormatProperties } from '../../../../Components/NumberFormater/NumberFormater';
import { BusinessPlanCapexStatus, useBusinessPlanCapex } from './hooks/useBusinessPlanCapex';
import { useBusinessPlanCostReducer } from './hooks/useBusinessPlanCostReducer';

interface Props {
  propertyId: number;
  businessPlanId: number;
  capex?: number | null;
  shouldReload: number;
  isPermitted?: boolean;
  reloadBusinessPlan: () => void;
}

enum Status {
  None,
  Loading,
  LoadError
}

const BusinessPlanCostsTab: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const [status, setStatus] = React.useState<Status>(Status.Loading);

  const {
    capexValidation,
    newCapex,
    setNewCapex,
    saveCapex,
    status: capexStatus,
    setCapexValidation
  } = useBusinessPlanCapex({
    capex: props.capex ?? undefined,
    businessPlanId: props.businessPlanId
  });

  const [businessPlanCosts, setBusinessPlanCosts] = React.useState<BusinessPlanCostReadView[]>([]);

  const [costToEdit, setCostToEdit] = React.useState<BusinessPlanCostReadView>();
  const [shouldCapexEdit, setShouldCapexEdit] = React.useState<boolean>(false);

  const [shouldCostsReload, reloadCosts] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();
    const load = async () => {
      try {
        setStatus(Status.Loading);

        const { data: costs } = await businessPlanApi.businessPlanCostResourceList(
          {
            ...createInfinitePaginationParams(),
            propertyId: props.propertyId
          },
          { signal: abortController.signal }
        );

        setBusinessPlanCosts(costs.records);
        setStatus(Status.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [props.propertyId, handleHttpErrors, shouldCostsReload, props.shouldReload]);

  const getLayout = () => {
    switch (status) {
      case Status.Loading:
        return businessPlanCosts.length !== 0 ? generalLayout : layoutLoading;
      case Status.None:
        return generalLayout;
      case Status.LoadError:
        return layoutLoadError;
    }
  };

  const cancelEditing = () => {
    setCostToEdit(undefined);
  };

  const isEditingDisabled = (id?: number) => {
    return (costToEdit && id !== costToEdit.id) || shouldCapexEdit;
  };
  const layoutLoading = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader active inline="centered" size="large" />
    </div>
  );

  const layoutLoadError = (
    <LoadError message={t('contracts:unableToLoadCostsOfBusinessPlanMessage')} retry={reloadCosts} />
  );

  const EmptyRow = () => (
    <Table.Row style={{ height: 42 }}>
      <td colSpan={3} />
    </Table.Row>
  );

  const otherCosts = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.OtherOpex);
  const serviceAgreement = businessPlanCosts.find(
    (item) => item.category === BusinessPlanCostReadViewCategoryEnum.ServiceAgreements
  );
  const marketing = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.Marketing);

  const cleaning = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.Cleaning);

  const snowremovalAndGardening = businessPlanCosts.find(
    (item) => item.category === BusinessPlanCostReadViewCategoryEnum.SnowRemovalGardening
  );
  const security = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.Security);
  const electricity = businessPlanCosts.find(
    (item) => item.category === BusinessPlanCostReadViewCategoryEnum.Electricity
  );
  const cooling = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.Cooling);
  const heating = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.Heating);
  const water = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.WaterSewage);
  const waste = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.Waste);
  const insurance = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.Insurance);
  const leaseholdFee = businessPlanCosts.find(
    (item) => item.category === BusinessPlanCostReadViewCategoryEnum.LeaseholdFee
  );
  const realEstateTaxes = businessPlanCosts.find(
    (item) => item.category === BusinessPlanCostReadViewCategoryEnum.PropertyTax
  );

  const opexValue =
    (otherCosts?.value ?? 0) +
    (serviceAgreement?.value ?? 0) +
    (marketing?.value ?? 0) +
    (cleaning?.value ?? 0) +
    (snowremovalAndGardening?.value ?? 0) +
    (security?.value ?? 0) +
    (electricity?.value ?? 0) +
    (cooling?.value ?? 0) +
    (heating?.value ?? 0) +
    (water?.value ?? 0) +
    (waste?.value ?? 0) +
    (insurance?.value ?? 0) +
    (leaseholdFee?.value ?? 0) +
    (realEstateTaxes?.value ?? 0);

  const repairs = businessPlanCosts.find((item) => item.category === BusinessPlanCostReadViewCategoryEnum.Repairs);
  const periodicMaintenance = businessPlanCosts.find(
    (item) => item.category === BusinessPlanCostReadViewCategoryEnum.PeriodicMaintenance
  );

  const maintenanceValue = (periodicMaintenance?.value ?? 0) + (repairs?.value ?? 0);

  const propertyManagement = businessPlanCosts.find(
    (item) => item.category === BusinessPlanCostReadViewCategoryEnum.PropertyManagement
  );
  const totalCosts = maintenanceValue + opexValue + (propertyManagement?.value ?? 0);
  const generalLayout = (
    <>
      <Form>
        <Form.Group>
          <Form.Input
            label="CAPEX (SEK)"
            placeholder="CAPEX (SEK)"
            width={3}
            input={
              <NumberFormat
                {...commonNumberFormatProperties}
                disabled={!shouldCapexEdit}
                value={formatCurrency(newCapex ?? 0, { unit: 'SEK', forceCurrency: 'SEK' }).value}
                onValueChange={(value) => {
                  setCapexValidation(undefined);
                  setNewCapex(Math.round((value.floatValue ?? 0) * 100));
                }}
              />
            }
          />
          {props.isPermitted && (
            <div style={{ alignSelf: 'flex-end', display: 'flex' }}>
              <Form.Field
                error={
                  capexValidation?.error
                    ? {
                        content: capexValidation.errorMessage,
                        pointing: 'left'
                      }
                    : undefined
                }
                label=""
              />
              <EditableControlCell
                isInEditMode={shouldCapexEdit}
                onCancel={() => {
                  setNewCapex(props.capex ?? undefined);
                  setShouldCapexEdit(false);
                }}
                onEditModeEnter={() => setShouldCapexEdit(true)}
                saveLoading={capexStatus === BusinessPlanCapexStatus.Saving}
                onSave={async () => {
                  const result = await saveCapex();
                  if (result) {
                    props.reloadBusinessPlan();
                    setShouldCapexEdit(false);
                  }
                }}
                savingDisabled={capexValidation?.error}
              />
            </div>
          )}
        </Form.Group>
      </Form>

      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common:categories')}</Table.HeaderCell>

            <Table.HeaderCell collapsing>
              <TableHeaderCellContent header={t('common:costs')} extra={t('common:sekSqm')} />
            </Table.HeaderCell>
            {props.isPermitted && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <TableRow
            label={t('contracts:costCategory.otherOpex')}
            item={otherCosts}
            isInEditMode={costToEdit?.id === otherCosts?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(otherCosts?.id)}
            onEditModeEnter={() => setCostToEdit(otherCosts)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.serviceAgreements')}
            item={serviceAgreement}
            isInEditMode={costToEdit?.id === serviceAgreement?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(serviceAgreement?.id)}
            onEditModeEnter={() => setCostToEdit(serviceAgreement)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.marketing')}
            item={marketing}
            isInEditMode={costToEdit?.id === marketing?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(marketing?.id)}
            onEditModeEnter={() => setCostToEdit(marketing)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.cleaning')}
            item={cleaning}
            isInEditMode={costToEdit?.id === cleaning?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(cleaning?.id)}
            onEditModeEnter={() => setCostToEdit(cleaning)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />

          <TableRow
            label={t('contracts:costCategory.snowRemovalAndGardening')}
            item={snowremovalAndGardening}
            isInEditMode={costToEdit?.id === snowremovalAndGardening?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(snowremovalAndGardening?.id)}
            onEditModeEnter={() => setCostToEdit(snowremovalAndGardening)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.security')}
            item={security}
            isInEditMode={costToEdit?.id === security?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(security?.id)}
            onEditModeEnter={() => setCostToEdit(security)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.electricity')}
            item={electricity}
            isInEditMode={costToEdit?.id === electricity?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(electricity?.id)}
            onEditModeEnter={() => setCostToEdit(electricity)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.cooling')}
            item={cooling}
            isInEditMode={costToEdit?.id === cooling?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(cooling?.id)}
            onEditModeEnter={() => setCostToEdit(cooling)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.heating')}
            item={heating}
            isInEditMode={costToEdit?.id === heating?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(heating?.id)}
            onEditModeEnter={() => setCostToEdit(heating)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.waterAndSewage')}
            item={water}
            isInEditMode={costToEdit?.id === water?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(water?.id)}
            onEditModeEnter={() => setCostToEdit(water)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.waste')}
            item={waste}
            isInEditMode={costToEdit?.id === waste?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(waste?.id)}
            onEditModeEnter={() => setCostToEdit(waste)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.insurance')}
            item={insurance}
            isInEditMode={costToEdit?.id === insurance?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(insurance?.id)}
            onEditModeEnter={() => setCostToEdit(insurance)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.leaseHoldFee')}
            item={leaseholdFee}
            isInEditMode={costToEdit?.id === leaseholdFee?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(leaseholdFee?.id)}
            onEditModeEnter={() => setCostToEdit(leaseholdFee)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.propertyTax')}
            item={realEstateTaxes}
            isInEditMode={costToEdit?.id === realEstateTaxes?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(realEstateTaxes?.id)}
            onEditModeEnter={() => setCostToEdit(realEstateTaxes)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />

          <TotalRow label="OPEX" value={opexValue} isPermitted={props.isPermitted} />

          <EmptyRow />

          <TableRow
            label={t('contracts:costCategory.repairs')}
            item={repairs}
            isInEditMode={costToEdit?.id === repairs?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(repairs?.id)}
            onEditModeEnter={() => setCostToEdit(repairs)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TableRow
            label={t('contracts:costCategory.periodicMaintenance')}
            item={periodicMaintenance}
            isInEditMode={costToEdit?.id === periodicMaintenance?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(periodicMaintenance?.id)}
            onEditModeEnter={() => setCostToEdit(periodicMaintenance)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />

          <TotalRow label={t('contracts:maintenance')} value={maintenanceValue} isPermitted={props.isPermitted} />
          <EmptyRow />

          <TableRow
            label={t('contracts:costCategory.propertyManagement')}
            item={propertyManagement}
            isInEditMode={costToEdit?.id === propertyManagement?.id}
            onCancel={cancelEditing}
            isEditingDisabled={isEditingDisabled(propertyManagement?.id)}
            onEditModeEnter={() => setCostToEdit(propertyManagement)}
            reload={reloadCosts}
            isPermitted={props.isPermitted}
          />
          <TotalRow label={t('contracts:totalCosts')} value={totalCosts} isPermitted={props.isPermitted} />
        </Table.Body>
      </Table>
    </>
  );

  return <>{getLayout()}</>;
};

const TotalRow = ({ label, value, isPermitted }: { label: string; value: number; isPermitted?: boolean }) => {
  return (
    <Table.Row style={{ fontWeight: 400 }}>
      <Table.Cell>{label}</Table.Cell>
      <Table.Cell textAlign="right">
        <NumberFormater value={formatCurrency(value, { forceCurrency: 'SEK' }).value} />
      </Table.Cell>
      {isPermitted && <Table.Cell />}
    </Table.Row>
  );
};

const TableRow = ({
  label,
  item,
  isInEditMode,
  onEditModeEnter,
  onCancel,
  isEditingDisabled,
  reload,
  isPermitted
}: {
  label: string;
  item?: BusinessPlanCostReadView;
  isInEditMode?: boolean;
  onEditModeEnter?: () => void;
  onCancel?: () => void;
  isEditingDisabled?: boolean;
  reload: () => void;
  isPermitted?: boolean;
}) => {
  const {
    businessPlanCost,
    saveBusinessPlanCost,
    saving: isCostSaving,
    dispatchBusinessPlanCost
  } = useBusinessPlanCostReducer({ cost: item });

  const displayValue = item?.value;
  const value = businessPlanCost.newValue;
  const validationResult = businessPlanCost.validationResult;

  return (
    <Table.Row>
      <Table.Cell>{label}</Table.Cell>

      <Table.Cell textAlign="right">
        <EditableTableNumberCell
          isInEditMode={isInEditMode}
          displayValue={getFormatedCurrencyValue(displayValue ?? 0)}
          value={getFormatedCurrencyValue(value ?? 0)}
          placeholder={label}
          onValueChange={(value) => {
            dispatchBusinessPlanCost({
              type: 'VALUE_CHANGE',
              value: Math.round((value.floatValue ?? 0) * 100)
            });
          }}
          validationResult={validationResult}
        />
      </Table.Cell>

      {isPermitted && (
        <Table.Cell collapsing textAlign="right">
          <EditableControlCell
            isInEditMode={isInEditMode ?? false}
            onCancel={() => {
              onCancel && onCancel();

              dispatchBusinessPlanCost({
                type: 'VALUE_RESET'
              });
            }}
            onEditModeEnter={() => onEditModeEnter && onEditModeEnter()}
            saveLoading={isCostSaving}
            onSave={async () => {
              const result = await saveBusinessPlanCost();
              if (result) {
                reload();
                dispatchBusinessPlanCost({
                  type: 'VALUE_RESET'
                });
                onCancel && onCancel();
              }
            }}
            savingDisabled={validationResult?.error}
            editModeDisabled={isEditingDisabled}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
};
export default BusinessPlanCostsTab;
