import { DropdownItemProps } from 'semantic-ui-react';
import { OrganisationReadView } from '../GeneratedServices';

export const mapOrganisationsToDropdownItems = (organisations?: OrganisationReadView[]): DropdownItemProps[] => {
  if (organisations)
    return organisations.map((organisation) => ({
      text: organisation.name,
      value: organisation.id,
      key: organisation.id
    }));
  else return [];
};
