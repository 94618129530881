import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Header, Modal, SemanticWIDTHS } from 'semantic-ui-react';
import DatePicker from '../../../../Components/DatePicker/DatePicker';
import FilterLabel from '../../../../Components/Filters/FilterLabel';
import { useFilters } from '../../../../Components/Filters/Hooks/useFilters';
import MunicipalityDropdown from '../../../../Components/Filters/FilterDropdowns/MunicipalityDropdown';
import OrganisationDropdown from '../../../../Components/Filters/FilterDropdowns/OrganisationDropdown';
import PortfolioDropdown from '../../../../Components/Filters/FilterDropdowns/PortfolioDropdown';
import UserMembershipDropdown from '../../../../Components/Filters/FilterDropdowns/UserMembershipDropdown';
import { FiltersContext } from '../../../../FiltersProvider/FiltersProvider';
import {
  PropertyReadView,
  TenantReadView,
  WorkOrderApiWorkOrderResourceListRequest,
  WorkOrderReadViewCategoryEnum,
  WorkOrderReadViewStatusEnum
} from '../../../../GeneratedServices';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { createInfinitePaginationParams, NetworkRequestStatus, propertyApi, tenantApi } from '../../../../Http/Http';
import { faultReportStorageFilterKeys, FaultReportStorageParams } from '../../../../Services/FaultReportService';
import { mapPropertiesToDropdownItems } from '../../../../Services/PropertyService.types';
import { setFaultReportDefaultFilters, setFaultReportSessionFilters } from '../../../../Services/StorageService';
import { mapTenantsToDropdownItems } from '../../../../Services/TenantService.types';
import { translateWorkOrderCategory, translateWorkOrderStatus } from '../../../../Services/WorkOrderService.types';
import { toDateString } from '../../../../Utils/date';
import { Role } from '../../../../Utils/permissions';

interface SharedProps {
  onClose: () => void;
  filterParams: WorkOrderApiWorkOrderResourceListRequest;
  onConfirm: (params: WorkOrderApiWorkOrderResourceListRequest) => void;
}
type Props =
  | ({
      canCacheParams: true;
      defaultFilterParams?: FaultReportStorageParams;
      reloadDefaultFilters: () => void;
    } & SharedProps)
  | ({
      canCacheParams?: false;
      filterKeys: (keyof FaultReportStorageParams)[];
    } & SharedProps);

const FaultReportFiltersModal: React.FC<Props> = (props) => {
  const { handleHttpErrors, currentUser } = React.useContext(globalStateCTX);
  const { userMembershipsMap } = React.useContext(FiltersContext);

  const { t } = useTranslation(['faultReports', 'common']);

  const filterKeys = props.canCacheParams ? faultReportStorageFilterKeys : props.filterKeys;

  const [selectedOrganisationIds, setSelectedOrganisationIds] = React.useState<number[] | undefined>(
    props.filterParams.organisationIds
  );
  const [selectedPortfolioIds, setSelectedPortfolioIds] = React.useState<number[] | undefined>(
    props.filterParams.portfolioIds
  );
  const [selectedPropertySubscriptionUserIds, setSelectedPropertySubscriptionUserIds] = React.useState<
    number[] | undefined
  >(props.filterParams.propertySubscriptionUserIds);

  const [selectedAssigneeIds, setSelectedAssigneeIds] = React.useState<number[] | undefined>(
    props.filterParams.assigneeIds
  );

  const [selectedMunicipalities, setSelectedMunicipalities] = React.useState<string[] | undefined>(
    props.filterParams.municipalities
  );

  const {
    handleClearFilters,
    handleOrganisationsChange,
    handlePortfoliosChange,
    memberships,
    organisations,
    portfolios,
    filterUsersByOrganisations,
    filterUsersByPortfolios
  } = useFilters({
    selectedOrganisationIds,
    selectedPortfolioIds,
    setSelectedOrganisationIds,
    setSelectedPortfolioIds,
    setSelectedPropertySubscriptionUserIds,
    setSelectedMunicipalities
  });

  const [minCreationDate, setMinCreationDate] = React.useState<string | undefined>(props.filterParams.createdAfter);
  const [maxCreationDate, setMaxCreationDate] = React.useState<string | undefined>(props.filterParams.createdBefore);

  const [categories, setCategories] = React.useState<WorkOrderReadViewCategoryEnum[]>(
    props.filterParams.categories ?? []
  );

  const [statuses, setStatuses] = React.useState<WorkOrderReadViewStatusEnum[]>(
    props.filterParams.statuses ?? Object.values(WorkOrderReadViewStatusEnum)
  );

  const [propertiesStatus, setPropertiesStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);
  const [properties, setProperties] = React.useState<PropertyReadView[]>([]);
  const [selectedPropertyIds, setSelectedPropertyIds] = React.useState<number[] | undefined>(
    props.filterParams.propertyIds
  );

  const [tenantsStatus, setTenantsStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);
  const [tenants, setTenants] = React.useState<TenantReadView[]>([]);
  const [selectedTenantIds, setSelectedTenantIds] = React.useState<number[] | undefined>(props.filterParams.tenantIds);

  const faultReportReaders = React.useMemo(() => {
    const organisationIds = selectedPortfolioIds
      ? Array.from(
          new Set(
            portfolios.filter((item) => selectedPortfolioIds.includes(item.id)).map((item) => item.organisationId)
          )
        )
      : selectedOrganisationIds;

    return [
      ...new Map(
        Array.from(userMembershipsMap.values())
          .flatMap((item) => item)
          .filter(
            (item) =>
              [Role.Admin, Role.Manager, Role.Technician].includes(item.role as Role) &&
              (organisationIds && organisationIds.length !== 0 ? organisationIds.includes(item.organisationId) : true)
          )
          .map((item) => [item.userId, item])
      ).values()
    ];
  }, [userMembershipsMap, selectedOrganisationIds, selectedPortfolioIds, portfolios]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadProperties = async () => {
      try {
        setPropertiesStatus(NetworkRequestStatus.Loading);
        const { data: result } = await propertyApi.propertyResourceList(
          {
            ...createInfinitePaginationParams(),
            propertySubscriptionUserIds: selectedPropertySubscriptionUserIds,
            organisationIds: selectedOrganisationIds,
            portfolioIds: selectedPortfolioIds,
            municipalities: selectedMunicipalities
          },
          {
            signal: abortController.signal
          }
        );

        setProperties(result.records);
        const tenantIdSet = new Set(result.records.map((item) => item.id));
        setSelectedPropertyIds((prevValue) => prevValue?.filter((item) => tenantIdSet.has(item)));

        setPropertiesStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setPropertiesStatus(NetworkRequestStatus.LoadError);
      }
    };

    loadProperties();

    return () => {
      abortController.abort();
    };
  }, [
    handleHttpErrors,
    selectedOrganisationIds,
    selectedPortfolioIds,
    selectedPropertySubscriptionUserIds,
    selectedMunicipalities
  ]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadTenants = async () => {
      try {
        setTenantsStatus(NetworkRequestStatus.Loading);
        const organisationIds = selectedPortfolioIds
          ? Array.from(
              new Set(
                portfolios.filter((item) => selectedPortfolioIds.includes(item.id)).map((item) => item.organisationId)
              )
            )
          : selectedOrganisationIds;

        const { data: result } = await tenantApi.tenantResourceList(
          {
            ...createInfinitePaginationParams(),
            organisationIds,
            ongoingPropertyIds: selectedPropertyIds,
            municipalities: selectedMunicipalities
          },
          {
            signal: abortController.signal
          }
        );

        setTenants(result.records);
        const tenantIdSet = new Set(result.records.map((item) => item.id));
        setSelectedTenantIds((prevValue) => prevValue?.filter((item) => tenantIdSet.has(item)));

        setTenantsStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setTenantsStatus(NetworkRequestStatus.LoadError);
      }
    };

    loadTenants();

    return () => {
      abortController.abort();
    };
  }, [
    handleHttpErrors,
    selectedOrganisationIds,
    selectedPortfolioIds,
    portfolios,
    selectedPropertyIds,
    selectedMunicipalities
  ]);

  const generalParams = {
    organisationIds: selectedOrganisationIds,
    portfolioIds: selectedPortfolioIds,
    propertySubscriptionUserIds: selectedPropertySubscriptionUserIds,
    assigneeIds: selectedAssigneeIds,
    tenantIds: selectedTenantIds,
    propertyIds: selectedPropertyIds,
    municipalities: selectedMunicipalities,
    categories,
    statuses,
    createdAfter: minCreationDate ? new Date(minCreationDate).toISOString() : undefined,
    createdBefore: maxCreationDate ? new Date(maxCreationDate).toISOString() : undefined
  };

  const FormFieldWrapper = React.useMemo(
    () =>
      ({
        children,
        width,
        filterKey
      }: {
        children?: React.ReactNode;
        width?: SemanticWIDTHS;
        filterKey: keyof FaultReportStorageParams;
      }) => {
        if (filterKeys.includes(filterKey)) return <Form.Field width={width}>{children}</Form.Field>;
        else return null;
      },
    [filterKeys]
  );

  return (
    <Modal open size="small" onClose={props.onClose} closeIcon>
      <Modal.Header>{t('faultReports:filterFaultReports')}</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <FormFieldWrapper filterKey="organisationIds">
            <Header as="h4">{t('common:organisations')}</Header>
            <OrganisationDropdown
              organisations={organisations}
              selectedOrganisationIds={selectedOrganisationIds}
              handleOrganisationsChange={(prevValue) => {
                handleOrganisationsChange(prevValue);
                if (prevValue.length > 0)
                  setSelectedAssigneeIds((assigneeIds) => filterUsersByOrganisations(assigneeIds, prevValue));
              }}
            />
          </FormFieldWrapper>

          <FormFieldWrapper filterKey="portfolioIds">
            <Header as="h4">{t('common:portfolios')}</Header>
            <PortfolioDropdown
              portfolios={portfolios}
              handlePortfoliosChange={(prevValue) => {
                handlePortfoliosChange(prevValue);
                if (prevValue.length > 0)
                  setSelectedAssigneeIds((assigneeIds) => filterUsersByPortfolios(assigneeIds, prevValue));
              }}
              selectedPortfolioIds={selectedPortfolioIds}
            />
          </FormFieldWrapper>

          <FormFieldWrapper filterKey="propertySubscriptionUserIds">
            <Header as="h4">{t('faultReports:userPropertySubscriptions')}</Header>
            <UserMembershipDropdown
              memberships={memberships}
              setSelectedUserIds={setSelectedPropertySubscriptionUserIds}
              selectedUserIds={selectedPropertySubscriptionUserIds}
            />
          </FormFieldWrapper>

          <FormFieldWrapper filterKey="assigneeIds">
            <Header as="h4">{t('faultReports:assignees')}</Header>
            <UserMembershipDropdown
              memberships={faultReportReaders}
              setSelectedUserIds={setSelectedAssigneeIds}
              selectedUserIds={selectedAssigneeIds}
            />
          </FormFieldWrapper>

          <FormFieldWrapper filterKey="municipalities">
            <Header as="h4">{t('common:municipalities')}</Header>
            <MunicipalityDropdown
              setSelectedMunicipalities={setSelectedMunicipalities}
              selectedMunicipalities={selectedMunicipalities}
            />
          </FormFieldWrapper>

          <FormFieldWrapper filterKey="propertyIds">
            <Header as="h4">{t('common:properties')}</Header>
            <Form.Dropdown
              placeholder={t('common:properties')}
              value={selectedPropertyIds ?? []}
              renderLabel={(item, i, prop) => <FilterLabel text={item.text} labelProps={prop} />}
              multiple
              fluid
              search
              scrolling
              selection
              floating
              error={propertiesStatus === NetworkRequestStatus.LoadError}
              loading={propertiesStatus === NetworkRequestStatus.Loading}
              disabled={propertiesStatus !== NetworkRequestStatus.None}
              clearable
              options={mapPropertiesToDropdownItems(properties)}
              onChange={(e, d) => {
                setSelectedPropertyIds(d.value as number[]);
              }}
            />
          </FormFieldWrapper>

          <FormFieldWrapper filterKey="tenantIds">
            <Header as="h4">{t('common:tenants')}</Header>
            <Form.Dropdown
              placeholder={t('common:tenants')}
              value={selectedTenantIds ?? []}
              renderLabel={(item, i, prop) => <FilterLabel text={item.text} labelProps={prop} />}
              multiple
              fluid
              search
              scrolling
              selection
              floating
              error={tenantsStatus === NetworkRequestStatus.LoadError}
              loading={tenantsStatus === NetworkRequestStatus.Loading}
              disabled={tenantsStatus !== NetworkRequestStatus.None}
              clearable
              options={mapTenantsToDropdownItems(tenants)}
              onChange={(e, d) => {
                setSelectedTenantIds(d.value as number[]);
              }}
            />
          </FormFieldWrapper>

          <Form.Field width={8}>
            <Header as="h4">{t('faultReports:creationDate')}</Header>
            <Form.Group widths="equal" style={{ alignItems: 'center' }}>
              <FormFieldWrapper filterKey="createdAfter">
                <DatePicker
                  popperPlacement="bottom"
                  selected={minCreationDate ? new Date(minCreationDate) : null}
                  dateFormat="P"
                  placeholderText={t('common:min')}
                  isClearable
                  maxDate={maxCreationDate ? new Date(maxCreationDate) : null}
                  onChange={(date) => setMinCreationDate(date ? toDateString(date) : undefined)}
                  customInput={<Form.Input icon="calendar alternate outline" iconPosition="left" />}
                />
              </FormFieldWrapper>
              {!props.canCacheParams &&
                props.filterKeys.filter((item) => item === 'createdAfter' || item === 'createdBefore').length === 2 && (
                  <div>-</div>
                )}
              <FormFieldWrapper filterKey="createdBefore">
                <DatePicker
                  selected={maxCreationDate ? new Date(maxCreationDate) : null}
                  popperPlacement="bottom"
                  dateFormat="P"
                  placeholderText={t('common:max')}
                  isClearable
                  minDate={minCreationDate ? new Date(minCreationDate) : null}
                  onChange={(date) => setMaxCreationDate(date ? toDateString(date) : undefined)}
                  customInput={<Form.Input icon="calendar alternate outline" iconPosition="left" />}
                />
              </FormFieldWrapper>
            </Form.Group>
          </Form.Field>

          <FormFieldWrapper filterKey="categories">
            <Header as="h4">{t('common:categories')}</Header>

            <Form.Dropdown
              placeholder={t('common:categories')}
              value={categories ?? []}
              renderLabel={(item, i, prop) => <FilterLabel text={item.text} labelProps={prop} />}
              multiple
              fluid
              search
              scrolling
              upward
              selection
              floating
              clearable
              options={Object.values(WorkOrderReadViewCategoryEnum).map((item) => ({
                value: item,
                text: translateWorkOrderCategory(item)
              }))}
              onChange={(e, d) => {
                setCategories(d.value as WorkOrderReadViewCategoryEnum[]);
              }}
            />
          </FormFieldWrapper>

          <FormFieldWrapper filterKey="statuses">
            <Header as="h4">{t('common:status')}</Header>

            <StatusCheckbox
              selectedStatuses={statuses}
              setSelectedStatuses={setStatuses}
              status={WorkOrderReadViewStatusEnum.Received}
            />
            <StatusCheckbox
              selectedStatuses={statuses}
              setSelectedStatuses={setStatuses}
              status={WorkOrderReadViewStatusEnum.Started}
            />
            <StatusCheckbox
              selectedStatuses={statuses}
              setSelectedStatuses={setStatuses}
              status={WorkOrderReadViewStatusEnum.Finished}
            />
          </FormFieldWrapper>
        </Form>
      </Modal.Content>

      <Modal.Actions style={{ display: 'flex', justifyContent: 'space-between' }}>
        {props.canCacheParams ? (
          <div>
            {props.defaultFilterParams && (
              <Button
                basic
                onClick={() => {
                  setSelectedOrganisationIds(props.defaultFilterParams?.organisationIds);
                  setSelectedPortfolioIds(props.defaultFilterParams?.portfolioIds);
                  setSelectedPropertySubscriptionUserIds(props.defaultFilterParams?.propertySubscriptionUserIds);
                  setSelectedAssigneeIds(props.defaultFilterParams?.assigneeIds);
                  setSelectedMunicipalities(props.defaultFilterParams?.municipalities);
                  setSelectedPropertyIds(props.defaultFilterParams?.propertyIds);
                  setSelectedTenantIds(props.defaultFilterParams?.tenantIds);
                  setMinCreationDate(props.defaultFilterParams?.createdAfter);
                  setMaxCreationDate(props.defaultFilterParams?.createdBefore);
                  setCategories(props.defaultFilterParams?.categories ?? []);
                  setStatuses(props.defaultFilterParams?.statuses ?? []);
                }}
                style={{ marginLeft: 0 }}
              >
                {t('common:restoreDefaultFilter')}
              </Button>
            )}
            <Button
              basic
              onClick={() => {
                setFaultReportDefaultFilters(currentUser!.id, generalParams);
                props.reloadDefaultFilters && props.reloadDefaultFilters();
              }}
            >
              {t('common:setFilterAsDefault')}
            </Button>
          </div>
        ) : (
          <div />
        )}

        <div>
          <Button
            color="blue"
            onClick={() => {
              handleClearFilters();
              setMinCreationDate(undefined);
              setMaxCreationDate(undefined);
              setCategories([]);
              setStatuses(Object.values(WorkOrderReadViewStatusEnum));
              setSelectedAssigneeIds(undefined);
              setSelectedPropertyIds(undefined);
              setSelectedTenantIds(undefined);
            }}
          >
            {t('common:clearFilters')}
          </Button>

          <Button
            primary
            onClick={() => {
              props.canCacheParams && setFaultReportSessionFilters(currentUser!.id, generalParams);
              props.onConfirm({ ...props.filterParams, ...generalParams });
              props.onClose();
            }}
          >
            {t('common:confirm')}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

const StatusCheckbox = (props: {
  status: WorkOrderReadViewStatusEnum;
  selectedStatuses: WorkOrderReadViewStatusEnum[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<WorkOrderReadViewStatusEnum[]>>;
}) => {
  const handleStatusChange = (status: WorkOrderReadViewStatusEnum, checked?: boolean) => {
    if (checked) {
      props.setSelectedStatuses((prevValue) => [...prevValue, status]);
    } else props.setSelectedStatuses((prevValue) => prevValue.filter((item) => item !== status));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em' }}>
      {translateWorkOrderStatus(props.status)}
      <Checkbox
        toggle
        checked={props.selectedStatuses.includes(props.status)}
        onChange={(e, d) => handleStatusChange(props.status, d.checked)}
      />
    </div>
  );
};

export default FaultReportFiltersModal;
