import React from 'react';
import { BusinessPlanCostReadView, BusinessPlanCostReadViewCategoryEnum } from '../../../../../GeneratedServices';
import { globalStateCTX } from '../../../../../GlobalState/GlobalState';
import { businessPlanApi } from '../../../../../Http/Http';
import { UserFriendlyApiResponse } from '../../../../../Http/response-error';
import { applyApiResponseValidationToFields, FieldValidationResult } from '../../../../../Utils/validation';

interface BusinessPlanCostEntity extends BusinessPlanCostReadView {
  validationResult?: FieldValidationResult;
  newValue?: number;
}

type BusinessPlanCostState = BusinessPlanCostEntity;
type BusinessPlanCostAction =
  | { type: 'INIT'; item: BusinessPlanCostState }
  | {
      type: 'VALUE_CHANGE';
      value?: number;
    }
  | {
      type: 'VALIDATION_CHANGE';
      validationResult?: FieldValidationResult;
    }
  | {
      type: 'VALUE_RESET';
    };

function businessPlanCostReducer(state: BusinessPlanCostState, action: BusinessPlanCostAction): BusinessPlanCostState {
  switch (action?.type) {
    case 'INIT':
      return action.item;

    case 'VALUE_CHANGE':
      return {
        ...state,
        newValue: action.value,
        validationResult: undefined
      };
    case 'VALIDATION_CHANGE':
      return {
        ...state,
        validationResult: action.validationResult
      };
    case 'VALUE_RESET':
      return {
        ...state,
        newValue: state.value
      };
  }
}

export enum BusinessPlanCostStatus {
  None,
  Saving
}

export const useBusinessPlanCostReducer = (props: { cost?: BusinessPlanCostReadView }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [businessPlanCost, dispatchBusinessPlanCost] = React.useReducer(
    businessPlanCostReducer,
    props.cost
      ? { ...props.cost, newValue: props.cost.value }
      : { id: 0, propertyId: 0, category: BusinessPlanCostReadViewCategoryEnum.Cleaning, value: 0 }
  );
  const [status, setStatus] = React.useState<BusinessPlanCostStatus>(BusinessPlanCostStatus.None);

  React.useEffect(() => {
    if (props.cost)
      dispatchBusinessPlanCost({
        type: 'INIT',
        item: { ...props.cost, newValue: props.cost?.value }
      });
  }, [props.cost]);

  const handleResponseValidationError = (resp: UserFriendlyApiResponse) => {
    const fieldIdMapping = [
      {
        formFieldId: 'value',
        setStateFunc: (data: any) => {
          dispatchBusinessPlanCost({
            type: 'VALIDATION_CHANGE',
            validationResult: data
          });
        }
      }
    ];

    applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  const saveBusinessPlanCost = async () => {
    try {
      setStatus(BusinessPlanCostStatus.Saving);

      await businessPlanApi.businessPlanCostResourceUpdate({
        id: businessPlanCost.id,
        businessPlanCostUpdateView: {
          value: businessPlanCost.newValue ?? 0
        }
      });
      return true;
    } catch (error) {
      handleHttpErrors(error, { handleResponseValidationError });
      return false;
    } finally {
      setStatus(BusinessPlanCostStatus.None);
    }
  };

  return {
    businessPlanCost,
    saveBusinessPlanCost,
    saving: status === BusinessPlanCostStatus.Saving,
    dispatchBusinessPlanCost
  };
};
