import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { FiltersContext } from '../../../FiltersProvider/FiltersProvider';
import { NetworkRequestStatus } from '../../../Http/Http';
import { mapPortfoliosToDropdownItems, PortfolioReadView } from '../../../Services/PortfolioService.types';
import FilterLabel from '../FilterLabel';

interface Props {
  selectedPortfolioIds?: number[];
  handlePortfoliosChange: (portfolioIds: number[]) => void;
  portfolios: PortfolioReadView[];
}

const PortfolioDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common']);

  const { portfoliosStatus } = React.useContext(FiltersContext);
  return (
    <Dropdown
      placeholder={t('common:allPortfolios')}
      value={props.selectedPortfolioIds ?? []}
      renderLabel={(item, i, prop) => <FilterLabel text={item.text} type="portfolio" labelProps={prop} />}
      multiple
      fluid
      search
      scrolling
      selection
      floating
      error={portfoliosStatus === NetworkRequestStatus.LoadError}
      loading={portfoliosStatus === NetworkRequestStatus.Loading}
      disabled={portfoliosStatus !== NetworkRequestStatus.None}
      clearable
      options={mapPortfoliosToDropdownItems(props.portfolios)}
      onChange={(e, d) => {
        props.handlePortfoliosChange(d.value as number[]);
      }}
    />
  );
};

export default PortfolioDropdown;
