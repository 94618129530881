/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ComparableLeaseCreateView } from '../model';
// @ts-ignore
import { ComparableLeaseListView } from '../model';
// @ts-ignore
import { ComparableLeaseReadView } from '../model';
// @ts-ignore
import { ComparableLeaseUpdateView } from '../model';
// @ts-ignore
import { ComparableMunicipalityLeaseStatisticsReadView } from '../model';
// @ts-ignore
import { ComparableMunicipalityTransactionStatisticsReadView } from '../model';
// @ts-ignore
import { ComparablePolygonLeaseStatisticsReadView } from '../model';
// @ts-ignore
import { ComparablePolygonTransactionStatisticsReadView } from '../model';
// @ts-ignore
import { ComparablePropertyCreateView } from '../model';
// @ts-ignore
import { ComparablePropertyLeaseStatisticsReadView } from '../model';
// @ts-ignore
import { ComparablePropertyListView } from '../model';
// @ts-ignore
import { ComparablePropertyReadView } from '../model';
// @ts-ignore
import { ComparablePropertyTransactionReadView } from '../model';
// @ts-ignore
import { ComparablePropertyUpdateView } from '../model';
// @ts-ignore
import { ComparableTransactionCreateView } from '../model';
// @ts-ignore
import { ComparableTransactionListView } from '../model';
// @ts-ignore
import { ComparableTransactionReadView } from '../model';
// @ts-ignore
import { ComparableTransactionUpdateView } from '../model';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { ImportResultsView } from '../model';
// @ts-ignore
import { PointView } from '../model';
/**
 * ComparableApi - axios parameter creator
 * @export
 */
export const ComparableApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a comparable lease
     * @param {ComparableLeaseCreateView} [comparableLeaseCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceCreate: async (
      comparableLeaseCreateView?: ComparableLeaseCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-leases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        comparableLeaseCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a comparable lease
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparableLeaseResourceDelete', 'id', id);
      const localVarPath = `/v1/comparable-leases/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Download Excel template for importing comparable leases to the organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceDownloadExcelTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-leases/xlsx/organisation-template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Download Excel template for importing comparable leases to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceDownloadExcelTemplateForProperty: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-leases/xlsx/property-template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import comparable leases from Excel file to the organisation
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceImportExcelToOrganisation: async (
      organisationId: number,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('comparableLeaseResourceImportExcelToOrganisation', 'organisationId', organisationId);
      const localVarPath = `/v1/comparable-leases/xlsx/organisation/{organisationId}`.replace(
        `{${'organisationId'}}`,
        encodeURIComponent(String(organisationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import comparable leases from Excel file to the property
     * @param {number} propertyId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceImportExcelToProperty: async (
      propertyId: number,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'propertyId' is not null or undefined
      assertParamExists('comparableLeaseResourceImportExcelToProperty', 'propertyId', propertyId);
      const localVarPath = `/v1/comparable-leases/xlsx/property/{propertyId}`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List comparable leases
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {boolean} [sensitive] Filter by sensitive
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {string} [date] Filter by leases active at the given date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      comparablePropertyId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minArea?: number,
      maxArea?: number,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-leases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minBaseRent !== undefined) {
        localVarQueryParameter['minBaseRent'] = minBaseRent;
      }

      if (maxBaseRent !== undefined) {
        localVarQueryParameter['maxBaseRent'] = maxBaseRent;
      }

      if (minTotalRent !== undefined) {
        localVarQueryParameter['minTotalRent'] = minTotalRent;
      }

      if (maxTotalRent !== undefined) {
        localVarQueryParameter['maxTotalRent'] = maxTotalRent;
      }

      if (minBaseRentPerSQM !== undefined) {
        localVarQueryParameter['minBaseRentPerSQM'] = minBaseRentPerSQM;
      }

      if (maxBaseRentPerSQM !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQM'] = maxBaseRentPerSQM;
      }

      if (minTotalRentPerSQM !== undefined) {
        localVarQueryParameter['minTotalRentPerSQM'] = minTotalRentPerSQM;
      }

      if (maxTotalRentPerSQM !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQM'] = maxTotalRentPerSQM;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a comparable lease
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparableLeaseResourceRead', 'id', id);
      const localVarPath = `/v1/comparable-leases/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a comparable lease
     * @param {number} id
     * @param {ComparableLeaseUpdateView} [comparableLeaseUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceUpdate: async (
      id: number,
      comparableLeaseUpdateView?: ComparableLeaseUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparableLeaseResourceUpdate', 'id', id);
      const localVarPath = `/v1/comparable-leases/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        comparableLeaseUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a comparable property
     * @param {ComparablePropertyCreateView} [comparablePropertyCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceCreate: async (
      comparablePropertyCreateView?: ComparablePropertyCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-properties`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        comparablePropertyCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a comparable property
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparablePropertyResourceDelete', 'id', id);
      const localVarPath = `/v1/comparable-properties/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List comparable properties
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by type
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {boolean} [validPosition] Filter by if the property has a null position or not
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      validPosition?: boolean,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-properties`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (minLeaseCount !== undefined) {
        localVarQueryParameter['minLeaseCount'] = minLeaseCount;
      }

      if (maxLeaseCount !== undefined) {
        localVarQueryParameter['maxLeaseCount'] = maxLeaseCount;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (owned !== undefined) {
        localVarQueryParameter['owned'] = owned;
      }

      if (polygon) {
        localVarQueryParameter['polygon'] = polygon;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (validPosition !== undefined) {
        localVarQueryParameter['validPosition'] = validPosition;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a comparable property
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparablePropertyResourceRead', 'id', id);
      const localVarPath = `/v1/comparable-properties/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a comparable property
     * @param {number} id
     * @param {ComparablePropertyUpdateView} [comparablePropertyUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceUpdate: async (
      id: number,
      comparablePropertyUpdateView?: ComparablePropertyUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparablePropertyResourceUpdate', 'id', id);
      const localVarPath = `/v1/comparable-properties/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        comparablePropertyUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Export comparable leases to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceExportExcelLeases: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('comparableResourceExportExcelLeases', 'token', token);
      const localVarPath = `/v1/comparable-leases/xlsx/export/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get a token to export leases
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceExportExcelLeasesUrl: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-leases/xlsx/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minBaseRent !== undefined) {
        localVarQueryParameter['minBaseRent'] = minBaseRent;
      }

      if (maxBaseRent !== undefined) {
        localVarQueryParameter['maxBaseRent'] = maxBaseRent;
      }

      if (minTotalRent !== undefined) {
        localVarQueryParameter['minTotalRent'] = minTotalRent;
      }

      if (maxTotalRent !== undefined) {
        localVarQueryParameter['maxTotalRent'] = maxTotalRent;
      }

      if (minBaseRentPerSQM !== undefined) {
        localVarQueryParameter['minBaseRentPerSQM'] = minBaseRentPerSQM;
      }

      if (maxBaseRentPerSQM !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQM'] = maxBaseRentPerSQM;
      }

      if (minTotalRentPerSQM !== undefined) {
        localVarQueryParameter['minTotalRentPerSQM'] = minTotalRentPerSQM;
      }

      if (maxTotalRentPerSQM !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQM'] = maxTotalRentPerSQM;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minLeaseCount !== undefined) {
        localVarQueryParameter['minLeaseCount'] = minLeaseCount;
      }

      if (maxLeaseCount !== undefined) {
        localVarQueryParameter['maxLeaseCount'] = maxLeaseCount;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (owned !== undefined) {
        localVarQueryParameter['owned'] = owned;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Export comparable transactions to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceExportExcelTransactions: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('comparableResourceExportExcelTransactions', 'token', token);
      const localVarPath = `/v1/comparable-transactions/xlsx/export/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get a token to export transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceExportExcelTransactionsUrl: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-transactions/xlsx/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minPricePerSQM !== undefined) {
        localVarQueryParameter['minPricePerSQM'] = minPricePerSQM;
      }

      if (maxPricePerSQM !== undefined) {
        localVarQueryParameter['maxPricePerSQM'] = maxPricePerSQM;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter['minPrice'] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter['maxPrice'] = maxPrice;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minNOI !== undefined) {
        localVarQueryParameter['minNOI'] = minNOI;
      }

      if (maxNOI !== undefined) {
        localVarQueryParameter['maxNOI'] = maxNOI;
      }

      if (minYield !== undefined) {
        localVarQueryParameter['minYield'] = minYield;
      }

      if (maxYield !== undefined) {
        localVarQueryParameter['maxYield'] = maxYield;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create token for listing municipalities with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListMunicipalityLeaseStatisticsToken: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/municipality-lease-statistics-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minBaseRent !== undefined) {
        localVarQueryParameter['minBaseRent'] = minBaseRent;
      }

      if (maxBaseRent !== undefined) {
        localVarQueryParameter['maxBaseRent'] = maxBaseRent;
      }

      if (minTotalRent !== undefined) {
        localVarQueryParameter['minTotalRent'] = minTotalRent;
      }

      if (maxTotalRent !== undefined) {
        localVarQueryParameter['maxTotalRent'] = maxTotalRent;
      }

      if (minBaseRentPerSQM !== undefined) {
        localVarQueryParameter['minBaseRentPerSQM'] = minBaseRentPerSQM;
      }

      if (maxBaseRentPerSQM !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQM'] = maxBaseRentPerSQM;
      }

      if (minTotalRentPerSQM !== undefined) {
        localVarQueryParameter['minTotalRentPerSQM'] = minTotalRentPerSQM;
      }

      if (maxTotalRentPerSQM !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQM'] = maxTotalRentPerSQM;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minLeaseCount !== undefined) {
        localVarQueryParameter['minLeaseCount'] = minLeaseCount;
      }

      if (maxLeaseCount !== undefined) {
        localVarQueryParameter['maxLeaseCount'] = maxLeaseCount;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (owned !== undefined) {
        localVarQueryParameter['owned'] = owned;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create token for listing properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListMunicipalityTransactionStatisticsToken: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/municipality-transaction-statistics-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minPricePerSQM !== undefined) {
        localVarQueryParameter['minPricePerSQM'] = minPricePerSQM;
      }

      if (maxPricePerSQM !== undefined) {
        localVarQueryParameter['maxPricePerSQM'] = maxPricePerSQM;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter['minPrice'] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter['maxPrice'] = maxPrice;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minNOI !== undefined) {
        localVarQueryParameter['minNOI'] = minNOI;
      }

      if (maxNOI !== undefined) {
        localVarQueryParameter['maxNOI'] = maxNOI;
      }

      if (minYield !== undefined) {
        localVarQueryParameter['minYield'] = minYield;
      }

      if (maxYield !== undefined) {
        localVarQueryParameter['maxYield'] = maxYield;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create token for listing properties with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListPropertyLeaseStatisticsToken: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/property-lease-statistics-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minBaseRent !== undefined) {
        localVarQueryParameter['minBaseRent'] = minBaseRent;
      }

      if (maxBaseRent !== undefined) {
        localVarQueryParameter['maxBaseRent'] = maxBaseRent;
      }

      if (minTotalRent !== undefined) {
        localVarQueryParameter['minTotalRent'] = minTotalRent;
      }

      if (maxTotalRent !== undefined) {
        localVarQueryParameter['maxTotalRent'] = maxTotalRent;
      }

      if (minBaseRentPerSQM !== undefined) {
        localVarQueryParameter['minBaseRentPerSQM'] = minBaseRentPerSQM;
      }

      if (maxBaseRentPerSQM !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQM'] = maxBaseRentPerSQM;
      }

      if (minTotalRentPerSQM !== undefined) {
        localVarQueryParameter['minTotalRentPerSQM'] = minTotalRentPerSQM;
      }

      if (maxTotalRentPerSQM !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQM'] = maxTotalRentPerSQM;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minLeaseCount !== undefined) {
        localVarQueryParameter['minLeaseCount'] = minLeaseCount;
      }

      if (maxLeaseCount !== undefined) {
        localVarQueryParameter['maxLeaseCount'] = maxLeaseCount;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (owned !== undefined) {
        localVarQueryParameter['owned'] = owned;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create token for listing properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListPropertyTransactionsToken: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/property-transactions-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minPricePerSQM !== undefined) {
        localVarQueryParameter['minPricePerSQM'] = minPricePerSQM;
      }

      if (maxPricePerSQM !== undefined) {
        localVarQueryParameter['maxPricePerSQM'] = maxPricePerSQM;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter['minPrice'] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter['maxPrice'] = maxPrice;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minNOI !== undefined) {
        localVarQueryParameter['minNOI'] = minNOI;
      }

      if (maxNOI !== undefined) {
        localVarQueryParameter['maxNOI'] = maxNOI;
      }

      if (minYield !== undefined) {
        localVarQueryParameter['minYield'] = minYield;
      }

      if (maxYield !== undefined) {
        localVarQueryParameter['maxYield'] = maxYield;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create token for listing lease statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListPropertyTypeLeaseStatisticsToken: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/property-type-lease-statistics-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minBaseRent !== undefined) {
        localVarQueryParameter['minBaseRent'] = minBaseRent;
      }

      if (maxBaseRent !== undefined) {
        localVarQueryParameter['maxBaseRent'] = maxBaseRent;
      }

      if (minTotalRent !== undefined) {
        localVarQueryParameter['minTotalRent'] = minTotalRent;
      }

      if (maxTotalRent !== undefined) {
        localVarQueryParameter['maxTotalRent'] = maxTotalRent;
      }

      if (minBaseRentPerSQM !== undefined) {
        localVarQueryParameter['minBaseRentPerSQM'] = minBaseRentPerSQM;
      }

      if (maxBaseRentPerSQM !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQM'] = maxBaseRentPerSQM;
      }

      if (minTotalRentPerSQM !== undefined) {
        localVarQueryParameter['minTotalRentPerSQM'] = minTotalRentPerSQM;
      }

      if (maxTotalRentPerSQM !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQM'] = maxTotalRentPerSQM;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minLeaseCount !== undefined) {
        localVarQueryParameter['minLeaseCount'] = minLeaseCount;
      }

      if (maxLeaseCount !== undefined) {
        localVarQueryParameter['maxLeaseCount'] = maxLeaseCount;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (owned !== undefined) {
        localVarQueryParameter['owned'] = owned;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create token for listing transaction statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListTransactionStatisticsToken: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/property-type-transaction-statistics-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minPricePerSQM !== undefined) {
        localVarQueryParameter['minPricePerSQM'] = minPricePerSQM;
      }

      if (maxPricePerSQM !== undefined) {
        localVarQueryParameter['maxPricePerSQM'] = maxPricePerSQM;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter['minPrice'] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter['maxPrice'] = maxPrice;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minNOI !== undefined) {
        localVarQueryParameter['minNOI'] = minNOI;
      }

      if (maxNOI !== undefined) {
        localVarQueryParameter['maxNOI'] = maxNOI;
      }

      if (minYield !== undefined) {
        localVarQueryParameter['minYield'] = minYield;
      }

      if (maxYield !== undefined) {
        localVarQueryParameter['maxYield'] = maxYield;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List transaction statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetTransactionStatistics: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/property-type-transaction-statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minPricePerSQM !== undefined) {
        localVarQueryParameter['minPricePerSQM'] = minPricePerSQM;
      }

      if (maxPricePerSQM !== undefined) {
        localVarQueryParameter['maxPricePerSQM'] = maxPricePerSQM;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter['minPrice'] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter['maxPrice'] = maxPrice;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minNOI !== undefined) {
        localVarQueryParameter['minNOI'] = minNOI;
      }

      if (maxNOI !== undefined) {
        localVarQueryParameter['maxNOI'] = maxNOI;
      }

      if (minYield !== undefined) {
        localVarQueryParameter['minYield'] = minYield;
      }

      if (maxYield !== undefined) {
        localVarQueryParameter['maxYield'] = maxYield;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List municipalities with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListMunicipalityLeaseStatistics: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/municipality-lease-statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minBaseRent !== undefined) {
        localVarQueryParameter['minBaseRent'] = minBaseRent;
      }

      if (maxBaseRent !== undefined) {
        localVarQueryParameter['maxBaseRent'] = maxBaseRent;
      }

      if (minTotalRent !== undefined) {
        localVarQueryParameter['minTotalRent'] = minTotalRent;
      }

      if (maxTotalRent !== undefined) {
        localVarQueryParameter['maxTotalRent'] = maxTotalRent;
      }

      if (minBaseRentPerSQM !== undefined) {
        localVarQueryParameter['minBaseRentPerSQM'] = minBaseRentPerSQM;
      }

      if (maxBaseRentPerSQM !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQM'] = maxBaseRentPerSQM;
      }

      if (minTotalRentPerSQM !== undefined) {
        localVarQueryParameter['minTotalRentPerSQM'] = minTotalRentPerSQM;
      }

      if (maxTotalRentPerSQM !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQM'] = maxTotalRentPerSQM;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minLeaseCount !== undefined) {
        localVarQueryParameter['minLeaseCount'] = minLeaseCount;
      }

      if (maxLeaseCount !== undefined) {
        localVarQueryParameter['maxLeaseCount'] = maxLeaseCount;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (owned !== undefined) {
        localVarQueryParameter['owned'] = owned;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List municipalities with lease statistics
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListMunicipalityLeaseStatisticsToken: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('comparableResourceListMunicipalityLeaseStatisticsToken', 'token', token);
      const localVarPath = `/v1/comparable/municipality-lease-statistics-token/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List municipalities with transaction statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListMunicipalityTransactionStatistics: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/municipality-transaction-statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minPricePerSQM !== undefined) {
        localVarQueryParameter['minPricePerSQM'] = minPricePerSQM;
      }

      if (maxPricePerSQM !== undefined) {
        localVarQueryParameter['maxPricePerSQM'] = maxPricePerSQM;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter['minPrice'] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter['maxPrice'] = maxPrice;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minNOI !== undefined) {
        localVarQueryParameter['minNOI'] = minNOI;
      }

      if (maxNOI !== undefined) {
        localVarQueryParameter['maxNOI'] = maxNOI;
      }

      if (minYield !== undefined) {
        localVarQueryParameter['minYield'] = minYield;
      }

      if (maxYield !== undefined) {
        localVarQueryParameter['maxYield'] = maxYield;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List properties with transactions
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListMunicipalityTransactionStatisticsToken: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('comparableResourceListMunicipalityTransactionStatisticsToken', 'token', token);
      const localVarPath = `/v1/comparable/municipality-transaction-statistics-token/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List properties with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyLeaseStatistics: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/property-lease-statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minBaseRent !== undefined) {
        localVarQueryParameter['minBaseRent'] = minBaseRent;
      }

      if (maxBaseRent !== undefined) {
        localVarQueryParameter['maxBaseRent'] = maxBaseRent;
      }

      if (minTotalRent !== undefined) {
        localVarQueryParameter['minTotalRent'] = minTotalRent;
      }

      if (maxTotalRent !== undefined) {
        localVarQueryParameter['maxTotalRent'] = maxTotalRent;
      }

      if (minBaseRentPerSQM !== undefined) {
        localVarQueryParameter['minBaseRentPerSQM'] = minBaseRentPerSQM;
      }

      if (maxBaseRentPerSQM !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQM'] = maxBaseRentPerSQM;
      }

      if (minTotalRentPerSQM !== undefined) {
        localVarQueryParameter['minTotalRentPerSQM'] = minTotalRentPerSQM;
      }

      if (maxTotalRentPerSQM !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQM'] = maxTotalRentPerSQM;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minLeaseCount !== undefined) {
        localVarQueryParameter['minLeaseCount'] = minLeaseCount;
      }

      if (maxLeaseCount !== undefined) {
        localVarQueryParameter['maxLeaseCount'] = maxLeaseCount;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (owned !== undefined) {
        localVarQueryParameter['owned'] = owned;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List properties with lease statistics
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyLeaseStatisticsToken: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('comparableResourceListPropertyLeaseStatisticsToken', 'token', token);
      const localVarPath = `/v1/comparable/property-lease-statistics-token/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyTransactions: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/property-transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minPricePerSQM !== undefined) {
        localVarQueryParameter['minPricePerSQM'] = minPricePerSQM;
      }

      if (maxPricePerSQM !== undefined) {
        localVarQueryParameter['maxPricePerSQM'] = maxPricePerSQM;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter['minPrice'] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter['maxPrice'] = maxPrice;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minNOI !== undefined) {
        localVarQueryParameter['minNOI'] = minNOI;
      }

      if (maxNOI !== undefined) {
        localVarQueryParameter['maxNOI'] = maxNOI;
      }

      if (minYield !== undefined) {
        localVarQueryParameter['minYield'] = minYield;
      }

      if (maxYield !== undefined) {
        localVarQueryParameter['maxYield'] = maxYield;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List properties with transactions
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyTransactionsToken: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('comparableResourceListPropertyTransactionsToken', 'token', token);
      const localVarPath = `/v1/comparable/property-transactions-token/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List lease statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyTypeLeaseStatistics: async (
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable/property-type-lease-statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minBaseRent !== undefined) {
        localVarQueryParameter['minBaseRent'] = minBaseRent;
      }

      if (maxBaseRent !== undefined) {
        localVarQueryParameter['maxBaseRent'] = maxBaseRent;
      }

      if (minTotalRent !== undefined) {
        localVarQueryParameter['minTotalRent'] = minTotalRent;
      }

      if (maxTotalRent !== undefined) {
        localVarQueryParameter['maxTotalRent'] = maxTotalRent;
      }

      if (minBaseRentPerSQM !== undefined) {
        localVarQueryParameter['minBaseRentPerSQM'] = minBaseRentPerSQM;
      }

      if (maxBaseRentPerSQM !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQM'] = maxBaseRentPerSQM;
      }

      if (minTotalRentPerSQM !== undefined) {
        localVarQueryParameter['minTotalRentPerSQM'] = minTotalRentPerSQM;
      }

      if (maxTotalRentPerSQM !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQM'] = maxTotalRentPerSQM;
      }

      if (minBaseRentIndexed !== undefined) {
        localVarQueryParameter['minBaseRentIndexed'] = minBaseRentIndexed;
      }

      if (maxBaseRentIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentIndexed'] = maxBaseRentIndexed;
      }

      if (minTotalRentIndexed !== undefined) {
        localVarQueryParameter['minTotalRentIndexed'] = minTotalRentIndexed;
      }

      if (maxTotalRentIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentIndexed'] = maxTotalRentIndexed;
      }

      if (minBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minBaseRentPerSQMIndexed'] = minBaseRentPerSQMIndexed;
      }

      if (maxBaseRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxBaseRentPerSQMIndexed'] = maxBaseRentPerSQMIndexed;
      }

      if (minTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['minTotalRentPerSQMIndexed'] = minTotalRentPerSQMIndexed;
      }

      if (maxTotalRentPerSQMIndexed !== undefined) {
        localVarQueryParameter['maxTotalRentPerSQMIndexed'] = maxTotalRentPerSQMIndexed;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minLeaseCount !== undefined) {
        localVarQueryParameter['minLeaseCount'] = minLeaseCount;
      }

      if (maxLeaseCount !== undefined) {
        localVarQueryParameter['maxLeaseCount'] = maxLeaseCount;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (municipality !== undefined) {
        localVarQueryParameter['municipality'] = municipality;
      }

      if (comparablePropertyType !== undefined) {
        localVarQueryParameter['comparablePropertyType'] = comparablePropertyType;
      }

      if (owned !== undefined) {
        localVarQueryParameter['owned'] = owned;
      }

      if (polygon) {
        localVarQueryParameter['polygon[]'] = polygon;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (comparablePropertyTypes) {
        localVarQueryParameter['comparablePropertyTypes'] = comparablePropertyTypes;
      }

      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List lease statistics by property type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyTypeLeaseStatisticsToken: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('comparableResourceListPropertyTypeLeaseStatisticsToken', 'token', token);
      const localVarPath = `/v1/comparable/property-type-lease-statistics-token/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List transaction statistics by property type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListTransactionStatisticsToken: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('comparableResourceListTransactionStatisticsToken', 'token', token);
      const localVarPath = `/v1/comparable/property-type-transaction-statistics-token/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a comparable transaction
     * @param {ComparableTransactionCreateView} [comparableTransactionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceCreate: async (
      comparableTransactionCreateView?: ComparableTransactionCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        comparableTransactionCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a comparable transaction
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparableTransactionResourceDelete', 'id', id);
      const localVarPath = `/v1/comparable-transactions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Download Excel template for importing comparable transactions to the organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceDownloadExcelTemplateForOrganisation: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-transactions/xlsx/organisation-template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Download Excel template for importing comparable transactions to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceDownloadExcelTemplateForProperty: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-transactions/xlsx/property-template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import comparable transactions from Excel file to the organisation
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceImportExcelToOrganisation: async (
      organisationId: number,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('comparableTransactionResourceImportExcelToOrganisation', 'organisationId', organisationId);
      const localVarPath = `/v1/comparable-transactions/xlsx/organisation/{organisationId}`.replace(
        `{${'organisationId'}}`,
        encodeURIComponent(String(organisationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import comparable transactions from Excel file to the property
     * @param {number} propertyId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceImportExcelToProperty: async (
      propertyId: number,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'propertyId' is not null or undefined
      assertParamExists('comparableTransactionResourceImportExcelToProperty', 'propertyId', propertyId);
      const localVarPath = `/v1/comparable-transactions/xlsx/property/{propertyId}`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List comparable transactions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {boolean} [sensitive] Filter by sensitive
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      comparablePropertyId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/comparable-transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (comparablePropertyId !== undefined) {
        localVarQueryParameter['comparablePropertyId'] = comparablePropertyId;
      }

      if (sensitive !== undefined) {
        localVarQueryParameter['sensitive'] = sensitive;
      }

      if (minPricePerSQM !== undefined) {
        localVarQueryParameter['minPricePerSQM'] = minPricePerSQM;
      }

      if (maxPricePerSQM !== undefined) {
        localVarQueryParameter['maxPricePerSQM'] = maxPricePerSQM;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter['minPrice'] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter['maxPrice'] = maxPrice;
      }

      if (minArea !== undefined) {
        localVarQueryParameter['minArea'] = minArea;
      }

      if (maxArea !== undefined) {
        localVarQueryParameter['maxArea'] = maxArea;
      }

      if (minNOI !== undefined) {
        localVarQueryParameter['minNOI'] = minNOI;
      }

      if (maxNOI !== undefined) {
        localVarQueryParameter['maxNOI'] = maxNOI;
      }

      if (minYield !== undefined) {
        localVarQueryParameter['minYield'] = minYield;
      }

      if (maxYield !== undefined) {
        localVarQueryParameter['maxYield'] = maxYield;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString().substr(0, 10) : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a comparable transaction
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparableTransactionResourceRead', 'id', id);
      const localVarPath = `/v1/comparable-transactions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a comparable transaction
     * @param {number} id
     * @param {ComparableTransactionUpdateView} [comparableTransactionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceUpdate: async (
      id: number,
      comparableTransactionUpdateView?: ComparableTransactionUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comparableTransactionResourceUpdate', 'id', id);
      const localVarPath = `/v1/comparable-transactions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        comparableTransactionUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ComparableApi - functional programming interface
 * @export
 */
export const ComparableApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ComparableApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a comparable lease
     * @param {ComparableLeaseCreateView} [comparableLeaseCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceCreate(
      comparableLeaseCreateView?: ComparableLeaseCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparableLeaseReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceCreate(
        comparableLeaseCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a comparable lease
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download Excel template for importing comparable leases to the organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceDownloadExcelTemplate(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceDownloadExcelTemplate(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download Excel template for importing comparable leases to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceDownloadExcelTemplateForProperty(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceDownloadExcelTemplateForProperty(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Import comparable leases from Excel file to the organisation
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceImportExcelToOrganisation(
      organisationId: number,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResultsView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceImportExcelToOrganisation(
        organisationId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Import comparable leases from Excel file to the property
     * @param {number} propertyId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceImportExcelToProperty(
      propertyId: number,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResultsView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceImportExcelToProperty(
        propertyId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List comparable leases
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {boolean} [sensitive] Filter by sensitive
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {string} [date] Filter by leases active at the given date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      comparablePropertyId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minArea?: number,
      maxArea?: number,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparableLeaseListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceList(
        limit,
        offset,
        organisationId,
        comparablePropertyId,
        sensitive,
        minBaseRent,
        maxBaseRent,
        minTotalRent,
        maxTotalRent,
        minBaseRentPerSQM,
        maxBaseRentPerSQM,
        minTotalRentPerSQM,
        maxTotalRentPerSQM,
        minArea,
        maxArea,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a comparable lease
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparableLeaseReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a comparable lease
     * @param {number} id
     * @param {ComparableLeaseUpdateView} [comparableLeaseUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableLeaseResourceUpdate(
      id: number,
      comparableLeaseUpdateView?: ComparableLeaseUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparableLeaseReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableLeaseResourceUpdate(
        id,
        comparableLeaseUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a comparable property
     * @param {ComparablePropertyCreateView} [comparablePropertyCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparablePropertyResourceCreate(
      comparablePropertyCreateView?: ComparablePropertyCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablePropertyReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparablePropertyResourceCreate(
        comparablePropertyCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a comparable property
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparablePropertyResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparablePropertyResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List comparable properties
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by type
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {boolean} [validPosition] Filter by if the property has a null position or not
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparablePropertyResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      validPosition?: boolean,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablePropertyListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparablePropertyResourceList(
        limit,
        offset,
        organisationId,
        minLeaseCount,
        maxLeaseCount,
        date,
        municipality,
        comparablePropertyType,
        owned,
        polygon,
        municipalities,
        comparablePropertyTypes,
        validPosition,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a comparable property
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparablePropertyResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablePropertyReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparablePropertyResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a comparable property
     * @param {number} id
     * @param {ComparablePropertyUpdateView} [comparablePropertyUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparablePropertyResourceUpdate(
      id: number,
      comparablePropertyUpdateView?: ComparablePropertyUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablePropertyReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparablePropertyResourceUpdate(
        id,
        comparablePropertyUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Export comparable leases to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceExportExcelLeases(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceExportExcelLeases(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a token to export leases
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceExportExcelLeasesUrl(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceExportExcelLeasesUrl(
        comparablePropertyId,
        organisationId,
        sensitive,
        minBaseRent,
        maxBaseRent,
        minTotalRent,
        maxTotalRent,
        minBaseRentPerSQM,
        maxBaseRentPerSQM,
        minTotalRentPerSQM,
        maxTotalRentPerSQM,
        minBaseRentIndexed,
        maxBaseRentIndexed,
        minTotalRentIndexed,
        maxTotalRentIndexed,
        minBaseRentPerSQMIndexed,
        maxBaseRentPerSQMIndexed,
        minTotalRentPerSQMIndexed,
        maxTotalRentPerSQMIndexed,
        minArea,
        maxArea,
        minLeaseCount,
        maxLeaseCount,
        date,
        municipality,
        comparablePropertyType,
        owned,
        polygon,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Export comparable transactions to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceExportExcelTransactions(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceExportExcelTransactions(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a token to export transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceExportExcelTransactionsUrl(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceExportExcelTransactionsUrl(
        comparablePropertyId,
        organisationId,
        sensitive,
        minPricePerSQM,
        maxPricePerSQM,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        minNOI,
        maxNOI,
        minYield,
        maxYield,
        startDate,
        endDate,
        municipality,
        comparablePropertyType,
        polygon,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create token for listing municipalities with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceGetListMunicipalityLeaseStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.comparableResourceGetListMunicipalityLeaseStatisticsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create token for listing properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceGetListMunicipalityTransactionStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.comparableResourceGetListMunicipalityTransactionStatisticsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          municipality,
          comparablePropertyType,
          polygon,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create token for listing properties with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceGetListPropertyLeaseStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceGetListPropertyLeaseStatisticsToken(
        comparablePropertyId,
        organisationId,
        sensitive,
        minBaseRent,
        maxBaseRent,
        minTotalRent,
        maxTotalRent,
        minBaseRentPerSQM,
        maxBaseRentPerSQM,
        minTotalRentPerSQM,
        maxTotalRentPerSQM,
        minBaseRentIndexed,
        maxBaseRentIndexed,
        minTotalRentIndexed,
        maxTotalRentIndexed,
        minBaseRentPerSQMIndexed,
        maxBaseRentPerSQMIndexed,
        minTotalRentPerSQMIndexed,
        maxTotalRentPerSQMIndexed,
        minArea,
        maxArea,
        minLeaseCount,
        maxLeaseCount,
        date,
        municipality,
        comparablePropertyType,
        owned,
        polygon,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create token for listing properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceGetListPropertyTransactionsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceGetListPropertyTransactionsToken(
        comparablePropertyId,
        organisationId,
        sensitive,
        minPricePerSQM,
        maxPricePerSQM,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        minNOI,
        maxNOI,
        minYield,
        maxYield,
        startDate,
        endDate,
        municipality,
        comparablePropertyType,
        polygon,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create token for listing lease statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceGetListPropertyTypeLeaseStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.comparableResourceGetListPropertyTypeLeaseStatisticsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create token for listing transaction statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceGetListTransactionStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceGetListTransactionStatisticsToken(
        comparablePropertyId,
        organisationId,
        sensitive,
        minPricePerSQM,
        maxPricePerSQM,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        minNOI,
        maxNOI,
        minYield,
        maxYield,
        startDate,
        endDate,
        municipality,
        comparablePropertyType,
        polygon,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List transaction statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceGetTransactionStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablePolygonTransactionStatisticsReadView>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceGetTransactionStatistics(
        comparablePropertyId,
        organisationId,
        sensitive,
        minPricePerSQM,
        maxPricePerSQM,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        minNOI,
        maxNOI,
        minYield,
        maxYield,
        startDate,
        endDate,
        municipality,
        comparablePropertyType,
        polygon,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List municipalities with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListMunicipalityLeaseStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparableMunicipalityLeaseStatisticsReadView>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListMunicipalityLeaseStatistics(
        comparablePropertyId,
        organisationId,
        sensitive,
        minBaseRent,
        maxBaseRent,
        minTotalRent,
        maxTotalRent,
        minBaseRentPerSQM,
        maxBaseRentPerSQM,
        minTotalRentPerSQM,
        maxTotalRentPerSQM,
        minBaseRentIndexed,
        maxBaseRentIndexed,
        minTotalRentIndexed,
        maxTotalRentIndexed,
        minBaseRentPerSQMIndexed,
        maxBaseRentPerSQMIndexed,
        minTotalRentPerSQMIndexed,
        maxTotalRentPerSQMIndexed,
        minArea,
        maxArea,
        minLeaseCount,
        maxLeaseCount,
        date,
        municipality,
        comparablePropertyType,
        owned,
        polygon,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List municipalities with lease statistics
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListMunicipalityLeaseStatisticsToken(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparableMunicipalityLeaseStatisticsReadView>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListMunicipalityLeaseStatisticsToken(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List municipalities with transaction statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListMunicipalityTransactionStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ComparableMunicipalityTransactionStatisticsReadView>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListMunicipalityTransactionStatistics(
        comparablePropertyId,
        organisationId,
        sensitive,
        minPricePerSQM,
        maxPricePerSQM,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        minNOI,
        maxNOI,
        minYield,
        maxYield,
        startDate,
        endDate,
        municipality,
        comparablePropertyType,
        polygon,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List properties with transactions
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListMunicipalityTransactionStatisticsToken(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ComparableMunicipalityTransactionStatisticsReadView>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.comparableResourceListMunicipalityTransactionStatisticsToken(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List properties with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListPropertyLeaseStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparablePropertyLeaseStatisticsReadView>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListPropertyLeaseStatistics(
        comparablePropertyId,
        organisationId,
        sensitive,
        minBaseRent,
        maxBaseRent,
        minTotalRent,
        maxTotalRent,
        minBaseRentPerSQM,
        maxBaseRentPerSQM,
        minTotalRentPerSQM,
        maxTotalRentPerSQM,
        minBaseRentIndexed,
        maxBaseRentIndexed,
        minTotalRentIndexed,
        maxTotalRentIndexed,
        minBaseRentPerSQMIndexed,
        maxBaseRentPerSQMIndexed,
        minTotalRentPerSQMIndexed,
        maxTotalRentPerSQMIndexed,
        minArea,
        maxArea,
        minLeaseCount,
        maxLeaseCount,
        date,
        municipality,
        comparablePropertyType,
        owned,
        polygon,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List properties with lease statistics
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListPropertyLeaseStatisticsToken(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparablePropertyLeaseStatisticsReadView>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListPropertyLeaseStatisticsToken(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListPropertyTransactions(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparablePropertyTransactionReadView>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListPropertyTransactions(
        comparablePropertyId,
        organisationId,
        sensitive,
        minPricePerSQM,
        maxPricePerSQM,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        minNOI,
        maxNOI,
        minYield,
        maxYield,
        startDate,
        endDate,
        municipality,
        comparablePropertyType,
        polygon,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List properties with transactions
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListPropertyTransactionsToken(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparablePropertyTransactionReadView>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListPropertyTransactionsToken(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List lease statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListPropertyTypeLeaseStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablePolygonLeaseStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListPropertyTypeLeaseStatistics(
        comparablePropertyId,
        organisationId,
        sensitive,
        minBaseRent,
        maxBaseRent,
        minTotalRent,
        maxTotalRent,
        minBaseRentPerSQM,
        maxBaseRentPerSQM,
        minTotalRentPerSQM,
        maxTotalRentPerSQM,
        minBaseRentIndexed,
        maxBaseRentIndexed,
        minTotalRentIndexed,
        maxTotalRentIndexed,
        minBaseRentPerSQMIndexed,
        maxBaseRentPerSQMIndexed,
        minTotalRentPerSQMIndexed,
        maxTotalRentPerSQMIndexed,
        minArea,
        maxArea,
        minLeaseCount,
        maxLeaseCount,
        date,
        municipality,
        comparablePropertyType,
        owned,
        polygon,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        comparablePropertyTypes,
        country,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List lease statistics by property type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListPropertyTypeLeaseStatisticsToken(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablePolygonLeaseStatisticsReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListPropertyTypeLeaseStatisticsToken(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List transaction statistics by property type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableResourceListTransactionStatisticsToken(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablePolygonTransactionStatisticsReadView>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableResourceListTransactionStatisticsToken(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a comparable transaction
     * @param {ComparableTransactionCreateView} [comparableTransactionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceCreate(
      comparableTransactionCreateView?: ComparableTransactionCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparableTransactionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableTransactionResourceCreate(
        comparableTransactionCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a comparable transaction
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableTransactionResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download Excel template for importing comparable transactions to the organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceDownloadExcelTemplateForOrganisation(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.comparableTransactionResourceDownloadExcelTemplateForOrganisation(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download Excel template for importing comparable transactions to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceDownloadExcelTemplateForProperty(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.comparableTransactionResourceDownloadExcelTemplateForProperty(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Import comparable transactions from Excel file to the organisation
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceImportExcelToOrganisation(
      organisationId: number,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResultsView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableTransactionResourceImportExcelToOrganisation(
        organisationId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Import comparable transactions from Excel file to the property
     * @param {number} propertyId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceImportExcelToProperty(
      propertyId: number,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResultsView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableTransactionResourceImportExcelToProperty(
        propertyId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List comparable transactions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {boolean} [sensitive] Filter by sensitive
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      comparablePropertyId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparableTransactionListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableTransactionResourceList(
        limit,
        offset,
        organisationId,
        comparablePropertyId,
        sensitive,
        minPricePerSQM,
        maxPricePerSQM,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        minNOI,
        maxNOI,
        minYield,
        maxYield,
        startDate,
        endDate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a comparable transaction
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparableTransactionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableTransactionResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a comparable transaction
     * @param {number} id
     * @param {ComparableTransactionUpdateView} [comparableTransactionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comparableTransactionResourceUpdate(
      id: number,
      comparableTransactionUpdateView?: ComparableTransactionUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparableTransactionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comparableTransactionResourceUpdate(
        id,
        comparableTransactionUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ComparableApi - factory interface
 * @export
 */
export const ComparableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ComparableApiFp(configuration);
  return {
    /**
     *
     * @summary Create a comparable lease
     * @param {ComparableLeaseCreateView} [comparableLeaseCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceCreate(
      comparableLeaseCreateView?: ComparableLeaseCreateView,
      options?: any
    ): AxiosPromise<ComparableLeaseReadView> {
      return localVarFp
        .comparableLeaseResourceCreate(comparableLeaseCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a comparable lease
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.comparableLeaseResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Download Excel template for importing comparable leases to the organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceDownloadExcelTemplate(options?: any): AxiosPromise<void> {
      return localVarFp
        .comparableLeaseResourceDownloadExcelTemplate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Download Excel template for importing comparable leases to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceDownloadExcelTemplateForProperty(options?: any): AxiosPromise<void> {
      return localVarFp
        .comparableLeaseResourceDownloadExcelTemplateForProperty(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import comparable leases from Excel file to the organisation
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceImportExcelToOrganisation(
      organisationId: number,
      body?: object,
      options?: any
    ): AxiosPromise<ImportResultsView> {
      return localVarFp
        .comparableLeaseResourceImportExcelToOrganisation(organisationId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import comparable leases from Excel file to the property
     * @param {number} propertyId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceImportExcelToProperty(
      propertyId: number,
      body?: object,
      options?: any
    ): AxiosPromise<ImportResultsView> {
      return localVarFp
        .comparableLeaseResourceImportExcelToProperty(propertyId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List comparable leases
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {boolean} [sensitive] Filter by sensitive
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {string} [date] Filter by leases active at the given date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      comparablePropertyId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minArea?: number,
      maxArea?: number,
      date?: string,
      options?: any
    ): AxiosPromise<ComparableLeaseListView> {
      return localVarFp
        .comparableLeaseResourceList(
          limit,
          offset,
          organisationId,
          comparablePropertyId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minArea,
          maxArea,
          date,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a comparable lease
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceRead(id: number, options?: any): AxiosPromise<ComparableLeaseReadView> {
      return localVarFp.comparableLeaseResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a comparable lease
     * @param {number} id
     * @param {ComparableLeaseUpdateView} [comparableLeaseUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableLeaseResourceUpdate(
      id: number,
      comparableLeaseUpdateView?: ComparableLeaseUpdateView,
      options?: any
    ): AxiosPromise<ComparableLeaseReadView> {
      return localVarFp
        .comparableLeaseResourceUpdate(id, comparableLeaseUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a comparable property
     * @param {ComparablePropertyCreateView} [comparablePropertyCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceCreate(
      comparablePropertyCreateView?: ComparablePropertyCreateView,
      options?: any
    ): AxiosPromise<ComparablePropertyReadView> {
      return localVarFp
        .comparablePropertyResourceCreate(comparablePropertyCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a comparable property
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.comparablePropertyResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List comparable properties
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by type
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {boolean} [validPosition] Filter by if the property has a null position or not
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      validPosition?: boolean,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<ComparablePropertyListView> {
      return localVarFp
        .comparablePropertyResourceList(
          limit,
          offset,
          organisationId,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          municipalities,
          comparablePropertyTypes,
          validPosition,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a comparable property
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceRead(id: number, options?: any): AxiosPromise<ComparablePropertyReadView> {
      return localVarFp.comparablePropertyResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a comparable property
     * @param {number} id
     * @param {ComparablePropertyUpdateView} [comparablePropertyUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparablePropertyResourceUpdate(
      id: number,
      comparablePropertyUpdateView?: ComparablePropertyUpdateView,
      options?: any
    ): AxiosPromise<ComparablePropertyReadView> {
      return localVarFp
        .comparablePropertyResourceUpdate(id, comparablePropertyUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export comparable leases to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceExportExcelLeases(token: string, options?: any): AxiosPromise<void> {
      return localVarFp.comparableResourceExportExcelLeases(token, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a token to export leases
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceExportExcelLeasesUrl(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .comparableResourceExportExcelLeasesUrl(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export comparable transactions to Excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceExportExcelTransactions(token: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .comparableResourceExportExcelTransactions(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a token to export transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceExportExcelTransactionsUrl(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .comparableResourceExportExcelTransactionsUrl(
          comparablePropertyId,
          organisationId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          municipality,
          comparablePropertyType,
          polygon,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create token for listing municipalities with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListMunicipalityLeaseStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .comparableResourceGetListMunicipalityLeaseStatisticsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create token for listing properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListMunicipalityTransactionStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .comparableResourceGetListMunicipalityTransactionStatisticsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          municipality,
          comparablePropertyType,
          polygon,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create token for listing properties with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListPropertyLeaseStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .comparableResourceGetListPropertyLeaseStatisticsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create token for listing properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListPropertyTransactionsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .comparableResourceGetListPropertyTransactionsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          municipality,
          comparablePropertyType,
          polygon,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create token for listing lease statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListPropertyTypeLeaseStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .comparableResourceGetListPropertyTypeLeaseStatisticsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create token for listing transaction statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetListTransactionStatisticsToken(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .comparableResourceGetListTransactionStatisticsToken(
          comparablePropertyId,
          organisationId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          municipality,
          comparablePropertyType,
          polygon,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List transaction statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceGetTransactionStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<ComparablePolygonTransactionStatisticsReadView> {
      return localVarFp
        .comparableResourceGetTransactionStatistics(
          comparablePropertyId,
          organisationId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          municipality,
          comparablePropertyType,
          polygon,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List municipalities with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListMunicipalityLeaseStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<Array<ComparableMunicipalityLeaseStatisticsReadView>> {
      return localVarFp
        .comparableResourceListMunicipalityLeaseStatistics(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List municipalities with lease statistics
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListMunicipalityLeaseStatisticsToken(
      token: string,
      options?: any
    ): AxiosPromise<Array<ComparableMunicipalityLeaseStatisticsReadView>> {
      return localVarFp
        .comparableResourceListMunicipalityLeaseStatisticsToken(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List municipalities with transaction statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListMunicipalityTransactionStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<Array<ComparableMunicipalityTransactionStatisticsReadView>> {
      return localVarFp
        .comparableResourceListMunicipalityTransactionStatistics(
          comparablePropertyId,
          organisationId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          municipality,
          comparablePropertyType,
          polygon,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List properties with transactions
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListMunicipalityTransactionStatisticsToken(
      token: string,
      options?: any
    ): AxiosPromise<Array<ComparableMunicipalityTransactionStatisticsReadView>> {
      return localVarFp
        .comparableResourceListMunicipalityTransactionStatisticsToken(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List properties with lease statistics
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyLeaseStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<Array<ComparablePropertyLeaseStatisticsReadView>> {
      return localVarFp
        .comparableResourceListPropertyLeaseStatistics(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List properties with lease statistics
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyLeaseStatisticsToken(
      token: string,
      options?: any
    ): AxiosPromise<Array<ComparablePropertyLeaseStatisticsReadView>> {
      return localVarFp
        .comparableResourceListPropertyLeaseStatisticsToken(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List properties with transactions
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyTransactions(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      polygon?: Array<PointView>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<Array<ComparablePropertyTransactionReadView>> {
      return localVarFp
        .comparableResourceListPropertyTransactions(
          comparablePropertyId,
          organisationId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          municipality,
          comparablePropertyType,
          polygon,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List properties with transactions
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyTransactionsToken(
      token: string,
      options?: any
    ): AxiosPromise<Array<ComparablePropertyTransactionReadView>> {
      return localVarFp
        .comparableResourceListPropertyTransactionsToken(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List lease statistics by property type
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {number} [organisationId] Filter by organisation
     * @param {boolean} [sensitive] Filter by sensitivity
     * @param {number} [minBaseRent] Filter by minimum Base Rent
     * @param {number} [maxBaseRent] Filter by maximum Base Rent
     * @param {number} [minTotalRent] Filter by minimum Total Rent
     * @param {number} [maxTotalRent] Filter by maximum Total Rent
     * @param {number} [minBaseRentPerSQM] Filter by minimum Base Rent/SQM
     * @param {number} [maxBaseRentPerSQM] Filter by maximum Base Rent/SQM
     * @param {number} [minTotalRentPerSQM] Filter by minimum Total Rent/SQM
     * @param {number} [maxTotalRentPerSQM] Filter by maximum Total Rent/SQM
     * @param {number} [minBaseRentIndexed] Filter by minimum Base Rent (indexed)
     * @param {number} [maxBaseRentIndexed] Filter by maximum Base Rent (indexed)
     * @param {number} [minTotalRentIndexed] Filter by minimum Total Rent (indexed)
     * @param {number} [maxTotalRentIndexed] Filter by maximum Total Rent (indexed)
     * @param {number} [minBaseRentPerSQMIndexed] Filter by minimum Base Rent/SQM (indexed)
     * @param {number} [maxBaseRentPerSQMIndexed] Filter by maximum Base Rent/SQM (indexed)
     * @param {number} [minTotalRentPerSQMIndexed] Filter by minimum Total Rent/SQM (indexed)
     * @param {number} [maxTotalRentPerSQMIndexed] Filter by maximum Total Rent/SQM (indexed)
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minLeaseCount] Filter minimum lease count (Total leases, passing only date filter)
     * @param {number} [maxLeaseCount] Filter by maximum lease count (Total leases, passing only date filter)
     * @param {string} [date] Filter by leases active at the given date
     * @param {string} [municipality] Filter by municipality. Requires the country filter.
     * @param {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'} [comparablePropertyType] Filter by comparablePropertyType
     * @param {boolean} [owned] Filter by if the property is owned or not
     * @param {Array<PointView>} [polygon] Filter by properties inside polygon.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by a list of municipalities. Requires the country filter.
     * @param {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>} [comparablePropertyTypes] Filter by a list of property types
     * @param {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'} [country] Filter by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyTypeLeaseStatistics(
      comparablePropertyId?: number,
      organisationId?: number,
      sensitive?: boolean,
      minBaseRent?: number,
      maxBaseRent?: number,
      minTotalRent?: number,
      maxTotalRent?: number,
      minBaseRentPerSQM?: number,
      maxBaseRentPerSQM?: number,
      minTotalRentPerSQM?: number,
      maxTotalRentPerSQM?: number,
      minBaseRentIndexed?: number,
      maxBaseRentIndexed?: number,
      minTotalRentIndexed?: number,
      maxTotalRentIndexed?: number,
      minBaseRentPerSQMIndexed?: number,
      maxBaseRentPerSQMIndexed?: number,
      minTotalRentPerSQMIndexed?: number,
      maxTotalRentPerSQMIndexed?: number,
      minArea?: number,
      maxArea?: number,
      minLeaseCount?: number,
      maxLeaseCount?: number,
      date?: string,
      municipality?: string,
      comparablePropertyType?:
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC',
      owned?: boolean,
      polygon?: Array<PointView>,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      comparablePropertyTypes?: Array<
        | 'RETAIL'
        | 'OTHER'
        | 'OFFICE'
        | 'HOTEL'
        | 'RESIDENTIAL'
        | 'LOGISTICS'
        | 'INDUSTRIAL'
        | 'PARKING'
        | 'BUILDING_RIGHTS'
        | 'WAREHOUSE'
        | 'PUBLIC'
      >,
      country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM',
      options?: any
    ): AxiosPromise<ComparablePolygonLeaseStatisticsReadView> {
      return localVarFp
        .comparableResourceListPropertyTypeLeaseStatistics(
          comparablePropertyId,
          organisationId,
          sensitive,
          minBaseRent,
          maxBaseRent,
          minTotalRent,
          maxTotalRent,
          minBaseRentPerSQM,
          maxBaseRentPerSQM,
          minTotalRentPerSQM,
          maxTotalRentPerSQM,
          minBaseRentIndexed,
          maxBaseRentIndexed,
          minTotalRentIndexed,
          maxTotalRentIndexed,
          minBaseRentPerSQMIndexed,
          maxBaseRentPerSQMIndexed,
          minTotalRentPerSQMIndexed,
          maxTotalRentPerSQMIndexed,
          minArea,
          maxArea,
          minLeaseCount,
          maxLeaseCount,
          date,
          municipality,
          comparablePropertyType,
          owned,
          polygon,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          comparablePropertyTypes,
          country,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List lease statistics by property type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListPropertyTypeLeaseStatisticsToken(
      token: string,
      options?: any
    ): AxiosPromise<ComparablePolygonLeaseStatisticsReadView> {
      return localVarFp
        .comparableResourceListPropertyTypeLeaseStatisticsToken(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List transaction statistics by property type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableResourceListTransactionStatisticsToken(
      token: string,
      options?: any
    ): AxiosPromise<ComparablePolygonTransactionStatisticsReadView> {
      return localVarFp
        .comparableResourceListTransactionStatisticsToken(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a comparable transaction
     * @param {ComparableTransactionCreateView} [comparableTransactionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceCreate(
      comparableTransactionCreateView?: ComparableTransactionCreateView,
      options?: any
    ): AxiosPromise<ComparableTransactionReadView> {
      return localVarFp
        .comparableTransactionResourceCreate(comparableTransactionCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a comparable transaction
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.comparableTransactionResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Download Excel template for importing comparable transactions to the organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceDownloadExcelTemplateForOrganisation(options?: any): AxiosPromise<void> {
      return localVarFp
        .comparableTransactionResourceDownloadExcelTemplateForOrganisation(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Download Excel template for importing comparable transactions to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceDownloadExcelTemplateForProperty(options?: any): AxiosPromise<void> {
      return localVarFp
        .comparableTransactionResourceDownloadExcelTemplateForProperty(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import comparable transactions from Excel file to the organisation
     * @param {number} organisationId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceImportExcelToOrganisation(
      organisationId: number,
      body?: object,
      options?: any
    ): AxiosPromise<ImportResultsView> {
      return localVarFp
        .comparableTransactionResourceImportExcelToOrganisation(organisationId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import comparable transactions from Excel file to the property
     * @param {number} propertyId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceImportExcelToProperty(
      propertyId: number,
      body?: object,
      options?: any
    ): AxiosPromise<ImportResultsView> {
      return localVarFp
        .comparableTransactionResourceImportExcelToProperty(propertyId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List comparable transactions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [comparablePropertyId] Filter by comparable property
     * @param {boolean} [sensitive] Filter by sensitive
     * @param {number} [minPricePerSQM] Filter by minimum price per square meter
     * @param {number} [maxPricePerSQM] Filter by minimum price per square meter
     * @param {number} [minPrice] Filter by minimum Price
     * @param {number} [maxPrice] Filter by maximum Price
     * @param {number} [minArea] Filter by minimum Area
     * @param {number} [maxArea] Filter by maximum Area
     * @param {number} [minNOI] Filter by minimum NOI
     * @param {number} [maxNOI] Filter by maximum NOI
     * @param {number} [minYield] Filter by minimum Yield
     * @param {number} [maxYield] Filter by maximum Yield
     * @param {string} [startDate] Filter by transactions on or after the given date
     * @param {string} [endDate] Filter by transactions on or before the given date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      comparablePropertyId?: number,
      sensitive?: boolean,
      minPricePerSQM?: number,
      maxPricePerSQM?: number,
      minPrice?: number,
      maxPrice?: number,
      minArea?: number,
      maxArea?: number,
      minNOI?: number,
      maxNOI?: number,
      minYield?: number,
      maxYield?: number,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<ComparableTransactionListView> {
      return localVarFp
        .comparableTransactionResourceList(
          limit,
          offset,
          organisationId,
          comparablePropertyId,
          sensitive,
          minPricePerSQM,
          maxPricePerSQM,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minNOI,
          maxNOI,
          minYield,
          maxYield,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a comparable transaction
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceRead(id: number, options?: any): AxiosPromise<ComparableTransactionReadView> {
      return localVarFp.comparableTransactionResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a comparable transaction
     * @param {number} id
     * @param {ComparableTransactionUpdateView} [comparableTransactionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comparableTransactionResourceUpdate(
      id: number,
      comparableTransactionUpdateView?: ComparableTransactionUpdateView,
      options?: any
    ): AxiosPromise<ComparableTransactionReadView> {
      return localVarFp
        .comparableTransactionResourceUpdate(id, comparableTransactionUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for comparableLeaseResourceCreate operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableLeaseResourceCreateRequest
 */
export interface ComparableApiComparableLeaseResourceCreateRequest {
  /**
   *
   * @type {ComparableLeaseCreateView}
   * @memberof ComparableApiComparableLeaseResourceCreate
   */
  readonly comparableLeaseCreateView?: ComparableLeaseCreateView;
}

/**
 * Request parameters for comparableLeaseResourceDelete operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableLeaseResourceDeleteRequest
 */
export interface ComparableApiComparableLeaseResourceDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for comparableLeaseResourceImportExcelToOrganisation operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableLeaseResourceImportExcelToOrganisationRequest
 */
export interface ComparableApiComparableLeaseResourceImportExcelToOrganisationRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceImportExcelToOrganisation
   */
  readonly organisationId: number;

  /**
   *
   * @type {object}
   * @memberof ComparableApiComparableLeaseResourceImportExcelToOrganisation
   */
  readonly body?: object;
}

/**
 * Request parameters for comparableLeaseResourceImportExcelToProperty operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableLeaseResourceImportExcelToPropertyRequest
 */
export interface ComparableApiComparableLeaseResourceImportExcelToPropertyRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceImportExcelToProperty
   */
  readonly propertyId: number;

  /**
   *
   * @type {object}
   * @memberof ComparableApiComparableLeaseResourceImportExcelToProperty
   */
  readonly body?: object;
}

/**
 * Request parameters for comparableLeaseResourceList operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableLeaseResourceListRequest
 */
export interface ComparableApiComparableLeaseResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by sensitive
   * @type {boolean}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly minBaseRent?: number;

  /**
   * Filter by maximum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly maxBaseRent?: number;

  /**
   * Filter by minimum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly minTotalRent?: number;

  /**
   * Filter by maximum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly maxTotalRent?: number;

  /**
   * Filter by minimum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly minBaseRentPerSQM?: number;

  /**
   * Filter by maximum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly maxBaseRentPerSQM?: number;

  /**
   * Filter by minimum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly minTotalRentPerSQM?: number;

  /**
   * Filter by maximum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly maxTotalRentPerSQM?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly maxArea?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparableLeaseResourceList
   */
  readonly date?: string;
}

/**
 * Request parameters for comparableLeaseResourceRead operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableLeaseResourceReadRequest
 */
export interface ComparableApiComparableLeaseResourceReadRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for comparableLeaseResourceUpdate operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableLeaseResourceUpdateRequest
 */
export interface ComparableApiComparableLeaseResourceUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableLeaseResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {ComparableLeaseUpdateView}
   * @memberof ComparableApiComparableLeaseResourceUpdate
   */
  readonly comparableLeaseUpdateView?: ComparableLeaseUpdateView;
}

/**
 * Request parameters for comparablePropertyResourceCreate operation in ComparableApi.
 * @export
 * @interface ComparableApiComparablePropertyResourceCreateRequest
 */
export interface ComparableApiComparablePropertyResourceCreateRequest {
  /**
   *
   * @type {ComparablePropertyCreateView}
   * @memberof ComparableApiComparablePropertyResourceCreate
   */
  readonly comparablePropertyCreateView?: ComparablePropertyCreateView;
}

/**
 * Request parameters for comparablePropertyResourceDelete operation in ComparableApi.
 * @export
 * @interface ComparableApiComparablePropertyResourceDeleteRequest
 */
export interface ComparableApiComparablePropertyResourceDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparablePropertyResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for comparablePropertyResourceList operation in ComparableApi.
 * @export
 * @interface ComparableApiComparablePropertyResourceListRequest
 */
export interface ComparableApiComparablePropertyResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter minimum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly minLeaseCount?: number;

  /**
   * Filter by maximum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly maxLeaseCount?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly date?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly municipality?: string;

  /**
   * Filter by type
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by if the property is owned or not
   * @type {boolean}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly owned?: boolean;

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by if the property has a null position or not
   * @type {boolean}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly validPosition?: boolean;

  /**
   * Filter by country.
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparablePropertyResourceList
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparablePropertyResourceRead operation in ComparableApi.
 * @export
 * @interface ComparableApiComparablePropertyResourceReadRequest
 */
export interface ComparableApiComparablePropertyResourceReadRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparablePropertyResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for comparablePropertyResourceUpdate operation in ComparableApi.
 * @export
 * @interface ComparableApiComparablePropertyResourceUpdateRequest
 */
export interface ComparableApiComparablePropertyResourceUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparablePropertyResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {ComparablePropertyUpdateView}
   * @memberof ComparableApiComparablePropertyResourceUpdate
   */
  readonly comparablePropertyUpdateView?: ComparablePropertyUpdateView;
}

/**
 * Request parameters for comparableResourceExportExcelLeases operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceExportExcelLeasesRequest
 */
export interface ComparableApiComparableResourceExportExcelLeasesRequest {
  /**
   *
   * @type {string}
   * @memberof ComparableApiComparableResourceExportExcelLeases
   */
  readonly token: string;
}

/**
 * Request parameters for comparableResourceExportExcelLeasesUrl operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceExportExcelLeasesUrlRequest
 */
export interface ComparableApiComparableResourceExportExcelLeasesUrlRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minBaseRent?: number;

  /**
   * Filter by maximum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxBaseRent?: number;

  /**
   * Filter by minimum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minTotalRent?: number;

  /**
   * Filter by maximum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxTotalRent?: number;

  /**
   * Filter by minimum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minBaseRentPerSQM?: number;

  /**
   * Filter by maximum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxBaseRentPerSQM?: number;

  /**
   * Filter by minimum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minTotalRentPerSQM?: number;

  /**
   * Filter by maximum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxTotalRentPerSQM?: number;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxArea?: number;

  /**
   * Filter minimum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly minLeaseCount?: number;

  /**
   * Filter by maximum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly maxLeaseCount?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly date?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by if the property is owned or not
   * @type {boolean}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly owned?: boolean;

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceExportExcelLeasesUrl
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceExportExcelTransactions operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceExportExcelTransactionsRequest
 */
export interface ComparableApiComparableResourceExportExcelTransactionsRequest {
  /**
   *
   * @type {string}
   * @memberof ComparableApiComparableResourceExportExcelTransactions
   */
  readonly token: string;
}

/**
 * Request parameters for comparableResourceExportExcelTransactionsUrl operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceExportExcelTransactionsUrlRequest
 */
export interface ComparableApiComparableResourceExportExcelTransactionsUrlRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly minPricePerSQM?: number;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly maxPricePerSQM?: number;

  /**
   * Filter by minimum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly minPrice?: number;

  /**
   * Filter by maximum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly maxPrice?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly maxArea?: number;

  /**
   * Filter by minimum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly minNOI?: number;

  /**
   * Filter by maximum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly maxNOI?: number;

  /**
   * Filter by minimum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly minYield?: number;

  /**
   * Filter by maximum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly maxYield?: number;

  /**
   * Filter by transactions on or after the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly startDate?: string;

  /**
   * Filter by transactions on or before the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly endDate?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceExportExcelTransactionsUrl
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceGetListMunicipalityLeaseStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsTokenRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minBaseRent?: number;

  /**
   * Filter by maximum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxBaseRent?: number;

  /**
   * Filter by minimum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minTotalRent?: number;

  /**
   * Filter by maximum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxTotalRent?: number;

  /**
   * Filter by minimum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minBaseRentPerSQM?: number;

  /**
   * Filter by maximum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxBaseRentPerSQM?: number;

  /**
   * Filter by minimum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minTotalRentPerSQM?: number;

  /**
   * Filter by maximum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxTotalRentPerSQM?: number;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxArea?: number;

  /**
   * Filter minimum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly minLeaseCount?: number;

  /**
   * Filter by maximum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly maxLeaseCount?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly date?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by if the property is owned or not
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly owned?: boolean;

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsToken
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceGetListMunicipalityTransactionStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsTokenRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly minPricePerSQM?: number;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly maxPricePerSQM?: number;

  /**
   * Filter by minimum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly minPrice?: number;

  /**
   * Filter by maximum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly maxPrice?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly maxArea?: number;

  /**
   * Filter by minimum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly minNOI?: number;

  /**
   * Filter by maximum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly maxNOI?: number;

  /**
   * Filter by minimum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly minYield?: number;

  /**
   * Filter by maximum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly maxYield?: number;

  /**
   * Filter by transactions on or after the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly startDate?: string;

  /**
   * Filter by transactions on or before the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly endDate?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsToken
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceGetListPropertyLeaseStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceGetListPropertyLeaseStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceGetListPropertyLeaseStatisticsTokenRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minBaseRent?: number;

  /**
   * Filter by maximum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxBaseRent?: number;

  /**
   * Filter by minimum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minTotalRent?: number;

  /**
   * Filter by maximum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxTotalRent?: number;

  /**
   * Filter by minimum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minBaseRentPerSQM?: number;

  /**
   * Filter by maximum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxBaseRentPerSQM?: number;

  /**
   * Filter by minimum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minTotalRentPerSQM?: number;

  /**
   * Filter by maximum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxTotalRentPerSQM?: number;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxArea?: number;

  /**
   * Filter minimum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly minLeaseCount?: number;

  /**
   * Filter by maximum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly maxLeaseCount?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly date?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by if the property is owned or not
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly owned?: boolean;

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceGetListPropertyLeaseStatisticsToken
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceGetListPropertyTransactionsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceGetListPropertyTransactionsTokenRequest
 */
export interface ComparableApiComparableResourceGetListPropertyTransactionsTokenRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly minPricePerSQM?: number;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly maxPricePerSQM?: number;

  /**
   * Filter by minimum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly minPrice?: number;

  /**
   * Filter by maximum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly maxPrice?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly maxArea?: number;

  /**
   * Filter by minimum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly minNOI?: number;

  /**
   * Filter by maximum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly maxNOI?: number;

  /**
   * Filter by minimum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly minYield?: number;

  /**
   * Filter by maximum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly maxYield?: number;

  /**
   * Filter by transactions on or after the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly startDate?: string;

  /**
   * Filter by transactions on or before the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly endDate?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceGetListPropertyTransactionsToken
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceGetListPropertyTypeLeaseStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsTokenRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minBaseRent?: number;

  /**
   * Filter by maximum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxBaseRent?: number;

  /**
   * Filter by minimum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minTotalRent?: number;

  /**
   * Filter by maximum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxTotalRent?: number;

  /**
   * Filter by minimum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minBaseRentPerSQM?: number;

  /**
   * Filter by maximum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxBaseRentPerSQM?: number;

  /**
   * Filter by minimum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minTotalRentPerSQM?: number;

  /**
   * Filter by maximum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxTotalRentPerSQM?: number;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxArea?: number;

  /**
   * Filter minimum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly minLeaseCount?: number;

  /**
   * Filter by maximum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly maxLeaseCount?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly date?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by if the property is owned or not
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly owned?: boolean;

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsToken
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceGetListTransactionStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceGetListTransactionStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceGetListTransactionStatisticsTokenRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly minPricePerSQM?: number;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly maxPricePerSQM?: number;

  /**
   * Filter by minimum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly minPrice?: number;

  /**
   * Filter by maximum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly maxPrice?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly maxArea?: number;

  /**
   * Filter by minimum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly minNOI?: number;

  /**
   * Filter by maximum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly maxNOI?: number;

  /**
   * Filter by minimum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly minYield?: number;

  /**
   * Filter by maximum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly maxYield?: number;

  /**
   * Filter by transactions on or after the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly startDate?: string;

  /**
   * Filter by transactions on or before the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly endDate?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceGetListTransactionStatisticsToken
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceGetTransactionStatistics operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceGetTransactionStatisticsRequest
 */
export interface ComparableApiComparableResourceGetTransactionStatisticsRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly minPricePerSQM?: number;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly maxPricePerSQM?: number;

  /**
   * Filter by minimum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly minPrice?: number;

  /**
   * Filter by maximum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly maxPrice?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly maxArea?: number;

  /**
   * Filter by minimum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly minNOI?: number;

  /**
   * Filter by maximum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly maxNOI?: number;

  /**
   * Filter by minimum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly minYield?: number;

  /**
   * Filter by maximum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly maxYield?: number;

  /**
   * Filter by transactions on or after the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly startDate?: string;

  /**
   * Filter by transactions on or before the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly endDate?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceGetTransactionStatistics
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceListMunicipalityLeaseStatistics operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListMunicipalityLeaseStatisticsRequest
 */
export interface ComparableApiComparableResourceListMunicipalityLeaseStatisticsRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minBaseRent?: number;

  /**
   * Filter by maximum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxBaseRent?: number;

  /**
   * Filter by minimum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minTotalRent?: number;

  /**
   * Filter by maximum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxTotalRent?: number;

  /**
   * Filter by minimum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minBaseRentPerSQM?: number;

  /**
   * Filter by maximum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxBaseRentPerSQM?: number;

  /**
   * Filter by minimum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minTotalRentPerSQM?: number;

  /**
   * Filter by maximum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxTotalRentPerSQM?: number;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxArea?: number;

  /**
   * Filter minimum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly minLeaseCount?: number;

  /**
   * Filter by maximum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly maxLeaseCount?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly date?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by if the property is owned or not
   * @type {boolean}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly owned?: boolean;

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatistics
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceListMunicipalityLeaseStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListMunicipalityLeaseStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceListMunicipalityLeaseStatisticsTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ComparableApiComparableResourceListMunicipalityLeaseStatisticsToken
   */
  readonly token: string;
}

/**
 * Request parameters for comparableResourceListMunicipalityTransactionStatistics operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListMunicipalityTransactionStatisticsRequest
 */
export interface ComparableApiComparableResourceListMunicipalityTransactionStatisticsRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly minPricePerSQM?: number;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly maxPricePerSQM?: number;

  /**
   * Filter by minimum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly minPrice?: number;

  /**
   * Filter by maximum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly maxPrice?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly maxArea?: number;

  /**
   * Filter by minimum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly minNOI?: number;

  /**
   * Filter by maximum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly maxNOI?: number;

  /**
   * Filter by minimum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly minYield?: number;

  /**
   * Filter by maximum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly maxYield?: number;

  /**
   * Filter by transactions on or after the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly startDate?: string;

  /**
   * Filter by transactions on or before the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly endDate?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatistics
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceListMunicipalityTransactionStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListMunicipalityTransactionStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceListMunicipalityTransactionStatisticsTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ComparableApiComparableResourceListMunicipalityTransactionStatisticsToken
   */
  readonly token: string;
}

/**
 * Request parameters for comparableResourceListPropertyLeaseStatistics operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListPropertyLeaseStatisticsRequest
 */
export interface ComparableApiComparableResourceListPropertyLeaseStatisticsRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minBaseRent?: number;

  /**
   * Filter by maximum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxBaseRent?: number;

  /**
   * Filter by minimum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minTotalRent?: number;

  /**
   * Filter by maximum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxTotalRent?: number;

  /**
   * Filter by minimum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minBaseRentPerSQM?: number;

  /**
   * Filter by maximum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxBaseRentPerSQM?: number;

  /**
   * Filter by minimum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minTotalRentPerSQM?: number;

  /**
   * Filter by maximum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxTotalRentPerSQM?: number;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxArea?: number;

  /**
   * Filter minimum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly minLeaseCount?: number;

  /**
   * Filter by maximum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly maxLeaseCount?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly date?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by if the property is owned or not
   * @type {boolean}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly owned?: boolean;

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatistics
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceListPropertyLeaseStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListPropertyLeaseStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceListPropertyLeaseStatisticsTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyLeaseStatisticsToken
   */
  readonly token: string;
}

/**
 * Request parameters for comparableResourceListPropertyTransactions operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListPropertyTransactionsRequest
 */
export interface ComparableApiComparableResourceListPropertyTransactionsRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly minPricePerSQM?: number;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly maxPricePerSQM?: number;

  /**
   * Filter by minimum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly minPrice?: number;

  /**
   * Filter by maximum Price
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly maxPrice?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly maxArea?: number;

  /**
   * Filter by minimum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly minNOI?: number;

  /**
   * Filter by maximum NOI
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly maxNOI?: number;

  /**
   * Filter by minimum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly minYield?: number;

  /**
   * Filter by maximum Yield
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly maxYield?: number;

  /**
   * Filter by transactions on or after the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly startDate?: string;

  /**
   * Filter by transactions on or before the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly endDate?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceListPropertyTransactions
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceListPropertyTransactionsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListPropertyTransactionsTokenRequest
 */
export interface ComparableApiComparableResourceListPropertyTransactionsTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyTransactionsToken
   */
  readonly token: string;
}

/**
 * Request parameters for comparableResourceListPropertyTypeLeaseStatistics operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListPropertyTypeLeaseStatisticsRequest
 */
export interface ComparableApiComparableResourceListPropertyTypeLeaseStatisticsRequest {
  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly organisationId?: number;

  /**
   * Filter by sensitivity
   * @type {boolean}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minBaseRent?: number;

  /**
   * Filter by maximum Base Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxBaseRent?: number;

  /**
   * Filter by minimum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minTotalRent?: number;

  /**
   * Filter by maximum Total Rent
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxTotalRent?: number;

  /**
   * Filter by minimum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minBaseRentPerSQM?: number;

  /**
   * Filter by maximum Base Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxBaseRentPerSQM?: number;

  /**
   * Filter by minimum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minTotalRentPerSQM?: number;

  /**
   * Filter by maximum Total Rent/SQM
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxTotalRentPerSQM?: number;

  /**
   * Filter by minimum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minBaseRentIndexed?: number;

  /**
   * Filter by maximum Base Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxBaseRentIndexed?: number;

  /**
   * Filter by minimum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minTotalRentIndexed?: number;

  /**
   * Filter by maximum Total Rent (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxTotalRentIndexed?: number;

  /**
   * Filter by minimum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minBaseRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Base Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxBaseRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minTotalRentPerSQMIndexed?: number;

  /**
   * Filter by maximum Total Rent/SQM (indexed)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxTotalRentPerSQMIndexed?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxArea?: number;

  /**
   * Filter minimum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly minLeaseCount?: number;

  /**
   * Filter by maximum lease count (Total leases, passing only date filter)
   * @type {number}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly maxLeaseCount?: number;

  /**
   * Filter by leases active at the given date
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly date?: string;

  /**
   * Filter by municipality. Requires the country filter.
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly municipality?: string;

  /**
   * Filter by comparablePropertyType
   * @type {'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly comparablePropertyType?:
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC';

  /**
   * Filter by if the property is owned or not
   * @type {boolean}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly owned?: boolean;

  /**
   * Filter by properties inside polygon.
   * @type {Array<PointView>}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly polygon?: Array<PointView>;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by a list of municipalities. Requires the country filter.
   * @type {Array<string>}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by a list of property types
   * @type {Array<'RETAIL' | 'OTHER' | 'OFFICE' | 'HOTEL' | 'RESIDENTIAL' | 'LOGISTICS' | 'INDUSTRIAL' | 'PARKING' | 'BUILDING_RIGHTS' | 'WAREHOUSE' | 'PUBLIC'>}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly comparablePropertyTypes?: Array<
    | 'RETAIL'
    | 'OTHER'
    | 'OFFICE'
    | 'HOTEL'
    | 'RESIDENTIAL'
    | 'LOGISTICS'
    | 'INDUSTRIAL'
    | 'PARKING'
    | 'BUILDING_RIGHTS'
    | 'WAREHOUSE'
    | 'PUBLIC'
  >;

  /**
   * Filter by country
   * @type {'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM'}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatistics
   */
  readonly country?: 'SWEDEN' | 'FINLAND' | 'DENMARK' | 'SPAIN' | 'UNITED_KINGDOM';
}

/**
 * Request parameters for comparableResourceListPropertyTypeLeaseStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListPropertyTypeLeaseStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceListPropertyTypeLeaseStatisticsTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ComparableApiComparableResourceListPropertyTypeLeaseStatisticsToken
   */
  readonly token: string;
}

/**
 * Request parameters for comparableResourceListTransactionStatisticsToken operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableResourceListTransactionStatisticsTokenRequest
 */
export interface ComparableApiComparableResourceListTransactionStatisticsTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ComparableApiComparableResourceListTransactionStatisticsToken
   */
  readonly token: string;
}

/**
 * Request parameters for comparableTransactionResourceCreate operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableTransactionResourceCreateRequest
 */
export interface ComparableApiComparableTransactionResourceCreateRequest {
  /**
   *
   * @type {ComparableTransactionCreateView}
   * @memberof ComparableApiComparableTransactionResourceCreate
   */
  readonly comparableTransactionCreateView?: ComparableTransactionCreateView;
}

/**
 * Request parameters for comparableTransactionResourceDelete operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableTransactionResourceDeleteRequest
 */
export interface ComparableApiComparableTransactionResourceDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for comparableTransactionResourceImportExcelToOrganisation operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableTransactionResourceImportExcelToOrganisationRequest
 */
export interface ComparableApiComparableTransactionResourceImportExcelToOrganisationRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceImportExcelToOrganisation
   */
  readonly organisationId: number;

  /**
   *
   * @type {object}
   * @memberof ComparableApiComparableTransactionResourceImportExcelToOrganisation
   */
  readonly body?: object;
}

/**
 * Request parameters for comparableTransactionResourceImportExcelToProperty operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableTransactionResourceImportExcelToPropertyRequest
 */
export interface ComparableApiComparableTransactionResourceImportExcelToPropertyRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceImportExcelToProperty
   */
  readonly propertyId: number;

  /**
   *
   * @type {object}
   * @memberof ComparableApiComparableTransactionResourceImportExcelToProperty
   */
  readonly body?: object;
}

/**
 * Request parameters for comparableTransactionResourceList operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableTransactionResourceListRequest
 */
export interface ComparableApiComparableTransactionResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by comparable property
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly comparablePropertyId?: number;

  /**
   * Filter by sensitive
   * @type {boolean}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly sensitive?: boolean;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly minPricePerSQM?: number;

  /**
   * Filter by minimum price per square meter
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly maxPricePerSQM?: number;

  /**
   * Filter by minimum Price
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly minPrice?: number;

  /**
   * Filter by maximum Price
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly maxPrice?: number;

  /**
   * Filter by minimum Area
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly minArea?: number;

  /**
   * Filter by maximum Area
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly maxArea?: number;

  /**
   * Filter by minimum NOI
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly minNOI?: number;

  /**
   * Filter by maximum NOI
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly maxNOI?: number;

  /**
   * Filter by minimum Yield
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly minYield?: number;

  /**
   * Filter by maximum Yield
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly maxYield?: number;

  /**
   * Filter by transactions on or after the given date
   * @type {string}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly startDate?: string;

  /**
   * Filter by transactions on or before the given date
   * @type {string}
   * @memberof ComparableApiComparableTransactionResourceList
   */
  readonly endDate?: string;
}

/**
 * Request parameters for comparableTransactionResourceRead operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableTransactionResourceReadRequest
 */
export interface ComparableApiComparableTransactionResourceReadRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for comparableTransactionResourceUpdate operation in ComparableApi.
 * @export
 * @interface ComparableApiComparableTransactionResourceUpdateRequest
 */
export interface ComparableApiComparableTransactionResourceUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof ComparableApiComparableTransactionResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {ComparableTransactionUpdateView}
   * @memberof ComparableApiComparableTransactionResourceUpdate
   */
  readonly comparableTransactionUpdateView?: ComparableTransactionUpdateView;
}

/**
 * ComparableApi - object-oriented interface
 * @export
 * @class ComparableApi
 * @extends {BaseAPI}
 */
export class ComparableApi extends BaseAPI {
  /**
   *
   * @summary Create a comparable lease
   * @param {ComparableApiComparableLeaseResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceCreate(
    requestParameters: ComparableApiComparableLeaseResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceCreate(requestParameters.comparableLeaseCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a comparable lease
   * @param {ComparableApiComparableLeaseResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceDelete(
    requestParameters: ComparableApiComparableLeaseResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download Excel template for importing comparable leases to the organisation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceDownloadExcelTemplate(options?: AxiosRequestConfig) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceDownloadExcelTemplate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download Excel template for importing comparable leases to the property
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceDownloadExcelTemplateForProperty(options?: AxiosRequestConfig) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceDownloadExcelTemplateForProperty(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import comparable leases from Excel file to the organisation
   * @param {ComparableApiComparableLeaseResourceImportExcelToOrganisationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceImportExcelToOrganisation(
    requestParameters: ComparableApiComparableLeaseResourceImportExcelToOrganisationRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceImportExcelToOrganisation(
        requestParameters.organisationId,
        requestParameters.body,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import comparable leases from Excel file to the property
   * @param {ComparableApiComparableLeaseResourceImportExcelToPropertyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceImportExcelToProperty(
    requestParameters: ComparableApiComparableLeaseResourceImportExcelToPropertyRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceImportExcelToProperty(requestParameters.propertyId, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List comparable leases
   * @param {ComparableApiComparableLeaseResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceList(
    requestParameters: ComparableApiComparableLeaseResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.comparablePropertyId,
        requestParameters.sensitive,
        requestParameters.minBaseRent,
        requestParameters.maxBaseRent,
        requestParameters.minTotalRent,
        requestParameters.maxTotalRent,
        requestParameters.minBaseRentPerSQM,
        requestParameters.maxBaseRentPerSQM,
        requestParameters.minTotalRentPerSQM,
        requestParameters.maxTotalRentPerSQM,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.date,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a comparable lease
   * @param {ComparableApiComparableLeaseResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceRead(
    requestParameters: ComparableApiComparableLeaseResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a comparable lease
   * @param {ComparableApiComparableLeaseResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableLeaseResourceUpdate(
    requestParameters: ComparableApiComparableLeaseResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableLeaseResourceUpdate(requestParameters.id, requestParameters.comparableLeaseUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a comparable property
   * @param {ComparableApiComparablePropertyResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparablePropertyResourceCreate(
    requestParameters: ComparableApiComparablePropertyResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparablePropertyResourceCreate(requestParameters.comparablePropertyCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a comparable property
   * @param {ComparableApiComparablePropertyResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparablePropertyResourceDelete(
    requestParameters: ComparableApiComparablePropertyResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparablePropertyResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List comparable properties
   * @param {ComparableApiComparablePropertyResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparablePropertyResourceList(
    requestParameters: ComparableApiComparablePropertyResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparablePropertyResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.minLeaseCount,
        requestParameters.maxLeaseCount,
        requestParameters.date,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.owned,
        requestParameters.polygon,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.validPosition,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a comparable property
   * @param {ComparableApiComparablePropertyResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparablePropertyResourceRead(
    requestParameters: ComparableApiComparablePropertyResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparablePropertyResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a comparable property
   * @param {ComparableApiComparablePropertyResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparablePropertyResourceUpdate(
    requestParameters: ComparableApiComparablePropertyResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparablePropertyResourceUpdate(requestParameters.id, requestParameters.comparablePropertyUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export comparable leases to Excel file
   * @param {ComparableApiComparableResourceExportExcelLeasesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceExportExcelLeases(
    requestParameters: ComparableApiComparableResourceExportExcelLeasesRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceExportExcelLeases(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a token to export leases
   * @param {ComparableApiComparableResourceExportExcelLeasesUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceExportExcelLeasesUrl(
    requestParameters: ComparableApiComparableResourceExportExcelLeasesUrlRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceExportExcelLeasesUrl(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minBaseRent,
        requestParameters.maxBaseRent,
        requestParameters.minTotalRent,
        requestParameters.maxTotalRent,
        requestParameters.minBaseRentPerSQM,
        requestParameters.maxBaseRentPerSQM,
        requestParameters.minTotalRentPerSQM,
        requestParameters.maxTotalRentPerSQM,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minLeaseCount,
        requestParameters.maxLeaseCount,
        requestParameters.date,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.owned,
        requestParameters.polygon,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export comparable transactions to Excel file
   * @param {ComparableApiComparableResourceExportExcelTransactionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceExportExcelTransactions(
    requestParameters: ComparableApiComparableResourceExportExcelTransactionsRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceExportExcelTransactions(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a token to export transactions
   * @param {ComparableApiComparableResourceExportExcelTransactionsUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceExportExcelTransactionsUrl(
    requestParameters: ComparableApiComparableResourceExportExcelTransactionsUrlRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceExportExcelTransactionsUrl(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minPricePerSQM,
        requestParameters.maxPricePerSQM,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minNOI,
        requestParameters.maxNOI,
        requestParameters.minYield,
        requestParameters.maxYield,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.polygon,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create token for listing municipalities with lease statistics
   * @param {ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceGetListMunicipalityLeaseStatisticsToken(
    requestParameters: ComparableApiComparableResourceGetListMunicipalityLeaseStatisticsTokenRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceGetListMunicipalityLeaseStatisticsToken(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minBaseRent,
        requestParameters.maxBaseRent,
        requestParameters.minTotalRent,
        requestParameters.maxTotalRent,
        requestParameters.minBaseRentPerSQM,
        requestParameters.maxBaseRentPerSQM,
        requestParameters.minTotalRentPerSQM,
        requestParameters.maxTotalRentPerSQM,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minLeaseCount,
        requestParameters.maxLeaseCount,
        requestParameters.date,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.owned,
        requestParameters.polygon,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create token for listing properties with transactions
   * @param {ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceGetListMunicipalityTransactionStatisticsToken(
    requestParameters: ComparableApiComparableResourceGetListMunicipalityTransactionStatisticsTokenRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceGetListMunicipalityTransactionStatisticsToken(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minPricePerSQM,
        requestParameters.maxPricePerSQM,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minNOI,
        requestParameters.maxNOI,
        requestParameters.minYield,
        requestParameters.maxYield,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.polygon,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create token for listing properties with lease statistics
   * @param {ComparableApiComparableResourceGetListPropertyLeaseStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceGetListPropertyLeaseStatisticsToken(
    requestParameters: ComparableApiComparableResourceGetListPropertyLeaseStatisticsTokenRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceGetListPropertyLeaseStatisticsToken(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minBaseRent,
        requestParameters.maxBaseRent,
        requestParameters.minTotalRent,
        requestParameters.maxTotalRent,
        requestParameters.minBaseRentPerSQM,
        requestParameters.maxBaseRentPerSQM,
        requestParameters.minTotalRentPerSQM,
        requestParameters.maxTotalRentPerSQM,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minLeaseCount,
        requestParameters.maxLeaseCount,
        requestParameters.date,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.owned,
        requestParameters.polygon,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create token for listing properties with transactions
   * @param {ComparableApiComparableResourceGetListPropertyTransactionsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceGetListPropertyTransactionsToken(
    requestParameters: ComparableApiComparableResourceGetListPropertyTransactionsTokenRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceGetListPropertyTransactionsToken(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minPricePerSQM,
        requestParameters.maxPricePerSQM,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minNOI,
        requestParameters.maxNOI,
        requestParameters.minYield,
        requestParameters.maxYield,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.polygon,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create token for listing lease statistics by property type
   * @param {ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceGetListPropertyTypeLeaseStatisticsToken(
    requestParameters: ComparableApiComparableResourceGetListPropertyTypeLeaseStatisticsTokenRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceGetListPropertyTypeLeaseStatisticsToken(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minBaseRent,
        requestParameters.maxBaseRent,
        requestParameters.minTotalRent,
        requestParameters.maxTotalRent,
        requestParameters.minBaseRentPerSQM,
        requestParameters.maxBaseRentPerSQM,
        requestParameters.minTotalRentPerSQM,
        requestParameters.maxTotalRentPerSQM,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minLeaseCount,
        requestParameters.maxLeaseCount,
        requestParameters.date,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.owned,
        requestParameters.polygon,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create token for listing transaction statistics by property type
   * @param {ComparableApiComparableResourceGetListTransactionStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceGetListTransactionStatisticsToken(
    requestParameters: ComparableApiComparableResourceGetListTransactionStatisticsTokenRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceGetListTransactionStatisticsToken(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minPricePerSQM,
        requestParameters.maxPricePerSQM,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minNOI,
        requestParameters.maxNOI,
        requestParameters.minYield,
        requestParameters.maxYield,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.polygon,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List transaction statistics by property type
   * @param {ComparableApiComparableResourceGetTransactionStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceGetTransactionStatistics(
    requestParameters: ComparableApiComparableResourceGetTransactionStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceGetTransactionStatistics(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minPricePerSQM,
        requestParameters.maxPricePerSQM,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minNOI,
        requestParameters.maxNOI,
        requestParameters.minYield,
        requestParameters.maxYield,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.polygon,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List municipalities with lease statistics
   * @param {ComparableApiComparableResourceListMunicipalityLeaseStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListMunicipalityLeaseStatistics(
    requestParameters: ComparableApiComparableResourceListMunicipalityLeaseStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListMunicipalityLeaseStatistics(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minBaseRent,
        requestParameters.maxBaseRent,
        requestParameters.minTotalRent,
        requestParameters.maxTotalRent,
        requestParameters.minBaseRentPerSQM,
        requestParameters.maxBaseRentPerSQM,
        requestParameters.minTotalRentPerSQM,
        requestParameters.maxTotalRentPerSQM,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minLeaseCount,
        requestParameters.maxLeaseCount,
        requestParameters.date,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.owned,
        requestParameters.polygon,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List municipalities with lease statistics
   * @param {ComparableApiComparableResourceListMunicipalityLeaseStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListMunicipalityLeaseStatisticsToken(
    requestParameters: ComparableApiComparableResourceListMunicipalityLeaseStatisticsTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListMunicipalityLeaseStatisticsToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List municipalities with transaction statistics
   * @param {ComparableApiComparableResourceListMunicipalityTransactionStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListMunicipalityTransactionStatistics(
    requestParameters: ComparableApiComparableResourceListMunicipalityTransactionStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListMunicipalityTransactionStatistics(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minPricePerSQM,
        requestParameters.maxPricePerSQM,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minNOI,
        requestParameters.maxNOI,
        requestParameters.minYield,
        requestParameters.maxYield,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.polygon,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List properties with transactions
   * @param {ComparableApiComparableResourceListMunicipalityTransactionStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListMunicipalityTransactionStatisticsToken(
    requestParameters: ComparableApiComparableResourceListMunicipalityTransactionStatisticsTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListMunicipalityTransactionStatisticsToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List properties with lease statistics
   * @param {ComparableApiComparableResourceListPropertyLeaseStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListPropertyLeaseStatistics(
    requestParameters: ComparableApiComparableResourceListPropertyLeaseStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListPropertyLeaseStatistics(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minBaseRent,
        requestParameters.maxBaseRent,
        requestParameters.minTotalRent,
        requestParameters.maxTotalRent,
        requestParameters.minBaseRentPerSQM,
        requestParameters.maxBaseRentPerSQM,
        requestParameters.minTotalRentPerSQM,
        requestParameters.maxTotalRentPerSQM,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minLeaseCount,
        requestParameters.maxLeaseCount,
        requestParameters.date,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.owned,
        requestParameters.polygon,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List properties with lease statistics
   * @param {ComparableApiComparableResourceListPropertyLeaseStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListPropertyLeaseStatisticsToken(
    requestParameters: ComparableApiComparableResourceListPropertyLeaseStatisticsTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListPropertyLeaseStatisticsToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List properties with transactions
   * @param {ComparableApiComparableResourceListPropertyTransactionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListPropertyTransactions(
    requestParameters: ComparableApiComparableResourceListPropertyTransactionsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListPropertyTransactions(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minPricePerSQM,
        requestParameters.maxPricePerSQM,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minNOI,
        requestParameters.maxNOI,
        requestParameters.minYield,
        requestParameters.maxYield,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.polygon,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List properties with transactions
   * @param {ComparableApiComparableResourceListPropertyTransactionsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListPropertyTransactionsToken(
    requestParameters: ComparableApiComparableResourceListPropertyTransactionsTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListPropertyTransactionsToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List lease statistics by property type
   * @param {ComparableApiComparableResourceListPropertyTypeLeaseStatisticsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListPropertyTypeLeaseStatistics(
    requestParameters: ComparableApiComparableResourceListPropertyTypeLeaseStatisticsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListPropertyTypeLeaseStatistics(
        requestParameters.comparablePropertyId,
        requestParameters.organisationId,
        requestParameters.sensitive,
        requestParameters.minBaseRent,
        requestParameters.maxBaseRent,
        requestParameters.minTotalRent,
        requestParameters.maxTotalRent,
        requestParameters.minBaseRentPerSQM,
        requestParameters.maxBaseRentPerSQM,
        requestParameters.minTotalRentPerSQM,
        requestParameters.maxTotalRentPerSQM,
        requestParameters.minBaseRentIndexed,
        requestParameters.maxBaseRentIndexed,
        requestParameters.minTotalRentIndexed,
        requestParameters.maxTotalRentIndexed,
        requestParameters.minBaseRentPerSQMIndexed,
        requestParameters.maxBaseRentPerSQMIndexed,
        requestParameters.minTotalRentPerSQMIndexed,
        requestParameters.maxTotalRentPerSQMIndexed,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minLeaseCount,
        requestParameters.maxLeaseCount,
        requestParameters.date,
        requestParameters.municipality,
        requestParameters.comparablePropertyType,
        requestParameters.owned,
        requestParameters.polygon,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.comparablePropertyTypes,
        requestParameters.country,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List lease statistics by property type
   * @param {ComparableApiComparableResourceListPropertyTypeLeaseStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListPropertyTypeLeaseStatisticsToken(
    requestParameters: ComparableApiComparableResourceListPropertyTypeLeaseStatisticsTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListPropertyTypeLeaseStatisticsToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List transaction statistics by property type
   * @param {ComparableApiComparableResourceListTransactionStatisticsTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableResourceListTransactionStatisticsToken(
    requestParameters: ComparableApiComparableResourceListTransactionStatisticsTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableResourceListTransactionStatisticsToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a comparable transaction
   * @param {ComparableApiComparableTransactionResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceCreate(
    requestParameters: ComparableApiComparableTransactionResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceCreate(requestParameters.comparableTransactionCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a comparable transaction
   * @param {ComparableApiComparableTransactionResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceDelete(
    requestParameters: ComparableApiComparableTransactionResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download Excel template for importing comparable transactions to the organisation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceDownloadExcelTemplateForOrganisation(options?: AxiosRequestConfig) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceDownloadExcelTemplateForOrganisation(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download Excel template for importing comparable transactions to the property
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceDownloadExcelTemplateForProperty(options?: AxiosRequestConfig) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceDownloadExcelTemplateForProperty(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import comparable transactions from Excel file to the organisation
   * @param {ComparableApiComparableTransactionResourceImportExcelToOrganisationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceImportExcelToOrganisation(
    requestParameters: ComparableApiComparableTransactionResourceImportExcelToOrganisationRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceImportExcelToOrganisation(
        requestParameters.organisationId,
        requestParameters.body,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import comparable transactions from Excel file to the property
   * @param {ComparableApiComparableTransactionResourceImportExcelToPropertyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceImportExcelToProperty(
    requestParameters: ComparableApiComparableTransactionResourceImportExcelToPropertyRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceImportExcelToProperty(requestParameters.propertyId, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List comparable transactions
   * @param {ComparableApiComparableTransactionResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceList(
    requestParameters: ComparableApiComparableTransactionResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.comparablePropertyId,
        requestParameters.sensitive,
        requestParameters.minPricePerSQM,
        requestParameters.maxPricePerSQM,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.minArea,
        requestParameters.maxArea,
        requestParameters.minNOI,
        requestParameters.maxNOI,
        requestParameters.minYield,
        requestParameters.maxYield,
        requestParameters.startDate,
        requestParameters.endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a comparable transaction
   * @param {ComparableApiComparableTransactionResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceRead(
    requestParameters: ComparableApiComparableTransactionResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a comparable transaction
   * @param {ComparableApiComparableTransactionResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComparableApi
   */
  public comparableTransactionResourceUpdate(
    requestParameters: ComparableApiComparableTransactionResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return ComparableApiFp(this.configuration)
      .comparableTransactionResourceUpdate(
        requestParameters.id,
        requestParameters.comparableTransactionUpdateView,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
