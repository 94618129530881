import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Header, Modal, SemanticCOLORS } from 'semantic-ui-react';
import { ContractApiContractResourceListRequest, ContractListItemViewStatusEnum } from '../../../GeneratedServices';
import { normalizeCurrencyFilter } from '../../../Services/ComparableService.types';
import { RangeView } from '../../../Services/types';
import { toDateString } from '../../../Utils/date';
import { FieldValidationResult, validateRange } from '../../../Utils/validation';
import RangeFilterInputs from '../../RangeFilterInputs/RangeFilterInputs';
import DatePicker from '../../DatePicker/DatePicker';
import LeaseStatusLabel from './LeaseStatusLabel';
import { convertToRange } from '../../../Utils/number';
import styles from './LeaseFiltersModal.module.scss';

interface Props {
  onClose: () => void;
  onConfirm: (filterParams: ContractApiContractResourceListRequest) => void;
  filterParams: ContractApiContractResourceListRequest | undefined;
}
const initialStatuses = [
  ContractListItemViewStatusEnum.ChangeOfTerms,
  ContractListItemViewStatusEnum.Extension,
  ContractListItemViewStatusEnum.ExtensionWithin90Days,
  ContractListItemViewStatusEnum.FutureVacantWithMoveOut,
  ContractListItemViewStatusEnum.Negotiation,
  ContractListItemViewStatusEnum.TenantChange,
  ContractListItemViewStatusEnum.VacantWithMoveOut,
  ContractListItemViewStatusEnum.VacantWithoutMoveOut,
  ContractListItemViewStatusEnum.OngoingWithoutEnd,
  ContractListItemViewStatusEnum.FutureMoveOutWithoutDate
];

export const initialLeaseParams: ContractApiContractResourceListRequest = {
  isOngoing: true,
  minStatusDate: toDateString(new Date()),
  statuses: initialStatuses
};

export const getActiveFiltersCount = (leaseFilterParams: ContractApiContractResourceListRequest) => {
  const {
    statuses,
    minBaseRentIndexed,
    maxBaseRentIndexed,
    minTotalRentIndexed,
    maxTotalRentIndexed,
    minBaseRentPerSQMIndexed,
    maxBaseRentPerSQMIndexed,
    minTotalRentPerSQMIndexed,
    maxTotalRentPerSQMIndexed,
    minArea,
    maxArea,
    minStatusDate,
    maxStatusDate,
    isOngoing
  } = leaseFilterParams;

  let count = statuses?.length ?? 0;
  // TenantChange and VacantWithMoveOut are coupled together
  if (statuses?.includes(ContractListItemViewStatusEnum.TenantChange)) {
    count--;
  }
  // Extension and ExtensionWithin90Days are coupled together
  if (statuses?.includes(ContractListItemViewStatusEnum.ExtensionWithin90Days)) {
    count--;
  }
  if (maxBaseRentIndexed || minBaseRentIndexed) {
    count++;
  }
  if (minTotalRentIndexed || maxTotalRentIndexed) {
    count++;
  }
  if (maxBaseRentPerSQMIndexed || minBaseRentPerSQMIndexed) {
    count++;
  }
  if (minTotalRentPerSQMIndexed || maxTotalRentPerSQMIndexed) {
    count++;
  }
  if (minArea || maxArea) {
    count++;
  }
  if (minStatusDate || maxStatusDate) {
    count++;
  }
  if (isOngoing) {
    count++;
  }
  return count;
};

const LeaseFiltersModal: React.FC<Props> = (props) => {
  const { filterParams, onClose, onConfirm } = props;
  const { t } = useTranslation(['common', 'contracts']);

  const [minBaseRentIndexed, setMinBaseRentIndexed] = React.useState<number | undefined>(
    filterParams?.minBaseRentIndexed ? filterParams.minBaseRentIndexed / 100 : undefined
  );
  const [maxBaseRentIndexed, setMaxBaseRentIndexed] = React.useState<number | undefined>(
    filterParams?.maxBaseRentIndexed ? filterParams.maxBaseRentIndexed / 100 : undefined
  );
  const [baseRentValidationResult, setBaseRentValidationResult] = React.useState<FieldValidationResult>();

  const [minTotalRentIndexed, setMinTotalRentIndexed] = React.useState<number | undefined>(
    filterParams?.minTotalRentIndexed ? filterParams.minTotalRentIndexed / 100 : undefined
  );
  const [maxTotalRentIndexed, setMaxTotalRentIndexed] = React.useState<number | undefined>(
    filterParams?.maxTotalRentIndexed ? filterParams.maxTotalRentIndexed / 100 : undefined
  );
  const [totalRentValidationResult, setTotalRentValidationResult] = React.useState<FieldValidationResult>();

  const [minBaseRentPerSQMIndexed, setMinBaseRentPerSQMIndexed] = React.useState<number | undefined>(
    filterParams?.minBaseRentPerSQMIndexed ? filterParams.minBaseRentPerSQMIndexed / 100 : undefined
  );
  const [maxBaseRentPerSQMIndexed, setMaxBaseRentPerSQMIndexed] = React.useState<number | undefined>(
    filterParams?.maxBaseRentPerSQMIndexed ? filterParams.maxBaseRentPerSQMIndexed / 100 : undefined
  );
  const [baseRentPerSQMValidationResult, setBaseRentPerSQMValidationResult] = React.useState<FieldValidationResult>();

  const [minTotalRentPerSQMIndexed, setMinTotalRentPerSQMIndexed] = React.useState<number | undefined>(
    filterParams?.minTotalRentPerSQMIndexed ? filterParams.minTotalRentPerSQMIndexed / 100 : undefined
  );
  const [maxTotalRentPerSQMIndexed, setMaxTotalRentPerSQMIndexed] = React.useState<number | undefined>(
    filterParams?.maxTotalRentPerSQMIndexed ? filterParams.maxTotalRentPerSQMIndexed / 100 : undefined
  );
  const [totalRentPerSQMValidationResult, setTotalRentPerSQMValidationResult] = React.useState<FieldValidationResult>();

  const [minArea, setMinArea] = React.useState<number | undefined>(filterParams?.minArea);
  const [maxArea, setMaxArea] = React.useState<number | undefined>(filterParams?.maxArea);
  const [areaValidationResult, setAreaValidationResult] = React.useState<FieldValidationResult>();

  const [minStatusDate, setMinStatusDate] = React.useState<string | undefined>(filterParams?.minStatusDate);
  const [maxStatusDate, setMaxStatusDate] = React.useState<string | undefined>(filterParams?.maxStatusDate);

  const [statuses, setStatuses] = React.useState<ContractListItemViewStatusEnum[]>(filterParams?.statuses ?? []);

  const [isOngoing, setOngoing] = React.useState<true | undefined>(filterParams?.isOngoing ? true : undefined);

  const clearData = () => {
    setMinBaseRentIndexed(undefined);
    setMaxBaseRentIndexed(undefined);
    setMinTotalRentIndexed(undefined);
    setMaxTotalRentIndexed(undefined);
    setMinBaseRentPerSQMIndexed(undefined);
    setMaxBaseRentPerSQMIndexed(undefined);
    setMinTotalRentPerSQMIndexed(undefined);
    setMaxTotalRentPerSQMIndexed(undefined);
    setMinArea(undefined);
    setMaxArea(undefined);
    setStatuses(initialStatuses);
    setMinStatusDate(initialLeaseParams.minStatusDate);
    setMaxStatusDate(undefined);
    setOngoing(initialLeaseParams.isOngoing ? true : undefined);
  };

  const validateRanges = (
    baseRent?: RangeView,
    baseRentPerSQM?: RangeView,
    totalRent?: RangeView,
    totalRentPerSQM?: RangeView,
    area?: RangeView
  ) => {
    const baseRentValidation = baseRent
      ? validateRange({ min: baseRent.min, max: baseRent.max }, setBaseRentValidationResult)
      : true;
    const baseRentPerSQMValidation = baseRentPerSQM
      ? validateRange({ min: baseRentPerSQM.min, max: baseRentPerSQM.max }, setBaseRentPerSQMValidationResult)
      : true;
    const totalRentValidation = totalRent
      ? validateRange({ min: totalRent.min, max: totalRent.max }, setTotalRentValidationResult)
      : true;
    const totalRentPerSQMValidation = totalRentPerSQM
      ? validateRange({ min: totalRentPerSQM.min, max: totalRentPerSQM.max }, setTotalRentPerSQMValidationResult)
      : true;
    const areaValidation = area ? validateRange({ min: area.min, max: area.max }, setAreaValidationResult) : true;

    return (
      baseRentValidation &&
      baseRentPerSQMValidation &&
      totalRentValidation &&
      areaValidation &&
      totalRentPerSQMValidation
    );
  };

  const handleConfirm = () => {
    if (
      !validateRanges(
        convertToRange(minBaseRentIndexed, maxBaseRentIndexed),
        convertToRange(minBaseRentPerSQMIndexed, maxBaseRentPerSQMIndexed),
        convertToRange(minTotalRentIndexed, maxTotalRentIndexed),
        convertToRange(minTotalRentPerSQMIndexed, maxTotalRentPerSQMIndexed),
        convertToRange(minArea, maxArea)
      )
    ) {
      return;
    }
    onConfirm({
      statuses,
      minStatusDate,
      maxStatusDate,
      minBaseRentIndexed: normalizeCurrencyFilter(minBaseRentIndexed),
      maxBaseRentIndexed: normalizeCurrencyFilter(maxBaseRentIndexed),
      minTotalRentIndexed: normalizeCurrencyFilter(minTotalRentIndexed),
      maxTotalRentIndexed: normalizeCurrencyFilter(maxTotalRentIndexed),
      minBaseRentPerSQMIndexed: normalizeCurrencyFilter(minBaseRentPerSQMIndexed),
      maxBaseRentPerSQMIndexed: normalizeCurrencyFilter(maxBaseRentPerSQMIndexed),
      minTotalRentPerSQMIndexed: normalizeCurrencyFilter(minTotalRentPerSQMIndexed),
      maxTotalRentPerSQMIndexed: normalizeCurrencyFilter(maxTotalRentPerSQMIndexed),
      minArea,
      maxArea,
      isOngoing
    });
    onClose();
  };

  return (
    <Modal open closeIcon onClose={onClose}>
      <Modal.Header>{t('contracts:filterLeaseTable')}</Modal.Header>
      <Modal.Content>
        <Form>
          <Header as="h4">{t('contracts:leaseDetailsFilters')}</Header>

          <Form.Group widths="equal">
            <RangeFilterInputs
              label={`${t('common:baseRent')} (SEK)`}
              minValue={minBaseRentIndexed}
              maxValue={maxBaseRentIndexed}
              setMinValue={setMinBaseRentIndexed}
              setMaxValue={setMaxBaseRentIndexed}
              validationResult={baseRentValidationResult}
              clearValidation={() => setBaseRentValidationResult(undefined)}
            />

            <RangeFilterInputs
              label={`${t('common:totalRent')} (SEK)`}
              minValue={minTotalRentIndexed}
              maxValue={maxTotalRentIndexed}
              setMinValue={setMinTotalRentIndexed}
              setMaxValue={setMaxTotalRentIndexed}
              validationResult={totalRentValidationResult}
              clearValidation={() => setTotalRentValidationResult(undefined)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <RangeFilterInputs
              label={t('common:baseRentSlashSqm')}
              minValue={minBaseRentPerSQMIndexed}
              maxValue={maxBaseRentPerSQMIndexed}
              setMinValue={setMinBaseRentPerSQMIndexed}
              setMaxValue={setMaxBaseRentPerSQMIndexed}
              validationResult={baseRentPerSQMValidationResult}
              clearValidation={() => setBaseRentPerSQMValidationResult(undefined)}
              additionalProps={{ decimalScale: 0 }}
            />

            <RangeFilterInputs
              label={t('common:totalRentSlashSqm')}
              minValue={minTotalRentPerSQMIndexed}
              maxValue={maxTotalRentPerSQMIndexed}
              setMinValue={setMinTotalRentPerSQMIndexed}
              setMaxValue={setMaxTotalRentPerSQMIndexed}
              validationResult={totalRentPerSQMValidationResult}
              clearValidation={() => setTotalRentPerSQMValidationResult(undefined)}
              additionalProps={{ decimalScale: 0 }}
            />
          </Form.Group>

          <RangeFilterInputs
            label={t('common:lettableAreaSqm')}
            minValue={minArea}
            maxValue={maxArea}
            setMinValue={setMinArea}
            setMaxValue={setMaxArea}
            validationResult={areaValidationResult}
            clearValidation={() => setAreaValidationResult(undefined)}
            additionalProps={{ decimalScale: 0 }}
          />

          <Header as="h4">{t('contracts:statusFilters')}</Header>

          <Form.Field width={16}>
            <label>{t('common:date')}</label>

            <Form.Group widths="4" className={styles.dateRangeWrapper}>
              <Form.Field>
                <DatePicker
                  popperPlacement="bottom"
                  selected={minStatusDate ? new Date(minStatusDate) : null}
                  dateFormat="P"
                  placeholderText={t('common:min')}
                  isClearable
                  maxDate={maxStatusDate ? new Date(maxStatusDate) : null}
                  onChange={(date) => setMinStatusDate(date ? toDateString(date) : undefined)}
                  customInput={<Form.Input icon="calendar alternate outline" iconPosition="left" />}
                />
              </Form.Field>
              <div>-</div>
              <Form.Field>
                <DatePicker
                  selected={maxStatusDate ? new Date(maxStatusDate) : null}
                  popperPlacement="bottom"
                  dateFormat="P"
                  placeholderText={t('common:max')}
                  isClearable
                  minDate={minStatusDate ? new Date(minStatusDate) : null}
                  onChange={(date) => setMaxStatusDate(date ? toDateString(date) : undefined)}
                  customInput={<Form.Input icon="calendar alternate outline" iconPosition="left" />}
                />
              </Form.Field>

              <Form.Field>
                <Checkbox
                  label={t('common:active')}
                  checked={isOngoing}
                  onChange={(e, d) => setOngoing(d.checked ? true : undefined)}
                />
              </Form.Field>
            </Form.Group>
          </Form.Field>

          <Form.Group widths={4}>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:willExtend')}
                requiredStatuses={[
                  ContractListItemViewStatusEnum.Extension,
                  ContractListItemViewStatusEnum.ExtensionWithin90Days
                ]}
                statuses={statuses}
                setStatuses={setStatuses}
                color={'green'}
              />
            </Form.Field>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:status.ongoingWithoutEnd')}
                color={'green'}
                requiredStatuses={[ContractListItemViewStatusEnum.OngoingWithoutEnd]}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Form.Field>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:willBeVacant')}
                color={'red'}
                requiredStatuses={[ContractListItemViewStatusEnum.FutureVacantWithMoveOut]}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={4}>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:status.tenantChange')}
                color={'green'}
                requiredStatuses={[
                  ContractListItemViewStatusEnum.TenantChange,
                  ContractListItemViewStatusEnum.VacantWithMoveOut
                ]}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Form.Field>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:renegotiationDeadline')}
                color={'orange'}
                requiredStatuses={[ContractListItemViewStatusEnum.Negotiation]}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Form.Field>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:status.vacantWithoutMoveOut')}
                color={'red'}
                requiredStatuses={[ContractListItemViewStatusEnum.VacantWithoutMoveOut]}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={4}>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:status.changeOfTerms')}
                color={'green'}
                requiredStatuses={[ContractListItemViewStatusEnum.ChangeOfTerms]}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Form.Field>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:status.movedOut')}
                color={'grey'}
                requiredStatuses={[ContractListItemViewStatusEnum.MovedOut]}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Form.Field>
            <Form.Field>
              <StatusCheckbox
                label={t('contracts:status.futureMoveOutWithoutDate')}
                color={'red'}
                requiredStatuses={[ContractListItemViewStatusEnum.FutureMoveOutWithoutDate]}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={clearData}>
          {t('common:clearFilters')}
        </Button>
        <Button primary onClick={handleConfirm}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const StatusCheckbox = (props: {
  label: string;
  requiredStatuses: ContractListItemViewStatusEnum[];
  statuses: ContractListItemViewStatusEnum[];
  setStatuses: React.Dispatch<React.SetStateAction<ContractListItemViewStatusEnum[]>>;
  color: SemanticCOLORS;
}) => {
  const checked = props.requiredStatuses.every((item) => props.statuses.includes(item));

  const handleChange = (checked: boolean) => {
    if (checked) {
      props.setStatuses((prevValue) => [...prevValue, ...props.requiredStatuses]);
    } else props.setStatuses((prevValue) => prevValue.filter((item) => !props.requiredStatuses.includes(item)));
  };

  return (
    <div className={styles.checkboxWrapper}>
      <Checkbox checked={checked} onChange={(e, d) => handleChange(d.checked ?? false)} />
      <LeaseStatusLabel color={props.color} content={props.label} />
    </div>
  );
};
export default LeaseFiltersModal;
