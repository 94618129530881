import { ListViewPagination } from './types';
import i18n from '../i18n';

export interface MeterListView extends ListViewPagination<MeterReadView> {}

export enum UtilityType {
  ELECTRICITY = 'ELECTRICITY',
  HEAT = 'HEAT',
  COOLING = 'COOLING',
  GAS = 'GAS',
  HOT_WATER = 'HOT_WATER',
  WATER = 'WATER',
  SENSOR = 'SENSOR'
}

export enum UtilityMetricsType {
  ENERGY_KWH = 'ENERGY_KWH',
  FLOW_M3 = 'FLOW_M3',
  ENERGY_HIGHTARIFF_KWH = 'ENERGY_HIGHTARIFF_KWH',
  TEMPERATURE_C = 'TEMPERATURE_C',
  ENERGY_LOWTARIFF_KWH = 'ENERGY_LOWTARIFF_KWH',
  RETURN_TEMPERATURE_C = 'RETURN_TEMPERATURE_C',
  SUPPLY_TEMPERATURE_C = 'SUPPLY_TEMPERATURE_C',
  TVOC_PPM = 'TVOC_PPM',
  CO2_PPM = 'CO2_PPM',
  RETAIL_SUBSCRIPTION_SEK = 'RETAIL_SUBSCRIPTION_SEK',
  RETAIL_USAGE_SEK = 'RETAIL_USAGE_SEK',
  RETAIL_UNDEFINED_SEK = 'RETAIL_UNDEFINED_SEK',
  RETAIL_TOTAL_SEK = 'RETAIL_TOTAL_SEK',
  GRID_SUBSCRIPTION_SEK = 'GRID_SUBSCRIPTION_SEK',
  GRID_SUBSCRIPTION_POWER_SEK = 'GRID_SUBSCRIPTION_POWER_SEK',
  GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK = 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK',
  GRID_SUBSCRIPTION_FLOW_SEK = 'GRID_SUBSCRIPTION_FLOW_SEK',
  GRID_USAGE_SEK = 'GRID_USAGE_SEK',
  GRID_UNDEFINED_SEK = 'GRID_UNDEFINED_SEK',
  GRID_TOTAL_SEK = 'GRID_TOTAL_SEK'
}

export interface MeterReadView {
  id: number;
  unitId: number | null;
  address: string | null;
  name: string | null;
  utilityType: UtilityType;
  utilityMetrics: UtilityMetricsType[];
  assigner: string | null;
}

export interface MeterUpdateView {
  unitId?: number | null;
}

export interface MeterListViewParams {
  limit?: number;
  offset?: number;
  linked?: boolean;
  organisationId?: number;
}

export const translateUtilityType = (type: UtilityType): string => {
  const map = new Map([
    [UtilityType.ELECTRICITY, i18n.t('integrations:utilityTypeEnum.electricity')],
    [UtilityType.HEAT, i18n.t('integrations:utilityTypeEnum.heat')],
    [UtilityType.COOLING, i18n.t('integrations:utilityTypeEnum.cooling')],
    [UtilityType.GAS, i18n.t('integrations:utilityTypeEnum.gas')],
    [UtilityType.HOT_WATER, i18n.t('integrations:utilityTypeEnum.hotWater')],
    [UtilityType.WATER, i18n.t('integrations:utilityTypeEnum.water')],
    [UtilityType.SENSOR, i18n.t('integrations:utilityTypeEnum.sensor')]
  ]);

  return map.has(type) ? map.get(type)! : type.toString();
};
