import React from 'react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import GaugeCard from './Gauge/GaugeCard';
import { KPIStatus } from '../KPIs/KPICard/KpiCard';
import { useNonce } from '../../Utils/hooks';
import { GovernmentInspectionStatisticsReadView } from '../../GeneratedServices';
import { statisticsApi } from '../../Http/Http';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';

interface Props {
  propertyId?: number;
  filterValues?: FilterValuesType;
}

const GovernmentInspectionGauge: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [governmentInspectionStatistics, setGovernmentInspectionStatistics] =
    React.useState<GovernmentInspectionStatisticsReadView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();
    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceReadGovernmentInspectionStatistics(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setGovernmentInspectionStatistics(data);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();
    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  return (
    <GaugeCard
      title={t('common:governmentInspections')}
      data={[
        {
          id: '1',
          value: governmentInspectionStatistics?.overdue ?? 0,
          label: t('common:late')
        },
        {
          id: '2',
          value: governmentInspectionStatistics?.followingMonth ?? 0,
          label: t('common:lessThan30Days')
        },
        {
          id: '3',
          value: governmentInspectionStatistics?.followingSixMonths ?? 0,
          label: t('common:lessThan6Months')
        }
      ]}
      status={status}
      retry={reload}
    />
  );
};

export default GovernmentInspectionGauge;
