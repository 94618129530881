import React from 'react';
import { Dropdown, DropdownProps, Popup } from 'semantic-ui-react';
import { renderValidationErrors } from '../../Utils/FieldValidationErrorMessage';
import { FieldValidationResult } from '../../Utils/validation';

interface Props extends DropdownProps {
  isInEditMode?: boolean;
  validationResult?: FieldValidationResult;
}

const EditableTableDropdownCell: React.FC<Props> = (props) => {
  const { validationResult, isInEditMode, ...rest } = props;

  if (isInEditMode)
    return (
      <Popup
        content={renderValidationErrors(validationResult)}
        disabled={!validationResult?.error}
        position="bottom center"
        trigger={
          <Dropdown selection floating error={validationResult?.error} {...rest} style={{ whiteSpace: 'nowrap' }} />
        }
      />
    );
  else
    return (
      <Dropdown
        selection
        floating
        readOnly
        error={validationResult?.error}
        {...rest}
        style={{ whiteSpace: 'nowrap' }}
      />
    );
};

export default EditableTableDropdownCell;
