/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { FaultReportAssignmentCreateView } from '../model';
// @ts-ignore
import { FaultReportAssignmentListView } from '../model';
// @ts-ignore
import { FaultReportAssignmentReadView } from '../model';
/**
 * FaultReportAssignmentsApi - axios parameter creator
 * @export
 */
export const FaultReportAssignmentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a fault report assignment
     * @param {FaultReportAssignmentCreateView} [faultReportAssignmentCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportAssignmentResourceCreate: async (
      faultReportAssignmentCreateView?: FaultReportAssignmentCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/fault-report-assignments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        faultReportAssignmentCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a fault report assignment
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportAssignmentResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('faultReportAssignmentResourceDelete', 'id', id);
      const localVarPath = `/v1/fault-report-assignments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List fault report assignments
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [faultReportId] Filter by fault report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportAssignmentResourceList: async (
      limit?: number,
      offset?: number,
      faultReportId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/fault-report-assignments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (faultReportId !== undefined) {
        localVarQueryParameter['faultReportId'] = faultReportId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FaultReportAssignmentsApi - functional programming interface
 * @export
 */
export const FaultReportAssignmentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FaultReportAssignmentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a fault report assignment
     * @param {FaultReportAssignmentCreateView} [faultReportAssignmentCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faultReportAssignmentResourceCreate(
      faultReportAssignmentCreateView?: FaultReportAssignmentCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaultReportAssignmentReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.faultReportAssignmentResourceCreate(
        faultReportAssignmentCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a fault report assignment
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faultReportAssignmentResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.faultReportAssignmentResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List fault report assignments
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [faultReportId] Filter by fault report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faultReportAssignmentResourceList(
      limit?: number,
      offset?: number,
      faultReportId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaultReportAssignmentListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.faultReportAssignmentResourceList(
        limit,
        offset,
        faultReportId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * FaultReportAssignmentsApi - factory interface
 * @export
 */
export const FaultReportAssignmentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FaultReportAssignmentsApiFp(configuration);
  return {
    /**
     *
     * @summary Create a fault report assignment
     * @param {FaultReportAssignmentCreateView} [faultReportAssignmentCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportAssignmentResourceCreate(
      faultReportAssignmentCreateView?: FaultReportAssignmentCreateView,
      options?: any
    ): AxiosPromise<FaultReportAssignmentReadView> {
      return localVarFp
        .faultReportAssignmentResourceCreate(faultReportAssignmentCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a fault report assignment
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportAssignmentResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.faultReportAssignmentResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List fault report assignments
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [faultReportId] Filter by fault report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportAssignmentResourceList(
      limit?: number,
      offset?: number,
      faultReportId?: number,
      options?: any
    ): AxiosPromise<FaultReportAssignmentListView> {
      return localVarFp
        .faultReportAssignmentResourceList(limit, offset, faultReportId, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for faultReportAssignmentResourceCreate operation in FaultReportAssignmentsApi.
 * @export
 * @interface FaultReportAssignmentsApiFaultReportAssignmentResourceCreateRequest
 */
export interface FaultReportAssignmentsApiFaultReportAssignmentResourceCreateRequest {
  /**
   *
   * @type {FaultReportAssignmentCreateView}
   * @memberof FaultReportAssignmentsApiFaultReportAssignmentResourceCreate
   */
  readonly faultReportAssignmentCreateView?: FaultReportAssignmentCreateView;
}

/**
 * Request parameters for faultReportAssignmentResourceDelete operation in FaultReportAssignmentsApi.
 * @export
 * @interface FaultReportAssignmentsApiFaultReportAssignmentResourceDeleteRequest
 */
export interface FaultReportAssignmentsApiFaultReportAssignmentResourceDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof FaultReportAssignmentsApiFaultReportAssignmentResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for faultReportAssignmentResourceList operation in FaultReportAssignmentsApi.
 * @export
 * @interface FaultReportAssignmentsApiFaultReportAssignmentResourceListRequest
 */
export interface FaultReportAssignmentsApiFaultReportAssignmentResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof FaultReportAssignmentsApiFaultReportAssignmentResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof FaultReportAssignmentsApiFaultReportAssignmentResourceList
   */
  readonly offset?: number;

  /**
   * Filter by fault report
   * @type {number}
   * @memberof FaultReportAssignmentsApiFaultReportAssignmentResourceList
   */
  readonly faultReportId?: number;
}

/**
 * FaultReportAssignmentsApi - object-oriented interface
 * @export
 * @class FaultReportAssignmentsApi
 * @extends {BaseAPI}
 */
export class FaultReportAssignmentsApi extends BaseAPI {
  /**
   *
   * @summary Create a fault report assignment
   * @param {FaultReportAssignmentsApiFaultReportAssignmentResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaultReportAssignmentsApi
   */
  public faultReportAssignmentResourceCreate(
    requestParameters: FaultReportAssignmentsApiFaultReportAssignmentResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FaultReportAssignmentsApiFp(this.configuration)
      .faultReportAssignmentResourceCreate(requestParameters.faultReportAssignmentCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a fault report assignment
   * @param {FaultReportAssignmentsApiFaultReportAssignmentResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaultReportAssignmentsApi
   */
  public faultReportAssignmentResourceDelete(
    requestParameters: FaultReportAssignmentsApiFaultReportAssignmentResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return FaultReportAssignmentsApiFp(this.configuration)
      .faultReportAssignmentResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List fault report assignments
   * @param {FaultReportAssignmentsApiFaultReportAssignmentResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaultReportAssignmentsApi
   */
  public faultReportAssignmentResourceList(
    requestParameters: FaultReportAssignmentsApiFaultReportAssignmentResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FaultReportAssignmentsApiFp(this.configuration)
      .faultReportAssignmentResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.faultReportId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
