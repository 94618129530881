import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isDate } from 'date-fns';
import { toDateString, toDateTimeString } from './Utils/date';

// Namespace imports

// Swedish
import svCommon from './Translations/sv/common.json';
import svAuth from './Translations/sv/auth.json';
import svValidation from './Translations/sv/validation.json';
import svFinancialStatistics from './Translations/sv/financialStatistics.json';
import svProperties from './Translations/sv/properties.json';
import svFiles from './Translations/sv/files.json';
import svContracts from './Translations/sv/contracts.json';
import svEnergyReport from './Translations/sv/energyReport.json';
import svPropertyChecks from './Translations/sv/propertyChecks.json';
import svInspections from './Translations/sv/inspections.json';
import svUsers from './Translations/sv/users.json';
import svAnalytics from './Translations/sv/analytics.json';
import svOrganisations from './Translations/sv/organisations.json';
import svPortfolios from './Translations/sv/portfolios.json';
import svIntegrations from './Translations/sv/integrations.json';
import svFaultReports from './Translations/sv/faultReports.json';

// English
import enCommon from './Translations/en/common.json';
import enAuth from './Translations/en/auth.json';
import enValidation from './Translations/en/validation.json';
import enFinancialStatistics from './Translations/en/financialStatistics.json';
import enProperties from './Translations/en/properties.json';
import enFiles from './Translations/en/files.json';
import enContracts from './Translations/en/contracts.json';
import enEnergyReport from './Translations/en/energyReport.json';
import enPropertyChecks from './Translations/en/propertyChecks.json';
import enInspections from './Translations/en/inspections.json';
import enUsers from './Translations/en/users.json';
import enAnalytics from './Translations/en/analytics.json';
import enOrganisations from './Translations/en/organisations.json';
import enPortfolios from './Translations/en/portfolios.json';
import enIntegrations from './Translations/en/integrations.json';
import enFaultReports from './Translations/en/faultReports.json';
import { languageDetectorPlugin } from './Utils/languageDetector';

export const resources = {
  EN: {
    common: enCommon,
    auth: enAuth,
    validation: enValidation,
    properties: enProperties,
    files: enFiles,
    contracts: enContracts,
    financialStatistics: enFinancialStatistics,
    energyReport: enEnergyReport,
    propertyChecks: enPropertyChecks,
    inspections: enInspections,
    users: enUsers,
    analytics: enAnalytics,
    organisations: enOrganisations,
    portfolios: enPortfolios,
    integrations: enIntegrations,
    faultReports: enFaultReports
  },
  SV: {
    common: svCommon,
    auth: svAuth,
    validation: svValidation,
    properties: svProperties,
    files: svFiles,
    contracts: svContracts,
    financialStatistics: svFinancialStatistics,
    energyReport: svEnergyReport,
    propertyChecks: svPropertyChecks,
    inspections: svInspections,
    users: svUsers,
    analytics: svAnalytics,
    organisations: svOrganisations,
    portfolios: svPortfolios,
    integrations: svIntegrations,
    faultReports: svFaultReports
  }
} as const;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(languageDetectorPlugin)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    interpolation: {
      format: (value, format) => {
        if (isDate(value)) {
          switch (format) {
            case 'datetime':
              return toDateTimeString(value);
            default:
              return toDateString(value);
          }
        }
        return value;
      },
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: resources,
    returnNull: false,
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'u', 'b']
    }
  });

export default i18n;
