import React from 'react';
import { FiltersContext } from '../../../FiltersProvider/FiltersProvider';
import { MembershipReadView } from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { getUserRoles, Role } from '../../../Utils/permissions';

export const useFilters = (props: {
  selectedOrganisationIds: number[] | undefined;
  selectedPortfolioIds: number[] | undefined;
  setSelectedOrganisationIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setSelectedPortfolioIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setSelectedPropertySubscriptionUserIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setSelectedMunicipalities: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}) => {
  const {
    selectedOrganisationIds,
    selectedPortfolioIds,
    setSelectedOrganisationIds,
    setSelectedPortfolioIds,
    setSelectedPropertySubscriptionUserIds,
    setSelectedMunicipalities
  } = props;
  const { currentUser, currentUserMemberships } = React.useContext(globalStateCTX);

  const { organisationsMap, portfoliosMap, userMembershipsMap } = React.useContext(FiltersContext);

  const isSuperuser = React.useMemo(() => currentUser?.isSuperuser, [currentUser]);
  const userRoles = React.useMemo(() => getUserRoles(currentUserMemberships), [currentUserMemberships]);
  const organisations = React.useMemo(() => Array.from(organisationsMap.values()), [organisationsMap]);

  const portfolios = React.useMemo(() => {
    const portfoliosArray = Array.from(portfoliosMap.values());
    if (isSuperuser || userRoles.some((role) => [Role.Admin, Role.Manager, Role.Investor].includes(role)))
      return portfoliosArray.filter((item) =>
        selectedOrganisationIds && selectedOrganisationIds.length !== 0
          ? selectedOrganisationIds.includes(item.organisationId)
          : true
      );
    else return [];
  }, [portfoliosMap, isSuperuser, userRoles, selectedOrganisationIds]);

  const memberships = React.useMemo(() => {
    let managersAndTechnicians: MembershipReadView[] = [];
    let managersOnly: MembershipReadView[] = [];
    let techniciansOnly: MembershipReadView[] = [];

    const organisationIds =
      selectedPortfolioIds && selectedPortfolioIds.length !== 0
        ? Array.from(
            new Set(
              portfolios.filter((item) => selectedPortfolioIds.includes(item.id)).map((item) => item.organisationId)
            )
          )
        : selectedOrganisationIds;

    if (isSuperuser || userRoles.some((role) => [Role.Admin, Role.Manager, Role.Investor].includes(role))) {
      const membershipsArray = Array.from(userMembershipsMap.values())
        .flatMap((item) => item)
        .filter((item) =>
          organisationIds && organisationIds.length !== 0 ? organisationIds.includes(item.organisationId) : true
        );

      if (isSuperuser) {
        managersAndTechnicians = membershipsArray.filter((item) =>
          [Role.Manager, Role.Admin, Role.Technician].includes(item.role as Role)
        );
      }

      if (userRoles.some((role) => [Role.Admin, Role.Manager].includes(role))) {
        const adminOrManagerMemberships = currentUserMemberships?.filter(
          (item) => item.role === Role.Admin || item.role === Role.Manager
        );

        managersAndTechnicians = membershipsArray.filter(
          (item) =>
            [Role.Manager, Role.Admin, Role.Technician].includes(item.role as Role) &&
            adminOrManagerMemberships?.some((membership) => membership.organisationId === item.organisationId)
        );
      }

      if (userRoles.some((role) => role === Role.Investor)) {
        const investorMemberships = currentUserMemberships?.filter((item) => item.role === Role.Investor);
        managersOnly = membershipsArray.filter(
          (item) =>
            [Role.Manager, Role.Admin].includes(item.role as Role) &&
            investorMemberships?.some((membership) => membership.organisationId === item.organisationId)
        );
      }

      if (userRoles.some((role) => role === Role.Technician)) {
        techniciansOnly =
          currentUserMemberships?.filter(
            (item) => item.role === Role.Technician && organisationIds?.includes(item.organisationId)
          ) ?? [];
      }

      return [
        ...new Map(
          [...managersAndTechnicians, ...managersOnly, ...techniciansOnly].map((item) => [item.userId, item])
        ).values()
      ];
    } else
      return (
        currentUserMemberships?.filter((item) =>
          item.role === Role.Technician && organisationIds ? organisationIds.includes(item.organisationId) : true
        ) ?? []
      );
  }, [
    userMembershipsMap,
    userRoles,
    isSuperuser,
    selectedOrganisationIds,
    selectedPortfolioIds,
    portfolios,
    currentUserMemberships
  ]);

  const handleClearFilters = () => {
    setSelectedOrganisationIds(undefined);
    setSelectedPortfolioIds(undefined);
    setSelectedPropertySubscriptionUserIds(undefined);
    setSelectedMunicipalities(undefined);
  };

  const handlePortfoliosChange = (portIds: number[]) => {
    setSelectedPortfolioIds(portIds);

    if (portIds.length !== 0) {
      setSelectedPropertySubscriptionUserIds((prevValue) => filterUsersByPortfolios(prevValue, portIds));
    }
  };

  const handleOrganisationsChange = (orgIds: number[]) => {
    setSelectedOrganisationIds(orgIds);

    if (orgIds.length !== 0) {
      setSelectedPortfolioIds((prevValue) =>
        prevValue
          ?.map((item) => portfoliosMap.get(item)!)
          .filter((item) => orgIds.includes(item.organisationId))
          .map((item) => item.id)
      );

      setSelectedPropertySubscriptionUserIds((prevValue) => filterUsersByOrganisations(prevValue, orgIds));
    }
  };

  const filterUsersByPortfolios = (userIds: number[] | undefined, portIds: number[]) => {
    const organisationIds = portIds.map((item) => portfoliosMap.get(item)?.organisationId);

    return userIds
      ?.map((item) => userMembershipsMap.get(item)!)
      .filter((item) =>
        item.some(
          (item) =>
            organisationIds.includes(item.organisationId) &&
            [Role.Admin, Role.Manager, Role.Technician].includes(item.role as Role)
        )
      )
      .map((item) => item[0].userId);
  };

  const filterUsersByOrganisations = (userIds: number[] | undefined, orgIds: number[]) => {
    return userIds
      ?.map((item) => userMembershipsMap.get(item)!)
      .filter((item) =>
        item.some(
          (item) =>
            orgIds.includes(item.organisationId) &&
            [Role.Admin, Role.Manager, Role.Technician].includes(item.role as Role)
        )
      )
      .map((item) => item[0].userId);
  };

  return {
    organisations,
    portfolios,
    memberships,
    handlePortfoliosChange,
    handleOrganisationsChange,
    handleClearFilters,
    filterUsersByOrganisations,
    filterUsersByPortfolios
  };
};
