import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Progress, SemanticICONS } from 'semantic-ui-react';
import colors from '../../Utils/Colors/Colors.module.scss';
import styles from './FileUploadProgress.module.scss';
import { UnexpectedErrorText } from '../../Http/response-error';

interface Props {
  progress?: UploadProgress;
  resetProgress: () => void;
  cancelUpload: () => void;
  iconVisible?: boolean;
}

export enum Status {
  Waiting,
  Preparing,
  Uploading,
  Uploaded,
  Canceled,
  Error
}

export interface UploadProgress {
  status: Status;
  totalBytes?: number;
  uploadedBytes?: number;
  fileReader?: FileReader;
  abortController?: AbortController;
  errorMessage?: string;
}

export const buildStatusCellContent = (
  content: any,
  iconName: SemanticICONS,
  iconTitle: string,
  iconVisible: () => boolean,
  onClick: () => void
) => {
  return (
    <div className={styles.statusCellWithIcon}>
      {content}
      {iconVisible() && <Icon name={iconName} className="clear-icon" onClick={onClick} title={iconTitle} />}
    </div>
  );
};
const FileUploadProgress: React.FC<Props> = (props) => {
  const { t } = useTranslation(['files', 'common']);

  const { progress, cancelUpload, resetProgress, iconVisible } = props;

  const getUploadProgressLayout = () => {
    if (!progress) {
      return <span className={styles.statusCellText}>{t('files:uploadNotYetStarted')}</span>;
    }

    switch (progress.status) {
      case Status.Canceled:
        return buildStatusCellContent(
          <span style={{ color: colors.red }} className={styles.statusCellText}>
            {t('files:uploadCanceled')}
          </span>,
          'refresh',
          t('common:tryAgain'),
          () => true,
          () => resetProgress()
        );

      case Status.Waiting:
        return buildStatusCellContent(
          <span className={styles.statusCellText}>{t('files:waiting')}</span>,
          'cancel',
          t('common:cancel'),
          () => true,
          () => cancelUpload()
        );

      case Status.Preparing:
        return buildStatusCellContent(
          <span className={styles.statusCellText}>{t('files:preparing')}</span>,
          'cancel',
          t('common:cancel'),
          () => true,
          () => cancelUpload()
        );

      case Status.Uploading:
        return buildStatusCellContent(
          <Progress
            size="medium"
            active
            progress="percent"
            total={progress.totalBytes}
            precision={0}
            value={progress.uploadedBytes}
            style={{ margin: 0 }}
          />,
          'cancel',
          t('common:cancel'),
          () => true,
          () => cancelUpload()
        );

      case Status.Uploaded:
        return (
          <Progress
            size="medium"
            progress="percent"
            total={100}
            precision={0}
            value={100}
            color="green"
            style={{ margin: 0 }}
          />
        );

      case Status.Error:
        return buildStatusCellContent(
          <span style={{ color: colors.red }} className={styles.statusCellText}>
            {props.progress?.errorMessage ?? UnexpectedErrorText()}
          </span>,
          'refresh',
          t('common:tryAgain'),
          () => (iconVisible ? iconVisible : true),
          () => resetProgress()
        );
    }
  };

  return getUploadProgressLayout();
};

export default FileUploadProgress;
