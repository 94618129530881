import { addDays, format, parseISO } from 'date-fns';
import svSeLocale from 'date-fns/locale/sv';
import enCaLocale from 'date-fns/locale/en-CA';
import i18n from '../i18n';

const getLocale = () => {
  return i18n.resolvedLanguage === 'SV' ? svSeLocale : enCaLocale;
};
/**
 * Returns a string representing specified date with time'.
 *
 * @param date Date object.
 */
export const toDateTimeString = (date: Date) => {
  return format(date, 'Pp', { locale: getLocale() });
};

/**
 * Returns a string representing specified date without time.
 *
 * @param date Date object.
 */
export const toDateString = (date: Date) => {
  return format(date, 'P', { locale: getLocale() });
};

/**
 * Returns abbreviated month for the specified date.
 * @param date Date object.
 */
export const toShortMonthName = (date: Date) => {
  return format(date, 'MMM', { locale: getLocale() });
};

/**
 * Returns full month name for the specified date.
 * @param date Date object.
 */
export const toLongMonthName = (date: Date) => {
  return format(date, 'MMMM', { locale: getLocale() });
};

/**
 * Returns number of days in specified month.
 *
 * @param year Year
 * @param month Month (zero based)
 */
export const daysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

/**
 * Returns the logical deadline for a specified date string.
 *
 * Let’s say the deadline for the property check is the end of this week, which will be 2022-02-07T00:00:00+01:00 (Swedish time),
 * note that the 7th is Monday and once Monday starts the check becomes overdue.
 * However, for the human being, it is more natural to have a deadline at the end of the day, not at the start.
 * So a human being would say that the deadline is 2022-02-06 (Sunday) and one should finish the work by the end of the day.
 * Using 2022-02-06T23:59:59+01:00 as a deadline is hard because calculations become very strange.
 * So to account for that and display a value that would make sense for a person, we subtract one day from the deadline,
 * to display the previous date (as we don’t want to display the time part and make it even more confusing for the user).
 *
 * @param deadline Deadline string
 */
export const getDeadlineDate = (deadline: string) => {
  return addDays(parseISO(deadline), -1);
};

/**
 * Returns a string representing specified date with YYMM format.
 *
 * @param date Date object.
 */
export const toMonthYearString = (date: Date) => {
  return format(date, 'yyMM', { locale: getLocale() });
};

/**
 * Returns a date at the start of the month.
 *
 * @param date Date string.
 */
export const toStartOfMonthDate = (dateString?: string | null) => {
  if (dateString) {
    const date = new Date(dateString);

    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  return null;
};

/**
 * Returns a date at the end of the month.
 *
 * @param date Date string.
 */
export const toEndOfMonthDate = (dateString?: string | null) => {
  if (dateString) {
    const date = new Date(dateString);

    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  return null;
};

/**
 * Returns a boolean value indicating wether the date strings share the same year and month.
 *
 * @param a Date string.
 * @param b Date string.
 */
export const areSameMonthYear = (a: string | null, b: string | null) => {
  if (!a && !b) return true;
  else if (a) {
    if (!b) return false;
    else {
      const aDate = new Date(a);
      const bDate = new Date(b);

      return aDate.getFullYear() === bDate.getFullYear() && aDate.getMonth() === bDate.getMonth();
    }
  }
};

/**
 * Returns a date in current year from month number.
 *
 * @param month Month number.
 */
export const monthNumberToDate = (month: number) => {
  const date = new Date();
  date.setMonth(month - 1);

  return date;
};
