import React from 'react';
import { Form } from 'semantic-ui-react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import * as validation from '../../Utils/validation';
import { commonNumberFormatProperties } from '../NumberFormater/NumberFormater';
import styles from './RangeFilterInputs.module.scss';

interface Props {
  label: string;
  minValue: number | undefined;
  setMinValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  maxValue: number | undefined;
  setMaxValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  validationResult: validation.FieldValidationResult | undefined;
  additionalProps?: NumberFormatProps;
  clearValidation: () => void;
}
const RangeFilterInputs: React.FC<Props> = (props) => {
  return (
    <Form.Field error={props.validationResult?.error}>
      <label>{props.label}</label>
      <Form.Group widths="equal" className={styles.inputsWrapper}>
        <Form.Input
          placeholder="Min"
          fluid
          input={
            <NumberFormat
              {...commonNumberFormatProperties}
              {...props.additionalProps}
              value={props.minValue ?? ''}
              placeholder={'Min'}
              onValueChange={(value, name) => {
                props.setMinValue(value.floatValue);
                props.clearValidation();
              }}
            />
          }
        />
        <div>-</div>
        <Form.Input
          placeholder="Max"
          fluid
          input={
            <NumberFormat
              {...commonNumberFormatProperties}
              {...props.additionalProps}
              value={props.maxValue ?? ''}
              placeholder={'Max'}
              onValueChange={(value, name) => {
                props.setMaxValue(value.floatValue);
                props.clearValidation();
              }}
            />
          }
        />
      </Form.Group>
      <Form.Field
        error={
          props.validationResult?.error && {
            content: props.validationResult.errorMessage
          }
        }
        label=""
      />
    </Form.Field>
  );
};
export default RangeFilterInputs;
