/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { PortfolioCreateView } from '../model';
// @ts-ignore
import { PortfolioListView } from '../model';
// @ts-ignore
import { PortfolioReadView } from '../model';
// @ts-ignore
import { PortfolioUpdateView } from '../model';
/**
 * PortfoliosApi - axios parameter creator
 * @export
 */
export const PortfoliosApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a portfolio
     * @param {PortfolioCreateView} [portfolioCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceCreate: async (
      portfolioCreateView?: PortfolioCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/portfolios`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(portfolioCreateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a portfolio
     * @param {number} id Portfolio ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('portfolioResourceDelete', 'id', id);
      const localVarPath = `/v1/portfolios/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List portfolios
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by which organisation\&#39;s properties are part of the portfolio
     * @param {Array<number>} [organisationIds] Filter by which organisations\&#39; properties are part of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      organisationIds?: Array<number>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/portfolios`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a portfolio
     * @param {number} id Portfolio ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('portfolioResourceRead', 'id', id);
      const localVarPath = `/v1/portfolios/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a portfolio
     * @param {number} id Portfolio ID
     * @param {PortfolioUpdateView} [portfolioUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceUpdate: async (
      id: number,
      portfolioUpdateView?: PortfolioUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('portfolioResourceUpdate', 'id', id);
      const localVarPath = `/v1/portfolios/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(portfolioUpdateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * PortfoliosApi - functional programming interface
 * @export
 */
export const PortfoliosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PortfoliosApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a portfolio
     * @param {PortfolioCreateView} [portfolioCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portfolioResourceCreate(
      portfolioCreateView?: PortfolioCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioResourceCreate(portfolioCreateView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a portfolio
     * @param {number} id Portfolio ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portfolioResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List portfolios
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by which organisation\&#39;s properties are part of the portfolio
     * @param {Array<number>} [organisationIds] Filter by which organisations\&#39; properties are part of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portfolioResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      organisationIds?: Array<number>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioResourceList(
        limit,
        offset,
        organisationId,
        organisationIds,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a portfolio
     * @param {number} id Portfolio ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portfolioResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a portfolio
     * @param {number} id Portfolio ID
     * @param {PortfolioUpdateView} [portfolioUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portfolioResourceUpdate(
      id: number,
      portfolioUpdateView?: PortfolioUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioResourceUpdate(
        id,
        portfolioUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * PortfoliosApi - factory interface
 * @export
 */
export const PortfoliosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PortfoliosApiFp(configuration);
  return {
    /**
     *
     * @summary Create a portfolio
     * @param {PortfolioCreateView} [portfolioCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceCreate(portfolioCreateView?: PortfolioCreateView, options?: any): AxiosPromise<PortfolioReadView> {
      return localVarFp
        .portfolioResourceCreate(portfolioCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a portfolio
     * @param {number} id Portfolio ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.portfolioResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List portfolios
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by which organisation\&#39;s properties are part of the portfolio
     * @param {Array<number>} [organisationIds] Filter by which organisations\&#39; properties are part of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      organisationIds?: Array<number>,
      options?: any
    ): AxiosPromise<PortfolioListView> {
      return localVarFp
        .portfolioResourceList(limit, offset, organisationId, organisationIds, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a portfolio
     * @param {number} id Portfolio ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceRead(id: number, options?: any): AxiosPromise<PortfolioReadView> {
      return localVarFp.portfolioResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a portfolio
     * @param {number} id Portfolio ID
     * @param {PortfolioUpdateView} [portfolioUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portfolioResourceUpdate(
      id: number,
      portfolioUpdateView?: PortfolioUpdateView,
      options?: any
    ): AxiosPromise<PortfolioReadView> {
      return localVarFp
        .portfolioResourceUpdate(id, portfolioUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for portfolioResourceCreate operation in PortfoliosApi.
 * @export
 * @interface PortfoliosApiPortfolioResourceCreateRequest
 */
export interface PortfoliosApiPortfolioResourceCreateRequest {
  /**
   *
   * @type {PortfolioCreateView}
   * @memberof PortfoliosApiPortfolioResourceCreate
   */
  readonly portfolioCreateView?: PortfolioCreateView;
}

/**
 * Request parameters for portfolioResourceDelete operation in PortfoliosApi.
 * @export
 * @interface PortfoliosApiPortfolioResourceDeleteRequest
 */
export interface PortfoliosApiPortfolioResourceDeleteRequest {
  /**
   * Portfolio ID
   * @type {number}
   * @memberof PortfoliosApiPortfolioResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for portfolioResourceList operation in PortfoliosApi.
 * @export
 * @interface PortfoliosApiPortfolioResourceListRequest
 */
export interface PortfoliosApiPortfolioResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof PortfoliosApiPortfolioResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof PortfoliosApiPortfolioResourceList
   */
  readonly offset?: number;

  /**
   * Filter by which organisation\&#39;s properties are part of the portfolio
   * @type {number}
   * @memberof PortfoliosApiPortfolioResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by which organisations\&#39; properties are part of the portfolio
   * @type {Array<number>}
   * @memberof PortfoliosApiPortfolioResourceList
   */
  readonly organisationIds?: Array<number>;
}

/**
 * Request parameters for portfolioResourceRead operation in PortfoliosApi.
 * @export
 * @interface PortfoliosApiPortfolioResourceReadRequest
 */
export interface PortfoliosApiPortfolioResourceReadRequest {
  /**
   * Portfolio ID
   * @type {number}
   * @memberof PortfoliosApiPortfolioResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for portfolioResourceUpdate operation in PortfoliosApi.
 * @export
 * @interface PortfoliosApiPortfolioResourceUpdateRequest
 */
export interface PortfoliosApiPortfolioResourceUpdateRequest {
  /**
   * Portfolio ID
   * @type {number}
   * @memberof PortfoliosApiPortfolioResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PortfolioUpdateView}
   * @memberof PortfoliosApiPortfolioResourceUpdate
   */
  readonly portfolioUpdateView?: PortfolioUpdateView;
}

/**
 * PortfoliosApi - object-oriented interface
 * @export
 * @class PortfoliosApi
 * @extends {BaseAPI}
 */
export class PortfoliosApi extends BaseAPI {
  /**
   *
   * @summary Create a portfolio
   * @param {PortfoliosApiPortfolioResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public portfolioResourceCreate(
    requestParameters: PortfoliosApiPortfolioResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PortfoliosApiFp(this.configuration)
      .portfolioResourceCreate(requestParameters.portfolioCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a portfolio
   * @param {PortfoliosApiPortfolioResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public portfolioResourceDelete(
    requestParameters: PortfoliosApiPortfolioResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return PortfoliosApiFp(this.configuration)
      .portfolioResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List portfolios
   * @param {PortfoliosApiPortfolioResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public portfolioResourceList(
    requestParameters: PortfoliosApiPortfolioResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PortfoliosApiFp(this.configuration)
      .portfolioResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.organisationIds,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a portfolio
   * @param {PortfoliosApiPortfolioResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public portfolioResourceRead(
    requestParameters: PortfoliosApiPortfolioResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return PortfoliosApiFp(this.configuration)
      .portfolioResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a portfolio
   * @param {PortfoliosApiPortfolioResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public portfolioResourceUpdate(
    requestParameters: PortfoliosApiPortfolioResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return PortfoliosApiFp(this.configuration)
      .portfolioResourceUpdate(requestParameters.id, requestParameters.portfolioUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
