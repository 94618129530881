import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { PropertyCheckReadView } from '../../Services/PropertyCheckService.types';
import * as PropertyCheckService from '../../Services/PropertyCheckService';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  propertyCheck: PropertyCheckReadView;
  onClose: (deleted: boolean) => void;
}

enum Status {
  Idle,
  Deleting
}

const DeletePropertyCheckModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'propertyChecks']);

  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);
  const [status, setStatus] = React.useState(Status.Idle);

  const onDelete = async () => {
    try {
      setStatus(Status.Deleting);
      await PropertyCheckService.deletePropertyCheck(props.propertyCheck.id);
      toast.info(
        <Trans i18nKey="propertyChecks:controlPointRemovedMessage" t={t}>
          Kontrollpunkten <strong>{{ title: props.propertyCheck.title }}</strong> har tagits bort.
        </Trans>
      );
      props.onClose(true);
    } catch (error) {
      globalState.handleHttpErrors(error);
    } finally {
      setStatus(Status.Idle);
    }
  };

  return (
    <Modal
      size={'mini'}
      open
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon={status !== Status.Deleting}
      onClose={() => props.onClose(false)}
    >
      <Modal.Header>{t('propertyChecks:removeControlPoint')}</Modal.Header>

      <Modal.Content>
        <Trans i18nKey="propertyChecks:doYouWantToRemoveMessage" t={t}>
          Vill du ta bort <strong>{{ title: props.propertyCheck.title }}</strong>?
        </Trans>
      </Modal.Content>

      <Modal.Actions>
        <Button primary loading={status === Status.Deleting} disabled={status === Status.Deleting} onClick={onDelete}>
          {t('common:yesRemove')}
        </Button>
        <Button secondary disabled={status === Status.Deleting} onClick={() => props.onClose(false)}>
          {t('common:close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeletePropertyCheckModal;
