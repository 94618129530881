/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface BusinessPlanLeaseReadView
 */
export interface BusinessPlanLeaseReadView {
  /**
   * Additions per sqm. (Öre)
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  additions: number;
  /**
   * Additions (indexed). (Öre)
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  additionsIndexed: number;
  /**
   * Base rent per sqm. (Öre)
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  baseRent: number;
  /**
   * Base rent (indexed). (Öre)
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  baseRentIndexed: number;
  /**
   * Downtime period. (Months)
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  downtimePeriod: number;
  /**
   * Generation
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  generation: number;
  /**
   * ID.
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  id: number;
  /**
   * Area (only indoor) in m².
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  indoorArea: number;
  /**
   * Investment during downtime. (Öre)
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  investment: number;
  /**
   * Move in date.
   * @type {string}
   * @memberof BusinessPlanLeaseReadView
   */
  moveInDate: string;
  /**
   * Move out date.
   * @type {string}
   * @memberof BusinessPlanLeaseReadView
   */
  moveOutDate: string;
  /**
   * Object the lease is planned for.
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  objectId: number;
  /**
   * Object Type.
   * @type {string}
   * @memberof BusinessPlanLeaseReadView
   */
  objectType: BusinessPlanLeaseReadViewObjectTypeEnum;
  /**
   * Property ID.
   * @type {number}
   * @memberof BusinessPlanLeaseReadView
   */
  propertyId: number;
  /**
   * Tenant name.
   * @type {string}
   * @memberof BusinessPlanLeaseReadView
   */
  tenantName: string;
}

export const BusinessPlanLeaseReadViewObjectTypeEnum = {
  Apartment: 'APARTMENT',
  StudentApartment: 'STUDENT_APARTMENT',
  BusinessPremises: 'BUSINESS_PREMISES',
  Antenna: 'ANTENNA',
  BlockRental: 'BLOCK_RENTAL',
  BuildingRights: 'BUILDING_RIGHTS',
  Depot: 'DEPOT',
  CommunityFacility: 'COMMUNITY_FACILITY',
  Garage: 'GARAGE',
  House: 'HOUSE',
  IndustrialPremises: 'INDUSTRIAL_PREMISES',
  Parking: 'PARKING',
  DisplaySpace: 'DISPLAY_SPACE',
  Premises: 'PREMISES',
  NursingHome: 'NURSING_HOME',
  Storage: 'STORAGE',
  Land: 'LAND'
} as const;

export type BusinessPlanLeaseReadViewObjectTypeEnum =
  (typeof BusinessPlanLeaseReadViewObjectTypeEnum)[keyof typeof BusinessPlanLeaseReadViewObjectTypeEnum];
