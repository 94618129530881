import React from 'react';
import { Button, Checkbox, Dropdown, Icon, Message, Modal, Table } from 'semantic-ui-react';
import './FileUploadModal.scss';
import { resolveIconFromFilename } from '../../Utils/icon';
import fileSize from 'filesize';
import * as FileService from '../../Services/FileService';
import { FileEntityType, FileSubType, FileType } from '../../Services/FileService.types';
import PromisePool from 'es6-promise-pool';
import { v4 } from 'uuid';
import FileUploadSelectTypes from './FileUploadSelectTypes';
import ModalDropdownRenderer from '../ModalDropdownRenderer';
import { getBase64FromReaderResult } from '../../Utils/file';
import FileDropzone from '../FileDropzone/FileDropzone';
import FileUploadProgress, { buildStatusCellContent, Status } from '../FileUploadProgress/FileUploadProgress';
import { useTranslation, Trans } from 'react-i18next';
import axios from 'axios';

interface Props {
  /**
   * Called when modal is closed.
   * @param filesUploadedTypes List of file types files were uploaded to.
   */
  onClose: (uploadedFiles: { id: string; type: FileType; subType?: FileSubType }[]) => void;
  entityType: FileEntityType;
  entityId: number;
  defaultFileType?: FileType;
  defaultFileSubType?: FileSubType;
}
const inProgressStatuses = [Status.Waiting, Status.Preparing, Status.Uploading];

interface FileItem {
  id: string;
  content: File;
  checked: boolean;
  name: string;
  fileSize: number;
  type: FileType;
  subType?: FileSubType;
  progress?: UploadProgress;
}

interface UploadProgress {
  status: Status;
  base64Size?: number;
  bytesUploaded?: number;
  fileReader?: FileReader;
  abortController?: AbortController;
}

type FileItemState = FileItem[];
type FileItemAction =
  | { type: 'ADD_TO_UPLOAD_QUEUE'; id: string }
  | { type: 'ADD_ALL_TO_UPLOAD_QUEUE' }
  | { type: 'PREPARE_FOR_UPLOAD'; id: string; fileReader: FileReader; abortController: AbortController }
  | { type: 'START_UPLOAD'; id: string }
  | { type: 'SET_STATUS'; id: string; status: Status; newId?: string }
  | { type: 'SET_TYPES_TO_ALL'; fileType: FileType; fileSubType?: FileSubType }
  | { type: 'SET_TYPE'; id: string; fileType: FileType }
  | { type: 'SET_SUB_TYPE'; id: string; fileSubType: FileSubType }
  | { type: 'SET_UPLOADED_BYTES'; id: string; bytesUploaded: number; totalBytes: number }
  | { type: 'ADD'; files: FileItem[] }
  | { type: 'REMOVE'; id: string }
  | { type: 'REMOVE_CHECKED' }
  | { type: 'HEADER_CHECKBOX_CLICKED' }
  | { type: 'CHECKBOX_CLICKED'; id: string }
  | { type: 'CANCEL'; id: string }
  | { type: 'CANCEL_ALL' };

const FileUploadModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['files', 'common']);

  const [files, dispatchFiles] = React.useReducer(filesReducer, []);
  const [typesForSelectedVisible, setTypesForSelectedVisible] = React.useState(false);

  const [promisePool, setPromisePool] = React.useState<PromisePool<void>>();
  const [uploadedIdList, setUploadedIdList] = React.useState<FileItem[]>([]);

  const categoryColumnVisible = ['PROPERTY', 'CONTRACT'].includes(props.entityType);

  /**
   * Returns a function that returns a promise for the next file to upload.
   * If no files exists, null is returned (and exits the promise pool).
   */
  const createPromiseProducer = React.useCallback(() => {
    // List of ids that has been pooled and should not be fetched again.
    // If a file is canceled and uploading again, it gets a new id.
    const idsInProgress: string[] = [];

    return () => {
      const file = files.find((x) => !idsInProgress.includes(x.id) && x.progress?.status === Status.Waiting);
      if (file) {
        idsInProgress.push(file.id);
        return uploadFile(file);
      }

      // Set promise pool to undefined to inform promise pool has ended.
      setPromisePool(undefined);
      return null;
    };

    // todo: Try to remove this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  /**
   * Start promise pool after is has been created.
   */
  React.useEffect(() => {
    if (promisePool) {
      promisePool.start();
    }
  }, [promisePool]);

  /**
   * Run each time files
   */
  React.useEffect(() => {
    for (const file of files) {
      switch (file.progress?.status) {
        case Status.Waiting:
          // Start promise pool if waiting item exists.
          if (!promisePool) {
            // @ts-ignore
            setPromisePool(new PromisePool(createPromiseProducer(), 6));
          }
          return;

        case Status.Uploaded:
          if (!uploadedIdList.includes(file)) {
            setUploadedIdList([...uploadedIdList, file]);

            // Let "100%" be shown for the user for 1 second before removing file from list.
            setTimeout(() => {
              dispatchFiles({
                type: 'REMOVE',
                id: file.id
              });
            }, 1000);
          }
          break;
      }
    }
  }, [files, uploadedIdList, promisePool, createPromiseProducer]);

  /**
   * Change current state base on an action.
   */
  function filesReducer(state: FileItemState, action: FileItemAction): FileItemState {
    switch (action.type) {
      case 'ADD_TO_UPLOAD_QUEUE': {
        const list = [...state];

        const index = list.findIndex((x) => x.id === action.id);
        if (index !== -1) {
          const file = { ...list[index], progress: { status: Status.Waiting } };
          list[index] = file;
        }

        return list;
      }

      case 'ADD_ALL_TO_UPLOAD_QUEUE': {
        const list = [...state];

        const itemsNotStarted = list.filter(
          (x) => !x.progress || [Status.Canceled, Status.Error].includes(x.progress.status)
        );
        for (const file of itemsNotStarted) {
          file.progress = {
            status: Status.Waiting
          };
        }
        return list;
      }

      case 'PREPARE_FOR_UPLOAD': {
        const list = [...state];

        const index = list.findIndex((x) => x.id === action.id);
        if (index !== -1) {
          const file = { ...list[index] };

          file.progress!.status = Status.Preparing;
          file.progress!.abortController = action.abortController;
          file.progress!.fileReader = action.fileReader;

          list[index] = file;
        }

        return list;
      }

      case 'START_UPLOAD': {
        const list = [...state];

        const index = list.findIndex((x) => x.id === action.id);
        if (index !== -1) {
          const file = { ...list[index] };

          file.progress!.status = Status.Uploading;
          file.progress!.bytesUploaded = 0;
          file.progress!.base64Size = 0;

          list[index] = file;
        }

        return list;
      }

      case 'SET_STATUS': {
        const list = [...state];

        const index = list.findIndex((x) => x.id === action.id);
        if (index !== -1) {
          list[index] = {
            ...list[index],
            id: action.newId ?? list[index].id,
            progress: {
              ...list[index].progress,
              status: action.status
            }
          };
        }

        return list;
      }

      case 'SET_TYPES_TO_ALL': {
        const list = [...state];

        for (const file of list.filter((x) => x.checked)) {
          file.type = action.fileType;
          file.subType = action.fileSubType;
        }

        return list;
      }

      case 'SET_TYPE': {
        const list = [...state];

        const index = list.findIndex((x) => x.id === action.id);
        if (index !== -1) {
          list[index] = {
            ...list[index],
            type: action.fileType,
            subType: undefined
          };
        }

        return list;
      }

      case 'SET_SUB_TYPE': {
        const list = [...state];

        const index = list.findIndex((x) => x.id === action.id);
        if (index !== -1) {
          list[index] = {
            ...list[index],
            subType: action.fileSubType
          };
        }

        return list;
      }

      case 'SET_UPLOADED_BYTES': {
        const list = [...state];

        const index = list.findIndex((x) => x.id === action.id);
        if (index) {
          const file = { ...list[index] };
          list[index] = file;

          file.progress!.status = Status.Uploading;
          file.progress!.bytesUploaded = action.bytesUploaded;
          file.progress!.base64Size = action.totalBytes;
        }

        return list;
      }

      case 'ADD':
        return [...action.files, ...state];

      case 'REMOVE': {
        return [...state].filter((x) => x.id !== action.id);
      }

      case 'REMOVE_CHECKED':
        return [...state].filter((x) => !x.checked);

      case 'HEADER_CHECKBOX_CLICKED': {
        const list = [...state];

        const checkableFiles = list.filter((x) => !x.progress || !inProgressStatuses.includes(x.progress?.status));
        const allChecked = checkableFiles.every((x) => x.checked);

        for (const [index, file] of list.entries()) {
          list[index] = {
            ...file,
            checked: !allChecked
          };
        }

        return list;
      }

      case 'CHECKBOX_CLICKED': {
        const list = [...state];

        const index = list.findIndex((x) => x.id === action.id);
        if (index !== -1) {
          list[index] = {
            ...list[index],
            checked: !list[index].checked
          };
        }

        return list;
      }

      case 'CANCEL': {
        const file = state.find((x) => x.id === action.id);
        if (file) {
          file.progress!.status = Status.Canceled;
          file.progress!.fileReader?.abort();
          file.progress?.abortController?.abort();
        }

        return state;
      }

      case 'CANCEL_ALL': {
        for (const file of state) {
          if (file.progress && inProgressStatuses.includes(file.progress.status)) {
            file.progress!.status = Status.Canceled;
            file.progress!.fileReader?.abort();
            file.progress!.abortController?.abort();
          }
        }

        return state;
      }
    }
  }

  const fileChangeHandler = (files: File[]) => {
    dispatchFiles({
      type: 'ADD',
      files: files.map((file) => ({
        id: v4(),
        content: file,
        name: file.name,
        fileSize: file.size,
        checked: categoryColumnVisible,
        type: props.defaultFileType,
        subType: props.defaultFileSubType
      })) as FileItem[]
    });
  };

  /**
   * Returns a promise for uploading the specified file.
   * Promise is resolved even if an error occurs (file state sets to Error).
   */
  const uploadFile = async (file: FileItem): Promise<void> => {
    return new Promise((resolve) => {
      const abortController = new AbortController();
      const reader = new FileReader();

      dispatchFiles({
        type: 'PREPARE_FOR_UPLOAD',
        id: file.id,
        abortController,
        fileReader: reader
      });

      // Read file from and get base64 data.
      reader.readAsDataURL(file.content);
      reader.onabort = async () => {
        dispatchFiles({
          type: 'SET_STATUS',
          id: file.id,
          status: Status.Canceled
        });
        resolve();
        return;
      };

      // Start upload after base64 has been calculated.
      reader.onload = async () => {
        try {
          // Set state of file to Uploading (start upload).
          dispatchFiles({
            type: 'START_UPLOAD',
            id: file.id
          });

          // Start upload and report uploaded bytes progressively.
          const result = await FileService.uploadFile(
            {
              blobBase64: getBase64FromReaderResult(reader.result as string)!,
              entityType: props.entityType,
              entityId: props.entityId,
              name: file.content.name,
              type: file.type,
              subType: file.subType
            },
            {
              signal: abortController.signal,
              onUploadProgress: (e: ProgressEvent) => {
                dispatchFiles({
                  type: 'SET_UPLOADED_BYTES',
                  id: file.id,
                  bytesUploaded: e.loaded,
                  totalBytes: e.total
                });
              }
            }
          );

          dispatchFiles({
            type: 'SET_STATUS',
            id: file.id,
            newId: result.id,
            status: Status.Uploaded
          });
        } catch (err) {
          dispatchFiles({
            type: 'SET_STATUS',
            id: file.id,
            status: axios.isCancel(err) ? Status.Canceled : Status.Error
          });
        } finally {
          // Always resolve promise in order for producer to return next file.
          resolve();
        }
      };
    });
  };

  const headerCheckboxEnabled = () => {
    return files.some((x) => !fileInProgress(x.id));
  };

  const filesReadyForUpload = () => {
    const allValid = files.every((x) => fileValid(x.id));
    return files.length > 0 && allValid && filesNotInProgress();
  };

  const fileInProgress = (id: string) => {
    const file = files.find((x) => x.id === id);
    return file?.progress && inProgressStatuses.includes(file.progress.status);
  };

  const filesNotInProgress = () => {
    return files.some((x) => !fileInProgress(x.id));
  };

  const filesInProgress = () => {
    return files.some((x) => fileInProgress(x.id));
  };

  const fileValid = (id: string): boolean => {
    const file = files.find((x) => x.id === id);
    if (!file) {
      return false;
    }

    if (categoryColumnVisible) {
      const subTypeRequired = FileService.resolveSubTypeDropdownItems(file.type).length > 0;
      return subTypeRequired ? file.type !== undefined && file.subType !== undefined : file.type !== undefined;
    }

    return true;
  };

  const getUniqueTypesForSelectedFiles = () => {
    return [...new Set(files.filter((x) => x.checked).map((x) => x.type))];
  };

  const getTopMessage = () => {
    if (files.length === 0 && uploadedIdList.length === 0) {
      return <Message warning>{t('files:noFilesHaveBeenChosen')}</Message>;
    }

    if (files.length === 0 && uploadedIdList.length > 0) {
      return (
        <Message success>
          <Trans i18nKey="files:filesUploadedSuccessMessage" t={t} count={uploadedIdList.length}>
            Sammanlagt {{ count: uploadedIdList.length }} filer har laddats upp.
            <br />
            <br />
            Nu kan du stänga dialogen.
          </Trans>
        </Message>
      );
    }

    const errorFiles = files.filter((x) => x.progress?.status === Status.Error);
    if (errorFiles.length > 0) {
      return (
        <Message error>
          <Trans i18nKey="files:filesUploadedErrorMessage" t={t} count={errorFiles.length}>
            {{ count: errorFiles.length }} filer har misslyckats.
            <br />
            Du kan försöka att ladda upp igen genom att klicka på "Starta uppladdning" eller på ikonen i statusfältet.
          </Trans>
        </Message>
      );
    }
  };

  const getCategoryLayout = (file: FileItem) => {
    const subTypeDropdownItems = FileService.resolveSubTypeDropdownItems(file.type);
    const canChooseCategory = props.entityType === 'PROPERTY';

    return (
      <div className="category-cell">
        {!fileValid(file.id) && <Icon name="exclamation circle" className="clear-icon" />}

        <div className="type-dropdowns">
          {canChooseCategory && (
            <ModalDropdownRenderer>
              <Dropdown
                search
                floating
                disabled={fileInProgress(file.id)}
                value={file.type}
                placeholder={t('common:selectCategory')}
                noResultsMessage={t('files:noMatchingCategoriesFound')}
                options={FileService.resolveTypeDropdownItems()}
                onChange={(e, d) =>
                  dispatchFiles({
                    type: 'SET_TYPE',
                    id: file.id,
                    fileType: d.value as FileType
                  })
                }
              />
            </ModalDropdownRenderer>
          )}

          {file.type && subTypeDropdownItems.length > 0 && (
            <ModalDropdownRenderer>
              <Dropdown
                search
                floating
                disabled={fileInProgress(file.id)}
                value={file.subType}
                placeholder={t('files:chooseSubCategory')}
                noResultsMessage={t('files:noMatchingCategoriesFound')}
                options={subTypeDropdownItems}
                onChange={(e, d) =>
                  dispatchFiles({
                    type: 'SET_SUB_TYPE',
                    id: file.id,
                    fileSubType: d.value as FileSubType
                  })
                }
              />
            </ModalDropdownRenderer>
          )}
        </div>
      </div>
    );
  };

  const layoutForm = (
    <div id="fileUploadModal">
      <FileDropzone onDrop={(files) => fileChangeHandler(files)} />
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              onClick={() =>
                dispatchFiles({
                  type: 'HEADER_CHECKBOX_CLICKED'
                })
              }
              collapsing
            >
              <Checkbox
                checked={files.length > 0 && files.every((x) => x.checked)}
                disabled={!headerCheckboxEnabled()}
                indeterminate={files.some((x) => x.checked) && !files.every((x) => x.checked)}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatchFiles({
                    type: 'HEADER_CHECKBOX_CLICKED'
                  });
                }}
              />
            </Table.HeaderCell>
            {categoryColumnVisible && <Table.HeaderCell width={5}>{t('common:category')}</Table.HeaderCell>}
            <Table.HeaderCell>{t('files:fileName')}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{t('files:size')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {buildStatusCellContent(
                <span>{t('files:status')}</span>,
                'cancel',
                t('files:cancelAll'),
                filesInProgress,
                () =>
                  dispatchFiles({
                    type: 'CANCEL_ALL'
                  })
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {files.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan="5">{getTopMessage()}</Table.Cell>
            </Table.Row>
          ) : (
            files.map((file) => {
              return (
                <Table.Row key={file.id} active={file.checked}>
                  <Table.Cell
                    className={!fileInProgress(file.id) ? 'checkbox-cell' : ''}
                    disabled={fileInProgress(file.id)}
                    onClick={() => {
                      dispatchFiles({
                        type: 'CHECKBOX_CLICKED',
                        id: file.id
                      });
                    }}
                  >
                    <Checkbox
                      checked={file.checked}
                      disabled={fileInProgress(file.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        dispatchFiles({
                          type: 'CHECKBOX_CLICKED',
                          id: file.id
                        });
                      }}
                    />
                  </Table.Cell>
                  {categoryColumnVisible && <Table.Cell>{getCategoryLayout(file)}</Table.Cell>}
                  <Table.Cell style={{ wordBreak: 'break-all' }}>
                    <Icon name={resolveIconFromFilename(file.name)} className="clear-icon" size="large" />
                    {file.name}
                  </Table.Cell>
                  <Table.Cell>{fileSize(file.fileSize)}</Table.Cell>
                  <Table.Cell>
                    <FileUploadProgress
                      progress={file.progress}
                      cancelUpload={() =>
                        dispatchFiles({
                          type: 'CANCEL',
                          id: file.id
                        })
                      }
                      resetProgress={() => {
                        dispatchFiles({
                          type: 'ADD_TO_UPLOAD_QUEUE',
                          id: file.id
                        });
                      }}
                      iconVisible={fileValid(file.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })
          )}
        </Table.Body>
      </Table>
    </div>
  );

  const getLayout = () => {
    const selectedUniqueTypes = getUniqueTypesForSelectedFiles();
    return (
      <>
        {layoutForm}
        {typesForSelectedVisible && (
          <FileUploadSelectTypes
            defaultType={selectedUniqueTypes.length === 1 ? selectedUniqueTypes[0] : undefined}
            cancel={() => setTypesForSelectedVisible(false)}
            close={(type: FileType, subType?: FileSubType) => {
              dispatchFiles({
                type: 'SET_TYPES_TO_ALL',
                fileType: type,
                fileSubType: subType
              });
              setTypesForSelectedVisible(false);
            }}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      closeIcon={!filesInProgress()}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size={'large'}
      open
      onClose={() => props.onClose(uploadedIdList)}
    >
      <Modal.Header>{t('files:fileUpload')}</Modal.Header>
      <Modal.Content scrolling>{getLayout()}</Modal.Content>

      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button
              disabled={!files.some((x) => x.checked)}
              secondary
              onClick={() =>
                dispatchFiles({
                  type: 'REMOVE_CHECKED'
                })
              }
            >
              {t('files:removeSelectedFiles')}
            </Button>

            {categoryColumnVisible && (
              <Button
                disabled={!files.some((x) => x.checked)}
                onClick={() => setTypesForSelectedVisible(true)}
                secondary
              >
                {t('files:selectCategoryForSelectedFiles')}
              </Button>
            )}
          </div>
          <Button
            primary
            disabled={!filesReadyForUpload()}
            onClick={() =>
              dispatchFiles({
                type: 'ADD_ALL_TO_UPLOAD_QUEUE'
              })
            }
          >
            {t('files:startUpload')}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default FileUploadModal;
