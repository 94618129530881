/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserUpdateSelfView
 */
export interface UserUpdateSelfView {
  /**
   * Full name.
   * @type {string}
   * @memberof UserUpdateSelfView
   */
  fullName?: string;
  /**
   * Preferred language setting.
   * @type {string}
   * @memberof UserUpdateSelfView
   */
  languagePreference?: UserUpdateSelfViewLanguagePreferenceEnum;
  /**
   * Password.
   * @type {string}
   * @memberof UserUpdateSelfView
   * @deprecated
   */
  password?: string;
  /**
   * Contact phone number.
   * @type {string}
   * @memberof UserUpdateSelfView
   */
  phone?: string;
}

export const UserUpdateSelfViewLanguagePreferenceEnum = {
  Sv: 'SV',
  En: 'EN'
} as const;

export type UserUpdateSelfViewLanguagePreferenceEnum =
  (typeof UserUpdateSelfViewLanguagePreferenceEnum)[keyof typeof UserUpdateSelfViewLanguagePreferenceEnum];
