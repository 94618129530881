import React from 'react';
import { useMutationObserver } from '../Utils/hooks';

interface ListBox {
  element: HTMLElement;
  listElement: HTMLElement;
}
interface Props {}

const isScrollable = (element: HTMLElement) => {
  const hasScrollableContent = element.scrollHeight > element.clientHeight;
  const overflowYStyle = window.getComputedStyle(element).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;
  return hasScrollableContent && !isOverflowHidden;
};

// https://htmldom.dev/get-the-first-scrollable-parent-of-an-element/
const getScrollableParent = (element: HTMLElement | null): HTMLElement => {
  return !element || element === document.body
    ? document.body
    : isScrollable(element)
    ? element
    : getScrollableParent(element.parentElement);
};

/**
 * Opening a dropdown inside a dialog that has props "scrolling", the dropdown
 * items container is rendered inside the modal container.
 *
 * This component listens for change in attribute "class", to see whether "visible"
 * has been added to the dropdown component ("visible" is set to both [role=combobox]
 * (header container) and [role=listbox] (items container) when dropdown is opened).
 *
 * If class "visible" has been added, the items container is positioned using "fixed"
 * and repositioned relative the viewport, with x/y values returned from getBoundingClientRect
 * of the header container.
 *
 * The items container will be rendered either above or below the header container
 * depending on available space left.
 *
 * todo: Items container is not repositioned when header container is repositioned
 * todo: after parent container scroll / window resize.
 */
const ModalDropdownRenderer: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const ref = React.useRef(null);
  const scrollableParentRef = React.useRef<HTMLElement>();
  const [opened, setOpened] = React.useState(false);

  const scrollHandler = React.useCallback(() => {
    scrollableParentRef.current?.click();
  }, []);

  React.useEffect(() => {
    const scrollableParent = getScrollableParent(ref.current);
    scrollableParentRef.current = scrollableParent;

    // Setup scroll listener for nearest scrollable parent.
    // When scrolled, click on the parent to close dropdown.
    if (opened) {
      scrollableParent.addEventListener('scroll', scrollHandler);
    } else {
      scrollableParent.removeEventListener('scroll', scrollHandler);
    }
  }, [opened, scrollHandler]);

  const handleMutations = React.useCallback<MutationCallback>(
    (mutations) => {
      let listbox: ListBox | undefined = undefined;

      // Fetch DOM element (header) and list element.
      for (const mutation of mutations) {
        let targetElement = mutation.target as Element;

        // Trigger only when attribute "class" has new va
        if (mutation.attributeName === 'class' && targetElement.className.includes('visible')) {
          // Dropdown "header" item.
          if (targetElement.getAttribute('role') === 'combobox') {
            listbox = {
              ...listbox,
              element: targetElement
            } as ListBox;
          }

          // Listbox with dropdown items.
          if (targetElement.getAttribute('role') === 'listbox') {
            listbox = {
              ...listbox,
              listElement: targetElement
            } as ListBox;
          }
        }
      }

      if (!opened && listbox) {
        setOpened(true);
      } else if (opened && !listbox) {
        setOpened(false);
      }

      // If listbox data has been set, dropdown is opened.
      if (listbox) {
        const viewportRect = document.documentElement.getBoundingClientRect();
        const rect = listbox.element.getBoundingClientRect();

        let css = ['position: fixed', 'min-width: auto !important', 'height: min-content', `left: ${rect.left}px`];

        const spaceAbove = rect.top;
        const spaceBelow = viewportRect.height - rect.top;
        const padding = 20;

        // Position either above or below.
        if (spaceAbove > spaceBelow) {
          const bottom = viewportRect.height - rect.top + padding;
          const maxListHeight = rect.top - 2 * padding;

          css = [...css, `bottom: ${bottom}px`, `top: auto`, `max-height: ${maxListHeight}px`];
        } else {
          const top = rect.top + rect.height;
          const maxListHeight = viewportRect.height - top - padding;

          css = [...css, `top: ${top}px`, `max-height: ${maxListHeight}px`];
        }

        listbox.listElement.setAttribute('style', css.join(';'));
      }
    },
    [opened]
  );

  // Start mutation observer for the wrapper element.
  useMutationObserver({
    target: ref,
    options: { attributes: true, subtree: true },
    callback: handleMutations
  });

  return (
    <div className="modal-dropdown-renderer" ref={ref}>
      {React.Children.only(props.children)}
    </div>
  );
};

export default React.memo(ModalDropdownRenderer);
