/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { SearchReadView } from '../model';
// @ts-ignore
import { TenantListView } from '../model';
// @ts-ignore
import { TenantReadView } from '../model';
/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchResourceList: async (
      query: string,
      limit: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchResourceList', 'query', query);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('searchResourceList', 'limit', limit);
      const localVarPath = `/v1/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List tenants
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [ongoingPropertyId] Filter by the presence of an ongoing contract for the property
     * @param {string} [search] Filter by search term
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [unitId] Filter by the presence of a contract for the unit
     * @param {number} [ongoingContractId] Filter by a contract
     * @param {Array<number>} [organisationIds] Filter by a list of organisations.
     * @param {Array<number>} [ongoingPropertyIds] Filter by the presence of an ongoing contract for a list of properties
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      ongoingPropertyId?: number,
      search?: string,
      portfolioId?: number,
      unitId?: number,
      ongoingContractId?: number,
      organisationIds?: Array<number>,
      ongoingPropertyIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/tenants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (ongoingPropertyId !== undefined) {
        localVarQueryParameter['ongoingPropertyId'] = ongoingPropertyId;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (unitId !== undefined) {
        localVarQueryParameter['unitId'] = unitId;
      }

      if (ongoingContractId !== undefined) {
        localVarQueryParameter['ongoingContractId'] = ongoingContractId;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (ongoingPropertyIds) {
        localVarQueryParameter['ongoingPropertyIds'] = ongoingPropertyIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a tenant
     * @param {number} id Tenant ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tenantResourceRead', 'id', id);
      const localVarPath = `/v1/tenants/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchResourceList(
      query: string,
      limit: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchResourceList(query, limit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List tenants
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [ongoingPropertyId] Filter by the presence of an ongoing contract for the property
     * @param {string} [search] Filter by search term
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [unitId] Filter by the presence of a contract for the unit
     * @param {number} [ongoingContractId] Filter by a contract
     * @param {Array<number>} [organisationIds] Filter by a list of organisations.
     * @param {Array<number>} [ongoingPropertyIds] Filter by the presence of an ongoing contract for a list of properties
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      ongoingPropertyId?: number,
      search?: string,
      portfolioId?: number,
      unitId?: number,
      ongoingContractId?: number,
      organisationIds?: Array<number>,
      ongoingPropertyIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantResourceList(
        limit,
        offset,
        organisationId,
        ongoingPropertyId,
        search,
        portfolioId,
        unitId,
        ongoingContractId,
        organisationIds,
        ongoingPropertyIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a tenant
     * @param {number} id Tenant ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TenantApiFp(configuration);
  return {
    /**
     *
     * @summary Search for things
     * @param {string} query Search term
     * @param {number} limit Maximum amount of items per kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchResourceList(query: string, limit: number, options?: any): AxiosPromise<SearchReadView> {
      return localVarFp.searchResourceList(query, limit, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List tenants
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [ongoingPropertyId] Filter by the presence of an ongoing contract for the property
     * @param {string} [search] Filter by search term
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [unitId] Filter by the presence of a contract for the unit
     * @param {number} [ongoingContractId] Filter by a contract
     * @param {Array<number>} [organisationIds] Filter by a list of organisations.
     * @param {Array<number>} [ongoingPropertyIds] Filter by the presence of an ongoing contract for a list of properties
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      ongoingPropertyId?: number,
      search?: string,
      portfolioId?: number,
      unitId?: number,
      ongoingContractId?: number,
      organisationIds?: Array<number>,
      ongoingPropertyIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<TenantListView> {
      return localVarFp
        .tenantResourceList(
          limit,
          offset,
          organisationId,
          ongoingPropertyId,
          search,
          portfolioId,
          unitId,
          ongoingContractId,
          organisationIds,
          ongoingPropertyIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a tenant
     * @param {number} id Tenant ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantResourceRead(id: number, options?: any): AxiosPromise<TenantReadView> {
      return localVarFp.tenantResourceRead(id, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for searchResourceList operation in TenantApi.
 * @export
 * @interface TenantApiSearchResourceListRequest
 */
export interface TenantApiSearchResourceListRequest {
  /**
   * Search term
   * @type {string}
   * @memberof TenantApiSearchResourceList
   */
  readonly query: string;

  /**
   * Maximum amount of items per kind
   * @type {number}
   * @memberof TenantApiSearchResourceList
   */
  readonly limit: number;
}

/**
 * Request parameters for tenantResourceList operation in TenantApi.
 * @export
 * @interface TenantApiTenantResourceListRequest
 */
export interface TenantApiTenantResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof TenantApiTenantResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof TenantApiTenantResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof TenantApiTenantResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by the presence of an ongoing contract for the property
   * @type {number}
   * @memberof TenantApiTenantResourceList
   */
  readonly ongoingPropertyId?: number;

  /**
   * Filter by search term
   * @type {string}
   * @memberof TenantApiTenantResourceList
   */
  readonly search?: string;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof TenantApiTenantResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by the presence of a contract for the unit
   * @type {number}
   * @memberof TenantApiTenantResourceList
   */
  readonly unitId?: number;

  /**
   * Filter by a contract
   * @type {number}
   * @memberof TenantApiTenantResourceList
   */
  readonly ongoingContractId?: number;

  /**
   * Filter by a list of organisations.
   * @type {Array<number>}
   * @memberof TenantApiTenantResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by the presence of an ongoing contract for a list of properties
   * @type {Array<number>}
   * @memberof TenantApiTenantResourceList
   */
  readonly ongoingPropertyIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof TenantApiTenantResourceList
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for tenantResourceRead operation in TenantApi.
 * @export
 * @interface TenantApiTenantResourceReadRequest
 */
export interface TenantApiTenantResourceReadRequest {
  /**
   * Tenant ID
   * @type {number}
   * @memberof TenantApiTenantResourceRead
   */
  readonly id: number;
}

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
  /**
   *
   * @summary Search for things
   * @param {TenantApiSearchResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public searchResourceList(requestParameters: TenantApiSearchResourceListRequest, options?: AxiosRequestConfig) {
    return TenantApiFp(this.configuration)
      .searchResourceList(requestParameters.query, requestParameters.limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List tenants
   * @param {TenantApiTenantResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantResourceList(requestParameters: TenantApiTenantResourceListRequest = {}, options?: AxiosRequestConfig) {
    return TenantApiFp(this.configuration)
      .tenantResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.ongoingPropertyId,
        requestParameters.search,
        requestParameters.portfolioId,
        requestParameters.unitId,
        requestParameters.ongoingContractId,
        requestParameters.organisationIds,
        requestParameters.ongoingPropertyIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a tenant
   * @param {TenantApiTenantResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantResourceRead(requestParameters: TenantApiTenantResourceReadRequest, options?: AxiosRequestConfig) {
    return TenantApiFp(this.configuration)
      .tenantResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
