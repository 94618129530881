import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Popup, Table } from 'semantic-ui-react';
import { EnergyReportListView } from '../../../GeneratedServices';
import { sortObjects } from '../../../Utils/array';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { useDidMountEffect, useNonce } from '../../../Utils/hooks';
import { getComparedPercentage } from '../../../Utils/number';
import NumberFormater from '../../NumberFormater/NumberFormater';
import { PercentageComparisonRow } from '../../FinancialStatistics/Components/FinancialStatisticsTable';
import SortableHeaderCell from '../../SortableTable/Components/SortableHeaderCell';
import SortableTable, { SortDirection } from '../../SortableTable/SortableTable';
import TableHeaderCellContent from '../../TableHeaderCell/TableHeaderCell';
import styles from './EnergyReportTable.module.scss';

interface Props {
  energyReport: EnergyReportListView;
  periods: string[];
  granularity: 'YEARLY' | 'QUARTERLY' | 'MONTHLY';
  unit: string;
}

enum CommonColumnKey {
  propertyName = 'name',
  total = 'totalSelected'
}

const isFirstElement = (index: number) => {
  return index === 0;
};

interface EnergyReportPropertyReadView {
  id: number;
  name: string;
  periods: EnergyReportPeriodReadView[];
}

interface EnergyReportPeriodReadView {
  value?: number;
  period: string;
}

interface EnergyReportReadView {
  properties: EnergyReportPropertyReadView[];
}

const generateEnergyReportPropertiesWithTotals = (
  periods: string[],
  energyReport: EnergyReportReadView
): (EnergyReportPropertyReadView & { totalSelected: number })[] => {
  if (energyReport) {
    return energyReport.properties.map((property) => {
      let totalSum: number = 0;
      periods.forEach((item, i) => {
        const foundPeriod = property.periods.find((propertyPeriod) => propertyPeriod.period === item);
        totalSum += foundPeriod?.value ?? 0;
      });

      return {
        ...property,
        totalSelected: totalSum
      };
    });
  } else return [];
};

const sortPropertiesEnergyReports = (
  properties: (EnergyReportPropertyReadView & { totalSelected: number })[],
  sortSettings: {
    columnKey?: string | undefined;
    sortDirection?: SortDirection | undefined;
  }
) => {
  const index =
    properties.length !== 0 && properties[0].periods.findIndex((period) => period.period === sortSettings.columnKey);
  if (sortSettings.columnKey && index > -1) {
    return sortObjects(properties, `periods[${index}].value`, sortSettings.sortDirection);
  }
  switch (sortSettings.columnKey) {
    case CommonColumnKey.propertyName:
    case CommonColumnKey.total:
      return sortObjects(properties, sortSettings.columnKey, sortSettings.sortDirection);

    default:
      return properties;
  }
};

const EnergyReportTable: React.FC<Props> = (props) => {
  const { t } = useTranslation(['energyReport', 'common']);

  const [sortSettings, setSortSettings] = React.useState<{
    columnKey?: string | undefined;
    sortDirection?: SortDirection | undefined;
  }>({
    columnKey: undefined,
    sortDirection: undefined
  });

  const formattedEnergyReport: EnergyReportReadView = React.useMemo(() => {
    if (props.energyReport.records.length !== 0) {
      const properties = props.energyReport.records[0].byProperty.map((item) => ({
        name: item.name,
        id: item.propertyId
      }));

      return {
        properties: properties.map((item) => {
          return {
            id: item.id,
            name: item.name,
            periods: props.energyReport.periods.map((period, index) => {
              const propertyDataForPeriod = props.energyReport.records[index].byProperty.find(
                (property) => property.propertyId === item.id
              );

              return {
                period,
                value: propertyDataForPeriod?.value
              };
            })
          };
        })
      };
    } else
      return {
        properties: []
      };
  }, [props.energyReport]);

  const [shouldResetSort, resetSort] = useNonce();

  useDidMountEffect(() => {
    resetSort();
    setSortSettings({
      columnKey: undefined,
      sortDirection: undefined
    });
  }, [props.granularity]);

  const propertiesWithTotals = React.useMemo(() => {
    return sortPropertiesEnergyReports(
      generateEnergyReportPropertiesWithTotals(props.periods, formattedEnergyReport),
      sortSettings
    );
  }, [props.periods, formattedEnergyReport, sortSettings]);

  const handleSortChange = (columnKey?: string | undefined, sortDirection?: SortDirection | undefined) => {
    setSortSettings({
      columnKey,
      sortDirection
    });
  };

  const selectedPeriods = props.energyReport.periods.filter((item) => props.periods.includes(item));

  if (props.periods.length === 0) {
    return <Message>{t('common:noPeriodsChosen')}</Message>;
  } else
    return (
      <div className={styles.tableWrapper}>
        <SortableTable
          handleSort={handleSortChange}
          additionalTableProps={{ singleLine: true }}
          shouldResetSort={shouldResetSort}
        >
          <Table.Header>
            <Table.Row>
              <SortableHeaderCell name={CommonColumnKey.propertyName}>{t('common:property')}</SortableHeaderCell>

              {selectedPeriods.map((item, i) => (
                <React.Fragment key={item}>
                  <SortableHeaderCell name={item} singleLine textAlign="center">
                    <TableHeaderCellContent header={item} extra={props.unit} />
                  </SortableHeaderCell>
                </React.Fragment>
              ))}

              <SortableHeaderCell name={CommonColumnKey.total} collapsing textAlign="right">
                <TableHeaderCellContent header={'Total'} extra={props.unit} />
              </SortableHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {propertiesWithTotals.map((item) => (
              <TableRow property={item} periods={selectedPeriods} key={item.id} />
            ))}
          </Table.Body>
        </SortableTable>
      </div>
    );
};

const TableRow = ({
  property,
  periods
}: {
  property: EnergyReportPropertyReadView & { totalSelected: number };
  periods: string[];
}) => {
  let previousValue: number | undefined = undefined;

  const valueCells = periods.map((item, i) => {
    const foundPeriod = property.periods.find((propertyPeriod) => propertyPeriod.period === item);
    const comparedToPrevious = foundPeriod?.value
      ? getComparedPercentage(foundPeriod.value, previousValue ?? 0)
      : undefined;

    let cell = (
      <React.Fragment key={i}>
        <Popup
          position="top center"
          hoverable
          trigger={
            <Table.Cell collapsing textAlign="center">
              <NumberFormater value={foundPeriod?.value} />
            </Table.Cell>
          }
          disabled={isFirstElement(i) || !comparedToPrevious}
          content={
            <PercentageComparisonRow
              color={comparedToPrevious && comparedToPrevious < 0 ? colors.green : colors.red}
              icon={comparedToPrevious && comparedToPrevious > 0 ? faArrowUp : faArrowDown}
              value={
                <NumberFormater
                  value={comparedToPrevious}
                  suffix={`% vs ${periods[i - 1]}`}
                  additionalProps={{ decimalScale: 1 }}
                />
              }
            />
          }
        />
      </React.Fragment>
    );

    previousValue = foundPeriod?.value;
    return cell;
  });

  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/properties/${property.id}`}>{property.name}</Link>
      </Table.Cell>

      {valueCells}

      <Table.Cell style={{ fontWeight: '400' }} textAlign="right">
        <NumberFormater value={property.totalSelected} />
      </Table.Cell>
    </Table.Row>
  );
};

export default EnergyReportTable;
