import React from 'react';
import { Card, Header, Segment, Image, Label } from 'semantic-ui-react';
import styles from './PropertyCard.module.scss';
import PlaceHolderImage from './images/placeholder.png';
import { Link } from 'react-router-dom';

interface Props {
  onClick?: () => void;
  name: string;
  pictureUrl?: string;
  imageHeight?: number;
  imageWidth?: number;
  labels?: JSX.Element;
  content?: JSX.Element;
  extra?: JSX.Element;
  href?: string;
  compact?: boolean;
}

const PropertyCard: React.FC<Props> = (props) => {
  const content = (
    <>
      <Segment className={styles.imageContainer} clearing basic>
        <Header as={props.compact ? 'h5' : 'h4'} className={styles.cardHeader} inverted>
          {props.name}
        </Header>

        <Image
          src={props.pictureUrl ?? PlaceHolderImage}
          alt=""
          className={styles.image}
          style={{
            height: props.imageHeight ?? 170,
            borderRadius: !props.content ? '5px' : undefined
          }}
        />

        <Label.Group className={styles.labelsContainer}>{props.labels}</Label.Group>
      </Segment>
      {props.content && (
        <Card.Content {...(props.compact && { className: styles.compactContent })}>
          <Card.Description>{props.content}</Card.Description>
        </Card.Content>
      )}
      {props.extra && (
        <Card.Content extra {...(props.compact && { className: styles.compactExtra })}>
          {props.extra}
        </Card.Content>
      )}
    </>
  );
  return (
    <Card
      fluid
      as={props.href ? Link : undefined}
      to={props.href}
      className={!props.imageWidth ? styles.cardContainer : undefined}
      onClick={props.href ? () => {} : props.onClick}
      title={props.name}
      style={{ width: props.imageWidth, minWidth: props.imageWidth }}
    >
      {content}
    </Card>
  );
};

export default PropertyCard;
