import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message, Table } from 'semantic-ui-react';
import { MembershipReadViewRoleEnum, PropertySubscriptionReadView } from '../../../../GeneratedServices';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { propertySubscriptionApi } from '../../../../Http/Http';
import { canManipulateSubscription, Role, translateRole } from '../../../../Utils/permissions';
import DeleteSubscriptionConfirmationModal from './DeleteSubscriptionConfirmationModal';

interface Props {
  subscriptions: PropertySubscriptionReadView[];
  reloadSubscriptions: () => void;
  organisationId: number;
}

enum SubscriptionStatus {
  Deleting,
  None,
  Awaiting_Confirmation
}

export type PropertySubscriptionEntity = PropertySubscriptionReadView & {
  status: SubscriptionStatus;
};

type PropertySubscriptionsState = PropertySubscriptionEntity[];
type PropertySubscriptionsAction =
  | { type: 'INIT'; data: PropertySubscriptionsState }
  | {
      type: 'STATUS_CHANGE';
      id: number;
      status: SubscriptionStatus;
    };

export function propertySubscriptionReducer(
  state: PropertySubscriptionsState,
  action: PropertySubscriptionsAction
): PropertySubscriptionsState {
  switch (action.type) {
    case 'INIT':
      return action.data;
    case 'STATUS_CHANGE':
      return state.map((item) =>
        item.id === action.id
          ? {
              ...item,
              status: action.status
            }
          : item
      );
  }
}

const SubscriptionsTab: React.FC<Props> = (props) => {
  const { t } = useTranslation(['users', 'common']);

  const navigate = useNavigate();

  const { currentUser, currentUserMemberships, handleHttpErrors } = React.useContext(globalStateCTX);
  const [propertySubscriptions, dispatchPropertySubscriptions] = React.useReducer(propertySubscriptionReducer, []);

  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    dispatchPropertySubscriptions({
      type: 'INIT',
      data: props.subscriptions.map((item) => ({ ...item, status: SubscriptionStatus.None }))
    });
  }, [props.subscriptions]);

  const handleDelete = async (item: PropertySubscriptionEntity) => {
    if (item.role === Role.Technician) {
      dispatchPropertySubscriptions({
        type: 'STATUS_CHANGE',
        id: item.id,
        status: SubscriptionStatus.Awaiting_Confirmation
      });
      setDeleteConfirmationModalOpen(true);
    } else {
      await deleteSubscription(item);
    }
  };

  const deleteSubscription = async (item: PropertySubscriptionReadView) => {
    try {
      dispatchPropertySubscriptions({
        type: 'STATUS_CHANGE',
        id: item.id,
        status: SubscriptionStatus.Deleting
      });

      await propertySubscriptionApi.propertySubscriptionResourceDelete({
        id: item.id
      });

      handleSubscriptionDeleted(item);
    } catch (e) {
      handleHttpErrors(e);
      dispatchPropertySubscriptions({
        type: 'STATUS_CHANGE',
        id: item.id,
        status: SubscriptionStatus.None
      });
    }
  };

  const handleSubscriptionDeleted = (item: PropertySubscriptionReadView) => {
    if (item.userId === currentUser?.id && item.role === MembershipReadViewRoleEnum.Technician) {
      navigate('/properties');
    } else {
      props.reloadSubscriptions();
      dispatchPropertySubscriptions({
        type: 'STATUS_CHANGE',
        id: item.id,
        status: SubscriptionStatus.None
      });
    }
  };

  const subscriptionToDelete = propertySubscriptions.find(
    (item) => item.status === SubscriptionStatus.Awaiting_Confirmation
  );
  return (
    <>
      {propertySubscriptions.length !== 0 ? (
        <Table basic="very" singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common:name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:email')}</Table.HeaderCell>
              <Table.HeaderCell>{t('users:role')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {propertySubscriptions.map((item) => (
              <Table.Row key={item.userId}>
                <Table.Cell>
                  <Link to={`/users/${item.userId}`}>{item.name}</Link>
                </Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
                <Table.Cell>{translateRole(item.role as Role)}</Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    negative
                    style={{
                      visibility: canManipulateSubscription(
                        item,
                        props.organisationId,
                        currentUserMemberships,
                        currentUser
                      )
                        ? undefined
                        : 'hidden'
                    }}
                    disabled={item.status === SubscriptionStatus.Deleting}
                    loading={item.status === SubscriptionStatus.Deleting}
                    onClick={() => handleDelete(item)}
                  >
                    {t('common:remove')}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Message>{t('common:noFollowers')}</Message>
      )}

      {isDeleteConfirmationModalOpen && subscriptionToDelete && (
        <DeleteSubscriptionConfirmationModal
          onClose={() => {
            dispatchPropertySubscriptions({
              type: 'STATUS_CHANGE',
              id: subscriptionToDelete.id,
              status: SubscriptionStatus.None
            });
          }}
          isDeleting={subscriptionToDelete.status === SubscriptionStatus.Deleting}
          deleteSubscription={deleteSubscription}
          subscription={subscriptionToDelete}
        />
      )}
    </>
  );
};

export default SubscriptionsTab;
