import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import Logotype from '../../images/logotype.png';
import SearchBar from './Components/SearchBar';
import NotificationMenuItem from './Components/NotificationMenuItem';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { hasRoleMatch, Role } from '../../Utils/permissions';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from './Components/LanguageDropdown';
interface Props {
  currentUser: string;
  handleLogOut: () => void;
}

const TopMenu: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const navigate = useNavigate();

  const { currentUser, currentUserMemberships } = React.useContext(globalStateCTX);

  const shouldShowSearchBar = hasRoleMatch(
    [Role.Admin, Role.Manager, Role.Technician],
    currentUser,
    currentUserMemberships
  );

  return (
    <>
      <Menu as="header" id="topMenu" borderless inverted>
        <Menu.Item header>
          <img src={Logotype} alt="GEM" />
          <div id="appName">GEM</div>
        </Menu.Item>
        <Menu.Item className={'search-menu-item'}>{shouldShowSearchBar && <SearchBar />}</Menu.Item>
        <Menu.Menu position="right">
          <LanguageDropdown />
          <NotificationMenuItem />
          <Dropdown item text={props.currentUser} className="profile-dropdown">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={'/my-profile'}>
                <Icon name="user" /> {t('myProfile')}
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item as={Link} to={'/privacy-policy'}>
                <Icon name="file alternate" /> {t('privacyPolicy')}
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item
                onClick={() => {
                  props.handleLogOut();
                  navigate('/');
                }}
              >
                <Icon name="log out" /> {t('logOut')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    </>
  );
};

export default TopMenu;
