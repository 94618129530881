/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { EmbeddedContractBusinessPlanLeaseReadView } from './embedded-contract-business-plan-lease-read-view';
import { EmbeddedContractPropertyReadView } from './embedded-contract-property-read-view';
import { EmbeddedContractTenantReadView } from './embedded-contract-tenant-read-view';

/**
 *
 * @export
 * @interface ContractListItemView
 */
export interface ContractListItemView {
  /**
   * Additionals. (Öre)
   * @type {number}
   * @memberof ContractListItemView
   */
  additionals: number | null;
  /**
   * Indexed additionals. (Öre)
   * @type {number}
   * @memberof ContractListItemView
   */
  additionalsIndexed: number | null;
  /**
   * Area (both indoor and outdoor) in m².
   * @type {number}
   * @memberof ContractListItemView
   */
  area: number;
  /**
   * Base rent of the lease. (Öre)
   * @type {number}
   * @memberof ContractListItemView
   */
  baseRent: number | null;
  /**
   * Indexed base rent of the lease. (Öre)
   * @type {number}
   * @memberof ContractListItemView
   */
  baseRentIndexed: number | null;
  /**
   *
   * @type {EmbeddedContractBusinessPlanLeaseReadView}
   * @memberof ContractListItemView
   */
  businessPlanLease: EmbeddedContractBusinessPlanLeaseReadView | null;
  /**
   * End date.
   * @type {string}
   * @memberof ContractListItemView
   * @deprecated
   */
  end: string | null;
  /**
   * The end date of the contract. Only applies to commercial contracts.
   * @type {string}
   * @memberof ContractListItemView
   */
  endDate: string | null;
  /**
   * Type of establishment.
   * @type {string}
   * @memberof ContractListItemView
   */
  establishmentType: string;
  /**
   * Period in months that the contract is renewed for.
   * @type {number}
   * @memberof ContractListItemView
   */
  extensionPeriod: number | null;
  /**
   * Contract ID.
   * @type {number}
   * @memberof ContractListItemView
   */
  id: number;
  /**
   * Area (only indoor) in m².
   * @type {number}
   * @memberof ContractListItemView
   */
  indoorArea: number;
  /**
   * Whether the lease is parked.
   * @type {boolean}
   * @memberof ContractListItemView
   */
  isParked: boolean;
  /**
   * Move-in date.
   * @type {string}
   * @memberof ContractListItemView
   */
  moveInDate: string;
  /**
   * Move-out date.
   * @type {string}
   * @memberof ContractListItemView
   */
  moveOutDate: string | null;
  /**
   * The negotiation deadline of the contract. Only populated if negotiationStatus = IN_PROGRESS (\'Uppsagningstyp\' = 1).
   * @type {string}
   * @memberof ContractListItemView
   */
  negotiationBy: string | null;
  /**
   * The object rented by the contract.
   * @type {number}
   * @memberof ContractListItemView
   */
  objectId: number;
  /**
   * Organisation ID.
   * @type {number}
   * @memberof ContractListItemView
   */
  organisationId: number;
  /**
   * Previous contract id.
   * @type {number}
   * @memberof ContractListItemView
   */
  previousContractId: number | null;
  /**
   * Previous contract indexed total rent.
   * @type {number}
   * @memberof ContractListItemView
   */
  previousContractTotalRentIndexed: number | null;
  /**
   * The primary index type.
   * @type {string}
   * @memberof ContractListItemView
   */
  primaryIndexType: string | null;
  /**
   *
   * @type {EmbeddedContractPropertyReadView}
   * @memberof ContractListItemView
   */
  property: EmbeddedContractPropertyReadView;
  /**
   * Property ID.
   * @type {number}
   * @memberof ContractListItemView
   */
  propertyId: number;
  /**
   * Rent rate in SEK/Year.
   * @type {number}
   * @memberof ContractListItemView
   * @deprecated
   */
  rate: number | null;
  /**
   * Rent reductions. (Öre)
   * @type {number}
   * @memberof ContractListItemView
   */
  reductionsIndexed: number | null;
  /**
   * Start date.
   * @type {string}
   * @memberof ContractListItemView
   * @deprecated
   */
  start: string;
  /**
   * The status of the contract.
   * @type {string}
   * @memberof ContractListItemView
   */
  status: ContractListItemViewStatusEnum;
  /**
   * Strifast object ID.
   * @type {number}
   * @memberof ContractListItemView
   */
  strifastObjectId: number;
  /**
   * Strifast property ID.
   * @type {number}
   * @memberof ContractListItemView
   */
  strifastPropertyId: number;
  /**
   *
   * @type {EmbeddedContractTenantReadView}
   * @memberof ContractListItemView
   */
  tenant: EmbeddedContractTenantReadView | null;
  /**
   * Tenant ID.
   * @type {number}
   * @memberof ContractListItemView
   */
  tenantId: number | null;
  /**
   * Number of tenants.
   * @type {number}
   * @memberof ContractListItemView
   */
  tenants: number;
  /**
   * Termination date (end date - termination period).
   * @type {string}
   * @memberof ContractListItemView
   */
  terminationDate: string | null;
  /**
   * Period in months before end before which re-negotiation must occur.
   * @type {number}
   * @memberof ContractListItemView
   */
  terminationPeriod: number;
  /**
   * Total rent. Total rent is base rent + additionals.
   * @type {number}
   * @memberof ContractListItemView
   */
  totalRent: number | null;
  /**
   * Indexed total rent. Total rent is base rent + additionals.
   * @type {number}
   * @memberof ContractListItemView
   */
  totalRentIndexed: number | null;
  /**
   * The type of the unit object.
   * @type {string}
   * @memberof ContractListItemView
   */
  unitObjectType: ContractListItemViewUnitObjectTypeEnum;
  /**
   * Upcoming contract id.
   * @type {number}
   * @memberof ContractListItemView
   */
  upcomingContractId: number | null;
  /**
   * Whether VAT is to be payed.
   * @type {boolean}
   * @memberof ContractListItemView
   */
  vat: boolean;
}

export const ContractListItemViewStatusEnum = {
  FutureMoveOutWithoutDate: 'FUTURE_MOVE_OUT_WITHOUT_DATE',
  VacantWithoutMoveOut: 'VACANT_WITHOUT_MOVE_OUT',
  VacantWithMoveOut: 'VACANT_WITH_MOVE_OUT',
  ChangeOfTerms: 'CHANGE_OF_TERMS',
  TenantChange: 'TENANT_CHANGE',
  FutureVacantWithMoveOut: 'FUTURE_VACANT_WITH_MOVE_OUT',
  MovedOut: 'MOVED_OUT',
  OngoingWithoutEnd: 'ONGOING_WITHOUT_END',
  ExtensionWithin90Days: 'EXTENSION_WITHIN_90_DAYS',
  Extension: 'EXTENSION',
  Negotiation: 'NEGOTIATION'
} as const;

export type ContractListItemViewStatusEnum =
  (typeof ContractListItemViewStatusEnum)[keyof typeof ContractListItemViewStatusEnum];
export const ContractListItemViewUnitObjectTypeEnum = {
  Apartment: 'APARTMENT',
  StudentApartment: 'STUDENT_APARTMENT',
  BusinessPremises: 'BUSINESS_PREMISES',
  Antenna: 'ANTENNA',
  BlockRental: 'BLOCK_RENTAL',
  BuildingRights: 'BUILDING_RIGHTS',
  Depot: 'DEPOT',
  CommunityFacility: 'COMMUNITY_FACILITY',
  Garage: 'GARAGE',
  House: 'HOUSE',
  IndustrialPremises: 'INDUSTRIAL_PREMISES',
  Parking: 'PARKING',
  DisplaySpace: 'DISPLAY_SPACE',
  Premises: 'PREMISES',
  NursingHome: 'NURSING_HOME',
  Storage: 'STORAGE',
  Land: 'LAND'
} as const;

export type ContractListItemViewUnitObjectTypeEnum =
  (typeof ContractListItemViewUnitObjectTypeEnum)[keyof typeof ContractListItemViewUnitObjectTypeEnum];
