import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import FilterLabel from '../FilterLabel';
import { ComparablePropertyReadViewCountryEnum } from '../../../GeneratedServices';
import { getMunicipalityOptions } from '../../../Services/ComparableService.types';

interface Props {
  country?: ComparablePropertyReadViewCountryEnum;
  selectedMunicipalities?: string[];
  setSelectedMunicipalities: (municipalities?: string[]) => void;
}

const MunicipalityDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common']);
  const { setSelectedMunicipalities, selectedMunicipalities, country } = props;

  return (
    <Dropdown
      placeholder={t('common:allMunicipalities')}
      value={selectedMunicipalities ?? []}
      renderLabel={(item, i, prop) => <FilterLabel text={item.text} labelProps={prop} />}
      multiple
      fluid
      search
      scrolling
      upward
      selection
      floating
      clearable
      options={getMunicipalityOptions(country ?? ComparablePropertyReadViewCountryEnum.Sweden)}
      onChange={(e, d) => {
        setSelectedMunicipalities(d.value as string[]);
      }}
    />
  );
};

export default MunicipalityDropdown;
