/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PointView } from './point-view';
import { PropertyStatisticsView } from './property-statistics-view';

/**
 *
 * @export
 * @interface PropertyReadView
 */
export interface PropertyReadView {
  /**
   * Property address.
   * @type {string}
   * @memberof PropertyReadView
   */
  address: string;
  /**
   * Property city.
   * @type {string}
   * @memberof PropertyReadView
   */
  city: string;
  /**
   * Owning Company ID.
   * @type {number}
   * @memberof PropertyReadView
   */
  companyId: number;
  /**
   * Amount of the work orders with FINISHED status associated with this property.
   * @type {number}
   * @memberof PropertyReadView
   */
  finishedWorkOrdersCount: number;
  /**
   * Property ID.
   * @type {number}
   * @memberof PropertyReadView
   */
  id: number;
  /**
   * Municipality.
   * @type {string}
   * @memberof PropertyReadView
   */
  municipality: string;
  /**
   * Name of property.
   * @type {string}
   * @memberof PropertyReadView
   */
  name: string;
  /**
   * Organisation Id.
   * @type {number}
   * @memberof PropertyReadView
   */
  organisationId: number;
  /**
   * File containing the main picture for the property.
   * @type {string}
   * @memberof PropertyReadView
   */
  pictureId: string | null;
  /**
   * Area in m².
   * @type {number}
   * @memberof PropertyReadView
   */
  plotArea: number;
  /**
   *
   * @type {PointView}
   * @memberof PropertyReadView
   */
  position: PointView | null;
  /**
   * Property postcode.
   * @type {string}
   * @memberof PropertyReadView
   */
  postcode: string;
  /**
   * Amount of the work orders with RECEIVED status associated with this property.
   * @type {number}
   * @memberof PropertyReadView
   */
  receivedWorkOrdersCount: number;
  /**
   * Amount of the work orders with STARTED status associated with this property.
   * @type {number}
   * @memberof PropertyReadView
   */
  startedWorkOrdersCount: number;
  /**
   *
   * @type {PropertyStatisticsView}
   * @memberof PropertyReadView
   */
  statistics: PropertyStatisticsView;
  /**
   * Street address.
   * @type {string}
   * @memberof PropertyReadView
   */
  streetAddress: string;
  /**
   * Property type.
   * @type {string}
   * @memberof PropertyReadView
   */
  type?: PropertyReadViewTypeEnum;
}

export const PropertyReadViewTypeEnum = {
  Retail: 'RETAIL',
  Other: 'OTHER',
  Office: 'OFFICE',
  Hotel: 'HOTEL',
  Residential: 'RESIDENTIAL',
  Logistics: 'LOGISTICS',
  Industrial: 'INDUSTRIAL',
  Parking: 'PARKING',
  BuildingRights: 'BUILDING_RIGHTS',
  Warehouse: 'WAREHOUSE',
  Public: 'PUBLIC'
} as const;

export type PropertyReadViewTypeEnum = (typeof PropertyReadViewTypeEnum)[keyof typeof PropertyReadViewTypeEnum];
