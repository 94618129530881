import { Bar } from '@nivo/bar';
import React from 'react';
import { EnergyReportListView } from '../../../GeneratedServices';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { useDidMountEffect, useDimensions } from '../../../Utils/hooks';
import { formatNumber } from '../../../Utils/number';
import styles from './EnergyReportBarGraph.module.scss';
import EnergyReportCustomTooltip from './EnergyReportCustomTooltip';

interface Props {
  energyReport: EnergyReportListView;
  onBarClick: (value: string) => void;
  selectedPeriods: string[];
  unit: string;
}

const getBarsPerWidth = (width: number) => {
  return (width - 160) / 100;
};
const getPaddedData = (
  data: {
    period: string;
    value: number;
  }[],
  width: number
) => {
  // In order to position elements on the right of the chart while having only a few periods, we add blank periods to mimic the vertical grid lines
  const difference = getBarsPerWidth(width) - data.length;
  if (difference > 0) {
    let newArray: {
      period: string;
      value: number;
    }[] = [];
    for (var i = difference; i > 0; i--) {
      newArray.push({ period: 'ghostLabel' + i, value: 0 });
    }
    return [...newArray, ...data];
  } else return data;
};

const EnergyReportBarGraph: React.FC<Props> = (props) => {
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null);

  const { ref, dimensions } = useDimensions();

  useDidMountEffect(() => {
    if (scrollableContainerRef.current) {
      setTimeout(() => {
        scrollableContainerRef.current?.scroll({
          behavior: 'smooth',
          left: scrollableContainerRef.current.scrollWidth
        });
      }, 500);
    }
  }, [scrollableContainerRef.current, props.energyReport]);

  let minValue = 0;
  let maxValue = 0;

  const getData = () => {
    return props.energyReport.periods.map((item, index) => {
      return {
        period: item,
        value: props.energyReport.records[index].value
      };
    });
  };

  const data = getPaddedData(getData(), dimensions?.width ?? 0);

  const min = Math.min(...data.map((item) => item.value));
  const max = Math.max(...data.map((item) => item.value));

  if (min < minValue) {
    minValue = min;
  }
  if (max > maxValue) {
    maxValue = max;
  }
  const commonProps = {
    data: data,
    keys: ['value'],
    gridYValues: 6,
    // Leave some more room above and below the bars
    minValue: minValue !== 0 ? minValue * 1.2 : maxValue * -0.1,
    maxValue: maxValue !== 0 ? maxValue * 1.2 : minValue * -0.1
  };

  const leftAxisWidth = 80;
  return (
    <div ref={ref} style={{ height: '100%' }}>
      <div className={styles.barContainer} style={{ height: dimensions?.height }}>
        <div style={{ width: leftAxisWidth }}>
          <Bar
            {...commonProps}
            layers={['axes']}
            height={dimensions?.height ?? 0}
            width={leftAxisWidth}
            axisBottom={null}
            margin={{ top: 10, right: 0, bottom: 40, left: leftAxisWidth }}
            axisLeft={{
              tickValues: 6,
              format: function (value) {
                return `${formatNumber(value)} ${props.unit}`;
              }
            }}
          />
        </div>

        <div className={styles.scrollableBarContainer} ref={scrollableContainerRef}>
          <Bar
            {...commonProps}
            colors={[colors.chart1]}
            indexBy="period"
            height={dimensions?.height ?? 400}
            width={dimensions?.width ? dimensions.width - leftAxisWidth : 0}
            enableGridX
            onClick={(e) => props.onBarClick(e.data.period)}
            valueScale={{ type: 'linear' }}
            padding={0.3}
            //Alternate between fixed width and responsive component based on the amount of entities in the data
            {...(data.length > 6 && { width: data.length * 100 })}
            axisBottom={{
              format: (value: string) => (value.includes('ghostLabel') ? '' : value)
            }}
            indexScale={{ type: 'band', round: true }}
            margin={{ top: 10, right: 0, bottom: 40, left: 0 }}
            enableLabel={false}
            motionConfig="gentle"
            axisLeft={null}
            defs={[
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255,255,255,0.5)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={props.selectedPeriods.map((item) => ({
              match: {
                indexValue: item
              },
              id: 'lines'
            }))}
            theme={{
              grid: {
                line: {
                  strokeWidth: 2,
                  strokeDasharray: '3 3'
                }
              }
            }}
            tooltip={(tooltip) => {
              return <EnergyReportCustomTooltip tooltipProps={tooltip} unit={props.unit} />;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EnergyReportBarGraph;
