import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Header, Icon, Loader } from 'semantic-ui-react';
import styles from './KpiCard.module.scss';
interface Props {
  title: string;
  value: string | JSX.Element | number;
  valueAs?: any;
  valueColor?: string;
  valueSuffix?: string;
  includeExtra?: boolean;
  extra?: string | JSX.Element;
  extraIcon?: IconDefinition;
  extraColor?: string;
  additionalValue?: string | JSX.Element;
  additionalValueSuffix?: string | JSX.Element;
  size?: KPISize;
  status?: KPIStatus;
  retry?: () => void;
}

export enum KPISize {
  small = 65.33,
  medium = 105
}
export enum KPIStatus {
  Loading = 'Loading',
  None = 'None',
  LoadError = 'LoadError'
}

const KpiCard: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const getKPIcontent = () => {
    if (props.status) {
      switch (props.status) {
        case KPIStatus.Loading:
          return layoutLoading;
        case KPIStatus.None:
          return generalLayout;
        case KPIStatus.LoadError:
          return layoutLoadError;
      }
    } else return generalLayout;
  };

  const layoutLoading = (
    <div className={styles.alternateLayoutContainer}>
      <Loader active inline size="medium" />
    </div>
  );

  const layoutLoadError = (
    <div className={styles.alternateLayoutContainer}>
      <Button onClick={props.retry} secondary>
        {t('tryAgain')}
      </Button>
    </div>
  );

  const generalLayout = (
    <>
      <div className={styles.valueContainer}>
        <Header as={props.valueAs ?? 'h3'} style={{ color: props.valueColor }} className={styles.cardValue}>
          {props.value}
        </Header>
        <div className={styles.valueSuffix}>{props.valueSuffix}</div>
      </div>

      {props.additionalValue && (
        <div>
          <div className={styles.cardValue}>{props.additionalValue}</div>
          <div className={styles.valueSuffix}>{props.additionalValueSuffix}</div>
        </div>
      )}

      {props.includeExtra && (
        <div className={styles.cardExtra} style={{ color: props.extraColor }}>
          {props.extraIcon && (
            <span>
              <Icon circular size="small" className={styles.extraIcon}>
                <FontAwesomeIcon icon={props.extraIcon} color={props.extraColor} />
              </Icon>
            </span>
          )}
          {props.extra}
        </div>
      )}
    </>
  );

  return (
    <Card className={styles.card} style={{ height: props.size ?? KPISize.medium }}>
      <Card.Content className={styles.cardContent}>
        <Header className={styles.cardHeader} as="h4">
          {props.title}
        </Header>

        {getKPIcontent()}
      </Card.Content>
    </Card>
  );
};

export default KpiCard;
