import React from 'react';
import styles from './CircleLoader.module.scss';

interface Props {
  size: string;
  title?: string;
  dy?: string;
}

const CircleLoader: React.FC<Props> = (props) => {
  const componentWrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    componentWrapperRef.current?.style.setProperty('--size', props.size);
    if (props.dy !== undefined) {
      componentWrapperRef.current?.style.setProperty('--dy', props.dy);
    }
  }, [props.size, props.dy]);

  return <div ref={componentWrapperRef} className={styles.componentWrapper} title={props.title} />;
};

export default CircleLoader;
