/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { FileCreateView } from '../model';
// @ts-ignore
import { FileInfoListView } from '../model';
// @ts-ignore
import { FileInfoReadView } from '../model';
// @ts-ignore
import { FileInfoUpdateView } from '../model';
/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Save a file
     * @param {FileCreateView} [fileCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceCreate: async (
      fileCreateView?: FileCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fileCreateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fileResourceDelete', 'id', id);
      const localVarPath = `/v1/files/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Download file archive
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceDownloadArchive: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('fileResourceDownloadArchive', 'token', token);
      const localVarPath = `/v1/files/archive/{token}`.replace(`{${'token'}}`, encodeURIComponent(String(token)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get a URL to download file archive
     * @param {number} [organisationId] Filter by organisation
     * @param {'CONTRACT' | 'PROPERTY' | 'WORKORDER' | 'WORKORDERLOG' | 'PROPERTY_CHECK' | 'GOVERNMENT_INSPECTION_WORK_ORDER' | 'ORGANISATION' | 'PORTFOLIO' | 'GOVERNMENT_INSPECTION'} [entityType] Filter by entityType
     * @param {number} [entityId] Filter by entityId
     * @param {'BUILDING_PERMITS' | 'DD_REPORT' | 'DRAWING' | 'EASEMENT' | 'INSPECTION' | 'INSURANCE' | 'LAND_AGREEMENT' | 'LEASE' | 'LEASEHOLD_AGREEMENT' | 'NEGOTIATION_AGREEMENT' | 'OPERATING_SERVICING_AGREEMENTS' | 'OTHER' | 'PROJECT' | 'PROPERTY_PICTURE'} [type] Filter by type
     * @param {'ADDITION' | 'AGREEMENT' | 'AGREEMENT_OIL_SEPARATOR' | 'AGREEMENT_PORT' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'BUILDING_PERMIT_DRAWING' | 'CONSTRUCTION_DRAWING' | 'CONTRACT_PRESSURE_VESSEL' | 'COOLING' | 'DOOR' | 'ELECTRICITY_AUDIT' | 'ELEVATOR' | 'ENERGY_DECLARATION' | 'ENVIRONMENTAL_SURVEY' | 'FINAL_INSPECTION' | 'FIRE_ALARM_TRANSMISSION' | 'FIRE_PROTECTION' | 'FLOOR_PLAN' | 'GROUND_DRAWING' | 'INSIDE' | 'INSTALLATION_DRAWING' | 'LAND_MANAGEMENT' | 'LEASEHOLD' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'LOCK_SCHEME' | 'OFFER' | 'OIL_SEPARATOR' | 'ORDER' | 'OVK' | 'PESTS' | 'PRESSURE_VESSELS' | 'PROPERTY_PICTURE' | 'RECORD_DRAWING' | 'REFRIGERANT' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'SHELTER' | 'TREATMENT_PLANT' | 'WINTER_MAINTENANCE' | 'ZONING_PLAN'} [subType] Filter by subType
     * @param {number} [propertyId] Filter files indirectly linked to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceGetArchiveUrl: async (
      organisationId?: number,
      entityType?:
        | 'CONTRACT'
        | 'PROPERTY'
        | 'WORKORDER'
        | 'WORKORDERLOG'
        | 'PROPERTY_CHECK'
        | 'GOVERNMENT_INSPECTION_WORK_ORDER'
        | 'ORGANISATION'
        | 'PORTFOLIO'
        | 'GOVERNMENT_INSPECTION',
      entityId?: number,
      type?:
        | 'BUILDING_PERMITS'
        | 'DD_REPORT'
        | 'DRAWING'
        | 'EASEMENT'
        | 'INSPECTION'
        | 'INSURANCE'
        | 'LAND_AGREEMENT'
        | 'LEASE'
        | 'LEASEHOLD_AGREEMENT'
        | 'NEGOTIATION_AGREEMENT'
        | 'OPERATING_SERVICING_AGREEMENTS'
        | 'OTHER'
        | 'PROJECT'
        | 'PROPERTY_PICTURE',
      subType?:
        | 'ADDITION'
        | 'AGREEMENT'
        | 'AGREEMENT_OIL_SEPARATOR'
        | 'AGREEMENT_PORT'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'BUILDING_PERMIT_DRAWING'
        | 'CONSTRUCTION_DRAWING'
        | 'CONTRACT_PRESSURE_VESSEL'
        | 'COOLING'
        | 'DOOR'
        | 'ELECTRICITY_AUDIT'
        | 'ELEVATOR'
        | 'ENERGY_DECLARATION'
        | 'ENVIRONMENTAL_SURVEY'
        | 'FINAL_INSPECTION'
        | 'FIRE_ALARM_TRANSMISSION'
        | 'FIRE_PROTECTION'
        | 'FLOOR_PLAN'
        | 'GROUND_DRAWING'
        | 'INSIDE'
        | 'INSTALLATION_DRAWING'
        | 'LAND_MANAGEMENT'
        | 'LEASEHOLD'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'LOCK_SCHEME'
        | 'OFFER'
        | 'OIL_SEPARATOR'
        | 'ORDER'
        | 'OVK'
        | 'PESTS'
        | 'PRESSURE_VESSELS'
        | 'PROPERTY_PICTURE'
        | 'RECORD_DRAWING'
        | 'REFRIGERANT'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'SHELTER'
        | 'TREATMENT_PLANT'
        | 'WINTER_MAINTENANCE'
        | 'ZONING_PLAN',
      propertyId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/files/archive-url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (entityType !== undefined) {
        localVarQueryParameter['entityType'] = entityType;
      }

      if (entityId !== undefined) {
        localVarQueryParameter['entityId'] = entityId;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (subType !== undefined) {
        localVarQueryParameter['subType'] = subType;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Return a URL to the file
     * @param {string} id File ID
     * @param {boolean} [download] Whether browser should trigger file downloading or display the file inline. See Content-Disposition header for more details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceGetBlobUrl: async (
      id: string,
      download?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fileResourceGetBlobUrl', 'id', id);
      const localVarPath = `/v1/files/{id}/blob-url`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (download !== undefined) {
        localVarQueryParameter['download'] = download;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Return a URL to the image thumbnail
     * @param {string} id File ID
     * @param {boolean} [download] Whether browser should trigger file downloading or display the file inline. See Content-Disposition header for more details.
     * @param {number} [maxWidth] Thumbnail max width
     * @param {number} [maxHeight] Thumbnail max height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceGetThumbnailUrl: async (
      id: string,
      download?: boolean,
      maxWidth?: number,
      maxHeight?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fileResourceGetThumbnailUrl', 'id', id);
      const localVarPath = `/v1/files/{id}/thumbnail-url`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (download !== undefined) {
        localVarQueryParameter['download'] = download;
      }

      if (maxWidth !== undefined) {
        localVarQueryParameter['maxWidth'] = maxWidth;
      }

      if (maxHeight !== undefined) {
        localVarQueryParameter['maxHeight'] = maxHeight;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List files
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {'CONTRACT' | 'PROPERTY' | 'WORKORDER' | 'WORKORDERLOG' | 'PROPERTY_CHECK' | 'GOVERNMENT_INSPECTION_WORK_ORDER' | 'ORGANISATION' | 'PORTFOLIO' | 'GOVERNMENT_INSPECTION'} [entityType] Filter by entityType
     * @param {number} [entityId] Filter by entityId
     * @param {'BUILDING_PERMITS' | 'DD_REPORT' | 'DRAWING' | 'EASEMENT' | 'INSPECTION' | 'INSURANCE' | 'LAND_AGREEMENT' | 'LEASE' | 'LEASEHOLD_AGREEMENT' | 'NEGOTIATION_AGREEMENT' | 'OPERATING_SERVICING_AGREEMENTS' | 'OTHER' | 'PROJECT' | 'PROPERTY_PICTURE'} [type] Filter by type
     * @param {'ADDITION' | 'AGREEMENT' | 'AGREEMENT_OIL_SEPARATOR' | 'AGREEMENT_PORT' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'BUILDING_PERMIT_DRAWING' | 'CONSTRUCTION_DRAWING' | 'CONTRACT_PRESSURE_VESSEL' | 'COOLING' | 'DOOR' | 'ELECTRICITY_AUDIT' | 'ELEVATOR' | 'ENERGY_DECLARATION' | 'ENVIRONMENTAL_SURVEY' | 'FINAL_INSPECTION' | 'FIRE_ALARM_TRANSMISSION' | 'FIRE_PROTECTION' | 'FLOOR_PLAN' | 'GROUND_DRAWING' | 'INSIDE' | 'INSTALLATION_DRAWING' | 'LAND_MANAGEMENT' | 'LEASEHOLD' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'LOCK_SCHEME' | 'OFFER' | 'OIL_SEPARATOR' | 'ORDER' | 'OVK' | 'PESTS' | 'PRESSURE_VESSELS' | 'PROPERTY_PICTURE' | 'RECORD_DRAWING' | 'REFRIGERANT' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'SHELTER' | 'TREATMENT_PLANT' | 'WINTER_MAINTENANCE' | 'ZONING_PLAN'} [subType] Filter by subType
     * @param {boolean} [deleted] Filter by deleted status
     * @param {boolean} [archived] Filter by archived status
     * @param {number} [propertyId] Filter files indirectly linked to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      entityType?:
        | 'CONTRACT'
        | 'PROPERTY'
        | 'WORKORDER'
        | 'WORKORDERLOG'
        | 'PROPERTY_CHECK'
        | 'GOVERNMENT_INSPECTION_WORK_ORDER'
        | 'ORGANISATION'
        | 'PORTFOLIO'
        | 'GOVERNMENT_INSPECTION',
      entityId?: number,
      type?:
        | 'BUILDING_PERMITS'
        | 'DD_REPORT'
        | 'DRAWING'
        | 'EASEMENT'
        | 'INSPECTION'
        | 'INSURANCE'
        | 'LAND_AGREEMENT'
        | 'LEASE'
        | 'LEASEHOLD_AGREEMENT'
        | 'NEGOTIATION_AGREEMENT'
        | 'OPERATING_SERVICING_AGREEMENTS'
        | 'OTHER'
        | 'PROJECT'
        | 'PROPERTY_PICTURE',
      subType?:
        | 'ADDITION'
        | 'AGREEMENT'
        | 'AGREEMENT_OIL_SEPARATOR'
        | 'AGREEMENT_PORT'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'BUILDING_PERMIT_DRAWING'
        | 'CONSTRUCTION_DRAWING'
        | 'CONTRACT_PRESSURE_VESSEL'
        | 'COOLING'
        | 'DOOR'
        | 'ELECTRICITY_AUDIT'
        | 'ELEVATOR'
        | 'ENERGY_DECLARATION'
        | 'ENVIRONMENTAL_SURVEY'
        | 'FINAL_INSPECTION'
        | 'FIRE_ALARM_TRANSMISSION'
        | 'FIRE_PROTECTION'
        | 'FLOOR_PLAN'
        | 'GROUND_DRAWING'
        | 'INSIDE'
        | 'INSTALLATION_DRAWING'
        | 'LAND_MANAGEMENT'
        | 'LEASEHOLD'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'LOCK_SCHEME'
        | 'OFFER'
        | 'OIL_SEPARATOR'
        | 'ORDER'
        | 'OVK'
        | 'PESTS'
        | 'PRESSURE_VESSELS'
        | 'PROPERTY_PICTURE'
        | 'RECORD_DRAWING'
        | 'REFRIGERANT'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'SHELTER'
        | 'TREATMENT_PLANT'
        | 'WINTER_MAINTENANCE'
        | 'ZONING_PLAN',
      deleted?: boolean,
      archived?: boolean,
      propertyId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (entityType !== undefined) {
        localVarQueryParameter['entityType'] = entityType;
      }

      if (entityId !== undefined) {
        localVarQueryParameter['entityId'] = entityId;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (subType !== undefined) {
        localVarQueryParameter['subType'] = subType;
      }

      if (deleted !== undefined) {
        localVarQueryParameter['deleted'] = deleted;
      }

      if (archived !== undefined) {
        localVarQueryParameter['archived'] = archived;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read information of a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fileResourceRead', 'id', id);
      const localVarPath = `/v1/files/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read blob of a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceReadFileBlob: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fileResourceReadFileBlob', 'id', id);
      const localVarPath = `/v1/files/{id}/blob`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read thumbnail of an image
     * @param {string} id File ID
     * @param {number} [maxWidth] Thumbnail max width
     * @param {number} [maxHeight] Thumbnail max height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceReadImageThumbnail: async (
      id: string,
      maxWidth?: number,
      maxHeight?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fileResourceReadImageThumbnail', 'id', id);
      const localVarPath = `/v1/files/{id}/thumbnail`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (maxWidth !== undefined) {
        localVarQueryParameter['maxWidth'] = maxWidth;
      }

      if (maxHeight !== undefined) {
        localVarQueryParameter['maxHeight'] = maxHeight;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update information of a file
     * @param {string} id File ID
     * @param {FileInfoUpdateView} [fileInfoUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceUpdate: async (
      id: string,
      fileInfoUpdateView?: FileInfoUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fileResourceUpdate', 'id', id);
      const localVarPath = `/v1/files/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fileInfoUpdateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Save a file
     * @param {FileCreateView} [fileCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceCreate(
      fileCreateView?: FileCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfoReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceCreate(fileCreateView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceDelete(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download file archive
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceDownloadArchive(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceDownloadArchive(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a URL to download file archive
     * @param {number} [organisationId] Filter by organisation
     * @param {'CONTRACT' | 'PROPERTY' | 'WORKORDER' | 'WORKORDERLOG' | 'PROPERTY_CHECK' | 'GOVERNMENT_INSPECTION_WORK_ORDER' | 'ORGANISATION' | 'PORTFOLIO' | 'GOVERNMENT_INSPECTION'} [entityType] Filter by entityType
     * @param {number} [entityId] Filter by entityId
     * @param {'BUILDING_PERMITS' | 'DD_REPORT' | 'DRAWING' | 'EASEMENT' | 'INSPECTION' | 'INSURANCE' | 'LAND_AGREEMENT' | 'LEASE' | 'LEASEHOLD_AGREEMENT' | 'NEGOTIATION_AGREEMENT' | 'OPERATING_SERVICING_AGREEMENTS' | 'OTHER' | 'PROJECT' | 'PROPERTY_PICTURE'} [type] Filter by type
     * @param {'ADDITION' | 'AGREEMENT' | 'AGREEMENT_OIL_SEPARATOR' | 'AGREEMENT_PORT' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'BUILDING_PERMIT_DRAWING' | 'CONSTRUCTION_DRAWING' | 'CONTRACT_PRESSURE_VESSEL' | 'COOLING' | 'DOOR' | 'ELECTRICITY_AUDIT' | 'ELEVATOR' | 'ENERGY_DECLARATION' | 'ENVIRONMENTAL_SURVEY' | 'FINAL_INSPECTION' | 'FIRE_ALARM_TRANSMISSION' | 'FIRE_PROTECTION' | 'FLOOR_PLAN' | 'GROUND_DRAWING' | 'INSIDE' | 'INSTALLATION_DRAWING' | 'LAND_MANAGEMENT' | 'LEASEHOLD' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'LOCK_SCHEME' | 'OFFER' | 'OIL_SEPARATOR' | 'ORDER' | 'OVK' | 'PESTS' | 'PRESSURE_VESSELS' | 'PROPERTY_PICTURE' | 'RECORD_DRAWING' | 'REFRIGERANT' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'SHELTER' | 'TREATMENT_PLANT' | 'WINTER_MAINTENANCE' | 'ZONING_PLAN'} [subType] Filter by subType
     * @param {number} [propertyId] Filter files indirectly linked to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceGetArchiveUrl(
      organisationId?: number,
      entityType?:
        | 'CONTRACT'
        | 'PROPERTY'
        | 'WORKORDER'
        | 'WORKORDERLOG'
        | 'PROPERTY_CHECK'
        | 'GOVERNMENT_INSPECTION_WORK_ORDER'
        | 'ORGANISATION'
        | 'PORTFOLIO'
        | 'GOVERNMENT_INSPECTION',
      entityId?: number,
      type?:
        | 'BUILDING_PERMITS'
        | 'DD_REPORT'
        | 'DRAWING'
        | 'EASEMENT'
        | 'INSPECTION'
        | 'INSURANCE'
        | 'LAND_AGREEMENT'
        | 'LEASE'
        | 'LEASEHOLD_AGREEMENT'
        | 'NEGOTIATION_AGREEMENT'
        | 'OPERATING_SERVICING_AGREEMENTS'
        | 'OTHER'
        | 'PROJECT'
        | 'PROPERTY_PICTURE',
      subType?:
        | 'ADDITION'
        | 'AGREEMENT'
        | 'AGREEMENT_OIL_SEPARATOR'
        | 'AGREEMENT_PORT'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'BUILDING_PERMIT_DRAWING'
        | 'CONSTRUCTION_DRAWING'
        | 'CONTRACT_PRESSURE_VESSEL'
        | 'COOLING'
        | 'DOOR'
        | 'ELECTRICITY_AUDIT'
        | 'ELEVATOR'
        | 'ENERGY_DECLARATION'
        | 'ENVIRONMENTAL_SURVEY'
        | 'FINAL_INSPECTION'
        | 'FIRE_ALARM_TRANSMISSION'
        | 'FIRE_PROTECTION'
        | 'FLOOR_PLAN'
        | 'GROUND_DRAWING'
        | 'INSIDE'
        | 'INSTALLATION_DRAWING'
        | 'LAND_MANAGEMENT'
        | 'LEASEHOLD'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'LOCK_SCHEME'
        | 'OFFER'
        | 'OIL_SEPARATOR'
        | 'ORDER'
        | 'OVK'
        | 'PESTS'
        | 'PRESSURE_VESSELS'
        | 'PROPERTY_PICTURE'
        | 'RECORD_DRAWING'
        | 'REFRIGERANT'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'SHELTER'
        | 'TREATMENT_PLANT'
        | 'WINTER_MAINTENANCE'
        | 'ZONING_PLAN',
      propertyId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceGetArchiveUrl(
        organisationId,
        entityType,
        entityId,
        type,
        subType,
        propertyId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Return a URL to the file
     * @param {string} id File ID
     * @param {boolean} [download] Whether browser should trigger file downloading or display the file inline. See Content-Disposition header for more details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceGetBlobUrl(
      id: string,
      download?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceGetBlobUrl(id, download, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Return a URL to the image thumbnail
     * @param {string} id File ID
     * @param {boolean} [download] Whether browser should trigger file downloading or display the file inline. See Content-Disposition header for more details.
     * @param {number} [maxWidth] Thumbnail max width
     * @param {number} [maxHeight] Thumbnail max height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceGetThumbnailUrl(
      id: string,
      download?: boolean,
      maxWidth?: number,
      maxHeight?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceGetThumbnailUrl(
        id,
        download,
        maxWidth,
        maxHeight,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List files
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {'CONTRACT' | 'PROPERTY' | 'WORKORDER' | 'WORKORDERLOG' | 'PROPERTY_CHECK' | 'GOVERNMENT_INSPECTION_WORK_ORDER' | 'ORGANISATION' | 'PORTFOLIO' | 'GOVERNMENT_INSPECTION'} [entityType] Filter by entityType
     * @param {number} [entityId] Filter by entityId
     * @param {'BUILDING_PERMITS' | 'DD_REPORT' | 'DRAWING' | 'EASEMENT' | 'INSPECTION' | 'INSURANCE' | 'LAND_AGREEMENT' | 'LEASE' | 'LEASEHOLD_AGREEMENT' | 'NEGOTIATION_AGREEMENT' | 'OPERATING_SERVICING_AGREEMENTS' | 'OTHER' | 'PROJECT' | 'PROPERTY_PICTURE'} [type] Filter by type
     * @param {'ADDITION' | 'AGREEMENT' | 'AGREEMENT_OIL_SEPARATOR' | 'AGREEMENT_PORT' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'BUILDING_PERMIT_DRAWING' | 'CONSTRUCTION_DRAWING' | 'CONTRACT_PRESSURE_VESSEL' | 'COOLING' | 'DOOR' | 'ELECTRICITY_AUDIT' | 'ELEVATOR' | 'ENERGY_DECLARATION' | 'ENVIRONMENTAL_SURVEY' | 'FINAL_INSPECTION' | 'FIRE_ALARM_TRANSMISSION' | 'FIRE_PROTECTION' | 'FLOOR_PLAN' | 'GROUND_DRAWING' | 'INSIDE' | 'INSTALLATION_DRAWING' | 'LAND_MANAGEMENT' | 'LEASEHOLD' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'LOCK_SCHEME' | 'OFFER' | 'OIL_SEPARATOR' | 'ORDER' | 'OVK' | 'PESTS' | 'PRESSURE_VESSELS' | 'PROPERTY_PICTURE' | 'RECORD_DRAWING' | 'REFRIGERANT' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'SHELTER' | 'TREATMENT_PLANT' | 'WINTER_MAINTENANCE' | 'ZONING_PLAN'} [subType] Filter by subType
     * @param {boolean} [deleted] Filter by deleted status
     * @param {boolean} [archived] Filter by archived status
     * @param {number} [propertyId] Filter files indirectly linked to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      entityType?:
        | 'CONTRACT'
        | 'PROPERTY'
        | 'WORKORDER'
        | 'WORKORDERLOG'
        | 'PROPERTY_CHECK'
        | 'GOVERNMENT_INSPECTION_WORK_ORDER'
        | 'ORGANISATION'
        | 'PORTFOLIO'
        | 'GOVERNMENT_INSPECTION',
      entityId?: number,
      type?:
        | 'BUILDING_PERMITS'
        | 'DD_REPORT'
        | 'DRAWING'
        | 'EASEMENT'
        | 'INSPECTION'
        | 'INSURANCE'
        | 'LAND_AGREEMENT'
        | 'LEASE'
        | 'LEASEHOLD_AGREEMENT'
        | 'NEGOTIATION_AGREEMENT'
        | 'OPERATING_SERVICING_AGREEMENTS'
        | 'OTHER'
        | 'PROJECT'
        | 'PROPERTY_PICTURE',
      subType?:
        | 'ADDITION'
        | 'AGREEMENT'
        | 'AGREEMENT_OIL_SEPARATOR'
        | 'AGREEMENT_PORT'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'BUILDING_PERMIT_DRAWING'
        | 'CONSTRUCTION_DRAWING'
        | 'CONTRACT_PRESSURE_VESSEL'
        | 'COOLING'
        | 'DOOR'
        | 'ELECTRICITY_AUDIT'
        | 'ELEVATOR'
        | 'ENERGY_DECLARATION'
        | 'ENVIRONMENTAL_SURVEY'
        | 'FINAL_INSPECTION'
        | 'FIRE_ALARM_TRANSMISSION'
        | 'FIRE_PROTECTION'
        | 'FLOOR_PLAN'
        | 'GROUND_DRAWING'
        | 'INSIDE'
        | 'INSTALLATION_DRAWING'
        | 'LAND_MANAGEMENT'
        | 'LEASEHOLD'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'LOCK_SCHEME'
        | 'OFFER'
        | 'OIL_SEPARATOR'
        | 'ORDER'
        | 'OVK'
        | 'PESTS'
        | 'PRESSURE_VESSELS'
        | 'PROPERTY_PICTURE'
        | 'RECORD_DRAWING'
        | 'REFRIGERANT'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'SHELTER'
        | 'TREATMENT_PLANT'
        | 'WINTER_MAINTENANCE'
        | 'ZONING_PLAN',
      deleted?: boolean,
      archived?: boolean,
      propertyId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfoListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceList(
        limit,
        offset,
        organisationId,
        entityType,
        entityId,
        type,
        subType,
        deleted,
        archived,
        propertyId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read information of a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceRead(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfoReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read blob of a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceReadFileBlob(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceReadFileBlob(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read thumbnail of an image
     * @param {string} id File ID
     * @param {number} [maxWidth] Thumbnail max width
     * @param {number} [maxHeight] Thumbnail max height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceReadImageThumbnail(
      id: string,
      maxWidth?: number,
      maxHeight?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceReadImageThumbnail(
        id,
        maxWidth,
        maxHeight,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update information of a file
     * @param {string} id File ID
     * @param {FileInfoUpdateView} [fileInfoUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileResourceUpdate(
      id: string,
      fileInfoUpdateView?: FileInfoUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfoReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileResourceUpdate(id, fileInfoUpdateView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FileApiFp(configuration);
  return {
    /**
     *
     * @summary Save a file
     * @param {FileCreateView} [fileCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceCreate(fileCreateView?: FileCreateView, options?: any): AxiosPromise<FileInfoReadView> {
      return localVarFp.fileResourceCreate(fileCreateView, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceDelete(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.fileResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Download file archive
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceDownloadArchive(token: string, options?: any): AxiosPromise<void> {
      return localVarFp.fileResourceDownloadArchive(token, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a URL to download file archive
     * @param {number} [organisationId] Filter by organisation
     * @param {'CONTRACT' | 'PROPERTY' | 'WORKORDER' | 'WORKORDERLOG' | 'PROPERTY_CHECK' | 'GOVERNMENT_INSPECTION_WORK_ORDER' | 'ORGANISATION' | 'PORTFOLIO' | 'GOVERNMENT_INSPECTION'} [entityType] Filter by entityType
     * @param {number} [entityId] Filter by entityId
     * @param {'BUILDING_PERMITS' | 'DD_REPORT' | 'DRAWING' | 'EASEMENT' | 'INSPECTION' | 'INSURANCE' | 'LAND_AGREEMENT' | 'LEASE' | 'LEASEHOLD_AGREEMENT' | 'NEGOTIATION_AGREEMENT' | 'OPERATING_SERVICING_AGREEMENTS' | 'OTHER' | 'PROJECT' | 'PROPERTY_PICTURE'} [type] Filter by type
     * @param {'ADDITION' | 'AGREEMENT' | 'AGREEMENT_OIL_SEPARATOR' | 'AGREEMENT_PORT' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'BUILDING_PERMIT_DRAWING' | 'CONSTRUCTION_DRAWING' | 'CONTRACT_PRESSURE_VESSEL' | 'COOLING' | 'DOOR' | 'ELECTRICITY_AUDIT' | 'ELEVATOR' | 'ENERGY_DECLARATION' | 'ENVIRONMENTAL_SURVEY' | 'FINAL_INSPECTION' | 'FIRE_ALARM_TRANSMISSION' | 'FIRE_PROTECTION' | 'FLOOR_PLAN' | 'GROUND_DRAWING' | 'INSIDE' | 'INSTALLATION_DRAWING' | 'LAND_MANAGEMENT' | 'LEASEHOLD' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'LOCK_SCHEME' | 'OFFER' | 'OIL_SEPARATOR' | 'ORDER' | 'OVK' | 'PESTS' | 'PRESSURE_VESSELS' | 'PROPERTY_PICTURE' | 'RECORD_DRAWING' | 'REFRIGERANT' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'SHELTER' | 'TREATMENT_PLANT' | 'WINTER_MAINTENANCE' | 'ZONING_PLAN'} [subType] Filter by subType
     * @param {number} [propertyId] Filter files indirectly linked to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceGetArchiveUrl(
      organisationId?: number,
      entityType?:
        | 'CONTRACT'
        | 'PROPERTY'
        | 'WORKORDER'
        | 'WORKORDERLOG'
        | 'PROPERTY_CHECK'
        | 'GOVERNMENT_INSPECTION_WORK_ORDER'
        | 'ORGANISATION'
        | 'PORTFOLIO'
        | 'GOVERNMENT_INSPECTION',
      entityId?: number,
      type?:
        | 'BUILDING_PERMITS'
        | 'DD_REPORT'
        | 'DRAWING'
        | 'EASEMENT'
        | 'INSPECTION'
        | 'INSURANCE'
        | 'LAND_AGREEMENT'
        | 'LEASE'
        | 'LEASEHOLD_AGREEMENT'
        | 'NEGOTIATION_AGREEMENT'
        | 'OPERATING_SERVICING_AGREEMENTS'
        | 'OTHER'
        | 'PROJECT'
        | 'PROPERTY_PICTURE',
      subType?:
        | 'ADDITION'
        | 'AGREEMENT'
        | 'AGREEMENT_OIL_SEPARATOR'
        | 'AGREEMENT_PORT'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'BUILDING_PERMIT_DRAWING'
        | 'CONSTRUCTION_DRAWING'
        | 'CONTRACT_PRESSURE_VESSEL'
        | 'COOLING'
        | 'DOOR'
        | 'ELECTRICITY_AUDIT'
        | 'ELEVATOR'
        | 'ENERGY_DECLARATION'
        | 'ENVIRONMENTAL_SURVEY'
        | 'FINAL_INSPECTION'
        | 'FIRE_ALARM_TRANSMISSION'
        | 'FIRE_PROTECTION'
        | 'FLOOR_PLAN'
        | 'GROUND_DRAWING'
        | 'INSIDE'
        | 'INSTALLATION_DRAWING'
        | 'LAND_MANAGEMENT'
        | 'LEASEHOLD'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'LOCK_SCHEME'
        | 'OFFER'
        | 'OIL_SEPARATOR'
        | 'ORDER'
        | 'OVK'
        | 'PESTS'
        | 'PRESSURE_VESSELS'
        | 'PROPERTY_PICTURE'
        | 'RECORD_DRAWING'
        | 'REFRIGERANT'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'SHELTER'
        | 'TREATMENT_PLANT'
        | 'WINTER_MAINTENANCE'
        | 'ZONING_PLAN',
      propertyId?: number,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .fileResourceGetArchiveUrl(organisationId, entityType, entityId, type, subType, propertyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Return a URL to the file
     * @param {string} id File ID
     * @param {boolean} [download] Whether browser should trigger file downloading or display the file inline. See Content-Disposition header for more details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceGetBlobUrl(id: string, download?: boolean, options?: any): AxiosPromise<string> {
      return localVarFp.fileResourceGetBlobUrl(id, download, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Return a URL to the image thumbnail
     * @param {string} id File ID
     * @param {boolean} [download] Whether browser should trigger file downloading or display the file inline. See Content-Disposition header for more details.
     * @param {number} [maxWidth] Thumbnail max width
     * @param {number} [maxHeight] Thumbnail max height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceGetThumbnailUrl(
      id: string,
      download?: boolean,
      maxWidth?: number,
      maxHeight?: number,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .fileResourceGetThumbnailUrl(id, download, maxWidth, maxHeight, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List files
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {'CONTRACT' | 'PROPERTY' | 'WORKORDER' | 'WORKORDERLOG' | 'PROPERTY_CHECK' | 'GOVERNMENT_INSPECTION_WORK_ORDER' | 'ORGANISATION' | 'PORTFOLIO' | 'GOVERNMENT_INSPECTION'} [entityType] Filter by entityType
     * @param {number} [entityId] Filter by entityId
     * @param {'BUILDING_PERMITS' | 'DD_REPORT' | 'DRAWING' | 'EASEMENT' | 'INSPECTION' | 'INSURANCE' | 'LAND_AGREEMENT' | 'LEASE' | 'LEASEHOLD_AGREEMENT' | 'NEGOTIATION_AGREEMENT' | 'OPERATING_SERVICING_AGREEMENTS' | 'OTHER' | 'PROJECT' | 'PROPERTY_PICTURE'} [type] Filter by type
     * @param {'ADDITION' | 'AGREEMENT' | 'AGREEMENT_OIL_SEPARATOR' | 'AGREEMENT_PORT' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'BUILDING_PERMIT_DRAWING' | 'CONSTRUCTION_DRAWING' | 'CONTRACT_PRESSURE_VESSEL' | 'COOLING' | 'DOOR' | 'ELECTRICITY_AUDIT' | 'ELEVATOR' | 'ENERGY_DECLARATION' | 'ENVIRONMENTAL_SURVEY' | 'FINAL_INSPECTION' | 'FIRE_ALARM_TRANSMISSION' | 'FIRE_PROTECTION' | 'FLOOR_PLAN' | 'GROUND_DRAWING' | 'INSIDE' | 'INSTALLATION_DRAWING' | 'LAND_MANAGEMENT' | 'LEASEHOLD' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'LOCK_SCHEME' | 'OFFER' | 'OIL_SEPARATOR' | 'ORDER' | 'OVK' | 'PESTS' | 'PRESSURE_VESSELS' | 'PROPERTY_PICTURE' | 'RECORD_DRAWING' | 'REFRIGERANT' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'SHELTER' | 'TREATMENT_PLANT' | 'WINTER_MAINTENANCE' | 'ZONING_PLAN'} [subType] Filter by subType
     * @param {boolean} [deleted] Filter by deleted status
     * @param {boolean} [archived] Filter by archived status
     * @param {number} [propertyId] Filter files indirectly linked to the property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      entityType?:
        | 'CONTRACT'
        | 'PROPERTY'
        | 'WORKORDER'
        | 'WORKORDERLOG'
        | 'PROPERTY_CHECK'
        | 'GOVERNMENT_INSPECTION_WORK_ORDER'
        | 'ORGANISATION'
        | 'PORTFOLIO'
        | 'GOVERNMENT_INSPECTION',
      entityId?: number,
      type?:
        | 'BUILDING_PERMITS'
        | 'DD_REPORT'
        | 'DRAWING'
        | 'EASEMENT'
        | 'INSPECTION'
        | 'INSURANCE'
        | 'LAND_AGREEMENT'
        | 'LEASE'
        | 'LEASEHOLD_AGREEMENT'
        | 'NEGOTIATION_AGREEMENT'
        | 'OPERATING_SERVICING_AGREEMENTS'
        | 'OTHER'
        | 'PROJECT'
        | 'PROPERTY_PICTURE',
      subType?:
        | 'ADDITION'
        | 'AGREEMENT'
        | 'AGREEMENT_OIL_SEPARATOR'
        | 'AGREEMENT_PORT'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'BUILDING_PERMIT_DRAWING'
        | 'CONSTRUCTION_DRAWING'
        | 'CONTRACT_PRESSURE_VESSEL'
        | 'COOLING'
        | 'DOOR'
        | 'ELECTRICITY_AUDIT'
        | 'ELEVATOR'
        | 'ENERGY_DECLARATION'
        | 'ENVIRONMENTAL_SURVEY'
        | 'FINAL_INSPECTION'
        | 'FIRE_ALARM_TRANSMISSION'
        | 'FIRE_PROTECTION'
        | 'FLOOR_PLAN'
        | 'GROUND_DRAWING'
        | 'INSIDE'
        | 'INSTALLATION_DRAWING'
        | 'LAND_MANAGEMENT'
        | 'LEASEHOLD'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'LOCK_SCHEME'
        | 'OFFER'
        | 'OIL_SEPARATOR'
        | 'ORDER'
        | 'OVK'
        | 'PESTS'
        | 'PRESSURE_VESSELS'
        | 'PROPERTY_PICTURE'
        | 'RECORD_DRAWING'
        | 'REFRIGERANT'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'SHELTER'
        | 'TREATMENT_PLANT'
        | 'WINTER_MAINTENANCE'
        | 'ZONING_PLAN',
      deleted?: boolean,
      archived?: boolean,
      propertyId?: number,
      options?: any
    ): AxiosPromise<FileInfoListView> {
      return localVarFp
        .fileResourceList(
          limit,
          offset,
          organisationId,
          entityType,
          entityId,
          type,
          subType,
          deleted,
          archived,
          propertyId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read information of a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceRead(id: string, options?: any): AxiosPromise<FileInfoReadView> {
      return localVarFp.fileResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read blob of a file
     * @param {string} id File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceReadFileBlob(id: string, options?: any): AxiosPromise<object> {
      return localVarFp.fileResourceReadFileBlob(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read thumbnail of an image
     * @param {string} id File ID
     * @param {number} [maxWidth] Thumbnail max width
     * @param {number} [maxHeight] Thumbnail max height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceReadImageThumbnail(
      id: string,
      maxWidth?: number,
      maxHeight?: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .fileResourceReadImageThumbnail(id, maxWidth, maxHeight, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update information of a file
     * @param {string} id File ID
     * @param {FileInfoUpdateView} [fileInfoUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileResourceUpdate(
      id: string,
      fileInfoUpdateView?: FileInfoUpdateView,
      options?: any
    ): AxiosPromise<FileInfoReadView> {
      return localVarFp.fileResourceUpdate(id, fileInfoUpdateView, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for fileResourceCreate operation in FileApi.
 * @export
 * @interface FileApiFileResourceCreateRequest
 */
export interface FileApiFileResourceCreateRequest {
  /**
   *
   * @type {FileCreateView}
   * @memberof FileApiFileResourceCreate
   */
  readonly fileCreateView?: FileCreateView;
}

/**
 * Request parameters for fileResourceDelete operation in FileApi.
 * @export
 * @interface FileApiFileResourceDeleteRequest
 */
export interface FileApiFileResourceDeleteRequest {
  /**
   * File ID
   * @type {string}
   * @memberof FileApiFileResourceDelete
   */
  readonly id: string;
}

/**
 * Request parameters for fileResourceDownloadArchive operation in FileApi.
 * @export
 * @interface FileApiFileResourceDownloadArchiveRequest
 */
export interface FileApiFileResourceDownloadArchiveRequest {
  /**
   *
   * @type {string}
   * @memberof FileApiFileResourceDownloadArchive
   */
  readonly token: string;
}

/**
 * Request parameters for fileResourceGetArchiveUrl operation in FileApi.
 * @export
 * @interface FileApiFileResourceGetArchiveUrlRequest
 */
export interface FileApiFileResourceGetArchiveUrlRequest {
  /**
   * Filter by organisation
   * @type {number}
   * @memberof FileApiFileResourceGetArchiveUrl
   */
  readonly organisationId?: number;

  /**
   * Filter by entityType
   * @type {'CONTRACT' | 'PROPERTY' | 'WORKORDER' | 'WORKORDERLOG' | 'PROPERTY_CHECK' | 'GOVERNMENT_INSPECTION_WORK_ORDER' | 'ORGANISATION' | 'PORTFOLIO' | 'GOVERNMENT_INSPECTION'}
   * @memberof FileApiFileResourceGetArchiveUrl
   */
  readonly entityType?:
    | 'CONTRACT'
    | 'PROPERTY'
    | 'WORKORDER'
    | 'WORKORDERLOG'
    | 'PROPERTY_CHECK'
    | 'GOVERNMENT_INSPECTION_WORK_ORDER'
    | 'ORGANISATION'
    | 'PORTFOLIO'
    | 'GOVERNMENT_INSPECTION';

  /**
   * Filter by entityId
   * @type {number}
   * @memberof FileApiFileResourceGetArchiveUrl
   */
  readonly entityId?: number;

  /**
   * Filter by type
   * @type {'BUILDING_PERMITS' | 'DD_REPORT' | 'DRAWING' | 'EASEMENT' | 'INSPECTION' | 'INSURANCE' | 'LAND_AGREEMENT' | 'LEASE' | 'LEASEHOLD_AGREEMENT' | 'NEGOTIATION_AGREEMENT' | 'OPERATING_SERVICING_AGREEMENTS' | 'OTHER' | 'PROJECT' | 'PROPERTY_PICTURE'}
   * @memberof FileApiFileResourceGetArchiveUrl
   */
  readonly type?:
    | 'BUILDING_PERMITS'
    | 'DD_REPORT'
    | 'DRAWING'
    | 'EASEMENT'
    | 'INSPECTION'
    | 'INSURANCE'
    | 'LAND_AGREEMENT'
    | 'LEASE'
    | 'LEASEHOLD_AGREEMENT'
    | 'NEGOTIATION_AGREEMENT'
    | 'OPERATING_SERVICING_AGREEMENTS'
    | 'OTHER'
    | 'PROJECT'
    | 'PROPERTY_PICTURE';

  /**
   * Filter by subType
   * @type {'ADDITION' | 'AGREEMENT' | 'AGREEMENT_OIL_SEPARATOR' | 'AGREEMENT_PORT' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'BUILDING_PERMIT_DRAWING' | 'CONSTRUCTION_DRAWING' | 'CONTRACT_PRESSURE_VESSEL' | 'COOLING' | 'DOOR' | 'ELECTRICITY_AUDIT' | 'ELEVATOR' | 'ENERGY_DECLARATION' | 'ENVIRONMENTAL_SURVEY' | 'FINAL_INSPECTION' | 'FIRE_ALARM_TRANSMISSION' | 'FIRE_PROTECTION' | 'FLOOR_PLAN' | 'GROUND_DRAWING' | 'INSIDE' | 'INSTALLATION_DRAWING' | 'LAND_MANAGEMENT' | 'LEASEHOLD' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'LOCK_SCHEME' | 'OFFER' | 'OIL_SEPARATOR' | 'ORDER' | 'OVK' | 'PESTS' | 'PRESSURE_VESSELS' | 'PROPERTY_PICTURE' | 'RECORD_DRAWING' | 'REFRIGERANT' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'SHELTER' | 'TREATMENT_PLANT' | 'WINTER_MAINTENANCE' | 'ZONING_PLAN'}
   * @memberof FileApiFileResourceGetArchiveUrl
   */
  readonly subType?:
    | 'ADDITION'
    | 'AGREEMENT'
    | 'AGREEMENT_OIL_SEPARATOR'
    | 'AGREEMENT_PORT'
    | 'AUDIT_INSPECTION_FIRE_ALARM'
    | 'AUDIT_INSPECTION_SPRINKLER'
    | 'BUILDING_PERMIT_DRAWING'
    | 'CONSTRUCTION_DRAWING'
    | 'CONTRACT_PRESSURE_VESSEL'
    | 'COOLING'
    | 'DOOR'
    | 'ELECTRICITY_AUDIT'
    | 'ELEVATOR'
    | 'ENERGY_DECLARATION'
    | 'ENVIRONMENTAL_SURVEY'
    | 'FINAL_INSPECTION'
    | 'FIRE_ALARM_TRANSMISSION'
    | 'FIRE_PROTECTION'
    | 'FLOOR_PLAN'
    | 'GROUND_DRAWING'
    | 'INSIDE'
    | 'INSTALLATION_DRAWING'
    | 'LAND_MANAGEMENT'
    | 'LEASEHOLD'
    | 'LIFT_TABLE'
    | 'LOADING_DOCK'
    | 'LOCK_SCHEME'
    | 'OFFER'
    | 'OIL_SEPARATOR'
    | 'ORDER'
    | 'OVK'
    | 'PESTS'
    | 'PRESSURE_VESSELS'
    | 'PROPERTY_PICTURE'
    | 'RECORD_DRAWING'
    | 'REFRIGERANT'
    | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
    | 'SHELTER'
    | 'TREATMENT_PLANT'
    | 'WINTER_MAINTENANCE'
    | 'ZONING_PLAN';

  /**
   * Filter files indirectly linked to the property
   * @type {number}
   * @memberof FileApiFileResourceGetArchiveUrl
   */
  readonly propertyId?: number;
}

/**
 * Request parameters for fileResourceGetBlobUrl operation in FileApi.
 * @export
 * @interface FileApiFileResourceGetBlobUrlRequest
 */
export interface FileApiFileResourceGetBlobUrlRequest {
  /**
   * File ID
   * @type {string}
   * @memberof FileApiFileResourceGetBlobUrl
   */
  readonly id: string;

  /**
   * Whether browser should trigger file downloading or display the file inline. See Content-Disposition header for more details.
   * @type {boolean}
   * @memberof FileApiFileResourceGetBlobUrl
   */
  readonly download?: boolean;
}

/**
 * Request parameters for fileResourceGetThumbnailUrl operation in FileApi.
 * @export
 * @interface FileApiFileResourceGetThumbnailUrlRequest
 */
export interface FileApiFileResourceGetThumbnailUrlRequest {
  /**
   * File ID
   * @type {string}
   * @memberof FileApiFileResourceGetThumbnailUrl
   */
  readonly id: string;

  /**
   * Whether browser should trigger file downloading or display the file inline. See Content-Disposition header for more details.
   * @type {boolean}
   * @memberof FileApiFileResourceGetThumbnailUrl
   */
  readonly download?: boolean;

  /**
   * Thumbnail max width
   * @type {number}
   * @memberof FileApiFileResourceGetThumbnailUrl
   */
  readonly maxWidth?: number;

  /**
   * Thumbnail max height
   * @type {number}
   * @memberof FileApiFileResourceGetThumbnailUrl
   */
  readonly maxHeight?: number;
}

/**
 * Request parameters for fileResourceList operation in FileApi.
 * @export
 * @interface FileApiFileResourceListRequest
 */
export interface FileApiFileResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof FileApiFileResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof FileApiFileResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof FileApiFileResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by entityType
   * @type {'CONTRACT' | 'PROPERTY' | 'WORKORDER' | 'WORKORDERLOG' | 'PROPERTY_CHECK' | 'GOVERNMENT_INSPECTION_WORK_ORDER' | 'ORGANISATION' | 'PORTFOLIO' | 'GOVERNMENT_INSPECTION'}
   * @memberof FileApiFileResourceList
   */
  readonly entityType?:
    | 'CONTRACT'
    | 'PROPERTY'
    | 'WORKORDER'
    | 'WORKORDERLOG'
    | 'PROPERTY_CHECK'
    | 'GOVERNMENT_INSPECTION_WORK_ORDER'
    | 'ORGANISATION'
    | 'PORTFOLIO'
    | 'GOVERNMENT_INSPECTION';

  /**
   * Filter by entityId
   * @type {number}
   * @memberof FileApiFileResourceList
   */
  readonly entityId?: number;

  /**
   * Filter by type
   * @type {'BUILDING_PERMITS' | 'DD_REPORT' | 'DRAWING' | 'EASEMENT' | 'INSPECTION' | 'INSURANCE' | 'LAND_AGREEMENT' | 'LEASE' | 'LEASEHOLD_AGREEMENT' | 'NEGOTIATION_AGREEMENT' | 'OPERATING_SERVICING_AGREEMENTS' | 'OTHER' | 'PROJECT' | 'PROPERTY_PICTURE'}
   * @memberof FileApiFileResourceList
   */
  readonly type?:
    | 'BUILDING_PERMITS'
    | 'DD_REPORT'
    | 'DRAWING'
    | 'EASEMENT'
    | 'INSPECTION'
    | 'INSURANCE'
    | 'LAND_AGREEMENT'
    | 'LEASE'
    | 'LEASEHOLD_AGREEMENT'
    | 'NEGOTIATION_AGREEMENT'
    | 'OPERATING_SERVICING_AGREEMENTS'
    | 'OTHER'
    | 'PROJECT'
    | 'PROPERTY_PICTURE';

  /**
   * Filter by subType
   * @type {'ADDITION' | 'AGREEMENT' | 'AGREEMENT_OIL_SEPARATOR' | 'AGREEMENT_PORT' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'BUILDING_PERMIT_DRAWING' | 'CONSTRUCTION_DRAWING' | 'CONTRACT_PRESSURE_VESSEL' | 'COOLING' | 'DOOR' | 'ELECTRICITY_AUDIT' | 'ELEVATOR' | 'ENERGY_DECLARATION' | 'ENVIRONMENTAL_SURVEY' | 'FINAL_INSPECTION' | 'FIRE_ALARM_TRANSMISSION' | 'FIRE_PROTECTION' | 'FLOOR_PLAN' | 'GROUND_DRAWING' | 'INSIDE' | 'INSTALLATION_DRAWING' | 'LAND_MANAGEMENT' | 'LEASEHOLD' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'LOCK_SCHEME' | 'OFFER' | 'OIL_SEPARATOR' | 'ORDER' | 'OVK' | 'PESTS' | 'PRESSURE_VESSELS' | 'PROPERTY_PICTURE' | 'RECORD_DRAWING' | 'REFRIGERANT' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'SHELTER' | 'TREATMENT_PLANT' | 'WINTER_MAINTENANCE' | 'ZONING_PLAN'}
   * @memberof FileApiFileResourceList
   */
  readonly subType?:
    | 'ADDITION'
    | 'AGREEMENT'
    | 'AGREEMENT_OIL_SEPARATOR'
    | 'AGREEMENT_PORT'
    | 'AUDIT_INSPECTION_FIRE_ALARM'
    | 'AUDIT_INSPECTION_SPRINKLER'
    | 'BUILDING_PERMIT_DRAWING'
    | 'CONSTRUCTION_DRAWING'
    | 'CONTRACT_PRESSURE_VESSEL'
    | 'COOLING'
    | 'DOOR'
    | 'ELECTRICITY_AUDIT'
    | 'ELEVATOR'
    | 'ENERGY_DECLARATION'
    | 'ENVIRONMENTAL_SURVEY'
    | 'FINAL_INSPECTION'
    | 'FIRE_ALARM_TRANSMISSION'
    | 'FIRE_PROTECTION'
    | 'FLOOR_PLAN'
    | 'GROUND_DRAWING'
    | 'INSIDE'
    | 'INSTALLATION_DRAWING'
    | 'LAND_MANAGEMENT'
    | 'LEASEHOLD'
    | 'LIFT_TABLE'
    | 'LOADING_DOCK'
    | 'LOCK_SCHEME'
    | 'OFFER'
    | 'OIL_SEPARATOR'
    | 'ORDER'
    | 'OVK'
    | 'PESTS'
    | 'PRESSURE_VESSELS'
    | 'PROPERTY_PICTURE'
    | 'RECORD_DRAWING'
    | 'REFRIGERANT'
    | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
    | 'SHELTER'
    | 'TREATMENT_PLANT'
    | 'WINTER_MAINTENANCE'
    | 'ZONING_PLAN';

  /**
   * Filter by deleted status
   * @type {boolean}
   * @memberof FileApiFileResourceList
   */
  readonly deleted?: boolean;

  /**
   * Filter by archived status
   * @type {boolean}
   * @memberof FileApiFileResourceList
   */
  readonly archived?: boolean;

  /**
   * Filter files indirectly linked to the property
   * @type {number}
   * @memberof FileApiFileResourceList
   */
  readonly propertyId?: number;
}

/**
 * Request parameters for fileResourceRead operation in FileApi.
 * @export
 * @interface FileApiFileResourceReadRequest
 */
export interface FileApiFileResourceReadRequest {
  /**
   * File ID
   * @type {string}
   * @memberof FileApiFileResourceRead
   */
  readonly id: string;
}

/**
 * Request parameters for fileResourceReadFileBlob operation in FileApi.
 * @export
 * @interface FileApiFileResourceReadFileBlobRequest
 */
export interface FileApiFileResourceReadFileBlobRequest {
  /**
   * File ID
   * @type {string}
   * @memberof FileApiFileResourceReadFileBlob
   */
  readonly id: string;
}

/**
 * Request parameters for fileResourceReadImageThumbnail operation in FileApi.
 * @export
 * @interface FileApiFileResourceReadImageThumbnailRequest
 */
export interface FileApiFileResourceReadImageThumbnailRequest {
  /**
   * File ID
   * @type {string}
   * @memberof FileApiFileResourceReadImageThumbnail
   */
  readonly id: string;

  /**
   * Thumbnail max width
   * @type {number}
   * @memberof FileApiFileResourceReadImageThumbnail
   */
  readonly maxWidth?: number;

  /**
   * Thumbnail max height
   * @type {number}
   * @memberof FileApiFileResourceReadImageThumbnail
   */
  readonly maxHeight?: number;
}

/**
 * Request parameters for fileResourceUpdate operation in FileApi.
 * @export
 * @interface FileApiFileResourceUpdateRequest
 */
export interface FileApiFileResourceUpdateRequest {
  /**
   * File ID
   * @type {string}
   * @memberof FileApiFileResourceUpdate
   */
  readonly id: string;

  /**
   *
   * @type {FileInfoUpdateView}
   * @memberof FileApiFileResourceUpdate
   */
  readonly fileInfoUpdateView?: FileInfoUpdateView;
}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
  /**
   *
   * @summary Save a file
   * @param {FileApiFileResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceCreate(requestParameters: FileApiFileResourceCreateRequest = {}, options?: AxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .fileResourceCreate(requestParameters.fileCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a file
   * @param {FileApiFileResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceDelete(requestParameters: FileApiFileResourceDeleteRequest, options?: AxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .fileResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download file archive
   * @param {FileApiFileResourceDownloadArchiveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceDownloadArchive(
    requestParameters: FileApiFileResourceDownloadArchiveRequest,
    options?: AxiosRequestConfig
  ) {
    return FileApiFp(this.configuration)
      .fileResourceDownloadArchive(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a URL to download file archive
   * @param {FileApiFileResourceGetArchiveUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceGetArchiveUrl(
    requestParameters: FileApiFileResourceGetArchiveUrlRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FileApiFp(this.configuration)
      .fileResourceGetArchiveUrl(
        requestParameters.organisationId,
        requestParameters.entityType,
        requestParameters.entityId,
        requestParameters.type,
        requestParameters.subType,
        requestParameters.propertyId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return a URL to the file
   * @param {FileApiFileResourceGetBlobUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceGetBlobUrl(requestParameters: FileApiFileResourceGetBlobUrlRequest, options?: AxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .fileResourceGetBlobUrl(requestParameters.id, requestParameters.download, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return a URL to the image thumbnail
   * @param {FileApiFileResourceGetThumbnailUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceGetThumbnailUrl(
    requestParameters: FileApiFileResourceGetThumbnailUrlRequest,
    options?: AxiosRequestConfig
  ) {
    return FileApiFp(this.configuration)
      .fileResourceGetThumbnailUrl(
        requestParameters.id,
        requestParameters.download,
        requestParameters.maxWidth,
        requestParameters.maxHeight,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List files
   * @param {FileApiFileResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceList(requestParameters: FileApiFileResourceListRequest = {}, options?: AxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .fileResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.entityType,
        requestParameters.entityId,
        requestParameters.type,
        requestParameters.subType,
        requestParameters.deleted,
        requestParameters.archived,
        requestParameters.propertyId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read information of a file
   * @param {FileApiFileResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceRead(requestParameters: FileApiFileResourceReadRequest, options?: AxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .fileResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read blob of a file
   * @param {FileApiFileResourceReadFileBlobRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceReadFileBlob(
    requestParameters: FileApiFileResourceReadFileBlobRequest,
    options?: AxiosRequestConfig
  ) {
    return FileApiFp(this.configuration)
      .fileResourceReadFileBlob(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read thumbnail of an image
   * @param {FileApiFileResourceReadImageThumbnailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceReadImageThumbnail(
    requestParameters: FileApiFileResourceReadImageThumbnailRequest,
    options?: AxiosRequestConfig
  ) {
    return FileApiFp(this.configuration)
      .fileResourceReadImageThumbnail(
        requestParameters.id,
        requestParameters.maxWidth,
        requestParameters.maxHeight,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update information of a file
   * @param {FileApiFileResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public fileResourceUpdate(requestParameters: FileApiFileResourceUpdateRequest, options?: AxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .fileResourceUpdate(requestParameters.id, requestParameters.fileInfoUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
