import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Header, Icon, Loader } from 'semantic-ui-react';
import { KPIStatus } from '../KPIs/KPICard/KpiCard';
import styles from './PieCard.module.scss';
import PieCardContent from './PieCardContent';

interface Props {
  title: string;
  extra?: string | JSX.Element;
  extraIcon?: IconDefinition;
  extraColor?: string;
  data: {
    id: string;
    label: string;
    value: number;
    suffix: string;
  }[];
  centerText?: { value?: number | string; suffix?: string };
  centerAdditionalText?: string | number;
  negateValue?: boolean;
  status?: KPIStatus;
  retry?: () => void;
  additionalContent?: string;
}

const PieCard: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const getPieCardContent = () => {
    if (props.status) {
      switch (props.status) {
        case KPIStatus.Loading:
          return layoutLoading;
        case KPIStatus.None:
          return generalLayout;
        case KPIStatus.LoadError:
          return layoutLoadError;
      }
    } else return generalLayout;
  };

  const layoutLoading = (
    <div className={styles.alternateLayoutContainer}>
      <Loader active inline size="medium" />
    </div>
  );

  const layoutLoadError = (
    <div className={styles.alternateLayoutContainer}>
      <Button onClick={props.retry} secondary>
        {t('tryAgain')}
      </Button>
    </div>
  );

  const generalLayout = (
    <>
      <PieCardContent {...props} />
      {props.additionalContent && <div>{props.additionalContent}</div>}
      {props.extra && (
        <div className={styles.cardExtra} style={{ color: props.extraColor }}>
          {props.extraIcon && (
            <div>
              <Icon circular size="small" className={styles.extraIcon}>
                <FontAwesomeIcon icon={props.extraIcon} />
              </Icon>
            </div>
          )}
          <div>{props.extra}</div>
        </div>
      )}
    </>
  );

  return (
    <Card className={styles.card}>
      <Card.Content className={styles.cardContent}>
        <Header className={styles.cardHeader} as="h4">
          {props.title}
        </Header>
        {getPieCardContent()}
      </Card.Content>
    </Card>
  );
};

export default PieCard;
