import React from 'react';
import { useDidMountEffect } from '../../../Utils/hooks';

export const useStreetView = (props: { map: google.maps.Map | null }) => {
  const [isInStreetView, setIsInStreetView] = React.useState<boolean>(false);
  const [streetViewPosition, setStreetViewPosition] = React.useState<google.maps.LatLngLiteral | undefined>();

  useDidMountEffect(() => {
    let streetViewVisibilityListener: google.maps.MapsEventListener;
    let streetViewPositionChangeListener: google.maps.MapsEventListener;

    if (props.map) {
      streetViewVisibilityListener = props.map.getStreetView().addListener('visible_changed', () => {
        if (props.map?.getStreetView().getVisible()) {
          setIsInStreetView(true);
        } else setIsInStreetView(false);
      });
      streetViewPositionChangeListener = props.map.getStreetView().addListener('position_changed', () => {
        const position = props.map?.getStreetView().getPosition();
        if (position)
          setStreetViewPosition({
            lat: position.lat(),
            lng: position.lng()
          });
        else setStreetViewPosition(undefined);
      });
    }
    return () => {
      streetViewVisibilityListener?.remove();
      streetViewPositionChangeListener?.remove();
    };
  }, [props.map]);

  return { isInStreetView, streetViewPosition };
};
