import { WorkOrderApiWorkOrderResourceListRequest } from '../GeneratedServices';

export type FaultReportStorageParams = Pick<
  WorkOrderApiWorkOrderResourceListRequest,
  | 'organisationIds'
  | 'portfolioIds'
  | 'propertySubscriptionUserIds'
  | 'assigneeIds'
  | 'tenantIds'
  | 'propertyIds'
  | 'municipalities'
  | 'categories'
  | 'statuses'
  | 'createdAfter'
  | 'createdBefore'
>;

const faultReportStorageParamsInstance: FaultReportStorageParams = {
  assigneeIds: [],
  categories: [],
  createdAfter: '',
  createdBefore: '',
  municipalities: [],
  organisationIds: [],
  portfolioIds: [],
  propertyIds: [],
  propertySubscriptionUserIds: [],
  statuses: [],
  tenantIds: []
};

export const faultReportStorageFilterKeys = Object.keys(
  faultReportStorageParamsInstance
) as (keyof FaultReportStorageParams)[];

export const getFiltersCount = (
  filterParams: WorkOrderApiWorkOrderResourceListRequest,
  customFilterKeys?: (keyof FaultReportStorageParams)[]
) => {
  const { statuses, createdBefore, createdAfter, ...rest } = filterParams;

  const filterKeys = new Set(customFilterKeys ?? faultReportStorageFilterKeys);

  let count = 0;
  if (createdBefore || createdAfter) {
    count++;
  }
  if (statuses) {
    count += 3 - statuses.length;
  }
  Object.entries(rest).forEach((item) =>
    filterKeys.has(item[0] as keyof FaultReportStorageParams) && typeof item[1] === 'object' && item[1].length !== 0
      ? count++
      : null
  );

  return count;
};
