import {
  MembershipListView,
  MembershipListViewParams,
  MembershipReadView,
  MembershipUpdateView
} from './MembershipService.types';
import { BAM_API_BASE_URL } from '../config';
import { http } from '../Http/Http';
import { AxiosRequestConfig } from 'axios';
import { UserReadView } from '../GeneratedServices';

export interface ObjectifiedMembershipListView extends MembershipListView {
  records: ObjectifiedMembershipReadView[];
}

export interface ObjectifiedMembershipReadView extends MembershipReadView {
  user: UserReadView;
}

export const getMemberships = async (
  params: MembershipListViewParams,
  config?: AxiosRequestConfig
): Promise<MembershipListView> => {
  const { data } = await http.get<MembershipListView>(`${BAM_API_BASE_URL}/v1/memberships`, {
    ...config,
    params
  });
  return data;
};

export const updateMembership = async (
  membershipId: number,
  payload: MembershipUpdateView,
  config?: AxiosRequestConfig
) => {
  const { data } = await http.patch<MembershipReadView>(
    `${BAM_API_BASE_URL}/v1/memberships/${membershipId}`,
    payload,
    config
  );
  return data;
};

export const deleteMembership = async (membershipId: number, config?: AxiosRequestConfig) => {
  await http.delete(`${BAM_API_BASE_URL}/v1/memberships/${membershipId}`, config);
};
