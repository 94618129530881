import { BusinessPlanCostReadViewCategoryEnum, BusinessPlanLeaseReadViewObjectTypeEnum } from '../GeneratedServices';
import i18n from '../i18n';

export const translateBusinessPlanCostCategory = (data: BusinessPlanCostReadViewCategoryEnum) => {
  switch (data) {
    case BusinessPlanCostReadViewCategoryEnum.Cleaning:
      return i18n.t('contracts:costCategory.cleaning');
    case BusinessPlanCostReadViewCategoryEnum.Cooling:
      return i18n.t('contracts:costCategory.cooling');
    case BusinessPlanCostReadViewCategoryEnum.Electricity:
      return i18n.t('contracts:costCategory.electricity');
    case BusinessPlanCostReadViewCategoryEnum.Waste:
      return i18n.t('contracts:costCategory.waste');
    case BusinessPlanCostReadViewCategoryEnum.Heating:
      return i18n.t('contracts:costCategory.heating');
    case BusinessPlanCostReadViewCategoryEnum.Insurance:
      return i18n.t('contracts:costCategory.insurance');
    case BusinessPlanCostReadViewCategoryEnum.LeaseholdFee:
      return i18n.t('contracts:costCategory.leaseHoldFee');
    case BusinessPlanCostReadViewCategoryEnum.PeriodicMaintenance:
      return i18n.t('contracts:costCategory.periodicMaintenance');
    case BusinessPlanCostReadViewCategoryEnum.Marketing:
      return i18n.t('contracts:costCategory.marketing');
    case BusinessPlanCostReadViewCategoryEnum.OtherOpex:
      return i18n.t('contracts:costCategory.otherOpex');
    case BusinessPlanCostReadViewCategoryEnum.PropertyManagement:
      return i18n.t('contracts:costCategory.propertyManagement');
    case BusinessPlanCostReadViewCategoryEnum.PropertyTax:
      return i18n.t('contracts:costCategory.propertyTax');
    case BusinessPlanCostReadViewCategoryEnum.Repairs:
      return i18n.t('contracts:costCategory.repairs');
    case BusinessPlanCostReadViewCategoryEnum.Security:
      return i18n.t('contracts:costCategory.security');
    case BusinessPlanCostReadViewCategoryEnum.ServiceAgreements:
      return i18n.t('contracts:costCategory.serviceAgreements');
    case BusinessPlanCostReadViewCategoryEnum.SnowRemovalGardening:
      return i18n.t('contracts:costCategory.snowRemovalAndGardening');
    case BusinessPlanCostReadViewCategoryEnum.WaterSewage:
      return i18n.t('contracts:costCategory.waterAndSewage');
  }
};

export const translateObjectType = (data: BusinessPlanLeaseReadViewObjectTypeEnum) => {
  switch (data) {
    case BusinessPlanLeaseReadViewObjectTypeEnum.Apartment:
      return i18n.t('contracts:objectType.apartment');
    case BusinessPlanLeaseReadViewObjectTypeEnum.StudentApartment:
      return i18n.t('contracts:objectType.studentApartment');
    case BusinessPlanLeaseReadViewObjectTypeEnum.BusinessPremises:
      return i18n.t('contracts:objectType.businessPremises');
    case BusinessPlanLeaseReadViewObjectTypeEnum.Antenna:
      return i18n.t('contracts:objectType.antenna');
    case BusinessPlanLeaseReadViewObjectTypeEnum.BlockRental:
      return i18n.t('contracts:objectType.blockRental');
    case BusinessPlanLeaseReadViewObjectTypeEnum.BuildingRights:
      return i18n.t('contracts:objectType.buildingRights');
    case BusinessPlanLeaseReadViewObjectTypeEnum.Depot:
      return i18n.t('contracts:objectType.depot');
    case BusinessPlanLeaseReadViewObjectTypeEnum.CommunityFacility:
      return i18n.t('contracts:objectType.communityFacility');
    case BusinessPlanLeaseReadViewObjectTypeEnum.Garage:
      return i18n.t('contracts:objectType.garage');
    case BusinessPlanLeaseReadViewObjectTypeEnum.House:
      return i18n.t('contracts:objectType.house');
    case BusinessPlanLeaseReadViewObjectTypeEnum.IndustrialPremises:
      return i18n.t('contracts:objectType.industrialPremises');
    case BusinessPlanLeaseReadViewObjectTypeEnum.Parking:
      return i18n.t('contracts:objectType.parking');
    case BusinessPlanLeaseReadViewObjectTypeEnum.DisplaySpace:
      return i18n.t('contracts:objectType.displaySpace');
    case BusinessPlanLeaseReadViewObjectTypeEnum.Premises:
      return i18n.t('contracts:objectType.premises');
    case BusinessPlanLeaseReadViewObjectTypeEnum.NursingHome:
      return i18n.t('contracts:objectType.nursingHome');
    case BusinessPlanLeaseReadViewObjectTypeEnum.Storage:
      return i18n.t('contracts:objectType.storage');
    case BusinessPlanLeaseReadViewObjectTypeEnum.Land:
      return i18n.t('contracts:objectType.land');
  }
};
