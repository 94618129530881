import React from 'react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import NumberFormater from '../NumberFormater/NumberFormater';
import KpiCard, { KPISize, KPIStatus } from './KPICard/KpiCard';
import { statisticsApi } from '../../Http/Http';
import { ObjectsCountsReadView } from '../../GeneratedServices';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}

const LeaseCountKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [leaseCountStatistics, setLeaseCountStatistics] = React.useState<ObjectsCountsReadView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceGetObjectsCounts(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setLeaseCountStatistics(data);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();
    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  return (
    <KpiCard
      title={t('lettedSpaces')}
      valueAs="h5"
      value={
        <span>
          <NumberFormater value={leaseCountStatistics?.occupiedCount} />/
          <NumberFormater value={leaseCountStatistics?.totalCount} />
        </span>
      }
      status={status}
      retry={reload}
      size={KPISize.small}
    />
  );
};

export default LeaseCountKpi;
