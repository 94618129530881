import { DropdownItemProps } from 'semantic-ui-react';
import { ListViewPaginationParams, ListViewPagination } from './types';

export interface PortfolioListViewParams extends ListViewPaginationParams {
  organisationId?: number;
}

export interface PortfolioListView extends ListViewPagination<PortfolioReadView> {}

export interface PortfolioReadView {
  id: number;
  name: string;
  organisationId: number;
  companyIds: number[];
  userIds: number[];
}

export interface PortfolioCreateView {
  name: string;
  organisationId: number;
}

export interface PortfolioUpdateView {
  name?: string;
  companyIds?: number[];
  userIds?: number[];
}

export const mapPortfoliosToDropdownItems = (portfolios: PortfolioReadView[]): DropdownItemProps[] => {
  return portfolios.map((portfolio) => ({
    text: portfolio.name,
    value: portfolio.id,
    key: portfolio.id
  }));
};
