import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Button } from 'semantic-ui-react';
import FilterButton from '../../../Components/Filters/FilterButton';
import { WorkOrderApiWorkOrderResourceListRequest } from '../../../GeneratedServices';
import { FaultReportStorageParams, getFiltersCount } from '../../../Services/FaultReportService';
import { useNonce } from '../../../Utils/hooks';
import CreateFaultReportModal from '../../FaultReports/Components/CreateFaultReportModal';
import FaultReportFiltersModal from '../../FaultReports/Components/FaultReportsList/FaultReportFiltersModal';
import FaultReportTable from '../../FaultReports/Components/FaultReportsList/FaultReportTable';

interface Props {
  propertyId: number;
}

const faultReportFilterKeys: (keyof FaultReportStorageParams)[] = [
  'categories',
  'createdAfter',
  'createdBefore',
  'assigneeIds',
  'statuses',
  'tenantIds'
];

const TabFaultReports: React.FC<Props> = (props) => {
  const { t } = useTranslation(['faultReports', 'common']);

  const [isNewFaultReportModalOpen, setNewFaultReportModalOpen] = React.useState(false);
  const [faultReportsReloadCount, incrementFaultReportsReloadCount] = useNonce();

  const [isFilterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);
  const [faultReportParams, setFaultReportParams] = React.useState<WorkOrderApiWorkOrderResourceListRequest>({
    propertyIds: [props.propertyId]
  });

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10 }}>
        <Header as="h3">{t('common:faultReports')}</Header>
        <div>
          <Button primary className="no-line-break" onClick={() => setNewFaultReportModalOpen(true)}>
            {t('faultReports:newFaultReport')}
          </Button>
          <FilterButton
            count={getFiltersCount(faultReportParams, faultReportFilterKeys)}
            openFiltersModal={() => setFilterModalOpen(true)}
          />
        </div>
      </div>

      <FaultReportTable filterParams={faultReportParams} reloadCount={faultReportsReloadCount} />

      {isFilterModalOpen && (
        <FaultReportFiltersModal
          onClose={() => setFilterModalOpen(false)}
          onConfirm={(params) => {
            setFaultReportParams(params);
          }}
          filterParams={faultReportParams}
          canCacheParams={false}
          filterKeys={faultReportFilterKeys}
        />
      )}

      {isNewFaultReportModalOpen && (
        <CreateFaultReportModal
          propertyId={props.propertyId}
          onCreated={() => {
            incrementFaultReportsReloadCount();
            setNewFaultReportModalOpen(false);
          }}
          onClose={() => setNewFaultReportModalOpen(false)}
          governmentInspectionWorkOrder={undefined}
        />
      )}
    </>
  );
};

export default TabFaultReports;
