import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic-ui-react';
import { GovernmentInspectionItemResultUpdateView } from '../../../GeneratedServices';
import { generateGovernmentInspectionItemGrade } from '../../../Services/GovernmentInspectionService';
import { toDateString } from '../../../Utils/date';
import DatePicker from '../../DatePicker/DatePicker';

interface Props {
  onClose: () => void;
  onConfirm: (data: Omit<GovernmentInspectionItemResultUpdateView, 'itemId'>) => void;
}

const InspectionItemResultDefaultsModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'inspections']);

  const [passed, setPassed] = React.useState<boolean>(true);
  const [performed, setPerformed] = React.useState<Date>();
  const [nextInspectionDeadline, setNextInspectionDeadline] = React.useState<Date>();
  const [comment, setComment] = React.useState<string>();

  return (
    <Modal open onClose={props.onClose} closeIcon size="mini" closeOnDimmerClick={false}>
      <Modal.Header>{t('common:results')}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            width={6}
            selection
            compact
            required
            label={t('inspections:itemGrade')}
            upward
            scrolling
            value={passed}
            onChange={(e, d) => setPassed(d.value as boolean)}
            options={generateGovernmentInspectionItemGrade()}
          />

          <Form.Field required>
            <label>{t('inspections:performDate')}</label>
            <DatePicker
              popperPlacement="top"
              selected={performed}
              dateFormat="P"
              placeholderText={'YYYY-MM-DD'}
              onChange={(d) => setPerformed(d ?? undefined)}
              customInput={<Form.Input icon="calendar alternate outline" iconPosition="left" />}
            />
          </Form.Field>

          <Form.Field required>
            <label>{t('inspections:nextDeadline')}</label>
            <DatePicker
              popperPlacement="top"
              selected={nextInspectionDeadline}
              dateFormat="P"
              placeholderText={'YYYY-MM-DD'}
              onChange={(d) => setNextInspectionDeadline(d ?? undefined)}
              customInput={<Form.Input icon="calendar alternate outline" iconPosition="left" />}
            />
          </Form.Field>

          <Form.TextArea
            required
            label={t('common:comment')}
            rows={3}
            value={comment ?? undefined}
            placeholder="Aa"
            onChange={(e, d) => setComment(d.value as string)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            props.onConfirm({
              comment: comment ?? null,
              passed,
              nextInspectionDeadline: toDateString(nextInspectionDeadline!),
              performed: toDateString(performed!)
            });
            props.onClose();
          }}
          disabled={!nextInspectionDeadline || !performed}
        >
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default InspectionItemResultDefaultsModal;
