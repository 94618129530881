import { LanguageDetectorAsyncModule } from 'i18next';

const DEFAULT_LANGUAGE = 'SV';
const LANGUAGE_STORAGE_KEY = 'preferredLanguage';

export const languageDetectorPlugin: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  init() {},
  detect: (callback: (lng: string) => void) => {
    callback(localStorage.getItem(LANGUAGE_STORAGE_KEY) ?? DEFAULT_LANGUAGE);
  },
  async cacheUserLanguage(language: string) {
    localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
  }
};
