/**
 * Determines whether two strings are equivalent using the swedish locale.
 * @param s1 String to compare.
 * @param s2 String to compare.
 */
export const localeCompareSv = (s1: string, s2: string): number => s1.localeCompare(s2, 'sv');

/**
 * Clean out non-numeric characters in the string.
 * @param data String to compare.
 */
export const getNumbersOnly = (data: string): string => data.replace(/\D/g, '');

/**
 * Format string to have uppercase first letter of each word and lowercase rest.
 * @param str String to format.
 */
export const toTitleCase = (str: string) =>
  str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

/**
 * Convert a string to a valid href (specify a new root address).
 * @param str String to format.
 */
export const toValidHref = (str: string) => {
  return str.startsWith('http') ? str : `https://${str}`;
};

/**
 * Convert a full name string to a 2 letter initials.
 * Foo will be converted to F.
 * Foo Bar will be converted to FB.
 * Foo Bar Name will be converted to FN.
 * @param name Full name
 */
export const toInitials = (name: string) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...name.matchAll(rgx)] || [];

  return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
};

/**
 * Checks wheather any entity in an array of strings contains a query.
 * @param query Query to search by
 * @param query Entities to compare
 */
export const isIncludedInAny = (query: string, entities: string[]) => {
  const normalizedQuery = query.toLocaleLowerCase();
  const normalizedEntities = entities.map((item) => item.toLocaleLowerCase());
  return normalizedEntities.some((item) => item.includes(normalizedQuery));
};
