import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../../FiltersProvider/FiltersProvider';
import { AdditionReadViewTypeEnum, AdditionsByTypeReadView } from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { statisticsApi } from '../../../Http/Http';
import { translateAdditionalType } from '../../../Services/ContractService.types';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { useNonce } from '../../../Utils/hooks';
import { formatCurrency, formatNumber, getComparedPercentage } from '../../../Utils/number';
import { KPIStatus } from '../../KPIs/KPICard/KpiCard';
import NumberFormater from '../../NumberFormater/NumberFormater';
import PieCard from '../../PieCard/PieCard';
import { faArrowDown, faArrowUp, faMinus } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
  reloadNonce?: number;
}
const AdditionsKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const { filterValues, propertyId } = props;

  const [additionsStatistics, setAdditionsStatistics] = React.useState<AdditionsByTypeReadView>();
  const [additionsStatus, setAdditionsStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReloadAdditions, reloadAdditions] = useNonce();

  const { additions, reduction } = React.useMemo(
    () =>
      additionsStatistics?.additions.reduce(
        (result, element) => {
          if (element.type === AdditionReadViewTypeEnum.RentReduction) result.reduction = element.actualValue;
          else
            result.additions.push({
              id: element.type,
              label: translateAdditionalType(element.type),
              ...formatCurrency(element.actualValue / additionsStatistics!.actualArea, {
                unit: t('common:sekSqm'),
                forceCurrency: 'SEK'
              })
            });
          return result;
        },
        { reduction: 0, additions: [] } as {
          reduction: number;
          additions: {
            id: string;
            label: string;
            value: number;
            suffix: string;
          }[];
        }
      ) ?? { reduction: 0, additions: [] },
    [additionsStatistics, t]
  );

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setAdditionsStatus(KPIStatus.Loading);
        const response = await statisticsApi.statisticsResourceGetAdditionsByTypeBreakdown(
          {
            ...filterValues,
            propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setAdditionsStatistics(response.data);
        setAdditionsStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setAdditionsStatus(KPIStatus.LoadError);
      }
    };
    load();
    return () => abortController.abort();
  }, [filterValues, propertyId, handleHttpErrors, shouldReloadAdditions, props.reloadNonce]);

  const comparedAdditions =
    additionsStatistics?.plannedTotal &&
    getComparedPercentage(additionsStatistics.actualTotal, additionsStatistics.plannedTotal);

  const actualTotalPerArea = (additionsStatistics?.actualTotal ?? 0) / (additionsStatistics?.actualArea ?? 1);

  return (
    <PieCard
      title={t('contracts:additions')}
      extra={
        comparedAdditions ? (
          <NumberFormater
            value={comparedAdditions}
            suffix={t('common:vsBusinessPlan')}
            additionalProps={{ decimalScale: 1 }}
          />
        ) : (
          t('common:noBusinessPlanInformation')
        )
      }
      data={additions}
      extraColor={comparedAdditions ? (comparedAdditions > 0 ? colors.green : colors.red) : colors.grey}
      extraIcon={comparedAdditions ? (comparedAdditions > 0 ? faArrowUp : faArrowDown) : faMinus}
      status={additionsStatus}
      retry={reloadAdditions}
      centerText={
        additionsStatistics?.actualTotal
          ? {
              value: formatNumber(
                parseFloat(
                  formatCurrency(additionsStatistics?.actualTotal ?? 0, {
                    forceCurrency: 'KSEK'
                  }).value.toFixed(0)
                )
              ),
              suffix: 'KSEK'
            }
          : { value: t('common:noData') }
      }
      centerAdditionalText={
        actualTotalPerArea
          ? `${formatCurrency(actualTotalPerArea, { forceCurrency: 'SEK' }).value.toFixed(0)} ${t('common:sekSqm')}`
          : undefined
      }
      additionalContent={`${t('contracts:additionalType.rentReduction')}: ${formatNumber(
        parseInt(
          formatCurrency(reduction, {
            forceCurrency: 'KSEK'
          }).value.toFixed(0)
        )
      )} KSEK`}
    />
  );
};
export default AdditionsKpi;
