import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Icon } from 'semantic-ui-react';
import { FileInfoReadView, FileInfoReadViewSubTypeEnum } from '../../GeneratedServices';
import { FileSubType, translateFileSubType, FileInfoReadView as FileReadView } from '../../Services/FileService.types';
import FileCardList from '../FileCardList/FileCardList';

interface Props {
  files: FileInfoReadView[];
  reloadFiles: () => void;
  emptyLayout?: JSX.Element;
  readOnly?: boolean;
}

const FileSubTypeAccordion: React.FC<Props> = (props) => {
  const { files, emptyLayout, reloadFiles, readOnly } = props;

  const { t } = useTranslation(['files']);

  const [expandedFileSubTypes, setExpandedFileSubTypes] = React.useState<Set<FileInfoReadViewSubTypeEnum>>(new Set());

  const fileSubTypeMap = React.useMemo(
    () =>
      files
        .filter((item) => item.subType)
        .reduce((result, element) => {
          return result.set(element.subType!, [...(result.get(element.subType!) || []), element]);
        }, new Map<FileInfoReadViewSubTypeEnum, FileInfoReadView[]>()),
    [files]
  );

  if (files.length === 0) return emptyLayout ?? <div>{t('files:noFilesFound')}</div>;
  return (
    <Accordion styled fluid>
      {Array.from(fileSubTypeMap.keys()).map((item) => (
        <Accordion.Panel
          active={expandedFileSubTypes.has(item)}
          title={
            <Accordion.Title
              onClick={() => {
                setExpandedFileSubTypes((prevValue) => {
                  const setCopy = new Set(prevValue);
                  if (setCopy.has(item)) setCopy.delete(item);
                  else setCopy.add(item);

                  return setCopy;
                });
              }}
            >
              <Icon name="dropdown" />
              {translateFileSubType(item as FileSubType)}
            </Accordion.Title>
          }
          content={
            <Accordion.Content style={{ fontSize: 12 }}>
              <FileCardList
                files={fileSubTypeMap.get(item) as FileReadView[]}
                reloadFiles={reloadFiles}
                readOnly={readOnly}
              />
            </Accordion.Content>
          }
          key={item}
        />
      ))}
    </Accordion>
  );
};

export default FileSubTypeAccordion;
