/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface WorkOrderCreateView
 */
export interface WorkOrderCreateView {
  /**
   * Description.
   * @type {string}
   * @memberof WorkOrderCreateView
   */
  description: string;
  /**
   * Files to attach to the work order.
   * @type {Array<string>}
   * @memberof WorkOrderCreateView
   */
  fileIds?: Array<string>;
  /**
   * Type.
   * @type {string}
   * @memberof WorkOrderCreateView
   */
  type: WorkOrderCreateViewTypeEnum;
  /**
   * Users to assign to the work order.
   * @type {Array<number>}
   * @memberof WorkOrderCreateView
   */
  userIds?: Array<number>;
}

export const WorkOrderCreateViewTypeEnum = {
  Default: 'DEFAULT',
  FailedGovernmentInspection: 'FAILED_GOVERNMENT_INSPECTION'
} as const;

export type WorkOrderCreateViewTypeEnum =
  (typeof WorkOrderCreateViewTypeEnum)[keyof typeof WorkOrderCreateViewTypeEnum];
