import React from 'react';
// Track updates to @react-google-maps/api which should resolve the issues with Marker component not re-rendering when its props change ( label, icon etc)
import { MarkerClustererF as MarkerClusterer, Marker } from '@react-google-maps/api';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { ComparablePropertyForMap } from '../../../Views/Analytics/Analytics';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { ComparablePropertyReadView, PointView } from '../../../GeneratedServices';
import { parseNatural } from '../../../Utils/safe-parse';
import { PropertyReadView } from '../../../Services/PropertyService.types';

export const getMarkersContent = ({
  properties,
  areMarkersClustered,
  handleMarkerClick,
  handleUnmount,
  disabled,
  label,
  isInStreetView,
  streetViewPosition
}: {
  properties: (ComparablePropertyForMap | PropertyReadView | ComparablePropertyReadView)[];
  handleMarkerClick: (item: ComparablePropertyForMap | PropertyReadView | ComparablePropertyReadView) => void;
  handleUnmount: () => void;
  areMarkersClustered: boolean;
  disabled?: boolean;
  label?: boolean;
  isInStreetView?: boolean;
  streetViewPosition?: google.maps.LatLngLiteral;
}) => {
  if (properties.length === 0) {
    return null;
  }
  if (areMarkersClustered)
    // Max zoom is required to make sure clusters disappear when zoomed in to a certain level.
    return (
      <MarkerClusterer
        maxZoom={15}
        averageCenter
        onLoad={(a) => {
          a.setStyles(a.styles.map((item) => ({ ...item, textColor: colors.white })));
        }}
      >
        {(clusterer) => (
          <>
            {getMarkers(
              properties,
              handleMarkerClick,
              handleUnmount,
              disabled,
              label,
              clusterer,
              isInStreetView,
              streetViewPosition
            )}
          </>
        )}
      </MarkerClusterer>
    );
  else
    return getMarkers(
      properties,
      handleMarkerClick,
      handleUnmount,
      disabled,
      label,
      undefined,
      isInStreetView,
      streetViewPosition
    );
};

//type guard for assignments array
function isComparable(
  item: ComparablePropertyForMap | PropertyReadView | ComparablePropertyReadView
): item is ComparablePropertyForMap {
  return (item as ComparablePropertyForMap).owned !== undefined;
}
const getMarkers = (
  properties: (ComparablePropertyForMap | PropertyReadView | ComparablePropertyReadView)[],
  handleMarkerClick: (item: ComparablePropertyForMap | PropertyReadView | ComparablePropertyReadView) => void,
  handleUnmount: () => void,
  disabled?: boolean,
  label?: boolean,
  clusterer?: Clusterer,
  isInStreetView?: boolean,
  streetViewPosition?: google.maps.LatLngLiteral
) => {
  let previousPosition: PointView | null;
  let circleOffset = Math.PI;
  const space = 0.00001;
  return properties?.map((item, i) => {
    const index = parseNatural(item.label) ?? 0;
    const fontSize = index < 10 ? undefined : index < 100 ? '14px' : index < 1000 ? '12px' : '10px';
    let position: PointView | null;

    // The below logic would place markers with exact same position in a
    // spiral shape to make sure that they don't stack into a single marker
    // and can be clicked individually.
    if (previousPosition && previousPosition.lat === item.position?.lat && previousPosition.lng === item.position.lng) {
      position = {
        lat: item.position.lat + space * Math.sin(circleOffset) * (circleOffset / (2 * Math.PI)),
        lng:
          item.position.lng -
          (space /
            // Longitude length depends on the latitude, so we need to make an
            // adjustment to have a circular spiral instead of oval closer to the
            // poles.
            // See https://www.quora.com/How-many-meters-make-up-a-degree-of-longitude-latitude-on-Earth
            Math.cos(Math.PI * (item.position.lat / 180))) *
            Math.cos(circleOffset) *
            (circleOffset / (2 * Math.PI))
      };
      circleOffset += Math.PI / 4;
    } else {
      position = item.position;
      circleOffset = Math.PI;
    }

    previousPosition = item.position;

    const markerUrl = isComparable(item) && !item.owned ? '/whiteMarker.png' : '/greenMarker.png';

    let computedDistance;
    let markerSize = new google.maps.Size(42, 42);
    let labelOrigin = new google.maps.Point(21, 16);
    if (position && streetViewPosition && isInStreetView) {
      //Scale marker size and label position in street view
      const pos1 = new google.maps.LatLng(position.lat, position.lng);
      const pos2 = new google.maps.LatLng(streetViewPosition.lat, streetViewPosition.lng);
      computedDistance = google.maps.geometry.spherical.computeDistanceBetween(pos1, pos2);
      if (computedDistance > 30) {
        markerSize = new google.maps.Size(74, 74);
        labelOrigin = new google.maps.Point(37, 32);
      } else if (computedDistance > 20) {
        markerSize = new google.maps.Size(49, 49);
        labelOrigin = new google.maps.Point(25, 19);
      }
    }

    if (position) {
      return (
        <Marker
          position={position}
          label={!disabled && label && item.label ? { text: item.label, fontSize } : undefined}
          onClick={() => {
            handleMarkerClick(item);
          }}
          key={i}
          icon={{
            url: markerUrl,
            size: markerSize,
            scaledSize: markerSize,
            labelOrigin: labelOrigin
          }}
          onUnmount={handleUnmount}
          clusterer={clusterer}
          options={{
            optimized: true
          }}
          //Only show markers within 35 meters in Street View
          visible={isInStreetView && computedDistance && computedDistance > 35 ? false : true}
          // There seems to be a general issue with the clusterer component where the clusters by default are being redrawn
          // whenever a marker is added, which in the cases where many markers are present ends up rerendering all the clusters for each marker
          // and causing performance issues.
          // To tackle this there's the noClustererRedraw flag which prevents clusterer rerendering.
          // Since we need the clusters to redraw when the markers change, one way of handling it is to hold off on rerendering them until the last
          // marker has been added and pass a false flag there.
          // https://github.com/JustFly1984/react-google-maps-api/issues/2849#issuecomment-913696819
          // https://github.com/tomchentw/react-google-maps/issues/836#issuecomment-894381349
          noClustererRedraw={i === properties.length - 1 ? false : true}
        />
      );
    }
    return null;
  });
};
