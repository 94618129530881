import { globalStateCTX } from '../../GlobalState/GlobalState';
import React from 'react';
import { Button, Dropdown, DropdownItemProps, Form, Modal } from 'semantic-ui-react';
import * as validation from '../../Utils/validation';
import { UserFriendlyApiResponse } from '../../Http/response-error';
import { useDidMountEffect } from '../../Utils/hooks';
import {
  getReocurrenceSortIndex,
  isReoccurrenceMonth,
  isReoccurrenceWeek,
  isReoccurrenceYear,
  PropertyCheckReadView,
  PropertyCheckReoccurrence,
  translatePropertyCheckReoccurrence
} from '../../Services/PropertyCheckService.types';
import * as PropertyCheckService from '../../Services/PropertyCheckService';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  action: Action;
  onClose: (saved: boolean) => void;
}

type TypeAddToProperty = { propertyId: number };
type TypeAddToOrganisation = { organisationId: number };
type TypeEdit = { propertyCheck: PropertyCheckReadView };
type Action = TypeAddToProperty | TypeAddToOrganisation | TypeEdit;

enum FormFieldId {
  title = 'title',
  description = 'description',
  reoccurrence = 'reoccurrence'
}

enum Status {
  None,
  Saving
}

function resolveTypeEdit(action: Action) {
  return 'propertyCheck' in action ? (action as TypeEdit) : undefined;
}

function resolveTypeAddToProperty(action: Action) {
  return 'propertyId' in action ? (action as TypeAddToProperty) : undefined;
}

function resolveTypeAddToOrganisation(action: Action) {
  return 'organisationId' in action ? (action as TypeAddToOrganisation) : undefined;
}

const EditPropertyCheckModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'propertyChecks', 'validation']);

  const propsTypeEdit = resolveTypeEdit(props.action);
  const propsTypeAddToProperty = resolveTypeAddToProperty(props.action);
  const propsTypeAddToOrganisation = resolveTypeAddToOrganisation(props.action);

  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);

  const [status, setStatus] = React.useState(Status.None);

  const [title, setTitle] = React.useState<string>(propsTypeEdit?.propertyCheck.title ?? '');
  const [titleValidationResult, setTitleValidationResult] = React.useState<validation.FieldValidationResult>();

  const [description, setDescription] = React.useState<string>(propsTypeEdit?.propertyCheck.description ?? '');
  const [descriptionValidationResult, setDescriptionValidationResult] =
    React.useState<validation.FieldValidationResult>();

  const [reoccurrence, setReoccurrence] = React.useState<PropertyCheckReoccurrence | undefined>(
    propsTypeEdit?.propertyCheck.reoccurrence
  );
  const [reoccurrenceValidationResult, setReoccurrenceValidationResult] =
    React.useState<validation.FieldValidationResult>();

  useDidMountEffect(() => {
    validateTitle();
  }, [title]);
  useDidMountEffect(() => {
    validateDescription();
  }, [description]);
  useDidMountEffect(() => {
    validateReoccurrence();
  }, [reoccurrence]);

  React.useEffect(() => {
    if (propsTypeEdit) {
      setTitle(propsTypeEdit.propertyCheck.title);
      setDescription(propsTypeEdit.propertyCheck.description);
      setReoccurrence(propsTypeEdit.propertyCheck.reoccurrence);
    }
  }, [propsTypeEdit]);

  const savePropertyCheck = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setStatus(Status.Saving);

      if (propsTypeEdit) {
        await PropertyCheckService.updatePropertyCheck(propsTypeEdit.propertyCheck.id, {
          [FormFieldId.title]: title!,
          [FormFieldId.description]: description!,
          [FormFieldId.reoccurrence]: reoccurrence!
        });

        toast.info(
          <Trans i18nKey="propertyChecks:controlPointUpdatedMessage" t={t}>
            Kontrollpunkten <strong>{{ title }}</strong> har uppdaterats.
          </Trans>
        );
      } else {
        await PropertyCheckService.createPropertyCheck({
          [FormFieldId.title]: title!,
          [FormFieldId.description]: description!,
          [FormFieldId.reoccurrence]: reoccurrence!,
          propertyId: propsTypeAddToProperty?.propertyId,
          organisationId: propsTypeAddToOrganisation?.organisationId
        });

        toast.info(
          <Trans i18nKey="propertyChecks:controlPointCreatedMessage" t={t}>
            Kontrollpunkten <strong>{{ title }}</strong> har skapats.
          </Trans>
        );
      }

      props.onClose(true);
    } catch (error) {
      globalState.handleHttpErrors(error, { handleResponseValidationError });
    } finally {
      setStatus(Status.None);
    }
  };

  const validateTitle = () => {
    return validation.validateRequiredField(title, setTitleValidationResult, t('validation:enterTitle'));
  };

  const validateDescription = () => {
    return validation.validateRequiredField(
      description,
      setDescriptionValidationResult,
      t('validation:enterDescription')
    );
  };

  const validateReoccurrence = () => {
    return validation.validateRequiredEnum(
      PropertyCheckReoccurrence,
      reoccurrence,
      setReoccurrenceValidationResult,
      t('validation:enterReoccurrence')
    );
  };

  const validateForm = () => {
    const titleValid = validateTitle();
    const descriptionValid = validateDescription();
    const reoccurrenceValid = validateReoccurrence();

    return titleValid && descriptionValid && reoccurrenceValid;
  };

  const handleResponseValidationError = (resp: UserFriendlyApiResponse) => {
    const fieldIdMapping = [
      {
        formFieldId: FormFieldId.title,
        setStateFunc: setTitleValidationResult
      },
      {
        formFieldId: FormFieldId.description,
        setStateFunc: setDescriptionValidationResult
      },
      {
        formFieldId: FormFieldId.reoccurrence,
        setStateFunc: setReoccurrenceValidationResult
      }
    ];

    validation.applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  const resolveReoccurrenceWeekDropdownItems = (
    filter: (reoccurrence: PropertyCheckReoccurrence) => boolean
  ): DropdownItemProps[] => {
    return (
      Object.values(PropertyCheckReoccurrence)
        .filter((item) => filter(item))
        .map((reoccurrence) => ({
          key: reoccurrence,
          text: translatePropertyCheckReoccurrence(reoccurrence),
          value: reoccurrence.toString()
        }))
        .sort((a, b) => (getReocurrenceSortIndex(a.key) < getReocurrenceSortIndex(b.key) ? -1 : 1)) ?? []
    );
  };

  const layoutForm = (
    <>
      <Form noValidate="noValidate">
        <Form.Input
          label={t('common:title')}
          required
          readOnly={status === Status.Saving}
          value={title}
          onChange={(event, data) => {
            setTitle(data.value as string);
          }}
          width={8}
          error={titleValidationResult?.errorMessage}
        />

        <Form.TextArea
          label={t('common:description')}
          required
          readOnly={status === Status.Saving}
          value={description}
          onChange={(event, data) => {
            setDescription(data.value as string);
          }}
          error={descriptionValidationResult?.errorMessage}
        />

        <Form.Dropdown
          label={t('propertyChecks:scheduling')}
          placeholder={t('propertyChecks:scheduling')}
          width={4}
          floating
          text={reoccurrence ? translatePropertyCheckReoccurrence(reoccurrence) : ''}
          value={reoccurrence ? translatePropertyCheckReoccurrence(reoccurrence) : ''}
          error={reoccurrenceValidationResult?.errorMessage}
          readOnly={status === Status.Saving}
          selection
          required
        >
          <Dropdown.Menu>
            <Dropdown.Header icon="calendar" content={t('common:week')} />
            <Dropdown.Divider />
            {resolveReoccurrenceWeekDropdownItems(isReoccurrenceWeek).map((item) => (
              <Dropdown.Item
                key={item.key}
                value={item.value}
                onClick={(e, d) => {
                  setReoccurrence(d.value as PropertyCheckReoccurrence);
                }}
              >
                {item.text}
              </Dropdown.Item>
            ))}
            <Dropdown.Header icon="calendar" content={t('common:month')} />
            <Dropdown.Divider />
            {resolveReoccurrenceWeekDropdownItems(isReoccurrenceMonth).map((item) => (
              <Dropdown.Item
                key={item.key}
                value={item.value}
                onClick={(e, d) => {
                  setReoccurrence(d.value as PropertyCheckReoccurrence);
                }}
              >
                {item.text}
              </Dropdown.Item>
            ))}
            <Dropdown.Header icon="calendar" content={t('common:year')} />
            <Dropdown.Divider />
            {resolveReoccurrenceWeekDropdownItems(isReoccurrenceYear).map((item) => (
              <Dropdown.Item
                key={item.key}
                value={item.value}
                onClick={(e, d) => {
                  setReoccurrence(d.value as PropertyCheckReoccurrence);
                }}
              >
                {item.text}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Form.Dropdown>
      </Form>
    </>
  );

  return (
    <Modal
      size={'small'}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon={status !== Status.Saving}
      open
      onClose={() => props.onClose(false)}
    >
      <Modal.Header>
        {propsTypeEdit ? t('propertyChecks:editControlPoint') : t('propertyChecks:newControlPoint')}
      </Modal.Header>

      <Modal.Content>{layoutForm}</Modal.Content>

      <Modal.Actions>
        <Button
          content={t('common:save')}
          loading={status === Status.Saving}
          disabled={status === Status.Saving}
          primary
          onClick={savePropertyCheck}
        />
        <Button content={t('common:close')} secondary onClick={() => props.onClose(false)} />
      </Modal.Actions>
    </Modal>
  );
};

export default EditPropertyCheckModal;
