import React from 'react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import NumberFormater from '../NumberFormater/NumberFormater';
import KpiCard, { KPISize, KPIStatus } from './KPICard/KpiCard';
import { WaultStatisticsReadView } from '../../GeneratedServices';
import { statisticsApi } from '../../Http/Http';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}
const WaultKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [waultStatistics, setWaultStatistics] = React.useState<WaultStatisticsReadView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceReadWAULTStatistics(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setWaultStatistics(data);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();
    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  return (
    <KpiCard
      title="WAULT"
      valueAs="h5"
      value={<NumberFormater value={waultStatistics?.value} />}
      valueSuffix={t('years')}
      status={status}
      retry={reload}
      size={KPISize.small}
    />
  );
};

export default WaultKpi;
