/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { FinancialTransactionImportView } from '../model';
// @ts-ignore
import { FinancialTransactionListView } from '../model';
// @ts-ignore
import { FinancialTransactionPeriodListView } from '../model';
// @ts-ignore
import { FinancialTransactionReadView } from '../model';
/**
 * FinancialTransactionApi - axios parameter creator
 * @export
 */
export const FinancialTransactionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List financial transaction periods
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {'updated_desc'} [sortBy] Sort by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialTransactionPeriodResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      sortBy?: 'updated_desc',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/financial-transaction-periods`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import financial transactions from Excel file to the organisation
     * @param {number} organisationId
     * @param {FinancialTransactionImportView} [financialTransactionImportView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialTransactionResourceImportFinancialTransactionsToOrganisation: async (
      organisationId: number,
      financialTransactionImportView?: FinancialTransactionImportView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists(
        'financialTransactionResourceImportFinancialTransactionsToOrganisation',
        'organisationId',
        organisationId
      );
      const localVarPath = `/v1/financial-transactions/xlsx/organisation/{organisationId}`.replace(
        `{${'organisationId'}}`,
        encodeURIComponent(String(organisationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        financialTransactionImportView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List financial transactions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [companyId] Filter by company
     * @param {number} [accountId] Filter by account
     * @param {number} [accountNumber] Filter by account number
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialTransactionResourceList: async (
      limit?: number,
      offset?: number,
      companyId?: number,
      accountId?: number,
      accountNumber?: number,
      propertyId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/financial-transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (accountId !== undefined) {
        localVarQueryParameter['accountId'] = accountId;
      }

      if (accountNumber !== undefined) {
        localVarQueryParameter['accountNumber'] = accountNumber;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a financial transaction
     * @param {number} id Financial transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialTransactionResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('financialTransactionResourceRead', 'id', id);
      const localVarPath = `/v1/financial-transactions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FinancialTransactionApi - functional programming interface
 * @export
 */
export const FinancialTransactionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FinancialTransactionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List financial transaction periods
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {'updated_desc'} [sortBy] Sort by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async financialTransactionPeriodResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      sortBy?: 'updated_desc',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialTransactionPeriodListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.financialTransactionPeriodResourceList(
        limit,
        offset,
        organisationId,
        sortBy,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Import financial transactions from Excel file to the organisation
     * @param {number} organisationId
     * @param {FinancialTransactionImportView} [financialTransactionImportView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async financialTransactionResourceImportFinancialTransactionsToOrganisation(
      organisationId: number,
      financialTransactionImportView?: FinancialTransactionImportView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.financialTransactionResourceImportFinancialTransactionsToOrganisation(
          organisationId,
          financialTransactionImportView,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List financial transactions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [companyId] Filter by company
     * @param {number} [accountId] Filter by account
     * @param {number} [accountNumber] Filter by account number
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async financialTransactionResourceList(
      limit?: number,
      offset?: number,
      companyId?: number,
      accountId?: number,
      accountNumber?: number,
      propertyId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialTransactionListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.financialTransactionResourceList(
        limit,
        offset,
        companyId,
        accountId,
        accountNumber,
        propertyId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a financial transaction
     * @param {number} id Financial transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async financialTransactionResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialTransactionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.financialTransactionResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * FinancialTransactionApi - factory interface
 * @export
 */
export const FinancialTransactionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FinancialTransactionApiFp(configuration);
  return {
    /**
     *
     * @summary List financial transaction periods
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {'updated_desc'} [sortBy] Sort by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialTransactionPeriodResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      sortBy?: 'updated_desc',
      options?: any
    ): AxiosPromise<FinancialTransactionPeriodListView> {
      return localVarFp
        .financialTransactionPeriodResourceList(limit, offset, organisationId, sortBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import financial transactions from Excel file to the organisation
     * @param {number} organisationId
     * @param {FinancialTransactionImportView} [financialTransactionImportView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialTransactionResourceImportFinancialTransactionsToOrganisation(
      organisationId: number,
      financialTransactionImportView?: FinancialTransactionImportView,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .financialTransactionResourceImportFinancialTransactionsToOrganisation(
          organisationId,
          financialTransactionImportView,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List financial transactions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [companyId] Filter by company
     * @param {number} [accountId] Filter by account
     * @param {number} [accountNumber] Filter by account number
     * @param {number} [propertyId] Filter by property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialTransactionResourceList(
      limit?: number,
      offset?: number,
      companyId?: number,
      accountId?: number,
      accountNumber?: number,
      propertyId?: number,
      options?: any
    ): AxiosPromise<FinancialTransactionListView> {
      return localVarFp
        .financialTransactionResourceList(limit, offset, companyId, accountId, accountNumber, propertyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a financial transaction
     * @param {number} id Financial transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialTransactionResourceRead(id: number, options?: any): AxiosPromise<FinancialTransactionReadView> {
      return localVarFp.financialTransactionResourceRead(id, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for financialTransactionPeriodResourceList operation in FinancialTransactionApi.
 * @export
 * @interface FinancialTransactionApiFinancialTransactionPeriodResourceListRequest
 */
export interface FinancialTransactionApiFinancialTransactionPeriodResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionPeriodResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionPeriodResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionPeriodResourceList
   */
  readonly organisationId?: number;

  /**
   * Sort by
   * @type {'updated_desc'}
   * @memberof FinancialTransactionApiFinancialTransactionPeriodResourceList
   */
  readonly sortBy?: 'updated_desc';
}

/**
 * Request parameters for financialTransactionResourceImportFinancialTransactionsToOrganisation operation in FinancialTransactionApi.
 * @export
 * @interface FinancialTransactionApiFinancialTransactionResourceImportFinancialTransactionsToOrganisationRequest
 */
export interface FinancialTransactionApiFinancialTransactionResourceImportFinancialTransactionsToOrganisationRequest {
  /**
   *
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionResourceImportFinancialTransactionsToOrganisation
   */
  readonly organisationId: number;

  /**
   *
   * @type {FinancialTransactionImportView}
   * @memberof FinancialTransactionApiFinancialTransactionResourceImportFinancialTransactionsToOrganisation
   */
  readonly financialTransactionImportView?: FinancialTransactionImportView;
}

/**
 * Request parameters for financialTransactionResourceList operation in FinancialTransactionApi.
 * @export
 * @interface FinancialTransactionApiFinancialTransactionResourceListRequest
 */
export interface FinancialTransactionApiFinancialTransactionResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionResourceList
   */
  readonly offset?: number;

  /**
   * Filter by company
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionResourceList
   */
  readonly companyId?: number;

  /**
   * Filter by account
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionResourceList
   */
  readonly accountId?: number;

  /**
   * Filter by account number
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionResourceList
   */
  readonly accountNumber?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionResourceList
   */
  readonly propertyId?: number;
}

/**
 * Request parameters for financialTransactionResourceRead operation in FinancialTransactionApi.
 * @export
 * @interface FinancialTransactionApiFinancialTransactionResourceReadRequest
 */
export interface FinancialTransactionApiFinancialTransactionResourceReadRequest {
  /**
   * Financial transaction ID
   * @type {number}
   * @memberof FinancialTransactionApiFinancialTransactionResourceRead
   */
  readonly id: number;
}

/**
 * FinancialTransactionApi - object-oriented interface
 * @export
 * @class FinancialTransactionApi
 * @extends {BaseAPI}
 */
export class FinancialTransactionApi extends BaseAPI {
  /**
   *
   * @summary List financial transaction periods
   * @param {FinancialTransactionApiFinancialTransactionPeriodResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialTransactionApi
   */
  public financialTransactionPeriodResourceList(
    requestParameters: FinancialTransactionApiFinancialTransactionPeriodResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FinancialTransactionApiFp(this.configuration)
      .financialTransactionPeriodResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.sortBy,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import financial transactions from Excel file to the organisation
   * @param {FinancialTransactionApiFinancialTransactionResourceImportFinancialTransactionsToOrganisationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialTransactionApi
   */
  public financialTransactionResourceImportFinancialTransactionsToOrganisation(
    requestParameters: FinancialTransactionApiFinancialTransactionResourceImportFinancialTransactionsToOrganisationRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialTransactionApiFp(this.configuration)
      .financialTransactionResourceImportFinancialTransactionsToOrganisation(
        requestParameters.organisationId,
        requestParameters.financialTransactionImportView,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List financial transactions
   * @param {FinancialTransactionApiFinancialTransactionResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialTransactionApi
   */
  public financialTransactionResourceList(
    requestParameters: FinancialTransactionApiFinancialTransactionResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FinancialTransactionApiFp(this.configuration)
      .financialTransactionResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.companyId,
        requestParameters.accountId,
        requestParameters.accountNumber,
        requestParameters.propertyId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a financial transaction
   * @param {FinancialTransactionApiFinancialTransactionResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialTransactionApi
   */
  public financialTransactionResourceRead(
    requestParameters: FinancialTransactionApiFinancialTransactionResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialTransactionApiFp(this.configuration)
      .financialTransactionResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
