/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Theme to be used for the create work order form for tenant.
 * @export
 * @enum {string}
 */

export const OrganisationTheme = {
  Default: 'DEFAULT',
  Broadgate: 'BROADGATE',
  Broadgate2: 'BROADGATE2'
} as const;

export type OrganisationTheme = (typeof OrganisationTheme)[keyof typeof OrganisationTheme];
