/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BusinessPlanIndexReadViewYear } from './business-plan-index-read-view-year';

/**
 *
 * @export
 * @interface BusinessPlanIndexReadView
 */
export interface BusinessPlanIndexReadView {
  /**
   * ID.
   * @type {number}
   * @memberof BusinessPlanIndexReadView
   */
  id: number;
  /**
   * Index type.
   * @type {string}
   * @memberof BusinessPlanIndexReadView
   */
  indexType: BusinessPlanIndexReadViewIndexTypeEnum;
  /**
   * Property ID.
   * @type {number}
   * @memberof BusinessPlanIndexReadView
   */
  propertyId: number;
  /**
   * Value. (Percent)
   * @type {number}
   * @memberof BusinessPlanIndexReadView
   */
  value: number;
  /**
   *
   * @type {BusinessPlanIndexReadViewYear}
   * @memberof BusinessPlanIndexReadView
   */
  year: BusinessPlanIndexReadViewYear;
}

export const BusinessPlanIndexReadViewIndexTypeEnum = {
  Inflation: 'INFLATION',
  RentalGrowth: 'RENTAL_GROWTH'
} as const;

export type BusinessPlanIndexReadViewIndexTypeEnum =
  (typeof BusinessPlanIndexReadViewIndexTypeEnum)[keyof typeof BusinessPlanIndexReadViewIndexTypeEnum];
