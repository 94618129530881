import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic-ui-react';
import {
  EmbeddedGovernmentInspectionPropertyReadView,
  GovernmentInspectionItemReadView,
  GovernmentInspectionItemReadViewAssigneeEnum,
  GovernmentInspectionListItemViewCategoryEnum,
  GovernmentInspectionSingleItemUpdateView,
  MembershipReadViewRoleEnum,
  PropertyReadView
} from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import {
  createInfinitePaginationParams,
  governmentInspectionApi,
  NetworkRequestStatus,
  propertyApi
} from '../../../Http/Http';
import { UserFriendlyApiResponse } from '../../../Http/response-error';
import {
  generateGovernmentInspectionAssigneeOptions,
  generateGovernmentInspectionCategoryOptions
} from '../../../Services/GovernmentInspectionService.types';
import { mapPropertiesToDropdownItems } from '../../../Services/PropertyService.types';
import { toDateString } from '../../../Utils/date';
import { renderValidationErrors } from '../../../Utils/FieldValidationErrorMessage';
import { useDidMountEffect } from '../../../Utils/hooks';
import {
  applyApiResponseValidationToFields,
  FieldValidationResult,
  validateDate,
  validateRequiredEnum,
  validateRequiredField
} from '../../../Utils/validation';
import DatePicker from '../../DatePicker/DatePicker';

interface SharedProps {
  onClose: () => void;
  onSave: (item: GovernmentInspectionItemReadView) => void;
}

type Props =
  | ({
      type: 'CREATE';
      property?: PropertyReadView | EmbeddedGovernmentInspectionPropertyReadView;
      category?: GovernmentInspectionListItemViewCategoryEnum;
    } & SharedProps)
  | ({ type: 'EDIT'; item: GovernmentInspectionItemReadView } & SharedProps);

enum Status {
  None,
  Saving
}

const EditGovernmentInspectionItemModal: React.FC<Props> = (props) => {
  const { type } = props;
  const isEditMode = type === 'EDIT';

  const { currentUserMemberships, handleHttpErrors } = React.useContext(globalStateCTX);
  const { t } = useTranslation(['inspections', 'common', 'properties', 'validation']);

  const [status, setStatus] = React.useState<Status>(Status.None);

  const [category, setCategory] = React.useState<GovernmentInspectionListItemViewCategoryEnum | undefined>(
    !isEditMode ? props.category : undefined
  );
  const [categoryValidationResult, setCategoryValidationResult] = React.useState<FieldValidationResult>();

  const [assignee, setAssignee] = React.useState<GovernmentInspectionItemReadViewAssigneeEnum>(
    isEditMode ? props.item.assignee : GovernmentInspectionItemReadViewAssigneeEnum.Landlord
  );
  const [assigneeValidationResult, setAssigneeValidationResult] = React.useState<FieldValidationResult>();

  const [properties, setProperties] = React.useState<
    (PropertyReadView | EmbeddedGovernmentInspectionPropertyReadView)[]
  >(!isEditMode && props.property ? [props.property] : []);
  const [propertiesStatus, setPropertiesStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);
  const [selectedPropertyId, setSelectedPropertyId] = React.useState<number | undefined>(
    !isEditMode ? props.property?.id : undefined
  );
  const [propertyValidationResult, setPropertyValidationResult] = React.useState<FieldValidationResult>();

  const [title, setTitle] = React.useState<string>(isEditMode ? props.item.title : '');
  const [titleValidationResult, setTitleValidationResult] = React.useState<FieldValidationResult>();

  const [description, setDescription] = React.useState<string | null>(isEditMode ? props.item.info : '');
  const [descriptionValidationResult, setDescriptionValidationResult] = React.useState<FieldValidationResult>();

  const [date, setDate] = React.useState<Date | undefined>(
    isEditMode ? new Date(props.item.initialInspectionDeadline) : undefined
  );
  const [dateValidationResult, setDateValidationResult] = React.useState<FieldValidationResult>();

  const [ordered, setOrdered] = React.useState<boolean>(isEditMode ? props.item.ordered : false);
  const [orderedValidationResult, setOrderedValidationResult] = React.useState<FieldValidationResult>();

  const propertyExists = !isEditMode && props.property;
  React.useEffect(() => {
    if (propertyExists) return;
    const abortController = new AbortController();

    const load = async () => {
      const nonInvestorOrganisationIds = currentUserMemberships
        ?.filter((item) => item.role !== MembershipReadViewRoleEnum.Investor)
        .map((item) => item.organisationId);

      try {
        setPropertiesStatus(NetworkRequestStatus.Loading);

        const { data } = await propertyApi.propertyResourceList(
          { ...createInfinitePaginationParams(), organisationIds: nonInvestorOrganisationIds },
          { signal: abortController.signal }
        );

        setProperties(data.records);

        setPropertiesStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setPropertiesStatus(NetworkRequestStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, currentUserMemberships, propertyExists]);

  const handleResponseValidationError = (resp: UserFriendlyApiResponse) => {
    const fieldIdMapping = [
      {
        formFieldId: 'title',
        setStateFunc: setTitleValidationResult
      },
      {
        formFieldId: 'info',
        setStateFunc: setDescriptionValidationResult
      },
      {
        formFieldId: 'category',
        setStateFunc: setCategoryValidationResult
      },
      {
        formFieldId: 'propertyId',
        setStateFunc: setPropertyValidationResult
      },
      {
        formFieldId: 'assignee',
        setStateFunc: setAssigneeValidationResult
      },
      {
        formFieldId: 'initialInspectionDeadline',
        setStateFunc: setDateValidationResult
      },
      {
        formFieldId: 'ordered',
        setStateFunc: setOrderedValidationResult
      }
    ];

    applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  const validateDateString = () => {
    return validateDate(date ? toDateString(date) : undefined, setDateValidationResult, t('validation:invalidDate'));
  };
  useDidMountEffect(() => {
    validateDateString();
  }, [date]);

  const validateTitle = () => {
    return validateRequiredField(title, setTitleValidationResult, t('validation:fieldError.required'));
  };
  useDidMountEffect(() => {
    validateTitle();
  }, [title]);

  const validateProperty = () => {
    return (
      !isEditMode &&
      validateRequiredField(
        selectedPropertyId ? selectedPropertyId.toString() : '',
        setPropertyValidationResult,
        t('validation:fieldError.required')
      )
    );
  };
  useDidMountEffect(() => {
    validateProperty();
  }, [selectedPropertyId]);

  const validateCategory = () => {
    return (
      !isEditMode &&
      validateRequiredEnum(
        GovernmentInspectionListItemViewCategoryEnum,
        category,
        setCategoryValidationResult,
        t('validation:enterCategory')
      )
    );
  };
  useDidMountEffect(() => {
    validateCategory();
  }, [category]);

  useDidMountEffect(() => setOrderedValidationResult(undefined), [ordered]);
  useDidMountEffect(() => setDescriptionValidationResult(undefined), [description]);
  useDidMountEffect(() => setAssigneeValidationResult(undefined), [assignee]);

  const hasErrors = [
    categoryValidationResult,
    propertyValidationResult,
    dateValidationResult,
    titleValidationResult,
    orderedValidationResult,
    assigneeValidationResult,
    descriptionValidationResult
  ].some((item) => item?.error === true);

  const validateForm = () => {
    if (hasErrors) return false;
    else
      return [
        ...(!isEditMode ? [validateCategory(), validateProperty()] : []),
        validateTitle(),
        validateDateString()
      ].every((item) => item);
  };

  const saveInspectionItem = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setStatus(Status.Saving);

      const params: GovernmentInspectionSingleItemUpdateView = {
        assignee,
        info: description,
        initialInspectionDeadline: toDateString(date!),
        ordered,
        title
      };
      let savedData = undefined;
      if (isEditMode) {
        savedData = (
          await governmentInspectionApi.governmentInspectionResourceUpdateItem({
            id: props.item.id,
            governmentInspectionSingleItemUpdateView: params
          })
        ).data;
      }

      if (props.type === 'CREATE' && category && selectedPropertyId) {
        savedData = (
          await governmentInspectionApi.governmentInspectionResourceCreateItem({
            governmentInspectionSingleItemCreateView: {
              ...params,
              category: category,
              propertyId: selectedPropertyId
            }
          })
        ).data;
      }

      setStatus(Status.None);
      savedData && props.onSave(savedData);
      props.onClose();
    } catch (error) {
      handleHttpErrors(error, { handleResponseValidationError }) && setStatus(Status.None);
    }
  };

  const getHeader = () => {
    return props.type === 'CREATE' ? t('inspections:createInspectionItem') : t('inspections:editInspectionItem');
  };

  return (
    <Modal open onClose={props.onClose} closeIcon={status !== Status.Saving} size="small">
      <Modal.Header>{getHeader()}</Modal.Header>
      <Modal.Content>
        <Form>
          {!isEditMode && (
            <>
              {!props.category && (
                <Form.Dropdown
                  label={t('common:category')}
                  floating
                  placeholder={t('common:category')}
                  value={category}
                  width={5}
                  onChange={(e, d) => setCategory(d.value as GovernmentInspectionListItemViewCategoryEnum)}
                  error={renderValidationErrors(categoryValidationResult)}
                  search
                  fluid
                  selection
                  scrolling
                  options={generateGovernmentInspectionCategoryOptions()}
                  required
                />
              )}

              {!props.property && (
                <Form.Dropdown
                  label={t('common:property')}
                  placeholder={t('common:property')}
                  width={5}
                  floating
                  value={selectedPropertyId}
                  onChange={(e, d) => setSelectedPropertyId(d.value as number)}
                  loading={propertiesStatus === NetworkRequestStatus.Loading}
                  disabled={propertiesStatus !== NetworkRequestStatus.None || status === Status.Saving}
                  error={
                    renderValidationErrors(propertyValidationResult) ||
                    (propertiesStatus === NetworkRequestStatus.LoadError
                      ? t('properties:unableToFetchProperties')
                      : undefined)
                  }
                  selection
                  search
                  options={mapPropertiesToDropdownItems(properties as PropertyReadView[])}
                  required
                />
              )}
            </>
          )}

          <Form.Dropdown
            label={t('inspections:responsible')}
            floating
            placeholder={t('inspections:responsible')}
            value={assignee}
            width={5}
            onChange={(e, d) => setAssignee(d.value as GovernmentInspectionItemReadViewAssigneeEnum)}
            error={renderValidationErrors(assigneeValidationResult)}
            search
            fluid
            selection
            options={generateGovernmentInspectionAssigneeOptions()}
            required
          />

          <Form.Input
            label="ID"
            value={title}
            onChange={(e, d) => setTitle(d.value)}
            placeholder="Aa"
            required
            width={5}
            error={renderValidationErrors(titleValidationResult)}
          />

          <Form.TextArea
            placeholder="Aa"
            error={renderValidationErrors(descriptionValidationResult)}
            label={t('common:description')}
            value={description ?? undefined}
            onChange={(e, d) => setDescription(d.value as string)}
          />

          <Form.Field width={5} required error={dateValidationResult?.error}>
            <label>{t('inspections:firstDeadline')}</label>
            <DatePicker
              popperPlacement="top"
              selected={date}
              dateFormat="P"
              placeholderText={'YYYY-MM-DD'}
              onChange={(d) => setDate(d ?? undefined)}
              customInput={
                <Form.Input
                  icon="calendar alternate outline"
                  iconPosition="left"
                  error={renderValidationErrors(dateValidationResult)}
                />
              }
            />
          </Form.Field>

          <Form.Checkbox
            error={renderValidationErrors(orderedValidationResult)}
            label={t('inspections:inspectionOrdered')}
            checked={ordered}
            onChange={(e, d) => setOrdered(d.checked ?? false)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={saveInspectionItem} disabled={hasErrors}>
          {t('inspections:complete')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditGovernmentInspectionItemModal;
