import React from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { Input, Popup } from 'semantic-ui-react';
import { renderValidationErrors } from '../../Utils/FieldValidationErrorMessage';
import { FieldValidationResult } from '../../Utils/validation';
import NumberFormater, { commonNumberFormatProperties } from '../NumberFormater/NumberFormater';
import styles from './EditableTableNumberCell.module.scss';
interface Props {
  displayValue: number | undefined;
  value: number | undefined;
  placeholder: string;
  onValueChange: (value: NumberFormatValues) => void;
  isInEditMode?: boolean;
  additionalInputProps?: NumberFormatProps;
  validationResult?: FieldValidationResult;
  suffix?: string;
}
const EditableTableNumberCell: React.FC<Props> = (props) => {
  const {
    displayValue,
    value,
    placeholder,
    onValueChange,
    isInEditMode,
    additionalInputProps,
    validationResult,
    suffix
  } = props;

  if (isInEditMode)
    return (
      <Popup
        content={renderValidationErrors(validationResult)}
        disabled={!validationResult?.error}
        openOnTriggerClick
        trigger={
          <Input
            className={styles.input}
            error={validationResult?.error}
            placeholder={placeholder}
            input={
              <NumberFormat
                autoFocus
                value={value}
                onValueChange={onValueChange}
                {...commonNumberFormatProperties}
                {...additionalInputProps}
                suffix={suffix}
              />
            }
          />
        }
      />
    );
  else return <NumberFormater value={displayValue} suffix={suffix} />;
};

export default EditableTableNumberCell;
