import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Loader, Table } from 'semantic-ui-react';
import { GovernmentInspectionItemResultReadView } from '../../GeneratedServices';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { createInfinitePaginationParams, governmentInspectionApi, NetworkRequestStatus } from '../../Http/Http';
import { useNonce } from '../../Utils/hooks';
import LoadError from '../LoadError';

interface Props {
  itemId: number;
  reloadCount: number;
  onItemResultClick: (workOrderId: number) => void;
}
const GovernmentInspectionItemResultsTable: React.FC<Props> = ({ itemId, reloadCount, onItemResultClick }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const { t } = useTranslation(['inspections', 'common']);

  const [itemResults, setItemResults] = React.useState<GovernmentInspectionItemResultReadView[]>([]);
  const [status, setStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.Loading);
  const [itemResultsReloadCount, incrementItemResultsReloadCount] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadItemResults = async () => {
      try {
        setStatus(NetworkRequestStatus.Loading);

        const { data } = await governmentInspectionApi.governmentInspectionItemResultResourceList(
          {
            ...createInfinitePaginationParams(),
            itemId: itemId
          },
          {
            signal: abortController.signal
          }
        );

        setItemResults(data.records);
        setStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(NetworkRequestStatus.LoadError);
      }
    };

    loadItemResults();

    return () => abortController.abort();
  }, [itemId, itemResultsReloadCount, handleHttpErrors, reloadCount]);

  switch (status) {
    case NetworkRequestStatus.LoadError:
      return (
        <LoadError message={t('inspections:unableToLoadPreviousInspections')} retry={incrementItemResultsReloadCount} />
      );
    case NetworkRequestStatus.Loading:
      return <Loader active inline="centered" />;
    case NetworkRequestStatus.None:
      return itemResults.length !== 0 ? (
        <>
          <Table basic="very" selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('inspections:itemGrade')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:comment')}</Table.HeaderCell>
                <Table.HeaderCell>{t('inspections:performDate')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {itemResults.map((item, i) => (
                <Table.Row key={item.id} onClick={() => onItemResultClick(item.governmentInspectionWorkOrderId)}>
                  <Table.Cell>
                    <Label size="large" color={item.passed ? 'green' : 'red'}>
                      {item.passed
                        ? t('inspections:inspectionItemStatus.approved')
                        : t('inspections:inspectionItemStatus.notApproved')}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>{item.comment}</Table.Cell>
                  <Table.Cell>{item.performed}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      ) : (
        <div>{t('inspections:noPreviousInspectionsFound')}</div>
      );
  }
};

export default GovernmentInspectionItemResultsTable;
