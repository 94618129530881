import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import NumberFormater from '../NumberFormater/NumberFormater';
import KpiCard, { KPISize, KPIStatus } from './KPICard/KpiCard';
import React from 'react';
import { statisticsApi } from '../../Http/Http';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}

const AreaVacancyKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation(['contracts', 'common']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [areaVacancyStatistics, setAreaVacancyStatistics] = React.useState<number | null>(null);
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceGetAreaOccupancy(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setAreaVacancyStatistics(data.value != null ? 100 * (1 - data.value) : null); // occupancy converted to vacancy and to %
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();

    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  return (
    <KpiCard
      title={t('contracts:vacancy')}
      valueAs="h5"
      value={
        areaVacancyStatistics != null ? <NumberFormater value={areaVacancyStatistics} suffix="%" /> : t('common:noData')
      }
      status={status}
      retry={reload}
      size={KPISize.small}
    />
  );
};

export default AreaVacancyKpi;
