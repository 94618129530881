import { Link } from 'react-router-dom';
import React from 'react';
import { Button, Icon, Loader, Modal, Table } from 'semantic-ui-react';
import { TenantReadView } from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { tenantApi } from '../../../Http/Http';
import { useNonce } from '../../../Utils/hooks';
import LoadError from '../../LoadError';

interface Props {
  onClose: () => void;
  contractId: number;
}

enum Status {
  None,
  Loading,
  LoadError
}
const ContractTenantsModal: React.FC<Props> = (props) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [tenants, setTenants] = React.useState<TenantReadView[]>();
  const [status, setStatus] = React.useState<Status>(Status.Loading);
  const [shouldReloadTenants, reloadTenants] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(Status.Loading);

        const { data } = await tenantApi.tenantResourceList(
          {
            ongoingContractId: props.contractId
          },
          {
            signal: abortController.signal
          }
        );

        setTenants(data.records);
        setStatus(Status.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };
    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, props.contractId, shouldReloadTenants]);

  const getModalContent = () => {
    switch (status) {
      case Status.None:
        return generalLayout;
      case Status.Loading:
        return layoutLoading;
      case Status.LoadError:
        return layoutLoadError;
    }
  };

  const layoutLoading = <Loader inline="centered" active size="large" />;

  const layoutLoadError = <LoadError message="Det gick inte att hämta avtalets hyresgäster." retry={reloadTenants} />;

  const generalLayout = (
    <Table basic="very" singleLine>
      <Table.Header>
        <Table.HeaderCell>Namn</Table.HeaderCell>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>Telefon</Table.HeaderCell>
        <Table.HeaderCell>E-postadress</Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {tenants?.map((item) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Icon name={item.type === 'COMPANY' ? 'briefcase' : 'user'} style={{ marginRight: 10 }} />
                  <Link to={`/tenants/${item.id}`}>{item.name}</Link>
                </div>
              </Table.Cell>
              <Table.Cell>{item.identification}</Table.Cell>
              <Table.Cell>{item.phone}</Table.Cell>
              <Table.Cell>{item.email}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );

  return (
    <Modal open closeOnEscape={false} closeOnDimmerClick={false} closeIcon onClose={props.onClose}>
      <Modal.Header>Hyresgäster</Modal.Header>
      <Modal.Content scrolling>{getModalContent()}</Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} secondary>
          Stäng
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ContractTenantsModal;
