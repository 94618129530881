import { faArrowUp, faArrowDown, faMinus, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Icon, Message, Popup, Table } from 'semantic-ui-react';
import { FinancialReportReadView, FinancialStatisticsValueView } from '../../../GeneratedServices';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { formatCurrency, getComparedPercentage } from '../../../Utils/number';
import NumberFormater from '../../NumberFormater/NumberFormater';
import styles from './FinancialStatisticsTable.module.scss';
import { useTranslation } from 'react-i18next';
import { generatePeriodLabel } from '../../../Services/FinancialReportService';

interface Props {
  financialStatistics: FinancialReportReadView[];
  areStatisticsPerArea: boolean;
}

const isFirstElement = (index: number) => {
  return index === 0;
};

export const PercentageComparisonRow = (props: {
  color: string;
  icon: IconDefinition;
  value?: string | JSX.Element;
}) => {
  if (props.value !== undefined)
    return (
      <div
        className={styles.popupContent}
        style={{
          color: props.color
        }}
      >
        <span>
          <Icon circular size="small" className={styles.popupIcon}>
            <FontAwesomeIcon icon={props.icon} color={props.color} />
          </Icon>
        </span>

        {props.value}
      </div>
    );
  else return null;
};

const FinancialStatisticsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation(['financialStatistics', 'common']);

  const EmptyRow = () => (
    <Table.Row className={styles.emptyRow}>
      <td colSpan={props.financialStatistics.length + 2} />
    </Table.Row>
  );

  const TableRow = ({
    label,
    columnValues,
    isTotal,
    shouldBeNegated
  }: {
    label: string;
    columnValues: FinancialStatisticsValueView[];
    isTotal?: boolean;
    shouldBeNegated?: boolean;
  }) => {
    let previousValue = 0;
    let totalActual = 0;
    let totalPlanned = 0;

    const valueCells = columnValues.map((item, i) => {
      const actualValue = formatCurrency(item.actual, { forceCurrency: 'SEK' }).value;
      const plannedValue = formatCurrency(item.planned, { forceCurrency: 'SEK' }).value;

      totalActual += actualValue;
      totalPlanned += plannedValue;

      const comparedToPrevious = getComparedPercentage(actualValue, previousValue);
      const comparedToPlanned = getComparedPercentage(actualValue, plannedValue);

      const isBiggerThanPrevious = shouldBeNegated ? actualValue < previousValue : actualValue > previousValue;
      const isBetterThanPrevious = shouldBeNegated ? !isBiggerThanPrevious : isBiggerThanPrevious;

      const isBiggerThanPlanned = shouldBeNegated ? actualValue < plannedValue : actualValue > plannedValue;
      const isBetterThanPlanned = shouldBeNegated ? !isBiggerThanPlanned : isBiggerThanPlanned;

      let cell = (
        <React.Fragment key={label + i}>
          <Popup
            position="top center"
            hoverable
            content={
              <div className={styles.popupContainer}>
                {!isFirstElement(i) && comparedToPrevious != null && (
                  <PercentageComparisonRow
                    color={comparedToPrevious !== 0 ? (isBetterThanPrevious ? colors.green : colors.red) : colors.grey}
                    icon={comparedToPrevious !== 0 ? (isBiggerThanPrevious ? faArrowUp : faArrowDown) : faMinus}
                    value={
                      <NumberFormater
                        value={comparedToPrevious}
                        suffix={`% vs ${props.financialStatistics[i - 1].label}`}
                        additionalProps={{ decimalScale: 1 }}
                      />
                    }
                  />
                )}

                <PercentageComparisonRow
                  color={
                    plannedValue && comparedToPlanned !== 0
                      ? isBetterThanPlanned
                        ? colors.green
                        : colors.red
                      : colors.grey
                  }
                  icon={
                    plannedValue && comparedToPlanned !== 0 ? (isBiggerThanPlanned ? faArrowUp : faArrowDown) : faMinus
                  }
                  value={
                    comparedToPlanned != null ? (
                      <NumberFormater
                        value={comparedToPlanned}
                        suffix={t('common:vsBusinessPlan')}
                        additionalProps={{ decimalScale: 1 }}
                      />
                    ) : (
                      t('common:noBusinessPlanInformation')
                    )
                  }
                />
              </div>
            }
            trigger={
              <Table.Cell collapsing textAlign="center">
                <NumberFormater
                  value={actualValue}
                  additionalProps={{ allowNegative: true, decimalScale: props.areStatisticsPerArea ? 1 : 0 }}
                />
              </Table.Cell>
            }
          />
        </React.Fragment>
      );

      previousValue = actualValue;
      return cell;
    });

    const comparedTotalSum = getComparedPercentage(totalActual, totalPlanned);

    const isBiggerThanPlannedTotal = shouldBeNegated ? totalActual < totalPlanned : totalActual > totalPlanned;
    const isBetterThanPlannedTotal = shouldBeNegated ? !isBiggerThanPlannedTotal : isBiggerThanPlannedTotal;

    return (
      <Table.Row style={{ fontWeight: isTotal ? '400' : undefined }}>
        <Table.Cell>{label}</Table.Cell>

        {valueCells}

        <Popup
          trigger={
            <Table.Cell style={{ fontWeight: '400' }} textAlign="right">
              <NumberFormater
                value={totalActual}
                additionalProps={{ allowNegative: true, decimalScale: props.areStatisticsPerArea ? 1 : 0 }}
              />
            </Table.Cell>
          }
          content={
            <PercentageComparisonRow
              color={
                totalPlanned && comparedTotalSum !== 0
                  ? isBetterThanPlannedTotal
                    ? colors.green
                    : colors.red
                  : colors.grey
              }
              icon={
                totalPlanned && comparedTotalSum !== 0 ? (isBiggerThanPlannedTotal ? faArrowUp : faArrowDown) : faMinus
              }
              value={
                comparedTotalSum != null ? (
                  <NumberFormater
                    value={comparedTotalSum}
                    suffix={t('common:vsBusinessPlan')}
                    additionalProps={{ decimalScale: 1 }}
                  />
                ) : (
                  t('common:noBusinessPlanInformation')
                )
              }
            />
          }
        />
      </Table.Row>
    );
  };

  if (props.financialStatistics.length === 0) {
    return <Message>{t('common:noPeriodsChosen')}</Message>;
  }

  return (
    <div className={styles.tableWrapper}>
      <Table basic="very" singleLine className={styles.table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common:categories')}</Table.HeaderCell>
            {props.financialStatistics?.map((item, i) => (
              <React.Fragment key={generatePeriodLabel(item)}>
                <Table.HeaderCell singleLine textAlign="center">
                  <div className={styles.headerContainer}>
                    <span>{generatePeriodLabel(item)}</span>
                    <span className={styles.headerSuffix}>
                      SEK{props.areStatisticsPerArea ? t('common:slashSqm') : ''}
                    </span>
                  </div>
                </Table.HeaderCell>
              </React.Fragment>
            ))}

            <Table.HeaderCell collapsing textAlign="right">
              <div className={styles.headerContainer}>
                <span>{t('common:total')}</span>
                <span className={styles.headerSuffix}>SEK{props.areStatisticsPerArea ? t('common:slashSqm') : ''}</span>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <TableRow
            label={t('financialStatistics:rent')}
            columnValues={props.financialStatistics.map((item) => item.rent)}
          />
          <TableRow
            label={t('financialStatistics:discounts')}
            columnValues={props.financialStatistics.map((item) => item.discounts)}
          />
          <TableRow
            label={t('financialStatistics:supplements')}
            columnValues={props.financialStatistics.map((item) => item.supplements)}
          />
          <TableRow
            label={t('financialStatistics:otherIncome')}
            columnValues={props.financialStatistics.map((item) => item.otherIncome)}
          />
          <TableRow
            label={t('financialStatistics:totalIncome')}
            columnValues={props.financialStatistics.map((item) => item.totalIncome)}
            isTotal
          />
          <EmptyRow />

          <TableRow
            label={t('financialStatistics:otherOperatingExpenses')}
            columnValues={props.financialStatistics.map((item) => item.otherOperatingExpenses)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:serviceAgreemanets')}
            columnValues={props.financialStatistics.map((item) => item.serviceAgreements)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:marketing')}
            columnValues={props.financialStatistics.map((item) => item.marketing)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:cleaning')}
            columnValues={props.financialStatistics.map((item) => item.cleaning)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:snowRemovalAndGardening')}
            columnValues={props.financialStatistics.map((item) => item.snowRemovalAndGardening)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:security')}
            columnValues={props.financialStatistics.map((item) => item.security)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:electricity')}
            columnValues={props.financialStatistics.map((item) => item.electricity)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:cooling')}
            columnValues={props.financialStatistics.map((item) => item.cooling)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:heating')}
            columnValues={props.financialStatistics.map((item) => item.heating)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:waterAndSewage')}
            columnValues={props.financialStatistics.map((item) => item.water)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:waste')}
            columnValues={props.financialStatistics.map((item) => item.waste)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:insurance')}
            columnValues={props.financialStatistics.map((item) => item.insurance)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:leaseholdFee')}
            columnValues={props.financialStatistics.map((item) => item.leaseholdFee)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:realEstateTaxes')}
            columnValues={props.financialStatistics.map((item) => item.realEstateTaxes)}
            shouldBeNegated
          />
          <TableRow
            label="OPEX"
            columnValues={props.financialStatistics.map((item) => item.OPEX)}
            isTotal
            shouldBeNegated
          />
          <EmptyRow />

          <TableRow
            label={t('financialStatistics:repairs')}
            columnValues={props.financialStatistics.map((item) => item.repairs)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:periodicMaintenance')}
            columnValues={props.financialStatistics.map((item) => item.periodicMaintenance)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:maintenance')}
            columnValues={props.financialStatistics.map((item) => item.maintenance)}
            isTotal
            shouldBeNegated
          />
          <EmptyRow />

          <TableRow
            label={t('financialStatistics:propertyManagementInNOI')}
            columnValues={props.financialStatistics.map((item) => item.propertyManagement)}
            shouldBeNegated
          />
          <TableRow
            label={t('financialStatistics:totalCosts')}
            columnValues={props.financialStatistics.map((item) => item.totalCosts)}
            shouldBeNegated
            isTotal
          />
          <EmptyRow />

          <TableRow label="NOI" columnValues={props.financialStatistics.map((item) => item.NOI)} isTotal />
          <EmptyRow />

          <TableRow
            label="CAPEX & TI"
            columnValues={props.financialStatistics.map((item) => item.capexAndTi)}
            isTotal
            shouldBeNegated
          />
        </Table.Body>
      </Table>
    </div>
  );
};

export default FinancialStatisticsTable;
