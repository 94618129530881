import React from 'react';
import { BusinessPlanLeaseReadView, BusinessPlanLeaseReadViewObjectTypeEnum } from '../../../../../GeneratedServices';
import { globalStateCTX } from '../../../../../GlobalState/GlobalState';
import { businessPlanApi } from '../../../../../Http/Http';
import { UserFriendlyApiResponse } from '../../../../../Http/response-error';
import { notEmpty } from '../../../../../Utils/array';
import { applyApiResponseValidationToFields, FieldValidationResult } from '../../../../../Utils/validation';

type ValidationResultWithFieldId = {
  field: BusinessPlanLeaseFormFieldId;
  result: FieldValidationResult;
};
interface BusinessPlanLeaseEntity extends BusinessPlanLeaseReadView {
  validationResult?: ValidationResultWithFieldId[];
}

export enum BusinessPlanLeaseFormFieldId {
  moveOutDate = 'moveOutDate',
  tenantName = 'tenantName',
  downtimePeriod = 'downtimePeriod',
  investment = 'investment',
  baseRent = 'baseRent',
  additions = 'additions',
  indoorArea = 'indoorArea'
}

type BusinessPlanLeaseState = BusinessPlanLeaseEntity;
type BusinessPlanLeaseAction =
  | { type: 'INIT'; lease: BusinessPlanLeaseState }
  | {
      type: 'VALUE_CHANGE';
      formFieldId: BusinessPlanLeaseFormFieldId;
      value?: number | string;
    }
  | {
      type: 'VALIDATION_CHANGE';
      validationResult?: ValidationResultWithFieldId;
    }
  | {
      type: 'VALUE_RESET';
    };

const getNewValuesAfterChange = (
  state: BusinessPlanLeaseState,
  formFieldId: BusinessPlanLeaseFormFieldId,
  value?: number | string
): BusinessPlanLeaseState => {
  switch (formFieldId) {
    case BusinessPlanLeaseFormFieldId.investment:
      return { ...state, investment: value as number };
    case BusinessPlanLeaseFormFieldId.downtimePeriod:
      return { ...state, downtimePeriod: value as number };
    case BusinessPlanLeaseFormFieldId.baseRent:
      return { ...state, baseRent: value as number };
    case BusinessPlanLeaseFormFieldId.additions:
      return { ...state, additions: value as number };
    case BusinessPlanLeaseFormFieldId.moveOutDate:
      return { ...state, moveOutDate: value as string };
    case BusinessPlanLeaseFormFieldId.tenantName:
      return { ...state, tenantName: value as string };
    case BusinessPlanLeaseFormFieldId.indoorArea:
      return { ...state, indoorArea: value as number };
  }
};
function businessPlanLeaseReducer(
  state: BusinessPlanLeaseState,
  action: BusinessPlanLeaseAction
): BusinessPlanLeaseState {
  switch (action.type) {
    case 'INIT':
      return {
        ...action.lease,
        validationResult: undefined
      };

    case 'VALUE_CHANGE':
      return {
        ...getNewValuesAfterChange(state, action.formFieldId, action.value),
        validationResult: undefined
      };
    case 'VALIDATION_CHANGE':
      return {
        ...state,
        validationResult: (state.validationResult
          ? [...state.validationResult, action.validationResult]
          : [action.validationResult]
        ).filter(notEmpty)
      };
    case 'VALUE_RESET':
      return defaultState;
  }
}

export enum BusinessPlanLeaseStatus {
  None,
  Saving,
  Deleting
}

const defaultState = {
  id: 1,
  indoorArea: 1,
  moveInDate: '',
  moveOutDate: '',
  baseRent: 0,
  additions: 0,
  generation: 1,
  downtimePeriod: 0,
  investment: 0,
  propertyId: 0,
  tenantName: '',
  objectId: 0,
  objectType: BusinessPlanLeaseReadViewObjectTypeEnum.Antenna,
  baseRentIndexed: 0,
  additionsIndexed: 0
};

export const useBusinessPlanLeasesReducer = (props: { lease?: BusinessPlanLeaseReadView }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [businessPlanLease, dispatchBusinessPlanLease] = React.useReducer(
    businessPlanLeaseReducer,
    props.lease ? props.lease : defaultState
  );
  const [status, setStatus] = React.useState<BusinessPlanLeaseStatus>(BusinessPlanLeaseStatus.None);

  React.useEffect(() => {
    if (props.lease)
      dispatchBusinessPlanLease({
        type: 'INIT',
        lease: props.lease
      });
  }, [props.lease]);

  const handleResponseValidationError = (resp: UserFriendlyApiResponse) => {
    const allFields = Object.values(BusinessPlanLeaseFormFieldId);
    const fieldIdMapping = allFields.map((item) => ({
      formFieldId: item,
      setStateFunc: (data: any) => {
        dispatchBusinessPlanLease({
          type: 'VALIDATION_CHANGE',
          validationResult: {
            result: data,
            field: item
          }
        });
      }
    }));

    applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  const saveBusinessPlanLease = async () => {
    try {
      setStatus(BusinessPlanLeaseStatus.Saving);

      await businessPlanApi.businessPlanLeaseResourceUpdate({
        id: businessPlanLease.id,
        businessPlanLeaseUpdateView: {
          [BusinessPlanLeaseFormFieldId.tenantName]: businessPlanLease.tenantName,
          [BusinessPlanLeaseFormFieldId.moveOutDate]: businessPlanLease.moveOutDate,
          [BusinessPlanLeaseFormFieldId.indoorArea]: businessPlanLease.indoorArea,
          [BusinessPlanLeaseFormFieldId.downtimePeriod]: businessPlanLease.downtimePeriod,
          [BusinessPlanLeaseFormFieldId.investment]: businessPlanLease.investment,
          [BusinessPlanLeaseFormFieldId.baseRent]: businessPlanLease.baseRent,
          [BusinessPlanLeaseFormFieldId.additions]: businessPlanLease.additions
        }
      });

      return true;
    } catch (error) {
      handleHttpErrors(error, { handleResponseValidationError });
      return false;
    } finally {
      setStatus(BusinessPlanLeaseStatus.None);
    }
  };

  const deleteBusinessPlanLease = async () => {
    try {
      setStatus(BusinessPlanLeaseStatus.Deleting);

      await businessPlanApi.businessPlanLeaseResourceDelete({
        id: businessPlanLease.id
      });
      return true;
    } catch (error) {
      handleHttpErrors(error);
      return false;
    } finally {
      setStatus(BusinessPlanLeaseStatus.None);
    }
  };

  return {
    businessPlanLease,
    saveBusinessPlanLease,
    deleteBusinessPlanLease,
    dispatchBusinessPlanLease,
    saving: status === BusinessPlanLeaseStatus.Saving,
    deleting: status === BusinessPlanLeaseStatus.Deleting
  };
};
