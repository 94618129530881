import React from 'react';
import { GovernmentInspectionWorkOrderReadView } from '../../../GeneratedServices';
import { useTranslation } from 'react-i18next';
import { Confirm } from 'semantic-ui-react';
import PerformGovernmentInspectionModal, { PerformInspectionModalProps } from './PerformGovernmentInspectionModal';
import CreateFaultReportModal from '../../../Views/FaultReports/Components/CreateFaultReportModal';

enum Stage {
  None,
  Initial,
  Create
}
const PerformGovernmentInspectionModalGroup: React.FC<PerformInspectionModalProps> = (props) => {
  const { t } = useTranslation(['inspections', 'common']);

  const [giWorkOrderForFaultReport, setGiWorkOrderForFaultReport] =
    React.useState<GovernmentInspectionWorkOrderReadView>();

  const [isCloseConfirmationModalOpen, setCloseConfirmationModalOpen] = React.useState<boolean>(false);

  const [isPerformModalOpen, setPerformModalOpen] = React.useState<boolean>(true);

  const [faultReportCreationStage, setFaultReportCreationStage] = React.useState<Stage>(Stage.None);

  const cancelFaultReportCreation = () => {
    setFaultReportCreationStage(Stage.None);
    setGiWorkOrderForFaultReport(undefined);
    props.onClose();
  };
  return (
    <>
      {isPerformModalOpen && (
        <PerformGovernmentInspectionModal
          {...props}
          onClose={(showConfirmation) => {
            showConfirmation ? setCloseConfirmationModalOpen(true) : props.onClose();
          }}
          onPerform={(workOrder) => {
            setPerformModalOpen(false);
            props.onPerform(workOrder);

            if (workOrder.itemResults.some((item) => !item.passed)) {
              setGiWorkOrderForFaultReport(workOrder);
              setFaultReportCreationStage(Stage.Initial);
            } else props.onClose();
          }}
        />
      )}

      <Confirm
        open={isCloseConfirmationModalOpen}
        header=""
        content={
          props.governmentInspectionWorkOrderId
            ? t('inspections:areYouSureYouWantToDiscardChanges')
            : t('inspections:areYouSureYouWantToStopPerformingTheInspection')
        }
        confirmButton={t('common:yes')}
        cancelButton={t('common:cancel')}
        onConfirm={() => props.onClose()}
        onCancel={() => setCloseConfirmationModalOpen(false)}
      />

      {giWorkOrderForFaultReport && (
        <>
          <Confirm
            open={faultReportCreationStage === Stage.Initial}
            header=""
            content={t('inspections:wouldYouLikeToCreateAFaultReportForDeniedItems')}
            confirmButton={t('common:yes')}
            cancelButton={t('common:cancel')}
            onConfirm={() => {
              setFaultReportCreationStage(Stage.Create);
            }}
            onCancel={cancelFaultReportCreation}
          />

          {faultReportCreationStage === Stage.Create && (
            <CreateFaultReportModal
              onClose={cancelFaultReportCreation}
              onCreated={props.onClose}
              governmentInspectionWorkOrder={giWorkOrderForFaultReport}
            />
          )}
        </>
      )}
    </>
  );
};

export default PerformGovernmentInspectionModalGroup;
