import React from 'react';
import { Navigate } from 'react-router-dom';
import { globalStateCTX } from '../GlobalState/GlobalState';
import { getUserRoles, Role } from '../Utils/permissions';
import NotFound from './NotFound';

export interface Props {
  allowedRoles?: Role[];
}

const AuthenticatedRoute: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { isAuthenticated, currentUser, currentUserMemberships } = React.useContext(globalStateCTX);

  const isSuperuser = currentUser?.isSuperuser;
  const userRoles = getUserRoles(currentUserMemberships);

  const roleOverlap = userRoles.find((role) => props.allowedRoles?.includes(role));

  if (isSuperuser || roleOverlap || !props.allowedRoles) {
    return isAuthenticated ? <>{React.Children.only(props.children)}</> : <Navigate to="/login" />;
  } else return <NotFound />;
};

export default AuthenticatedRoute;
