/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface MembershipReadView
 */
export interface MembershipReadView {
  /**
   * When entity was created.
   * @type {string}
   * @memberof MembershipReadView
   */
  created: string;
  /**
   * User\'s email.
   * @type {string}
   * @memberof MembershipReadView
   */
  email: string;
  /**
   * Unique identifier.
   * @type {number}
   * @memberof MembershipReadView
   */
  id: number;
  /**
   * Organisation.
   * @type {number}
   * @memberof MembershipReadView
   */
  organisationId: number;
  /**
   * User\'s role in the organisation.
   * @type {string}
   * @memberof MembershipReadView
   */
  role: MembershipReadViewRoleEnum;
  /**
   * When entity was last updated.
   * @type {string}
   * @memberof MembershipReadView
   */
  updated: string;
  /**
   * User\'s full name.
   * @type {string}
   * @memberof MembershipReadView
   */
  userFullName: string;
  /**
   * User.
   * @type {number}
   * @memberof MembershipReadView
   */
  userId: number;
}

export const MembershipReadViewRoleEnum = {
  Admin: 'ADMIN',
  Manager: 'MANAGER',
  Technician: 'TECHNICIAN',
  Investor: 'INVESTOR'
} as const;

export type MembershipReadViewRoleEnum = (typeof MembershipReadViewRoleEnum)[keyof typeof MembershipReadViewRoleEnum];
