import { AxiosRequestConfig } from 'axios';
import { BAM_API_BASE_URL } from '../config';
import { cachedFetcher, http } from '../Http/Http';
import {
  PropertyCheckApplyGeneralView,
  PropertyCheckCreateView,
  PropertyCheckListParams,
  PropertyCheckListView,
  PropertyCheckOrderUpdateView,
  PropertyCheckReadView,
  PropertyCheckUpdateView
} from './PropertyCheckService.types';
import { PropertyReadView } from './PropertyService.types';
import * as PropertyService from '../Services/PropertyService';
import { CachedFetcher } from './Cache';

export const getPropertyChecks = async (
  params: PropertyCheckListParams,
  config?: AxiosRequestConfig
): Promise<PropertyCheckListView> => {
  const { data } = await http.get<PropertyCheckListView>(`${BAM_API_BASE_URL}/v1/property-checks`, {
    ...config,
    params
  });
  return data;
};

export const createPropertyCheck = async (payload: PropertyCheckCreateView) => {
  const { data } = await http.post(`${BAM_API_BASE_URL}/v1/property-checks`, payload);
  return data;
};

export const updatePropertyCheck = async (
  propertyCheckId: number,
  payload: PropertyCheckUpdateView,
  config?: AxiosRequestConfig
) => {
  const { data } = await http.patch<PropertyCheckReadView>(
    `${BAM_API_BASE_URL}/v1/property-checks/${propertyCheckId}`,
    payload,
    config
  );
  return data;
};

export const applyGeneralListToProperties = async (organisationId: number, payload: PropertyCheckApplyGeneralView) => {
  await http.post(`${BAM_API_BASE_URL}/v1/property-checks/apply-general/${organisationId}`, payload);
};

export const deletePropertyCheck = async (propertyCheckId: number) => {
  await http.delete(`${BAM_API_BASE_URL}/v1/property-checks/${propertyCheckId}`);
};

export const updateSortOrderForProperty = async (
  propertyId: number | undefined,
  payload: PropertyCheckOrderUpdateView
) => {
  const { data } = await http.post<PropertyCheckReadView>(
    `${BAM_API_BASE_URL}/v1/property-checks/sort-property/${propertyId}`,
    payload
  );
  return data;
};

export const updateSortOrderForOrganisation = async (
  organisationId: number | undefined,
  payload: PropertyCheckOrderUpdateView
) => {
  const { data } = await http.post<PropertyCheckReadView>(
    `${BAM_API_BASE_URL}/v1/property-checks/sort-general/${organisationId}`,
    payload
  );
  return data;
};

export const getPropertyCheck = async (id: number, config?: AxiosRequestConfig): Promise<PropertyCheckReadView> => {
  const { data } = await http.get<PropertyCheckReadView>(`${BAM_API_BASE_URL}/v1/property-checks/${id}`, { ...config });
  return data;
};

//Extended

export interface PropertyCheckWithPropertyListView extends PropertyCheckListView {
  records: PropertyCheckWithPropertyReadView[];
}

export interface PropertyCheckWithPropertyReadView extends PropertyCheckReadView {
  property?: PropertyReadView;
}

export const getPropertyChecksWithProperty = async (
  params: PropertyCheckListParams,
  config?: AxiosRequestConfig
): Promise<PropertyCheckWithPropertyListView> => {
  const listView = await getPropertyChecks(params, config);

  const cache = {
    getProperty: cachedFetcher((propertyId: number) => PropertyService.getProperty(propertyId, config))
  };

  return {
    ...listView,
    records: await Promise.all(
      listView.records.map(async (readView) => await getPropertyCheckWithPropertyReadView(readView, config, cache))
    )
  };
};

export const getPropertyCheckWithPropertyReadView = async (
  readView: PropertyCheckReadView,
  config?: AxiosRequestConfig,
  cache?: CachedFetcher
): Promise<PropertyCheckWithPropertyReadView> => {
  const property = readView.propertyId
    ? await (cache?.getProperty?.(readView.propertyId) ?? PropertyService.getProperty(readView.propertyId, config))
    : undefined;

  return {
    ...readView,
    property: property
  };
};
