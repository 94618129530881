import React from 'react';
import { Label, SemanticSIZES } from 'semantic-ui-react';
import { toInitials } from '../../Utils/string';
import styles from './UserAvatar.module.scss';

interface Props {
  name: string;
  style?: React.CSSProperties;
  size?: SemanticSIZES;
}

const UserAvatar: React.FC<Props> = (props) => {
  return (
    <Label className={styles.label} circular style={props.style} size={props.size}>
      {toInitials(props.name)}
    </Label>
  );
};

export default UserAvatar;
