import React from 'react';
import { globalStateCTX } from '../../../../../GlobalState/GlobalState';
import { businessPlanApi } from '../../../../../Http/Http';
import { UserFriendlyApiResponse } from '../../../../../Http/response-error';
import { applyApiResponseValidationToFields, FieldValidationResult } from '../../../../../Utils/validation';

export enum BusinessPlanCapexStatus {
  None,
  Saving
}

export const useBusinessPlanCapex = (props: { capex?: number; businessPlanId: number }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  React.useLayoutEffect(() => {
    setNewCapex(props.capex);
  }, [props.capex]);

  const [newCapex, setNewCapex] = React.useState<number | undefined>(props.capex);
  const [capexValidation, setCapexValidation] = React.useState<FieldValidationResult | undefined>();
  const [status, setStatus] = React.useState<BusinessPlanCapexStatus>(BusinessPlanCapexStatus.None);

  const handleCapexResponseValidationError = (resp: UserFriendlyApiResponse) => {
    const fieldIdMapping = [
      {
        formFieldId: 'CAPEX',
        setStateFunc: setCapexValidation
      }
    ];

    applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  const saveCapex = async () => {
    try {
      setStatus(BusinessPlanCapexStatus.Saving);

      await businessPlanApi.businessPlanResourceUpdate({
        id: props.businessPlanId,
        businessPlanUpdateView: {
          CAPEX: newCapex
        }
      });
      return true;
    } catch (error) {
      handleHttpErrors(error, { handleResponseValidationError: handleCapexResponseValidationError });
      return false;
    } finally {
      setStatus(BusinessPlanCapexStatus.None);
    }
  };

  return {
    newCapex,
    setNewCapex,
    capexValidation,
    status,
    saveCapex,
    setCapexValidation
  };
};
