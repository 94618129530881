/**
 * Returns name of file without file extension.
 * @param filename Name of file.
 */
export const filenameWithoutExtensions = (filename: string) => {
  return filename.indexOf('.') === -1 ? filename : filename.split('.').slice(0, -1).join('.');
};

/**
 * Returns file extension (including dot) or undefined if no extension exists.
 * @param filename Name of file.
 */
export const filenameExtension = (filename: string): string | undefined => {
  const ext = filename.split('.').pop();
  return ext === filename || ext?.trim() === '' ? undefined : `.${ext}`;
};

/**
 * Downloads file from specified url. If content disposition in response is not "attachment", the url will be opened instead.
 * @param url URL to file to download.
 */
export const downloadFile = (url: string) => {
  window.location.assign(url);
};

/**
 * Expects a string representing the file result from a FileReader object read as data URL (readAsDataURL).
 * Returns the base64 part of the result or undefined if ready state is not DONE or string doesn't.
 * @param reader
 */
export const getBase64FromReaderResult = (fileData: string) => {
  // Remove "data:*/*;base64," from result to retrieve the base64 part.
  return fileData.split(',').pop();
};

/**
 * Returns base 64 string for a 1x1 transparent gif.
 */
export const transparent1x1Image = () =>
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

/**
 * Converts a file to base64 string
 * @param file file to convert
 * @returns
 */
export const fileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });
