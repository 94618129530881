import React from 'react';
import { Header, Loader, Menu } from 'semantic-ui-react';
import styles from './NotificationsPopup.module.scss';
import ReactDOM from 'react-dom';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { Status as NotificationLoadStatus } from '../../../NotificationState/NotificationState';
import { useIntersectionObserver } from '../../../Utils/hooks';
import LoadError from '../../../Components/LoadError';
import { PropertyTabType } from '../../Properties/PropertyDetails';
import { toDateTimeString } from '../../../Utils/date';
import { contractApi, governmentInspectionApi, propertyApi, workOrderApi } from '../../../Http/Http';
import { useTranslation } from 'react-i18next';
import { NotificationReadView, NotificationReadViewEntityTypeEnum } from '../../../GeneratedServices';
import { useNavigate } from 'react-router-dom';

interface Props {
  status: NotificationLoadStatus;
  notifications: NotificationReadView[];
  hasMore: boolean;
  top: number;
  right: number;
  onRetry: () => void;
  onLoadMore: () => void;
  onClose: () => void;
  onSeen: (notification: NotificationReadView) => void;
}

const NotificationsPopup: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const navigate = useNavigate();

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const componentElementRef = React.useRef<HTMLDivElement>(null);
  const loadMoreElementRef = React.useRef<HTMLDivElement>(null);

  const { onClose: propsOnClose, onLoadMore: propsOnLoadMore } = props;

  // Set up intersection observer to monitor for intersection changes for
  // the load more element container, relative the popup itself (overflow auto).
  // Trig when there is (max) 100 px left before load more element container
  // becomes visible.
  useIntersectionObserver({
    target: loadMoreElementRef,
    options: {
      root: componentElementRef.current,
      rootMargin: '0px 0px 100px 0px',
      threshold: 0
    },
    callback: ([{ isIntersecting }]) => {
      if (isIntersecting) {
        propsOnLoadMore();
      }
    }
  });

  // Close popup when Escape is pressed.
  React.useLayoutEffect(() => {
    const keyFunc = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        propsOnClose();
      }
    };

    document.addEventListener('keydown', keyFunc, false);

    return () => {
      document.removeEventListener('keydown', keyFunc, false);
    };
  }, [propsOnClose]);

  const isLoadMoreElementVisible = () => {
    if (!props.hasMore) {
      return false;
    }

    return ![
      NotificationLoadStatus.LoadingInitial,
      NotificationLoadStatus.LoadingNewer,
      NotificationLoadStatus.LoadingOlder
    ].includes(props.status);
  };

  const redirect = <T extends {}>(url: string, state?: T) => {
    navigate(url, state ? { state } : undefined);
    propsOnClose();
  };

  const handleNotificationClick = async (notification: NotificationReadView) => {
    switch (notification.entityType) {
      case NotificationReadViewEntityTypeEnum.Property:
        redirect(`/properties${notification.entityId ? '/' + notification.entityId : ''}`);
        break;

      case NotificationReadViewEntityTypeEnum.Contract: {
        if (notification.entityId) {
          try {
            const { data: contract } = await contractApi.contractResourceRead({ id: notification.entityId });
            redirect(`/properties/${contract.propertyId}?tab=leases&contractId=${notification.entityId}`);
          } catch (error) {
            handleHttpErrors(error);
          }
        }
        break;
      }

      case NotificationReadViewEntityTypeEnum.Workorder: {
        redirect(`/fault-reports/${notification.entityId}`);
        break;
      }

      case NotificationReadViewEntityTypeEnum.Workorderlog: {
        if (notification.entityId) {
          try {
            const { data: workOrderLog } = await workOrderApi.workOrderLogResourceRead({ id: notification.entityId });
            redirect(`/fault-reports/${workOrderLog.workOrderId}#log-${notification.entityId}`);
          } catch (error) {
            handleHttpErrors(error);
          }
        }
        break;
      }

      case NotificationReadViewEntityTypeEnum.GovernmentInspectionWorkOrder: {
        if (notification.entityId) {
          try {
            const { data: GIWO } = await governmentInspectionApi.governmentInspectionWorkOrderResourceRead({
              id: notification.entityId
            });
            const { data: inspection } = await governmentInspectionApi.governmentInspectionResourceRead({
              id: GIWO.id
            });
            redirect(
              `/properties/${inspection.propertyId}?tab=${PropertyTabType.GOVERNMENT_INSPECTIONS}&governmentInspectionWorkOrderId=${notification.entityId}`
            );
          } catch (error) {
            handleHttpErrors(error);
          }
        }
        break;
      }

      case NotificationReadViewEntityTypeEnum.GovernmentInspection: {
        if (notification.entityId) {
          try {
            const { data: inspection } = await governmentInspectionApi.governmentInspectionResourceRead({
              id: notification.entityId
            });
            redirect(
              `/properties/${inspection.propertyId}?tab=${PropertyTabType.GOVERNMENT_INSPECTIONS}&inspectionId=${notification.entityId}`
            );
          } catch (error) {
            handleHttpErrors(error);
          }
        }
        break;
      }

      case NotificationReadViewEntityTypeEnum.PropertyCheck: {
        if (notification.entityId) {
          try {
            const { data: propertyCheck } = await propertyApi.propertyCheckResourceRead({ id: notification.entityId });
            redirect(
              `/properties/${propertyCheck.propertyId}?tab=${PropertyTabType.PROPERTY_CHECKS}&propertyCheckId=${notification.entityId}`
            );
          } catch (error) {
            handleHttpErrors(error);
          }
        }
        break;
      }
      case NotificationReadViewEntityTypeEnum.Organisation: {
        redirect(`/organisations${notification.entityId ? '/' + notification.entityId : ''}`);
        break;
      }
      case NotificationReadViewEntityTypeEnum.Portfolio: {
        redirect(
          `/organisations/${notification.organisationId}${
            notification.entityId ? `/portfolios/${notification.entityId}` : '?tab=portfolios'
          }`
        );
        break;
      }
    }
  };

  const getLayout = () => {
    switch (props.status) {
      case NotificationLoadStatus.LoadError:
        return (
          <div className={styles.alternateLayout}>
            <LoadError message={t('unableToLoadNotifications')} retry={props.onRetry} />
          </div>
        );
      default:
        return (props.notifications?.length ?? 0) > 0 ? (
          getNotificationsLayout()
        ) : (
          <div className={styles.alternateLayout}>{t('noNotifications')}</div>
        );
    }
  };

  const getNotificationsLayout = () => {
    return (
      <>
        <Menu vertical className={styles.menu}>
          {props.notifications!.map((notification) => (
            <Menu.Item
              key={notification.id}
              as="a"
              className={styles.item}
              data-seen={notification.seen}
              onMouseEnter={() => {
                props.onSeen(notification);
              }}
              onClick={() => handleNotificationClick(notification)}
            >
              <Header as="h5">{notification.title}</Header>
              <p>{notification.message}</p>
              <p>{toDateTimeString(new Date(notification.created))}</p>
            </Menu.Item>
          ))}
        </Menu>

        {isLoadMoreElementVisible() && <div ref={loadMoreElementRef} />}

        {props.status === NotificationLoadStatus.LoadingOlder && (
          <div className={styles.alternateLayout}>
            <Loader inline active size="small" />
          </div>
        )}
      </>
    );
  };

  const notificationsBackground = <div className={styles.background} onClick={props.onClose} />;

  const notificationsWindow = (
    <div
      ref={componentElementRef}
      className={styles.popup}
      style={{
        right: props.right,
        top: props.top
      }}
    >
      {getLayout()}
    </div>
  );

  return ReactDOM.createPortal(
    <aside className={styles.componentWrapper}>
      {notificationsBackground}
      {notificationsWindow}
    </aside>,
    document.body
  );
};

export default NotificationsPopup;
