import React from 'react';
import { StrictTableProps, Table } from 'semantic-ui-react';
import SortableTableState from './Components/SortableTableContext';

interface Props {
  additionalTableProps?: StrictTableProps;
  children: any;
  defaultSortDirection?: SortDirection;
  defaultSortedColumn?: string;
  handleSort: (columnKey?: string, sortDirection?: SortDirection) => void;
  shouldResetSort?: number;
}

export enum SortDirection {
  ASC = 'ascending',
  DESC = 'descending'
}

export const getUpcomingSortDirection = (currentSortDirection?: SortDirection) => {
  if (!currentSortDirection) return SortDirection.ASC;
  else if (currentSortDirection === SortDirection.ASC) {
    return SortDirection.DESC;
  } else return undefined;
};

const SortableTable: React.FC<Props> = (props) => {
  return (
    <SortableTableState
      handleSort={props.handleSort}
      defaultSortDirection={props.defaultSortDirection}
      defaultSortedColumn={props.defaultSortedColumn}
      shouldResetSort={props.shouldResetSort}
    >
      <Table sortable basic="very" {...props.additionalTableProps}>
        {props.children}
      </Table>
    </SortableTableState>
  );
};

export default SortableTable;
