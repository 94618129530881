import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'semantic-ui-react';
import styles from './OverflowButton.module.scss';

interface Props {
  direction: 'left' | 'right';
  onClick: () => void;
  visible: boolean;
}

const OverflowButton: React.FC<Props> = (props) => {
  if (props.visible)
    return (
      <Button className={styles.button} onClick={props.onClick} direction={props.direction}>
        <FontAwesomeIcon icon={props.direction === 'left' ? faChevronLeft : faChevronRight} fixedWidth />
      </Button>
    );
  else return null;
};

export default OverflowButton;
