/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { NotificationListView } from '../model';
// @ts-ignore
import { NotificationReadView } from '../model';
// @ts-ignore
import { NotificationUpdateView } from '../model';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List notifications
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [userId] Filter by user
     * @param {number} [idLt] Filter by id less than
     * @param {number} [idGt] Filter by id greater than
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      userId?: number,
      idLt?: number,
      idGt?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      if (idLt !== undefined) {
        localVarQueryParameter['id_lt'] = idLt;
      }

      if (idGt !== undefined) {
        localVarQueryParameter['id_gt'] = idGt;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a notification
     * @param {number} id Notification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationResourceRead', 'id', id);
      const localVarPath = `/v1/notifications/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a notification
     * @param {number} id Notification ID
     * @param {NotificationUpdateView} [notificationUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationResourceUpdate: async (
      id: number,
      notificationUpdateView?: NotificationUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationResourceUpdate', 'id', id);
      const localVarPath = `/v1/notifications/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List notifications
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [userId] Filter by user
     * @param {number} [idLt] Filter by id less than
     * @param {number} [idGt] Filter by id greater than
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      userId?: number,
      idLt?: number,
      idGt?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationResourceList(
        limit,
        offset,
        organisationId,
        userId,
        idLt,
        idGt,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a notification
     * @param {number} id Notification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a notification
     * @param {number} id Notification ID
     * @param {NotificationUpdateView} [notificationUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationResourceUpdate(
      id: number,
      notificationUpdateView?: NotificationUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationResourceUpdate(
        id,
        notificationUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificationApiFp(configuration);
  return {
    /**
     *
     * @summary List notifications
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [userId] Filter by user
     * @param {number} [idLt] Filter by id less than
     * @param {number} [idGt] Filter by id greater than
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      userId?: number,
      idLt?: number,
      idGt?: number,
      options?: any
    ): AxiosPromise<NotificationListView> {
      return localVarFp
        .notificationResourceList(limit, offset, organisationId, userId, idLt, idGt, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a notification
     * @param {number} id Notification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationResourceRead(id: number, options?: any): AxiosPromise<NotificationReadView> {
      return localVarFp.notificationResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a notification
     * @param {number} id Notification ID
     * @param {NotificationUpdateView} [notificationUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationResourceUpdate(
      id: number,
      notificationUpdateView?: NotificationUpdateView,
      options?: any
    ): AxiosPromise<NotificationReadView> {
      return localVarFp
        .notificationResourceUpdate(id, notificationUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for notificationResourceList operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationResourceListRequest
 */
export interface NotificationApiNotificationResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof NotificationApiNotificationResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof NotificationApiNotificationResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof NotificationApiNotificationResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by user
   * @type {number}
   * @memberof NotificationApiNotificationResourceList
   */
  readonly userId?: number;

  /**
   * Filter by id less than
   * @type {number}
   * @memberof NotificationApiNotificationResourceList
   */
  readonly idLt?: number;

  /**
   * Filter by id greater than
   * @type {number}
   * @memberof NotificationApiNotificationResourceList
   */
  readonly idGt?: number;
}

/**
 * Request parameters for notificationResourceRead operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationResourceReadRequest
 */
export interface NotificationApiNotificationResourceReadRequest {
  /**
   * Notification ID
   * @type {number}
   * @memberof NotificationApiNotificationResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for notificationResourceUpdate operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationResourceUpdateRequest
 */
export interface NotificationApiNotificationResourceUpdateRequest {
  /**
   * Notification ID
   * @type {number}
   * @memberof NotificationApiNotificationResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {NotificationUpdateView}
   * @memberof NotificationApiNotificationResourceUpdate
   */
  readonly notificationUpdateView?: NotificationUpdateView;
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
  /**
   *
   * @summary List notifications
   * @param {NotificationApiNotificationResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public notificationResourceList(
    requestParameters: NotificationApiNotificationResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .notificationResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.userId,
        requestParameters.idLt,
        requestParameters.idGt,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a notification
   * @param {NotificationApiNotificationResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public notificationResourceRead(
    requestParameters: NotificationApiNotificationResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .notificationResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a notification
   * @param {NotificationApiNotificationResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public notificationResourceUpdate(
    requestParameters: NotificationApiNotificationResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .notificationResourceUpdate(requestParameters.id, requestParameters.notificationUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
