import React from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styles from './FileDropzone.module.scss';

interface Props {
  maxFiles?: number;
  multiple?: boolean;
  onDrop: (files: File[]) => void;
  disabled?: boolean;
}

const FileDropzone: React.FC<Props> = (props) => {
  const { t } = useTranslation('files');
  return (
    <Dropzone
      disabled={props.disabled}
      maxFiles={props.maxFiles}
      multiple={props.multiple}
      onDrop={(acceptedFiles) => {
        props.onDrop(acceptedFiles);
      }}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <section className={styles.dropContainer}>
          <div {...getRootProps()} className={styles.dropContainerContent}>
            <input {...getInputProps()} />
            {isDragActive ? <p>{t('dropFilesHere')}</p> : <p>{t('dragFilesHereOrClick')}</p>}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FileDropzone;
