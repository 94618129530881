import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Button, Header, Icon, Loader, Message } from 'semantic-ui-react';
import FileCardList from '../../../Components/FileCardList/FileCardList';
import FileUploadModal from '../../../Components/FileUpload/FileUploadModal';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { createInfinitePaginationParams } from '../../../Http/Http';
import * as FileService from '../../../Services/FileService';
import { getFilesArchiveUrl } from '../../../Services/FileService';
import {
  FileArchiveParams,
  FileInfoListView,
  FileSubType,
  FileType,
  translateFileSubType,
  translateFileType
} from '../../../Services/FileService.types';
import { notEmpty } from '../../../Utils/array';
import { useNonce } from '../../../Utils/hooks';
import { hasPermittedWriteRoleInOrganisation } from '../../../Utils/permissions';
import styles from './TabFiles.module.scss';

interface Props {
  propertyId: number;

  /**
   * Called when files has been uploaded, edited, removed.
   * @param types List of file types which content has changed.
   */
  onChange?: (
    params: { action: 'CREATE' | 'UPDATE' | 'DELETE'; fileId: string; type?: FileType; subType?: FileSubType }[]
  ) => void;
  organisationId?: number;
}

enum Status {
  None,
  Loading,
  LoadError,
  Loaded,
  Saving,
  Deleting
}

const TabFiles: React.FC<Props> = (props) => {
  const { t } = useTranslation(['files', 'common']);

  const { currentUser, currentUserMemberships, handleHttpErrors } = React.useContext(globalStateCTX);
  const isPermitted = hasPermittedWriteRoleInOrganisation(currentUser, currentUserMemberships, props.organisationId);

  const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);
  const [reloadFilesNonce, setReloadFilesNonce] = useNonce();

  const [filesExist, setFilesExist] = React.useState(false);
  const [retry, setRetry] = useNonce();

  const [status, setStatus] = React.useState<Status>(Status.None);
  const [files, setFiles] = React.useState<FileInfoListView>();

  const [fileTypes, setFileTypes] = React.useState<FileType[]>([]);
  const [fileSubTypes, setFileSubTypes] = React.useState<FileSubType[]>([]);

  const [expandedFileTypes, setExpandedFileTypes] = React.useState<FileType[]>([]);
  const [expandedFileSubTypes, setExpandedFileSubTypes] = React.useState<{ type: FileType; subType: FileSubType }[]>(
    []
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const load = async () => {
      try {
        setStatus(Status.Loading);

        const files = await FileService.getFiles(
          {
            ...createInfinitePaginationParams(),
            propertyId: props.propertyId
          },
          { signal: abortController.signal }
        );
        setFiles(files);

        if (files.records.length !== 0) {
          setFileTypes(Array.from(new Set(files.records.map((item) => item.type).filter(notEmpty))));
          setFileSubTypes(Array.from(new Set(files.records.map((item) => item.subType).filter(notEmpty))));
        }

        setFilesExist(files.totalCount > 0);
        setStatus(Status.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [props.propertyId, retry, reloadFilesNonce, handleHttpErrors]);

  const handleFileSubTypeClick = (type: FileType, subType: FileSubType) => {
    if (expandedFileSubTypes.find((item) => item.subType === subType && item.type === type)) {
      setExpandedFileSubTypes((prevSubTypes) =>
        prevSubTypes.filter((item) => !(item.type === type && item.subType === subType))
      );
    } else setExpandedFileSubTypes((prevSubTypes) => [...prevSubTypes, { type, subType }]);
  };

  const handleFileTypeClick = (type: FileType) => {
    if (expandedFileTypes.includes(type)) {
      setExpandedFileTypes((prevTypes) => prevTypes.filter((_type) => _type !== type));
    } else setExpandedFileTypes((prevTypes) => [...prevTypes, type]);
  };

  const downloadFiles = async (params: FileArchiveParams) => {
    try {
      const fileUrl = await getFilesArchiveUrl(params);
      window.location.assign(fileUrl);
    } catch (error) {
      handleHttpErrors(error);
    }
  };

  const getLayout = () => {
    switch (status) {
      case Status.Loading:
        return files?.records.length !== 0 && layoutLoading;
      case Status.LoadError:
        return layoutLoadError;
      default:
        return layoutMain;
    }
  };

  const layoutLoading = (
    <div style={{ padding: 20 }}>
      <Loader active inline="centered" size="large" />
    </div>
  );

  const layoutLoadError = (
    <>
      <Message error>{t('files:unableToReadFiles')}</Message>
      <div style={{ textAlign: 'right' }}>
        <Button secondary onClick={setRetry}>
          {t('common:tryAgain')}
        </Button>
      </div>
    </>
  );

  const fileSubTypesLayout = (type: FileType, subType: FileSubType) => {
    const filesWithSubType = files?.records.filter((file) => file.subType === subType && file.type === type);
    const isSubTypeExpanded = expandedFileSubTypes.find((item) => item.subType === subType && item.type === type)
      ? true
      : false;
    return (
      <Accordion.Panel
        key={subType}
        title={
          <Accordion.Title active={isSubTypeExpanded} className={styles.listItemContainer}>
            <div
              className={styles.listItemContentContainer}
              onClick={() => {
                handleFileSubTypeClick(type, subType);
              }}
            >
              <Icon name="dropdown" />
              <div>{translateFileSubType(subType)}</div>
            </div>

            <div className={styles.buttonContainer}>
              <div style={{ paddingRight: 10, cursor: 'default' }}>({filesWithSubType?.length})</div>

              <Button primary compact onClick={() => downloadFiles({ propertyId: props.propertyId, type, subType })}>
                {t('common:download')}
              </Button>
            </div>
          </Accordion.Title>
        }
        content={
          <Accordion.Content active={isSubTypeExpanded}>
            <FileCardList
              files={filesWithSubType}
              readOnly={!isPermitted}
              reloadFiles={setReloadFilesNonce}
              allowCategoryEditing
              onFileChange={(action, file) => {
                props.onChange && props.onChange([{ action: action, fileId: file.id, ...file }]);
              }}
            />
          </Accordion.Content>
        }
      />
    );
  };

  const layoutMain = !filesExist ? (
    <Message style={{ width: '100%', textAlign: 'center' }}>{t('files:noFilesFound')}</Message>
  ) : (
    <>
      <Accordion styled fluid>
        {fileTypes.map((type) => {
          const allSubTypesForType = FileService.getSubTypesForType(type);
          const availableSubTypesForType = fileSubTypes.filter((subType) => allSubTypesForType.includes(subType));
          const filesWithType = files?.records.filter((file) => file.type === type);
          const filesExclusiveToType = filesWithType?.filter((file) => file.subType === null);

          return (
            <Accordion.Panel
              title={
                <Accordion.Title
                  active={expandedFileTypes.includes(type)}
                  className={styles.listItemContainer}
                  key={type}
                >
                  <div
                    className={styles.listItemContentContainer}
                    onClick={() => {
                      handleFileTypeClick(type);
                    }}
                  >
                    <Icon name="dropdown" />
                    <div>{translateFileType(type)}</div>
                  </div>

                  <div className={styles.buttonContainer}>
                    <div style={{ paddingRight: 10, cursor: 'default' }}>({filesWithType?.length})</div>

                    <Button primary compact onClick={() => downloadFiles({ propertyId: props.propertyId, type })}>
                      {t('common:download')}
                    </Button>
                  </div>
                </Accordion.Title>
              }
              content={
                <Accordion.Content active={expandedFileTypes.includes(type)}>
                  <FileCardList
                    files={filesExclusiveToType}
                    readOnly={!isPermitted}
                    reloadFiles={setReloadFilesNonce}
                    allowCategoryEditing
                    onFileChange={(action, file) => {
                      props.onChange && props.onChange([{ action: action, fileId: file.id, ...file }]);
                    }}
                  />

                  {availableSubTypesForType.length !== 0 && (
                    <Accordion.Accordion>
                      {availableSubTypesForType.map((subType) => fileSubTypesLayout(type, subType))}
                    </Accordion.Accordion>
                  )}
                </Accordion.Content>
              }
              key={type}
            />
          );
        })}
      </Accordion>
    </>
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <Header as="h3">{t('common:files')}</Header>
        <div style={{ textAlign: 'right', marginBottom: '1em' }}>
          {isPermitted && (
            <Button primary onClick={() => setShowFileUploadModal(true)}>
              {t('common:uploadFiles')}
            </Button>
          )}

          <Button primary onClick={() => downloadFiles({ propertyId: props.propertyId })}>
            {t('files:downloadAllFiles')}
          </Button>
        </div>
      </div>
      {getLayout()}

      {showFileUploadModal && (
        <FileUploadModal
          entityType="PROPERTY"
          entityId={props.propertyId}
          onClose={(uploadedFiles) => {
            setShowFileUploadModal(false);

            // Any files uploaded?
            if (uploadedFiles) {
              setReloadFilesNonce();

              props.onChange &&
                props.onChange(
                  uploadedFiles.map((item) => ({
                    action: 'CREATE',
                    fileId: item.id,
                    ...item
                  }))
                );
            }
          }}
        />
      )}
    </div>
  );
};

export default TabFiles;
