import { debounce } from 'lodash';
import React, { RefObject } from 'react';

/**
 * Returns a React.useEffect that runs specified function callback only after first render.
 */
export const useDidMountEffect = (func: React.EffectCallback, deps: React.DependencyList) => {
  const didMount = React.useRef(false);

  React.useEffect(
    () => {
      if (didMount.current) {
        func();
      } else {
        didMount.current = true;
      }
    },

    // todo: Try to remove this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
};

/**
 * Returns a stateful nonce value, a function to update it and a function to reset it.
 */
export const useNonce = (): [number, () => void, () => void] => {
  const [nonce, setNonce] = React.useState<number>(USE_NONCE_HOOK_DEFAULT_VALUE);
  return [nonce, () => setNonce((value) => value + 1), () => setNonce(USE_NONCE_HOOK_DEFAULT_VALUE)];
};

/**
 * Default value for useNonce hook.
 */
export const USE_NONCE_HOOK_DEFAULT_VALUE = 0;

interface UseMutationObserverProps {
  target?: React.RefObject<Element>;
  options?: MutationObserverInit;
  callback?: MutationCallback;
}

/**
 * Hook for setting up a MutationObserver.
 */
export const useMutationObserver = ({ target, options = {}, callback }: UseMutationObserverProps): void => {
  const observer = React.useMemo(
    () => new MutationObserver((mutationRecord, mutationObserver) => callback?.(mutationRecord, mutationObserver)),
    [callback]
  );

  React.useEffect(() => {
    const element = target?.current;

    if (observer && element) {
      observer.observe(element, options);
      return () => observer.disconnect();
    }
  }, [target, observer, options]);
};

interface UseIntersectionObserverProps {
  target?: React.RefObject<Element>;
  options?: IntersectionObserverInit;
  callback?: IntersectionObserverCallback;
}

/**
 * Hook for setting up a IntersectionObserver.
 */
export const useIntersectionObserver = ({ target, options = {}, callback }: UseIntersectionObserverProps): void => {
  const observer = React.useMemo(
    () => new IntersectionObserver((entry, observer) => callback?.(entry, observer), options),
    [options, callback]
  );

  React.useEffect(() => {
    const element = target?.current;

    if (observer && element) {
      observer.observe(element);
      return () => observer.disconnect();
    }
  }, [target, observer, options]);
};

/**
 * Returns a function that indicates whether the component is currently mounted.
 */
export const useIsMounted = () => {
  const isMountedRef = React.useRef(true);
  const isMounted = React.useCallback(() => isMountedRef.current, []);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMounted;
};

/**
 * @returns The dimensions of a referenced element and tracks their change.
 */
export const useDimensions = () => {
  const [dimensions, setDimensions] = React.useState<{ width: number; height: number }>();
  const [node, setNode] = React.useState<HTMLDivElement | null>(null);

  const triggerDimensionChange = React.useMemo(() => debounce(setDimensions, 300), [setDimensions]);

  React.useEffect(() => () => triggerDimensionChange.cancel(), [triggerDimensionChange]);

  const ref = React.useCallback((node: HTMLDivElement) => {
    setNode(node);
  }, []);

  const handleResize = React.useCallback(() => {
    if (node) {
      const boundingRect = node.getBoundingClientRect();
      const { width, height } = boundingRect;
      triggerDimensionChange({ width, height });
    }
  }, [node, triggerDimensionChange]);

  React.useEffect(() => {
    handleResize();

    const observer = new ResizeObserver(handleResize);
    if (node) {
      observer.observe(node);
    }

    return () => observer.disconnect();
  }, [node, handleResize]);

  return { ref, dimensions };
};

/**
 * Returns a boolean indicating whether the two elements intersect or not.
 * Ratio is set at 0.95 as it appears that it is not as precise in some scenarios.
 * @param element element to track
 * @param root element to compare with, if left undefined it considers the screen as the root
 */
export function useIntersection(element?: HTMLElement, root?: RefObject<HTMLElement>): boolean {
  const observerRef = React.useRef<IntersectionObserver>();
  const [isOnScreen, setIsOnScreen] = React.useState(false);

  React.useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        const ratio = entry.intersectionRatio;
        if (entry.isIntersecting === true) {
          if (ratio > 0.95) {
            setIsOnScreen(true);
          } else setIsOnScreen(false);
        } else setIsOnScreen(false);
      },
      { threshold: [0, 0.95, 1], root: root?.current }
    );

    return () => {
      observerRef.current?.disconnect();
      observerRef.current = undefined;
    };
  }, [root]);

  React.useEffect(() => {
    if (element) {
      observerRef.current?.observe(element);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [element]);

  return isOnScreen;
}

/**
 *
 * @returns The position of the mouse within the application's viewport
 */
export const useMousePosition = () => {
  const [position, setPosition] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 });

  React.useEffect(() => {
    const handleWindowMouseMove = (event: MouseEvent) => {
      setPosition({
        x: event.pageX,
        y: event.pageY
      });
    };
    window.addEventListener('mousemove', handleWindowMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
    };
  }, []);
  return position;
};
