import { Link } from 'react-router-dom';
import React from 'react';
import { Button, Header, Input, Loader, Message, Table } from 'semantic-ui-react';
import LoadError from '../../../../Components/LoadError';
import { MembershipReadView, PropertySubscriptionReadView } from '../../../../GeneratedServices';
import { MembershipStatus } from './SubscriptionsModal';
import styles from './AddSubscriptionsTab.module.scss';
import { debounce } from 'lodash';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';
import { propertySubscriptionApi } from '../../../../Http/Http';
import { Role, translateRole } from '../../../../Utils/permissions';
import { useTranslation } from 'react-i18next';
import { isIncludedInAny } from '../../../../Utils/string';

interface Props {
  subscriptions: PropertySubscriptionReadView[];
  reloadSubscriptions: () => void;
  organisationId: number;
  users: MembershipReadView[];
  userStatus: MembershipStatus;
  reloadUsers: () => void;
  propertyId: number;
}

enum Status {
  Saving,
  None
}

type MembershipSubscriptionEntity = MembershipReadView & {
  status: Status;
  subscriptionId: number | null;
};
type MembershipSubscriptionsState = MembershipSubscriptionEntity[];
type MembershipSubscriptionsAction =
  | { type: 'INIT'; data: MembershipSubscriptionsState }
  | {
      type: 'STATUS_CHANGE';
      id: number;
      status: Status;
    }
  | {
      type: 'SUBSCRIPTION_CHANGE';
      id: number;
      subscriptionId: number | null;
    };

export function membershipsSubscriptionReducer(
  state: MembershipSubscriptionsState,
  action: MembershipSubscriptionsAction
): MembershipSubscriptionsState {
  switch (action.type) {
    case 'INIT':
      return action.data;
    case 'STATUS_CHANGE':
      return state.map((item) =>
        item.id === action.id
          ? {
              ...item,
              status: action.status
            }
          : item
      );
    case 'SUBSCRIPTION_CHANGE':
      return state.map((item) =>
        item.id === action.id
          ? {
              ...item,
              subscriptionId: action.subscriptionId,
              status: Status.None
            }
          : item
      );
  }
}

const AddSubscriptionsTab: React.FC<Props> = (props) => {
  const { t } = useTranslation(['users', 'common']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [searchValue, setSearchValue] = React.useState<string>('');
  const triggerSearch = React.useMemo(() => debounce(setSearchValue, 300), [setSearchValue]);

  React.useEffect(() => () => triggerSearch.cancel(), [triggerSearch]);

  const [membershipsSubscriptions, dispatchMembershipSubscriptions] = React.useReducer(
    membershipsSubscriptionReducer,
    []
  );

  React.useLayoutEffect(() => {
    if (membershipsSubscriptions.length === 0 && props.users.length !== 0) {
      dispatchMembershipSubscriptions({
        type: 'INIT',
        data: props.users.map((item) => {
          const subscription = props.subscriptions.find((subscription) => subscription.userId === item.userId);
          return {
            ...item,
            status: Status.None,
            subscriptionId: subscription?.id ?? null
          };
        })
      });
    }
  }, [props.users, props.subscriptions, membershipsSubscriptions]);

  const handleSubscriptionChange = async (item: MembershipSubscriptionEntity) => {
    try {
      dispatchMembershipSubscriptions({
        type: 'STATUS_CHANGE',
        id: item.id,
        status: Status.Saving
      });

      if (!item.subscriptionId) {
        const { data } = await propertySubscriptionApi.propertySubscriptionResourceCreate({
          propertySubscriptionCreateView: {
            propertyId: props.propertyId,
            userId: item.userId
          }
        });

        dispatchMembershipSubscriptions({
          type: 'SUBSCRIPTION_CHANGE',
          id: item.id,
          subscriptionId: data.id
        });
      }

      props.reloadSubscriptions();
    } catch (error) {
      handleHttpErrors(error);
    } finally {
      dispatchMembershipSubscriptions({
        type: 'STATUS_CHANGE',
        id: item.id,
        status: Status.None
      });
    }
  };

  const getLayout = () => {
    switch (props.userStatus) {
      case MembershipStatus.Loading:
        return loadingLayout;
      case MembershipStatus.LoadError:
        return loadErrorLayout;
      case MembershipStatus.None:
        return generalLayout;
    }
  };

  const filteredUsers = React.useMemo(
    () => membershipsSubscriptions.filter((item) => isIncludedInAny(searchValue, [item.userFullName, item.email])),
    [searchValue, membershipsSubscriptions]
  );

  const loadingLayout = (
    <div className={styles.loadingContainer}>
      <Loader active inline="centered" size="large" />
    </div>
  );

  const loadErrorLayout = <LoadError message={t('users:unableToLoadUsers')} retry={props.reloadUsers} />;

  const generalLayout =
    filteredUsers.length !== 0 ? (
      <Table basic="very">
        <Table.Body>
          {filteredUsers.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>
                <Link to={`/users/${item.userId}`}>{item.userFullName}</Link>
              </Table.Cell>

              <Table.Cell>{translateRole(item.role as Role)}</Table.Cell>

              <Table.Cell collapsing>
                <Button
                  color={item.subscriptionId ? 'grey' : 'green'}
                  fluid
                  disabled={item.status === Status.Saving || item.subscriptionId !== null}
                  loading={item.status === Status.Saving}
                  onClick={() => handleSubscriptionChange(item)}
                >
                  {item.subscriptionId ? t('common:added') : t('common:add')}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    ) : (
      <Message>{t('users:noUsersFound')}</Message>
    );

  return (
    <>
      <Input
        icon="search"
        iconPosition="left"
        fluid
        placeholder={t('common:search')}
        onChange={(e, d) => {
          triggerSearch(d.value);
        }}
      />
      <Header as="h5">{t('users:suggestions')}</Header>
      <div className={styles.scrollableContainer}>{getLayout()}</div>
    </>
  );
};

export default AddSubscriptionsTab;
