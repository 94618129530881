import React from 'react';
import { ContractListItemView, PortfolioReadView, PropertyReadView } from '../../../../../GeneratedServices';
import { globalStateCTX } from '../../../../../GlobalState/GlobalState';
import { contractApi, portfolioApi, propertyApi } from '../../../../../Http/Http';
import { useNonce } from '../../../../../Utils/hooks';
import { usePropertiesThumbnails } from '../../../../../Views/Properties/Hooks/usePropertiesThumbnails';
import { ContractStatus } from '../ContractModal';

export const useContractDetails = (props: { contractId: number }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [retry, setRetry] = useNonce();
  const [status, setStatus] = React.useState<ContractStatus>(ContractStatus.Loading);

  const [contract, setContract] = React.useState<ContractListItemView>();
  const [property, setProperty] = React.useState<PropertyReadView>();
  const [portfolios, setPortfolios] = React.useState<PortfolioReadView[]>();

  const { loadThumbnails, propertiesThumbnails } = usePropertiesThumbnails();
  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(ContractStatus.Loading);

        const { data: contract } = await contractApi.contractResourceRead(
          { id: props.contractId },
          { signal: abortController.signal }
        );

        const propertyResult = contract.propertyId
          ? await propertyApi.propertyResourceRead({ id: contract.propertyId }, { signal: abortController.signal })
          : undefined;

        const portfoliosResult = await portfolioApi.portfolioResourceList(
          { organisationId: contract.organisationId },
          { signal: abortController.signal }
        );

        let portfoliosForProperty;

        if (propertyResult) {
          portfoliosForProperty = portfoliosResult.data.records.filter((item) =>
            item.companyIds.includes(propertyResult.data.companyId)
          );
          if (propertyResult.data.pictureId) {
            loadThumbnails([propertyResult.data.pictureId], abortController);
          }
        }
        setContract(contract);
        setProperty(propertyResult?.data);
        setPortfolios(portfoliosForProperty);

        setStatus(ContractStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(ContractStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, props.contractId, retry, loadThumbnails]);

  let pictureUrl;
  if (property?.pictureId && propertiesThumbnails[property.pictureId])
    pictureUrl = propertiesThumbnails[property.pictureId];

  return {
    contract,
    property,
    status,
    reloadContractInfo: setRetry,
    propertyPictureUrl: pictureUrl,
    portfolios
  };
};
