import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { FiltersContext } from '../../../FiltersProvider/FiltersProvider';
import { OrganisationReadView } from '../../../GeneratedServices';
import { NetworkRequestStatus } from '../../../Http/Http';
import { mapOrganisationsToDropdownItems } from '../../../Services/OrganisationService.types';
import FilterLabel from '../FilterLabel';

interface Props {
  selectedOrganisationIds?: number[];
  handleOrganisationsChange: (organisationIds: number[]) => void;
  organisations: OrganisationReadView[];
}
const OrganisationDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common']);
  const { organisationsStatus } = React.useContext(FiltersContext);

  return (
    <Dropdown
      placeholder={t('common:allOrganisations')}
      error={organisationsStatus === NetworkRequestStatus.LoadError}
      disabled={organisationsStatus !== NetworkRequestStatus.None}
      loading={organisationsStatus === NetworkRequestStatus.Loading}
      search
      renderLabel={(item, i, prop) => <FilterLabel text={item.text} type="organisation" labelProps={prop} />}
      multiple
      fluid
      scrolling
      value={props.selectedOrganisationIds ?? []}
      selection
      floating
      clearable
      options={mapOrganisationsToDropdownItems(props.organisations)}
      onChange={(e, d) => {
        props.handleOrganisationsChange(d.value as number[]);
      }}
    />
  );
};

export default OrganisationDropdown;
