import React from 'react';
import { ContractPaymentReadView } from '../../../../../GeneratedServices';
import { globalStateCTX } from '../../../../../GlobalState/GlobalState';
import { contractApi, createInfinitePaginationParams } from '../../../../../Http/Http';
import { useNonce } from '../../../../../Utils/hooks';
import { ContractStatus } from '../ContractModal';

export const useContractPayments = (props: { contractId: number }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [retry, setRetry] = useNonce();
  const [status, setStatus] = React.useState<ContractStatus>(ContractStatus.Loading);

  const [contractPayments, setContractPayments] = React.useState<ContractPaymentReadView[]>();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(ContractStatus.Loading);

        const { data } = await contractApi.contractPaymentResourceList(
          { contractId: props.contractId, ...createInfinitePaginationParams() },
          { signal: abortController.signal }
        );

        setContractPayments(data.records);

        setStatus(ContractStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(ContractStatus.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, props.contractId, retry]);

  return {
    contractPayments,
    status,
    reloadPayments: setRetry
  };
};
