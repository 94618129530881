import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Header, Icon } from 'semantic-ui-react';
import { globalStateCTX } from '../GlobalState/GlobalState';

const NotFound: React.FC<{}> = (props) => {
  const { isAuthenticated } = React.useContext(globalStateCTX);
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation(['common']);

  if (!isAuthenticated) return <Navigate to="/login" state={{ from: location.pathname }} />;
  return (
    <Container textAlign="center" style={{ paddingTop: '10rem' }}>
      <Header as="h2" icon>
        <Icon name="ban" color="red" />
        404 Page not found
        <Header.Subheader />
      </Header>
      <div>
        <Button primary onClick={() => navigate('/')}>
          {t('common:backToMainPage')}
        </Button>
      </div>
    </Container>
  );
};

export default NotFound;
