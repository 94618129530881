import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import NumberFormater from '../NumberFormater/NumberFormater';
import KpiCard, { KPISize, KPIStatus } from './KPICard/KpiCard';
import React from 'react';
import { statisticsApi } from '../../Http/Http';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';
import { CoverageRatioListView } from '../../GeneratedServices';
import { Header, Popup } from 'semantic-ui-react';
import { translateCoverageRatioType } from '../../Services/StatisticService';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}

const CoverageRatioKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation(['contracts', 'common', 'financialStatistics']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [coverageRatio, setCoverageRatio] = React.useState<CoverageRatioListView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceReadCoverageRatio(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setCoverageRatio(data);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();

    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload]);

  return (
    <Popup
      hoverable
      position="bottom center"
      disabled={!coverageRatio || status !== KPIStatus.None}
      trigger={
        <div>
          <KpiCard
            title={t('contracts:coverageRatio')}
            valueAs="h5"
            value={
              coverageRatio ? (
                <CoveragePercentage
                  contribution={coverageRatio.totalContribution}
                  salePrice={coverageRatio.totalSalePrice}
                />
              ) : (
                ''
              )
            }
            status={status}
            retry={reload}
            size={KPISize.small}
          />
        </div>
      }
      content={
        <>
          {coverageRatio?.records.map((item) => (
            <React.Fragment key={item.type}>
              <Header as="h5" style={{ marginBottom: 7 }}>
                {translateCoverageRatioType(item.type)}
              </Header>
              <CoveragePercentage contribution={item.contribution} salePrice={item.salePrice} />
            </React.Fragment>
          ))}
        </>
      }
    />
  );
};

const CoveragePercentage = (props: { salePrice: number; contribution: number }) => {
  const { contribution, salePrice } = props;

  let normalizedsalePrice = salePrice;
  if (salePrice === 0) {
    normalizedsalePrice = 1;

    if (contribution === 0) {
      return <div>N/A</div>;
    }
  }
  return <NumberFormater value={(contribution / normalizedsalePrice) * 100} suffix={'%'} />;
};

export default CoverageRatioKpi;
