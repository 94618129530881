import React from 'react';
import { Label } from 'semantic-ui-react';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {}
const FinancialStatisticsGraphLegend: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'financialStatistics']);
  return (
    <div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
      <Label circular style={{ backgroundColor: colors.chart4 }} size="tiny" />
      <div>{t('financialStatistics:outcome')}</div>
      <Label circular style={{ backgroundColor: colors.chart1 }} size="tiny" />
      <div>{t('common:businessPlan')}</div>
    </div>
  );
};
// TODO: Fix error with legend
export default FinancialStatisticsGraphLegend;
