import React from 'react';
import { globalStateCTX } from '../GlobalState/GlobalState';
import { createPaginationParams, MAX_LIST_LIMIT, notificationApi } from '../Http/Http';
import { useNonce, USE_NONCE_HOOK_DEFAULT_VALUE } from '../Utils/hooks';
import { AxiosError } from 'axios';
import { NotificationApiNotificationResourceListRequest, NotificationReadView } from '../GeneratedServices';

export enum Status {
  None,
  Idle,
  LoadingInitial,
  LoadingNewer,
  LoadingOlder,
  LoadError
}

interface NotificationStateCTX {
  status: Status;
  unseenCount: number;
  totalCount: number;
  notifications: NotificationReadView[];
  onRetry: () => void;
  onLoadMore: () => void;
  updateToSeen: (notification: NotificationReadView) => Promise<void>;
}

const initialState: NotificationStateCTX = {
  status: Status.Idle,
  unseenCount: 0,
  totalCount: 0,
  notifications: [],
  onRetry: () => {},
  onLoadMore: () => {},
  updateToSeen: () => {
    return Promise.resolve();
  }
};

type NotificationItemState = NotificationReadView[];
type NotificationItemAction =
  | { type: 'PREPEND'; notifications: NotificationItemState }
  | { type: 'APPEND'; notifications: NotificationItemState }
  | { type: 'SEEN'; id: number };

const REFRESH_MINUTES = 0.5;
const FETCH_LIMIT = 10;

enum FetchType {
  LoadNewer = 'LoadNewer',
  LoadOlder = 'LoadOlder'
}

function notificationReducer(state: NotificationItemState, action: NotificationItemAction): NotificationItemState {
  switch (action.type) {
    // Add new notifications at top.
    case 'PREPEND': {
      return [...action.notifications, ...state];
    }

    // Add more notifications (older) to bottom.
    case 'APPEND': {
      return [...state, ...action.notifications];
    }

    case 'SEEN': {
      return state.map((item) => (item.id === action.id ? { ...item, seen: true } : item));
    }
  }
}

export const notificationStateCTX = React.createContext<NotificationStateCTX>(initialState);

type Props = { children: any };

const NotificationState: React.FC<Props> = ({ children }) => {
  const globalState = React.useContext(globalStateCTX);
  const [totalCount, setTotalCount] = React.useState(0);
  const [unseenCount, setUnseenCount] = React.useState(0);
  const [notifications, dispatchNotifications] = React.useReducer(notificationReducer, []);
  const [status, setStatus] = React.useState(Status.None);
  const [prevFetchType, setPrevFetchType] = React.useState(FetchType.LoadOlder);

  const [retryNonce, setRetryNonce] = useNonce();

  /**
   * Returns loading state.
   */
  const resolveLoadingState = React.useCallback(
    (fetchType: FetchType): Status => {
      if (notifications.length === 0) {
        return Status.LoadingInitial;
      }

      switch (fetchType) {
        case FetchType.LoadNewer:
          return Status.LoadingNewer;

        case FetchType.LoadOlder:
          return Status.LoadingOlder;
      }
    },
    [notifications]
  );

  /**
   * On first fetch (when no notifications exist) ignore specified fetch
   * type and initialize list by loading N first notifications.
   */
  const resolvePaginationParams = React.useCallback(
    (fetchType: FetchType): NotificationApiNotificationResourceListRequest => {
      if (notifications.length === 0) {
        return createPaginationParams(1, FETCH_LIMIT);
      }

      switch (fetchType) {
        case FetchType.LoadNewer:
          return {
            idGt: notifications[0].id,
            limit: MAX_LIST_LIMIT
          };

        case FetchType.LoadOlder:
          return {
            idLt: notifications[notifications.length - 1].id,
            limit: FETCH_LIMIT
          };
      }
    },
    [notifications]
  );

  const fetchNotifications = React.useCallback(
    async (fetchType: FetchType) => {
      setPrevFetchType(fetchType);
      const abortController = new AbortController();

      try {
        setStatus(resolveLoadingState(fetchType));

        const { data: result } = await notificationApi.notificationResourceList(
          {
            ...resolvePaginationParams(fetchType),
            userId: globalState.currentUser!.id
          },
          {
            signal: abortController.signal
          }
        );

        // On first fetch, initialize total count and unseen count.
        //
        // When fetching newer notifications (background task), add
        // number of new notifications to total count and unseen count.
        //
        // When fetching older notifications, don't alter total count
        // and unseen count.
        if (notifications.length === 0) {
          setTotalCount(result.totalCount);
          setUnseenCount(result.unseenCount);
        } else if (fetchType === FetchType.LoadNewer) {
          setTotalCount(totalCount + result.totalCount);
          setUnseenCount(unseenCount + result.unseenCount);
        }

        dispatchNotifications({
          type: fetchType === FetchType.LoadOlder ? 'APPEND' : 'PREPEND',
          notifications: result.records
        });

        setStatus(Status.Idle);
      } catch (error) {
        setStatus(Status.LoadError);
      }

      return () => abortController.abort();
    },
    [globalState.currentUser, notifications, totalCount, unseenCount, resolveLoadingState, resolvePaginationParams]
  );

  React.useEffect(() => {
    // Handles retry on fetch error.
    // Ignore initial value. Only trig on future nonce changes.
    if (retryNonce > USE_NONCE_HOOK_DEFAULT_VALUE) {
      fetchNotifications(prevFetchType);
    }
  }, [retryNonce, prevFetchType, fetchNotifications]);

  React.useEffect(() => {
    // First run.
    if (status === Status.None) {
      fetchNotifications(FetchType.LoadOlder);
    }

    // Background task to fetch newer notifications each N minutes.
    const id = setInterval(() => {
      fetchNotifications(FetchType.LoadNewer);
    }, REFRESH_MINUTES * 60 * 1000);

    return () => {
      clearInterval(id);
    };
  }, [status, fetchNotifications]);

  const onLoadMore = () => {
    fetchNotifications(FetchType.LoadOlder);
  };

  const onSeen = React.useCallback(
    (id: number) => {
      setUnseenCount(unseenCount - 1);
      dispatchNotifications({
        type: 'SEEN',
        id
      });
    },
    [unseenCount]
  );

  const onRetry = () => {
    setRetryNonce();
  };

  const updateToSeen = React.useCallback(
    async (notification: NotificationReadView) => {
      if (notification.seen) {
        return;
      }

      try {
        await notificationApi.notificationResourceUpdate({
          id: notification.id,
          notificationUpdateView: {
            seen: true
          }
        });
        onSeen(notification.id);
      } catch (error) {
        globalState.handleHttpErrors(error as AxiosError);
      }
    },
    [globalState, onSeen]
  );

  return (
    <notificationStateCTX.Provider
      value={{
        status,
        unseenCount,
        notifications,
        totalCount,
        onRetry,
        onLoadMore,
        updateToSeen
      }}
    >
      {children}
    </notificationStateCTX.Provider>
  );
};

export default NotificationState;
