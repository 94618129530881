import {
  faHouseBuilding,
  faClipboardList,
  faDoorClosed,
  faFan,
  faFaucet,
  faFire,
  faLeaf,
  faUsers,
  IconDefinition
} from '@fortawesome/pro-solid-svg-icons';
import { WorkOrderReadViewCategoryEnum, WorkOrderReadViewStatusEnum } from '../GeneratedServices';
import { ListViewPagination, ListViewPaginationParams } from './types';
import i18n from '../i18n';
import { DropdownItemProps } from 'semantic-ui-react';
import { localeCompareSv } from '../Utils/string';

export interface WorkOrderAnonymousCreateView {
  category: WorkOrderCategory;
  description: string;
  tenantEmail: string;
  tenantPhone: string;
  tenantName: string;
  propertyPostCode: string;
  propertyCity: string;
  propertyAddress: string;
  organisationId: number;
}

export interface WorkOrderListViewParams extends ListViewPaginationParams {
  propertyId?: number;
  tenantId?: number;
  status?: WorkOrderStatus;
  category?: WorkOrderCategory;
  isFinished?: boolean;
  sortBy?: WorkOrderSortBy;
  created_before?: string;
  created_after?: string;
  finished_before?: string;
  finished_after?: string;
  search?: string;
  governmentInspectionWorkOrderId?: number;
  organisationId?: number;
  portfolioId?: number;
  propertyAssigneeId?: number;
}

export interface WorkOrderListView extends ListViewPagination<WorkOrderListItemView> {}

export interface WorkOrderListItemView extends WorkOrderReadView {
  latestWorkOrderLogWithNote: EmbeddedWorkOrderLogReadView | null;
}

export interface WorkOrderReadView {
  id: number;
  caseNumber: string;
  creatorId: number | null;
  type: string;
  category: WorkOrderCategory;
  description: string;
  status: WorkOrderStatus;
  propertyId: number;
  tenantId: number | null;
  tenantEmail: string | null;
  tenantPhone: string | null;
  organisationId: number;
  governmentInspectionWorkOrderId: number | null;
  created: string;
  updated: string;
  finished: string | null;
  fileIds?: string[];
  spentTime?: number | null;
}

export interface WorkOrderLogReadView {
  id: number;
  workOrderId: number;
  status: WorkOrderStatus | null;
  note: string | null;
  tenantNote: string | null;
  organisationId: number;
  authorId: number;
  created: string;
  fileIds?: string[] | null;
  spentTime?: number | null;
}

export interface WorkOrderLogCreateView {
  workOrderId: number;
  status?: WorkOrderStatus;
  note?: string;
  fileIds?: string[];
  tenantNote?: string;
  spentTime?: number;
}

export interface EmbeddedWorkOrderLogReadView {
  id: number;
  status: string | null;
  note: string | null;
  tenantNote: string | null;
  authorId: number;
  created: string;
}

export enum WorkOrderCategory {
  Ground = 'GROUND',
  PortDoor = 'PORT_DOOR',
  IndoorClimate = 'INDOOR_CLIMATE',
  FireProtection = 'FIRE_PROTECTION',
  Building = 'BUILDING',
  SharedSpace = 'SHARED_SPACE',
  WaterDrain = 'WATER_DRAIN',
  InspectionNote = 'INSPECTION_NOTE'
}

export enum WorkOrderStatus {
  Received = 'RECEIVED',
  Started = 'STARTED',
  Finished = 'FINISHED'
}

export enum WorkOrderSortBy {
  CreatedAsc = 'created_asc',
  CreatedDesc = 'created_desc',
  FinishedAsc = 'finished_asc',
  FinishedDesc = 'finished_desc',
  Intuitive = 'intuitive'
}

export const translateWorkOrderCategory = (category: WorkOrderReadViewCategoryEnum): string => {
  const map = new Map([
    [WorkOrderReadViewCategoryEnum.Building, i18n.t('faultReports:workOrderCategory.building')],
    [WorkOrderReadViewCategoryEnum.FireProtection, i18n.t('faultReports:workOrderCategory.fireProtection')],
    [WorkOrderReadViewCategoryEnum.Ground, i18n.t('faultReports:workOrderCategory.ground')],
    [WorkOrderReadViewCategoryEnum.IndoorClimate, i18n.t('faultReports:workOrderCategory.indoorClimate')],
    [WorkOrderReadViewCategoryEnum.PortDoor, i18n.t('faultReports:workOrderCategory.door')],
    [WorkOrderReadViewCategoryEnum.SharedSpace, i18n.t('faultReports:workOrderCategory.sharedSpace')],
    [WorkOrderReadViewCategoryEnum.WaterDrain, i18n.t('faultReports:workOrderCategory.waterDrain')],
    [WorkOrderReadViewCategoryEnum.InspectionNote, i18n.t('faultReports:workOrderCategory.inspectionNote')]
  ]);

  return map.has(category) ? map.get(category)! : category.toString();
};

export const translateWorkOrderStatus = (status: WorkOrderReadViewStatusEnum): string => {
  const map = new Map([
    [WorkOrderReadViewStatusEnum.Finished, i18n.t('faultReports:workOrderStatus.finished')],
    [WorkOrderReadViewStatusEnum.Received, i18n.t('faultReports:workOrderStatus.received')],
    [WorkOrderReadViewStatusEnum.Started, i18n.t('faultReports:workOrderStatus.started')]
  ]);

  return map.has(status) ? map.get(status)! : status.toString();
};

export const translateWorkOrderStatusPlural = (status: WorkOrderReadViewStatusEnum): string => {
  const map = new Map([
    [WorkOrderReadViewStatusEnum.Finished, i18n.t('faultReports:workOrderStatusPlural.finished')],
    [WorkOrderReadViewStatusEnum.Received, i18n.t('faultReports:workOrderStatusPlural.received')],
    [WorkOrderReadViewStatusEnum.Started, i18n.t('faultReports:workOrderStatusPlural.started')]
  ]);

  return map.has(status) ? map.get(status)! : status.toString();
};

export const getStatusAction = (status: WorkOrderReadViewStatusEnum): string => {
  const map = new Map([
    [WorkOrderReadViewStatusEnum.Finished, i18n.t('faultReports:finished')],
    [WorkOrderReadViewStatusEnum.Started, i18n.t('faultReports:finish')],
    [WorkOrderReadViewStatusEnum.Received, i18n.t('faultReports:start')]
  ]);

  return map.has(status) ? map.get(status)! : status.toString();
};

export interface ListTenantNamesRequestView {
  propertyPostCode: string;
  propertyCity: string;
  propertyAddress: string;
  organisationId: number;
}

export interface MatchTenantNamesRequestView {
  tenantNameSearch: string;
  organisationId: number;
}

export interface TenantNamesResultView {
  names: string[];
}

export interface ListOngoingCitiesRequestView {
  organisationId: number;
}

export interface ListOngoingCityAddressesRequestView {
  city: string;
  organisationId: number;
}

export interface AddressesResultView {
  addresses: Address[];
}

export interface Address {
  streetAddress: string;
  postcode: string;
  city: string;
}

export const getWorkOrderCategoryIcon = (category: WorkOrderReadViewCategoryEnum): IconDefinition => {
  const map = new Map([
    [WorkOrderReadViewCategoryEnum.Building, faHouseBuilding],
    [WorkOrderReadViewCategoryEnum.FireProtection, faFire],
    [WorkOrderReadViewCategoryEnum.Ground, faLeaf],
    [WorkOrderReadViewCategoryEnum.IndoorClimate, faFan],
    [WorkOrderReadViewCategoryEnum.PortDoor, faDoorClosed],
    [WorkOrderReadViewCategoryEnum.SharedSpace, faUsers],
    [WorkOrderReadViewCategoryEnum.WaterDrain, faFaucet],
    [WorkOrderReadViewCategoryEnum.InspectionNote, faClipboardList]
  ]);

  return map.get(category)!;
};

export const convertStatusArrayToDropdownItems = (statuses: WorkOrderReadViewStatusEnum[]): DropdownItemProps[] => {
  return (
    statuses.map((type) => ({
      key: type,
      text: translateWorkOrderStatus(type),
      value: type
    })) ?? []
  );
};

export const getFaultReportCategoryDropdownItems = (): DropdownItemProps[] => {
  return (
    Object.values(WorkOrderReadViewCategoryEnum)
      .map((item) => ({
        key: item,
        text: translateWorkOrderCategory(item),
        value: item
      }))
      .sort((a, b) => localeCompareSv(a.text, b.text)) ?? []
  );
};
