/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { FinancialReportView } from '../model';
/**
 * FinancialReportApi - axios parameter creator
 * @export
 */
export const FinancialReportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Export financial report to excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialReportResourceExportExcelLeases: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('financialReportResourceExportExcelLeases', 'token', token);
      const localVarPath = `/v1/financial-report/xlsx/export/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get a token to export financial report
     * @param {'QUARTERLY' | 'YEARLY'} granularity
     * @param {Array<string>} periods Filter by specific periods
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by transactions for properties assigned to the user
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {string} [maxDate] The maximum date at which point the last period should end. Used for the yearly granularity since we don\&#39;t want to include data from the last quarter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialReportResourceExportExcelLeasesUrl: async (
      granularity: 'QUARTERLY' | 'YEARLY',
      periods: Array<string>,
      organisationId?: number,
      companyId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      maxDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'granularity' is not null or undefined
      assertParamExists('financialReportResourceExportExcelLeasesUrl', 'granularity', granularity);
      // verify required parameter 'periods' is not null or undefined
      assertParamExists('financialReportResourceExportExcelLeasesUrl', 'periods', periods);
      const localVarPath = `/v1/financial-report/xlsx/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (granularity !== undefined) {
        localVarQueryParameter['granularity'] = granularity;
      }

      if (periods) {
        localVarQueryParameter['periods'] = periods;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (maxDate !== undefined) {
        localVarQueryParameter['maxDate'] =
          (maxDate as any) instanceof Date ? (maxDate as any).toISOString().substr(0, 10) : maxDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read financial report information.
     * @param {'QUARTERLY' | 'YEARLY'} granularity
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by transactions for properties assigned to the user
     * @param {string} [startTime] Time before which to returning results, paginating into the past. Transactions from incomplete quarters are ignored in both granularities.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [includePrognosis] Include year long prognosis data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialReportResourceGetReport: async (
      granularity: 'QUARTERLY' | 'YEARLY',
      limit?: number,
      offset?: number,
      organisationId?: number,
      companyId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      startTime?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      includePrognosis?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'granularity' is not null or undefined
      assertParamExists('financialReportResourceGetReport', 'granularity', granularity);
      const localVarPath = `/v1/financial-report`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (startTime !== undefined) {
        localVarQueryParameter['startTime'] =
          (startTime as any) instanceof Date ? (startTime as any).toISOString().substr(0, 10) : startTime;
      }

      if (granularity !== undefined) {
        localVarQueryParameter['granularity'] = granularity;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (includePrognosis !== undefined) {
        localVarQueryParameter['includePrognosis'] = includePrognosis;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FinancialReportApi - functional programming interface
 * @export
 */
export const FinancialReportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FinancialReportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Export financial report to excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async financialReportResourceExportExcelLeases(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.financialReportResourceExportExcelLeases(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a token to export financial report
     * @param {'QUARTERLY' | 'YEARLY'} granularity
     * @param {Array<string>} periods Filter by specific periods
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by transactions for properties assigned to the user
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {string} [maxDate] The maximum date at which point the last period should end. Used for the yearly granularity since we don\&#39;t want to include data from the last quarter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async financialReportResourceExportExcelLeasesUrl(
      granularity: 'QUARTERLY' | 'YEARLY',
      periods: Array<string>,
      organisationId?: number,
      companyId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      maxDate?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.financialReportResourceExportExcelLeasesUrl(
        granularity,
        periods,
        organisationId,
        companyId,
        propertyId,
        portfolioId,
        assigneeId,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        maxDate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read financial report information.
     * @param {'QUARTERLY' | 'YEARLY'} granularity
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by transactions for properties assigned to the user
     * @param {string} [startTime] Time before which to returning results, paginating into the past. Transactions from incomplete quarters are ignored in both granularities.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [includePrognosis] Include year long prognosis data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async financialReportResourceGetReport(
      granularity: 'QUARTERLY' | 'YEARLY',
      limit?: number,
      offset?: number,
      organisationId?: number,
      companyId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      startTime?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      includePrognosis?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialReportView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.financialReportResourceGetReport(
        granularity,
        limit,
        offset,
        organisationId,
        companyId,
        propertyId,
        portfolioId,
        assigneeId,
        startTime,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        includePrognosis,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * FinancialReportApi - factory interface
 * @export
 */
export const FinancialReportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FinancialReportApiFp(configuration);
  return {
    /**
     *
     * @summary Export financial report to excel file
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialReportResourceExportExcelLeases(token: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .financialReportResourceExportExcelLeases(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a token to export financial report
     * @param {'QUARTERLY' | 'YEARLY'} granularity
     * @param {Array<string>} periods Filter by specific periods
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by transactions for properties assigned to the user
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {string} [maxDate] The maximum date at which point the last period should end. Used for the yearly granularity since we don\&#39;t want to include data from the last quarter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialReportResourceExportExcelLeasesUrl(
      granularity: 'QUARTERLY' | 'YEARLY',
      periods: Array<string>,
      organisationId?: number,
      companyId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      maxDate?: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .financialReportResourceExportExcelLeasesUrl(
          granularity,
          periods,
          organisationId,
          companyId,
          propertyId,
          portfolioId,
          assigneeId,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          maxDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read financial report information.
     * @param {'QUARTERLY' | 'YEARLY'} granularity
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [companyId] Filter by company
     * @param {number} [propertyId] Filter by property
     * @param {number} [portfolioId] Filter by portfolio
     * @param {number} [assigneeId] Filter by transactions for properties assigned to the user
     * @param {string} [startTime] Time before which to returning results, paginating into the past. Transactions from incomplete quarters are ignored in both granularities.
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {boolean} [includePrognosis] Include year long prognosis data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    financialReportResourceGetReport(
      granularity: 'QUARTERLY' | 'YEARLY',
      limit?: number,
      offset?: number,
      organisationId?: number,
      companyId?: number,
      propertyId?: number,
      portfolioId?: number,
      assigneeId?: number,
      startTime?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      includePrognosis?: boolean,
      options?: any
    ): AxiosPromise<FinancialReportView> {
      return localVarFp
        .financialReportResourceGetReport(
          granularity,
          limit,
          offset,
          organisationId,
          companyId,
          propertyId,
          portfolioId,
          assigneeId,
          startTime,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          includePrognosis,
          options
        )
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for financialReportResourceExportExcelLeases operation in FinancialReportApi.
 * @export
 * @interface FinancialReportApiFinancialReportResourceExportExcelLeasesRequest
 */
export interface FinancialReportApiFinancialReportResourceExportExcelLeasesRequest {
  /**
   *
   * @type {string}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeases
   */
  readonly token: string;
}

/**
 * Request parameters for financialReportResourceExportExcelLeasesUrl operation in FinancialReportApi.
 * @export
 * @interface FinancialReportApiFinancialReportResourceExportExcelLeasesUrlRequest
 */
export interface FinancialReportApiFinancialReportResourceExportExcelLeasesUrlRequest {
  /**
   *
   * @type {'QUARTERLY' | 'YEARLY'}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly granularity: 'QUARTERLY' | 'YEARLY';

  /**
   * Filter by specific periods
   * @type {Array<string>}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly periods: Array<string>;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly organisationId?: number;

  /**
   * Filter by company
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly companyId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly propertyId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly portfolioId?: number;

  /**
   * Filter by transactions for properties assigned to the user
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly assigneeId?: number;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly municipalities?: Array<string>;

  /**
   * The maximum date at which point the last period should end. Used for the yearly granularity since we don\&#39;t want to include data from the last quarter.
   * @type {string}
   * @memberof FinancialReportApiFinancialReportResourceExportExcelLeasesUrl
   */
  readonly maxDate?: string;
}

/**
 * Request parameters for financialReportResourceGetReport operation in FinancialReportApi.
 * @export
 * @interface FinancialReportApiFinancialReportResourceGetReportRequest
 */
export interface FinancialReportApiFinancialReportResourceGetReportRequest {
  /**
   *
   * @type {'QUARTERLY' | 'YEARLY'}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly granularity: 'QUARTERLY' | 'YEARLY';

  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly organisationId?: number;

  /**
   * Filter by company
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly companyId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly propertyId?: number;

  /**
   * Filter by portfolio
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly portfolioId?: number;

  /**
   * Filter by transactions for properties assigned to the user
   * @type {number}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly assigneeId?: number;

  /**
   * Time before which to returning results, paginating into the past. Transactions from incomplete quarters are ignored in both granularities.
   * @type {string}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly startTime?: string;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly municipalities?: Array<string>;

  /**
   * Include year long prognosis data.
   * @type {boolean}
   * @memberof FinancialReportApiFinancialReportResourceGetReport
   */
  readonly includePrognosis?: boolean;
}

/**
 * FinancialReportApi - object-oriented interface
 * @export
 * @class FinancialReportApi
 * @extends {BaseAPI}
 */
export class FinancialReportApi extends BaseAPI {
  /**
   *
   * @summary Export financial report to excel file
   * @param {FinancialReportApiFinancialReportResourceExportExcelLeasesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialReportApi
   */
  public financialReportResourceExportExcelLeases(
    requestParameters: FinancialReportApiFinancialReportResourceExportExcelLeasesRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialReportApiFp(this.configuration)
      .financialReportResourceExportExcelLeases(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a token to export financial report
   * @param {FinancialReportApiFinancialReportResourceExportExcelLeasesUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialReportApi
   */
  public financialReportResourceExportExcelLeasesUrl(
    requestParameters: FinancialReportApiFinancialReportResourceExportExcelLeasesUrlRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialReportApiFp(this.configuration)
      .financialReportResourceExportExcelLeasesUrl(
        requestParameters.granularity,
        requestParameters.periods,
        requestParameters.organisationId,
        requestParameters.companyId,
        requestParameters.propertyId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.maxDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read financial report information.
   * @param {FinancialReportApiFinancialReportResourceGetReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialReportApi
   */
  public financialReportResourceGetReport(
    requestParameters: FinancialReportApiFinancialReportResourceGetReportRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialReportApiFp(this.configuration)
      .financialReportResourceGetReport(
        requestParameters.granularity,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.companyId,
        requestParameters.propertyId,
        requestParameters.portfolioId,
        requestParameters.assigneeId,
        requestParameters.startTime,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.includePrognosis,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
