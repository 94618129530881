/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { MembershipListView } from '../model';
// @ts-ignore
import { MembershipReadView } from '../model';
// @ts-ignore
import { MembershipUpdateView } from '../model';
/**
 * MembershipApi - axios parameter creator
 * @export
 */
export const MembershipApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete a membership
     * @param {number} id Membership ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('membershipResourceDelete', 'id', id);
      const localVarPath = `/v1/memberships/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List memberships
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [userId] Filter by user
     * @param {number} [organisationId] Filter by organisation
     * @param {'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'INVESTOR'} [role] Filter by role
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipResourceList: async (
      limit?: number,
      offset?: number,
      userId?: number,
      organisationId?: number,
      role?: 'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'INVESTOR',
      organisationIds?: Array<number>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/memberships`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (role !== undefined) {
        localVarQueryParameter['role'] = role;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a membership
     * @param {number} id Membership ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('membershipResourceRead', 'id', id);
      const localVarPath = `/v1/memberships/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a membership
     * @param {number} id Membership ID
     * @param {MembershipUpdateView} [membershipUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipResourceUpdate: async (
      id: number,
      membershipUpdateView?: MembershipUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('membershipResourceUpdate', 'id', id);
      const localVarPath = `/v1/memberships/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(membershipUpdateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * MembershipApi - functional programming interface
 * @export
 */
export const MembershipApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MembershipApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete a membership
     * @param {number} id Membership ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.membershipResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List memberships
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [userId] Filter by user
     * @param {number} [organisationId] Filter by organisation
     * @param {'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'INVESTOR'} [role] Filter by role
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipResourceList(
      limit?: number,
      offset?: number,
      userId?: number,
      organisationId?: number,
      role?: 'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'INVESTOR',
      organisationIds?: Array<number>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.membershipResourceList(
        limit,
        offset,
        userId,
        organisationId,
        role,
        organisationIds,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a membership
     * @param {number} id Membership ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.membershipResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a membership
     * @param {number} id Membership ID
     * @param {MembershipUpdateView} [membershipUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipResourceUpdate(
      id: number,
      membershipUpdateView?: MembershipUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.membershipResourceUpdate(
        id,
        membershipUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * MembershipApi - factory interface
 * @export
 */
export const MembershipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MembershipApiFp(configuration);
  return {
    /**
     *
     * @summary Delete a membership
     * @param {number} id Membership ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.membershipResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List memberships
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [userId] Filter by user
     * @param {number} [organisationId] Filter by organisation
     * @param {'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'INVESTOR'} [role] Filter by role
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipResourceList(
      limit?: number,
      offset?: number,
      userId?: number,
      organisationId?: number,
      role?: 'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'INVESTOR',
      organisationIds?: Array<number>,
      options?: any
    ): AxiosPromise<MembershipListView> {
      return localVarFp
        .membershipResourceList(limit, offset, userId, organisationId, role, organisationIds, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a membership
     * @param {number} id Membership ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipResourceRead(id: number, options?: any): AxiosPromise<MembershipReadView> {
      return localVarFp.membershipResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a membership
     * @param {number} id Membership ID
     * @param {MembershipUpdateView} [membershipUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipResourceUpdate(
      id: number,
      membershipUpdateView?: MembershipUpdateView,
      options?: any
    ): AxiosPromise<MembershipReadView> {
      return localVarFp
        .membershipResourceUpdate(id, membershipUpdateView, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for membershipResourceDelete operation in MembershipApi.
 * @export
 * @interface MembershipApiMembershipResourceDeleteRequest
 */
export interface MembershipApiMembershipResourceDeleteRequest {
  /**
   * Membership ID
   * @type {number}
   * @memberof MembershipApiMembershipResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for membershipResourceList operation in MembershipApi.
 * @export
 * @interface MembershipApiMembershipResourceListRequest
 */
export interface MembershipApiMembershipResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof MembershipApiMembershipResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof MembershipApiMembershipResourceList
   */
  readonly offset?: number;

  /**
   * Filter by user
   * @type {number}
   * @memberof MembershipApiMembershipResourceList
   */
  readonly userId?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof MembershipApiMembershipResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by role
   * @type {'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'INVESTOR'}
   * @memberof MembershipApiMembershipResourceList
   */
  readonly role?: 'ADMIN' | 'MANAGER' | 'TECHNICIAN' | 'INVESTOR';

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof MembershipApiMembershipResourceList
   */
  readonly organisationIds?: Array<number>;
}

/**
 * Request parameters for membershipResourceRead operation in MembershipApi.
 * @export
 * @interface MembershipApiMembershipResourceReadRequest
 */
export interface MembershipApiMembershipResourceReadRequest {
  /**
   * Membership ID
   * @type {number}
   * @memberof MembershipApiMembershipResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for membershipResourceUpdate operation in MembershipApi.
 * @export
 * @interface MembershipApiMembershipResourceUpdateRequest
 */
export interface MembershipApiMembershipResourceUpdateRequest {
  /**
   * Membership ID
   * @type {number}
   * @memberof MembershipApiMembershipResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {MembershipUpdateView}
   * @memberof MembershipApiMembershipResourceUpdate
   */
  readonly membershipUpdateView?: MembershipUpdateView;
}

/**
 * MembershipApi - object-oriented interface
 * @export
 * @class MembershipApi
 * @extends {BaseAPI}
 */
export class MembershipApi extends BaseAPI {
  /**
   *
   * @summary Delete a membership
   * @param {MembershipApiMembershipResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipResourceDelete(
    requestParameters: MembershipApiMembershipResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return MembershipApiFp(this.configuration)
      .membershipResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List memberships
   * @param {MembershipApiMembershipResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipResourceList(
    requestParameters: MembershipApiMembershipResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return MembershipApiFp(this.configuration)
      .membershipResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.userId,
        requestParameters.organisationId,
        requestParameters.role,
        requestParameters.organisationIds,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a membership
   * @param {MembershipApiMembershipResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipResourceRead(
    requestParameters: MembershipApiMembershipResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return MembershipApiFp(this.configuration)
      .membershipResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a membership
   * @param {MembershipApiMembershipResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipResourceUpdate(
    requestParameters: MembershipApiMembershipResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return MembershipApiFp(this.configuration)
      .membershipResourceUpdate(requestParameters.id, requestParameters.membershipUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
