/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EnergyReportListView } from '../model';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { MeterListView } from '../model';
// @ts-ignore
import { MeterReadView } from '../model';
// @ts-ignore
import { MeterUpdateView } from '../model';
/**
 * UtilityApi - axios parameter creator
 * @export
 */
export const UtilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Export meter data.
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    energyReportResourceExportExcel: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('energyReportResourceExportExcel', 'token', token);
      const localVarPath = `/v1/energy-report/xlsx/export/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get a token to export meter data.
     * @param {'MONTHLY' | 'QUARTERLY' | 'YEARLY'} granularity Granularity defines the length of each period in the time series.
     * @param {number} [limit] Maximum amount of periods
     * @param {string} [time] Start time of time series.
     * @param {Array<string>} [periods] List of specific periods. Overrides time and limit if given.
     * @param {number} [organisationId]
     * @param {number} [portfolioId]
     * @param {number} [propertyId]
     * @param {number} [assigneeId]
     * @param {'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR'} [utilityType]
     * @param {'ENERGY_KWH' | 'FLOW_M3' | 'ENERGY_HIGHTARIFF_KWH' | 'ENERGY_LOWTARIFF_KWH' | 'TEMPERATURE_C' | 'RETURN_TEMPERATURE_C' | 'SUPPLY_TEMPERATURE_C' | 'TVOC_PPM' | 'CO2_PPM' | 'RETAIL_SUBSCRIPTION_SEK' | 'RETAIL_USAGE_SEK' | 'RETAIL_UNDEFINED_SEK' | 'RETAIL_TOTAL_SEK' | 'GRID_SUBSCRIPTION_SEK' | 'GRID_SUBSCRIPTION_POWER_SEK' | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK' | 'GRID_SUBSCRIPTION_FLOW_SEK' | 'GRID_USAGE_SEK' | 'GRID_UNDEFINED_SEK' | 'GRID_TOTAL_SEK'} [utilityMetricType]
     * @param {Array<number>} [organisationIds]
     * @param {Array<number>} [portfolioIds]
     * @param {Array<number>} [propertySubscriptionUserIds]
     * @param {Array<string>} [municipalities]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    energyReportResourceExportExcelUrl: async (
      granularity: 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
      limit?: number,
      time?: string,
      periods?: Array<string>,
      organisationId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      utilityType?: 'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR',
      utilityMetricType?:
        | 'ENERGY_KWH'
        | 'FLOW_M3'
        | 'ENERGY_HIGHTARIFF_KWH'
        | 'ENERGY_LOWTARIFF_KWH'
        | 'TEMPERATURE_C'
        | 'RETURN_TEMPERATURE_C'
        | 'SUPPLY_TEMPERATURE_C'
        | 'TVOC_PPM'
        | 'CO2_PPM'
        | 'RETAIL_SUBSCRIPTION_SEK'
        | 'RETAIL_USAGE_SEK'
        | 'RETAIL_UNDEFINED_SEK'
        | 'RETAIL_TOTAL_SEK'
        | 'GRID_SUBSCRIPTION_SEK'
        | 'GRID_SUBSCRIPTION_POWER_SEK'
        | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK'
        | 'GRID_SUBSCRIPTION_FLOW_SEK'
        | 'GRID_USAGE_SEK'
        | 'GRID_UNDEFINED_SEK'
        | 'GRID_TOTAL_SEK',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'granularity' is not null or undefined
      assertParamExists('energyReportResourceExportExcelUrl', 'granularity', granularity);
      const localVarPath = `/v1/energy-report/xlsx/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (granularity !== undefined) {
        localVarQueryParameter['granularity'] = granularity;
      }

      if (time !== undefined) {
        localVarQueryParameter['time'] =
          (time as any) instanceof Date ? (time as any).toISOString().substr(0, 10) : time;
      }

      if (periods) {
        localVarQueryParameter['periods'] = periods;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (utilityType !== undefined) {
        localVarQueryParameter['utilityType'] = utilityType;
      }

      if (utilityMetricType !== undefined) {
        localVarQueryParameter['utilityMetricType'] = utilityMetricType;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read data collected from meters.
     * @param {'MONTHLY' | 'QUARTERLY' | 'YEARLY'} granularity Granularity defines the length of each period in the time series.
     * @param {number} [limit] Maximum amount of periods
     * @param {string} [time] Start time of time series.
     * @param {Array<string>} [periods] List of specific periods. Overrides time and limit if given.
     * @param {number} [organisationId]
     * @param {number} [portfolioId]
     * @param {number} [propertyId]
     * @param {number} [assigneeId]
     * @param {'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR'} [utilityType]
     * @param {'ENERGY_KWH' | 'FLOW_M3' | 'ENERGY_HIGHTARIFF_KWH' | 'ENERGY_LOWTARIFF_KWH' | 'TEMPERATURE_C' | 'RETURN_TEMPERATURE_C' | 'SUPPLY_TEMPERATURE_C' | 'TVOC_PPM' | 'CO2_PPM' | 'RETAIL_SUBSCRIPTION_SEK' | 'RETAIL_USAGE_SEK' | 'RETAIL_UNDEFINED_SEK' | 'RETAIL_TOTAL_SEK' | 'GRID_SUBSCRIPTION_SEK' | 'GRID_SUBSCRIPTION_POWER_SEK' | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK' | 'GRID_SUBSCRIPTION_FLOW_SEK' | 'GRID_USAGE_SEK' | 'GRID_UNDEFINED_SEK' | 'GRID_TOTAL_SEK'} [utilityMetricType]
     * @param {Array<number>} [organisationIds]
     * @param {Array<number>} [portfolioIds]
     * @param {Array<number>} [propertySubscriptionUserIds]
     * @param {Array<string>} [municipalities]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    energyReportResourceList: async (
      granularity: 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
      limit?: number,
      time?: string,
      periods?: Array<string>,
      organisationId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      utilityType?: 'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR',
      utilityMetricType?:
        | 'ENERGY_KWH'
        | 'FLOW_M3'
        | 'ENERGY_HIGHTARIFF_KWH'
        | 'ENERGY_LOWTARIFF_KWH'
        | 'TEMPERATURE_C'
        | 'RETURN_TEMPERATURE_C'
        | 'SUPPLY_TEMPERATURE_C'
        | 'TVOC_PPM'
        | 'CO2_PPM'
        | 'RETAIL_SUBSCRIPTION_SEK'
        | 'RETAIL_USAGE_SEK'
        | 'RETAIL_UNDEFINED_SEK'
        | 'RETAIL_TOTAL_SEK'
        | 'GRID_SUBSCRIPTION_SEK'
        | 'GRID_SUBSCRIPTION_POWER_SEK'
        | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK'
        | 'GRID_SUBSCRIPTION_FLOW_SEK'
        | 'GRID_USAGE_SEK'
        | 'GRID_UNDEFINED_SEK'
        | 'GRID_TOTAL_SEK',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'granularity' is not null or undefined
      assertParamExists('energyReportResourceList', 'granularity', granularity);
      const localVarPath = `/v1/energy-report`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (granularity !== undefined) {
        localVarQueryParameter['granularity'] = granularity;
      }

      if (time !== undefined) {
        localVarQueryParameter['time'] =
          (time as any) instanceof Date ? (time as any).toISOString().substr(0, 10) : time;
      }

      if (periods) {
        localVarQueryParameter['periods'] = periods;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (assigneeId !== undefined) {
        localVarQueryParameter['assigneeId'] = assigneeId;
      }

      if (utilityType !== undefined) {
        localVarQueryParameter['utilityType'] = utilityType;
      }

      if (utilityMetricType !== undefined) {
        localVarQueryParameter['utilityMetricType'] = utilityMetricType;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read meters that provide utility usage information on units
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {boolean} [linked] Filter meters that are already linked to units
     * @param {number} [organisationId] Filter meters by organisation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meterResourceList: async (
      limit?: number,
      offset?: number,
      linked?: boolean,
      organisationId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/meters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (linked !== undefined) {
        localVarQueryParameter['linked'] = linked;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a meter
     * @param {number} id Meter ID
     * @param {MeterUpdateView} [meterUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meterResourceUpdate: async (
      id: number,
      meterUpdateView?: MeterUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('meterResourceUpdate', 'id', id);
      const localVarPath = `/v1/meters/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(meterUpdateView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * UtilityApi - functional programming interface
 * @export
 */
export const UtilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UtilityApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Export meter data.
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async energyReportResourceExportExcel(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.energyReportResourceExportExcel(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a token to export meter data.
     * @param {'MONTHLY' | 'QUARTERLY' | 'YEARLY'} granularity Granularity defines the length of each period in the time series.
     * @param {number} [limit] Maximum amount of periods
     * @param {string} [time] Start time of time series.
     * @param {Array<string>} [periods] List of specific periods. Overrides time and limit if given.
     * @param {number} [organisationId]
     * @param {number} [portfolioId]
     * @param {number} [propertyId]
     * @param {number} [assigneeId]
     * @param {'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR'} [utilityType]
     * @param {'ENERGY_KWH' | 'FLOW_M3' | 'ENERGY_HIGHTARIFF_KWH' | 'ENERGY_LOWTARIFF_KWH' | 'TEMPERATURE_C' | 'RETURN_TEMPERATURE_C' | 'SUPPLY_TEMPERATURE_C' | 'TVOC_PPM' | 'CO2_PPM' | 'RETAIL_SUBSCRIPTION_SEK' | 'RETAIL_USAGE_SEK' | 'RETAIL_UNDEFINED_SEK' | 'RETAIL_TOTAL_SEK' | 'GRID_SUBSCRIPTION_SEK' | 'GRID_SUBSCRIPTION_POWER_SEK' | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK' | 'GRID_SUBSCRIPTION_FLOW_SEK' | 'GRID_USAGE_SEK' | 'GRID_UNDEFINED_SEK' | 'GRID_TOTAL_SEK'} [utilityMetricType]
     * @param {Array<number>} [organisationIds]
     * @param {Array<number>} [portfolioIds]
     * @param {Array<number>} [propertySubscriptionUserIds]
     * @param {Array<string>} [municipalities]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async energyReportResourceExportExcelUrl(
      granularity: 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
      limit?: number,
      time?: string,
      periods?: Array<string>,
      organisationId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      utilityType?: 'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR',
      utilityMetricType?:
        | 'ENERGY_KWH'
        | 'FLOW_M3'
        | 'ENERGY_HIGHTARIFF_KWH'
        | 'ENERGY_LOWTARIFF_KWH'
        | 'TEMPERATURE_C'
        | 'RETURN_TEMPERATURE_C'
        | 'SUPPLY_TEMPERATURE_C'
        | 'TVOC_PPM'
        | 'CO2_PPM'
        | 'RETAIL_SUBSCRIPTION_SEK'
        | 'RETAIL_USAGE_SEK'
        | 'RETAIL_UNDEFINED_SEK'
        | 'RETAIL_TOTAL_SEK'
        | 'GRID_SUBSCRIPTION_SEK'
        | 'GRID_SUBSCRIPTION_POWER_SEK'
        | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK'
        | 'GRID_SUBSCRIPTION_FLOW_SEK'
        | 'GRID_USAGE_SEK'
        | 'GRID_UNDEFINED_SEK'
        | 'GRID_TOTAL_SEK',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.energyReportResourceExportExcelUrl(
        granularity,
        limit,
        time,
        periods,
        organisationId,
        portfolioId,
        propertyId,
        assigneeId,
        utilityType,
        utilityMetricType,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read data collected from meters.
     * @param {'MONTHLY' | 'QUARTERLY' | 'YEARLY'} granularity Granularity defines the length of each period in the time series.
     * @param {number} [limit] Maximum amount of periods
     * @param {string} [time] Start time of time series.
     * @param {Array<string>} [periods] List of specific periods. Overrides time and limit if given.
     * @param {number} [organisationId]
     * @param {number} [portfolioId]
     * @param {number} [propertyId]
     * @param {number} [assigneeId]
     * @param {'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR'} [utilityType]
     * @param {'ENERGY_KWH' | 'FLOW_M3' | 'ENERGY_HIGHTARIFF_KWH' | 'ENERGY_LOWTARIFF_KWH' | 'TEMPERATURE_C' | 'RETURN_TEMPERATURE_C' | 'SUPPLY_TEMPERATURE_C' | 'TVOC_PPM' | 'CO2_PPM' | 'RETAIL_SUBSCRIPTION_SEK' | 'RETAIL_USAGE_SEK' | 'RETAIL_UNDEFINED_SEK' | 'RETAIL_TOTAL_SEK' | 'GRID_SUBSCRIPTION_SEK' | 'GRID_SUBSCRIPTION_POWER_SEK' | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK' | 'GRID_SUBSCRIPTION_FLOW_SEK' | 'GRID_USAGE_SEK' | 'GRID_UNDEFINED_SEK' | 'GRID_TOTAL_SEK'} [utilityMetricType]
     * @param {Array<number>} [organisationIds]
     * @param {Array<number>} [portfolioIds]
     * @param {Array<number>} [propertySubscriptionUserIds]
     * @param {Array<string>} [municipalities]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async energyReportResourceList(
      granularity: 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
      limit?: number,
      time?: string,
      periods?: Array<string>,
      organisationId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      utilityType?: 'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR',
      utilityMetricType?:
        | 'ENERGY_KWH'
        | 'FLOW_M3'
        | 'ENERGY_HIGHTARIFF_KWH'
        | 'ENERGY_LOWTARIFF_KWH'
        | 'TEMPERATURE_C'
        | 'RETURN_TEMPERATURE_C'
        | 'SUPPLY_TEMPERATURE_C'
        | 'TVOC_PPM'
        | 'CO2_PPM'
        | 'RETAIL_SUBSCRIPTION_SEK'
        | 'RETAIL_USAGE_SEK'
        | 'RETAIL_UNDEFINED_SEK'
        | 'RETAIL_TOTAL_SEK'
        | 'GRID_SUBSCRIPTION_SEK'
        | 'GRID_SUBSCRIPTION_POWER_SEK'
        | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK'
        | 'GRID_SUBSCRIPTION_FLOW_SEK'
        | 'GRID_USAGE_SEK'
        | 'GRID_UNDEFINED_SEK'
        | 'GRID_TOTAL_SEK',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnergyReportListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.energyReportResourceList(
        granularity,
        limit,
        time,
        periods,
        organisationId,
        portfolioId,
        propertyId,
        assigneeId,
        utilityType,
        utilityMetricType,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read meters that provide utility usage information on units
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {boolean} [linked] Filter meters that are already linked to units
     * @param {number} [organisationId] Filter meters by organisation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meterResourceList(
      limit?: number,
      offset?: number,
      linked?: boolean,
      organisationId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meterResourceList(
        limit,
        offset,
        linked,
        organisationId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a meter
     * @param {number} id Meter ID
     * @param {MeterUpdateView} [meterUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meterResourceUpdate(
      id: number,
      meterUpdateView?: MeterUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meterResourceUpdate(id, meterUpdateView, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * UtilityApi - factory interface
 * @export
 */
export const UtilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UtilityApiFp(configuration);
  return {
    /**
     *
     * @summary Export meter data.
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    energyReportResourceExportExcel(token: string, options?: any): AxiosPromise<void> {
      return localVarFp.energyReportResourceExportExcel(token, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a token to export meter data.
     * @param {'MONTHLY' | 'QUARTERLY' | 'YEARLY'} granularity Granularity defines the length of each period in the time series.
     * @param {number} [limit] Maximum amount of periods
     * @param {string} [time] Start time of time series.
     * @param {Array<string>} [periods] List of specific periods. Overrides time and limit if given.
     * @param {number} [organisationId]
     * @param {number} [portfolioId]
     * @param {number} [propertyId]
     * @param {number} [assigneeId]
     * @param {'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR'} [utilityType]
     * @param {'ENERGY_KWH' | 'FLOW_M3' | 'ENERGY_HIGHTARIFF_KWH' | 'ENERGY_LOWTARIFF_KWH' | 'TEMPERATURE_C' | 'RETURN_TEMPERATURE_C' | 'SUPPLY_TEMPERATURE_C' | 'TVOC_PPM' | 'CO2_PPM' | 'RETAIL_SUBSCRIPTION_SEK' | 'RETAIL_USAGE_SEK' | 'RETAIL_UNDEFINED_SEK' | 'RETAIL_TOTAL_SEK' | 'GRID_SUBSCRIPTION_SEK' | 'GRID_SUBSCRIPTION_POWER_SEK' | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK' | 'GRID_SUBSCRIPTION_FLOW_SEK' | 'GRID_USAGE_SEK' | 'GRID_UNDEFINED_SEK' | 'GRID_TOTAL_SEK'} [utilityMetricType]
     * @param {Array<number>} [organisationIds]
     * @param {Array<number>} [portfolioIds]
     * @param {Array<number>} [propertySubscriptionUserIds]
     * @param {Array<string>} [municipalities]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    energyReportResourceExportExcelUrl(
      granularity: 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
      limit?: number,
      time?: string,
      periods?: Array<string>,
      organisationId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      utilityType?: 'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR',
      utilityMetricType?:
        | 'ENERGY_KWH'
        | 'FLOW_M3'
        | 'ENERGY_HIGHTARIFF_KWH'
        | 'ENERGY_LOWTARIFF_KWH'
        | 'TEMPERATURE_C'
        | 'RETURN_TEMPERATURE_C'
        | 'SUPPLY_TEMPERATURE_C'
        | 'TVOC_PPM'
        | 'CO2_PPM'
        | 'RETAIL_SUBSCRIPTION_SEK'
        | 'RETAIL_USAGE_SEK'
        | 'RETAIL_UNDEFINED_SEK'
        | 'RETAIL_TOTAL_SEK'
        | 'GRID_SUBSCRIPTION_SEK'
        | 'GRID_SUBSCRIPTION_POWER_SEK'
        | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK'
        | 'GRID_SUBSCRIPTION_FLOW_SEK'
        | 'GRID_USAGE_SEK'
        | 'GRID_UNDEFINED_SEK'
        | 'GRID_TOTAL_SEK',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .energyReportResourceExportExcelUrl(
          granularity,
          limit,
          time,
          periods,
          organisationId,
          portfolioId,
          propertyId,
          assigneeId,
          utilityType,
          utilityMetricType,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read data collected from meters.
     * @param {'MONTHLY' | 'QUARTERLY' | 'YEARLY'} granularity Granularity defines the length of each period in the time series.
     * @param {number} [limit] Maximum amount of periods
     * @param {string} [time] Start time of time series.
     * @param {Array<string>} [periods] List of specific periods. Overrides time and limit if given.
     * @param {number} [organisationId]
     * @param {number} [portfolioId]
     * @param {number} [propertyId]
     * @param {number} [assigneeId]
     * @param {'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR'} [utilityType]
     * @param {'ENERGY_KWH' | 'FLOW_M3' | 'ENERGY_HIGHTARIFF_KWH' | 'ENERGY_LOWTARIFF_KWH' | 'TEMPERATURE_C' | 'RETURN_TEMPERATURE_C' | 'SUPPLY_TEMPERATURE_C' | 'TVOC_PPM' | 'CO2_PPM' | 'RETAIL_SUBSCRIPTION_SEK' | 'RETAIL_USAGE_SEK' | 'RETAIL_UNDEFINED_SEK' | 'RETAIL_TOTAL_SEK' | 'GRID_SUBSCRIPTION_SEK' | 'GRID_SUBSCRIPTION_POWER_SEK' | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK' | 'GRID_SUBSCRIPTION_FLOW_SEK' | 'GRID_USAGE_SEK' | 'GRID_UNDEFINED_SEK' | 'GRID_TOTAL_SEK'} [utilityMetricType]
     * @param {Array<number>} [organisationIds]
     * @param {Array<number>} [portfolioIds]
     * @param {Array<number>} [propertySubscriptionUserIds]
     * @param {Array<string>} [municipalities]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    energyReportResourceList(
      granularity: 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
      limit?: number,
      time?: string,
      periods?: Array<string>,
      organisationId?: number,
      portfolioId?: number,
      propertyId?: number,
      assigneeId?: number,
      utilityType?: 'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR',
      utilityMetricType?:
        | 'ENERGY_KWH'
        | 'FLOW_M3'
        | 'ENERGY_HIGHTARIFF_KWH'
        | 'ENERGY_LOWTARIFF_KWH'
        | 'TEMPERATURE_C'
        | 'RETURN_TEMPERATURE_C'
        | 'SUPPLY_TEMPERATURE_C'
        | 'TVOC_PPM'
        | 'CO2_PPM'
        | 'RETAIL_SUBSCRIPTION_SEK'
        | 'RETAIL_USAGE_SEK'
        | 'RETAIL_UNDEFINED_SEK'
        | 'RETAIL_TOTAL_SEK'
        | 'GRID_SUBSCRIPTION_SEK'
        | 'GRID_SUBSCRIPTION_POWER_SEK'
        | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK'
        | 'GRID_SUBSCRIPTION_FLOW_SEK'
        | 'GRID_USAGE_SEK'
        | 'GRID_UNDEFINED_SEK'
        | 'GRID_TOTAL_SEK',
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      options?: any
    ): AxiosPromise<EnergyReportListView> {
      return localVarFp
        .energyReportResourceList(
          granularity,
          limit,
          time,
          periods,
          organisationId,
          portfolioId,
          propertyId,
          assigneeId,
          utilityType,
          utilityMetricType,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read meters that provide utility usage information on units
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {boolean} [linked] Filter meters that are already linked to units
     * @param {number} [organisationId] Filter meters by organisation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meterResourceList(
      limit?: number,
      offset?: number,
      linked?: boolean,
      organisationId?: number,
      options?: any
    ): AxiosPromise<MeterListView> {
      return localVarFp
        .meterResourceList(limit, offset, linked, organisationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a meter
     * @param {number} id Meter ID
     * @param {MeterUpdateView} [meterUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meterResourceUpdate(id: number, meterUpdateView?: MeterUpdateView, options?: any): AxiosPromise<MeterReadView> {
      return localVarFp.meterResourceUpdate(id, meterUpdateView, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for energyReportResourceExportExcel operation in UtilityApi.
 * @export
 * @interface UtilityApiEnergyReportResourceExportExcelRequest
 */
export interface UtilityApiEnergyReportResourceExportExcelRequest {
  /**
   *
   * @type {string}
   * @memberof UtilityApiEnergyReportResourceExportExcel
   */
  readonly token: string;
}

/**
 * Request parameters for energyReportResourceExportExcelUrl operation in UtilityApi.
 * @export
 * @interface UtilityApiEnergyReportResourceExportExcelUrlRequest
 */
export interface UtilityApiEnergyReportResourceExportExcelUrlRequest {
  /**
   * Granularity defines the length of each period in the time series.
   * @type {'MONTHLY' | 'QUARTERLY' | 'YEARLY'}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly granularity: 'MONTHLY' | 'QUARTERLY' | 'YEARLY';

  /**
   * Maximum amount of periods
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly limit?: number;

  /**
   * Start time of time series.
   * @type {string}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly time?: string;

  /**
   * List of specific periods. Overrides time and limit if given.
   * @type {Array<string>}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly periods?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly organisationId?: number;

  /**
   *
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly portfolioId?: number;

  /**
   *
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly propertyId?: number;

  /**
   *
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly assigneeId?: number;

  /**
   *
   * @type {'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR'}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly utilityType?: 'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR';

  /**
   *
   * @type {'ENERGY_KWH' | 'FLOW_M3' | 'ENERGY_HIGHTARIFF_KWH' | 'ENERGY_LOWTARIFF_KWH' | 'TEMPERATURE_C' | 'RETURN_TEMPERATURE_C' | 'SUPPLY_TEMPERATURE_C' | 'TVOC_PPM' | 'CO2_PPM' | 'RETAIL_SUBSCRIPTION_SEK' | 'RETAIL_USAGE_SEK' | 'RETAIL_UNDEFINED_SEK' | 'RETAIL_TOTAL_SEK' | 'GRID_SUBSCRIPTION_SEK' | 'GRID_SUBSCRIPTION_POWER_SEK' | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK' | 'GRID_SUBSCRIPTION_FLOW_SEK' | 'GRID_USAGE_SEK' | 'GRID_UNDEFINED_SEK' | 'GRID_TOTAL_SEK'}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly utilityMetricType?:
    | 'ENERGY_KWH'
    | 'FLOW_M3'
    | 'ENERGY_HIGHTARIFF_KWH'
    | 'ENERGY_LOWTARIFF_KWH'
    | 'TEMPERATURE_C'
    | 'RETURN_TEMPERATURE_C'
    | 'SUPPLY_TEMPERATURE_C'
    | 'TVOC_PPM'
    | 'CO2_PPM'
    | 'RETAIL_SUBSCRIPTION_SEK'
    | 'RETAIL_USAGE_SEK'
    | 'RETAIL_UNDEFINED_SEK'
    | 'RETAIL_TOTAL_SEK'
    | 'GRID_SUBSCRIPTION_SEK'
    | 'GRID_SUBSCRIPTION_POWER_SEK'
    | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK'
    | 'GRID_SUBSCRIPTION_FLOW_SEK'
    | 'GRID_USAGE_SEK'
    | 'GRID_UNDEFINED_SEK'
    | 'GRID_TOTAL_SEK';

  /**
   *
   * @type {Array<number>}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly organisationIds?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly portfolioIds?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof UtilityApiEnergyReportResourceExportExcelUrl
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for energyReportResourceList operation in UtilityApi.
 * @export
 * @interface UtilityApiEnergyReportResourceListRequest
 */
export interface UtilityApiEnergyReportResourceListRequest {
  /**
   * Granularity defines the length of each period in the time series.
   * @type {'MONTHLY' | 'QUARTERLY' | 'YEARLY'}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly granularity: 'MONTHLY' | 'QUARTERLY' | 'YEARLY';

  /**
   * Maximum amount of periods
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly limit?: number;

  /**
   * Start time of time series.
   * @type {string}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly time?: string;

  /**
   * List of specific periods. Overrides time and limit if given.
   * @type {Array<string>}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly periods?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly organisationId?: number;

  /**
   *
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly portfolioId?: number;

  /**
   *
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly propertyId?: number;

  /**
   *
   * @type {number}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly assigneeId?: number;

  /**
   *
   * @type {'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR'}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly utilityType?: 'ELECTRICITY' | 'HEAT' | 'COOLING' | 'GAS' | 'HOT_WATER' | 'WATER' | 'SENSOR';

  /**
   *
   * @type {'ENERGY_KWH' | 'FLOW_M3' | 'ENERGY_HIGHTARIFF_KWH' | 'ENERGY_LOWTARIFF_KWH' | 'TEMPERATURE_C' | 'RETURN_TEMPERATURE_C' | 'SUPPLY_TEMPERATURE_C' | 'TVOC_PPM' | 'CO2_PPM' | 'RETAIL_SUBSCRIPTION_SEK' | 'RETAIL_USAGE_SEK' | 'RETAIL_UNDEFINED_SEK' | 'RETAIL_TOTAL_SEK' | 'GRID_SUBSCRIPTION_SEK' | 'GRID_SUBSCRIPTION_POWER_SEK' | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK' | 'GRID_SUBSCRIPTION_FLOW_SEK' | 'GRID_USAGE_SEK' | 'GRID_UNDEFINED_SEK' | 'GRID_TOTAL_SEK'}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly utilityMetricType?:
    | 'ENERGY_KWH'
    | 'FLOW_M3'
    | 'ENERGY_HIGHTARIFF_KWH'
    | 'ENERGY_LOWTARIFF_KWH'
    | 'TEMPERATURE_C'
    | 'RETURN_TEMPERATURE_C'
    | 'SUPPLY_TEMPERATURE_C'
    | 'TVOC_PPM'
    | 'CO2_PPM'
    | 'RETAIL_SUBSCRIPTION_SEK'
    | 'RETAIL_USAGE_SEK'
    | 'RETAIL_UNDEFINED_SEK'
    | 'RETAIL_TOTAL_SEK'
    | 'GRID_SUBSCRIPTION_SEK'
    | 'GRID_SUBSCRIPTION_POWER_SEK'
    | 'GRID_SUBSCRIPTION_RETURN_TEMPERATURE_SEK'
    | 'GRID_SUBSCRIPTION_FLOW_SEK'
    | 'GRID_USAGE_SEK'
    | 'GRID_UNDEFINED_SEK'
    | 'GRID_TOTAL_SEK';

  /**
   *
   * @type {Array<number>}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof UtilityApiEnergyReportResourceList
   */
  readonly municipalities?: Array<string>;
}

/**
 * Request parameters for meterResourceList operation in UtilityApi.
 * @export
 * @interface UtilityApiMeterResourceListRequest
 */
export interface UtilityApiMeterResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof UtilityApiMeterResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof UtilityApiMeterResourceList
   */
  readonly offset?: number;

  /**
   * Filter meters that are already linked to units
   * @type {boolean}
   * @memberof UtilityApiMeterResourceList
   */
  readonly linked?: boolean;

  /**
   * Filter meters by organisation id
   * @type {number}
   * @memberof UtilityApiMeterResourceList
   */
  readonly organisationId?: number;
}

/**
 * Request parameters for meterResourceUpdate operation in UtilityApi.
 * @export
 * @interface UtilityApiMeterResourceUpdateRequest
 */
export interface UtilityApiMeterResourceUpdateRequest {
  /**
   * Meter ID
   * @type {number}
   * @memberof UtilityApiMeterResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {MeterUpdateView}
   * @memberof UtilityApiMeterResourceUpdate
   */
  readonly meterUpdateView?: MeterUpdateView;
}

/**
 * UtilityApi - object-oriented interface
 * @export
 * @class UtilityApi
 * @extends {BaseAPI}
 */
export class UtilityApi extends BaseAPI {
  /**
   *
   * @summary Export meter data.
   * @param {UtilityApiEnergyReportResourceExportExcelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityApi
   */
  public energyReportResourceExportExcel(
    requestParameters: UtilityApiEnergyReportResourceExportExcelRequest,
    options?: AxiosRequestConfig
  ) {
    return UtilityApiFp(this.configuration)
      .energyReportResourceExportExcel(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a token to export meter data.
   * @param {UtilityApiEnergyReportResourceExportExcelUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityApi
   */
  public energyReportResourceExportExcelUrl(
    requestParameters: UtilityApiEnergyReportResourceExportExcelUrlRequest,
    options?: AxiosRequestConfig
  ) {
    return UtilityApiFp(this.configuration)
      .energyReportResourceExportExcelUrl(
        requestParameters.granularity,
        requestParameters.limit,
        requestParameters.time,
        requestParameters.periods,
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.propertyId,
        requestParameters.assigneeId,
        requestParameters.utilityType,
        requestParameters.utilityMetricType,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read data collected from meters.
   * @param {UtilityApiEnergyReportResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityApi
   */
  public energyReportResourceList(
    requestParameters: UtilityApiEnergyReportResourceListRequest,
    options?: AxiosRequestConfig
  ) {
    return UtilityApiFp(this.configuration)
      .energyReportResourceList(
        requestParameters.granularity,
        requestParameters.limit,
        requestParameters.time,
        requestParameters.periods,
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.propertyId,
        requestParameters.assigneeId,
        requestParameters.utilityType,
        requestParameters.utilityMetricType,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read meters that provide utility usage information on units
   * @param {UtilityApiMeterResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityApi
   */
  public meterResourceList(requestParameters: UtilityApiMeterResourceListRequest = {}, options?: AxiosRequestConfig) {
    return UtilityApiFp(this.configuration)
      .meterResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.linked,
        requestParameters.organisationId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a meter
   * @param {UtilityApiMeterResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityApi
   */
  public meterResourceUpdate(requestParameters: UtilityApiMeterResourceUpdateRequest, options?: AxiosRequestConfig) {
    return UtilityApiFp(this.configuration)
      .meterResourceUpdate(requestParameters.id, requestParameters.meterUpdateView, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
