import React from 'react';
import { Button, DropdownItemProps, Form, Modal } from 'semantic-ui-react';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { FileInfoReadView, FileSubType, FileType } from '../../../Services/FileService.types';
import * as FileService from '../../../Services/FileService';
import { renderValidationErrors } from '../../../Utils/FieldValidationErrorMessage';
import * as validation from '../../../Utils/validation';
import { useDidMountEffect } from '../../../Utils/hooks';
import { UserFriendlyApiResponse } from '../../../Http/response-error';
import { toast } from 'react-toastify';
import { filenameExtension, filenameWithoutExtensions } from '../../../Utils/file';
import { useTranslation } from 'react-i18next';

interface Props {
  file: FileInfoReadView;
  onClose: (file?: FileInfoReadView) => void;
  allowCategoryEditing?: boolean;
}

enum Status {
  None,
  Saving
}

enum FormFieldId {
  name = 'name',
  type = 'type',
  subType = 'subType'
}

const FileEditModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'files']);

  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);
  const [status, setStatus] = React.useState<Status>(Status.None);

  const [fileName, setFileName] = React.useState<string>(filenameWithoutExtensions(props.file.name));
  const [fileNameValidationResult, setFileNameValidationResult] = React.useState<validation.FieldValidationResult>();

  const [selectedFileType, setSelectedFileType] = React.useState<FileType | undefined | null>(props.file.type);
  const [fileTypeValidationResult, setFileTypeValidationResult] = React.useState<validation.FieldValidationResult>();

  const [selectedFileSubType, setSelectedFileSubType] = React.useState<FileSubType | undefined | null>(
    props.file.subType
  );
  const [fileSubTypeValidationResult, setFileSubTypeValidationResult] =
    React.useState<validation.FieldValidationResult>();

  useDidMountEffect(() => validateName(), [fileName]);
  useDidMountEffect(() => {
    validateFileType();
  }, [selectedFileType]);
  useDidMountEffect(() => {
    validateFileSubType();
  }, [selectedFileSubType]);

  const validateName = () => {
    validation.validateRequiredField(fileName, setFileNameValidationResult, t('files:enterFileName'));
  };

  const validateFileType = () => {
    return validation.validateRequiredEnum(
      FileType,
      selectedFileType,
      setFileTypeValidationResult,
      t('common:selectCategory')
    );
  };

  const validateFileSubType = () => {
    return validation.validateRequiredEnum(
      FileSubType,
      selectedFileSubType,
      setFileSubTypeValidationResult,
      t('files:chooseSubCategory')
    );
  };

  const handleResponseValidationError = (resp: UserFriendlyApiResponse) => {
    const fieldIdMapping = [
      {
        formFieldId: FormFieldId.name,
        setStateFunc: setFileNameValidationResult
      },
      {
        formFieldId: FormFieldId.type,
        setStateFunc: setFileTypeValidationResult
      },
      {
        formFieldId: FormFieldId.subType,
        setStateFunc: setFileSubTypeValidationResult
      }
    ];

    validation.applyApiResponseValidationToFields(resp, fieldIdMapping);
  };

  const getSubTypesForType = () => {
    let subTypeDropdownItems: DropdownItemProps[] = [];
    if (selectedFileType) subTypeDropdownItems = FileService.resolveSubTypeDropdownItems(selectedFileType);

    return subTypeDropdownItems;
  };

  const validateForm = () => {
    let fieldsValid: boolean = validateFileType();

    //only validate the subtype if the type has potential subtypes
    if (getSubTypesForType().length !== 0 && fieldsValid) fieldsValid = validateFileSubType();

    return validation.allFieldsValid([fileNameValidationResult]) && fieldsValid;
  };

  const editFile = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setStatus(Status.Saving);

      const filenameWithExtension = fileName + filenameExtension(props.file.name);
      const data = await FileService.updateFile(props.file.id, {
        [FormFieldId.name]: filenameWithExtension,
        [FormFieldId.subType]: selectedFileSubType,
        [FormFieldId.type]: selectedFileType
      });

      toast.info(t('files:filesHaveBeenUpdated'));

      setStatus(Status.None);
      props.onClose(data);
    } catch (error) {
      globalState.handleHttpErrors(error, { handleResponseValidationError });
      setStatus(Status.None);
    }
  };

  const haveFieldsChanged = () => {
    let fieldsChanged: boolean = false;

    if (fileName && fileName !== filenameWithoutExtensions(props.file.name)) {
      fieldsChanged = true;
    } else if (selectedFileType !== props.file.type) {
      fieldsChanged = true;
    } else if (getSubTypesForType().length !== 0 && props.file.subType !== selectedFileSubType) {
      fieldsChanged = true;
    }

    return fieldsChanged;
  };

  const getLayout = () => {
    let subTypeDropdownItems = getSubTypesForType();

    return (
      <Form>
        <Form.Input
          label={t('common:name')}
          required
          value={fileName}
          fluid
          error={renderValidationErrors(fileNameValidationResult)}
          onChange={(e, d) => {
            setFileName(d.value);
          }}
        />
        {props.allowCategoryEditing && (
          <>
            <Form.Dropdown
              search
              required
              fluid
              selection
              floating
              error={fileTypeValidationResult?.errorMessage}
              label={t('common:category')}
              placeholder={t('common:selectCategory')}
              value={selectedFileType ? selectedFileType : undefined}
              noResultsMessage={t('files:noMatchingCategoriesFound')}
              options={FileService.resolveTypeDropdownItems()}
              onChange={(e, d) => {
                setSelectedFileType(d.value as FileType);
                setSelectedFileSubType(null);
              }}
            />
            {subTypeDropdownItems.length !== 0 && (
              <Form.Dropdown
                search
                fluid
                required
                selection
                floating
                error={fileSubTypeValidationResult?.errorMessage}
                label={t('files:subCategory')}
                value={selectedFileSubType ? selectedFileSubType : undefined}
                placeholder={t('files:chooseSubCategory')}
                noResultsMessage={t('files:noMatchingCategoriesFound')}
                options={subTypeDropdownItems}
                onChange={(e, d) => {
                  setSelectedFileSubType(d.value as FileSubType);
                }}
              />
            )}
          </>
        )}
      </Form>
    );
  };

  return (
    <Modal
      size="mini"
      open
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon={status !== Status.Saving}
      onClose={() => props.onClose()}
    >
      <Modal.Header>{t('files:editFile')}</Modal.Header>
      <Modal.Content>{getLayout()}</Modal.Content>
      <Modal.Actions>
        <Button
          primary
          loading={status === Status.Saving}
          disabled={status === Status.Saving || !haveFieldsChanged()}
          onClick={editFile}
        >
          {t('common:save')}
        </Button>

        <Button secondary disabled={status === Status.Saving} onClick={() => props.onClose()}>
          {t('common:close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default FileEditModal;
