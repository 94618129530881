import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import * as FileService from '../../Services/FileService';
import { FileSubType, FileType } from '../../Services/FileService.types';

interface Props {
  defaultType?: FileType;
  close: (type: FileType, subType?: FileSubType) => void;
  cancel: () => void;
}

const FileUploadSelectTypes: React.FC<Props> = (props) => {
  const { t } = useTranslation(['files', 'common']);
  const [type, setType] = React.useState<FileType | undefined>(props.defaultType);
  const [subType, setSubType] = React.useState<FileSubType>();

  React.useEffect(() => {
    setSubType(undefined);
  }, [type]);

  const allValid = () => {
    return FileService.subTypesExist(type) ? type && subType : type;
  };

  return (
    <Modal open closeIcon size="mini" onClose={props.cancel}>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>{t('common:category')}</label>
            <Form.Group>
              {!type && <Icon className="error-icon" name="exclamation circle" color="red" />}
              <Form.Dropdown
                floating
                search
                selection
                placeholder={t('common:selectCategory')}
                noResultsMessage={t('files:noMatchingCategoriesFound')}
                value={type}
                options={FileService.resolveTypeDropdownItems()}
                onChange={(e, d) => setType(d.value as FileType)}
              />
            </Form.Group>
          </Form.Field>
          <Form.Field>
            {FileService.subTypesExist(type) && (
              <>
                <label>{t('files:subCategory')}</label>

                <Form.Group>
                  {!subType && <Icon className="error-icon" name="exclamation circle" color="red" />}
                  <Form.Dropdown
                    floating
                    search
                    selection
                    value={subType}
                    placeholder={t('common:selectCategory')}
                    noResultsMessage={t('files:noMatchingCategoriesFound')}
                    options={FileService.resolveSubTypeDropdownItems(type!)}
                    onChange={(e, d) => setSubType(d.value as FileSubType)}
                  />
                </Form.Group>
              </>
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.cancel} secondary>
          {t('common:cancel')}
        </Button>
        <Button
          primary
          disabled={!allValid()}
          onClick={() => {
            props.close(type!, subType);
          }}
        >
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default FileUploadSelectTypes;
