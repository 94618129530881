import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { useNonce } from '../../../Utils/hooks';
import NumberFormater from '../../NumberFormater/NumberFormater';
import KpiCard, { KPIStatus } from '../../KPIs/KPICard/KpiCard';
import React from 'react';
import { formatCurrency, getComparedPercentage } from '../../../Utils/number';
import { faArrowDown, faArrowUp, faMinus } from '@fortawesome/pro-solid-svg-icons';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { statisticsApi } from '../../../Http/Http';
import { BaseRentReadView } from '../../../GeneratedServices';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../../FiltersProvider/FiltersProvider';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
  reloadNonce?: number;
}

const BaseRentKpi: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [baseRentStatistics, setBaseRentStatistics] = React.useState<BaseRentReadView>();
  const [status, setStatus] = React.useState<KPIStatus>(KPIStatus.Loading);
  const [shouldReload, reload] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(KPIStatus.Loading);
        const { data } = await statisticsApi.statisticsResourceGetBaseRent(
          {
            ...props.filterValues,
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setBaseRentStatistics(data);
        setStatus(KPIStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(KPIStatus.LoadError);
      }
    };
    load();

    return () => abortController.abort();
  }, [props.filterValues, props.propertyId, handleHttpErrors, shouldReload, props.reloadNonce]);

  const comparedBaseRent =
    baseRentStatistics?.plannedValue &&
    getComparedPercentage(baseRentStatistics.actualValue, baseRentStatistics.plannedValue);

  const hasPlannedValue = baseRentStatistics?.plannedValue;
  return (
    <KpiCard
      title={t('contracts:baseRent')}
      value={
        <NumberFormater
          value={formatCurrency(baseRentStatistics?.actualValue ?? 0, { forceCurrency: 'KSEK' }).value}
          additionalProps={{ decimalScale: 0 }}
        />
      }
      valueSuffix={'KSEK'}
      extra={
        hasPlannedValue ? (
          <NumberFormater
            value={comparedBaseRent}
            suffix={t('common:vsBusinessPlan')}
            additionalProps={{ decimalScale: 1 }}
          />
        ) : (
          t('common:noBusinessPlanInformation')
        )
      }
      extraColor={
        hasPlannedValue ? (comparedBaseRent && comparedBaseRent > 0 ? colors.green : colors.red) : colors.grey
      }
      extraIcon={hasPlannedValue ? (comparedBaseRent && comparedBaseRent > 0 ? faArrowUp : faArrowDown) : faMinus}
      additionalValue={
        baseRentStatistics?.actualValuePerArea != null ? (
          <NumberFormater
            value={formatCurrency(baseRentStatistics?.actualValuePerArea ?? 0, { forceCurrency: 'SEK' }).value}
            additionalProps={{ decimalScale: 0 }}
          />
        ) : (
          t('common:none')
        )
      }
      additionalValueSuffix={t('common:sekSqm')}
      includeExtra
      retry={reload}
      status={status}
    />
  );
};

export default BaseRentKpi;
