/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { PushSubscriptionCreateView } from '../model';
// @ts-ignore
import { PushSubscriptionListView } from '../model';
// @ts-ignore
import { PushSubscriptionReadView } from '../model';
/**
 * PushSubscriptionApi - axios parameter creator
 * @export
 */
export const PushSubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Save push token
     * @param {PushSubscriptionCreateView} [pushSubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushSubscriptionResourceCreate: async (
      pushSubscriptionCreateView?: PushSubscriptionCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/push-subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pushSubscriptionCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete push token
     * @param {string} token Push token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushSubscriptionResourceDelete: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('pushSubscriptionResourceDelete', 'token', token);
      const localVarPath = `/v1/push-subscriptions/{token}`.replace(`{${'token'}}`, encodeURIComponent(String(token)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List user\'s push subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushSubscriptionResourceList: async (
      limit?: number,
      offset?: number,
      userId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/push-subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * PushSubscriptionApi - functional programming interface
 * @export
 */
export const PushSubscriptionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PushSubscriptionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Save push token
     * @param {PushSubscriptionCreateView} [pushSubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pushSubscriptionResourceCreate(
      pushSubscriptionCreateView?: PushSubscriptionCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushSubscriptionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pushSubscriptionResourceCreate(
        pushSubscriptionCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete push token
     * @param {string} token Push token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pushSubscriptionResourceDelete(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pushSubscriptionResourceDelete(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List user\'s push subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pushSubscriptionResourceList(
      limit?: number,
      offset?: number,
      userId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushSubscriptionListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pushSubscriptionResourceList(
        limit,
        offset,
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * PushSubscriptionApi - factory interface
 * @export
 */
export const PushSubscriptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PushSubscriptionApiFp(configuration);
  return {
    /**
     *
     * @summary Save push token
     * @param {PushSubscriptionCreateView} [pushSubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushSubscriptionResourceCreate(
      pushSubscriptionCreateView?: PushSubscriptionCreateView,
      options?: any
    ): AxiosPromise<PushSubscriptionReadView> {
      return localVarFp
        .pushSubscriptionResourceCreate(pushSubscriptionCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete push token
     * @param {string} token Push token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushSubscriptionResourceDelete(token: string, options?: any): AxiosPromise<void> {
      return localVarFp.pushSubscriptionResourceDelete(token, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List user\'s push subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushSubscriptionResourceList(
      limit?: number,
      offset?: number,
      userId?: number,
      options?: any
    ): AxiosPromise<PushSubscriptionListView> {
      return localVarFp
        .pushSubscriptionResourceList(limit, offset, userId, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for pushSubscriptionResourceCreate operation in PushSubscriptionApi.
 * @export
 * @interface PushSubscriptionApiPushSubscriptionResourceCreateRequest
 */
export interface PushSubscriptionApiPushSubscriptionResourceCreateRequest {
  /**
   *
   * @type {PushSubscriptionCreateView}
   * @memberof PushSubscriptionApiPushSubscriptionResourceCreate
   */
  readonly pushSubscriptionCreateView?: PushSubscriptionCreateView;
}

/**
 * Request parameters for pushSubscriptionResourceDelete operation in PushSubscriptionApi.
 * @export
 * @interface PushSubscriptionApiPushSubscriptionResourceDeleteRequest
 */
export interface PushSubscriptionApiPushSubscriptionResourceDeleteRequest {
  /**
   * Push token
   * @type {string}
   * @memberof PushSubscriptionApiPushSubscriptionResourceDelete
   */
  readonly token: string;
}

/**
 * Request parameters for pushSubscriptionResourceList operation in PushSubscriptionApi.
 * @export
 * @interface PushSubscriptionApiPushSubscriptionResourceListRequest
 */
export interface PushSubscriptionApiPushSubscriptionResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof PushSubscriptionApiPushSubscriptionResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof PushSubscriptionApiPushSubscriptionResourceList
   */
  readonly offset?: number;

  /**
   * Filter by user
   * @type {number}
   * @memberof PushSubscriptionApiPushSubscriptionResourceList
   */
  readonly userId?: number;
}

/**
 * PushSubscriptionApi - object-oriented interface
 * @export
 * @class PushSubscriptionApi
 * @extends {BaseAPI}
 */
export class PushSubscriptionApi extends BaseAPI {
  /**
   *
   * @summary Save push token
   * @param {PushSubscriptionApiPushSubscriptionResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushSubscriptionApi
   */
  public pushSubscriptionResourceCreate(
    requestParameters: PushSubscriptionApiPushSubscriptionResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PushSubscriptionApiFp(this.configuration)
      .pushSubscriptionResourceCreate(requestParameters.pushSubscriptionCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete push token
   * @param {PushSubscriptionApiPushSubscriptionResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushSubscriptionApi
   */
  public pushSubscriptionResourceDelete(
    requestParameters: PushSubscriptionApiPushSubscriptionResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return PushSubscriptionApiFp(this.configuration)
      .pushSubscriptionResourceDelete(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List user\'s push subscriptions
   * @param {PushSubscriptionApiPushSubscriptionResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushSubscriptionApi
   */
  public pushSubscriptionResourceList(
    requestParameters: PushSubscriptionApiPushSubscriptionResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PushSubscriptionApiFp(this.configuration)
      .pushSubscriptionResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.userId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
