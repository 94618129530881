/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { FileImportView } from '../model';
/**
 * ImportApi - axios parameter creator
 * @export
 */
export const ImportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Import financial information
     * @param {number} organisationId Organisation ID
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importResourceEdisonFinancialInformationImport: async (
      organisationId: number,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('importResourceEdisonFinancialInformationImport', 'organisationId', organisationId);
      const localVarPath = `/v1/import/edison/organisation/{organisationId}/financial-information`.replace(
        `{${'organisationId'}}`,
        encodeURIComponent(String(organisationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import Strifast files
     * @param {number} organisationId Organisation ID
     * @param {FileImportView} [fileImportView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importResourceStrifastFileImport: async (
      organisationId: number,
      fileImportView?: FileImportView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organisationId' is not null or undefined
      assertParamExists('importResourceStrifastFileImport', 'organisationId', organisationId);
      const localVarPath = `/v1/import/strifast/organisation/{organisationId}/files`.replace(
        `{${'organisationId'}}`,
        encodeURIComponent(String(organisationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fileImportView, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import Strifast database
     * @param {number} id Organisation ID
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importResourceStrifastImport: async (
      id: number,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('importResourceStrifastImport', 'id', id);
      const localVarPath = `/v1/import/strifast/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ImportApi - functional programming interface
 * @export
 */
export const ImportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Import financial information
     * @param {number} organisationId Organisation ID
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importResourceEdisonFinancialInformationImport(
      organisationId: number,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importResourceEdisonFinancialInformationImport(
        organisationId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Import Strifast files
     * @param {number} organisationId Organisation ID
     * @param {FileImportView} [fileImportView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importResourceStrifastFileImport(
      organisationId: number,
      fileImportView?: FileImportView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importResourceStrifastFileImport(
        organisationId,
        fileImportView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Import Strifast database
     * @param {number} id Organisation ID
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importResourceStrifastImport(
      id: number,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importResourceStrifastImport(id, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ImportApi - factory interface
 * @export
 */
export const ImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ImportApiFp(configuration);
  return {
    /**
     *
     * @summary Import financial information
     * @param {number} organisationId Organisation ID
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importResourceEdisonFinancialInformationImport(
      organisationId: number,
      body?: object,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .importResourceEdisonFinancialInformationImport(organisationId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import Strifast files
     * @param {number} organisationId Organisation ID
     * @param {FileImportView} [fileImportView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importResourceStrifastFileImport(
      organisationId: number,
      fileImportView?: FileImportView,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .importResourceStrifastFileImport(organisationId, fileImportView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import Strifast database
     * @param {number} id Organisation ID
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importResourceStrifastImport(id: number, body?: object, options?: any): AxiosPromise<void> {
      return localVarFp.importResourceStrifastImport(id, body, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for importResourceEdisonFinancialInformationImport operation in ImportApi.
 * @export
 * @interface ImportApiImportResourceEdisonFinancialInformationImportRequest
 */
export interface ImportApiImportResourceEdisonFinancialInformationImportRequest {
  /**
   * Organisation ID
   * @type {number}
   * @memberof ImportApiImportResourceEdisonFinancialInformationImport
   */
  readonly organisationId: number;

  /**
   *
   * @type {object}
   * @memberof ImportApiImportResourceEdisonFinancialInformationImport
   */
  readonly body?: object;
}

/**
 * Request parameters for importResourceStrifastFileImport operation in ImportApi.
 * @export
 * @interface ImportApiImportResourceStrifastFileImportRequest
 */
export interface ImportApiImportResourceStrifastFileImportRequest {
  /**
   * Organisation ID
   * @type {number}
   * @memberof ImportApiImportResourceStrifastFileImport
   */
  readonly organisationId: number;

  /**
   *
   * @type {FileImportView}
   * @memberof ImportApiImportResourceStrifastFileImport
   */
  readonly fileImportView?: FileImportView;
}

/**
 * Request parameters for importResourceStrifastImport operation in ImportApi.
 * @export
 * @interface ImportApiImportResourceStrifastImportRequest
 */
export interface ImportApiImportResourceStrifastImportRequest {
  /**
   * Organisation ID
   * @type {number}
   * @memberof ImportApiImportResourceStrifastImport
   */
  readonly id: number;

  /**
   *
   * @type {object}
   * @memberof ImportApiImportResourceStrifastImport
   */
  readonly body?: object;
}

/**
 * ImportApi - object-oriented interface
 * @export
 * @class ImportApi
 * @extends {BaseAPI}
 */
export class ImportApi extends BaseAPI {
  /**
   *
   * @summary Import financial information
   * @param {ImportApiImportResourceEdisonFinancialInformationImportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public importResourceEdisonFinancialInformationImport(
    requestParameters: ImportApiImportResourceEdisonFinancialInformationImportRequest,
    options?: AxiosRequestConfig
  ) {
    return ImportApiFp(this.configuration)
      .importResourceEdisonFinancialInformationImport(requestParameters.organisationId, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Strifast files
   * @param {ImportApiImportResourceStrifastFileImportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public importResourceStrifastFileImport(
    requestParameters: ImportApiImportResourceStrifastFileImportRequest,
    options?: AxiosRequestConfig
  ) {
    return ImportApiFp(this.configuration)
      .importResourceStrifastFileImport(requestParameters.organisationId, requestParameters.fileImportView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Strifast database
   * @param {ImportApiImportResourceStrifastImportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public importResourceStrifastImport(
    requestParameters: ImportApiImportResourceStrifastImportRequest,
    options?: AxiosRequestConfig
  ) {
    return ImportApiFp(this.configuration)
      .importResourceStrifastImport(requestParameters.id, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
