import { ListViewPagination, ListViewPaginationParams } from './types';
import i18n from '../i18n';

export interface PropertyCheckListView extends ListViewPagination<PropertyCheckReadView> {}

export enum PropertyCheckSortBy {
  DeadlineAsc = 'deadline_asc'
}
export interface PropertyCheckListParams extends ListViewPaginationParams {
  propertyId?: number;
  isGeneral?: boolean;
  caretakerId?: number;
  propertyManagerId?: number;
  organisationId?: number;
  sortBy?: PropertyCheckSortBy.DeadlineAsc;
  portfolioId?: number;
  propertyAssigneeId?: number;
  search?: string;
  organisationIds?: number[];
  portfolioIds?: number[];
  propertySubscriptionUserIds?: number[];
  municipalities?: string[];
}

export interface PropertyCheckReadView {
  id: number;
  title: string;
  description: string;
  reoccurrence: PropertyCheckReoccurrence;
  lastCompleted?: string;
  propertyId?: number;
  status: PropertyCheckStatus;
  deadline: string;
}

export interface PropertyCheckCreateView {
  title: string;
  description: string;
  reoccurrence: PropertyCheckReoccurrence;
  lastCompleted?: string;
  propertyId?: number;
  organisationId?: number;
}

export interface PropertyCheckUpdateView {
  title?: string;
  description?: string;
  reoccurrence?: PropertyCheckReoccurrence;
  lastCompleted?: string;
}

export interface PropertyCheckApplyGeneralView {
  properties: number[];
}

export interface PropertyCheckOrderUpdateView {
  orders: number[];
}

export enum PropertyCheckStatus {
  COMPLETED = 'COMPLETED',
  DUE = 'DUE',
  OVERDUE = 'OVERDUE'
}

export enum PropertyCheckReoccurrence {
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEK = 'TWO_WEEK',
  THREE_WEEK = 'THREE_WEEK',
  FOUR_WEEK = 'FOUR_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTH = 'TWO_MONTH',
  THREE_MONTH = 'THREE_MONTH',
  FOUR_MONTH = 'FOUR_MONTH',
  FIVE_MONTH = 'FIVE_MONTH',
  SIX_MONTH = 'SIX_MONTH',
  SEVEN_MONTH = 'SEVEN_MONTH',
  EIGHT_MONTH = 'EIGHT_MONTH',
  NINE_MONTH = 'NINE_MONTH',
  TEN_MONTH = 'TEN_MONTH',
  ELEVEN_MONTH = 'ELEVEN_MONTH',
  YEAR = 'YEAR',
  TWO_YEAR = 'TWO_YEAR',
  THREE_YEAR = 'THREE_YEAR'
}

enum ReocurrenceCalendarType {
  WEEK,
  MONTH,
  YEAR
}

const reocurrenceTranslationAndOrderMap = () =>
  new Map([
    // WEEK
    [
      PropertyCheckReoccurrence.ONE_WEEK,
      { type: ReocurrenceCalendarType.WEEK, value: i18n.t('propertyChecks:recurrence.everyWeek') }
    ],
    [
      PropertyCheckReoccurrence.TWO_WEEK,
      { type: ReocurrenceCalendarType.WEEK, value: i18n.t('propertyChecks:recurrence.every2Weeks') }
    ],
    [
      PropertyCheckReoccurrence.THREE_WEEK,
      { type: ReocurrenceCalendarType.WEEK, value: i18n.t('propertyChecks:recurrence.every3Weeks') }
    ],
    [
      PropertyCheckReoccurrence.FOUR_WEEK,
      { type: ReocurrenceCalendarType.WEEK, value: i18n.t('propertyChecks:recurrence.every4Weeks') }
    ],

    // MONTH
    [
      PropertyCheckReoccurrence.ONE_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.everyMonth') }
    ],
    [
      PropertyCheckReoccurrence.TWO_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every2Months') }
    ],
    [
      PropertyCheckReoccurrence.THREE_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every3Months') }
    ],
    [
      PropertyCheckReoccurrence.FOUR_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every4Months') }
    ],
    [
      PropertyCheckReoccurrence.FIVE_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every5Months') }
    ],
    [
      PropertyCheckReoccurrence.SIX_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every6Months') }
    ],
    [
      PropertyCheckReoccurrence.SEVEN_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every7Months') }
    ],
    [
      PropertyCheckReoccurrence.EIGHT_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every8Months') }
    ],
    [
      PropertyCheckReoccurrence.NINE_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every9Months') }
    ],
    [
      PropertyCheckReoccurrence.TEN_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every10Months') }
    ],
    [
      PropertyCheckReoccurrence.ELEVEN_MONTH,
      { type: ReocurrenceCalendarType.MONTH, value: i18n.t('propertyChecks:recurrence.every11Months') }
    ],

    // YEAR
    [
      PropertyCheckReoccurrence.YEAR,
      { type: ReocurrenceCalendarType.YEAR, value: i18n.t('propertyChecks:recurrence.everyYear') }
    ],
    [
      PropertyCheckReoccurrence.TWO_YEAR,
      { type: ReocurrenceCalendarType.YEAR, value: i18n.t('propertyChecks:recurrence.every2Years') }
    ],
    [
      PropertyCheckReoccurrence.THREE_YEAR,
      { type: ReocurrenceCalendarType.YEAR, value: i18n.t('propertyChecks:recurrence.every3Years') }
    ]
  ]);

export const translatePropertyCheckReoccurrence = (reoccurrence: PropertyCheckReoccurrence): string => {
  return reocurrenceTranslationAndOrderMap().has(reoccurrence)
    ? reocurrenceTranslationAndOrderMap().get(reoccurrence)!.value
    : reoccurrence.toString();
};

export const getReocurrenceSortIndex = (reoccurrence: PropertyCheckReoccurrence): number => {
  return [...reocurrenceTranslationAndOrderMap().keys()].indexOf(reoccurrence);
};

export const isReoccurrenceWeek = (reoccurrence: PropertyCheckReoccurrence): boolean => {
  return reocurrenceTranslationAndOrderMap().has(reoccurrence)
    ? reocurrenceTranslationAndOrderMap().get(reoccurrence)!.type === ReocurrenceCalendarType.WEEK
    : false;
};

export const isReoccurrenceMonth = (reoccurrence: PropertyCheckReoccurrence) => {
  return reocurrenceTranslationAndOrderMap().has(reoccurrence)
    ? reocurrenceTranslationAndOrderMap().get(reoccurrence)!.type === ReocurrenceCalendarType.MONTH
    : false;
};

export const isReoccurrenceYear = (reoccurrence: PropertyCheckReoccurrence) => {
  return reocurrenceTranslationAndOrderMap().has(reoccurrence)
    ? reocurrenceTranslationAndOrderMap().get(reoccurrence)!.type === ReocurrenceCalendarType.YEAR
    : false;
};
