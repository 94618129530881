import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, List } from 'semantic-ui-react';
import { FiltersContext } from '../../FiltersProvider/FiltersProvider';
import { businessPlanApi } from '../../Http/Http';
import { mapOrganisationsToDropdownItems } from '../../Services/OrganisationService.types';
import ImportModal from '../ImportModal/ImportModal';

interface Props {
  onClose: (imported: boolean) => void;
  multiple?: boolean;
  organisationId?: number;
}

const BusinessPlanImportModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts', 'files']);
  const { organisationsMap } = React.useContext(FiltersContext);

  const [selectedOrganisationId, setSelectedOrganisationId] = React.useState<number | undefined>(props.organisationId);

  return (
    <ImportModal
      onClose={props.onClose}
      uploadedEntityType={props.multiple ? t('common:businessPlans') : t('common:businessPlan')}
      importPromise={(file, config) =>
        businessPlanApi.businessPlanResourceImportBusinessPlan(
          { organisationId: selectedOrganisationId!, body: file },
          config
        )
      }
      importDisabled={!selectedOrganisationId}
      uploadStepsLayout={
        <List ordered relaxed>
          <List.Item>
            {props.multiple ? t('contracts:exportBusinessPlans') : t('contracts:exportBusinessPlan')}
          </List.Item>
          <List.Item>{t('files:populateFileWithData')}</List.Item>
          {!props.organisationId && (
            <List.Item style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
              <div>{t('files:selectOrganisation')}</div>
              <Dropdown
                label={t('common:organisation')}
                placeholder={t('common:organisation')}
                floating
                closeOnBlur
                closeOnChange
                closeOnEscape
                value={selectedOrganisationId ?? ''}
                onChange={(e, d) => {
                  setSelectedOrganisationId((d.value as number) ?? undefined);
                }}
                selection
                scrolling
                options={mapOrganisationsToDropdownItems(Array.from(organisationsMap.values()))}
              />
            </List.Item>
          )}
          <List.Item>{t('common:upload')}</List.Item>
        </List>
      }
    />
  );
};

export default BusinessPlanImportModal;
