import React, { ReactNode } from 'react';
import { Input, Popup } from 'semantic-ui-react';
import { renderValidationErrors } from '../../Utils/FieldValidationErrorMessage';
import { FieldValidationResult } from '../../Utils/validation';
import styles from './EditableTableDateCell.module.scss';
import ReactDOM from 'react-dom';
import { datepickerPortalId } from '../../Views/Properties/Components/BusinessPlan/BusinessPlanModal';
import DatePicker from '../DatePicker/DatePicker';
import { toDateString } from '../../Utils/date';

interface Props {
  displayValue: string;
  value?: string;
  placeholder: string;
  onValueChange: (value: string) => void;
  isInEditMode?: boolean;
  validationResult?: FieldValidationResult;
  minDate?: string | null;
  inputClassName?: string;
  disabled?: boolean;
}

const EditableTableDateCell: React.FC<Props> = (props) => {
  const { displayValue, value, onValueChange, isInEditMode, validationResult, minDate, inputClassName } = props;

  const [isPopupOpen, setPopupOpen] = React.useState<boolean>(false);
  const PopperContainer = ({ children }: { children: ReactNode }) => {
    const container = document.getElementById(datepickerPortalId);
    return ReactDOM.createPortal(children, container!);
  };

  if (isInEditMode)
    return (
      <Popup
        content={renderValidationErrors(validationResult)}
        open={validationResult !== undefined && validationResult.error && isPopupOpen}
        position="bottom center"
        trigger={
          <DatePicker
            selected={value ? new Date(value) : undefined}
            disabled={props.disabled}
            dateFormat="P"
            popperContainer={PopperContainer}
            popperPlacement="top"
            placeholderText="YYYY-MM-DD"
            className={styles.datePicker}
            minDate={minDate ? new Date(minDate) : undefined}
            wrapperClassName={styles.datePickerWrapper}
            isClearable={false}
            onChange={(date) => {
              onValueChange(date != null ? toDateString(date) : displayValue!);
            }}
            customInput={
              <Input
                onMouseEnter={() => setPopupOpen(true)}
                onMouseLeave={() => setPopupOpen(false)}
                className={inputClassName ?? styles.input}
                icon="calendar alternate outline"
                iconPosition="left"
                error={validationResult?.error}
              />
            }
          />
        }
      />
    );
  else return <>{displayValue}</>;
};

export default EditableTableDateCell;
