import { round } from 'lodash';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface Props {
  value?: string | number | null;
  prefix?: string;
  suffix?: string;
  additionalProps?: NumberFormatProps;
}
export const commonNumberFormatProperties: NumberFormatProps = {
  thousandSeparator: ' ',
  decimalSeparator: ',',
  allowedDecimalSeparators: [',', '.'],
  allowNegative: false,
  allowLeadingZeros: false,
  decimalScale: 2
};
const NumberFormater: React.FC<Props> = (props) => {
  let value = props.value;
  if (props.additionalProps?.decimalScale === 1 && typeof props.value === 'number') {
    value = round(props.value, 1);
  }
  return (
    <NumberFormat
      {...commonNumberFormatProperties}
      {...props.additionalProps}
      value={value ?? ''}
      displayType={'text'}
      prefix={props.prefix}
      suffix={props.suffix}
    />
  );
};

export default NumberFormater;
