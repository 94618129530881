import React from 'react';
import { Icon } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { useIsMounted } from '../../Utils/hooks';
import styles from './ActionBadge.module.scss';

interface Props {
  icon: SemanticICONS;
  backgroundColor: string;
  semiTransparent?: boolean;
  spinning?: boolean;
  title?: string;
  size?: string;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

const ActionBadge: React.FC<Props> = (props) => {
  const componentWrapperRef = React.useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();

  React.useEffect(() => {
    if (isMounted()) {
      componentWrapperRef.current?.style.setProperty('--background-color', props.backgroundColor);

      if (props.size !== undefined) {
        componentWrapperRef.current?.style.setProperty('--font-size', props.size);
      }
    }
  }, [isMounted, props.backgroundColor, props.size]);

  return (
    <div
      ref={componentWrapperRef}
      className={styles.componentWrapper}
      data-semi-transparent={props.semiTransparent}
      data-spinning={props.spinning ?? false}
      title={props.title}
      onClick={(e) => props.onClick?.(e)}
    >
      <Icon name={props.icon} loading={props.spinning ?? false} fitted className="clear-icon" />
    </div>
  );
};

ActionBadge.defaultProps = {
  semiTransparent: true
};

export default ActionBadge;
