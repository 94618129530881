import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { globalStateCTX } from '../GlobalState/GlobalState';
import { Role } from '../Utils/permissions';
import AnonymousRoute from './AnonymousRoute';
import AuthenticatedRoute from './AuthenticatedRoute';

const Overview = React.lazy(() => import('../Views/Overview/OverViewContext'));
const Analytics = React.lazy(() => import('../Views/Analytics/AnalyticsWrapper'));
const MyProfile = React.lazy(() => import('../Views/Profile/MyProfile'));
const Login = React.lazy(() => import('../Views/Auth/Login'));
const ForgotPassword = React.lazy(() => import('../Views/Auth/ForgotPassword'));
const CreateAccount = React.lazy(() => import('../Views/Auth/CreateAccount'));
const ChangePassword = React.lazy(() => import('../Views/Auth/ChangePassword'));
const VerifyEmail = React.lazy(() => import('../Views/Auth/VerifyEmail'));
const NotFound = React.lazy(() => import('./NotFound'));
const OrganisationList = React.lazy(() => import('../Views/Organisations/OrganisationList'));
const OrganisationDetailWrapper = React.lazy(() => import('../Views/Organisations/OrganisationDetailWrapper'));
const Properties = React.lazy(() => import('../Views/Properties/Properties'));
const PortfolioDetails = React.lazy(() => import('../Views/Portfolios/PortfolioDetails'));
const PropertyDetails = React.lazy(() => import('../Views/Properties/PropertyDetails'));
const Companies = React.lazy(() => import('../Views/Companies/Companies'));
const CompanyDetails = React.lazy(() => import('../Views/Companies/CompanyDetails'));
const Tenants = React.lazy(() => import('../Views/Tenants/Tenants'));
const TenantDetails = React.lazy(() => import('../Views/Tenants/TenantDetails'));
const FaultReportDetails = React.lazy(() => import('../Views/FaultReports/FaultReportDetails'));
const UserOverview = React.lazy(() => import('../Views/Users/UserOverview'));
const Metry = React.lazy(() => import('../Views/Organisations/Components/Integrations/Metry/Metry'));
const Edison = React.lazy(() => import('../Views/Organisations/Components/Integrations/Edison/Edison'));
const CreateFaultReportPublic = React.lazy(
  () => import('../Views/FaultReports/CreateFaultReportPublic/CreateFaultReportPublic')
);
const CreateFaultReportPublic2 = React.lazy(
  () => import('../Views/FaultReports/CreateFaultReportPublic/CreateFaultReportPublic2')
);
const SearchPage = React.lazy(() => import('../Views/Search/SearchPage'));
const WorkOrders = React.lazy(() => import('../Views/WorkOrders/WorkOrders'));
const GovernmentInspections = React.lazy(() => import('../Views/GovernmentInspections/GovernmentInspections'));
const GovernmentInspectionItems = React.lazy(() => import('../Views/GovernmentInspections/GovernmentInspectionItems'));
const ComparableProperty = React.lazy(() => import('../Views/Analytics/ComparableProperty/ComparableProperty'));
const FaultReports = React.lazy(() => import('../Views/FaultReports/FaultReports'));
const AnalyticsPositionChange = React.lazy(
  () => import('../Views/Analytics/AnalyticsPositionChange/AnalyticsPositionChange')
);
const PrivacyPolicy = React.lazy(() => import('../Views/PrivacyPolicy/PrivacyPolicy'));
const AnonymousRoutes = (
  <>
    <Route
      element={
        <AnonymousRoute>
          <Login />
        </AnonymousRoute>
      }
      path="/login"
    />
    <Route
      element={
        <AnonymousRoute>
          <ForgotPassword />
        </AnonymousRoute>
      }
      path="/forgot-password"
    />
    <Route
      element={
        <AnonymousRoute>
          <CreateAccount />
        </AnonymousRoute>
      }
      path="/create-account"
    />
    <Route
      element={
        <AnonymousRoute>
          <ChangePassword />
        </AnonymousRoute>
      }
      path="/forgot-password/reset"
    />
    <Route
      element={
        <AnonymousRoute>
          <VerifyEmail />
        </AnonymousRoute>
      }
      path="/verify-email"
    />
  </>
);

const AuthenticatedRoutes = (
  <>
    <Route
      element={
        <AuthenticatedRoute>
          <MyProfile />
        </AuthenticatedRoute>
      }
      path="/my-profile"
    />

    <Route
      element={
        <AuthenticatedRoute>
          <Overview />
        </AuthenticatedRoute>
      }
      path="/overview"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician, Role.Investor]}>
          <Properties />
        </AuthenticatedRoute>
      }
      path="/properties"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician, Role.Investor]}>
          <PropertyDetails />
        </AuthenticatedRoute>
      }
      path="/properties/:propertyId"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}>
          <Tenants />
        </AuthenticatedRoute>
      }
      path="/tenants"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician, Role.Investor]}>
          <TenantDetails />
        </AuthenticatedRoute>
      }
      path="/tenants/:tenantId"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}>
          <WorkOrders />
        </AuthenticatedRoute>
      }
      path="/work-orders"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}>
          <GovernmentInspections />
        </AuthenticatedRoute>
      }
      path="/government-inspections"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}>
          <GovernmentInspectionItems />
        </AuthenticatedRoute>
      }
      path="/government-inspection-items/:category"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}>
          <FaultReports />
        </AuthenticatedRoute>
      }
      path="/fault-reports"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}>
          <FaultReportDetails />
        </AuthenticatedRoute>
      }
      path="/fault-reports/:faultReportId"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}>
          <UserOverview />
        </AuthenticatedRoute>
      }
      path="/users/:userId"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Technician]}>
          <SearchPage />
        </AuthenticatedRoute>
      }
      path="/search"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Investor, Role.Manager]}>
          <PortfolioDetails />
        </AuthenticatedRoute>
      }
      path="/organisations/:organisationId/portfolios/:portfolioId"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager]}>
          <Companies />
        </AuthenticatedRoute>
      }
      path="/companies"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager]}>
          <CompanyDetails />
        </AuthenticatedRoute>
      }
      path="/companies/:companyId"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Investor]}>
          <Analytics />
        </AuthenticatedRoute>
      }
      path="/analytics/:viewType/:comparableDataType"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager]}>
          <AnalyticsPositionChange />
        </AuthenticatedRoute>
      }
      path="/analytics-position-change"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Investor]}>
          <OrganisationList />
        </AuthenticatedRoute>
      }
      path="/organisations"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager, Role.Investor]}>
          <OrganisationDetailWrapper />
        </AuthenticatedRoute>
      }
      path="/organisations/:organisationId"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager]}>
          <Metry />
        </AuthenticatedRoute>
      }
      path="/organisations/:organisationId/metry"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager]}>
          <Edison />
        </AuthenticatedRoute>
      }
      path="/organisations/:organisationId/edison"
    />

    <Route
      element={
        <AuthenticatedRoute allowedRoles={[Role.Admin, Role.Manager]}>
          <ComparableProperty />
        </AuthenticatedRoute>
      }
      path="/analytics/property/:propertyId"
    />
  </>
);

const PublicRoutes = (
  <>
    <Route
      element={<CreateFaultReportPublic />}
      path="/organisations/:organisationId/theme/:theme/create-work-order-public"
    />
    <Route
      element={<CreateFaultReportPublic2 />}
      path="/organisations/:organisationId/theme/:theme/create-work-order-public-2"
    />
    <Route element={<Analytics />} path="/analytics/:viewType/:comparableDataType/share" />
    <Route element={<PrivacyPolicy />} path="/privacy-policy" />
  </>
);

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {AnonymousRoutes}

      {AuthenticatedRoutes}

      {PublicRoutes}

      {
        // FALLBACK ROUTES
      }
      <Route path="/" element={<DefaultRoute />} />
      <Route path="/analytics" element={<Navigate to="/analytics/direct/transactions" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const DefaultRoute: React.FC<{}> = () => {
  const { isAuthenticated } = React.useContext(globalStateCTX);
  return isAuthenticated ? <Navigate to="/overview" /> : <Navigate to="/login" />;
};

export default AppRoutes;
