import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Loader, Modal } from 'semantic-ui-react';
import {
  FileInfoReadView,
  GovernmentInspectionItemResultUpdateView,
  GovernmentInspectionListItemView,
  GovernmentInspectionListItemViewCategoryEnum,
  GovernmentInspectionWorkOrderPerformView,
  GovernmentInspectionWorkOrderReadView
} from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import {
  createInfinitePaginationParams,
  fileApi,
  governmentInspectionApi,
  NetworkRequestStatus
} from '../../../Http/Http';
import { FileType } from '../../../Services/FileService.types';
import {
  convertGovernmentInspectionCategoryToFileSubType,
  generateGovernmentInspectionCategoryOptions,
  mapGovernmentInspectionsToDropdownItems
} from '../../../Services/GovernmentInspectionService.types';
import { useNonce } from '../../../Utils/hooks';
import FileAutoUploader from '../../FileAutoUploader/FileAutoUploader';
import { InspectionItemResultState, validateRequired } from './GovernmentInspectionItemResultsReducer';
import GovernmentInspectionWorkOrderTable from './GovernmentInspectionWorkOrderTable';
import InspectionItemResultDefaultsModal from './InspectionItemResultDefaultsModal';
import LoadError from '../../LoadError';
import { v4 as uuid } from 'uuid';
import styles from './PerformGovernmentInspectionModal.module.scss';
import { toast } from 'react-toastify';
import { UserFriendlyApiResponse } from '../../../Http/response-error';
import { applyApiResponseValidationToFields } from '../../../Utils/validation';

interface SharedProps {
  onClose: (showConfirmation?: boolean) => void;
  onPerform: (workOrder: GovernmentInspectionWorkOrderReadView) => void;
}

export type PerformInspectionModalProps =
  | ({
      governmentInspectionWorkOrderId: undefined;
      category?: GovernmentInspectionListItemViewCategoryEnum;
      propertyId?: number;
      initialItemIds?: number[];
    } & SharedProps)
  | ({
      governmentInspectionWorkOrderId: number;
    } & SharedProps);

enum Status {
  None,
  Saving
}
const PerformGovernmentInspectionModal: React.FC<PerformInspectionModalProps> = (props) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const propertyId = props.governmentInspectionWorkOrderId === undefined ? props.propertyId : undefined;

  const { t } = useTranslation(['inspections', 'common']);

  const [inspectionStatus, setInspectionStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.None);
  const [inspectionWorkOrderStatus, setInspectionWorkOrderStatus] = React.useState<NetworkRequestStatus>(
    NetworkRequestStatus.None
  );

  const [inspectionWorkOrder, setInspectionWorkOrder] = React.useState<GovernmentInspectionWorkOrderReadView>();
  const [inspectionWorkOrderReloadCount, incrementWorkOrderReloadCount] = useNonce();

  const [category, setCategory] = React.useState<GovernmentInspectionListItemViewCategoryEnum | undefined>(
    props.governmentInspectionWorkOrderId === undefined ? props.category : undefined
  );

  const [status, setStatus] = React.useState<Status>(Status.None);

  const [inspections, setInspections] = React.useState<GovernmentInspectionListItemView[]>([]);
  const [inspection, setInspection] = React.useState<GovernmentInspectionListItemView>();

  const [inspectionItemResults, setInspectionItemResults] = React.useState<InspectionItemResultState>([]);

  const [hasNonCompletedFiles, setHasNonCompletedFiles] = React.useState(false);
  const [fileSelectionCount, incrementFileSelectionCount] = useNonce();
  const [initFiles, setInitFiles] = React.useState<FileInfoReadView[]>([]);
  const [fileIds, setFileIds] = React.useState<string[]>([]);

  const [isResultDefaultsModalOpen, setResultDefaultsModalOpen] = React.useState<boolean>(false);
  const [itemResultDefaults, setItemResultDefaults] =
    React.useState<Omit<GovernmentInspectionItemResultUpdateView, 'itemId'>>();

  const initialItemIds = props.governmentInspectionWorkOrderId === undefined ? props.initialItemIds : undefined;

  const initialInspectionItemResultState: InspectionItemResultState | undefined = React.useMemo(() => {
    if (inspectionWorkOrder) {
      return inspectionWorkOrder.itemResults.map((item) => {
        return {
          uuid: uuid(),
          ...item,
          validationResults: {
            comment: undefined,
            itemId: undefined,
            nextInspectionDeadline: undefined,
            passed: undefined,
            performed: undefined
          }
        };
      });
    }
    if (initialItemIds) {
      return initialItemIds.map((id) => ({
        uuid: uuid(),
        itemId: id,
        validationResults: {
          comment: undefined,
          itemId: undefined,
          nextInspectionDeadline: validateRequired(undefined),
          passed: validateRequired(undefined),
          performed: validateRequired(undefined)
        }
      }));
    }
  }, [inspectionWorkOrder, initialItemIds]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setInspectionStatus(NetworkRequestStatus.Loading);
        const { data } = await governmentInspectionApi.governmentInspectionResourceList(
          {
            ...createInfinitePaginationParams(),
            category,
            propertyId
          },
          {
            signal: abortController.signal
          }
        );

        setInspections(data.records);
        if (propertyId) {
          setInspection(data.records[0]);
        }
        setInspectionStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setInspectionStatus(NetworkRequestStatus.LoadError);
      }
    };

    if (category && props.governmentInspectionWorkOrderId === undefined) load();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, category, propertyId, props.governmentInspectionWorkOrderId]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadWorkOrder = async () => {
      if (props.governmentInspectionWorkOrderId)
        try {
          setInspectionWorkOrderStatus(NetworkRequestStatus.Loading);
          const { data } = await governmentInspectionApi.governmentInspectionWorkOrderResourceRead(
            {
              id: props.governmentInspectionWorkOrderId
            },
            {
              signal: abortController.signal
            }
          );

          const { data: inspectionData } = await governmentInspectionApi.governmentInspectionResourceRead(
            {
              id: data.governmentInspectionId
            },
            {
              signal: abortController.signal
            }
          );

          const { data: files } = await fileApi.fileResourceList({
            ...createInfinitePaginationParams(),
            entityType: 'GOVERNMENT_INSPECTION_WORK_ORDER',
            entityId: props.governmentInspectionWorkOrderId
          });

          setInspection(inspectionData);
          setInspectionWorkOrder(data);
          setInitFiles(files.records);
          setInspectionWorkOrderStatus(NetworkRequestStatus.None);
        } catch (error) {
          handleHttpErrors(error) && setInspectionWorkOrderStatus(NetworkRequestStatus.LoadError);
        }
    };
    loadWorkOrder();

    return () => abortController.abort();
  }, [props.governmentInspectionWorkOrderId, handleHttpErrors, inspectionWorkOrderReloadCount]);

  const handleResponseValidationError = (resp: UserFriendlyApiResponse) => {
    applyApiResponseValidationToFields(resp, [
      {
        formFieldId: 'itemResults' || 'fileIds' || 'governmentInspectionId',
        setStateFunc: (data) => data?.errorMessage && toast.error(data?.errorMessage[0])
      }
    ]);
  };

  const hasFailedValidation = inspectionItemResults.some((item) =>
    Object.values(item.validationResults).some((field) => field && field.error)
  );

  const performInspection = async () => {
    if (hasFailedValidation) return;
    try {
      setStatus(Status.Saving);

      const params: GovernmentInspectionWorkOrderPerformView = {
        fileIds: fileIds,
        itemResults: inspectionItemResults.map((item) => ({
          comment: item.comment ?? null,
          itemId: item.itemId!,
          nextInspectionDeadline: item.nextInspectionDeadline!,
          passed: item.passed!,
          performed: item.performed!
        }))
      };

      let workOrder: GovernmentInspectionWorkOrderReadView;
      if (props.governmentInspectionWorkOrderId) {
        workOrder = (
          await governmentInspectionApi.governmentInspectionWorkOrderResourcePerform({
            id: props.governmentInspectionWorkOrderId,
            governmentInspectionWorkOrderPerformView: params
          })
        ).data;
      } else {
        workOrder = (
          await governmentInspectionApi.governmentInspectionWorkOrderResourceCreateAndPerform({
            governmentInspectionWorkOrderCreateView: {
              governmentInspectionId: inspection!.id,
              ...params
            }
          })
        ).data;
      }
      props.onPerform(workOrder);
      setStatus(Status.None);
    } catch (error) {
      handleHttpErrors(error, { handleResponseValidationError });
      setStatus(Status.None);
    }
  };

  const onInspectionItemResultsChange = React.useCallback((state: InspectionItemResultState) => {
    setInspectionItemResults(state);
  }, []);

  const isSaving = status === Status.Saving;
  const isLocked = inspectionWorkOrder?.locked;
  return (
    <>
      <Modal
        open
        closeIcon={!isSaving}
        onClose={() => props.onClose(!isLocked)}
        size="large"
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t('inspections:performInspection')}</Modal.Header>

        <Modal.Content scrolling>
          <div id={'datepicker-portal'} />
          <Form>
            {props.governmentInspectionWorkOrderId === undefined && (
              <>
                {!props.category && (
                  <Form.Dropdown
                    label={t('common:category')}
                    floating
                    placeholder={t('common:category')}
                    value={category}
                    width={5}
                    onChange={(e, d) => {
                      if (inspection && inspection.category !== d.value) {
                        setInspection(undefined);
                      }
                      setCategory(d.value as GovernmentInspectionListItemViewCategoryEnum);
                    }}
                    search
                    fluid
                    selection
                    scrolling
                    options={generateGovernmentInspectionCategoryOptions()}
                    required
                    className={styles.dropdown}
                  />
                )}

                {!props.propertyId && (
                  <Form.Dropdown
                    label={t('common:property')}
                    placeholder={t('common:property')}
                    width={5}
                    floating
                    value={inspection?.id}
                    onChange={(e, d) => setInspection(inspections.find((item) => item.id === d.value))}
                    loading={inspectionStatus === NetworkRequestStatus.Loading}
                    disabled={inspectionStatus !== NetworkRequestStatus.None || !category}
                    error={
                      inspectionStatus === NetworkRequestStatus.LoadError &&
                      t('inspections:unableToLoadGovernmentInspections')
                    }
                    selection
                    search
                    options={mapGovernmentInspectionsToDropdownItems(inspections)}
                    required
                    className={styles.dropdown}
                  />
                )}
              </>
            )}

            <GovernmentInspectionWorkOrder
              incrementWorkOrderReloadCount={incrementWorkOrderReloadCount}
              onInspectionItemResultsChange={onInspectionItemResultsChange}
              status={inspectionWorkOrderStatus}
              initialInspectionItemResultState={initialInspectionItemResultState}
              inspection={inspection}
              itemResultDefaults={itemResultDefaults}
              disabled={isLocked}
            />

            <Form.Field>
              <label>{t('common:files')}</label>

              <FileAutoUploader
                noFilesUploadedMessage={<div>{t('inspections:atLeastOneFileNeedsToBeUploaded')}</div>}
                initFiles={initFiles as any}
                openFileSelectionNonce={fileSelectionCount}
                onStateChanged={(hasNonCompletedFiles) => {
                  setHasNonCompletedFiles(hasNonCompletedFiles);
                }}
                onFilesChanged={(fileIds) => {
                  setFileIds(fileIds);
                }}
                type={FileType.INSPECTION}
                subType={inspection && convertGovernmentInspectionCategoryToFileSubType(inspection.category)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions className={styles.buttonWrapper}>
          <div>
            <Button color="blue" onClick={() => setResultDefaultsModalOpen(true)} disabled={isSaving || isLocked}>
              {t('inspections:setResultsForAll')}
            </Button>

            <Button onClick={incrementFileSelectionCount} disabled={isSaving || isLocked}>
              {t('common:uploadFiles')}
            </Button>
          </div>

          <Button
            primary
            onClick={performInspection}
            disabled={hasNonCompletedFiles || hasFailedValidation || isSaving || fileIds.length === 0 || isLocked}
          >
            {t('inspections:complete')}
          </Button>
        </Modal.Actions>
      </Modal>

      {isResultDefaultsModalOpen && (
        <InspectionItemResultDefaultsModal
          onClose={() => setResultDefaultsModalOpen(false)}
          onConfirm={(data) => setItemResultDefaults(data)}
        />
      )}
    </>
  );
};

const GovernmentInspectionWorkOrder = (props: {
  status: NetworkRequestStatus;
  inspection?: GovernmentInspectionListItemView | undefined;
  itemResultDefaults?: Omit<GovernmentInspectionItemResultUpdateView, 'itemId'>;
  onInspectionItemResultsChange: (state: InspectionItemResultState) => void;
  initialInspectionItemResultState?: InspectionItemResultState;
  incrementWorkOrderReloadCount: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(['inspections']);
  switch (props.status) {
    case NetworkRequestStatus.LoadError:
      return (
        <LoadError
          message={t('inspections:unableToLoadGovernmentInspectionWorkOrder')}
          retry={props.incrementWorkOrderReloadCount}
        />
      );
    case NetworkRequestStatus.Loading:
      return <Loader active inline="centered" />;
    case NetworkRequestStatus.None:
      return (
        <GovernmentInspectionWorkOrderTable
          inspection={props.inspection}
          itemResultDefaults={props.itemResultDefaults}
          onChange={props.onInspectionItemResultsChange}
          initialState={props.initialInspectionItemResultState}
          disabled={props.disabled}
        />
      );
  }
};

export default PerformGovernmentInspectionModal;
