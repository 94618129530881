import React from 'react';
import ThumbnailPlaceholder from '../../../Utils/images/image-placeholder.jpeg';
import PropertyPlaceholder from '../../../Components/PropertyCard/images/placeholder.png';
import PromisePool from 'es6-promise-pool';
import { getCachedFileThumbnailUrl } from '../../../Services/FileService';

export const usePropertiesThumbnails = () => {
  const [propertiesThumbnails, setPropertiesThumbnails] = React.useState<{ [id: string]: string }>({});

  const loadThumbnails = React.useCallback(async (ids: string[], abortController?: AbortController) => {
    const fetchThumbnailUrl = async (id: string, abortController?: AbortController): Promise<void> => {
      return new Promise(async (resolve) => {
        try {
          const thumnbailUrl = await getCachedFileThumbnailUrl(
            {
              id: id,
              download: false
            },
            { signal: abortController?.signal }
          );
          setPropertiesThumbnails((prev) => {
            if (prev[id]) return prev;
            else
              return {
                ...prev,
                [id!]: thumnbailUrl
              };
          });
        } catch (error) {
          setPropertiesThumbnails((prev) => {
            return {
              ...prev,
              [id!]: ThumbnailPlaceholder
            };
          });
          // eslint-disable-next-line no-console
          console.log(error);
        } finally {
          resolve();
        }
      });
    };

    const generateThumbnailPromises = function* () {
      for (let count = 0; count < ids.length; count++) {
        yield fetchThumbnailUrl(ids[count], abortController);
      }
    };

    const promiseIterator = generateThumbnailPromises();
    // @ts-ignore
    const pool = new PromisePool(promiseIterator, 5);

    pool.start();
  }, []);

  const getPropertyImageUrl = (propertyImageUrl?: string) => {
    return propertyImageUrl ?? PropertyPlaceholder;
  };

  return { propertiesThumbnails, loadThumbnails, getPropertyImageUrl };
};
