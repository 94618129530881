import React from 'react';
import {
  MembershipReadView,
  MembershipReadViewRoleEnum,
  PropertySubscriptionReadView
} from '../../../GeneratedServices';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import {
  createInfinitePaginationParams,
  membershipApi,
  NetworkRequestStatus,
  propertySubscriptionApi
} from '../../../Http/Http';
import { useNonce } from '../../../Utils/hooks';

const filterMemberships = (memberships: MembershipReadView[], subscriptions: PropertySubscriptionReadView[]) => {
  return memberships.filter(
    (item) =>
      item.role === MembershipReadViewRoleEnum.Admin ||
      item.role === MembershipReadViewRoleEnum.Manager ||
      subscriptions.find((sub) => sub.userId === item.userId)
  );
};

export const useFaultReportReaders = (props: { propertyId?: number; organisationId?: number }) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [status, setStatus] = React.useState<NetworkRequestStatus>(NetworkRequestStatus.None);

  const [memberships, setMemberships] = React.useState<MembershipReadView[]>([]);
  const [shouldReloadMemberships, reloadMemberships] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadFaultReportReaders = async () => {
      try {
        setStatus(NetworkRequestStatus.Loading);

        const { data: subscriptions } = await propertySubscriptionApi.propertySubscriptionResourceList(
          {
            ...createInfinitePaginationParams(),
            propertyId: props.propertyId
          },
          {
            signal: abortController.signal
          }
        );

        const { data } = await membershipApi.membershipResourceList(
          {
            organisationId: props.organisationId,
            ...createInfinitePaginationParams()
          },
          {
            signal: abortController.signal
          }
        );

        setMemberships(filterMemberships(data.records, subscriptions.records));
        setStatus(NetworkRequestStatus.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(NetworkRequestStatus.LoadError);
      }
    };

    if (props.organisationId && props.propertyId) loadFaultReportReaders();

    return () => {
      abortController.abort();
    };
  }, [props.propertyId, props.organisationId, handleHttpErrors, shouldReloadMemberships]);

  return {
    memberships,
    status,
    reloadMemberships
  };
};
