import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Change import to functional component InfoBoxF when issue is resolved with auto pan positioning
import { InfoBox } from '@react-google-maps/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Header, Table } from 'semantic-ui-react';
import { translateComparablePropertyType } from '../../../../Services/ComparableService.types';
import { PropertyReadView } from '../../../../Services/PropertyService.types';
import { formatCurrency } from '../../../../Utils/number';
import NumberFormater from '../../../../Components/NumberFormater/NumberFormater';
import styles from './OverviewInfoBox.module.scss';
import { hasPermittedReadRoleInOrganisation } from '../../../../Utils/permissions';
import { globalStateCTX } from '../../../../GlobalState/GlobalState';

interface Props {
  selectedProperty: PropertyReadView;
  onClose: () => void;
}

const OverviewInfoBox: React.FC<Props> = (props) => {
  const { currentUser, currentUserMemberships } = React.useContext(globalStateCTX);
  const { t } = useTranslation(['common', 'analytics']);
  const isPermitted = hasPermittedReadRoleInOrganisation(
    currentUser,
    currentUserMemberships,
    props.selectedProperty.organisationId
  );

  const RowContent = ({ title, value }: { title: string; value: string | number | JSX.Element }) => {
    return (
      <>
        <Table.Cell className={styles.titleCell}>{title}</Table.Cell>
        <Table.Cell className={styles.valueCell}>{value}</Table.Cell>
      </>
    );
  };

  const getPropertyContent = (item: PropertyReadView) => {
    return (
      <Table basic="very" className={styles.table} compact singleLine>
        <Table.Body>
          <Table.Row>
            <RowContent title={t('common:leases')} value={item.statistics.leases} />
          </Table.Row>

          <Table.Row>
            <RowContent
              title={t('common:lettableArea')}
              value={<NumberFormater value={item.statistics.indoorArea} suffix={` ${t('common:sqm')}`} />}
            />
          </Table.Row>

          {isPermitted && (
            <>
              <Table.Row>
                <RowContent
                  title={t('common:baseRent')}
                  value={
                    <NumberFormater
                      {...formatCurrency(item.statistics.baseRentIndexed)}
                      additionalProps={{ decimalScale: 0 }}
                    />
                  }
                />
              </Table.Row>

              <Table.Row>
                <RowContent
                  title={t('common:baseRentSlashSqm')}
                  value={
                    item.statistics.baseRentPerAreaIndexed != null ? (
                      <NumberFormater
                        {...formatCurrency(item.statistics.baseRentPerAreaIndexed, { unit: t('common:sekSqm') })}
                        additionalProps={{ decimalScale: 0 }}
                      />
                    ) : (
                      '-'
                    )
                  }
                />
              </Table.Row>

              <Table.Row>
                <RowContent
                  title={t('common:totalRent')}
                  value={
                    <NumberFormater
                      {...formatCurrency(item.statistics.rentIndexed)}
                      additionalProps={{ decimalScale: 0 }}
                    />
                  }
                />
              </Table.Row>

              <Table.Row>
                <RowContent
                  title={t('common:totalRentSlashSqm')}
                  value={
                    item.statistics.rentPerAreaIndexed != null ? (
                      <NumberFormater
                        {...formatCurrency(item.statistics.rentPerAreaIndexed, { unit: t('common:sekSqm') })}
                        additionalProps={{ decimalScale: 0 }}
                      />
                    ) : (
                      '-'
                    )
                  }
                />
              </Table.Row>

              <Table.Row>
                <RowContent
                  title={'WAULT'}
                  value={
                    item.statistics.waultIndexed != null ? (
                      <NumberFormater value={item.statistics.waultIndexed} suffix={` ${t('common:years')}`} />
                    ) : (
                      '-'
                    )
                  }
                />
              </Table.Row>
            </>
          )}
        </Table.Body>
      </Table>
    );
  };

  return (
    <InfoBox
      options={{
        closeBoxURL: '',
        enableEventPropagation: false,
        boxStyle: {
          width: 'fit-content',
          pointerEvents: 'none'
        }
      }}
      position={new google.maps.LatLng(props.selectedProperty.position!)}
    >
      <Card centered className={styles.card}>
        <div className={styles.cardHeaderContainer}>
          <Header as="h4" className={styles.cardHeader}>
            {props.selectedProperty.name}

            <Header.Subheader>
              <div>{props.selectedProperty.address}</div>
              <div>{props.selectedProperty.municipality}</div>
            </Header.Subheader>
          </Header>

          <div className={styles.headerIconContainer}>
            <Button compact icon={<FontAwesomeIcon icon={faTimes} fixedWidth />} onClick={() => props.onClose()} />
          </div>
        </div>

        <Card.Content>
          <Card.Description>{getPropertyContent(props.selectedProperty)}</Card.Description>
        </Card.Content>

        <div className={styles.footer}>
          <div className={styles.footerContent}>
            {props.selectedProperty.type && translateComparablePropertyType(props.selectedProperty.type)}
          </div>

          <a
            href={`${window.location.origin}/properties/${props.selectedProperty.id}`}
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            {t('analytics:moreInfo')}
          </a>
        </div>
      </Card>
    </InfoBox>
  );
};

export default OverviewInfoBox;
