import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../../config';

import styles from './Map.module.scss';
import FullscreenControl from './ControlOptions/FullscreenControl';
import ZoomControl from './ControlOptions/ZoomControl';
import MapTypeControl from './ControlOptions/MapTypeControl';

interface Props {
  content?: (map: google.maps.Map | null) => unknown;
  roundedEdges?: boolean;
}

export const DEFAULT_COORDINATES = { lat: 59.3293, lng: 18.0686 };
type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const libraries: Libraries = ['drawing'];

const Map: React.FC<Props> = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries
  });

  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
    const maxBounds = new google.maps.LatLngBounds(new google.maps.LatLng(-85, -180), new google.maps.LatLng(85, 180));

    map.setOptions({
      center: DEFAULT_COORDINATES,
      zoom: 10,
      controlSize: 30,
      restriction: {
        latLngBounds: maxBounds,
        strictBounds: true
      },
      disableDefaultUI: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      keyboardShortcuts: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }]
        }
      ]
    });
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={styles.mapContainer}
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={5}
      mapContainerStyle={{ borderRadius: props.roundedEdges ? '5px' : undefined }}
    >
      <FullscreenControl map={map} />
      <ZoomControl map={map} />
      <MapTypeControl map={map} />
      <>{props.content && props.content(map)}</>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Map;
