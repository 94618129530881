import React from 'react';
import { Label, List, Popup } from 'semantic-ui-react';

interface Base {
  id: number;
}
interface Props<TValue> {
  list: TValue[];
  displayValueFunction: (data: TValue) => string;
}
const OrganisationLabel = <TValue extends Base>(props: Props<TValue>) => {
  const { list, displayValueFunction } = props;

  if (list.length !== 0) {
    if (list.length > 1)
      return (
        <Popup
          trigger={
            <Label size="large">
              {displayValueFunction(list[0])} +{list.length - 1}
            </Label>
          }
          position="bottom center"
          content={
            <List>
              {list?.map((item) => (
                <List.Item key={item.id}>{displayValueFunction(item)}</List.Item>
              ))}
            </List>
          }
        />
      );
    else return <Label size="large">{displayValueFunction(list[0])}</Label>;
  } else return null;
};

export default OrganisationLabel;
