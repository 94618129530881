import React from 'react';
import styles from './FileView.module.scss';
import * as FileService from '../../Services/FileService';
import { useDimensions, useNonce } from '../../Utils/hooks';
import CircleLoader from '../CircleLoader/CircleLoader';
import { transparent1x1Image } from '../../Utils/file';
import mime from 'mime/lite';
import { FileInfoReadView } from '../../Services/FileService.types';
import LoadError from '../LoadError';

interface Props {
  file: FileInfoReadView;
  onClosed: () => void;
}
enum Status {
  Loading,
  None,
  Error
}

const FileView: React.FC<Props> = (props) => {
  const fileMimeType = mime.getType(props.file.name);
  const isImage = fileMimeType?.startsWith('image/');

  const { ref: componentWrapperRef, dimensions } = useDimensions();

  const imageRef = React.useRef<HTMLImageElement>(null);
  const abortControllerRef = React.useRef<AbortController>();

  const [status, setStatus] = React.useState<Status>(Status.None);

  const [url, setUrl] = React.useState<string>();
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [retryNonce, setRetryNonce] = useNonce();

  const { onClosed: propsOnClosed } = props;

  const keyFunc = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        propsOnClosed();
      }
    },
    [propsOnClosed]
  );

  React.useEffect(() => {
    //reset the error/loader trackers when the url changes
    setError(false);
    setLoaded(false);
  }, [url, setError, setLoaded]);

  React.useLayoutEffect(() => {
    return () => {
      // Make <img> stop loading image when component dismounts.
      if (imageRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        imageRef.current.src = '';
      }
    };
  }, [props.file]);

  React.useEffect(() => {
    const prevOverflow = document.body.style.overflow;

    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', keyFunc, false);

    return () => {
      document.body.style.overflow = prevOverflow;
      document.removeEventListener('keydown', keyFunc, false);
    };
  }, [keyFunc]);

  React.useEffect(() => {
    abortControllerRef.current = new AbortController();

    const load = async () => {
      try {
        if (isImage || fileMimeType?.includes('pdf')) {
          setStatus(Status.Loading);
          const fileUrl = await FileService.getFileBlobUrl(
            { id: props.file.id, download: false },
            { signal: abortControllerRef.current!.signal }
          );
          setUrl(fileUrl);
          setStatus(Status.None);
        }
      } catch (error) {
        setStatus(Status.Error);
      }
    };

    load();

    return () => {
      abortControllerRef.current!.abort();
    };
  }, [props.file.id, retryNonce, isImage, fileMimeType]);

  const onLoad = () => {
    // Only set loaded to true when <img> has loaded image from url
    // and not from the transparent image used while fetching image blob url.
    if (url) {
      setLoaded(true);
    }
  };

  const onError = () => {
    setError(true);
  };

  const content = (
    <div className={styles.componentWrapper} ref={componentWrapperRef}>
      <div className={styles.imageContainer}>
        {!error && status === Status.None && (
          <FileViewer
            imageRef={imageRef}
            onError={onError}
            onLoad={onLoad}
            isImage={isImage}
            isPDF={fileMimeType?.includes('pdf')}
            url={url}
            maxHeight={dimensions?.height}
          />
        )}
        {(status === Status.Loading || (!loaded && !error)) && <CircleLoader size={'40px'} />}
        {(error || status === Status.Error) && (
          <div className={styles.error}>
            <LoadError message="Bilden kunde inte läsas in." retry={setRetryNonce} />
          </div>
        )}
      </div>
    </div>
  );

  return content;
};

const FileViewer = React.memo(
  (props: {
    isImage?: boolean;
    isPDF?: boolean;
    onLoad: () => void;
    onError: () => void;
    imageRef: React.RefObject<HTMLImageElement>;
    url?: string;
    maxHeight?: number;
    maxWidth?: number;
  }) => {
    if (props.isImage) {
      return (
        <img
          style={{ ...props }}
          ref={props.imageRef}
          src={props.url ?? transparent1x1Image()}
          alt=""
          onLoad={props.onLoad}
          onError={props.onError}
        />
      );
    } else if (props.isPDF) {
      return (
        <div style={{ height: props.maxHeight, maxWidth: props.maxWidth, width: '60vw' }}>
          <embed
            height="100%"
            width="100%"
            src={props.url + '#view=FitH'}
            onLoad={props.onLoad}
            onError={props.onError}
          />
        </div>
      );
    } else return null;
  }
);

export default FileView;
