/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface NotificationSettingReadView
 */
export interface NotificationSettingReadView {
  /**
   * Whether email notifications are enabled.
   * @type {boolean}
   * @memberof NotificationSettingReadView
   */
  emailEnabled: boolean;
  /**
   * ID.
   * @type {number}
   * @memberof NotificationSettingReadView
   */
  id: number;
  /**
   * Whether in-app notifications are enabled.
   * @type {boolean}
   * @memberof NotificationSettingReadView
   */
  inAppEnabled: boolean;
  /**
   * Organisation ID. Null if the notification type is global.
   * @type {number}
   * @memberof NotificationSettingReadView
   */
  organisationId: number | null;
  /**
   * Whether push notifications are enabled.
   * @type {boolean}
   * @memberof NotificationSettingReadView
   */
  pushEnabled: boolean;
  /**
   * Notification type.
   * @type {string}
   * @memberof NotificationSettingReadView
   */
  type: NotificationSettingReadViewTypeEnum;
}

export const NotificationSettingReadViewTypeEnum = {
  AddedOrRemovedOrganisation: 'ADDED_OR_REMOVED_ORGANISATION',
  ConnectedOrDisconnectedProperty: 'CONNECTED_OR_DISCONNECTED_PROPERTY',
  NewErrorReport: 'NEW_ERROR_REPORT',
  NewErrorReportLog: 'NEW_ERROR_REPORT_LOG',
  FaultReportStatusChanged: 'FAULT_REPORT_STATUS_CHANGED',
  ExpiringContract: 'EXPIRING_CONTRACT',
  MissedGovernmentInspectionWorkOrder: 'MISSED_GOVERNMENT_INSPECTION_WORK_ORDER',
  CompleteGovernmentInspectionWorkOrder: 'COMPLETE_GOVERNMENT_INSPECTION_WORK_ORDER',
  UpcomingGovernmentInspectionWorkOrder: 'UPCOMING_GOVERNMENT_INSPECTION_WORK_ORDER',
  PropertyCheckDue: 'PROPERTY_CHECK_DUE',
  ErrorReportAssignment: 'ERROR_REPORT_ASSIGNMENT',
  AddedOrRemovedPortfolio: 'ADDED_OR_REMOVED_PORTFOLIO'
} as const;

export type NotificationSettingReadViewTypeEnum =
  (typeof NotificationSettingReadViewTypeEnum)[keyof typeof NotificationSettingReadViewTypeEnum];
