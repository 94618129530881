import React from 'react';
import * as FileService from '../../Services/FileService';
import { Icon } from 'semantic-ui-react';
import { filenameExtension, getBase64FromReaderResult, transparent1x1Image } from '../../Utils/file';
import { useIsMounted } from '../../Utils/hooks';
import CircleLoader from '../CircleLoader/CircleLoader';
import Positioned from '../Positioned/Positioned';
import ActionBadge from '../ActionBadge/ActionBadge';
import styles from './FileAutoUploaderItem.module.scss';
import { FileInfoReadView, FileSubType, FileType } from '../../Services/FileService.types';
import FileViewer from '../FileViewer/FileViewer';
import colors from '../../Utils/Colors/Colors.module.scss';
import axios from 'axios';

export interface FileItem {
  tempId: string;
  id?: string;
  startNonce?: string;
  content: File;
  name: string;
  fileSize: number;
  deleteAction: 'DELETE' | 'MARK_FOR_DELETION';
}

interface Props {
  /**
   * When this nonce is changed, the component tries to reload
   * image from local file system, or tries to upload, depending
   * on the last error. When undefined, component is loaded
   * from local file system only (not uploaded). The upload
   * starts when the parent component says so by setting
   * this value to any string value.
   */
  startNonce?: string;
  file: FileItem;
  type?: FileType;
  subType?: FileSubType;
  onLoaded: (tempId: string) => void;
  onUploaded: (tempId: string, id: string) => void;
  onError: (tempId: string) => void;
  onDeleted: (tempId: string) => void;
  onDeleting: (tempId: string) => void;

  /**
   * Callback for when user manually clicks the retry button.
   * The parent component then puts this image in the "upload queue",
   * and starts it (sets "startNonce") when the "upload pool" has free entries.
   */
  onRetry: (tempId: string) => void;
}

enum Status {
  None = 'None',
  Loading = 'Loading',
  LoadError = 'LoadError',
  ReadyForUpload = 'ReadyForUpload',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  UploadError = 'UploadError',
  Deleting = 'Deleting',
  DeleteError = 'DeleteError'
}

/**
 * This components auto loads file if it is an image. For non-image
 * files the loading occurs just before it is uploaded. Non-image files are
 * instantly flagged as ReadyForUpload.
 *
 * The parent component decides when files are uploaded by updating the
 * startNonce prop.
 *
 * If any error occurs during load/upload the user manually need to click
 * a retry icon to inform the parent component to retry this file again.
 */
const FileAutoUploaderItem: React.FC<Props> = (props) => {
  const isImage = props.file.content.type.startsWith('image/');

  const isMounted = useIsMounted();
  const componentWrapperRef = React.useRef<HTMLDivElement>(null);
  const readerRef = React.useRef<FileReader>();
  const abortControllerRef = React.useRef<AbortController>();

  const [percent, setPercent] = React.useState(0);
  const [content, setContent] = React.useState<string>();
  const [uploaded, setUploaded] = React.useState(false);
  const [file, setFile] = React.useState<FileInfoReadView>();
  const [url, setUrl] = React.useState<string>();
  const [status, setStatus] = React.useState(Status.None);
  const [imageViewerOpened, setImageViewerOpened] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  const { onLoaded: propsOnLoaded } = props;

  // Cancel load/upload when component unmounts.
  React.useLayoutEffect(() => {
    readerRef.current = new FileReader();
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current?.abort();
      readerRef.current?.abort();
    };
  }, []);

  // Loads files from disk or uploads it depending on current status.
  React.useEffect(() => {
    switch (status) {
      case Status.None:
      case Status.LoadError:
        if (isImage) {
          // For image file, start loading from disk.
          // (Images are displayed in the "thumbnail" element).
          loadFileFromDisk();
        } else {
          // Non-image files are not loaded from disk as soon as the
          // component is first rendered (to save RAM if a lot of large
          // non-image files are chosen). Instead, flag file ready for upload.
          // This will tell parent component to start uploading
          // the file as soon as one empty entry exists in the "upload pool".
          setStatus(Status.ReadyForUpload);
          propsOnLoaded(props.file.tempId);
        }
        break;

      case Status.ReadyForUpload:
        if (isImage) {
          startUpload();
        } else if (!content) {
          loadFileFromDisk();
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startNonce]);

  // Content set and ready for upload?
  React.useEffect(() => {
    if (content !== undefined && status === Status.ReadyForUpload) {
      if (isImage) {
        // When image has been loaded from disk, call onLoaded callback
        // to tell parent component to start uploading the file as soon as
        // one empty entry exists in the "upload pool".
        propsOnLoaded(props.file.tempId);
      } else {
        // For non-image file, immediately start the upload.
        startUpload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, status]);

  // Sets CSS variables to increment the progress bar when percent changes.
  React.useEffect(() => {
    if (isMounted()) {
      componentWrapperRef.current?.style.setProperty('--progress-text', `"${percent}%"`);
      componentWrapperRef.current?.style.setProperty('--progress-value', `${percent}%`);
    }
  }, [isMounted, percent]);

  /**
   * Loads file content from disk.
   * todo: Note that larger files might freeze the browser.
   * todo: Will be fixed later on when file is uploaded in chunks.
   */
  const loadFileFromDisk = async (): Promise<void> => {
    setStatus(Status.Loading);

    if (readerRef.current) {
      readerRef.current.onload = async () => {
        setStatus(Status.ReadyForUpload);
        setContent(readerRef.current!.result as string);
      };

      readerRef.current.onerror = (error) => {
        if (isMounted()) {
          setStatus(Status.LoadError);
          if (axios.isCancel(error)) {
            props.onError(props.file.tempId);
          }
        }
      };

      readerRef.current.onabort = (error) => {
        if (isMounted() && !axios.isCancel(error)) {
          props.onError(props.file.tempId);
        }
      };

      readerRef.current.readAsDataURL(props.file.content);
    }
  };

  const startUpload = async () => {
    if (!abortControllerRef.current) {
      return;
    }

    try {
      // Start upload and report uploaded bytes progressively.
      setStatus(Status.Uploading);
      const result = await FileService.uploadFile(
        {
          blobBase64: getBase64FromReaderResult(content!)!,
          name: props.file.content.name,
          type: props.type,
          subType: props.subType
        },
        {
          signal: abortControllerRef.current.signal,
          onUploadProgress: (e: ProgressEvent) => {
            const percentUploaded = Math.trunc((e.loaded / content!.length) * 100);
            setPercent(percentUploaded);
          }
        }
      );

      setUrl(await FileService.getFileBlobUrl({ id: result.id }, { signal: abortControllerRef.current.signal }));

      if (isMounted()) {
        setFile(result);
        setStatus(Status.Uploaded);
        setUploaded(true);

        props.onUploaded(props.file.tempId, result.id);

        // If file is not an image, remove content to free up memory.
        if (!isImage) {
          setContent(undefined);
        }
      }
    } catch (error) {
      if (isMounted()) {
        setStatus(Status.UploadError);

        // Only notify listeners if error is not caused by user manually canceling the upload request.
        if (!axios.isCancel(error)) {
          props.onError(props.file.tempId);
        }
      }
    }
  };

  const startDelete = async () => {
    if (status === Status.Deleting) {
      return;
    }

    try {
      setStatus(Status.Deleting);
      abortControllerRef.current?.abort();

      if (file) {
        props.onDeleting(props.file.tempId);
        await FileService.deleteFile(file.id, {});
      }
    } catch (error) {
      // If API responds with an error trying to delete the file,
      // or if network connection is down or similar, ignore error
      // and call onDeleted callback to get item removed.
      //
      // File will later be garbage collected on server.
    } finally {
      if (isMounted()) {
        props.onDeleted(props.file.tempId);
      }
    }
  };

  const resolveImageData = () => {
    // For non-image, use a transparent 1x1 image (src is needed in order to remove border).
    return isImage && content ? content : transparent1x1Image();
  };

  const displayExtension = () => {
    // If image, display if image is not loaded.
    // If non image, display always.
    return isImage ? !content : true;
  };

  const download = () => {
    if (url) {
      window.location.assign(url!);
    }
  };

  return (
    <div
      ref={componentWrapperRef}
      className={styles.componentWrapper}
      data-status={status}
      title={props.file.name}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Positioned position="TOP-RIGHT" dx={'1.2em'} dy={'-50%'} zIndex={1}>
        <div style={{ display: 'flex' }}>
          {status === Status.Deleting ? (
            <ActionBadge
              icon="spinner"
              spinning
              backgroundColor={colors.black}
              title="Tar bort..."
              semiTransparent={false}
            />
          ) : (
            <>
              {hovering && (
                <ActionBadge icon="download" title="Ladda ner" backgroundColor={colors.blue1} onClick={download} />
              )}
              {hovering && (
                <ActionBadge icon="delete" backgroundColor={colors.black} title="Ta bort" onClick={startDelete} />
              )}
            </>
          )}
        </div>
      </Positioned>

      <div
        className={styles.imageWrapper}
        onClick={() => (status === Status.Uploaded ? setImageViewerOpened(true) : undefined)}
      >
        <img src={resolveImageData()} alt="" />

        {displayExtension() && (
          <span className={styles.fileNameExtension}>{filenameExtension(props.file.name) ?? ''}</span>
        )}

        {[Status.Loading, Status.ReadyForUpload].includes(status) && (
          <CircleLoader
            size="3em"
            dy="-0.9em"
            title={content === undefined ? 'Förbereder för uppladdning...' : 'Väntar på uppladdning...'}
          />
        )}

        {uploaded && (
          <Icon
            className={styles.uploadFinishedIcon}
            name="check"
            color="green"
            circular
            fitted
            title="Nyligen uppladdad"
          />
        )}

        {[Status.LoadError, Status.UploadError].includes(status) && (
          <div className={styles.error} title={'Fel vid uppladdning'}>
            <Icon
              name="redo"
              fitted
              className="clear-icon"
              onClick={() => {
                setStatus(Status.ReadyForUpload);
                props.onRetry(props.file.tempId);
              }}
            />
          </div>
        )}
      </div>

      {imageViewerOpened && file && <FileViewer file={file} onClosed={() => setImageViewerOpened(false)} />}
    </div>
  );
};

export default React.memo(FileAutoUploaderItem);
