/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ContractPaymentReadView
 */
export interface ContractPaymentReadView {
  /**
   * True if the payment is not considered part of the base rent .
   * @type {boolean}
   * @memberof ContractPaymentReadView
   */
  additional: boolean;
  /**
   * Type of the additional payment (null unless additional = true).
   * @type {string}
   * @memberof ContractPaymentReadView
   */
  additionalType: ContractPaymentReadViewAdditionalTypeEnum;
  /**
   * Payment amount.
   * @type {number}
   * @memberof ContractPaymentReadView
   */
  amount: number | null;
  /**
   * Area covered by the payment (null unless additional = false).
   * @type {number}
   * @memberof ContractPaymentReadView
   */
  area: number | null;
  /**
   * Type of the area (null unless additional = false).
   * @type {string}
   * @memberof ContractPaymentReadView
   */
  areaType: ContractPaymentReadViewAreaTypeEnum;
  /**
   * Contract.
   * @type {number}
   * @memberof ContractPaymentReadView
   */
  contractId: number;
  /**
   * Description.
   * @type {string}
   * @memberof ContractPaymentReadView
   */
  description: string;
  /**
   * End date.
   * @type {string}
   * @memberof ContractPaymentReadView
   */
  endDate: string | null;
  /**
   * Contract payment ID.
   * @type {number}
   * @memberof ContractPaymentReadView
   */
  id: number;
  /**
   * Indexed payment amount.
   * @type {number}
   * @memberof ContractPaymentReadView
   */
  indexedAmount: number | null;
  /**
   * Organisation.
   * @type {number}
   * @memberof ContractPaymentReadView
   */
  organisationId: number;
  /**
   * Start date.
   * @type {string}
   * @memberof ContractPaymentReadView
   */
  startDate: string;
}

export const ContractPaymentReadViewAdditionalTypeEnum = {
  Other: 'OTHER',
  PropertyTax: 'PROPERTY_TAX',
  RentReduction: 'RENT_REDUCTION',
  Maintenance: 'MAINTENANCE',
  LandLease: 'LAND_LEASE',
  AdditionalRent: 'ADDITIONAL_RENT',
  Heating: 'HEATING',
  Electricity: 'ELECTRICITY',
  WaterAndSewage: 'WATER_AND_SEWAGE',
  CoolingAndVentilation: 'COOLING_AND_VENTILATION',
  Waste: 'WASTE',
  SnowRemoval: 'SNOW_REMOVAL',
  ChargedCost: 'CHARGED_COST',
  ClaimAndDelay: 'CLAIM_AND_DELAY',
  ParkingAndGarage: 'PARKING_AND_GARAGE',
  TenantImprovements: 'TENANT_IMPROVEMENTS',
  Deposit: 'DEPOSIT'
} as const;

export type ContractPaymentReadViewAdditionalTypeEnum =
  (typeof ContractPaymentReadViewAdditionalTypeEnum)[keyof typeof ContractPaymentReadViewAdditionalTypeEnum];
export const ContractPaymentReadViewAreaTypeEnum = {
  Residence: 'RESIDENCE',
  Store: 'STORE',
  Garage: 'GARAGE',
  IndustrialOffice: 'INDUSTRIAL_OFFICE',
  Office: 'OFFICE',
  Warehouse: 'WAREHOUSE',
  Land: 'LAND',
  Parking: 'PARKING',
  Production: 'PRODUCTION',
  Restaurant: 'RESTAURANT',
  Other: 'OTHER',
  NotAnArea: 'NOT_AN_AREA'
} as const;

export type ContractPaymentReadViewAreaTypeEnum =
  (typeof ContractPaymentReadViewAreaTypeEnum)[keyof typeof ContractPaymentReadViewAreaTypeEnum];
