/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { EmbeddedGovernmentInspectionPropertyReadView } from './embedded-government-inspection-property-read-view';
import { GovernmentInspectionItemReadView } from './government-inspection-item-read-view';

/**
 *
 * @export
 * @interface GovernmentInspectionListItemView
 */
export interface GovernmentInspectionListItemView {
  /**
   * Government inspection category.
   * @type {string}
   * @memberof GovernmentInspectionListItemView
   */
  category: GovernmentInspectionListItemViewCategoryEnum;
  /**
   * Government inspection ID.
   * @type {number}
   * @memberof GovernmentInspectionListItemView
   */
  id: number;
  /**
   * Government inspection items.
   * @type {Array<GovernmentInspectionItemReadView>}
   * @memberof GovernmentInspectionListItemView
   */
  items: Array<GovernmentInspectionItemReadView>;
  /**
   *
   * @type {EmbeddedGovernmentInspectionPropertyReadView}
   * @memberof GovernmentInspectionListItemView
   */
  property: EmbeddedGovernmentInspectionPropertyReadView;
  /**
   * Property ID.
   * @type {number}
   * @memberof GovernmentInspectionListItemView
   */
  propertyId: number;
}

export const GovernmentInspectionListItemViewCategoryEnum = {
  Ovk: 'OVK',
  Elevator: 'ELEVATOR',
  Door: 'DOOR',
  LiftTable: 'LIFT_TABLE',
  LoadingDock: 'LOADING_DOCK',
  AuditInspectionFireAlarm: 'AUDIT_INSPECTION_FIRE_ALARM',
  AuditInspectionSprinkler: 'AUDIT_INSPECTION_SPRINKLER',
  ElectricityAudit: 'ELECTRICITY_AUDIT',
  Refrigerant: 'REFRIGERANT',
  OilSeparator: 'OIL_SEPARATOR',
  SbaSystematicFireProtectionWork: 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK',
  EnergyDeclaration: 'ENERGY_DECLARATION',
  PressureVessels: 'PRESSURE_VESSELS',
  Shelter: 'SHELTER'
} as const;

export type GovernmentInspectionListItemViewCategoryEnum =
  (typeof GovernmentInspectionListItemViewCategoryEnum)[keyof typeof GovernmentInspectionListItemViewCategoryEnum];
