import React from 'react';
import { Icon, Label, Menu } from 'semantic-ui-react';
import { notificationStateCTX } from '../../../NotificationState/NotificationState';
import NotificationsPopup from './NotificationsPopup';
import { Status } from '../../../NotificationState/NotificationState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell as lightFaBell } from '@fortawesome/pro-light-svg-icons';
import { faBell as solidFaBell } from '@fortawesome/pro-solid-svg-icons';

const NotificationMenuItem: React.FC = () => {
  const { status, unseenCount, notifications, totalCount, onRetry, onLoadMore, updateToSeen } =
    React.useContext(notificationStateCTX);
  const [notificationPopupVisible, setNotificationPopupVisible] = React.useState(false);

  return (
    <>
      <Menu.Item onClick={() => setNotificationPopupVisible(true)}>
        <Icon color={status === Status.LoadError ? 'red' : undefined} inverted size="large">
          <FontAwesomeIcon icon={status !== Status.LoadError ? lightFaBell : solidFaBell} />
        </Icon>
        {unseenCount > 0 && status !== Status.LoadError && (
          <Label as="label" circular color="red" size="mini">
            {unseenCount}
          </Label>
        )}
      </Menu.Item>

      {notificationPopupVisible && (
        <NotificationsPopup
          notifications={notifications}
          hasMore={notifications.length < totalCount}
          status={status}
          top={70}
          right={50}
          onRetry={onRetry}
          onLoadMore={onLoadMore}
          onSeen={updateToSeen}
          onClose={() => setNotificationPopupVisible(false)}
        />
      )}
    </>
  );
};

export default NotificationMenuItem;
