import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header } from 'semantic-ui-react';
import EditGovernmentInspectionItemModal from '../../../Components/GovernmentInspections/Edit/EditGovernmentInspectionItemModal';
import GovernmentInspectionItemsTable from '../../../Components/GovernmentInspections/GovernmentInspectionItemsTable';
import { GovernmentInspectionListItemView, PropertyReadView } from '../../../GeneratedServices';
import { useNonce } from '../../../Utils/hooks';
import PerformGovernmentInspectionModalGroup from '../../../Components/GovernmentInspections/Perform/PerformGovernmentInspectionModalGroup';
import { useSearchParams } from 'react-router-dom';
import { getSearchParam, governmentInspectionApi } from '../../../Http/Http';
import { parseNatural } from '../../../Utils/safe-parse';
import { globalStateCTX } from '../../../GlobalState/GlobalState';

interface Props {
  property: PropertyReadView;
}

const TabGovernmentInspections: React.FC<Props> = (props) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const [searchParams] = useSearchParams();
  const inspectionId = parseNatural(getSearchParam(searchParams, 'inspectionId'));
  const inspectionWorkOrderId = parseNatural(getSearchParam(searchParams, 'governmentInspectionWorkOrderId'));

  const [inspection, setInspection] = React.useState<GovernmentInspectionListItemView>();

  const { t } = useTranslation(['common', 'inspections']);

  const [isCreateInspectionItemModalOpen, setCreateInspectionItemModalOpen] = React.useState<boolean>(false);

  const [isPerformInspectionModalOpen, setPerformInspectionModalOpen] = React.useState<boolean>(
    inspectionWorkOrderId !== undefined
  );

  const [reloadCount, incrementReloadCount] = useNonce();

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadInspectionData = async () => {
      try {
        if (inspectionId) {
          const { data } = await governmentInspectionApi.governmentInspectionResourceRead(
            {
              id: inspectionId
            },
            { signal: abortController.signal }
          );

          setInspection(data);
          setPerformInspectionModalOpen(true);
        }
      } catch (error) {
        handleHttpErrors(error);
      }
    };

    loadInspectionData();

    return () => abortController.abort();
  }, [inspectionId, handleHttpErrors]);

  const params = React.useMemo(() => ({ propertyId: props.property.id }), [props.property]);
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Header as="h2">{t('common:inspections')}</Header>
        <div>
          <Button color="blue" onClick={() => setCreateInspectionItemModalOpen(true)}>
            {t('inspections:addInspectionObject')}
          </Button>

          <Button primary onClick={() => setPerformInspectionModalOpen(true)}>
            {t('inspections:performInspection')}
          </Button>
        </div>
      </div>

      <GovernmentInspectionItemsTable reloadCount={reloadCount} governmentInspectionParams={params} />

      {isCreateInspectionItemModalOpen && (
        <EditGovernmentInspectionItemModal
          property={props.property}
          onClose={() => setCreateInspectionItemModalOpen(false)}
          onSave={incrementReloadCount}
          type="CREATE"
        />
      )}

      {isPerformInspectionModalOpen && (
        <PerformGovernmentInspectionModalGroup
          onClose={() => setPerformInspectionModalOpen(false)}
          onPerform={incrementReloadCount}
          {...(inspectionWorkOrderId
            ? { governmentInspectionWorkOrderId: inspectionWorkOrderId }
            : {
                governmentInspectionWorkOrderId: undefined,
                category: inspection?.category,
                propertyId: props.property.id
              })}
        />
      )}
    </>
  );
};

export default TabGovernmentInspections;
