import { faSlidersUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Label } from 'semantic-ui-react';

interface Props {
  openFiltersModal: () => void;
  count: number;
}
const FilterButton: React.FC<Props> = (props) => {
  return (
    <Button icon onClick={props.openFiltersModal} style={{ position: 'relative' }}>
      <FontAwesomeIcon fixedWidth icon={faSlidersUp} />
      {props.count > 0 && (
        <Label as="label" circular color="blue" floating>
          {props.count}
        </Label>
      )}
    </Button>
  );
};

export default FilterButton;
