import { Bar } from '@nivo/bar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FinancialReportReadView } from '../../../GeneratedServices';
import { generatePeriodLabel } from '../../../Services/FinancialReportService';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { useDidMountEffect, useDimensions } from '../../../Utils/hooks';
import { formatCurrency, formatNumber } from '../../../Utils/number';
import { FinancialStatisticsFilterType } from '../FinancialStatistics';
import styles from './FinancialStatisticsBarGraph.module.scss';
import FinancialStatisticsCustomTooltip from './FinancialStatisticsCustomTooltip';

interface Props {
  financialStatistics: FinancialReportReadView[];
  onBarClick: (value: string) => void;
  areStatisticsPerArea: boolean;
  selectedStatisticsType: FinancialStatisticsFilterType[];
  selectedLabels: string[];
}

const formatDataForGraph = (label: string, actual: number, planned: number) => {
  return {
    label,
    actual,
    planned
  };
};

const getBarsPerWidth = (width: number) => {
  return (width - 160) / 100;
};
const getPaddedData = (
  data: {
    label: string;
    actual: number;
    planned: number;
  }[],
  width: number
) => {
  // In order to position elements on the right of the chart while having only a few periods, we add up to 5 blank periods to mimic the vertical grid lines
  const difference = getBarsPerWidth(width) - data.length;
  if (difference > 0) {
    let newArray: {
      label: string;
      actual: number;
      planned: number;
    }[] = [];
    for (var i = difference; i > 0; i--) {
      newArray.push({ label: 'ghostLabel' + i, actual: 0, planned: 0 });
    }
    return [...newArray, ...data];
  } else return data;
};

const getValuePerType = (filter: FinancialStatisticsFilterType, item: FinancialReportReadView) => {
  switch (filter) {
    case FinancialStatisticsFilterType.Income:
      return item.totalIncome;
    case FinancialStatisticsFilterType.CapexAndTi:
      return item.capexAndTi;
    case FinancialStatisticsFilterType.Maintenance:
      return item.maintenance;
    case FinancialStatisticsFilterType.Management:
      return item.propertyManagement;
    case FinancialStatisticsFilterType.OPEX:
      return item.OPEX;
  }
};

const FinancialStatisticsBarGraph: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const { ref, dimensions } = useDimensions();

  const scrollableContainerRef = React.useRef<HTMLDivElement>(null);

  useDidMountEffect(() => {
    if (scrollableContainerRef.current) {
      setTimeout(() => {
        scrollableContainerRef.current?.scroll({
          behavior: 'smooth',
          left: scrollableContainerRef.current.scrollWidth
        });
      }, 500);
    }
  }, [scrollableContainerRef.current, props.financialStatistics]);

  const getData = () => {
    return props.financialStatistics.map((item) => {
      let totalActualValue = 0;
      let totalPlannedValue = 0;
      props.selectedStatisticsType.forEach((filter) => {
        totalActualValue += getValuePerType(filter, item).actual;
        totalPlannedValue += getValuePerType(filter, item).planned;
      });

      return formatDataForGraph(generatePeriodLabel(item), totalActualValue, totalPlannedValue);
    });
  };

  const paddedData = getPaddedData(getData(), dimensions?.width ?? 0);

  let minValue = 0;
  let maxValue = 0;

  const minActualValue = Math.min(...paddedData.map((item) => item.actual));
  const minPlannedValue = Math.min(...paddedData.map((item) => item.planned));
  const maxActualValue = Math.max(...paddedData.map((item) => item.actual));
  const maxPlannedValue = Math.max(...paddedData.map((item) => item.planned));

  if (minActualValue < minValue || minPlannedValue < minValue) {
    if (minActualValue < minPlannedValue) minValue = minActualValue;
    else minValue = minPlannedValue;
  }

  if (maxActualValue > maxValue || maxPlannedValue > maxValue) {
    if (maxActualValue > maxPlannedValue) maxValue = maxActualValue;
    else maxValue = maxPlannedValue;
  }

  const commonProps = {
    data: paddedData,
    keys: ['actual', 'planned'],
    gridYValues: 6,
    // Leave some more room above and below the bars
    minValue: minValue !== 0 ? minValue * 1.2 : maxValue * -0.1,
    maxValue: maxValue !== 0 ? maxValue * 1.2 : minValue * -0.1
  };

  const leftAxisWidth = props.areStatisticsPerArea ? 100 : 90;
  return (
    <div ref={ref} style={{ height: '100%' }}>
      <div className={styles.barContainer} style={{ height: dimensions?.height }}>
        <div style={{ width: leftAxisWidth }}>
          <Bar
            {...commonProps}
            layers={['axes']}
            height={dimensions?.height ?? 0}
            width={leftAxisWidth}
            axisBottom={null}
            margin={{ top: 10, right: 0, bottom: 40, left: leftAxisWidth }}
            axisLeft={{
              tickValues: 6,
              format: function (value) {
                const currency = formatCurrency(value, {
                  unit: props.areStatisticsPerArea ? t('sekSqm') : undefined,
                  forceCurrency: props.areStatisticsPerArea ? 'SEK' : 'KSEK'
                });
                return formatNumber(currency.value) + currency.suffix;
              }
            }}
          />
        </div>

        <div className={styles.scrollableBarContainer} ref={scrollableContainerRef}>
          <Bar
            {...commonProps}
            colors={(bar) => {
              return bar.indexValue.toString().includes('*')
                ? bar.id === 'actual'
                  ? colors.chart9
                  : colors.chart8
                : bar.id === 'actual'
                ? colors.chart4
                : colors.chart1;
            }}
            indexBy="label"
            height={dimensions?.height ?? 400}
            width={dimensions?.width ? dimensions.width - leftAxisWidth : 0}
            enableGridX
            groupMode="grouped"
            onClick={(e) => props.onBarClick(e.data.label)}
            valueScale={{ type: 'linear' }}
            padding={0.2}
            //Alternate between fixed width and responsive component based on the amount of entities in the data
            {...(paddedData.length > 6 && { width: paddedData.length * 100 })}
            axisBottom={{
              format: (value: string) => (value.includes('ghostLabel') ? '' : value)
            }}
            indexScale={{ type: 'band', round: true }}
            margin={{ top: 10, right: 0, bottom: 40, left: 0 }}
            enableLabel={false}
            motionConfig="gentle"
            axisLeft={null}
            defs={[
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255,255,255,0.5)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={props.selectedLabels.map((item) => ({
              match: {
                indexValue: item
              },
              id: 'lines'
            }))}
            theme={{
              grid: {
                line: {
                  strokeWidth: 2,
                  strokeDasharray: '3 3'
                }
              }
            }}
            tooltip={(tooltip) => {
              return (
                <FinancialStatisticsCustomTooltip
                  areStatisticsPerArea={props.areStatisticsPerArea}
                  financialStatistics={props.financialStatistics}
                  selectedStatisticsType={props.selectedStatisticsType}
                  tooltipProps={tooltip}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialStatisticsBarGraph;
