import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Loader, Table } from 'semantic-ui-react';
import LoadError from '../../../../Components/LoadError';
import OverlayLoader from '../../../../Components/OverlayLoader';
import UserLabels from '../../../../Components/UserLabels/UserLabels';
import { WorkOrderApiWorkOrderResourceListRequest, WorkOrderReadViewStatusEnum } from '../../../../GeneratedServices';
import { NetworkRequestStatus } from '../../../../Http/Http';
import { translateWorkOrderCategory, translateWorkOrderStatus } from '../../../../Services/WorkOrderService.types';
import { toDateTimeString } from '../../../../Utils/date';
import { useFaultReports } from '../../Hooks/useFaultReports';

interface Props {
  filterParams: WorkOrderApiWorkOrderResourceListRequest;
  reloadCount?: number;
  includeProperty?: boolean;
}

const FaultReportTable: React.FC<Props> = (props) => {
  const { faultReports, status, reloadFaultReports } = useFaultReports({
    filterParams: props.filterParams,
    reloadCount: props.reloadCount
  });
  const { t } = useTranslation(['faultReports', 'common']);
  const navigate = useNavigate();

  const loadingLayout = <Loader active inline="centered" size="large" />;

  const loadErrorLayout = <LoadError message={t('faultReports:unableToLoadFaultReports')} retry={reloadFaultReports} />;

  const emptyLayout = <div>{t('faultReports:noFaultReportsFound')}</div>;

  const tableLayout = (
    <OverlayLoader loading={status === NetworkRequestStatus.Loading}>
      <div style={{ overflowX: 'auto' }}>
        <Table basic="very" selectable singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              {props.includeProperty && <Table.HeaderCell>{t('common:property')}</Table.HeaderCell>}
              <Table.HeaderCell>{t('common:tenant')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:created')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:status')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:category')}</Table.HeaderCell>
              <Table.HeaderCell>{t('faultReports:assignees')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:description')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {faultReports?.map((item) => (
              <Table.Row key={item.id} onClick={() => navigate(`/fault-reports/${item.id}`)}>
                <Table.Cell>
                  <span>
                    {item.caseNumber}{' '}
                    {item.status === WorkOrderReadViewStatusEnum.Received && (
                      <Label size="large" color="green">
                        {t('faultReports:new')}!
                      </Label>
                    )}
                  </span>
                </Table.Cell>
                {props.includeProperty && (
                  <Table.Cell>
                    <Link to={`/properties/${item.property.id}`} onClick={(e) => e.stopPropagation()}>
                      {item.property.name}
                    </Link>
                  </Table.Cell>
                )}
                <Table.Cell>
                  {item.tenant ? (
                    <Link to={`/tenants/${item.tenant.id}`} onClick={(e) => e.stopPropagation()}>
                      {item.tenant.name}
                    </Link>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
                <Table.Cell>{toDateTimeString(new Date(item.created))}</Table.Cell>
                <Table.Cell>
                  <Label size="large">{translateWorkOrderStatus(item.status)}</Label>
                </Table.Cell>
                <Table.Cell>
                  <Label size="large" style={{ whiteSpace: 'nowrap' }}>
                    {translateWorkOrderCategory(item.category)}
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  {item.assignees.length !== 0 ? (
                    <UserLabels
                      users={item.assignees.map((item) => ({ id: item.userId, name: item.userFullName }))}
                      displayedUsersLimit={2}
                    />
                  ) : (
                    '-'
                  )}
                </Table.Cell>
                <Table.Cell>
                  {item.description.slice(undefined, 50).concat(item.description.length > 51 ? '...' : '')}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </OverlayLoader>
  );

  const getLayout = () => {
    switch (status) {
      case NetworkRequestStatus.LoadError:
        return loadErrorLayout;
      case NetworkRequestStatus.Loading:
        return faultReports && faultReports.length > 0 ? tableLayout : loadingLayout;
      case NetworkRequestStatus.None:
        return faultReports && faultReports.length > 0 ? tableLayout : emptyLayout;
    }
  };
  return getLayout();
};

export default FaultReportTable;
