/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OrganisationDataSourceReadView
 */
export interface OrganisationDataSourceReadView {
  /**
   * Data source.
   * @type {string}
   * @memberof OrganisationDataSourceReadView
   */
  dataSource: OrganisationDataSourceReadViewDataSourceEnum;
  /**
   * Options for data source.
   * @type {string}
   * @memberof OrganisationDataSourceReadView
   */
  dataSourceOptions: string;
  /**
   * Unique identifier.
   * @type {number}
   * @memberof OrganisationDataSourceReadView
   */
  id: number;
  /**
   * When the data source was last run.
   * @type {string}
   * @memberof OrganisationDataSourceReadView
   */
  lastRun: string | null;
  /**
   * Organisation ID.
   * @type {number}
   * @memberof OrganisationDataSourceReadView
   */
  organisationId: number;
  /**
   * Run cycle.
   * @type {string}
   * @memberof OrganisationDataSourceReadView
   */
  runCycle: OrganisationDataSourceReadViewRunCycleEnum;
  /**
   * Sequence ID.
   * @type {number}
   * @memberof OrganisationDataSourceReadView
   */
  sequence: number;
  /**
   * Status.
   * @type {string}
   * @memberof OrganisationDataSourceReadView
   */
  status: OrganisationDataSourceReadViewStatusEnum;
}

export const OrganisationDataSourceReadViewDataSourceEnum = {
  Strifast: 'STRIFAST',
  Edison: 'EDISON',
  Dummy: 'DUMMY',
  Metry: 'METRY'
} as const;

export type OrganisationDataSourceReadViewDataSourceEnum =
  (typeof OrganisationDataSourceReadViewDataSourceEnum)[keyof typeof OrganisationDataSourceReadViewDataSourceEnum];
export const OrganisationDataSourceReadViewRunCycleEnum = {
  Daily: 'DAILY',
  None: 'NONE'
} as const;

export type OrganisationDataSourceReadViewRunCycleEnum =
  (typeof OrganisationDataSourceReadViewRunCycleEnum)[keyof typeof OrganisationDataSourceReadViewRunCycleEnum];
export const OrganisationDataSourceReadViewStatusEnum = {
  NotRun: 'NOT_RUN',
  Succeeded: 'SUCCEEDED',
  Failed: 'FAILED',
  InProgress: 'IN_PROGRESS'
} as const;

export type OrganisationDataSourceReadViewStatusEnum =
  (typeof OrganisationDataSourceReadViewStatusEnum)[keyof typeof OrganisationDataSourceReadViewStatusEnum];
