/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Values by addition type.
 * @export
 * @interface AdditionReadView
 */
export interface AdditionReadView {
  /**
   * Actual value of the addition type.
   * @type {number}
   * @memberof AdditionReadView
   */
  actualValue: number;
  /**
   * Type of the addition.
   * @type {string}
   * @memberof AdditionReadView
   */
  type: AdditionReadViewTypeEnum;
}

export const AdditionReadViewTypeEnum = {
  Other: 'OTHER',
  PropertyTax: 'PROPERTY_TAX',
  RentReduction: 'RENT_REDUCTION',
  Maintenance: 'MAINTENANCE',
  LandLease: 'LAND_LEASE',
  AdditionalRent: 'ADDITIONAL_RENT',
  Heating: 'HEATING',
  Electricity: 'ELECTRICITY',
  WaterAndSewage: 'WATER_AND_SEWAGE',
  CoolingAndVentilation: 'COOLING_AND_VENTILATION',
  Waste: 'WASTE',
  SnowRemoval: 'SNOW_REMOVAL',
  ChargedCost: 'CHARGED_COST',
  ClaimAndDelay: 'CLAIM_AND_DELAY',
  ParkingAndGarage: 'PARKING_AND_GARAGE',
  TenantImprovements: 'TENANT_IMPROVEMENTS',
  Deposit: 'DEPOSIT'
} as const;

export type AdditionReadViewTypeEnum = (typeof AdditionReadViewTypeEnum)[keyof typeof AdditionReadViewTypeEnum];
