import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { globalStateCTX } from './GlobalState/GlobalState';
import NotificationState from './NotificationState/NotificationState';
import Routes from './Navigation/Routes';
import { ComponentLoader } from './Components/ComponentLoader';
import AppShell from './Views/AppShell/AppShell';
import FiltersProvider from './FiltersProvider/FiltersProvider';

function App() {
  const globalState: React.ContextType<typeof globalStateCTX> = React.useContext(globalStateCTX);

  const isPublicWorkOrderRoute = window.location.pathname.includes('create-work-order-public');
  const isInPresentationMode = window.location.pathname.includes('share');
  return (
    <>
      {globalState.loading ? (
        <ComponentLoader />
      ) : (
        <Suspense fallback={<ComponentLoader />}>
          {globalState.isAuthenticated && !isPublicWorkOrderRoute && !isInPresentationMode ? (
            <NotificationState>
              <AppShell>
                <FiltersProvider>
                  <Routes />
                </FiltersProvider>
              </AppShell>
            </NotificationState>
          ) : (
            <Routes />
          )}
        </Suspense>
      )}
      <ToastContainer autoClose={3000} position="bottom-center" draggable={false} hideProgressBar />
    </>
  );
}

export default App;
