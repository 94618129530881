import { translateWorkOrderStatus, WorkOrderStatus } from '../Services/WorkOrderService.types';
import i18n from '../i18n';

export enum ResponseErrorCode {
  /**
   * Authorization header is missing or token is not valid.
   */
  R000001 = 'R000001',

  /**
   * Internal server error.
   */
  R000002 = 'R000002',

  /**
   * Request body is empty.
   */
  R000003 = 'R000003',

  /**
   * Validation failed.
   */
  R000004 = 'R000004',

  /**
   * Entity not found.
   */
  R000005 = 'R000005',

  /**
   * Offset is larger than total count.
   */
  R000006 = 'R000006',

  /**
   * Unrecognized property in the request body: %s.
   */
  R000007 = 'R000007',

  /**
   * Provided credentials are incorrect.
   */
  R000008 = 'R000008',

  /**
   * Password reset token is invalid or expired.
   */
  R000009 = 'R000009',

  /**
   * User must be a system administrator to send this request.
   */
  R000010 = 'R000010',

  /**
   * Current user has been deleted.
   */
  R000011 = 'R000011',

  /**
   * Email verification token is invalid or expired.
   */
  R000012 = 'R000012',

  /**
   * User email is not verified.
   */
  R000013 = 'R000013',

  /**
   * Current user is not allowed to grant/revoke given role in given organisation.
   */
  R000014 = 'R000014',

  /**
   * The last organisation administrator can not leave/be revoked.
   */
  R000015 = 'R000015',

  /**
   * Current user does not have the correct role in the organisation.
   */
  R000016 = 'R000016',

  /**
   * Can not create a work order log without any information.
   */
  R000017 = 'R000017',

  /**
   * Tenant must have an ongoing contract with the property.
   */
  R000018 = 'R000018',

  /**
   * Multiple matched tenants have an ongoing contract with the matched property.
   */
  R000019 = 'R000019',

  /**
   * Both entityType and entityId are required to attach a file to an entity.
   */
  R000020 = 'R000020',

  /**
   * Given sort order is missing an entity.
   */
  R000021 = 'R000021',

  /**
   * FCM token is already registered.
   */
  R000022 = 'R000022',

  /**
   * Requested analytics type is not supported.
   */
  R000023 = 'R000023',

  /**
   * Government inspection work order is not editable.
   */
  R000025 = 'R000025',

  /**
   * No thumbnail for the file extension.
   */
  R000026 = 'R000026',

  /**
   * Can not get thumbnail because the blob is not in image format.
   */
  R000027 = 'R000027',

  /**
   * Cannot delete government inspection that has already been completed.
   */
  R000028 = 'R000028',

  /**
   * File download token is invalid or expired.
   */
  R000029 = 'R000029',

  /**
   * Only 2000 rows can be imported at a time.
   */
  R000030 = 'R000030',

  /**
   * User is already subscribed to property.
   */
  R000031 = 'R000031',

  /**
   * User does not have the correct permission to assign users to fault report.
   */
  R000032 = 'R000032',

  /**
   * User can only delete their own fault report subscription.
   */
  R000033 = 'R000033',

  /**
   * User does not have the correct permission to subscribe to the fault report.
   */
  R000034 = 'R000034',

  /**
   * Fault report assignee can't unsubscribe.
   */
  R000035 = 'R000035',

  /**
   * User is already assigned to the fault report.
   */
  R000036 = 'R000036',

  /**
   * User is already subscribed to the fault report.
   */
  R000037 = 'R000037',

  /**
   * Only author can edit work order log.
   */
  R000038 = 'R000038',

  /**
   * Entity type does not support attaching files.
   */
  R000039 = 'R000039',

  /**
   * Only log where type=MESSAGE can be edited.
   */
  R000040 = 'R000040',

  /**
   * Only log where type=MESSAGE or type=TENANT_MESSAGE or type=STATUS_CHANGE can be created.
   */
  R000041 = 'R000041',

  /**
   * Failed to send email because attachments exceed 6MB.
   */
  R000042 = 'R000042',

  /**
   * Only a superuser can update the minimum supported mobile versions.
   */
  R000043 = 'R000043',

  /**
   * 	A superuser can't request account deletion.
   */
  R000044 = 'R000044',

  /**
   * Only fault reports where type=DEFAULT or type=FAILED_GOVERNMENT_INSPECTION can be created.
   */
  R000045 = 'R000045',

  /**
   * Only the lease with the latest generation for an object can be removed from a business plan.
   */
  R000046 = 'R000046'
}

export enum ResponseFieldErrorCode {
  /**
   * Field must not be empty.
   */
  F000001 = 'F000001',

  /**
   * Field is required.
   */
  F000002 = 'F000002',

  /**
   * Field size must be between {min} and {max}.
   */
  F000003 = 'F000003',

  /**
   * Field is not a well-formed email address.
   */
  F000004 = 'F000004',

  /**
   * Email is already in-use.
   */
  F000005 = 'F000005',

  /**
   * Current user must be a system administrator to update this field.
   */
  F000006 = 'F000006',

  /**
   * Organisation name must be unique.
   */
  F000007 = 'F000007',

  /**
   * Organisation does not exist.
   */
  F000008 = 'F000008',

  /**
   * User with this email is already invited to the organisation.
   */
  F000009 = 'F000009',

  /**
   * User with this email is already member in the organisation.
   */
  F000010 = 'F000010',

  /**
   * Given user does not have the correct role in the organisation.
   */
  F000011 = 'F000011',

  /**
   * Property does not exist.
   */
  F000012 = 'F000012',

  /**
   * Work order does not exist.
   */
  F000013 = 'F000013',

  /**
   * Work order is not allowed to transition from {from} to {to}.
   */
  F000014 = 'F000014',

  /**
   * Tenant does not exist.
   */
  F000015 = 'F000015',

  /**
   * Tenant must have an ongoing contract with the property.
   */
  F000016 = 'F000016',

  /**
   * User does not exist.
   */
  F000017 = 'F000017',

  /**
   * Property with given address does not exist.
   */
  F000018 = 'F000018',

  /**
   * Tenant with given name does not exist.
   */
  F000019 = 'F000019',

  /**
   * Contract does not exist.
   */
  F000020 = 'F000020',

  /**
   * Work order log does not exist.
   */
  F000021 = 'F000021',

  /**
   * File does not exist.
   */
  F000022 = 'F000022',

  /**
   * File is already attached to another entity.
   */
  F000025 = 'F000025',

  /**
   * Field must contain a valid base64-encoded string.
   */
  F000026 = 'F000026',

  /**
   * Government inspection must have at least one inspection item.
   */
  F000027 = 'F000027',

  /**
   * Government inspection item does not exist.
   */
  F000028 = 'F000028',

  /**
   * Government inspection for the property and the category already exists.
   */
  F000029 = 'F000029',

  /**
   * Approved item should not be included on fault report.
   */
  F000030 = 'F000030',

  /**
   * Fault report work order must have failed item and description.
   */
  F000032 = 'F000032',

  /**
   * At least one document must be uploaded in order to perform the government inspection.
   */
  F000034 = 'F000034',

  /**
   * Item can not be approved since the associated fault report has been started.
   */
  F000037 = 'F000037',

  /**
   * Item can not be excluded since the associated fault report has been started.
   */
  F000038 = 'F000038',

  /**
   * File type and sub type do not match.
   */
  F000039 = 'F000039',

  /**
   * Company does not exist.
   */
  F000040 = 'F000040',

  /**
   * Company is part of a different organisation than portfolio.
   */
  F000041 = 'F000041',

  /**
   * Cannot set time spent without finishing the WO at the same time.
   */
  F000042 = 'F000042',

  /**
   * * User must have Investor role in the organisation to be added to the portfolio.
   */
  F000043 = 'F000043',

  /**
   * The tenant name search returned too many results.
   */
  F000044 = 'F000044',

  /**
   * Government inspection item belongs to the different government inspection.
   */
  F000045 = 'F000045',

  /**
   * Files linked to government inspections must be inspection type.
   */
  F000046 = 'F000046',

  /**
   * Incorrect file subtype.
   */
  F000047 = 'F000047',

  /**
   * Government inspection WO does not exist.
   */
  F000048 = 'F000048',

  /**
   * Government inspection WO belongs to a different government inspection.
   */
  F000049 = 'F000049',

  /**
   * Item cannot be removed since the associated fault report has been started.
   */
  F000050 = 'F000050',

  /**
   * Cannot set deadline to before the previously completed inspection.
   */
  F000051 = 'F000051',

  /**
   * Unit does not exist.
   */
  F000052 = 'F000052',

  /**
   * Organisation data source options are not valid.
   */
  F000053 = 'F000053',

  /**
   * Metry API key is invalid.
   */
  F000054 = 'F000054',

  /**
   * Comparable property does not exist.
   */
  F000055 = 'F000055',

  /**
   * Range min is larger than max.
   */
  F000056 = 'F000056',

  /**
   * Range and isEstimate must either both be null, or both populated.
   */
  F000057 = 'F000057',

  /**
   * End date cannot be before start date.
   */
  F000058 = 'F000058',

  /**
   * Position on the map wasn't explicitly set and couldn't be resolved automatically.
   */
  F000059 = 'F000059',

  /**
   * Unknown Municipality.
   */
  F000060 = 'F000060',

  /**
   * Number must be positive.
   */
  F000061 = 'F000061',

  /**
   * Index type should be either set to "KPI oktober", "KPI oktober, 3% minst", "KPI oktober, 1% minst, 3.5% högst" or a number representing a fixed index percentage.
   */
  F000062 = 'F000062',

  /**
   * Business plan must have start date and end date.
   */
  F000064 = 'F000064',

  /**
   * Main property pictures must be linked to the property.
   */
  F000065 = 'F000065',

  /**
   * Main property pictures must have PROPERTY_PICTURE type and subtype.
   */
  F000066 = 'F000066',

  /**
   * The rentroll for the business plan start date is empty. The earliest date with a rentroll is {date}.
   */
  F000067 = 'F000067',

  /**
   * User to be assigned does not have access to the fault report.
   */
  F000068 = 'F000068',

  /**
   * Mentioned user does not exist.
   */
  F000069 = 'F000069',

  /**
   * Mentioned user does not have access to the fault report.
   */
  F000070 = 'F000070',

  /**
   * Parent fault report log belongs to a different fault report.
   */
  F000071 = 'F000071',

  /**
   * Threads only allow one level of nesting.
   */
  F000072 = 'F000072',

  /**
   * Parent log must be a message.
   */
  F000073 = 'F000073',

  /**
   * Incorrect current password.
   */
  F000074 = 'F000074',

  /**
   * Tenant message can't be sent because fault report does not have tenant contact email.
   */
  F000075 = 'F000075',

  /**
   * User name cannot contain characters "[" or "]".
   */
  F000076 = 'F000076',

  /**
   * Mobile app version must conform to the format "Integer.Integer.Integer".
   */
  F000077 = 'F000077',

  /**
   * List can't be empty.
   */
  F000078 = 'F000078',

  /**
   * Latest generation filter not compatible with min and max status date.
   */
  F000079 = 'F000079',

  /**
   * Share percentage is required when index type is specified.
   */
  F000080 = 'F000080',

  /**
   * Comparable lease reduction dates can't be before the lease is started or after it is finished.
   */
  F000081 = 'F000081',

  /**
   * Comparable lease reduction does not exist for the specified lease.
   */
  F000082 = 'F000082',

  /**
   * Number must be negative or 0.
   */
  F000083 = 'F000083',

  /**
   * Account specification can't be null for a used account.
   */
  F000084 = 'F000084',

  /**
   * Files overlap for the following periods: {periods}.
   */
  F000085 = 'F000085',

  /**
   * Import is ongoing for the following periods: {periods}.
   */
  F000086 = 'F000086',

  /**
   * 	Government inspection does not exist.
   */
  F000087 = 'F000087',

  /**
   * Government inspection item result does not exist.
   */
  F000088 = 'F000088',

  /**
   * At least one inspection item must be selected in order to create the fault report.
   */
  F000089 = 'F000089',

  /**
   * Property already has a business plan.
   */
  F000090 = 'F000090',

  /**
   * Municipality filter requires the country filter.
   */
  F000091 = 'F000091',

  /**
   * Business plan does not exist.
   */
  F000092 = 'F000092',

  /**
   * Unit object does not exist.
   */
  F000093 = 'F000093',

  /**
   * Business plan lease for that generation already exist.
   */
  F000094 = 'F000094',

  /**
   * Business plan lease must start after the previous generation lease ends.
   */
  F000095 = 'F000095',

  /**
   * Business plan lease must end before the next generation lease ends.
   */
  F000096 = 'F000096',

  /**
   * End date must be at least 2 days after previous end date.
   */
  F000097 = 'F000097',

  /**
   * Cannot create a vacant lease for a generation higher than 1.
   */
  F000098 = 'F000098'
}

interface ApiResponse {
  code: ResponseErrorCode;
  fieldErrors?: Record<string, ApiFieldError[]>;
  message: string;
}

export interface UserFriendlyApiResponse {
  code: ResponseErrorCode;
  fieldErrors?: Map<string, ApiFieldError[]>;
  message: string;
}

export interface ApiFieldError {
  code: ResponseFieldErrorCode;
  message: string;
  context?: Record<string, any>;
}

export type ResponseErrorCodeOverrideList = Map<ResponseErrorCode, string>;
export type ResponseFieldErrorCodeOverrideList = Map<ResponseFieldErrorCode, string>;

export const UnexpectedErrorText = () => i18n.t('validation:anUnexpectedErrorOccurred');

const responseErrorToTextMapping = () =>
  new Map<ResponseErrorCode, string>([
    [ResponseErrorCode.R000001, i18n.t('validation:responseError.unableToValidateLogin')],
    [ResponseErrorCode.R000002, i18n.t('validation:responseError.unexpectedServerError')],
    [ResponseErrorCode.R000003, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000004, i18n.t('validation:responseError.oneOrMoreFieldsInvalid')],
    [ResponseErrorCode.R000005, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000006, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000007, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000008, i18n.t('validation:responseError.invalidEmailOrPassword')],
    [ResponseErrorCode.R000009, i18n.t('validation:responseError.passwordTokenInvalid')],
    [ResponseErrorCode.R000010, i18n.t('validation:responseError.needToBeSystemAdministratorToPerform')],
    [ResponseErrorCode.R000011, i18n.t('validation:responseError.loggedInUserNoLongerExists')],
    [ResponseErrorCode.R000012, i18n.t('validation:responseError.verificationLinkExpired')],
    [ResponseErrorCode.R000013, i18n.t('validation:responseError.unverifiedEmail')],
    [ResponseErrorCode.R000014, i18n.t('validation:responseError.noPrivilegeToGrantRevokeRoleInOrganisation')],
    [ResponseErrorCode.R000015, i18n.t('validation:responseError.lastOrganisationAdminUnableToLeaveOrBeRevoked')],
    [ResponseErrorCode.R000016, i18n.t('validation:responseError.currentUserInsufficientRole')],
    [ResponseErrorCode.R000017, i18n.t('validation:responseError.unableToCreateWorkOrderLogWithoutInfo')],
    [ResponseErrorCode.R000018, i18n.t('validation:responseError.tenantMustHaveOngoingContractWithProperty')],
    [ResponseErrorCode.R000019, i18n.t('validation:responseError.multipleTenantsHaveOngoingContractWithProperty')],
    [ResponseErrorCode.R000020, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000021, i18n.t('validation:responseError.sortOrderMissingEntity')],
    [ResponseErrorCode.R000022, i18n.t('validation:responseError.FCMTokenAlreadyRegistered')],
    [ResponseErrorCode.R000023, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000025, i18n.t('validation:responseError.inspectionWorkOrderNotEditable')],
    [ResponseErrorCode.R000026, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000027, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000028, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000029, i18n.t('validation:responseError.fileDownloadTokenExpired')],
    [ResponseErrorCode.R000030, i18n.t('validation:responseError.noMoreThan2000RowsPerImport')],
    [ResponseErrorCode.R000031, i18n.t('validation:responseError.userIsAlreadyMemberOfProperty')],
    [ResponseErrorCode.R000032, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000033, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000034, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000035, i18n.t('validation:responseError.faultReportAssigneeCannotUnsubscribe')],
    [ResponseErrorCode.R000036, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000037, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000038, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000039, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000040, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000041, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000042, i18n.t('validation:responseError.emailTooLarge')],
    [ResponseErrorCode.R000043, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000044, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000045, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseErrorCode.R000046, i18n.t('validation:anUnexpectedErrorOccurred')]
  ]);

const fieldErrorToTextMapping = () =>
  new Map<ResponseFieldErrorCode, string>([
    [ResponseFieldErrorCode.F000001, i18n.t('validation:fieldError.mustNotBeEmpty')],
    [ResponseFieldErrorCode.F000002, i18n.t('validation:fieldError.required')],
    [ResponseFieldErrorCode.F000003, i18n.t('validation:fieldError.sizeMustBeBetweenMinMax')],
    [ResponseFieldErrorCode.F000004, i18n.t('validation:fieldError.invalidEmail')],
    [ResponseFieldErrorCode.F000005, i18n.t('validation:fieldError.emailAlreadyInUse')],
    [ResponseFieldErrorCode.F000006, i18n.t('validation:fieldError.mustBeSystemAdministrator')],
    [ResponseFieldErrorCode.F000007, i18n.t('validation:fieldError.organisationNameMustBeUnique')],
    [ResponseFieldErrorCode.F000008, i18n.t('validation:fieldError.organisationDoesNotExist')],
    [ResponseFieldErrorCode.F000009, i18n.t('validation:fieldError.userAlreadyInvitedToOrganisation')],
    [ResponseFieldErrorCode.F000010, i18n.t('validation:fieldError.userAlreadyMemeberOfOrganisation')],
    [ResponseFieldErrorCode.F000011, i18n.t('validation:fieldError.userIncorrectRoleInOrganisation')],
    [ResponseFieldErrorCode.F000012, i18n.t('validation:fieldError.propertyDoesNotExist')],
    [ResponseFieldErrorCode.F000013, i18n.t('validation:fieldError.workOrderDoesNotExist')],
    [ResponseFieldErrorCode.F000014, i18n.t('validation:fieldError.workOrderInvalidTransition')],
    [ResponseFieldErrorCode.F000015, i18n.t('validation:fieldError.tenantDoesNotExist')],
    [ResponseFieldErrorCode.F000016, i18n.t('validation:fieldError.tenantMustHaveOngoingContractWithProperty')],
    [ResponseFieldErrorCode.F000017, i18n.t('validation:fieldError.userDoesNotExist')],
    [ResponseFieldErrorCode.F000018, i18n.t('validation:fieldError.propertyWithGivenAddressDoesNotExist')],
    [ResponseFieldErrorCode.F000019, i18n.t('validation:fieldError.tenantWithNameDoesNotExist')],
    [ResponseFieldErrorCode.F000020, i18n.t('validation:fieldError.contractDoesNotExist')],
    [ResponseFieldErrorCode.F000021, i18n.t('validation:fieldError.workOrderLogDoesNotExist')],
    [ResponseFieldErrorCode.F000022, i18n.t('validation:fieldError.fileDoesNotExist')],
    [ResponseFieldErrorCode.F000025, i18n.t('validation:fieldError.fileAlreadyAttachedToAnotherEntity')],
    [ResponseFieldErrorCode.F000026, i18n.t('validation:fieldError.filedMustContainValidBase64EncodedString')],
    [ResponseFieldErrorCode.F000027, i18n.t('validation:fieldError.inspectionMustHaveAtleastOneInspectionItem')],
    [ResponseFieldErrorCode.F000028, i18n.t('validation:fieldError.inspectionItemDoesNotExist')],
    [ResponseFieldErrorCode.F000029, i18n.t('validation:fieldError.inspectionOnPropertyWithCategoryAlreadyExists')],
    [ResponseFieldErrorCode.F000030, i18n.t('validation:fieldError.approvedItemShouldNotBeIncludedOnFaultReport')],
    [
      ResponseFieldErrorCode.F000032,
      i18n.t('validation:fieldError.faultReportWorkOrderMustHaveFailedItemAndDescription')
    ],
    [
      ResponseFieldErrorCode.F000034,
      i18n.t('validation:fieldError.atLeastOneDocumentMustBeUploadedToPerformInspection')
    ],
    [ResponseFieldErrorCode.F000037, i18n.t('validation:fieldError.itemUnableToBeApprovedFaultReportStarted')],
    [ResponseFieldErrorCode.F000038, i18n.t('validation:fieldError.itemUnableToBeExcludedFaultReportStarted')],
    [ResponseFieldErrorCode.F000039, i18n.t('validation:fieldError.fileTypeSubTypeMismatch')],
    [ResponseFieldErrorCode.F000040, i18n.t('validation:fieldError.companyDoesNotExist')],
    [ResponseFieldErrorCode.F000041, i18n.t('validation:fieldError.companyPartOfDifferentOrganisationThanPortfolio')],
    [ResponseFieldErrorCode.F000042, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000043, i18n.t('validation:fieldError.userMustHaveInvestorRoleToBeAddedToPortfolio')],
    [ResponseFieldErrorCode.F000044, i18n.t('validation:fieldError.tenantNameSearchTooManyResults')],
    [ResponseFieldErrorCode.F000045, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000046, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000047, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000048, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000049, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000050, i18n.t('validation:fieldError.itemUnableToBeRemovedFaultReportStarted')],
    [
      ResponseFieldErrorCode.F000051,
      i18n.t('validation:fieldError.unableToSetDeadlineBeforePreviouslyCompletedInspection')
    ],
    [ResponseFieldErrorCode.F000052, i18n.t('validation:fieldError.unitDoesNotExist')],
    [ResponseFieldErrorCode.F000053, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000054, i18n.t('validation:fieldError.invalidMetryApiKey')],
    [ResponseFieldErrorCode.F000055, i18n.t('validation:fieldError.comparablePropertyDoesNotExist')],
    [ResponseFieldErrorCode.F000056, i18n.t('validation:fieldError.rangeMinIsLargerThanMax')],
    [ResponseFieldErrorCode.F000057, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000058, i18n.t('validation:fieldError.endDateBeforeStartDate')],
    [ResponseFieldErrorCode.F000059, i18n.t('validation:fieldError.unableToFindAddressOnMap')],
    [ResponseFieldErrorCode.F000060, i18n.t('validation:fieldError.unknownMunicipality')],
    [ResponseFieldErrorCode.F000061, i18n.t('validation:fieldError.numberMustBePositive')],
    [ResponseFieldErrorCode.F000062, i18n.t('validation:fieldError.invalidIndex')],
    [ResponseFieldErrorCode.F000064, i18n.t('validation:fieldError.businessPlanNeedsStartEndDate')],
    [ResponseFieldErrorCode.F000065, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000066, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000067, i18n.t('validation:fieldError.businessPlanNoRentrollForStartDate')],
    [ResponseFieldErrorCode.F000068, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000069, i18n.t('validation:fieldError.mentionedUserDoesNotExist')],
    [ResponseFieldErrorCode.F000070, i18n.t('validation:fieldError.mentionedUserDoesNotHaveAccessToFaultReport')],
    [ResponseFieldErrorCode.F000071, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000072, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000073, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000074, i18n.t('validation:fieldError.incorrectPassword')],
    [ResponseFieldErrorCode.F000075, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000076, i18n.t('validation:fieldError.userNameCannotContainCharacters')],
    [ResponseFieldErrorCode.F000077, i18n.t('validation:fieldError.mobileVersionMustConformToFormat')],
    [ResponseFieldErrorCode.F000078, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000079, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000080, i18n.t('validation:fieldError.sharePercentageIsRequired')],
    [ResponseFieldErrorCode.F000081, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000082, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000083, i18n.t('validation:fieldError.numberMustBeNegativeOrZero')],
    [ResponseFieldErrorCode.F000084, i18n.t('validation:fieldError.accountSpecificationCannotBeNullForUsedAccount')],
    [ResponseFieldErrorCode.F000085, i18n.t('validation:fieldError.filesOverlapForTheFollowingPeriods')],
    [ResponseFieldErrorCode.F000086, i18n.t('validation:fieldError.importIsOngoingForTheFollowingPeriods')],
    [ResponseFieldErrorCode.F000087, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000088, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000089, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000090, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000091, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000092, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000093, i18n.t('validation:anUnexpectedErrorOccurred')],
    [ResponseFieldErrorCode.F000094, i18n.t('validation:fieldError.businessPlanLeaseForGenerationAlreadyExists')],
    [ResponseFieldErrorCode.F000095, i18n.t('validation:fieldError.businessPlanLeaseMustStartAfterPreviousLeaseEnds')],
    [ResponseFieldErrorCode.F000096, i18n.t('validation:fieldError.businessPlanLeaseMustEndBeforeNextLeaseEnds')],
    [ResponseFieldErrorCode.F000097, i18n.t('validation:fieldError.endDateMustBeAtLeast2DaysAfterPreviousEndDate')],
    [ResponseFieldErrorCode.F000098, i18n.t('validation:fieldError.cannotCreateVacantLeaseForGenerationHigherThan1')]
  ]);

/**
 * Returns translated text for specified response error code.
 * If translation is not found an unexpected error text is returned.
 * @param code Response error code.
 * @param overrides List of messages to use instead of default message for specified error codes.
 */
export const translateResponseErrorCode = (
  code: ResponseErrorCode,
  overrides?: ResponseErrorCodeOverrideList
): string => {
  if (overrides?.has(code)) {
    return overrides.get(code)!;
  }

  let text = responseErrorToTextMapping().get(code) ?? UnexpectedErrorText();

  //Add error code to errors displaying the unexpected error text.
  if (text === UnexpectedErrorText()) {
    text += ` ${i18n.t('validation:errorCode')}: ${code}`;
  }

  return text;
};

/**
 * Returns translated text for specified field error.
 * If translation is not found an unexpected error text is returned.
 * @param fieldError Field error (FieldErrorView in API doc)
 */
const translateResponseFieldErrorCode = (fieldError: ApiFieldError): string => {
  let text = fieldErrorToTextMapping().get(fieldError.code);

  if (!text) {
    return UnexpectedErrorText();
  }

  //Add error code to errors displaying the unexpected error text.
  if (text === UnexpectedErrorText()) {
    text += ` ${i18n.t('validation:errorCode')}: ${fieldError.code}`;
  }

  // Insert any context values if any.
  if (fieldError.context) {
    for (const [key, value] of Object.entries(fieldError.context)) {
      text = text.replace(`{${key}}`, translateResponseFieldErrorCodeContextValue(fieldError.code, value));
    }
  }

  return text;
};

const translateResponseFieldErrorCodeContextValue = (code: ResponseFieldErrorCode, value: string) => {
  switch (code) {
    case ResponseFieldErrorCode.F000014:
      return translateWorkOrderStatus(value as WorkOrderStatus);
  }

  return value;
};

/**
 * Converts the specified api response to an api response with user friendly error messages.
 * @param data Raw response returned from API request.
 * @param overrides List of messages to use instead of default message for specified error codes.
 */
export const toUserfriendlyApiResponse = (
  data: ApiResponse,
  overrides?: ResponseErrorCodeOverrideList
): UserFriendlyApiResponse => {
  const resp = data as ApiResponse;

  return {
    code: resp.code,
    message: translateResponseErrorCode(resp.code as ResponseErrorCode, overrides),
    fieldErrors: resp.fieldErrors
      ? new Map<string, ApiFieldError[]>(
          Object.entries(resp.fieldErrors).map(([key, value]) => [
            key,
            value.map((fieldError) => ({
              code: fieldError.code,
              message: translateResponseFieldErrorCode(fieldError)
            }))
          ])
        )
      : undefined
  };
};
