/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { FaultReportSubscriptionCreateView } from '../model';
// @ts-ignore
import { FaultReportSubscriptionListView } from '../model';
// @ts-ignore
import { FaultReportSubscriptionReadView } from '../model';
/**
 * FaultReportSubscriptionsApi - axios parameter creator
 * @export
 */
export const FaultReportSubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a fault report subscription
     * @param {FaultReportSubscriptionCreateView} [faultReportSubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportSubscriptionResourceCreate: async (
      faultReportSubscriptionCreateView?: FaultReportSubscriptionCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/fault-report-subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        faultReportSubscriptionCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a fault report subscription
     * @param {number} id Fault report subscription ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportSubscriptionResourceDelete: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('faultReportSubscriptionResourceDelete', 'id', id);
      const localVarPath = `/v1/fault-report-subscriptions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List fault report subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [faultReportId] Filter by fault report
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportSubscriptionResourceList: async (
      limit?: number,
      offset?: number,
      faultReportId?: number,
      userId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/fault-report-subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (faultReportId !== undefined) {
        localVarQueryParameter['faultReportId'] = faultReportId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a fault report subscription
     * @param {number} id Fault report subscription ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportSubscriptionResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('faultReportSubscriptionResourceRead', 'id', id);
      const localVarPath = `/v1/fault-report-subscriptions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FaultReportSubscriptionsApi - functional programming interface
 * @export
 */
export const FaultReportSubscriptionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FaultReportSubscriptionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a fault report subscription
     * @param {FaultReportSubscriptionCreateView} [faultReportSubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faultReportSubscriptionResourceCreate(
      faultReportSubscriptionCreateView?: FaultReportSubscriptionCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaultReportSubscriptionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.faultReportSubscriptionResourceCreate(
        faultReportSubscriptionCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a fault report subscription
     * @param {number} id Fault report subscription ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faultReportSubscriptionResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.faultReportSubscriptionResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List fault report subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [faultReportId] Filter by fault report
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faultReportSubscriptionResourceList(
      limit?: number,
      offset?: number,
      faultReportId?: number,
      userId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaultReportSubscriptionListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.faultReportSubscriptionResourceList(
        limit,
        offset,
        faultReportId,
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a fault report subscription
     * @param {number} id Fault report subscription ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faultReportSubscriptionResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaultReportSubscriptionReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.faultReportSubscriptionResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * FaultReportSubscriptionsApi - factory interface
 * @export
 */
export const FaultReportSubscriptionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FaultReportSubscriptionsApiFp(configuration);
  return {
    /**
     *
     * @summary Create a fault report subscription
     * @param {FaultReportSubscriptionCreateView} [faultReportSubscriptionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportSubscriptionResourceCreate(
      faultReportSubscriptionCreateView?: FaultReportSubscriptionCreateView,
      options?: any
    ): AxiosPromise<FaultReportSubscriptionReadView> {
      return localVarFp
        .faultReportSubscriptionResourceCreate(faultReportSubscriptionCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a fault report subscription
     * @param {number} id Fault report subscription ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportSubscriptionResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.faultReportSubscriptionResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List fault report subscriptions
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [faultReportId] Filter by fault report
     * @param {number} [userId] Filter by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportSubscriptionResourceList(
      limit?: number,
      offset?: number,
      faultReportId?: number,
      userId?: number,
      options?: any
    ): AxiosPromise<FaultReportSubscriptionListView> {
      return localVarFp
        .faultReportSubscriptionResourceList(limit, offset, faultReportId, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a fault report subscription
     * @param {number} id Fault report subscription ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faultReportSubscriptionResourceRead(id: number, options?: any): AxiosPromise<FaultReportSubscriptionReadView> {
      return localVarFp.faultReportSubscriptionResourceRead(id, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for faultReportSubscriptionResourceCreate operation in FaultReportSubscriptionsApi.
 * @export
 * @interface FaultReportSubscriptionsApiFaultReportSubscriptionResourceCreateRequest
 */
export interface FaultReportSubscriptionsApiFaultReportSubscriptionResourceCreateRequest {
  /**
   *
   * @type {FaultReportSubscriptionCreateView}
   * @memberof FaultReportSubscriptionsApiFaultReportSubscriptionResourceCreate
   */
  readonly faultReportSubscriptionCreateView?: FaultReportSubscriptionCreateView;
}

/**
 * Request parameters for faultReportSubscriptionResourceDelete operation in FaultReportSubscriptionsApi.
 * @export
 * @interface FaultReportSubscriptionsApiFaultReportSubscriptionResourceDeleteRequest
 */
export interface FaultReportSubscriptionsApiFaultReportSubscriptionResourceDeleteRequest {
  /**
   * Fault report subscription ID
   * @type {number}
   * @memberof FaultReportSubscriptionsApiFaultReportSubscriptionResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for faultReportSubscriptionResourceList operation in FaultReportSubscriptionsApi.
 * @export
 * @interface FaultReportSubscriptionsApiFaultReportSubscriptionResourceListRequest
 */
export interface FaultReportSubscriptionsApiFaultReportSubscriptionResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof FaultReportSubscriptionsApiFaultReportSubscriptionResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof FaultReportSubscriptionsApiFaultReportSubscriptionResourceList
   */
  readonly offset?: number;

  /**
   * Filter by fault report
   * @type {number}
   * @memberof FaultReportSubscriptionsApiFaultReportSubscriptionResourceList
   */
  readonly faultReportId?: number;

  /**
   * Filter by user
   * @type {number}
   * @memberof FaultReportSubscriptionsApiFaultReportSubscriptionResourceList
   */
  readonly userId?: number;
}

/**
 * Request parameters for faultReportSubscriptionResourceRead operation in FaultReportSubscriptionsApi.
 * @export
 * @interface FaultReportSubscriptionsApiFaultReportSubscriptionResourceReadRequest
 */
export interface FaultReportSubscriptionsApiFaultReportSubscriptionResourceReadRequest {
  /**
   * Fault report subscription ID
   * @type {number}
   * @memberof FaultReportSubscriptionsApiFaultReportSubscriptionResourceRead
   */
  readonly id: number;
}

/**
 * FaultReportSubscriptionsApi - object-oriented interface
 * @export
 * @class FaultReportSubscriptionsApi
 * @extends {BaseAPI}
 */
export class FaultReportSubscriptionsApi extends BaseAPI {
  /**
   *
   * @summary Create a fault report subscription
   * @param {FaultReportSubscriptionsApiFaultReportSubscriptionResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaultReportSubscriptionsApi
   */
  public faultReportSubscriptionResourceCreate(
    requestParameters: FaultReportSubscriptionsApiFaultReportSubscriptionResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FaultReportSubscriptionsApiFp(this.configuration)
      .faultReportSubscriptionResourceCreate(requestParameters.faultReportSubscriptionCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a fault report subscription
   * @param {FaultReportSubscriptionsApiFaultReportSubscriptionResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaultReportSubscriptionsApi
   */
  public faultReportSubscriptionResourceDelete(
    requestParameters: FaultReportSubscriptionsApiFaultReportSubscriptionResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return FaultReportSubscriptionsApiFp(this.configuration)
      .faultReportSubscriptionResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List fault report subscriptions
   * @param {FaultReportSubscriptionsApiFaultReportSubscriptionResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaultReportSubscriptionsApi
   */
  public faultReportSubscriptionResourceList(
    requestParameters: FaultReportSubscriptionsApiFaultReportSubscriptionResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FaultReportSubscriptionsApiFp(this.configuration)
      .faultReportSubscriptionResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.faultReportId,
        requestParameters.userId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a fault report subscription
   * @param {FaultReportSubscriptionsApiFaultReportSubscriptionResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaultReportSubscriptionsApi
   */
  public faultReportSubscriptionResourceRead(
    requestParameters: FaultReportSubscriptionsApiFaultReportSubscriptionResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return FaultReportSubscriptionsApiFp(this.configuration)
      .faultReportSubscriptionResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
