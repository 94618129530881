import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Message, Table } from 'semantic-ui-react';
import { EmbeddedContractBusinessPlanLeaseReadView } from '../../../GeneratedServices';
import colors from '../../../Utils/Colors/Colors.module.scss';
import { getComparedPercentage, getFormatedCurrencyValue } from '../../../Utils/number';
import NumberFormater from '../../NumberFormater/NumberFormater';
import TableHeaderCellContent from '../../TableHeaderCell/TableHeaderCell';
import styles from './LeasePopup.module.scss';

interface Props {
  businessPlanLease: EmbeddedContractBusinessPlanLeaseReadView | null;
  previousContractTotalRentIndexed: number | null;
  currentContractTotalRentIndexed: number | null;
}

const LeasePopup: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);

  const getDifferenceVsPreviousLayout = (previous: number, current: number) => {
    const percentage = getComparedPercentage(current, previous);

    return (
      <span style={{ color: percentage && percentage >= 0 ? colors.green : colors.red }}>
        <NumberFormater
          value={percentage}
          suffix={`%`}
          prefix={percentage && percentage > 0 ? '+' : undefined}
          additionalProps={{
            decimalScale: 1,
            allowNegative: true
          }}
        />
      </span>
    );
  };
  const businessPlanTable = (lease: EmbeddedContractBusinessPlanLeaseReadView) => {
    const baseRent = getFormatedCurrencyValue(lease.estimatedBaseRentIndexed);
    const additions = getFormatedCurrencyValue(lease.estimatedAdditionsIndexed);
    const totalRent = baseRent + additions;

    return (
      <Table basic="very" singleLine size="small" className={styles.table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('common:baseRent')} extra="SEK" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('contracts:additions')} extra="SEK" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellContent header={t('common:totalRent')} extra="SEK" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {baseRent ? <NumberFormater value={baseRent} additionalProps={{ decimalScale: 0 }} /> : '-'}
            </Table.Cell>
            <Table.Cell>
              {additions ? <NumberFormater value={additions} additionalProps={{ decimalScale: 0 }} /> : '-'}
            </Table.Cell>
            <Table.Cell>
              {totalRent ? <NumberFormater value={totalRent} additionalProps={{ decimalScale: 0 }} /> : '-'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const noBusinessPlanMessage = <Message>{t('contracts:noBusinessPlanForObjectMessage')}</Message>;

  return (
    <>
      <Header as="h5">{t('common:businessPlan')}</Header>
      {props.businessPlanLease == null ? noBusinessPlanMessage : businessPlanTable(props.businessPlanLease)}

      {props.currentContractTotalRentIndexed != null &&
        props.previousContractTotalRentIndexed != null &&
        props.previousContractTotalRentIndexed > 0 && (
          <>
            <Header as="h5">{t('contracts:rentalIncomeVsPrevious')}</Header>
            {getDifferenceVsPreviousLayout(
              props.previousContractTotalRentIndexed,
              props.currentContractTotalRentIndexed
            )}
          </>
        )}
    </>
  );
};

export default LeasePopup;
