import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Segment } from 'semantic-ui-react';
import FileDropzone from '../FileDropzone/FileDropzone';
import FileUploadProgress, { Status as UploadStatus, UploadProgress } from '../FileUploadProgress/FileUploadProgress';
import { translateResponseErrorCode } from '../../Http/response-error';
import { ImportResultsView } from '../../Services/ComparableService.types';
import { resolveIconFromFilename } from '../../Utils/icon';

interface Props {
  onClose: (imported: boolean) => void;
  uploadedEntityType: string;
  importPromise: (file: File, config: AxiosRequestConfig) => Promise<AxiosResponse<ImportResultsView, any>>;
  importDisabled?: boolean;
  closeDisabled?: boolean;
  uploadStepsLayout?: React.ReactNode;
}

const ImportModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'files']);

  const [selectedFile, setSelectedFile] = React.useState<File>();

  const [progress, setProgress] = React.useState<UploadProgress>();

  const [results, setResults] = React.useState<ImportResultsView>();

  const [imported, setImported] = React.useState(false);

  /**
   * Returns a promise for uploading the specified file.
   * Promise is resolved even if an error occurs (file state sets to Error).
   */
  const uploadFile = async (file: File): Promise<void> => {
    const abortController = new AbortController();

    try {
      // Set state of file to Uploading (start upload).
      setProgress((prevValue) => ({
        ...prevValue,
        status: UploadStatus.Uploading,
        totalBytes: 0,
        uploadedBytes: 0
      }));

      const config = {
        signal: abortController.signal,
        onUploadProgress: (e: ProgressEvent) => {
          setProgress((prevValue) => ({
            ...prevValue,
            status: UploadStatus.Uploading,
            uploadedBytes: e.loaded,
            totalBytes: e.total
          }));
        }
      };

      // Start upload and report uploaded bytes progressively.
      let uploadedFile: ImportResultsView | undefined;
      uploadedFile = (await props.importPromise(file, config)).data;

      // Set the uploaded file after one second to show 100% progress briefly, cleanup other states
      setTimeout(() => {
        setImported(true);
        setResults(uploadedFile);
        setSelectedFile(undefined);
        setProgress(undefined);
      }, 1000);

      setProgress((prevValue) => ({
        ...prevValue,
        status: UploadStatus.Uploaded
      }));
    } catch (err) {
      setProgress((prevValue) => ({
        ...prevValue,
        status: axios.isCancel(err) ? UploadStatus.Canceled : UploadStatus.Error,
        errorMessage: (err as AxiosError).response?.data?.code
          ? translateResponseErrorCode((err as AxiosError).response?.data.code)
          : undefined
      }));
    }
  };

  const cancelUpload = (progress?: UploadProgress) => {
    progress?.fileReader?.abort();
    progress?.abortController?.abort();
    setProgress((prevValue) => ({
      ...prevValue,
      status: UploadStatus.Canceled
    }));
  };

  const getLayout = () => {
    if (results) {
      return (
        <div>
          <ul>
            <li>
              {t('common:created')}: {results.created}
            </li>
            <li>
              {t('common:updated')}: {results.updated}
            </li>
            <li>
              {t('files:failed')}: {results.failed}
              {results.failedRows != null && (
                <>
                  {' '}
                  (
                  <a
                    download={`${t('files:failed')}-${props.uploadedEntityType}.xlsx`}
                    href={'data:application/octet-stream;base64,' + results.failedRows}
                  >
                    {t('common:download')}
                  </a>
                  )
                </>
              )}
            </li>
            <li>
              {t('files:skipped')}: {results.skipped}{' '}
              {results.skippedRows != null && (
                <>
                  {' '}
                  (
                  <a
                    download={`${t('files:skipped')}-${props.uploadedEntityType}.xlsx`}
                    href={'data:application/octet-stream;base64,' + results.skippedRows}
                  >
                    {t('common:download')}
                  </a>
                  )
                </>
              )}
            </li>
          </ul>
        </div>
      );
    } else
      return (
        <>
          {props.uploadStepsLayout}

          {selectedFile ? (
            <Segment style={{ display: 'flex' }}>
              <Icon name={resolveIconFromFilename(selectedFile.name)} className="clear-icon" size="large" />
              {selectedFile.name}
              <div style={{ flex: 1, paddingLeft: '1em', paddingRight: '1em' }}>
                <FileUploadProgress
                  progress={progress}
                  resetProgress={() => {
                    setProgress((prevValue) => ({
                      ...prevValue,
                      status: UploadStatus.Waiting
                    }));
                    uploadFile(selectedFile);
                  }}
                  cancelUpload={() => cancelUpload(progress)}
                />
              </div>

              <Icon
                name="trash"
                onClick={() => {
                  setSelectedFile(undefined);
                  setProgress(undefined);
                }}
              />
            </Segment>
          ) : (
            <FileDropzone
              multiple={false}
              onDrop={(acceptedFiles) => {
                if (acceptedFiles && acceptedFiles[0]) {
                  setSelectedFile(acceptedFiles[0]);
                }
              }}
            />
          )}
        </>
      );
  };
  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      open={true}
      closeIcon={progress?.status !== UploadStatus.Uploading && !props.closeDisabled}
      onClose={() => props.onClose(imported)}
    >
      <Modal.Header>{t('files:fileUpload')}</Modal.Header>
      <Modal.Content>{getLayout()}</Modal.Content>
      <Modal.Actions>
        {results ? (
          <Button
            primary
            onClick={() => {
              setResults(undefined);
            }}
          >
            {t('files:uploadAnother')}
          </Button>
        ) : (
          <Button
            primary
            loading={progress?.status === UploadStatus.Uploading}
            disabled={!selectedFile || (progress && progress.status !== UploadStatus.Waiting) || props.importDisabled}
            onClick={() => {
              selectedFile && uploadFile(selectedFile);
            }}
          >
            {t('common:upload')}
          </Button>
        )}
        <Button
          secondary
          disabled={props.closeDisabled || progress?.status === UploadStatus.Uploading}
          onClick={() => props.onClose(imported)}
        >
          {t('common:close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ImportModal;
