import React from 'react';
import styles from './Positioned.module.scss';

interface Props {
  position: 'TOP-RIGHT';
  dx: string;
  dy: string;
  zIndex?: number;
  className?: string;
}

/**
 * Positions content relative the first parent container that has position set
 * to either "relative", "absolute", "fixed", "sticky" or "inherit".
 *
 * Currently only supports upper right corner.
 */
const Positioned: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const componentWrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    componentWrapperRef.current?.style.setProperty('--dx', props.dx);
    componentWrapperRef.current?.style.setProperty('--dy', props.dy);

    if (props.zIndex !== undefined) {
      componentWrapperRef.current?.style.setProperty('--z-index', props.zIndex.toString());
    }
  }, [props.dx, props.dy, props.zIndex]);

  return (
    <div ref={componentWrapperRef} className={`${styles.componentWrapper} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Positioned;
